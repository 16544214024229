import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ResetCustomerTracking, SetCustomerTracking } from './../../actions/trackingModule';
import { connect } from "react-redux";
import { RootState } from "./../../store";
import { getCustomerdocketDataTrack, trackCustomerBooking } from './../../services/customersection';
import { fetchDocketDetails } from '../../services/operations';
import { ResetCustomerBookingTracking } from '../../actions/customerBooking';
import { useMediaQuery, useTheme } from "@mui/material";
import { withRouter } from "../../hoc/withRouter";
import { showHttpError } from "../../utils/message";
// import {withRouter} from "react-router-dom";

const TrackAuth = (props: any) => {

  const theme = useTheme();
  const isMobile1 = useMediaQuery(theme.breakpoints.down('sm')); // Change the breakpoint as needed
  const isMobile2 = useMediaQuery(theme.breakpoints.down('xs')); // Change the breakpoint as needed
  const mobileView = isMobile1 || isMobile2

  const location: any = useLocation();
  const navigate = useNavigate();

  console.log("zsdnkn 61634165 location", location)
  console.log("zsdnkn 61634165 history", history)

  const GetTrackingData = async () => {

    if (location?.state?.from === "Branch-Authenticated-CustomerOrder") {
      try {
        console.log("5641351421 From First Main")
        const response: any = await trackCustomerBooking({ shipmentNumber: location?.state?.docketNumber?.docket });
        console.log("5641351421 From First Main", response)
        if (response.bookingTracker.length > 0) {
          props.SetCustomerTracking({ "orderData": response.bookingTracker[0], "source": location?.state?.from, "backToOriginPath": location?.state?.backSource }),
            navigate("/dashboard/Customer-track")
        }
        else {
          alert("Shipment Number is Incorrect !")
          // navigate("/dashboard")
          return;

        }
      } catch (error: any) {
        console.log("errro 200", error.message)
        alert("Your are Not Eligible To Track This Docket !")
        // navigate("/dashboard")
        return;

      }
    }

    if (location?.state?.from === "Branch-Authenticated-BranchOrder") {
      try {
        let Payload = {
          docket: location?.state?.docketNumber?.docket,
          queryType: "tracking",
          old: props?.location?.state?.oldDocketView || false,
          // old: false,
          sub: props.userDetails.loginType,
          entity: props.userDetails.opBranch._id
        }
        console.log("5641351421 From Second Main")
        const response: any = await fetchDocketDetails(Payload);
        console.log("5641351421 From Second Main", response)
        props.SetCustomerTracking({ "orderData": response, "source": location?.state?.from, "backToOriginPath": location?.state?.backSource });
        navigate("/dashboard/Customer-track")
      } catch (error: any) {
        if (error.message === "docket is not exists") {
          console.log("errro 200", error.message)
          showHttpError("Your are Not Eligible To Track This Docket!")
          navigate("/dashboard")
          return;

        }
        if (error.message === "No docket found" || error.message === "No such docket available") {
          console.log("errro 200", error.message)
          if (props?.location?.state?.oldDocketView) {
            showHttpError(
              "Docket number or tracking number does not match in old data."
            );
          } else {
            showHttpError("Docket Number or Tracking Number does not exist!");
          }
          navigate("/dashboard")
          return;
          
        }
      }
    }
    
    if (location?.state?.from === "Customer-Authenticated-CustomerOrder") {
      try {
        console.log("5641351421 From Third Main")
        const response: any = await trackCustomerBooking({ shipmentNumber: location?.state?.docketNumber?.docket });
        console.log("5641351421 From Third Main", response)
        if (response.bookingTracker.length > 0) {
          props.SetCustomerTracking({ "orderData": response.bookingTracker[0], "source": location?.state?.from, "backToOriginPath": location?.state?.backSource });
          navigate("/CustomerDashboard/Customer-track")
        }
        else {
          alert("Shipment Number is Incorrect !")
          navigate("/CustomerDashboard/pastBookings")
        }
      } catch (error: any) {
        console.log("errro 200", error.message)
        alert("Your are Not Eligible To Track This Docket !")
        navigate("/CustomerDashboard/pastBookings")
      }
    }

    if (location?.state?.from === "Customer-Authenticated-BranchOrder") {
      try {
        console.log("5641351421 From Four Main")
        const response: any = await getCustomerdocketDataTrack({
          docket: location?.state?.docketNumber?.docket,
          customer: props.customerReducer._id,
          isFromMainPage: false
        });
        console.log("5641351421 From Four Main", response)
        props.SetCustomerTracking({ "orderData": response, "source": location?.state?.from, "backToOriginPath": location?.state?.backSource });
        navigate("/CustomerDashboard/Customer-track")
      } catch (error: any) {
        if (error.message === "docket is not exists") {
          console.log("errro 200", error.message)
          alert("Your are Not Eligible To Track This Docket !")
          navigate("/CustomerDashboard/pastBookings")
        }
      }
    }

    if (location?.state?.from === "Customer-NotAuthenticated-CustomerOrder") {
      try {
        console.log("5641351421 From Fifth Main")
        const response: any = await trackCustomerBooking({ shipmentNumber: location?.state?.docketNumber?.docket });
        console.log("5641351421 From Fifth Main", response)
        if (response.bookingTracker.length > 0) {
          props.SetCustomerTracking({ "orderData": response.bookingTracker[0], "source": location?.state?.from });
          navigate("/Customer-track")
        }
        else {
          alert("Shipment Number is Incorrect !")
          navigate("/")
        }
      } catch (error: any) {
        console.log("errro 200", error.message)
        alert("Your are Not Eligible To Track This Docket !")
        navigate("/")
      }
    }

    if (location?.state?.from === "Customer-NotAuthenticated-BranchOrder") {
      try {
        console.log("5641351421 From Sixth Main")
        const response: any = await getCustomerdocketDataTrack({ docket: location?.state?.docketNumber?.docket, isFromMainPage: true });
        console.log("5641351421 From Sixth Main", response)
        props.SetCustomerTracking({ "orderData": response, "source": location?.state?.from });
        navigate("/Customer-track")
      } catch (error: any) {
        if (error.message === "docket is not exists") {
          console.log("errro 200", error.message)
          alert("Your are Not Eligible To Track This Docket !")
          navigate("/")
        }
      }
    }

  }

  const GetTrackingDataMobVersion = async () => {

    if (location?.state?.from === "Branch-Authenticated-CustomerOrder") {
      try {
        const response: any = await trackCustomerBooking({ shipmentNumber: location?.state?.docketNumber?.docket });
        console.log("5641351421 First", response)
        if (response.bookingTracker.length > 0) {
          props.SetCustomerTracking({ "orderData": response.bookingTracker[0], "source": location?.state?.from, "backToOriginPath": location?.state?.backSource });
          navigate("/dashboard/Customer-track")
        }
        else {
          alert("Shipment Number is Incorrect !")
          // navigate("/dashboard")
          return;

        }
      } catch (error: any) {
        console.log("errro 200", error.message)
        alert("Your are Not Eligible To Track This Docket !")
        // navigate("/dashboard")
        return;

      }
    }

    if (location?.state?.from === "Branch-Authenticated-BranchOrder") {
      try {
        let Payload = {
          docket: location?.state?.docketNumber?.docket,
          queryType: "tracking",
          old: props?.location?.state?.oldDocketView || false,
          // old: false,
          sub: props.userDetails.loginType,
          entity: props.userDetails.opBranch._id
        }
        const response: any = await fetchDocketDetails(Payload);
        console.log("5641351421 Second", response)
        props.SetCustomerTracking({ "orderData": response, "source": location?.state?.from, "backToOriginPath": location?.state?.backSource });
        navigate("/dashboard/Customer-track")
      } catch (error: any) {
        if (error.message === "docket is not exists") {
          console.log("errro 200", error.message)
          alert("Your are Not Eligible To Track This Docket !")
          // navigate("/dashboard")
          return;

        }
        if (error.message === "No docket found" || error.message === "No such docket available") {
          console.log("errro 200", error.message)
          alert("Docket Number Or Tracking Number is Not Exist ! 1")
          // navigate("/dashboard")
          return;

        }
      }
    }

    if (location?.state?.from === "Customer-Authenticated-CustomerOrder") {
      try {
        const response: any = await trackCustomerBooking({ shipmentNumber: location?.state?.docketNumber?.docket });
        console.log("5641351421 Third", response)
        if (response.bookingTracker.length > 0) {
          props.SetCustomerTracking({ "orderData": response.bookingTracker[0], "source": location?.state?.from, "backToOriginPath": location?.state?.backSource });
          navigate("/CustomerDashboard/Customer-track")
        }
        else {
          alert("Shipment Number is Incorrect !")
          navigate("/CustomerDashboard/pastBookings")
        }
      } catch (error: any) {
        console.log("errro 200", error.message)
        alert("Your are Not Eligible To Track This Docket !")
        navigate("/CustomerDashboard/pastBookings")
      }
    }

    if (location?.state?.from === "Customer-Authenticated-BranchOrder") {
      try {
        const response: any = await getCustomerdocketDataTrack({
          docket: location?.state?.docketNumber?.docket,
          customer: props.customerReducer._id,
          isFromMainPage: false
        });
        console.log("5641351421 Fourth", response)
        props.SetCustomerTracking({ "orderData": response, "source": location?.state?.from, "backToOriginPath": location?.state?.backSource });
        navigate("/CustomerDashboard/Customer-track")
      } catch (error: any) {
        if (error.message === "docket is not exists") {
          console.log("errro 200", error.message)
          alert("Your are Not Eligible To Track This Docket !")
          navigate("/CustomerDashboard/pastBookings")
        }
      }
    }

    if (location?.state?.from === "Customer-NotAuthenticated-CustomerOrder") {
      try {
        const response: any = await trackCustomerBooking({ shipmentNumber: location?.state?.docketNumber?.docket });
        console.log("5641351421 Fifth", response)
        if (response.bookingTracker.length > 0) {
          props.SetCustomerTracking({ "orderData": response.bookingTracker[0], "source": location?.state?.from });
          navigate("/Customer-track")
        }
        else {
          alert("Shipment Number is Incorrect !")
          navigate("/")
        }
      } catch (error: any) {
        console.log("errro 200", error.message)
        alert("Your are Not Eligible To Track This Docket !")
        navigate("/")
      }
    }

    if (location?.state?.from === "Customer-NotAuthenticated-BranchOrder") {
      try {
        const response: any = await getCustomerdocketDataTrack({ docket: location?.state?.docketNumber?.docket, isFromMainPage: true });
        console.log("5641351421 Sixth", response)
        props.SetCustomerTracking({ "orderData": response, "source": location?.state?.from });
        navigate("/Customer-track")
      } catch (error: any) {
        if (error.message === "docket is not exists") {
          console.log("errro 200", error.message)
          alert("Your are Not Eligible To Track This Docket !")
          navigate("/")
        }
      }
    }

  }

  useEffect(() => {
    props.ResetCustomerTracking()
    props.ResetCustomerBookingTracking()
    if (mobileView) {
      if (location?.state?.from) {
        GetTrackingDataMobVersion();
      }
    }
    if (!mobileView) {
      if (location?.state?.from) {
        GetTrackingData();
      }
    }
  }, [location?.state?.from])

  return (
    <>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    customerReducer: state.customerLoginReducer,
    userDetails: state.user
  };
};

const mapDispatchToProps = {
  SetCustomerTracking,
  ResetCustomerTracking,
  ResetCustomerBookingTracking
};

export default
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(TrackAuth)
  )