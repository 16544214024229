import { FaUserPlus } from "react-icons/fa";
import { GoogleMap, Marker, Polyline, MarkerClusterer, OverlayView, } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
// import { showHttpError } from "../../utils/message";
import { hideLoader, showLoader } from "../../actions/UserActions";
import "./viewShipmentTrack.css"
import { useGoogleMapJsLoader } from "../../hooks/useGoogleMapJsLoader";

let lebelArr = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"]
import { connect } from "react-redux";
import { RootState } from "../../store";


interface Iprops {
  Route?: any;
  from?: any;
  customeised?: any;
  encoded: any;
  className?: string;
  mobileMapHeight?: string;
  style?: any;
  zoom?: number;
  customerDocketLocation?: any
  orderData?: any;
}

const ViewShipmentTrack = ({ zoom = 11, orderData, ...props }: Iprops) => {
  // const { isLoaded ,loadError} = useJsApiLoader({
  //   googleMapsApiKey: "AIzaSyA5kw1jdPoWOGfUDL0o2vW0Ezf2Cvq12OU",
  //   nonce: "nonce",
  //   libraries: ["places"],
  // });
  const { isLoaded, loadError }: any = useGoogleMapJsLoader()
  // let Origin = props?.Route && props?.Route[0]
  // let Dest = props?.Route && props?.Route[props?.Route.length -1]
  let WayPoint = props?.Route && props?.Route?.slice(1, -1);
  // const [directionsResponse, setDirectionsResponse] = useState<any>(null);
  const [mypolyline, setMyPolyline] = useState<any>([]);
  const [vehicleBearing, setVehicleBearing] = useState<number>(0);
  // const [autoCompleteOrigin, setAutoCompleteOrigin] = useState<any>(null);
  // const [autoCompleteDest, setAutoCompleteDest] = useState<any>(null);    
  // const [origin, setOrigin] = useState<any>("");
  // const [destination, setDestionation] = useState<any>("");
  // const [wayPoint,setWayPoint]=useState<any>([]);
  // const onLoadOrigin = useCallback(function callback(autocomplete) {
  //   setAutoCompleteOrigin(autocomplete);
  // }, []);
  console.log("onLoadOrigin", vehicleBearing);
  // const onLoadDest = useCallback(function callback(autocomplete) {
  //   setAutoCompleteDest(autocomplete);
  // }, []);

  useEffect(() => {
    if (props?.customerDocketLocation?.path?.length) {
      setMyPolyline(props?.customerDocketLocation.path);
    } else if (props.encoded) {
      decodePolyLine(props.encoded?.points.toString());
    }

    if (mypolyline.length > 1) {
      const bearing = calculateBearing(mypolyline[0], mypolyline[1]);
      setVehicleBearing(bearing);
    }
  }, [isLoaded, props?.customerDocketLocation]);
  const theme = useTheme();
  const isMobile1 = useMediaQuery(theme.breakpoints.down('sm')); // Change the breakpoint as needed
  const isMobile2 = useMediaQuery(theme.breakpoints.down('xs')); // Change the breakpoint as needed
  const mobileView = isMobile1 || isMobile2

  function calculateBearing(start: any, end: any) {
    const startLat = (start.lat * Math.PI) / 180;
    const startLng = (start.lng * Math.PI) / 180;
    const endLat = (end.lat * Math.PI) / 180;
    const endLng = (end.lng * Math.PI) / 180;

    const dLng = endLng - startLng;
    const x = Math.sin(dLng) * Math.cos(endLat);
    const y = Math.cos(startLat) * Math.sin(endLat) - Math.sin(startLat) * Math.cos(endLat) * Math.cos(dLng);

    const bearing = Math.atan2(x, y) * (180 / Math.PI);
    return (bearing + 360) % 360; // Normalize to 0-360 degrees
  }


  // function clearRoute() {
  //   setDirectionsResponse(null);
  //   setDistance("");
  //   setDuration("");
  // }

  function decodePolyLine(encoded: any) {
    if (!encoded) {
      return [];
    }
    var poly = [];
    var index = 0, len = encoded.length;
    var lat = 0, lng = 0;
    console.log("decoded pnts", encoded.length)

    while (index < len) {
      var b, shift = 0, result = 0;

      do {
        b = encoded.charCodeAt(index++) - 63;
        result = result | ((b & 0x1f) << shift);
        shift += 5;
      } while (b >= 0x20);

      var dlat = (result & 1) != 0 ? ~(result >> 1) : (result >> 1);
      lat += dlat;

      shift = 0;
      result = 0;

      do {
        b = encoded.charCodeAt(index++) - 63;
        result = result | ((b & 0x1f) << shift);
        shift += 5;
      } while (b >= 0x20);

      var dlng = (result & 1) != 0 ? ~(result >> 1) : (result >> 1);
      lng += dlng;

      var p = {
        lat: lat / 1e5,
        lng: lng / 1e5,
      };
      poly.push(p);
    }
    console.log("decoded polyline ans", poly)
    // console.log("decoded polyline first last", poly[0].lat, poly[poly.length - 1].lat)
    setMyPolyline(poly)
    // setWayPoint(props.Route.slice(1,-1))
    return poly;
  }

  if (!isLoaded) {
    return <FaUserPlus />;
  }

  const icon1 = {
    url: orderData?.assetSubType?.assetType?.topImage,
    scaledSize: new window.google.maps.Size(45, 50),
    anchor: new window.google.maps.Point(20, 10),
    scale: 3,
    rotation: vehicleBearing,
  };


  const OriginMarkerColor = "#157759";
  const DestinationMarkerColor = "#f41919";

  const originPosition = {
    lat: mypolyline[0]?.lat,
    lng: mypolyline[0]?.lng,
  };
  const destinationPosition = {
    lat: mypolyline?.[mypolyline?.length - 1]?.lat,
    lng: mypolyline?.[mypolyline?.length - 1]?.lng,
  };

  const possitionZero = {
    lat: props?.Route && props?.Route[0]?.coordinate?.latitude
    , lng: props?.Route && props?.Route[0]?.coordinate?.longitude
  };
  const possitionOne = {
    lat: props?.Route && props?.Route[1]?.coordinate?.latitude
    , lng: props?.Route && props?.Route[1]?.coordinate?.longitude
  };

  // const icon2 = {
  //   url: "https://img.icons8.com/ios-filled/50/006bb3/marker.png",
  //   scaledSize: new window.google.maps.Size(25, 20),
  //   anchor: new window.google.maps.Point(20, 10),
  //   scale: 3,
  //   strokeColor: "#FFFFFF",
  //   fillColor: "#0000FF",
  //   fillOpacity: 1,
  //   rotation: 215,

  // };
  console.log(props, "props1111111111");
  console.log("nbkinfvkinjv customerTrackingMap mypolyline", mypolyline)
  return (
    <div className={`${props?.className} ${props?.from === "driver" ? "outerContainerForDriverModule" : (mobileView ? "mapContainerForViewShipmentTrackCustomerTrackingMobile h-100" : "mapContainerForViewShipmentTrackCustomerTracking")}`} style={props.style ? props.style : {}}>
      <Box sx={{ border: "0.2px solid transparent", paddingTop: "1px", width: "100%", height: mobileView && props?.from === "customerTrackingMap" || props?.from === "customerBookingMap" ? "100%" : props.mobileMapHeight ? props.mobileMapHeight : "100%", display: "flex", justifyContent: "center" }}>
        {loadError && <p>{loadError}</p>}
        {!isLoaded && <p>Loading .. </p>}
        <GoogleMap
          center={props?.Route && props?.Route?.length > 0 ?
            {
              lat: (props?.Route[0]?.coordinate?.latitude + props?.Route[1]?.coordinate?.latitude) / 2
              , lng: (props?.Route[0]?.coordinate?.longitude + props?.Route[1]?.coordinate?.longitude) / 2
            }
            :
            mypolyline && {
              lat: (mypolyline[0]?.lat + mypolyline[mypolyline.length - 1]?.lat) / 2
              , lng: (mypolyline[0]?.lng + mypolyline[mypolyline.length - 1]?.lng) / 2
            }
          }
          zoom={mobileView && props?.from === "customerBookingMap" ? 13 : zoom}
          mapContainerStyle={{ width: "100%", height: props?.from === "customerTrackingMap" ? "100%" : mobileView && props?.from === "customerBookingMap" ? "100%" : "100%" }}
          options={
            {
              zoomControl: false,
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
              disableDefaultUI: true,
              gestureHandling: "greedy", // Improves usability on mobile
              styles: [
                // Customize map styles
                {
                  featureType: 'poi',
                  elementType: 'labels',
                  stylers: [{ visibility: 'off' }],
                },
                {
                  featureType: 'transit',
                  elementType: 'labels',
                  stylers: [{ visibility: 'off' }],
                },
              ],

            }
          }
        // onLoad={(map) => setMap(map)}
        >
          <>
            {console.log("check poli", mypolyline)}
            {(mypolyline && mypolyline.length > 0) ?
              <>
                <Polyline
                  path={mypolyline}
                  options={{ strokeColor: "#007acc" }}
                />
                {props?.customeised &&
                  <div className="kmAndDistanceInMap">
                    <span> {props?.customeised?.distance} Km </span>
                    <span> {props?.customeised?.duration} </span>
                  </div>
                }
                {orderData?.assetSubType?.assetType?.topImage ?
                  <Marker
                    position={originPosition}
                    title='Your Driver'
                    icon={icon1}
                  />
                  :
                  <OverlayView
                    position={originPosition} // Use the exact position for the marker
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  >
                    <div
                      style={{
                        position: "absolute",
                        transform: "translate(-50%, -100%)", // Center and adjust for pin alignment
                        pointerEvents: "none", // Allow interactions with the map below
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 24 24"
                        fill={OriginMarkerColor} // Set custom color
                      >
                        <path d="M12 2c-3.866 0-7 3.134-7 7 0 4.418 7 13 7 13s7-8.582 7-13c0-3.866-3.134-7-7-7zm0 9.5c-1.378 0-2.5-1.122-2.5-2.5s1.122-2.5 2.5-2.5 2.5 1.122 2.5 2.5-1.122 2.5-2.5 2.5z" />
                      </svg>
                    </div>
                  </OverlayView>
                }
                <OverlayView
                  position={destinationPosition} // Use the exact position for the marker
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                >
                  <div
                    style={{
                      position: "absolute",
                      transform: "translate(-50%, -100%)", // Center and adjust for pin alignment
                      pointerEvents: "none", // Allow interactions with the map below
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 24 24"
                      fill={DestinationMarkerColor} // Set custom color
                    >
                      <path d="M12 2c-3.866 0-7 3.134-7 7 0 4.418 7 13 7 13s7-8.582 7-13c0-3.866-3.134-7-7-7zm0 9.5c-1.378 0-2.5-1.122-2.5-2.5s1.122-2.5 2.5-2.5 2.5 1.122 2.5 2.5-1.122 2.5-2.5 2.5z" />
                    </svg>
                  </div>
                </OverlayView>
                {/* <Marker
                  position={{
                    lat: mypolyline[mypolyline.length - 1].lat
                    , lng: mypolyline[mypolyline.length - 1].lng
                  }}
                  title='Your Driver'
                  icon={icon2}  >
                </Marker> */}

              </>

              :
              <>
                <Polyline
                  path={props?.Route}
                  options={{ strokeColor: "#007acc" }}
                />
                * <Polyline
                  path={props?.Route?.slice(0, 10)}
                  options={{ strokeColor: "var(--clr-danger-400, red)" }}
                />
                {props?.customeised &&
                  <div className="kmAndDistanceInMap">
                    <span> {props?.customeised?.distance} Km </span>
                    <span> {props?.customeised?.duration} </span>
                  </div>
                }
                {/* <div className="mapSuggetions1" >
              {props?.Route[0]?.formatted_address}
            </div> */}
                {orderData?.assetSubType?.assetType?.topImage ?

                  <Marker
                    position={possitionZero}
                    title='Your Driver'
                    icon={icon1}
                  />
                  :
                  <OverlayView
                    position={possitionZero} // Use the exact position for the marker
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  >
                    <div
                      style={{
                        position: "absolute",
                        transform: "translate(-50%, -100%)", // Center and adjust for pin alignment
                        pointerEvents: "none", // Allow interactions with the map below
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 24 24"
                        fill={OriginMarkerColor} // Set custom color
                      >
                        <path d="M12 2c-3.866 0-7 3.134-7 7 0 4.418 7 13 7 13s7-8.582 7-13c0-3.866-3.134-7-7-7zm0 9.5c-1.378 0-2.5-1.122-2.5-2.5s1.122-2.5 2.5-2.5 2.5 1.122 2.5 2.5-1.122 2.5-2.5 2.5z" />
                      </svg>
                    </div>
                  </OverlayView>
                }
                {/* <div className="mapSuggetions2" >
              {props?.Route[1]?.formatted_address}
            </div> */}

                <OverlayView
                  position={possitionOne} // Use the exact position for the marker
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                >
                  <div
                    style={{
                      position: "absolute",
                      transform: "translate(-50%, -100%)", // Center and adjust for pin alignment
                      pointerEvents: "none", // Allow interactions with the map below
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 24 24"
                      fill={DestinationMarkerColor} // Set custom color
                    >
                      <path d="M12 2c-3.866 0-7 3.134-7 7 0 4.418 7 13 7 13s7-8.582 7-13c0-3.866-3.134-7-7-7zm0 9.5c-1.378 0-2.5-1.122-2.5-2.5s1.122-2.5 2.5-2.5 2.5 1.122 2.5 2.5-1.122 2.5-2.5 2.5z" />
                    </svg>
                  </div>
                </OverlayView>
                {/* <Marker
                  position={{
                    lat: props?.Route[1]?.coordinate?.latitude
                    , lng: props?.Route[1]?.coordinate?.longitude
                  }}
                  title='Your Driver'
                  icon={icon2}
                /> */}

                <MarkerClusterer>
                  {(clusterer: any) => (


                    WayPoint && WayPoint.length > 0 &&
                    WayPoint.map((e: any, i: any) => {
                      return (
                        <>
                          <Marker
                            position={{
                              lat: e.coordinate?.latitude
                              , lng: e.coordinate?.longitude
                            }}
                            title='Destination'
                            label={{ text: lebelArr[i + 1], color: "white" }}
                            clusterer={clusterer}
                            icon={{
                              url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                              fillColor: "#007acc",
                              scaledSize: new window.google.maps.Size(50, 50),

                            }}
                          />
                          <OverlayView
                            position={possitionZero} // Use the exact position for the marker
                            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                          >
                            <div
                              style={{
                                position: "absolute",
                                transform: "translate(-50%, -100%)", // Center and adjust for pin alignment
                                pointerEvents: "none", // Allow interactions with the map below
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                viewBox="0 0 24 24"
                                fill={OriginMarkerColor} // Set custom color
                              >
                                <path d="M12 2c-3.866 0-7 3.134-7 7 0 4.418 7 13 7 13s7-8.582 7-13c0-3.866-3.134-7-7-7zm0 9.5c-1.378 0-2.5-1.122-2.5-2.5s1.122-2.5 2.5-2.5 2.5 1.122 2.5 2.5-1.122 2.5-2.5 2.5z" />
                              </svg>
                              <span
                                style={{
                                  display: "block",
                                  color: "white",
                                  background: "#007acc",
                                  padding: "2px 5px",
                                  borderRadius: "3px",
                                  marginTop: "5px",
                                  fontSize: "12px",
                                }}
                              >
                                {lebelArr[0]}
                              </span>
                            </div>
                          </OverlayView>
                          <OverlayView
                            position={possitionOne} // Use the exact position for the marker
                            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                          >
                            <div
                              style={{
                                position: "absolute",
                                transform: "translate(-50%, -100%)", // Center and adjust for pin alignment
                                pointerEvents: "none", // Allow interactions with the map below
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                viewBox="0 0 24 24"
                                fill={DestinationMarkerColor} // Set custom color
                              >
                                <path d="M12 2c-3.866 0-7 3.134-7 7 0 4.418 7 13 7 13s7-8.582 7-13c0-3.866-3.134-7-7-7zm0 9.5c-1.378 0-2.5-1.122-2.5-2.5s1.122-2.5 2.5-2.5 2.5 1.122 2.5 2.5-1.122 2.5-2.5 2.5z" />
                              </svg>
                              <span
                                style={{
                                  display: "block",
                                  color: "white",
                                  background: "#007acc",
                                  padding: "2px 5px",
                                  borderRadius: "3px",
                                  marginTop: "5px",
                                  fontSize: "12px",
                                }}
                              >
                                {lebelArr[props?.Route.length - 1]}
                              </span>
                            </div>
                          </OverlayView>

                          {/* <Marker
                            position={{
                              lat: props?.Route[0]?.coordinate?.latitude
                              , lng: props?.Route[0]?.coordinate?.longitude
                            }}
                            title='Origin'
                            label={{ text: lebelArr[0], color: "white" }}

                          />
                          <Marker
                            position={{
                              lat: props?.Route[1]?.coordinate?.latitude
                              , lng: props?.Route[1]?.coordinate?.longitude
                            }}
                            title='Destination'
                            label={{ text: lebelArr[props?.Route.length - 1], color: "white" }}
                          /> */}
                        </>)
                    })
                  )}
                </MarkerClusterer>

              </>

            }

          </>
        </GoogleMap>
      </Box>
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  customerDocketLocation: state.liveLocation.customerDocketLocation,
});

const mapDispatchToProps = {
  showLoader,
  hideLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewShipmentTrack);
// export default ViewShipmentTrack;
