import React, {
  Children,
  cloneElement,
  ComponentProps,
  createContext,
  isValidElement,
  ReactNode,
  useContext,
  useState,
} from "react";
import { DownIcon, UpIcon } from "../../../constants/icons";
import { CSSTransition } from "react-transition-group";
import "./accordion.css";

// Define AccordionContext Type
type AccordionContextType = {
  isAccordionOpen: boolean;
  toggleAccordion: () => void;
};

// Define AccordionProps Type
type AccordionProps = {
  children: ReactNode;
  index?: number;
} & ComponentProps<"div">;

// Create Context with default value null
const AccordionContext = createContext<AccordionContextType | null>(null);

// Accordion Component
export function Accordion({
  children,
  className = "",
  index,
  ...props
}: AccordionProps) {
  const groupContext = useContext(AccordionGroupContext);
  const isAccordionOpen = groupContext ? groupContext.openIndex === index : false;
  const toggleAccordion = () => groupContext?.toggleAccordion(index!);

  const [isLocalOpen, setIsLocalOpen] = useState(false);
  const localToggle = () => setIsLocalOpen((prev) => !prev);

  const effectiveIsOpen = groupContext ? isAccordionOpen : isLocalOpen;
  const effectiveToggle = groupContext ? toggleAccordion : localToggle;

  return (
    <AccordionContext.Provider value={{ isAccordionOpen: effectiveIsOpen, toggleAccordion: effectiveToggle }}>
      <div className={`taptap-accordion flex-col border-white br-4 ${className}`} {...props}>
        {Children.map(children, (child) => {
          if (isValidElement(child)) {
            return cloneElement(child);
          }
          return null;
        })}
      </div>
    </AccordionContext.Provider>
  );
}

// AccordionSummary Component
type AccordionSummaryProps = {
  children: ReactNode;
} & ComponentProps<"div">;

export function AccordionSummary({
  children,
  className = "",
  onClick,
  ...props
}: AccordionSummaryProps) {
  const context = useContext(AccordionContext);

  if (!context) {
    throw new Error("AccordionSummary must be used within an Accordion");
  }

  const { isAccordionOpen, toggleAccordion } = context;

  return (
    <div
      className={`taptap-accordion-summary flex-between gap-1 cursor-pointer p-1 ${className}`}
      onClick={(e) => {
        toggleAccordion();
        onClick?.(e);
      }}
      {...props}
    >
      {children}
      <span className="ml-auto flex-center pointer user-select-none">
        {isAccordionOpen ? <UpIcon /> : <DownIcon />}
      </span>
    </div>
  );
}

// AccordionDetails Component
type AccordionDetailsProps = {
  children: ReactNode;
} & ComponentProps<"div">;

export function AccordionDetails({
  children,
  className = "",
  ...props
}: AccordionDetailsProps) {
  const context = useContext(AccordionContext);

  if (!context) {
    throw new Error("AccordionDetails must be used within an Accordion");
  }

  const { isAccordionOpen } = context;

  return (
    <CSSTransition
      in={isAccordionOpen} // Controls the visibility of the details
      timeout={300} // Duration of the transition
      classNames="taptap-accordion" // Matches the CSS class prefix
      unmountOnExit // Unmount when not open
    >
      <div className={`${className} taptap-accordion-details pt-0 p-1`} {...props}>
        {children}
      </div>
    </CSSTransition>
  );
}

// AccordionGroup Context
type AccordionGroupContextType = {
  openIndex: number | null;
  toggleAccordion: (index: number) => void;
};

const AccordionGroupContext = createContext<AccordionGroupContextType | null>(null);

// AccordionGroup Component
// type AccordionGroupProps = {
//   children: ReactNode;
// } & ComponentProps<"div">;

// export function AccordionGroup({ children, className = "", ...props }: AccordionGroupProps) {
//   const [openIndex, setOpenIndex] = useState<number | null>(null);

//   const toggleAccordion = (index: number) => {
//     setOpenIndex((prev) => (prev === index ? null : index));
//   };

//   return (
//     <AccordionGroupContext.Provider value={{ openIndex, toggleAccordion }}>
//       <div className={`taptap-accordion-group ${className}`} {...props}>
//         {Children.map(children, (child, index) => {
//           if (isValidElement(child)) {
//             return cloneElement(child as React.ReactElement<{ index: number }>, { index });
//           }
//           return null;
//         })}
//       </div>
//     </AccordionGroupContext.Provider>
//   );
// }


type AccordionGroupProps = {
  children: ReactNode;
  defaultOpenIndex?: number | null; // New prop to specify the default open accordion
} & ComponentProps<"div">;

export function AccordionGroup({
  children,
  defaultOpenIndex = null, // Default value is null (no accordion open initially)
  className = "",
  ...props
}: AccordionGroupProps) {
  const [openIndex, setOpenIndex] = useState<number | null>(defaultOpenIndex); // Initialize with defaultOpenIndex

  const toggleAccordion = (index: number) => {
    setOpenIndex((prev) => (prev === index ? null : index));
  };

  return (
    <AccordionGroupContext.Provider value={{ openIndex, toggleAccordion }}>
      <div className={`taptap-accordion-group ${className}`} {...props}>
        {Children.map(children, (child, index) => {
          if (isValidElement(child)) {
            return cloneElement(child as React.ReactElement<{ index: number }>, { index });
          }
          return null;
        })}
      </div>
    </AccordionGroupContext.Provider>
  );
}

AccordionSummary.displayName = "AccordionSummary";
AccordionDetails.displayName = "AccordionDetails";
