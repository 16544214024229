// react
import React from "react";
// react-router-dom
import { Link, useNavigate } from "react-router-dom";
// constants (images)
import {
  appleStoreImg,
  facebookImg,
  instagramImg,
  kabraExpressImg,
  linkedinImg,
  playStoreImg,
  youtubeImg,
} from "../../../../constants/images";
// css
import "./footer.css";

// variables
const footerLinks = [
  { img: facebookImg, link: "https://www.facebook.com/KabraExpressLogistics" },
  { img: instagramImg, link: "https://www.instagram.com/kabra.express/" },
  {
    img: linkedinImg,
    link: "https://www.linkedin.com/company/kabra-express-logistics-private-limited/",
  },
  {
    img: youtubeImg,
    link: "https://www.youtube.com/channel/UCt4wx_zJAyQ-el--Jy3iurQ",
  },
];
const footerDownloadLinks = [
  {
    img: playStoreImg,
    link: "https://play.google.com/store/apps/details?id=in.taptap.twa&pcampaignid=web_share",
  },
  {
    img: appleStoreImg,
    link: "https://play.google.com/store/apps/details?id=in.taptap.twa&pcampaignid=web_share",
  },
];
const resourceList = [
  { value: "Privacy Policy", href: "privacy-policy" },
  { value: "User Agreement", href: "user-agreement" },
  { value: "T&C", href: "t&c" },
];
const othersList = [
  { value: "About Us", href: "about-us" },
  { value: "Contact Us", href: "contact-us" },
  { value: "Careers", href: "careers" },
  { value: "GST Details", href: "gst-details" },
  { value: "Our Network", href: "our-network" },
  { value: "Others", href: "#" },
];

// FC: Footer Component
const Footer = () => {
  // useHistory
  const navigate = useNavigate();

  // component return
  return (
    <footer className="footer | bg-white-900 p-0">
      <div className="| flex-col gap-2 jc-between h-100">
        {/* Logo */}
        <div className="| flex-col gap-1 p-3 my-container">
          {/* <img src={logoImg} alt="logo-img" style={{ maxWidth: "150px", marginInline: "auto" }} /> */}
          <h1
            onClick={() => navigate("/", {
              replace: true
            })}
            className="fs-logo text-primary-400 fw-800 text-center pointer"
          >
            TapTap
          </h1>
          <p
            style={{ maxWidth: "1920px" }}
            className="| m-0 text-neutral-900 text-justify fw-500 fs-list-item-text"
          >
            Taptap.in is a two sided platform for transporters and customers.
            Transporters can use it to get business and process orders
            efficiently and effectively. They can even collaborate with other
            transporters seamlessly to expand their network. Whereas customers
            can book various kinds of transportation service and track their
            orders while taking advantage of various supportive services.
          </p>
          {/* <p style={{ maxWidth: "1920px" }} className="| m-0 text-neutral-900 text-justify fw-500 fs-body"></p>
          <p style={{ maxWidth: "1920px" }} className="| m-0 text-neutral-900 text-justify fw-500 fs-body"></p> */}
        </div>
        <div className="| p-3 m-py-20 bg-primary-400 flex-between gap-1 flex-wrap m-flex-col m-ai-stretch">
          {/* List */}
          <div className="| flex flex-wrap gap-2 text-neutral-900 m-flex-evenly m-ai-start">
            {/* Resources */}
            <ul className="| ">
              {/* <li className="| fs-list-item-text fw-700 text-white-900">
                Resources
              </li> */}
              {resourceList.map((item) => (
                <li key={item.value} className="| pointer">
                  <Link
                    className="text-white-700 fs-list-item-text fw-400 hover-underline"
                    to={`/${item.href}`}
                  >
                    {item.value}
                  </Link>
                </li>
              ))}
            </ul>
            {/* Others */}
            <ul className="|">
              {/* <li className="| fs-list-item-text fw-700 text-white-900">
                Others
              </li> */}
              {othersList.map((item) => (
                <li key={item.value} className="| pointer">
                  <Link
                    className="text-white-700 fs-list-item-text fw-400 hover-underline"
                    to={`/${item.href}`}
                  >
                    {item.value}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          {/* Social Media Links */}
          <div className="| flex-col gap-8 p-1 m-p-0">
            <ul className="| flex-between gap-4 m-px-30 m-py-10">
              {footerLinks.length > 0 &&
                footerLinks.map((item) => (
                  <li key={item.link} className="">
                    <a href={item.link} target="_blank">
                      <img
                        loading="lazy"
                        src={item.img}
                        alt="img"
                        className="| pointer"
                      />
                    </a>
                  </li>
                ))}
            </ul>
            <ul className="| flex gap-4 m-flex-evenly flex-between m-px-30 m-py-10">
              {footerDownloadLinks.length > 0 &&
                footerDownloadLinks.map((item) => (
                  <a key={item.img} href={item.link} target="_blank" style={{ maxWidth: "250px" }}>
                    <img
                      loading="lazy"
                      src={item.img}
                      alt="img"
                      className="| pointer"
                      style={{ maxWidth: "250px" }}
                    />
                  </a>
                ))}
            </ul>
            <a href="https://www.kabraexpress.com/" target="_blank" className="text-center">
              <img
                loading="lazy"
                src={kabraExpressImg}
                alt="Kabra Express"
                className="| br-4 shadow-small text-center"
                style={{ maxWidth: "315px" }}
              />
            </a>
          </div>
        </div>
      </div>
      <section className="bg-white-800 p-1 text-center text-primary-400 fw-400 fs-small">
        Copyright © 2024 TapTap®. All rights reserved.
      </section>
    </footer>
  );
};

export default Footer;
