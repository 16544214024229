import React, { Dispatch, FunctionComponent, SetStateAction } from "react";
import "./dropdown.css";
import { useLandingPageContext } from "../../../../hooks/useLandingPageContext";
import { LockIcon } from "../../../../constants/icons";

type DropdownProps = {
  item: "Intercity Express Parcel" | "Part Load & Full Load";
  index: number;
  dialogOpen: number;
  setDialogOpen: Dispatch<SetStateAction<number>>;
  items: {
    value: string; Icon: FunctionComponent;
    isLocked: boolean;
    onClick: () => void
  }[];
};

const Dropdown = ({
  item,
  index,
  items,
  dialogOpen,
  setDialogOpen,
}: DropdownProps) => {
  const { activeService } = useLandingPageContext();

  const toggleDropdown = () => {
    if (dialogOpen === index) {
      setDialogOpen(-1);
    } else {
      setDialogOpen(index);
    }
  };

  const handleMouseEnter = () => {
    console.log("first");
    setDialogOpen(index);
  };

  const handleMouseLeave = () => {
    setDialogOpen(-1);
  };

  console.log(item, activeService, item === activeService);

  if (dialogOpen === index) {
    return (
      <div
        className="dd-menu shadow-small"
        onClick={toggleDropdown}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          transform: item === activeService ? "translateY(6px)" : "initial",
        }}
      >
        {items.length > 0 &&
          items.map((Item, index) => {
            return (
              <div
                key={index}
                className="dd-item"
                onClick={(e) => {
                  e.stopPropagation();
                  Item.onClick();
                  setDialogOpen(-1);
                }}
              >
                <Item.Icon /> {Item.value}
                {Item?.isLocked &&
                  <span className="navigation-lock-icon">
                    <LockIcon />
                  </span>
                }
              </div>
            );
          })}
      </div>
    );
  } else return null;
};

export default Dropdown;
