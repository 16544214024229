import { facebookImg, instagramImg, linkedinImg, youtubeImg } from "./images";

type HeaderNavListItemType = {
  id: number;
  value: "services" | "partners" | "customer";
  href: string;
  variant:
    | "neutral"
    | "primary"
    | "secondary"
    | "accent"
    | "danger"
    | "warning"
    | "success"
    | "info";
  action: "primary" | "secondary" | "tertiary";
};

export const headerNavListItems: HeaderNavListItemType[] = [
  {
    id: 0,
    value: "services",
    href: "customer",
    variant: "primary",
    action: "primary",
  },
  {
    id: 1,
    value: "partners",
    href: "partners",
    variant: "primary",
    action: "tertiary",
  },
  {
    id: 2,
    value: "customer",
    href: "customer",
    variant: "primary",
    action: "tertiary",
  },
];

export const camerstatusRemarksItems = [
  {
    label : "No Camera System",
    value : "No Camera System",
  },
  {
    label : "Camera Issues / Wifi Issues",
    value : "Camera Issues / Wifi Issues",
  },
  {
    label : "Power Nhi Hai",
    value : "Power Nhi Hai",
  },
  {
    label : "Connectivity Issues",
    value : "Connectivity Issues",
  },
  {
    label : "Others",
    value : "Others",
  },
];

export const headerMobileMenuListItems = [
  {
    id: 1,
    value: "About Us",
    href: "about-us",
    isDropdown: false,
  },
  {
    id: 2,
    value: "Services",
    isDropdown: true,
    dropdownItems: [
      {
        id: 1,
        value: "Intracity",
        isDropdown: false,
      },
      {
        id: 2,
        value: "Intercity Express Parcel",
        isDropdown: true,
        dropdownItems: [
          {
            id: 1,
            value: "Branch to Branch",
            isDropdown: false,
          },
          {
            id: 2,
            value: "Door Delivery",
            isDropdown: false,
          },
        ]
      },
      {
        id: 3,
        value: "Part Load & Full Load",
        isDropdown: true,
        dropdownItems: [
          {
            id: 1,
            value: "Part Load",
            isDropdown: false,
          },
          {
            id: 2,
            value: "Full Load",
            isDropdown: false,
          },
        ]
      },
      {
        id: 4,
        value: "Packers & Movers",
        isDropdown: false,
      },
      {
        id: 5,
        value: "International",
        isDropdown: false,
      },
    ],
  },
  {
    id: 3,
    value: "Partners",
    isDropdown: true,
    dropdownItems: [
      {
        id: 1,
        value: "Direct Sales Agent",
        isDropdown: false,
      },
      {
        id: 2,
        value: "Delivery Partner",
        isDropdown: false,
      },
      {
        id: 3,
        value: "Transport Company",
        isDropdown: false,
      },
      {
        id: 4,
        value: "Warehouse",
        isDropdown: false,
      },
      {
        id: 5,
        value: "Investors",
        isDropdown: false,
      },
    ],
  },
  {
    id: 4,
    value: "Customer",
    isDropdown: false,
  },
  {
    id: 5,
    value: "Our Networks",
    href: "our-network",
    isDropdown: false,
  },
  {
    id: 6,
    value: "Others",
    isDropdown: true,
    dropdownItems: [
      {
        id: 1,
        value: "Careers",
        href: "careers",
        isDropdown: false,
      },
      {
        id: 2,
        value: "Insurance",
        isDropdown: false,
        isComingSoon: true,
      },
      {
        id: 3,
        value: "Store",
        isDropdown: false,
        isComingSoon: true,
      },
      {
        id: 4,
        value: "Buy/Sell Vehicle",
        isDropdown: false,
        isComingSoon: true,
      },
    ],
  },
  {
    id: 7,
    value: "Resources",
    isDropdown: true,
    dropdownItems: [
      {
        id: 1,
        value: "Privacy Policy",
        href: "privacy-policy",
        isDropdown: false,
      },
      {
        id: 2,
        value: "User Agreement",
        href: "user-agreement",
        isDropdown: false,
      },
      {
        id: 3,
        value: "T&C",
        href: "t&c",
        isDropdown: false,
      },
      // {
      //     id: 4,
      //     value: "FAQ",
      //     isDropdown: false,
      // },
      // {
      //     id: 5,
      //     value: "Blog",
      //     isDropdown: false,
      // },
    ],
  },
];
export const landingPageSidebarItems = [
  "Book Now",
  "Features",
  "How It Works",
  "Testimonails",
  "FAQs",
] as const;

export type LandingPageMainNavItem = (typeof landingPageMainNavItem)[number];
export type CustomerBookingNavItemType =
  (typeof customerBookingNavItem)[number];
export const landingPageMainNavItem = [
  "Intracity",
  "Intercity Express Parcel",
  "Part Load & Full Load",
  "Packers & Movers",
  "International",
] as const;
export const customerBookingNavItem = [
  "Intracity",
  "Intercity Express Parcel",
  "Part Load",
  "Full Load",
  "Packers & Movers",
  "International",
] as const;
export const customerHistoryNavItem = ["Online", "Branch"] as const;
export type CustomerHistoryNavItem = (typeof customerHistoryNavItem)[number];
export const customerSubHistoryNavItem = [
  "Upcoming",
  "OnGoing",
  "Completed",
  "Cancelled",
  "MonthWise",
] as const;
export type CustomerSubHistoryNavItem =
  (typeof customerSubHistoryNavItem)[number];
export const branchSubHistoryNavItem = [
  "OnGoing",
  "Completed",
  "Cancelled",
  "MonthWise",
] as const;
export type BranchSubHistoryNavItem = (typeof branchSubHistoryNavItem)[number];
export type LandingPagePartnersNavItem =
  (typeof landingPagePartnersNavItem)[number];
export const landingPagePartnersNavItem = [
  "Direct Sales Agent",
  "Delivery Partner",
  "Transport Company",
  "Warehouse",
  "Investors",
] as const;

export const mobileLandingPageCustomerListItem = [
  {
    id: 0,
    value: "Intracity",
    img: "/assets/landing-page/customer-service/intra-city.webp",
    description:
      "Delivery your package within city on demand by bike, tempo & bolero",
  },
  {
    id: 1,
    value: "Intercity Express Parcel",
    img: "/assets/landing-page/customer-service/domestic.jpg",
    description:
      "Delivery your packages to another cities with options such as branch delivery and door delivery",
  },
  {
    id: 2,
    value: "Part Load & Full Load",
    img: "/assets/landing-page/customer-service/part-load.jpg",
    description:
      "Delivery your less than truck load to another cities with door pickup and door delivery service",
  },
  // {
  //     id: 3,
  //     value: "Full Load",
  //     img: "/assets/landing-page/customer-service/full-load.webp",
  //     description: "Delivery your full truck load to another cities on demand by selecting from wide range of vehicles",
  // },
  {
    id: 3,
    value: "Packers & Movers",
    img: "/assets/landing-page/customer-service/packers-and-movers.jpg",
    description:
      "Get your home and office appliances, furniture and fixture packed, transported and unpacked to a new place",
  },
  {
    id: 4,
    value: "International",
    img: "/assets/landing-page/customer-service/international.jpg",
    description:
      "Delivery your package in other countries through flight or frieght ship by getting quotation from us",
  },
] as const;

type MobileLandingPagePartnerListItem = {
  id: number;
  value: (typeof landingPagePartnersNavItem)[number];
  img: string;
  description: string;
};
export const mobileLandingPagePartnerListItem: MobileLandingPagePartnerListItem[] =
  [
    {
      id: 0,
      value: "Direct Sales Agent",
      img: "/assets/landing-page/customer-service/intra-city.webp",
      description:
        "Sign Up & Start earning by helping transporters understand how to use the platform and support customers to book various kind of transportation service",
    },
    {
      id: 1,
      value: "Delivery Partner",
      img: "/assets/landing-page/customer-service/domestic.jpg",
      description:
        "Sign up and start earning by delivery intracity & intercity orders by your fleet with flexible working hours",
    },
    {
      id: 2,
      value: "Transport Company",
      img: "/assets/landing-page/customer-service/part-load.jpg",
      description:
        "Sign up to manage your transport business digitally turning to strong processes and higher customer satisfaction. Additionally earn extra revenue by being part of the network get more business from other transporters and expand delivery network for your customers",
    },
    {
      id: 3,
      value: "Warehouse",
      img: "/assets/landing-page/customer-service/full-load.webp",
      description:
        "Sign up and make your warehouse spaces available to platform's customers and transporters",
    },
    {
      id: 4,
      value: "Investors",
      img: "/assets/landing-page/customer-service/packers-and-movers.jpg",
      description:
        "Sign up and make taptap.in live for your city's transporters empowering them with latest technology & help customers book various kind of transportation services",
    },
  ];

export const aboutUs = {
  headings: [
    "Kabra Logitech Private Limited provides an efficient and cost-effective solution, helping businesses save time and resources.",
    "We automate and streamline logistics processes by providing businesses with an efficient ERP solution.",
    "With over two decades of experience in the logistics industry, Kabra Logitech is your go- to choice for innovative and effective ERP solutions.",
    "Our company's core product, «TapTap» helps businesses manage their inventory, shipping, and tracking information in a single platform.",
  ],
  headline:
    "TAP TAP- An ERP software that takes care of the needs of all the agents. One can track the route of their logistics services along with the income and expenses. This would help the client in having all the data in one place making work hassle-free. Kabra Logitech Private Limited provides an efficient and cost-effective solution, helping businesses save time and resources. We automate and streamline logistics processes by providing businesses with an efficient ERP solution. With over two decades of experience in the logistics industry, Kabra Logitech is your go- to choice for innovative and effective ERP solutions.",
  team: [
    {
      id: 0,
      name: "Chintan Kabra",
      title: "Chief Executive Officer",
      linkedin: "#",
      twitter: "#",
      mail: "#",
      img: "/assets/about-us/team/placeholder.jpg",
    },
    {
      id: 1,
      name: "Jay Patel",
      title: "Full Stack Developer",
      linkedin: "#",
      twitter: "#",
      mail: "#",
      img: "/assets/about-us/team/placeholder.jpg",
    },
    {
      id: 2,
      name: "Prashant Bhatiya",
      title: "React JS Developer",
      linkedin: "#",
      twitter: "#",
      mail: "#",
      img: "/assets/about-us/team/placeholder.jpg",
    },
    {
      id: 3,
      name: "Vraj Patel",
      title: "React JS Developer",
      linkedin: "#",
      twitter: "#",
      mail: "#",
      img: "/assets/about-us/team/placeholder.jpg",
    },
    {
      id: 4,
      name: "Pankaj Gautam",
      title: "React JS Developer",
      linkedin: "#",
      twitter: "#",
      mail: "#",
      img: "/assets/about-us/team/placeholder.jpg",
    },
    {
      id: 5,
      name: "Uttam Makwana",
      title: "React JS Developer",
      linkedin: "#",
      twitter: "#",
      mail: "#",
      img: "/assets/about-us/team/placeholder.jpg",
    },
    {
      id: 6,
      name: "Dhruvi Prajapati",
      title: "HR Executive",
      linkedin: "#",
      twitter: "#",
      mail: "#",
      img: "/assets/about-us/team/placeholder.jpg",
    },
  ],
  facts: [
    {
      id: 0,
      title: "Clients",
      number: "10000+",
    },
    {
      id: 1,
      title: "Transporters",
      number: "1000+",
    },
    {
      id: 2,
      title: "Vehicles",
      number: "20000+",
    },
    {
      id: 3,
      title: "Branches",
      number: "100+",
    },
  ],
  products: [
    {
      id: 0,
      title: "TapTap for Customer",
      description:
        "Become a customer and access our premium services such as Intra City, Domestic, Part Load, Full Load.",
    },
    {
      id: 1,
      title: "TapTap for Transporter",
      description:
        "Become a trasnporter and access our premium services such as Intra City, Domestic, Part Load, Full Load.",
    },
  ],
  missionAndVision: [
    {
      title: "Our Vision",
      description:
        "Our vision is to make TapTap the foremost choice for logistics services in the nation, offering unmatched solutions and an exceptional delivery experience.",
    },
    {
      title: "Our Mission",
      description:
        "Our mission at TapTap is to excel as the most cost-effective service provider and capital-efficient company while simultaneously establishing our position as the foremost logistics partner within the industry.",
    },
  ],
  coreValues: [
    {
      id: 0,
      title: "Integrity",
      description:
        "Upholding unwavering honesty and ethical principles, fostering trust and respect",
    },
    {
      id: 1,
      title: "Passion for Excellence",
      description:
        "Relentlessly pursuing the highest standards, igniting growth and innovation",
    },
    {
      id: 2,
      title: "Innovation & Learning",
      description:
        "Embracing change, fueling growth through continuous exploration and improvement",
    },
    {
      id: 3,
      title: "Customer First",
      description:
        "Dedication to exceed expectations, delivering exceptional solutions and experiences",
    },
    {
      id: 4,
      title: "Confidence & Respect",
      description:
        "Valuing self-assurance and treating all with dignity and esteem",
    },
    {
      id: 5,
      title: "Building a community",
      description:
        "Fostering connections, collaboration, and shared growth through unity",
    },
  ],
};

export const contactUs = {
  information: [
    {
      id: 0,
      title: "Location",
      description:
        "02/13 Vallabh Flats, Vijay Chowk, NR Sardar Patel Diamond Market, Bapunagar, Ahmedabad, Gujarat 380024",
    },
    {
      id: 1,
      title: "Email",
      description: "customersupport@kabraexpress.com",
    },
    {
      id: 2,
      title: "Contact Number",
      description: "+917575857777\n+91 9099982886",
    },
  ],
};

export const careers = {
  options: [
    {
      id: 0,
      title: "React JS Developer",
      description: "xyz",
      location: "Prahlad Nagar",
      isOpening: false,
      tag: "IT",
    },
    {
      id: 1,
      title: "Node JS Developer",
      description: "xyz",
      location: "Prahlad Nagar",
      isOpening: false,
      tag: "IT",
    },
    {
      id: 2,
      title: "UI/UX Developer",
      description: "xyz",
      location: "Prahlad Nagar",
      isOpening: false,
      tag: "IT",
    },
    {
      id: 3,
      title: "DevOps Developer",
      description: "xyz",
      location: "Prahlad Nagar",
      isOpening: false,
      tag: "IT",
    },
    {
      id: 4,
      title: "Full Stack Developer",
      description: "xyz",
      location: "Prahlad Nagar",
      isOpening: false,
      tag: "IT",
    },
    {
      id: 5,
      title: "Software Tester",
      description: "xyz",
      location: "Prahlad Nagar",
      isOpening: false,
      tag: "IT",
    },
    {
      id: 6,
      title: "Branch Manager",
      description: "xyz",
      location: "Bapunagar, Ahmedabad",
      isOpening: false,
      tag: "Branch Manager",
    },
    {
      id: 7,
      title: "Branch Executive",
      description: "xyz",
      location: "Bapunagar, Ahmedabad",
      isOpening: false,
      tag: "Branch Executive",
    },
    {
      id: 8,
      title: "Tele Calling Support",
      description: "xyz",
      location: "Bapunagar, Ahmedabad",
      isOpening: false,
      tag: "Tele Calling Support",
    },
    {
      id: 9,
      title: "Fleet Driver",
      description: "xyz",
      location: "Bapunagar, Ahmedabad",
      isOpening: false,
      tag: "Fleet Driver",
    },
    {
      id: 10,
      title: "Branch Labour",
      description: "xyz",
      location: "Bapunagar, Ahmedabad",
      isOpening: false,
      tag: "Branch Labour",
    },
  ],
};

export const termsAndConditions = [
  "It's deemed that consignor/consignee has read all the terms & conditions and have agreed mutually before booking goods for transportation through us.",
  "Goods/Parcels are items handed over for transportation.",
  "Consignor is the sender of goods/Parcels.",
  "Consignee is receiver of goods/Parcels.",
  "Consignment note is receipt issued to consignor at the time of booking, and we the transporter is the entity that will transport goods/parcels.",
  "It is presumed that consignor has kept original invoice and other legal documents required for transportation within the goods.",
  "It is of consignor's & consignee's responsibility to properly and adequately pack goods required as per its type & nature. Kabra is not liable for any kind of leakage, breakage, pilferage and/or spoilage of goods.",
  "We don't support transportation of perishable goods.",
  "In Case of any transportation of perishable goods, Kabra would not be responsible for any loss/damage caused.",
  "We only support Branch to Branch and Branch-Door good Service.",
  "We transport all goods booked by us through road. We won't be responsible for any loss/damage occurred to consignor and/or consignee due to uncontrollable events such as riots, earthquake, theft, accident, fire, floods, etc. It's advisable to get marine insurance for goods/parcels.",
  "Delivery of goods shall be taken within three days after their arrival at delivery destination, failing which the consignee/consignor will be liable to pay additional demurrage charge (Demurrage refers to the charge that the merchant pays for the use of the container within the terminal beyond the free time period) at Rs.20/- per day per goods/parcels.",
  "If any Competent Govt. authority detains goods, we won't be responsible for the same and consignor/consignee shall indemnify us for any expenses, losses, damages suffered by the company. Consignor/consignee would be responsible to obtain all the permit required before booking and shall provide us requisite documents for transportation of goods.",
  "In the event of loss of consignment note goods might won't be delivered and consignor/consignee shall have no objection if the company delivers the goods directly to consignee on the basis consignment number or name of consignee as mentioned on consignment note.",
  "Consignor of the goods would be responsible to declare the actual value, and description of goods at the time of booking. We are not aware about the value, nature, type and description of goods given for transport.",
  "We at Kabra would be Liable for any kind of loss/damage/missing of goods. It is restricted to freight value charged on consignment note or Rs.500 whichever is less.",
  "We are not Transporting any goods which are prohibited by law in India, goods hazardous in nature and/or are dangerous to human life. Consignor shall not book any of such goods for transport and will be solely responsible.",
  "If the delivery is not taken from our warehouse within 15 days from the date of booking than we are not responsible for such goods and we have right to sell the goods to recover the total charges due as per consignment note.",
  "GST is applicable on total freight amount including other charges exceeding Rs.750 and its responsibility of the service receiver to pay tax as per reverse charge mechanism of section 9 (3) of CGST/SGST Act 2017.",
  "Any misinformation on consignment note shall be intimidated to our head office as soon as possible.",
  "Any complaint related to transportation of goods shall be communicated to our head office within seven days.",
  "Any dispute will be subject to Ahmedabad Jurisdiction.",
];

export const termsAndConditionsCustomerBooking = [
  "Welcome to Taptap. If you continue to browse and use our app/website you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern Kabra Express Logitech Private Limited's (Owner of Taptap) relationship with you in relation to this app/website and the Services (as described below).",
  "In the event there is a conflict between the terms and conditions specified herein and the provisions of any other document executed between the parties hereto, the terms and conditions specified herein would prevail.",
  "DEFINITIONS",
  "\"We\", \"us\", \"our\", \"Carrier\", \"Taptap\", \"Company\" shall refer to Kabra Express Logitech Private Limited (owner of Taptap), its employees, and its service providers that carry or undertake to carry the consignment hereunder or perform any other services incidental thereto on its behalf.",
  "“You”, “your”, “consignor” shall refer to the sender, consignor or consignee of the Consignment, holder of this Consignment Note, receiver and owner of the contents of the Consignment or any other party having a legal interest in those contents, as the case may be.",
  "“Services” means the services provided by Taptap which enable the following:",
  "The services of goods transport of vehicles by Customers for a point-to-point service, or for time and usage based service within city limits and outside the city limits, including inter-city all over India;",
  "Hiring of labour by Customers for loading/unloading of the consignment;",
  "Such other services which Taptap may from time to time add.",
  "\"Carriage\" means and includes the whole of the operations and services undertaken by us in connection with the Consignment.",
  "“Consignment” means goods defined herein which are listed on the consignment note and are handed over by the Customer for carriage by Taptap.",
  // Add additional content if needed,
"“Dangerous Goods” means goods classified as dangerous as per ICAO T.I., IATA DGR, IMDG-Code, ADR or other national regulations for transport.", "“Delivery” means the tender of the consignment to the consignee or intimation about the arrival of the consignment.", "\"Prohibited Items\" means any goods or materials, the Carriage of which is prohibited by Applicable Law.", "“Receiver” or “Consignee” shall refer to the recipient or addressee or the consignee of the Consignment.", "“Applicable Law” means all laws, statutes, ordinance, regulations, guidelines, policies, rules, bye-laws, notifications, directions, directives and orders or other governmental restrictions or any similar form of decision of, or determination by, or any interpretation, administration and other pronouncements having the effect of law of the Republic of India or any other applicable jurisdiction by state, municipality, court, tribunal, government, ministry, department, commission, arbitrator or board or such other body which has the force of law in India." ,

  "USER(S) ELIGIBILITY",
  "User(s) means any individual or business entity/organization that legally operates in India or in other country, uses and has the right to use the Services provided by Taptap.",
  "It is hereby being clarified that our Services are available only to those individuals or business entities/organizations who can form legally binding contracts under the Applicable Law.",
  "To be a User(s) and be eligible to use our Services, an individual must be at least 18 (eighteen) years of age.",
  "Taptap advises its users that while accessing the website, they must follow/abide by the related laws.",
  "Taptap is not responsible for the possible consequences caused by your behavior/acts during use of the website.",
  "Taptap may, in its sole discretion, refuse the service to anyone at any time.",

  "USER(S) AGREEMENT",
  "This agreement applies to user(s) if user(s) are visitors, registered - free or paid user(s) who access the website for any purpose.",
  "It also applies to any legal entity which may be represented by you under actual or apparent authority.",
  "User(s) may use this site solely for their own personal or internal purposes.",
  "This agreement applies to all Taptap Services, collectively with any additional terms and conditions that may be applicable to the specific service used/accessed by user(s).",
  "In the event of a conflict or inconsistency between any provision of the terms and conditions mentioned herein with those of the particular service, the provisions of the terms and conditions applicable to such specific Services shall prevail.",

  "AMENDMENT TO USER(S) AGREEMENT",
  "Taptap may change, modify, amend, or update this agreement from time to time with or without any prior notification to user(s) and the amended and restated terms and conditions of use shall be effective immediately on posting.",
  "If you do not adhere to the changes, you must stop using the service.",
  "Your continuous use of the Services will signify your acceptance of the changed terms and deemed acceptance of the amended Agreement.",
  "Further, User(s) shall also be bound by any amendment made in any policy or agreement from time to time, referred to in these Terms of Service.",

  "INTELLECTUAL PROPERTY RIGHTS",
  "Taptap is the sole owner or lawful licensee of all the rights to the website and its content. Website content means its design, layout, text, images, graphics, sound, video, etc.",
  "The website content embodies trade secrets and intellectual property rights protected under worldwide copyright and other laws.",
  "All title, ownership, and intellectual property rights in the website and its content shall remain with Taptap, its affiliates, or licensors of Taptap content, as the case may be.",
  "All rights, not otherwise claimed under this agreement or by Taptap.in, are hereby reserved.",
  "The information contained in this website is intended solely to provide general information for the personal use of the reader, who accepts full responsibility for its use.",
  "Taptap does not represent or endorse the accuracy or reliability of any information, or advertisements (collectively, the 'content') contained on, distributed through, or linked, downloaded, or accessed from any of the Services contained on this website, or the quality of any products, information, or other materials displayed, or obtained by you as a result of an advertisement or any other information or offer in or in connection with the Service.",
  "We accept no responsibility for any errors or omissions, or for the results obtained from the use of this information.",
  "All information on this website is provided 'AS IS' with no guarantee of completeness, accuracy, timeliness, or of the results obtained from the use of this information, and without warranty of any kind, express or implied, including, but not limited to, warranties of performance, merchantability, and fitness for a particular purpose.",
  "Nothing herein shall to any extent substitute for the independent investigations and the sound technical and business judgment of the user(s).",
  "In no event shall Taptap be liable for any direct, indirect, incidental, punitive, or consequential damages of any kind whatsoever with respect to the Service.",
  "User(s) of this site must hereby acknowledge that any reliance upon any content shall be at their sole risk.",
  "Taptap reserves the right, in its sole discretion and without any obligation, to make improvements to, or correct any errors or omissions in any portion of the Service or the app/website.",
  "Trademark",
  "All related icons and logos are registered trademarks or trademarks or service marks of Taptap in various jurisdictions and are protected under applicable copyright, trademark, and other proprietary rights laws.",
  "The unauthorized copying, modification, use, or publication of these marks is strictly prohibited.",
  "Copyright",
  "All content on this website is the copyright of Taptap except the third-party content and links to third-party websites on our app/website.",
  "Systematic retrieval of Taptap content to create or compile, directly or indirectly, a collection, compilation, database, or directory (whether through robots, spiders, automatic devices, or manual processes) without written permission from Taptap is prohibited.",
  "In addition, use of the content for any purpose not expressly permitted in this Agreement is prohibited and may invite legal action.",
  "As a condition of your access to and use of Taptap's Services, you agree that you will not use the website service to infringe the intellectual property rights of Taptap or any other third party in any way.",
  "Taptap reserves the right to terminate the account of a user(s) upon any infringement of the rights of any third party in conjunction with use of the Taptap service, or if Taptap believes that user(s) conduct is harmful to the interests of Taptap, its affiliates, or other users, or for any other reason in Taptap's sole discretion, with or without cause.",
  "You shall be liable to indemnify Taptap for any losses or expenses incurred by Taptap due to any infringement of intellectual property rights owned by Taptap without prejudicing Taptap's right to bring any legal action against you.",
  "LINKS TO THIRD PARTY SITES",
  "Links to third party sites are provided by the website as a convenience to user(s) and Taptap has no control over such sites i.e. content and resources provided by them.",
  "Taptap may allow user(s) access to content, products or Services offered by third parties through hyperlinks (in the form of word link, banners, channels or otherwise) to such third party's website.",
  "You are cautioned to read such sites' terms and conditions and/or privacy policies before using such sites in order to be aware of the terms and conditions of your use of such sites.",
  "Taptap believes that user(s) acknowledge that Taptap has no control over such third party's site, does not monitor such sites, and Taptap shall not be responsible or liable to anyone for such third party site, or any content, products or Services made available on such a site.",
  "User(s) shall review Taptap's Privacy Policy and abide by Taptap's Privacy Policy at the time of the User(s) interaction with Taptap, with respect to and concerning any information and data.",

  "TERMINATION",
  "Most content and some of the features on the website are made available to visitors free of charge.",
  "However, Taptap reserves the right to terminate access to certain areas or features of the website at any time for any reason, with or without notice.",
  "Taptap also reserves the universal right to deny access to particular users to any/all of its Services without any prior notice/explanation in order to protect the interests of Taptap and/or other visitors to the website.",
  "Taptap reserves the right to limit, deny or create different access to the website and its features with respect to different user(s), or to change any of the features or introduce new features without prior notice.",

  "TERMS & CONDITIONS FOR USE OF OUR SERVICE",
  "The following Terms & Conditions shall apply to customers utilising the Services offered by the Company for the hiring of vehicles:",
  "The customer shall pay the fare (as agreed), parking charges, additional night surcharge (where applicable) and any fee or levy presently payable or hereinafter imposed by the Applicable Law or required to be paid for availing the Services of Taptap.",
  "The customer agrees and accepts that the use of the Services provided by the Company is at the sole risk of the Customer, and further acknowledges that the Company disclaims all representations and warranties of any kind, whether express or implied.",
  "The customer shall ensure that he/she will not indulge in any of the following activities while availing the service:",
  "● Soiling or damaging the body and/or any other interiors of the vehicles.",
  "● Misusing, soiling or damaging any of the devices (technical/non-technical) in the vehicle.",
  "● Asking the driver to break any Traffic/RTO/City Police and/or government rules for any purpose. The driver has the right to refuse such a request by the customer. The driver also has the right to refuse such a pick-up.",
  "● Pressurizing the driver to overload the truck with the consignment beyond the allowed limit.",
  "The Customer shall indemnify the Company from and against and in respect of any or all liabilities, losses, charges and expenses (including legal fees and costs on a full indemnity basis), claims, demands, actions and proceedings which the Company may incur or sustain directly or indirectly from or by any reason of or in relation to the use or proposed use of the Services by the Customer and shall pay such sums on demand to the Company.",
  "The Company is hereby authorized to use the location-based information provided by any of the telecommunication companies when the Customer uses the mobile phone to make a vehicle booking. The location-based information will be used only to facilitate and improve the probability of locating a mini-truck for the Customer.",
  "The Company shall be entitled to disclose to all companies within its group, or any government body as so required by the Applicable Law or by directive or request from any government body, the particulars of the Customer in the possession of the Company in any way as the Company, in its absolute discretion, deems fit or if it considers it in its interests to do so.",
  "The Company shall be entitled at any time without giving any reason to terminate the booking of the vehicle done by the Customer. User(s) shall indemnify Taptap with respect to any expenses incurred with respect to such booking.",
  "Taptap shall be liable for the loss or damage of the consignment to the extent permissible by Carriage by Road Act and Rules.",
  "In the event of loss of any item, User(s) shall not have any right to withhold the payment to be made to Taptap. Further, in the event any payments from the User to Taptap are pending for the period prescribed by Taptap in its respective invoice/statement, Taptap reserves the right, in accordance with the Applicable Law, to exercise particular lien over the consignment till full payment is made to Taptap for the Services. Additionally, User(s) will be liable to indemnify Taptap against any loss, damage or expenses incurred by it due to the custody of the consignment during this period.",
  "Any complaint in respect of the Services or the use of the vehicles, the Customer has to inform the Company of the same in writing within 24 hours of using the vehicles or Services of the Company.",
  "The Company shall not be liable for any conduct of the drivers of the vehicles. However, the Company encourages you to notify it of any complaints that you may have against the driver that you may have hired using the Company's Services.",
  "The Company shall be entitled to add to, vary or amend any or all these terms and conditions at any time and the Customer shall be bound by such addition, variation or amendment once such addition, variation or amendment is incorporated into these terms and conditions at Company's website at https://taptap.in/ on the date that Company may indicate that such addition, variation or amendment is to come into effect.",
  "All the calls made to the Company's call centre are recorded by the Company for quality and training purposes. In the event you place a query on our app/website including any query with respect to our Services, applicable fees or Terms of Service, you hereby expressly agree to consent to receive our responses, whether by way of telephonic calls or electronic mail, to such query and all related information with respect to our Services.",
  "For removal of doubts, related information shall include without limitation any marketing and/or commercial information. You understand, agree and acknowledge that such information shall in no event qualify as unsolicited commercial communication under the Telecom Unsolicited Commercial Communications Regulations, 2007 and/or due to disclosure of such information, our telephone numbers shall not qualify to be registered under the 'National Do Not Call Register' or the 'Private Do Not Call Register' in accordance with the Telecom Unsolicited Commercial Communications Regulations, 2007 or any other Applicable Law.",
  "The Company will consider the email address and/or mobile number utilized by the user for transacting with Taptap, for seeking the user’s information required for processing of payments, for any reason whatsoever. The User hereby acknowledges that Taptap explicitly disclaims any responsibilities or liabilities resulting from inaccuracies in the data or information provided by the user.",
  "Cancellation Policy: All cancellations made 5 minutes after driver allocation will incur a cancellation fee of INR. 50/- (Indian Rupees Fifty only). Cancelling four bookings in a day after driver allocation will temporarily suspend your account for 24 hours. Figures are subject to change.",
  "Toll Charges: In case of a toll on your trip, return toll fare will be charged.",

  "LABOUR SERVICES",
  "Taptap provides the option to Customers to utilize Labour Services by clicking on the 'Include Labour' toggle on the review screen of the website/App.",
  "'Labour Services' means that the service provided by the labour wherein labour undertakes the task of loading/unloading the consignment of goods from the loading/unloading point to the vehicle or vice-versa, as the case may be.",
  "'Labour' means the driver of the vehicle and the person accompanying him in the vehicle.",
  "By clicking on the 'Include Labour' toggle on the review screen of the Website/App or by availing the Labour Services, the Customer agrees to the following terms and conditions. These terms shall be applicable in addition to the terms and conditions applicable to the customers for hiring of vehicles:",
  "● You acknowledge that the Labour Service is provided only for ground floor to ground floor loading/unloading. You acknowledge that the ground floor movement (distance between the transport vehicle and the loading/unloading point) shall not exceed 25 metres.",
  "● You acknowledge that the extra charges (as visible in the Invoice) for the Labour Service shall be payable by you.",
  "● You agree that you shall be present in person at the time of loading/unloading of Consignment. If you are not available, an authorised representative must be present.",
  "● You agree that Taptap shall not be liable for misbehaviour of the labour during the loading/unloading. However, you are requested to bring to the notice of Company such incidents. The Company, in its sole discretion, may take action in such matters.",

  "CONFIDENTIALITY",
  "User(s) shall not disclose any information received under the contract of service with Taptap to any third party.",
  "Access to any information which pertains to the business of Taptap shall be kept confidential to the extent it might adversely impact Taptap's business.",
  "User(s) shall be liable to indemnify Taptap against any loss of business or reputation due to the act of the user(s).",
  "DISCLAIMER",
  "All vehicles registered with the Company are continuously tracked using mobile technology for security reasons only.",
  "It is hereby made expressly clear to you that the Company does not own any vehicle nor does it directly or indirectly employ any driver for the vehicles or Labour for loading/unloading of Consignment.",
  "Vehicles, drivers, and Labour are all supplied by third parties and the Company disclaims any and all liability(ies) in respect of the Labour, drivers, and the vehicles alike.",
  "The Company has the right to use the customer contact information for its own marketing purposes.",
  "The Company may send regular SMS updates to the mobile numbers registered with it.",
  "ACE PLUS TERMS AND CONDITIONS",
  "● Only ground floor to ground floor loading/unloading is allowed.",
  "● Distance between Taptap truck and loading/unloading point should not be more than 25 feet (8 metres).",
  "● Max weight per box allowed is 25 kg and up to 30 such boxes/cartons are allowed.",
  "● Labour charge will be automatically added to the invoice.",
  "○ Only ground floor to ground floor loading/unloading is allowed.",
  "○ Distance between Taptap truck and loading/unloading point should not be more than 25 feet (8 metres).",
  "○ Max weight per box allowed is 25 kg and up to 30 such boxes/cartons are allowed.",
  "○ Labour charge will be automatically added to the invoice.",

  "INSURANCE INFO",
  "Please find Insurance related terms and conditions.",
  "INSURANCE FAQS",
  "FREQUENTLY ASKED QUESTIONS",
  "Are the customer's goods insured by Taptap?",
  "No, the goods are not insured. Our rates do not include any charges towards insurance. But the customer should take insurance for goods being shipped.",
  "Can a customer purchase an insurance on Taptap's platform?",
  "We do not offer this facility as of now.",
  "What is the process in case of damage/loss/theft of goods?",
  "If the customer has taken an insurance for his shipment, the customer has to inform us that he/she wishes to lodge an insurance claim and the next steps as advised by his Insurer.",
  "If required, we can help in the survey process initiated by the Insurer of the customer. We can provide a Certificate of Facts, which is an important document for claiming the insurance.",
  "In case of damage or theft to the customer's goods or theft during the trip, what is the liability from Taptap's side?",
  "Taptap does not take any liability for the goods being shipped unless it is established with conclusive evidence that the damage or theft was due to reckless driving.",
  "Only in such cases, after the evidence is shared with us, as per law, the transporter's liability is restricted to the lower of:",
  "1. Value of goods damaged; OR",
  "2. Ten times the freight amount paid or payable.",
  "Unless a higher risk rate has been charged and agreed to be paid by the Customer.",
  "We do not charge a risk rate, and our billable rates are purely a factor of utilization of the vehicle (distance + time) without factoring any of the insurable risks or the value of goods being transported.",
  "We accept the goods for shipments on a said-to-contain basis.",
  "We recommend exercising extreme caution in case of transporting extremely fragile/flammable goods or goods which are prone to damage while transporting.",
  "If you have any doubts regarding the goods that may be transported on our platform, please refer to our Terms of Service or kindly get in touch with us on 4410 4410.",

  "Please find Courier related terms and conditions",

  "PACKAGING INSTRUCTIONS",
  "● The items shall be packed using carton boxes and the same shall be sealed using proper packaging tapes.",
  "● Documents shall be packed using the envelopes with proper sealing.",
  "● Do not use damaged or old boxes for packaging.",
  "● Fragile items shall be packed inside thick carton boxes by filling the voids with foam/crumpled paper.",
  "● Ensure that the right labels are printed and pasted on the package.",
  "● Remove any old labels or barcodes.",
  "● Avoid using low quality adhesive tapes, Kraft paper tape, cellophane, masking tapes, and springs or ropes.",
  "● Only one box/envelope is allowed per AWB number.",
  "● Packing and Labeling: It is the sender's obligation to ensure adequate packing for purpose of carriage with normal care in handling.",
  "● In case the Shipment is inappropriately packed and/or it contains any of the prohibited items to the knowledge of TapTap, the Shipment may be flagged as Rejected by the pickup person assigned with such tasks.",
  "● The maximum allowable size of the package for the TapTap courier is 100,000 cubic cms.",

  "GENERAL INSTRUCTIONS",
  "● If any discrepancy in weight is found post acceptance of a consignment and if the actual weight or volumetric weight is greater than the declared weight, then the differential applicable charges shall be collected from the Parties.",
  "● In order to carry out successful pickup and delivery of goods, customer details may be shared with the service provider by TapTap.",
  "● The Parties shall pay all such payments as may be required to be made to statutory bodies or Municipal or State/Central Government agencies with respect to any consignment during transit or at the time of delivery.",
  "● The sender shall provide complete address of sender and recipient along with valid contact telephone numbers and correct Postal Index Number (PIN code). Any service failure arising out of any defect in such details shall be at the sole responsibility & risk of the sender.",
  "● The consignment note is issued strictly based on the declaration given by the parties at the time of booking. The Parties shall remain solely liable for any consequences arising out of any false or wrongful declaration.",
  "● In the event of any consignment being held up by any statutory authorities such as, but not limited to, Goods and Services Tax, Excise, Customs, Check-Post officials, e-way bill, etc., TapTap shall not be responsible for any consequential losses or for refund of freight charges.",
  "● Further, the Parties agree to make good to TapTap any losses incurred by TapTap, in the form of taxes/duties, fines and penalties levied by statutory authorities arising out of insufficiency of documents or wrongful declaration and any claims, if any, from any other entity affected because of delays arising out of issues related to such consignments.",
  "● Inspection of consignment: TapTap has the right at its option or at the request of competent authorities to open consignments at any time to inspect the contents of the consignment as part of the acceptance process and/or at various TapTap consignment handling points and/or at airline security gates and/or on request by any statutory, regulatory or security agencies.",
  "● If the Parties do not take delivery of the consignment or it remains undelivered due to any reason such as wrong or incomplete address or refusal by recipient to pay any applicable duties/taxes/charges or containing prohibited items or if the packaging of a consignment is damaged to the extent that repacking is not possible resulting in non-delivery, or the consignment is found as likely to cause damage to other goods or cause injury to individuals, then the Parties shall still be liable to pay freight charges and all other dues and charges to TapTap.",
  "● In case of undelivered consignments beyond 48 hours from the date of tendering the consignment for delivery, demurrage/warehouse charges at the rate of 0.1% of the consignment invoice value per day will be charged, or at such other rates as may be fixed by TapTap from time to time.",
  "● If the consignment is not received or claimed within a month from the date of tendering the consignment for delivery for the first time, TapTap shall have the right to proceed with the sale of the goods to realize all its dues.",
  "● Directors, owners, partners, and shareholders of TapTap shall not be personally liable for any claims or liabilities arising out of service failures resulting from situations, circumstances, omissions, errors, failures or misleading statements/guidance from any employees of TapTap or its channel partners or authorized agents.",
  "● In the event of non-delivery of a shipment, the consignor can opt for the shipment to be returned. Consignors will be liable for reverse movement charges.",
  "● When a shipment comes back as RTO (return to origin) due to failed delivery, or any other reason, it is the sender's responsibility to confirm the order for RTO with the Reverse Airway Bill number.",
  "● At the time of booking parcels, the consignor will be required to provide a declaration stating the value of the consignment and the detailed description of materials in the consignment. If the consignee does not declare the value of the shipment, it will be considered as zero.",
  "● TapTap will use the consignor/consignee information to update and inform the consignor/consignee through SMS, WhatsApp, and/or email regarding the delivery status of the product.",
  "● Shipments cannot be delivered to PO boxes or postal codes.",
  "● If the consignee refuses delivery or the shipment is deemed unacceptable, TapTap will make reasonable efforts to return the shipment to the consignor's address at the consignor's cost.",
  "● TapTap reserves the right to refuse the booking of any consignment without assigning any reason.",
  "● Sunday Pickup is not available.",
  "● Open Deliveries will not be entertained.",
  "● Shipments with a declared value exceeding INR 49,999 cannot be shipped via TapTap.",
  "● Volumetric weight Calculation: {Length(cms) X Breadth(cms) X Height(cms)}/5000.",
  "● Incase of any dispute, TapTap reserves the right to hold the goods until the differential amount is paid. TapTap shall have the right to lien on these disputed goods.",
  "● Shipping charges shown against any courier company are inclusive of platform charges incurred by TapTap.",
  "EXPRESS Per KG Rate: Rs. 120",
  "STANDARD Per KG Rate: Rs. 60",

  "RESTRICTED ITEMS/MATERIALS",
  "● Items not acceptable for carriage: The Parties hereby declare that the consignment covered under this consignment note does not include any articles restricted to be carried in courier mode, contrabands or such commodities which can cause safety hazards as specified by the current edition of IATA DG regulation.",
  "● Perishable Articles: Parties shall not tender for transportation any consignment containing perishable articles having shelf life of less than 7 days. TapTap shall not be liable for any loss or damage to any such consignment arising consequent to any delay in delivery.",
  "● The consignor shall not book any consignment containing any of the following nor can TapTap knowingly carry any of the following items:",
  "○ Perishable and Food items.",
  "○ LEDs, LCDs, plasma, OLED and any kind of television screens.",
  "○ Liquid product, livestock, perishables, live plants.",
  "○ Flammable items (firecrackers, oil cans, adhesives, paint cans), explosives (arms, ammunition, fireworks, flares, gunpowder, airbag inflators), fire extinguishers, electric lighter/cigarette.",
  "○ Platinum, gold, silver, artificial jewelry, gem, precious, semi-precious metals or stones in any form including bricks.",
  "○ Alcohol, tobacco, medicines, drugs, poisonous goods, toxic and infectious items.",
  "○ Valuable items, electronic devices, high capacity batteries such as car batteries, generator batteries.",
  "○ Machinery parts containing oil, grease, fuel or batteries, corrosive items (acids, chemicals).",
  "○ Radioactive material, Magnetized materials, Pressurized Containers, Narcotic Substances & Medical and research equipment.",
  "○ Indian postal articles such as stamps and articles like coins, banknotes, currency notes, Sodexo or securities of any kind payable to bearer, traveler's cheques.",
  "○ Gambling devices, lottery tickets, pornographic material.",
  "○ Dry ice used for packaging or temperature control and other items considered contraband under the Indian Postal law.",
  "○ Further, consignors will not hand over any secure original documents including but not restricted to passport, Aadhar Cards, bank statements, credit cards, debit cards, bank cheques and/or currency items, passports amongst other documents.",
  "○ TapTap is not licensed to carry the above mentioned products/documents and shall have no liability in such cases if such product is handed over without the consent of TapTap for delivery by the consignor.",

  "DAMAGE/LOSS/REFUND",
  "● TapTap shall not deliver consignments to PO Box addresses. Wherever TapTap carries out drop-box deliveries such as to ministry offices, armed forces establishments, certain government offices & high security zones, etc., TapTap shall not be providing proof of delivery (POD) and the parties shall accept the information provided as final.",
  "● In case of any requirement of POD, the customer shall request for the POD within 5 days after delivery. Post 5 days of delivery, TapTap will be under no obligation to provide POD.",
  "● Limited liability for Delay: In the event of any delay in delivery of a consignment, TapTap shall not be liable for any consequential or indirect losses or damages, including but not limited to loss of income or profits or claims by the parties or any other entity affected because of a delay.",
  "● Freight Refund: The refund of freight shall not be entertained if a service failure is the result of any Force Majeure conditions such as strikes, bandhs, elections, rains, floods, fire, accidents, or other natural calamities and any other events such as sudden or planned road closures or traffic diversions during festivals, political rallies, religious processions, etc., or any other reasons beyond the direct control of TapTap.",
  "● This also includes any routine or sudden inspections carried out by any authorities or tax recovery agencies such as but not limited to Excise, Customs, Sales Tax, municipalities, or any other authorities competent to inspect goods or vehicles.",
  "● Any claims or disputes arising out of our services shall be solely adjudicated before the courts at Bengaluru, Karnataka.",
  "● In case of verified damage or loss of shipment due to mishandling by a courier partner, TapTap shall cover maximum liability up to Rs.5000 based on the material invoice.",
  "● For material value above Rs.5000, TapTap will provide a certificate of facts to customers.",
  "● In case of unavailability of the material invoice, refund claims for damage and loss scenarios will be invalid.",
  "● For refund of damage cases, customers need to share required proofs like photos and videos of the package before and after opening the package.",
  "● For any damage cases, the customer has to intimate TapTap via call or email within 24 hours post-delivery of the package.",

  "Please find Taptap wallet related FAQs and terms & conditions",
  "WALLET FAQs",
  "FREQUENTLY ASKED QUESTIONS",
  "What is Taptap Wallet?",
  "Taptap wallet is an easy way of paying online for all your trips with Taptap.",
  "What are the benefits of using Taptap Wallet?",
  "1. Attractive promotions",
  "2. No hassle of cash #Cashless",
  "How can I sign up for Taptap Wallet?",
  "Every Taptap customer is by default eligible for Taptap Wallet. Just sign up and start using Taptap Wallet.",
  "How can I put money in Taptap wallet?",
  "There are two ways to recharge a Taptap Wallet:",
  "● Logging in your account via our website.",
  "● Sending a SMS to 6358880250. Format: 'Recharge <Amount>' and send this to 6358880250, where amount is the value you want to recharge.",
  "How do I use balance present in Taptap Wallet?",
  "If you have any balance in your Taptap wallet, it will get automatically adjusted against your future trip fares.",
  "Is there any validity period of the balance available in my Taptap Wallet?",
  "No, there is no validity period/expiry associated with the balance available in your Taptap wallet. You can use it at any point of time. However, the amount which is credited as part of promotional campaigns will carry certain validity period as mentioned in the campaign.",
  "Can I transfer balance in my Taptap Wallet to any other user?",
  "Sorry! Balance from one Taptap Wallet cannot be transferred to another.",
  "How can I check balance present in my Taptap Wallet?",
  "Log-in to your Taptap Account via our website and switch to Wallet tab. You would see your balance amount. You can also check out the same using wallet screen of our app.",
  "Is there any extra charge to use Taptap Wallet?",
  "Taptap Wallet is absolutely free for our customers and you are not charged any money for using the same.",
  "Are there any limitations to add money to Taptap Wallet?",
  "Yes, you can add up to a maximum of Rs. 50,000 against each recharge request. The balance in the Taptap Wallet, at any given time, shall not exceed Rs. 50,000.",
  "My Money has been debited but it is not reflecting in the wallet, why?",
  "At times, it can take up to 48 hours for the recharge to go through. In case, you don't see your recharge amount reflected in your Taptap wallet within 48 hours of making the recharge, please contact us via our CC at 4410 4410.",
  "When will my cashback be credited?",
  "Once your transaction is successful, cashback will be credited in your wallet within 24 hours.",
  "What happens in case my wallet doesn't have sufficient balance?",
  "Taptap will try to adjust the balance as much as possible against the trip fare (or any dues, if present). Remaining trip fare will have to be paid in cash.",

  "TERMS AND CONDITIONS",
  "● Taptap wallet saves you from the hassles of handling cash. You can load value in your Taptap account as a prepaid balance which can be used only for Taptap services.",
  "● Balance in Taptap Wallet does not bear interest. Offer of cash-back may be changed/withdrawn by the Management without prior notice.",
  "● Available balance in Taptap Wallet will be forfeited if no orders have been placed for one year from the date of last order.",
  "● Amount credited in wallet by Taptap in connection with any promotional/marketing campaign is non-refundable and may expire as per applicable terms.",
  "● Taptap has the sole discretion for rewarding the Taptap Wallet user with promotional offers, points, monitoring and/or temporarily suspending the Taptap Wallet.",
  "● Management reserves the rights to amend, modify or withdraw these terms at any time without prior notice.",
  "● Available balance in Taptap Wallet will not be refunded either in cash or bank account and can only be used for Taptap services.",

  "YOUR ACCEPTANCE OF OUR TERMS AND CONDITIONS",
  "By giving us your consignment you accept our terms and conditions set out in the consignment note and/or the contract of carriage and/or the contract for the performance of other services on behalf of yourself and/or anyone else who has an interest in the consignment or the performance of other Services irrespective of whether you have signed the front of our consignment note or not.",
  "Our terms and conditions also cover and can be invoked by anyone we use or sub-contract to collect, transport, deliver your consignment or perform other Services as well as our employees, directors and agents.",
  "Only one of our authorised officers may agree to a variation of these terms and conditions in writing.",
  "When you give us the consignment with oral or written instructions that conflict with our terms and conditions we shall not be bound by such instructions.",

  "YOUR OBLIGATIONS",
  "● You warrant, undertake and guarantee to us:",
  "○ The contents of the consignment (including but not limited to weight and number of items) have been properly described on our consignment note and that the Consignment Note is complete in all respects and the documents as required for transporting the consignment including invoice, permits are enclosed with the Consignment Note.",
  "○ That the contents of the Consignment are not Prohibited Items and/or are not restricted by the applicable regulations and that you will supply to us any Dangerous Goods declaration that is needed, properly and accurately in accordance with Applicable Law and neither you nor the consignee is a person or organisation with whom we or you may not legally trade under Applicable Law.",
  "○ That all statements and information and documents provided by you relating to the Consignment will be true and correct and you acknowledge that in the event that you make untrue or fraudulent statements about the Consignment or any of its contents, you would risk a civil claim and/or criminal prosecution, the penalties for which may include forfeiture and sale. You agree to indemnify us and hold us harmless from any claims that may be brought against us or our agents arising from the information provided by you.",
  "○ We are authorised to deliver the goods at the address mentioned on the Consignment Note and without prejudice to the foregoing it is expressly agreed that we shall be conclusively presumed to have delivered the goods in accordance with this contract. We will be sending the delivery confirmation by SMS/emails, no-response within 24 hours would be considered as an affirmative to the delivery.",
  "○ You have declared the correct weight of the consignment and you will provide any special equipment we may need to load or unload the consignment on or off our vehicles.",
  "○ When you have asked us to charge the receiver or a third party and the receiver or third party does not pay us, you will promptly settle our invoice together with an administration fee in full within 7 days of us sending you the invoice.",
  "○ Applicable Law has been complied with by you.",
  "● You understand, agree and acknowledge that the Services are not suitable for transportation of valuables like cash, gold, silver, diamond, precious stones, jewels or jewellery, expensive luxury items etc. ('Valuables').",
  "● If you handover/load the vehicles with Valuables for transportation, any loss/damage/theft/misappropriation to/of the consignment shall be at your risk and not ours, for the reasons mentioned earlier and without prejudice, we shall not only have the right to explicitly and specifically disclaim any liability and/or responsibility arising/accruing from the damage/loss/theft/misappropriation to/of the consignment or any contents of the consignment, but also the right to claim indemnification from you where we have suffered loss of reputation/goodwill due to your actions of breaching our terms of service.",
  "● You understand, agree and acknowledge that if the consignment or any contents thereof are of the value exceeding INR 25,000/- (Indian Rupees Twenty-Five Thousand only), you shall get the consignment insured from a General Insurance company operating in India before handing over the consignment for transportation.",
  "● In absence of adequate insurance to cover loss of goods in transit or in absence of wrong or non-declaration of the consignment details/value, and whether insured or not, the transportation of the consignment shall be entirely at your risk and not ours and we explicitly and specifically disclaim any liability and/or responsibility arising/accruing from the damage/loss/theft/misappropriation or any insurable loss to/of the consignment or any contents of the consignment and you further agree to indemnify us in all those cases where we have suffered loss of reputation/goodwill due to your actions of breaching our terms of service.",
  "● You agree to indemnify us and hold us harmless from any liabilities we may suffer or any costs, damages or expenses, including legal costs, we incur either to you or to anyone else arising out of you being in breach of any of these warranties, representations and guarantees, even if we inadvertently accept a consignment that contravenes any of your obligations.",
  "● You certify that all statements and information you provide relating to the transportation of the consignment will be true and correct.",
  "● You acknowledge that in the event that you make untrue or fraudulent statements about the consignment or any of its contents, you risk a civil claim and/or criminal prosecution the penalties for which include forfeiture and sale of your consignment.",
  "● To the extent that we may voluntarily assist you in completing the required customs and other formalities such assistance will be rendered at your sole risk.",
  "● You agree to indemnify us and hold us harmless from any claims that may be brought against us arising from the information you provide to us and any costs we will incur regarding this, and pay any administration fee we may charge you for providing the Services described in this condition.",
  "● The customer agrees and acknowledges that the use of the Services offered by the Company is at the sole risk of the customer and that the Company disclaims all representations and warranties of any kind, whether express or implied as to condition, suitability, quality, merchantability and fitness for any purposes are excluded to the fullest extent permitted by Applicable Law.",
  "● Without prejudice to the above, the Company makes no representation or warranties with respect to:",
  "○ The Services meeting the customer's requirements.",
  "○ The Services being uninterrupted, timely, secure, or error-free.",
  "○ Any additional or extra charges for far off locations & toll charges as well.",
  "● Circumstances beyond our control such as (but not limited to):",
  "○ Acts of god including earthquakes, cyclones, storms, flooding, fire, disease, fog, snow or frost or other natural calamities or disasters.",
  "○ Force majeure including (but not limited to) war, epidemics, pandemics, accidents, acts of public enemies, strikes, embargoes, perils of the air, local disputes or civil commotions.",
  "○ National or local disruptions in air or ground transportation networks and mechanical problems to modes of transport or machinery.",
  "○ Latent defects or inherent vice in the contents of the consignment.",
  "○ Criminal acts of third parties such as theft and arson.",
  "● Your acts or omissions or those of third parties such as:",
  "○ You being in breach of (or any other party claiming an interest in the consignment causing you to breach) your obligations under these terms and conditions.",
  "○ The contents of the consignment consisting of any article that is a prohibited item/Dangerous Goods/Valuables even though we may have accepted the consignment by mistake or you have willingly handed it over to us without notifying/informing/declaring to us.",

  "DANGEROUS GOODS / SECURITY",
  "● Dangerous Goods",
  "○ We do not carry, nor perform other Services regarding, goods which are in our sole opinion Dangerous Goods including, but not limited to, those specified in the regulations, guidelines, technical instructions, codes applicable to us and our business or to the transport of, or the performance of other Services regarding, Dangerous Goods.",
  "○ We may at our discretion accept some Dangerous Goods for carriage, or for the performance of other Services, in some locations if you have been accorded the status of an approved customer and this must be given by us in writing before your consignment can be accepted.",
  "○ Your Dangerous Goods will only be accepted if they comply with the applicable regulations and our requirements. Details of our requirements together with the procedure for applying for approved customer status are available from our nearest office and a dangerous goods surcharge will be invoiced to you upon acceptance of your consignment.",
  "● Prohibited Items: We do not accept consignments that contain prohibited items.",
  "● We accept consignments only upon your declaration of the type and value of the consignment ('said to contain' basis).",
  "● We have no responsibility whatsoever as to the correctness of description, type or value thereof and you agree to indemnify us and hold us harmless from any claims that may be brought against us arising out of or relating to such declaration provided by you and any costs we will incur relating thereto.",

  "REJECTED CONSIGNMENTS",
  "● If the receiver refuses to accept delivery, we will try to contact you and agree to the next action if it is appropriate.",
  "● You agree to pay us any costs we incur in forwarding, disposing of or returning the consignment and our charges (if any) for the agreed appropriate next action.",
  "● If user(s) terminates the agreement with Taptap with respect to any consignment, user(s) shall be liable to pay Taptap the entire fees and other expenses so incurred with respect to such consignment.",

  "CLAIMS BROUGHT BY THIRD PARTIES",
  "● You undertake to us that you shall not permit any other person who has an interest in the consignment to bring a claim or action against us arising out of Carriage even though we may have been negligent or in default and if a claim or action is made you will indemnify us against the consequences of the claim or action and the costs and expenses we incur in defending it.",
  "● The above mentioned terms and conditions of use and/or Agreement and the Privacy Policy constitute the entire agreement between the User(s) and Taptap with respect to access to and use of the web site and the Services offered by Taptap, superseding any prior written or oral agreements in relation to the same subject matter herein.",

  "TAPTAP REFERRAL PROGRAM",
  "Please find all referral related terms and conditions",
  "Refer n Earn - Terms & Conditions",
  "GENERAL TERMS",
  "● These referral terms and conditions shall be read jointly and in addition to the terms and conditions set out in Terms of Service.",
  "● For the purpose of these Referral Terms:",
  "  ○ 'We', 'Us', 'Taptap' or 'Company' shall mean Kabra Express Logitech Private Limited.",
  "  ○ 'Referrer' shall mean the person whose Referral Code has been used to sign up.",
  "  ○ 'Referral Recipient' or 'referee' shall mean the person who signs up using a valid Referral Code.",
  "  ○ 'Customer' or 'You' shall mean either the Referrer and the Referral Recipient.",
  "  ○ 'Referral Code' shall mean a unique code assigned to the Referrer by the Company.",
  "  ○ 'Referral Discount' shall mean any incentive provided by the Company to the Referrer or the Referral Recipient.",
  "  ○ 'Referral Program' shall mean the process under which the Referrer or the Referral Recipient uses the Referral Code on the online platform, Taptap, to avail the Referral Discount.",
  "● To participate in a Referral Program, You must agree to these Referral Terms set forth herein, which become part of the Terms & Conditions.",
  "● These Referral Terms constitute a valid and legally binding agreement between You and Us. By participating in the Referral Program, You agree to be bound by the Referral Terms provided herein.",
  "ELIGIBILITY TERMS",
  "● You agree that the Referral Program is valid only for users of the Taptap mobile application. The participation in the Referral Program is voluntary and any purchase or transaction on the Company’s mobile application shall be deemed as acceptance of these Referral Terms.",
  "CRITERIA TO EARN REFERRAL COUPON",
  "● The Referral Discount will be provided to the Referrer only upon completion of the first order of the Referral Recipient.",
  "● The Email ID and/or phone number through which the Referral Recipient signs up on Taptap app must not have been used for signing up with the Taptap app earlier.",
  "● The value of the Referral Discount will be as per the Company’s Referral Program active at the time of Referral Recipient's signup using the Referral Code.",
  "● Usage of the Referral Discount may be subject to additional terms such as minimum order value, days of use, timing, etc., which may be notified by the Company.",
  "● The Referral Code shall not be duplicated, sold or transferred in any manner or made available to the general public.",
  "● Sharing the Referral Code on any communication medium for purposes beyond personal use is not permitted.",
  "● A signup with a different email address or phone number using the same device will not qualify as a valid referral.",
  "● The maximum limit of referral coupons a user can earn is 20.",
  "TERMS OF USAGE OF REFERRAL DISCOUNT",
  "● Any Referral Discount received by You shall not be used in conjunction with any other Referral Code.",
  "● The Referral Code is non-transferrable and does not have any cash value.",
  "● Referral Discount cannot be used for previous orders and is applicable only on the final chargeable amount.",
  "TERMS FOR CHANGES IN REFERRAL PROGRAM",
  "● The Company reserves the right to convert Referral Discount into any other form of credit or discontinue the Referral Discount without prior notice.",
  "● The Company reserves the right to add, alter, withdraw, modify or change any or all Referral Terms.",
  "TERMS TO MONITOR REFERRAL ACTIVITIES",
  "● The Company reserves the right to review and investigate all referral activities and revoke Referral Discount in case of fraud or misuse.",
  "TERMS IN CASE OF ABUSE/FRAUD OF REFERRAL SCHEME",
  "● Creating multiple accounts using different devices or email IDs to gain Referral Discount is prohibited.",
  "● The Company reserves the right to revoke Referral Discount or suspend accounts in case of misuse.",
  "● Any liabilities, taxes, or charges related to the Referral Discount are to be borne by the Customer.",
  "MISCELLANEOUS",
  "● If any provision is held invalid, the remaining provisions will remain valid.",
  "● These terms and conditions shall be governed by the laws of India, and disputes will be adjudicated exclusively in Bangalore.",
  "● Personal data collected for the Referral Program will be processed per the privacy policy.",

  "ASSURED BOOKING TERMS",
  "● Only the users who have received the communication are eligible under this scheme.",
  "● This scheme is eligible for Tata Ace open category bookings between 10:30-19:00 Hours only.",
  "● This scheme is eligible when the pickup location is from Perambur, Choolai, Vyasarpadi, Purshwalkam, Ayanavram and Kilpauk clusters in Chennai only.",
  "● Each user will only be eligible once a day for this scheme i.e. a user will be eligible for a maximum of Rs 50/- (Rupees Fifty only) cashback in a day in case of unfulfilled booking.",
  "● The cashback will be auto credited in user's account within 24 hours of an unfulfilled booking.",
  "● Management decision will be considered final with regard to all aspects of the scheme.",
  "GST",
  "● GST, as applicable, will be levied on the Invoice.",
  "● Any information shall be considered only prospectively. Under no circumstances, will invoices be revised retrospectively for delay in providing GST registration information.",
  "● Taxes on reverse charge mechanism, wherever applicable, shall have to be paid by the recipient of services.",
  "GOVERNING LAW AND JURISDICTION",
  "● These Terms of Service shall be governed by and construed in accordance with the laws of India, without regard to the principles of conflict of laws.",
  "● The courts of [Bengaluru] shall have exclusive jurisdiction over any disputes, differences or claims arising out of or in connection with these Terms of Service or any Services provided by us pursuant to these Terms of Service.",
  "GRIEVANCE OFFICER",
  "● In accordance with the Consumer Protection (E-Commerce) Rules, 2020, the name and contact details of the Nodal/Grievance Officer are provided.",
  "● Any grievances under the said Rules, may have relevance with respect to the said Rules, its treatment or any grievance related to Services being availed at Taptap the request may be directed by the Authority under the said Rules, to such grievance officer or at the below mentioned coordinate:",
  "Legal Team,",
  "Taptap Head Office",
  "1008, Parshwanath Business Park,",
  "Prahlad Nagar, Ahmedabad,",
  "Gujarat 380015",
  "Contact us:",
  "Email: kabralogitech@gmail.com",
  "Phone: 6358880250",
  "Time: Monday to Saturday (10 a.m. to 6:30 p.m.)",
  "DISCLAIMER",
  "● The information contained in this website and Taptap App is for general information purposes only.",
  "● The information is provided by Taptap and while we endeavor to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website / App or the information, products, services, or related graphics contained on the website / App for any purpose.",
  "● Any reliance you place on such information is therefore strictly at your own risk.",
  "● In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website / App.",
  "● Through this website / App you are able to link to other websites which are not under the control of Taptap.",
  "● We have no control over the nature, content and availability of those sites.",
  "● The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.",
  "● Every effort is made to keep the portal up and running smoothly.",
  "● However, Taptap takes no responsibility for, and will not be liable for, the portal being temporarily unavailable due to technical issues beyond our control."
]
;

export const termsAndConditionsUserLogin = [
  "SERVICES AGREEMENT",
  "● THIS SERVICES AGREEMENT (“Agreement”) is made and executed on the “Execution Date” and commencing on the “Effective Date”.",
  "● Location [Applicable city office]",
  "● First Party Kabra Express Logitech Private Limited.",
  "● Registered Office: No. A - 501, A – 502, B – 504, B – 505 and B - 506, Fifth Floor at Universal Business Park, Chandivali Farm Road, Off. Saki Vihar Road, Andheri (East), Mumbai - 400 072, Maharashtra",
  "● Corporate Office: BCM Prime, 1st cross, BDA Layout, Guruppanan Palya, Tavarekere extension, Bannerghatta Road, Bangalore 560 041, Karnataka",
  "● (hereinafter referred to as the “TapTap” / “We” / “Us” / “Our”, which expression, if the context otherwise requires, shall mean and include its successors, assigns and Affiliates).",
  "● Second Party Name of the owner/ driver of the Vehicle(s) provider -",
  " a) PAN card number -",
  " b) Aadhaar Number -",
  " c) Driving License Number –",
  "● (As per the documents provided and attached to the Agreement)",
  "● (hereinafter referred to as the “Service Provider” / “You” / “yourself” / “your”, which expression, unless repugnant to the context hereof, shall mean and include its successors and assigns).",
  "Recitals WHEREAS,",
  "a) TapTap is an on-demand logistics’ services provider engaged in the business of transportation of goods of its customers from one place to another, under the brand name of TapTap; and",
  "b) TapTap avails services of drivers/ service provider in the course of its regular business.",
  "c) Service Provider is an individual / entity who is an independent commercial Vehicle operator and / or driver, who agrees to provide services of renting/ hiring of motor vehicle, with operator to TapTap, and has agreed to make himself available for the services of TapTap for the purposes as stated herein based on the terms and conditions attached with this Agreement.",
  "“Term” As per clause 3 (Term and Termination) of the Agreement.",
  "Services to be provided / availed, as the case may be",
  "As below and in accordance with General Terms and Conditions of the Agreement.",
  "Fees / Charges",
  "As below and in accordance with General Terms and Conditions of the Agreement.",
  "Details of Vehicles",
  "As per Annexure IA and the documents attached to the Agreement.",
  "Name of the Owner of the Vehicle (if different from the driver)",
  "As per Annexure IB and the documents attached to the Agreement.",
  "Attachments forming part of the Agreement",
  "This Agreement contains the following documents which are, and shall form, an integral part of this Agreement:",
  "a) The General Terms and Conditions in Appendix A;",
  "b) The Additional Terms and Conditions for 2W (Two-Wheeler) in Appendix B;",
  "c) The Additional Terms and Conditions for 3W (Three-Wheeler) and 4W (Four-Wheeler) in Appendix C;",
  "d) Details of the Vehicle(s) and driver including No Objection Certificate(s) for non-owned Vehicle in Annexure IA & Annexure IB;",
  "Reference to this Agreement shall include the applicable above documents and the General Terms and Conditions of TapTap.",
  "(The Service Provider and TapTap are hereinafter collectively referred to as the “Parties” and individually each as a “Party” and the word Service Provider and You, shall include its driver(s), as well).",
  "PLEASE ENSURE THAT YOU READ AND UNDERSTAND ALL THE CLAUSES BEFORE YOU PROVIDE SERVICES TO TapTap, AS YOU SHALL BE BOUND BY ALL THE TERMS AND CONDITIONS CONTAINED HEREIN upon your Acceptance.",
  "YOUR ACCEPTANCE / SHALL OPERATE AS A BINDING AGREEMENT BETWEEN YOU AND TapTap IN RESPECT OF THE VENDOR SERVICES TO TapTap.",
  "1. APPLICABILITY AND VALIDITY",
  "1.1. This document is an electronic record as per the provisions of Information Technology Act, 2000 and rules framed thereunder, as applicable, and the provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000.",
  "1.2. This electronic record is generated by a computer system and does not require any physical or digital signatures.",
  "1.3. Your Acceptance is a valid consent to be bound by this Agreement for using TapTap for the purpose of receiving and delivering the Vendor Services.",
  "1.4. Your engagement with TapTap to provide Vendor Services to TapTap is subject to your acceptance of the clauses, as specified in this Agreement. TapTap reserves the right to change, modify, add, or remove these clauses / terms and conditions, in part or in whole. It is your responsibility and duty to check the terms and conditions of TapTap periodically for the changes, if any. Your continued use of TapTap following the posting of changes will mean that You accept and agree to the changes, if any, made by TapTap.",
  "1.5. By accepting this Agreement, You further agree and consent to the collection, transfer, use, storage, disclosure and sharing of your information (personally identifiable or not), as per the applicable law(s). The data collected shall be utilised for improving Vendor Services to TapTap and in particular for the reason of internal record keeping, security and statutory disclosures, if any, as per the applicable law(s). You further agree to the Privacy Policy of TapTap as available on the website of TapTap (www.TapTap.in).",
  "2. TERMS OF SERVICE",
  "2.1. You shall not have more than one active TapTap vendor registration which you are further prohibited from selling, trading, or otherwise transferring to another party;",
  "2.2. Service Provider/Driver Information shall be true and correct and there will be no information that could impact your performance to render Vendor Services pursuant to this Agreement, shall be hidden from TapTap at any point of time. In case of furnishing of inaccurate data, TapTap reserves the right to deregister and/or suspend/terminate and/or take appropriate legal proceedings against the Vendor without prior notice;",
  "2.3. You acknowledge and agree that You may be subject to certain background and driving record verification as and when required from time to time;",
  "2.4. You shall not use any information pertaining to TapTap’s customer, including but not limited to any confidential information, according to and available with TapTap, for any purpose other than performing your obligations under or in connection with this Agreement;",
  "2.5. You shall ensure that all valid documents related to the vehicle such as a comprehensive valid insurance(s) are obtained and retained by you during the term of this agreement. Further, you shall ensure that all documents pertaining to each consignment such as invoices, description of the goods, insurance on the goods obtained by the customer (if any) etc. have been obtained by You during the term of this Agreement;",
  "2.6. You will have a functioning smartphone, active mobile number having the ability to read and send communication/message regarding the order/trip details including customer details, kms and time reading, amount paid by the customer and to convey to TapTap and/or TapTap’s verified agents.",
  "2.7. You will be a part of the standard vendor ratings process which will be dependent upon the transparent process and parameters of TapTap. Failure to adhere to the rating process will have an impact on the Vendor Services and the decision/action consequent thereupon will be solely decided by TapTap;",
  "2.8. During the performance of Vendor Services, the Vehicle shall not be pilfered or tampered with, and in case of any pilferage or tampering, the same shall be reported immediately by You to us;",
  "2.9. You must be aware of the prominent destinations and routes, both outside and within the Location or territory in which you will be rendering the Vendor Services;",
  "2.10. Pricing/trip fare, incentives & discounts provided to Customers of TapTap will be decided by TapTap at its sole and absolute discretion;",
  "2.11. You shall ensure and shall be solely responsible to ensure that before the pickup, the device/phone is available for TapTap for tracking and making the Vendor Services smooth. Your phone should not be busy for long, unavailable, or switched off. Doing this may lead to deductions in the amounts to be paid to You by adjusting the vendor pricing due to compensation paid to the customer, if any, given to the customer with the amount to be paid to You. Such deductions may extend up to any quantum of the amounts to be paid to You and would be decided at the sole and absolute discretion of TapTap;",
  "2.12. You shall be liable for all claims and demands raised by any third-party, at all times, in respect of the Vehicle (owned or hired). TapTap may or may not verify the details of the Vehicle used by You. In case of any claims as to the title of the Vehicle between the registered owner of the Vehicle and You, TapTap shall not be held responsible for the same. You shall be liable to indemnify TapTap in case of the loss of its brand value, goodwill, loss of business, direct or indirect claims, claims from accident and/or offenses under the Applicable Laws, etc. during the term of this Agreement and arising due to the direct or indirect acts of yours;",
  "2.13. Any statutory compliance with respect to each consignment such as procurement of GST invoices and E-way bill shall primarily be the responsibility of the customer, and prior to picking up the consignment, You shall be duty-bound to collect all the requisite documents from the customer that are necessary for the transportation of the consignment;",
  "2.14. You shall be liable to intimate TapTap, within 24 hours, with regard to any disputes arising out of the Vendor Services and obligations and/or any legal expenses (including but not limited to charges incurred for releasing seized Vehicle(s), advocate’s fees, and ancillary expenses) incurred by you while completing the trip, for no fault of yours. TapTap shall do all that is necessary to assist You to release the seized Vehicle(s) and safeguard your interests upon being intimated within the prescribed time period, as above, and upon internal investigation of the notified incident at TapTap’s end. In case of failure on your part to intimate TapTap within the specified time period, TapTap shall not be held liable and responsible for any costs, expenses, and losses incurred by You in this behalf;",
  "2.15. You shall indemnify TapTap in accordance with the provisions of this Agreement;",
  "2.16. You shall be solely liable for all offenses and penalties relating to the Vendor Services; and",
  "2.17. Any damage/loss/theft/misappropriation of consignment occurring during the rendering of the Vendor Services, on account of reasons attributable to Vendor, shall be completely indemnified by the Vendor to TapTap.",
  "3. TERM AND TERMINATION –",
  "3.1. The Agreement shall commence w.e.f. the Effective Date and shall be valid for a period of 1 (one) year unless terminated by TapTap.",
  "3.2. The Agreement shall be automatically renewed unless terminated as per the Agreement.",
  "3.3. TapTap reserves the right to suspend or terminate this Agreement, at any point of time, without assigning any reason whatsoever or notice to the Service Provider.",
  "3.4. Termination of this Agreement shall be without prejudice to the acquired rights and obligations of TapTap existing on the date of termination (including any right to take action in respect of the circumstances giving rise to termination) and shall not affect any other rights and remedies available to TapTap.",
  "4. CONSIDERATION, CHARGES AND TAXES –",
  "4.1. In consideration for performance of the Vendor Services, the Service Provider shall be entitled to receive Fees as notified by TapTap from time to time subject to deduction/collections of applicable Taxes.",
  "4.2. The applicable fees for the services shall be communicated to You on the application of TapTap prior to acceptance of the consignment which will be subject to variations (if any) including but not limited to changes in the route, any delay, etc during the performance of the services.",
  "4.3. All payments due to the Service Provider shall be made by a crossed pay order cheque in favor of Service Provider or through net banking / NEFT / RTGS etc., as decided by TapTap from time to time.",
  "4.4. The Service Provider acknowledges and agrees that in the event, any Tax proceedings are initiated against TapTap, the Service Provider shall fully cooperate with TapTap by furnishing the relevant information related to the supply provided to TapTap on a timely basis as may be required by TapTap. Furthermore, all the damages and other costs incurred by TapTap due to any fault of the Service Provider, the Service Provider shall indemnify TapTap for all such damages and other costs.",
  "4.5. The Fees payable to the Service Provider is inclusive of all applicable central, state, and other Taxes and levies applicable in the hands of the Service Provider. Further, TapTap shall withhold applicable income tax on the payments being made to the Service Provider.",
  "4.6. The Service Provider shall be solely responsible to pay the Taxes or any other applicable taxes with respect to the provision of Vendor Services provided to TapTap.",
  "4.7. In case of cash bookings, You shall collect on behalf of TapTap, the transportation with customer (as displayed on the application of TapTap or if otherwise agreed upon by TapTap and You at the time of accepting the said booking), parking charges, additional night surcharge (where applicable) and any fee or levy presently payable or hereinafter imposed by Applicable Law(s) or required to be paid for the Service.",
  "4.8. Toll charges will be payable by the customer (whether by way of cash or credit, as previously agreed between TapTap and the Customer as applicable, when the Vehicle that is in use and crosses a toll post.",
  "5. RELATIONSHIP -",
  "5.1. The relationship between You and TapTap is that of independent contractors and on a principal-to-principal basis. Nothing in this Agreement shall be construed as creating a partnership, joint venture, employer-employee, agency, or other relationship between the Parties.",
  "5.2. You covenant that all Persons assigned by You (not by way of transfer) to perform Vendor Services under this Agreement shall be your employees or contractors and under no circumstances shall such Persons be deemed to be employees / workers of TapTap.",
  "6. RPO AND NON-DISPARAGEMENT -",
  "6.1. The Service Provider shall provide the Vendor Services with the due care, skill and diligence of a reasonable prudent operator (“RPO”) and shall ensure that any person appointed by the Service Provider while performing under this Agreement shall provide the Vendor Services to the best of his/her ability and in accordance with the standards of an RPO.",
  "6.2. For the purposes of this Agreement, a “RPO” means a person seeking in good faith to perform its contractual obligations and in so doing and in the general conduct of its undertaking, exercises that degree of skill, diligence, prudence and foresight which would reasonably and ordinarily be expected from a skilled and experienced operator complying with all Applicable Laws and statutory obligations, engaged in same type of undertaking, under the same or similar circumstances and conditions and any reference to the standards of a RPO will be construed accordingly.",
  "6.3. Specifically, the Service Provider shall ensure that the services are provided in compliance with the Motor Vehicles Act, 1988, Motor Transport Workers Act, 1961 or any Rules made thereunder, Carriage by Road Act 2007 and Carriage by Road Rule 2011 and in strict compliance with the provisions of any Applicable Laws along with this Agreement;",
  "6.4. The Service Provider agrees to provide the Vendor Services in a manner so as to advance the business interests and goodwill of TapTap. Specifically, the Service Provider agrees not to, and shall ensure that its drivers do not, (a) form any union, trusts, association, society or any other type of entity; or (b) hold meetings, conference or any other form of gathering of 5 or more, to discuss this Agreement, the Services and payments provided hereunder and/or the regular business affairs of TapTap;",
  "6.5. The Service Provider shall not directly or indirectly engage in any conduct averse to the best interests of TapTap. The Service Provider shall not, and shall ensure that its drivers and other employees do not disparage or make any negative comments (either oral or in writing) about, TapTap or any of its officers, employees, directors or other representatives;",
  "6.6. The Service Provider shall be liable for all cases as may be initiated through the Vehicle during the engagement hours while providing the services;",
  "6.7. Notwithstanding anything contained anywhere in this Agreement, breach of this Clause 21(i) by the Service Provider shall entitle TapTap to terminate this Agreement and TapTap shall be entitled for all indemnification from the Service Provider, as per this Agreement.",
  "6.8. You have the appropriate and current level of training, expertise and experience to provide the Vendor Services in a professional manner with due skill, care and diligence;",
  "6.9. The appropriate and current level of training, expertise, and experience to provide the Vendor Services in a professional manner with due skill, care, and diligence and high standards of professionalism, service, and courtesy.",
  "6.10. All license(s), permit(s), approval(s) and authority(ies) to the Vehicle utilized by You, that are necessary to provide Vendor Services, have been obtained by You and further, any statutory compliance required for the running of such Vehicle in the Location or the territory in which Vendor Services are to be provided, have to be obtained by You at your own cost(s).",
  "7. BRANDING AND ADVERTISING TERMS -",
  "7.1. The Vehicles registered with TapTap from time to time by the Service Providers for providing Vendor Services can be branded/advertised with the logo and content of TapTap as per the terms and conditions specified by TapTap in this regard. The Service Provider shall ensure that such brand affixation is carried out only by the vendors identified by TapTap.",
  "7.2. The Service Provider and/or the driver(s) may not opt-out of any branding/advertising program of TapTap or its customers (as applicable) and stop branding/advertising on its Vehicle(s) during the subsistence of this agreement. Further, the Service Provider and/or its driver(s) shall be solely liable and accountable for any responsibility(ies)/liability(ies)/loss(es)/action(s)/penalty(ies) by any government, regional transport office(s)/police/local/regulatory body(ies) arising during the time they opted for such branding/advertising activity any such opt-out from branding/advertising program of TapTap or stoppage of branding/advertising their Vehicles, by the Service Provider and/or the driver(s).",
  "7.3. The Service Provider and/or the driver(s) shall comply with all the applicable law(s)/regulations for branding/advertising on its Vehicle(s) as defined by the state/central transport corporation/authority. The Service Provider and/or the driver(s) shall be solely responsible for compliance with the laws/regulations of the state/central transport corporation/authority (including the regional transport offices where the Vehicle got registered, local bodies, regulatory agencies (local/state/central), i.e., ensuring to get the TapTap branding approval in their Vehicle from the respective regional transport offices/state transport authority, where the Vehicle got registered within the prescribed time period. The Service Provider and/or the driver(s) shall be solely responsible for any liability, loss, action, and/or penalty arising out of any noncompliance with the applicable laws in relation to such branding/advertising, and TapTap will not be responsible for such liability in any manner.",
  "7.4. Non-adherence of this Clause by the Service Provider and/or the driver(s) shall be deemed to be a breach of this Agreement by the Service Provider and/or the driver(s) and TapTap may terminate this Agreement without any notice and further, the Service Provider and/or the driver(s) agrees to indemnify TapTap from and against and in respect of any or all liabilities, losses, charges and expenses (including legal fees and costs on a full indemnity basis), claims, demands, actions and proceedings, which TapTap may incur or sustain directly or indirectly from any breach by Service Provider and/or the driver(s) of its obligations hereunder or any breach of Service Provider’s and/or the driver(s’) representations and warranties, or by any reason, or in relation to the provision or proposed provision of the services by Service Provider and/or its driver(s) and Service Provider and/or the driver(s) shall forthwith pay all such sums forthwith on demand by TapTap in writing.",
  "7.5. The Service Provider explicitly accepts and consents to share the Service Provider/Driver Information with TapTap, including the submission of copies of such documents as provided by You with any financial lending institution(s) and/or insurance broker entities, if it is proved within reason that you have availed a service from them for which You are consenting the same. You forego the right to claim any damages from TapTap in the event any such financial facility and/or liability which has been availed by you. You also agree that TapTap will not be liable for any damages arising as a result of such disclosure of your information. TapTap retains the right to withhold pending dues and terminate your services on an immediate basis in such cases.",
  "7.6. The Service Provider hereby acknowledges and agrees to the use of his/her photographs by TapTap for certain purposes, which may include usage of the Service Provider and his vehicle photographs in TapTap's internal communications and presentations, training materials, marketing and advertisement activities, including without limitation, online advertisement, social media, and offline advertisement. The Service Provider hereby consents to sharing of his/her photographs by TapTap with third parties for the aforesaid purposes. The Service Provider further agrees to the Privacy Policy of TapTap as available on its website of TapTap (www.TapTap.in) on the link https://TapTap.in/privacy-policy.",
  "8. ELECTRONIC SIGNATURES –",
  "The Parties agree that the electronic signature of a party to this Agreement shall be as valid as an original signature of such party and shall be effective to bind such party to this Agreement.",
  "The parties agree that the electronically signed document (including this Agreement) shall be deemed:",
  "(i) to be 'written' or 'in writing,'",
  "(ii) to have been signed and",
  "(iii) to constitute a record established and maintained in the ordinary course of business and an original written record when printed from electronic files.",
  "Such paper copies or 'printouts,' if introduced as evidence in any judicial, arbitral, mediation, or administrative proceeding, will be admissible between the parties to the same extent as physical signed document.",
  "For and on behalf of Kabra Express Logitech Private Limited.",
  "For and on behalf of the Service Provider.",
  "[Electronic Signatures]",
  "[Electronic Signature]",
  "APPENDIX A - GENERAL TERMS AND CONDITIONS",
  "1. DEFINITIONS –",
  "Unless defined or the context otherwise provides or requires, the following words and expressions used in this Agreement shall have the meaning as provided to them herein below:",
  "“Acceptance” means your affirmative action of, (i) clicking on the box against the words “I Accept / Agree” or (ii) by “logging-in” / “signing-in” on the application of TapTap, provided at the end of this Agreement (including its annexure and the General Terms and Conditions), by which action, You unequivocally accept and agree to the Agreement and any modifications thereof;",
  "“Affiliates” means any corporation, association, or other entity that directly or indirectly owns, is owned by, or is under common ownership with the TapTap, either currently or during subsistence of this Agreement.",
  "“Agreement” shall mean the Services Agreement executed between TapTap and the Service Provider pursuant to which, the Service Provider has agreed to provide Vendor Services to TapTap;",
  "“Applicable Law” shall mean and include any law, statute, ordinance, rule, regulation, guideline, policy, notification, circular or other pronouncement or any similar form of decision of, or determination by or any interpretation, administration and other pronouncements having the effect of law of the Republic of India or any other applicable jurisdiction by State, Municipality, Court, Tribunal, Government, Ministry, Government-Department, Commission, Arbitrator or board having the effect of law in India, as in effect or which may come in effect on a future date;",
  "“Business Day” means a day (other than national gazette holidays, and bank holidays in the territory / location / place of execution of this Agreement) on which nationalized banks are generally open in India for the conduct of banking business and comprising of normal working hours. It is however clarified that a working day for TapTap at any Location shall always be a Business Day for the Service Provider for the Location in which the Service Provider is providing the Vendor Services;",
  "“Driver Information” shall mean and include any personal data collected from the Driver including know your client documents with Driver’s bank, copies of valid Government issued Vehicle registration certificate, Vehicle insurance copy, driving license, identity proof, residence proof, location data, proof of ownership, credit bureau score, registration(s) and compliance under welfare legislations for Driver and any other information that TapTap may deem fit;",
  "“Effective Date” shall mean the date on which the Service Provider accepted this Agreement and the General Terms and Conditions of TapTap by clicking “accept” or “agree” or by “logging-in” / “signing-in” on the application of TapTap;",
  "“Vendor Services / Services” shall be the services provided by the Service Provider to TapTap of making vehicles and operators available for TapTap’s logistics / transportation services in reference to the order provided by customers of TapTap on the application of TapTap;",
  "“Fees” means the consideration payable by TapTap to the Vendor/ Driver for providing the Vendor Services;",
  "“Governmental Authority” means any governmental agency, semi-governmental or judicial entity or authority, department of law and order including the Police personnel of any rank (including, without limitation, any stock exchange or any self-regulatory organisation established under statute);",
  "“Intellectual Property Rights” shall mean and include the copyright (whether registered or not), patents including rights of filing patents, trademarks, trade names, trade dresses, house marks, collective marks, associate marks and the right to register them, designs (both industrial and layout), geographical indicators, moral rights, broadcasting rights, displaying rights, distribution rights, selling rights, reproducing rights, domain names, internet address, graphics, artwork, links, information, logos, software, configuration, marketing and distribution material and any other intellectual property right in the website of TapTap and / or its application, which are used by and / or owned by TapTap;",
  "“Tax” means all forms of present and future taxes (including but not limited to direct and indirect Taxes), deductions, withholdings, duties, imposts, levies, cesses, fees, charges, social security contributions and rates imposed, levied, collected, withheld or assessed by any Governmental Authority or other taxing authority in India or elsewhere and any interest, additional taxation, penalty, surcharge, cess or fine in connection therewith and “Taxes” shall be construed accordingly;",
  "“Termination Date” shall mean the date on which this Agreement shall stand terminated by TapTap;",
  "“General Terms and Conditions” or “Vendor/ Driver T&C” or “Terms and Conditions” refers to the Vendor/ Driver Terms and Conditions, which are forming part of this Agreement under Annexure B and/or C and are also available on the website and application of TapTap, as may be amended by TapTap from time to time;",
  "“Person” means any natural person, limited or unlimited liability company, corporation, partnership (whether limited or unlimited), proprietorship, Hindu undivided family, trust, union, association, Governmental Authority or any agency or political subdivision thereof or any other entity that may be treated as a person under the Applicable Law;",
  "“Vehicle” shall mean the vehicles used by the Vendor/ Driver and includes the ones plying on non-renewable resources as well as renewable resources but not limited to electric vehicles, which will be used for performing the Vendor Services;",
  "2. INTERPRETATION –",
  "Unless the context of this Agreement otherwise provides or requires:",
  "a) The recitals, annexures, terms and conditions, attachments, fees receipts, invoices and schedules shall constitute an integral and operative part of this Agreement;",
  "b) A word or an expression, which denotes a natural person shall include an artificial person (and vice versa), any one gender shall include the other genders, the singular shall include the plural (and vice versa);",
  "c) Heading and bold typeface in this Agreement are for convenience only and shall be ignored for the purposes of interpretation;",
  "d) The use of the word “including” followed by a specific example(s) in these Agreement, shall not be construed as limiting the meaning of the general wording preceding it;",
  "e) The rule of construction, if any, that any clause should be interpreted against the parties responsible for drafting and preparation thereof, shall not apply;",
  "f) Reference to any clause, article, section or schedule shall mean a reference to a clause, article, section or schedule of these Agreement, unless specified otherwise;",
  "g) Reference to any Applicable Law shall mean a reference to that law as amended, consolidated, supplemented or replaced;",
  "h) All references to this agreements, documents or other instruments include all documents attached to this Agreement (subject to all relevant approvals) a reference to this agreement, document or instrument as amended, supplemented, substituted, novated or assigned from time to time;",
  "i) Unless the context otherwise requires, the singular includes the plural and vice versa;",
  "j) The words ‘hereof’, ‘herein’, and ‘hereto’ and words of similar import when used with reference to a specific Clause in, or Schedule to, this Agreement shall refer to such Clause in, or Schedule to, this Agreement, and when used otherwise than in connection with specific Clauses or Schedules, shall refer to the Agreement as a whole;",
  "k) Headings and the use of bold typeface shall be ignored in its construction;",
  "l) A reference to a Clause or Schedule is, unless indicated to the contrary, a reference to a clause or schedule to this Agreement;",
  "m) References to this Agreement shall be construed as references also to any separate or independent stipulation or agreement contained in it;",
  "n) The words “other”, “or otherwise” and “whatsoever” shall not be construed ejusdem generis or be construed as any limitation upon the generality of any preceding words or matters specifically referred to; and",
  "o) Words and abbreviations, which have well known technical or trade/commercial meanings are used in the Agreement in accordance with such meanings.",
  "3. VALIDITY -",
  "a) Any terms and conditions that by their nature or otherwise reasonably should survive a cancellation or termination of this Agreement shall be deemed to survive the cancellation or termination of this Agreement.",
  "b) This Agreement shall be governed by, and construed in accordance with, the laws of India, without regard to the principles of conflict of laws.",
  "c) If any part or any provision of this Agreement is or becomes illegal, invalid, or unenforceable, that part or provision shall be ineffective to the extent of such invalidity or unenforceability only, without in any way affecting the validity or enforceability of the remaining parts of said provision or the remaining provisions of this Agreement. The parties hereby agree to attempt to substitute any invalid or unenforceable provision with a valid or enforceable provision, which achieves to the greatest extent possible the economic, legal, and commercial objectives of the invalid or unenforceable provision.",
  "4. COPIES -",
  "a) This Agreement may be executed and delivered in one or more counterparts, each of which shall be deemed as an original, or by facsimile or other electronic copies, such as by email. The parties agree that such facsimile or electronic execution and shall have the same force and effect as of an original document with original signatures.",
  "b) No variation of this Agreement shall be effective unless it is in writing and signed by the Parties (or their authorized representatives).",
  "c) The Parties agree that the Parties may enter into one or more written addendums from time to time to supplement, modify, or alter the terms of this Agreement.",
  "5. LIMITATIONS -",
  "TapTap does neither warrant that You will be able to use TapTap’s application and / or will be able to provide the Vendor Services at all times or locations on TapTap nor it warrants that TapTap will be uninterrupted or error-free or that the defects will be capable of being corrected by TapTap in a timely fashion. The Services, Devices, the output generated therefrom, and all other technology developed by TapTap are provided to you on an “AS IS” and “AS AVAILABLE” basis and TapTap specifically disclaims all warranties and indemnities, express, implied or statutory, including without limitation any warranty of merchantability, fitness for a particular purpose, accuracy, completeness, any assurance of minimum business guarantee with respect to any Fees or any other warranty arising from the course of performance or course of dealing.",
  "TapTap shall not be responsible or liable for any loss or damage, howsoever caused or suffered by the Service Provider arising out of the use of the service offered by TapTap or due to the failure of TapTap to provide services to the consignor/consignee for any reason whatsoever including but not limited to any customer’s noncompliance with the services offered by TapTap, which includes, but is not limited to any incorrectly placed voice instructions, malfunction, partial or total failure of any network terminal, data processing system, computer tele transmission or telecommunication system or other circumstances whether or not beyond the control of TapTap or any person or any organization involved in the above-mentioned systems. The Service provider shall also be liable to TapTap for any loss caused to TapTap due to the negligence of the Service Provider and/or his appointed operators/contractors and or any unlawful act or omission in the performance of the Vendor Services.",
  "Without prejudice to the above, TapTap shall not be liable for any direct or indirect loss or damage, which may be suffered by the Service Provider as a result of any failure by a consignor/consignee to show up within any stipulated time even if TapTap has agreed to such timing or even if the customer has advised TapTap of the possibility that he/she may not show up within the stipulated time;",
  "TapTap makes available general third-party information and other data from external sources (“Third Party Content”). The provision of Third-Party Content is for general informational purposes only. The Service Provider acknowledges that the Third-Party Content provided is obtained from sources believed to be reliable. TapTap does not provide any guarantee with respect to any Third-Party Content and TapTap shall not be held liable for any loss suffered by the Service Provider based on the reliance placed on or use of Third-Party Content. Further, to the extent that such Third-Party Content is infringing upon some other party’s intellectual property rights or proprietary rights, TapTap shall not be held liable for the same at any instances.",
  "6. VIOLATIONS / BREACH -",
  "a) All remedies of either party under this Agreement whether provided herein or conferred under statute, civil law, common law, custom or trade usages, are cumulative and not alternative and may be enforced successively or concurrently.",
  "b) Each party shall bear its own costs in connection with its obligations under this Agreement. Further, all charges and expenses including stamp duty or otherwise in relation these presents shall be borne and paid by the Service Provider. The Service Provider shall bear and pay their own advocates’ fees.",
  "c) Each party will act in good faith in the performance of its respective responsibilities under this Agreement and will not unreasonably delay, condition, or withhold the giving of any consent, decision or approval that is either requested or reasonably required by the other party to perform its responsibilities.",
  "d) Any violation of any provision hereof, any incomplete or mistaken performance of any obligation provided hereunder, any misrepresentation made hereunder, any material nondisclosure or omission of any material fact, or any failure to perform any covenants provided hereunder by any Party shall constitute a breach of this Agreement. The breaching Party shall be liable for any such breach pursuant to the applicable laws. On breach of covenants of agreement by defaulting party, consequently other party of the agreement will be discharged from all its obligations and liabilities from the moment breach is committed till the time breach is corrected.",
  "e) This Agreement shall be binding and inure to the benefit of the Parties and their respective legal representatives, administrators, executors, and permitted assigns.",
  "7. REPRESENTATIONS AND WARRANTIES –",
  "a) On the Effective Date and as long as this Agreement is effective, the Service Provider hereby represents and warrants that the following representations and warranties are true, correct, valid and subsisting in all respects:",
  "b) The Service Provider is competent and of valid age to enter into the Agreement with TapTap in the Location / territory in which Service Provider will provide the Vendor Services and may or may not be enrolled for any welfare schemes, as provided by any Governmental Authority and must share the requisite documents with TapTap, from time to time, for compliance purposes only.",
  "c) The Service Provider must hold and maintain:",
  "    i. a valid driver’s license (as applicable) with the appropriate level of certification(s) to operate the Vehicle driven / ridden; and",
  "    ii. all license(s), permit(s), approval(s) and authority(ies), as applicable to the Vehicle that are necessary to provide the Vendor Services.",
  "d) You hereby provide consent to TapTap to access, use or share data and information including but not limited to location information, contacts information, transaction information, usage and preference information, device information, call and SMS data and log information related to the application of TapTap;",
  "e) You have not been convicted by any Court, in the Location or the territory in which You are providing the Vendor Services, of any crimes / cases, including but not limited to involving moral turpitude. You are further not a party to any pending litigation, which shall materially affect your obligations under this Agreement;",
  "f) Vendor/ Driver as Service Provider hereby agree that their documents may be sent for background verification (“BGV”) by TapTap and if there is any discrepancy / issue in the opinion of TapTap, found during or post BGV, this Agreement shall stand null and void with immediate effect at the sole discretion of TapTap.",
  "g) The Service Provider further acknowledges and agrees that:",
  "    i. TapTap neither owns or in any way controls the Vehicles used by the Service Provider rendering the Vendor Services to TapTap, nor it controls the action(s) of the Service Provider providing the Vendor Services to TapTap, in case the Service Provider itself is not the Driver.",
  "    ii. Notwithstanding anything contained herein and in addition to the responsibilities of the Service Provider specifically in the segment of 2 (Two) wheeler Vehicles, the Service Provider who is also the driver of the Vehicles which may or may not be under his ownership as to the right, title and interest on the Vehicle, is responsible and liable for all loss or damages as to any matter arising to and from the Vehicle including the claims, demands, charges, expenses, show cause, detention, litigation and but not limited to objections, etc. as may be raised by a third party, family or related members, appropriate authorities, police functionary, etc. and shall keep TapTap indemnified from all such claims that have arisen during the term of this Agreement.",
  "8. INDEMNITY AND LIABILITY –",
  "You shall indemnify TapTap from and against and in respect of any or all liabilities, losses, charges and expenses (including legal fees and costs on a full indemnity basis) claims in general and any provision which forms a part of this Agreement, demands, actions and proceedings, which TapTap may incur or sustain directly or indirectly from any breach by You of your obligations hereunder or any breach of your representations and warranties, or by any reason, or in real action to the provision or proposed provision of the Vendor Services and You shall forthwith pay all such sums forthwith on demand by TapTap in writing.",
  "Notwithstanding any other provisions of this Agreement, You shall be liable to TapTap for indirect, special, or consequential damages arising out of or in connection with the provision of Vendor Services under this Agreement.",
  "You agree that except for the reasons of Force Majeure, in the event of any loss or damage to TapTap that arises from the Vendor Services, You shall make good the loss, costs, charges, and expenses that TapTap may suffer or incur.",
  "To the extent permissible under Applicable Laws, TapTap is not liable for any civil, criminal, tortious, or any other liability, that may accrue as a consequence of your breach (a) of the Applicable Laws while providing Vendor Services to TapTap; (b) of the terms of the applicable licenses and permits of the Vehicle(s) that are issued by the transport authorities; (c) of the terms of this Agreement.",
  "TapTap’s maximum liability to You in any event, which includes any failure by TapTap under this Agreement, shall be restricted and limited to a maximum amount of INR 500 (Indian Rupees Five Hundred Only) in the aggregate during the Term.",
  "There will be no limitation of liability on your part at any point of time due to any indemnity provisions, as contained in this Agreement.",
  "9. INTELLECTUAL PROPERTY RIGHTS -",
  "a) Except as expressly set forth herein, as between the Parties, TapTap shall remain the owner of all intellectual property that it owns or controls as of the Effective Date or that it develops or acquires thereafter. Nothing in this Agreement will function to transfer any of either Party's intellectual property rights to the other Party and each Party will retain exclusive interest in and ownership of its intellectual property developed before this Agreement or developed outside the scope of this Agreement.",
  "b) Any intellectual property rights created during the course of the Agreement shall be exclusively owned by TapTap.",
  "c) All Intellectual Property Rights in relation to the branding/advertisement shall continue with TapTap. No part of this clause or this Agreement shall imply any license/assignment of the Intellectual Property Rights by TapTap to the Service Provider in respect of the TapTap logo.",
  "APPENDIX B - ADDITIONAL TERMS AND CONDITIONS FOR 2 (TWO WHEELERS)",
  "1. The Service Provider acknowledges and agrees that the Vehicle with which he/she chooses to provide Vendor Services shall be:",
  "a. to operate in compliance with all applicable laws, circulars, notifications, notices as raised by the appropriate Authority from time to time;",
  "b. properly registered, insured and licensed in accordance with law to operate as a passenger transportation Vehicle, a shared mobility Vehicle and/or Vehicle to transport/deliver Vendor Services;",
  "c. must wear helmet while driving the said Vehicle;",
  "d. to keep the Vehicle engine turned on while providing the Vendor Services except upon reaching the origin and/or destination;",
  "e. to maintain proper speed limit as prescribed by the Government Authority;",
  "f. suitable for performing the Vendor Services; and",
  "g. maintained the Vehicle in good operating condition, consistent with industry safety and maintenance standards for a Vehicle of its kind and any additional standards or requirements in the applicable Branch Location, and in a clean and sanitary condition.",
  "2. The Service Provider may or may not have ownership as to the right, title and interest on the Vehicle and is responsible and liable for all loss or damages as to any matter arising from the vehicle including the claims, demands, charges, expenses, show cause, detention, litigation cases, actions from Government Authority and but not limited to objections, etc. as may be raised by a third party, family or related members, appropriate authorities, police functionary, etc. and shall keep TapTap indemnified from all such claims from time to time during the course during the term of this Agreement. The Service Provider shall also provide TapTap the requisite documents as prescribed under Annexure A to furnish the Vehicle owner details.",
  "3. Service Provider makes himself/herself available to undertake Vendor Services for TapTap as and when a request for the same is placed by the Customer on TapTap. If required, the Service Provider may use his personal Vehicle or acquire a Vehicle from a third party (“Service Provider Vehicle”) for providing the Vendor Services. No Vehicle of any kind shall be provided by TapTap under any circumstances and TapTap will not be liable to settle any claims or pay any amounts in regard to any accidental claims, insurance claims, tolls and all expenses with regard to the acquisition and maintenance of the Service Provider Vehicle are to be borne by the Service Provider solely.",
  "4. The Service Provider shall ensure that the Service Provider Vehicle is well maintained and in good condition so that there are no delays in rendering Vendor Services.",
  "5. All expenses incurred in maintaining, running and riding the Service Provider Vehicle shall be borne exclusively by the Service Provider.",
  "6. The Service Provider shall hold and possess a valid driving license and a valid registration number for the Service Provider Vehicle, if required under the Applicable Law for the Vehicle used by the Service Provider for providing Vendor Services, which are up to date and in subsistence throughout the Term of these Service Provider T&C. Copies of the driving license as well as the registration certificate of the Service Provider Vehicle, including any other Service Provider Information, shall be handed to TapTap before commencing Vendor Services or at any other time deemed appropriate by TapTap.",
  "7. Service Provider shall not commit any fraud while providing Vendor Services or otherwise commit any act or omission, to gain any undue advantage. Service Provider agrees and acknowledges that in case TapTap believes that the Service Provider has committed any of the foregoing while undertaking Vendor Services, TapTap shall, in addition to its right to terminate the Agreement and these Service Provider T&C, in its sole discretion reserve the right to (a) disable the Service Provider from undertaking Vendor Services for such time as it deems fit and/or (b) deduct the undue gain made by the Service Provider through the fraudulent orders from the pay-out including incentives/Fees thereof and/or the Security Deposit, if any. This right to withhold pay out including incentives/Fees thereof may also be exercised by TapTap in the event service parameter guidelines, as prescribed by TapTap from time to time, are not met.",
  "8. The Service Provider is not entitled to claim reimbursement of hospitalization/ hospital bills that may be incurred by the Service Provider while rendering Vendor Services or incurred by his family members for any unfortunate accidents or severe illness, during the subsistence of Agreement and these Service Provider T&C.",
  "9. TapTap shall, with regard to clause 8 above and on a best effort basis, try to reimburse costs, expenses and losses, if any, incurred by You towards any legal expenses (including but not limited to charges incurred for releasing of seized vehicles, advocate’s fees, ancillary expenses) while completing the trip, for no fault of yours. The reimbursement, in any case, shall not be more than INR 5,000 /- (Rupees Five Thousand) and shall be subject to the charges applicable to the particular City Court and/or State, whichever is lesser. This reimbursement shall be done only after internal investigation and on verification of facts by TapTap on a case-to-case basis.",
  "APPENDIX C - ADDITIONAL TERMS AND CONDITIONS FOR 3 (THREE) AND 4 (FOUR) WHEELERS",
  "1. The Service Provider acknowledges and agrees that the Vehicle with which he/she chooses to provide Vendor Services shall be:",
  "a. to operate in compliance with all applicable laws, circulars, notifications, notices as raised by the appropriate Authority from time to time;",
  "b. properly registered, insured and licensed in accordance with law to operate as a passenger transportation Vehicle, a shared mobility Vehicle and/or Vehicle to transport/deliver Vendor Services;",
  "c. must apply the seat belt while driving the Vehicle;",
  "d. to keep the pollution certifications in place;",
  "e. to maintain proper speed limit as prescribed by the Government Authority;",
  "f. to check and maintain that before the departure of the locations, the Vehicle is under the responsibility of the Service Provider deployed therein at the time of loading of the consignment to check and verify that all such consignments are loaded in absolutely sealed containers/packages as per the order details. Post verification of the same, they should affix their sign and seal as well as a token of verification. If any loss and/or damage arises due to any such seal/lock cutting incident, then the Service Provider shall be immediately liable to report such incident at the nearest police station by way of lodging a First Information Report and thereby immediately take pictures/photographs of the same and report such incident to the nearest branch office of TapTap and shall thereby handover the copy of the FIR together with the photographs. In case of any deviation and/or contravention/non-compliance, the Service Provider shall be solely responsible and liable to make good of all the consequential loss/damage arising therefrom which is inclusive but not limited to payment of incidental expenses relating to the same, viz. Police Station Expenses for the lodging of the FIR, travelling expenses of the representative of the TapTap to manage such incident.",
  "g. suitable for performing the Vendor Services; and",
  "h. the partner is required to have rope, ply, and hook in his vehicle for providing service efficiently maintained the Vehicle in good operating condition, consistent with industry safety and maintenance standards for a Vehicle of its kind and any additional standards or requirements in the applicable Branch Location, and in a clean and sanitary condition.",
  "i. The Service Provider may or may not have ownership as to the right, title and interest on the Vehicle and is responsible and liable for all loss or damages as to any matter arising from the vehicle including the claims, demands, charges, expenses, show cause, detention, litigation cases, actions from Government Authority and but not limited to objections, etc. as may be raised by a third party, family or related members, appropriate authorities, police functionary, etc. and shall keep TapTap indemnified from all such claims from time to time during the course during the term of this Agreement. The Service Provider shall also provide TapTap the requisite documents as prescribed under Annexure A to furnish the Vehicle owner details.",
  "2. The Service Provider may use his personal Vehicle or acquire a Vehicle from a third party ('Service Provider Vehicle') for providing the Vendor Services. No Vehicle of any kind shall be provided by TapTap under any circumstances and TapTap shall not be liable to settle any claims or pay any amounts in regard to any accidental claims, insurance claims, tolls and all expenses with regard to the acquisition and maintenance of the Service Provider Vehicle are to be borne by the Service Provider solely.",
  "3. The Service Provider shall ensure that the Service Provider Vehicle is well maintained and in good condition so that there are no delays in rendering Vendor Services.",
  "4. All expenses incurred in maintaining, running and riding the Service Provider Vehicle shall be borne exclusively by the Service Provider.",
  "5. The Service Provider shall hold and possess a valid driving license and a valid registration number for the Service Provider Vehicle, if required under the Applicable Law for the Vehicle used by the Service Provider for providing Vendor Services, which are up to date and in subsistence throughout the Term of these Service Provider T&C. Copies of the driving license as well as the registration certificate of the Service Provider Vehicle, including any other Service Provider Information, shall be handed to TapTap before commencing Vendor Services or at any other time deemed appropriate by TapTap.",
  "6. The Service Provider shall have a valid and adequate insurance coverage to the Service Provider Vehicle. Service Provider shall ensure that the insurance is valid, up to date and in subsistence throughout the Term of these Service Provider T&C. A copy of the insurance policy shall be given by the Service Provider to TapTap. The Service Provider further confirms that all premium payments towards the insurance policy shall be paid exclusively by the Service Provider. Under no circumstances shall TapTap be liable to make any payment with respect to such insurance.",
  "7. During the course of undertaking Vendor Services, the Service Provider shall conduct himself with honesty, discipline and in accordance with the policies and instructions of TapTap, whether presently in force or adopted in the future, including but not limited to safety, driving rules, etc. The Service Provider shall comply with the Applicable Laws as required to be complied with by any person driving Vehicles.",
  "8. Service Provider shall not commit any fraud while providing Vendor Services or otherwise commit any act or omission, to gain any undue advantage. Service Provider agrees and acknowledges that in case TapTap believes that the Service Provider has committed any of the foregoing while undertaking Vendor Services, TapTap shall, in addition to its right to terminate the Agreement and these Service Provider T&C, in its sole discretion reserve the right to (a) disable the Service Provider from undertaking Vendor Services for such time as it deems fit and/or (b) deduct the undue gain made by the Service Provider through the fraudulent orders from the pay out including incentives/Fees thereof and/or the Security Deposit, if any. This right to withhold pay out including incentives/Fees thereof may also be exercised by TapTap in the event service parameter guidelines, as prescribed by TapTap from time to time, are not met.",
  "9. At no time whatsoever shall the Service Provider tamper, damage, open or do anything to the consignments of the Users that he/she is not specifically permitted to do. In case TapTap suffers any loss due to the Service Provider tampering, damaging or opening the packaging of consignments of the Users, TapTap shall have the right to recover any loss, if any, from the payments required to be made by TapTap to the Service Provider under the Agreement or these Service Provider T&C.",
  "10. The Service Provider acknowledges that the goodwill and reputation of TapTap is at stake with how effectively and efficiently the Service Provider shall render Vendor Services pursuant to these Service Provider T&C. Accordingly, the Service Provider shall not do any act that adversely affects TapTap and undertakes to be in compliance with Applicable Law at all times and protect the brand image, business reputation or any other asset/property of TapTap.",
  "11. While logged-in TapTap the Service Provider shall not engage in any illegal activity or perform any actions that are contrary to Applicable Law.",
  "12. All Confidential Information procured shall at all times be kept confidential and used only for the limited permitted purposes of rendering Vendor Services.",
  "13. The Service Provider is not entitled to claim reimbursement of hospitalization/ hospital bills that may be incurred by the Service Provider while rendering Vendor Services or incurred by his family members for any unfortunate accidents or severe illness, during the subsistence of Agreement and these Service Provider T&C.",
  "14. TapTap shall, with regard to clause 2.14 of this Agreement and on a best effort basis, try to reimburse costs, expenses and losses, if any, incurred by You towards any legal expenses (including but not limited to charges incurred for releasing of seized vehicles, advocate’s fees, ancillary expenses) while completing the trip, for no fault of yours. The reimbursement, in any case, shall not be more than INR 10,000 /- (Rupees Ten Thousand) and shall be subject to the charges applicable to the particular City Court and/or State, whichever is lesser. This reimbursement shall be done only after internal investigation and on verification of facts by TapTap on a case-to-case basis.",
  "15. The Service Provider shall not resort to the below mentioned acts/ commission/ omission of events:",
  "a. Any violation or breach of any term of these T&Cs;",
  "b. If the Service Provider, in the opinion of TapTap, misuses TapTap;",
  "c. failure to verify or authenticate Service Provider Information;",
  "d. Misbehaviour, rude behaviour with the staff of TapTap, customers or any other persons associated with TapTap;",
  "e. Any acts involving criminal offence punishable under law, including physical assault, threatening any of the staff of TapTap, person associated with TapTap and any other persons;",
  "f. Concealment of fact/material information while entering into a contract with TapTap;",
  "g. Poor & irregular for work, meetings and failure to abide by the rules/terms of the contract;",
  "h. Drunk while on duty and drunken behaviour;",
  "i. Commission of fraud/ misappropriation/embezzlement for undue monetary gain, which is against the interest of TapTap;",
  "j. Negligence in performing the duty, causing damage of moveable and immoveable assets of TapTap, its employees, customers;",
  "k. Indulging in spreading content through digital media, social networking sites, or any other form, which could be detrimental to TapTap's brand and its image;",
  "l. Indulging in acts such as creating ruckus/ strike/ or any activity against TapTap, which could be detrimental to TapTap's brand and its image;",
  "m. Indulging in unauthorized disclosure of confidential information of TapTap to external agency, person, TapTap or organization;",
  "n. Misuse of assets provided by TapTap, which could be detrimental to the interest of TapTap's brand and its image;",
  "o. Absconding for more than 4 hours with any asset, item, money or any other valuable item belonging to TapTap, its employees, customers or other staff member(s);",
  "p. Failure to abide by any of the rules and guidelines given by TapTap as part of service quality standards and principles and/or Code of Conduct; or",
  "q. In case the background check, whether wholly or partially, is found negative at any point of time during the term of these T&Cs.",
  // {
  //     "Vehicle Details": {
  //         "Vehicle Mfg year": "As per the attached Vehicle documents",
  //         "Vehicle Model": "As per the attached Vehicle documents",
  //         "Vehicle Registration No": "As per the attached Vehicle documents",
  //         "Vehicle owner": "As per the attached Vehicle documents",
  //         "Copy of all documents": "Attached"
  //     }
  // },
  // {
  //     "Service Provider Details": {
  //         "Is Owner the Service Provider": "Y/N",
  //         "If No": {
  //             "Name": "As per attached details",
  //             "Address": "As per attached details",
  //             "Contact Details": "As per attached details",
  //             "No Objection Certificate": "Appended"
  //         }
  //     }
  // },
  // {
  //     "Driver Details": {
  //         "Driver's Name": "As per documents attached",
  //         "Contact Number Issued for TapTap": "As per documents attached",
  //         "Personal Contact Number": "As per documents attached",
  //         "License Number": "As per documents attached",
  //         "License Expiration Date": "As per documents attached",
  //         "Aadhaar No": "As per documents attached"
  //     }
  // },
  // {
  //     "Bank Account Details": {
  //         "Bank Name": "As per documents attached",
  //         "Account No": "As per documents attached",
  //         "Account Holder’s Name": "As per documents attached",
  //         "Branch Name and Address": "As per documents attached",
  //         "IFSC Code": "As per documents attached"
  //     }
  // },
  // {
  //     "Statutory Details": {
  //         "Permanent Account Number": "As per documents attached",
  //         "GSTIN (if any)": "As per documents attached",
  //         "Aadhaar No (if any)": "As per documents attached",
  //         "CIN (if any)": "As per documents attached"
  //     }
  // },
  // {
  //     "Documents Attached": [
  //         "Cancelled cheque specifying the bank account information of the Service Provider",
  //         "Copy of PAN Card (of the Service Provider)",
  //         "Proof of the GSTIN (of the Service Provider)",
  //         "Copy of Driving License (of the Driver)",
  //         "Copy of Aadhaar Card (of both Service Provider and Driver)",
  //         "Vehicle Registration Certificate and Documents",
  //         "PUC",
  //         "Fitness Certificate"
  //     ]
  // },
  // {
  //     "Declaration": {
  //         "Statement": "For & on behalf of the Service Provider, I declare that the above information is correct. Any changes in the above information will be duly informed by me to TapTap in advance."
  //     }
  // },
  // {
  //     "No Objection Certificate": {
  //         "Title": "TO WHOM IT MAY CONCERN",
  //         "Authorization": "I am the owner of the Vehicle. I am hereby authorizing the Service Provider to engage my Vehicle and enter into the Service Agreement with Kabra Express Logitech Private Limited.",
  //         "Compensation": "I also authorize the Service Provider to collect any payments or compensation against the services.",
  //         "No Objection": "I have no objection on any of the above commercial transactions with TapTap.",
  //         "Thanking You": {
  //             "Owner": {
  //                 "Name": "[As per the document attached]",
  //                 "Phone Number": "[As per the document attached]"
  //             }
  //         }
  //     }
  // }
]
;

export const footerLinks = [
  { img: facebookImg, link: "https://www.facebook.com/KabraExpressLogistics" },
  { img: instagramImg, link: "https://www.instagram.com/kabra.express/" },
  {
    img: linkedinImg,
    link: "https://www.linkedin.com/company/kabra-express-logistics-private-limited/",
  },
  {
    img: youtubeImg,
    link: "https://www.youtube.com/channel/UCt4wx_zJAyQ-el--Jy3iurQ",
  },
];

// export const mobileLoginCards = [
//   {
//     id: 0,
//     title: "Transport Company",
//     description: "",
//     icon: [TransportCompanyIcon]
//   },
//   {
//     id: 1,
//     title: "Customer",
//     description: "",
//     icon: [CustomerIcon]
//   },
//   {
//     id: 2,
//     title: "Individual with Fleet",
//     description: "",
//     icon: [BikeSvg, TempoSvg]
//   },
// ] as const;