import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useCallback,
  FunctionComponent,
  useMemo,
  ReactNode,
} from "react";
import Webcam from "react-webcam";
import { compressImageFile } from "../utils/compressImage";
import ImageCompression from "browser-image-compression/dist/browser-image-compression";
import { getSignedUrl } from "../services/utils";
import Axios from "axios";
import "./imageInput.css";
import { ArrowRotateLeftIcon, ArrowRotateRightIcon, DeleteIcon, GalleryIcon } from "../newSrc/constants/icons";
import { Button, Dialog, Tooltip } from "../newSrc/components/UI";
import ProgressBar from "../containers/Home/ProgressBar";
import { DownloadButton } from "./Button/AllButton";

interface ImageInputProps {
  name: string;
  Icon?: React.ElementType;
  onChange: (file: null | File | File[]) => void;
  value: any;
  extras?: JSX.Element;
  compressImage?: boolean;
  multiple?: boolean;
  acceptPdf?: boolean;
  info?: string;
  onlyCamera?: boolean;
  height?: any;
  width?: any;
  padding?: any;
  margin?: any;
  style?: any;
  trigger?:any;
  handleCloseTrigger?:any
  disableExtrasClickClose?: boolean
  label?: string;
  children?: ReactNode;
  wantTooltip?: boolean;
  isDownload?:boolean;
}

const ImageInput: FunctionComponent<ImageInputProps> = ({
  name,
  Icon = GalleryIcon,
  onChange,
  value,
  extras,
  compressImage,
  multiple,
  acceptPdf,
  info,
  onlyCamera,
  height,
  width,
  margin,
  style,
  trigger,
  handleCloseTrigger,
  disableExtrasClickClose,
  label,
  children,
  isDownload,
  wantTooltip = true
}) => {
  const [openImageDialog, setImageDialog] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<any>(value)
  const [compressing, setCompressing] = useState(false);
  const [rotateDeg, setRotateDeg] = useState(0);
  const [zoomDisplay, setZoomDisplay] = useState(false);
  const [deviceId, setDeviceId] = React.useState({});
  const [devices, setDevices] = React.useState<
    { deviceId?: any; label?: any }[]
  >([]);
  console.log({ imageUrl, value })

  const handleDevices = React.useCallback(
    (mediaDevices: any) =>
      setDevices(mediaDevices.filter(({ kind }: any) => kind === "videoinput")),
    [setDevices]
  );

  React.useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  useEffect(() => {
    const fetchData = async () => {
      const a: any = document.getElementById("inputimage" + name);
      if (a) {
        a.value = "";
      }
      if (!value) {
        return setImageUrl(null);
      }
      if (typeof value === "object") {
        if (value.constructor === Blob || value.constructor === File) {
          // For single file, create object URL or use file name for PDF
          return setImageUrl(
            value.type === 'application/pdf' 
              ? value 
              : window.URL.createObjectURL(value)
          );
        }
        if (
          value.constructor === Array &&
          value.length > 0 &&
          value[0]
        ) {
          // For multiple files
          if (value[0].constructor === Blob || value[0].constructor === File) {
            return setImageUrl(
              value.map(file => 
                file.type === 'application/pdf' 
                  ? file 
                  : window.URL.createObjectURL(file)
              )
            );
          }
          if (typeof value[0] === "string") {
            return setImageUrl(
              await Promise.all(
                (value).map(async (v) => {
                  try {
                    const newUrl =
                      v.includes("https://") || v.includes("http://")
                        ? v
                        : await getSignedUrl(v);
                    const s = newUrl.split("?");
                    if (/(\.pdf|\.png|\.jpg|\.jpeg)$/.test(s[0])) {
                      return newUrl;
                    } else {
                      return (await Axios.get(newUrl)).data;
                    }
                  } catch (err : any) {
                    console.error("Error fetching URL:", err);
                    return null;
                  }
                })
              )
            );
          }
        }
      }
      if (typeof value === "string") {
        try {
          const newUrl =
            value.includes("https://") || value.includes("http://")
              ? value
              : await getSignedUrl(value);
          const s = newUrl.split("?");
          if (/(\.pdf|\.png|\.jpg|\.jpeg)$/.test(s[0])) {
            return setImageUrl(newUrl);
          } else {
            return setImageUrl((await Axios.get(newUrl)).data);
          }
        } catch (err : any) {
          console.error("Error fetching URL:", err);
        }
      }

      setImageUrl(null);
    };
    fetchData();
  }, [value]);

  const toggleImageDialog = useCallback(() => {
    setImageDialog(!openImageDialog);
    if(openImageDialog){
      handleCloseTrigger?.()
    }
  }, [openImageDialog]);

  useEffect(() => {
    if(trigger){
      toggleImageDialog()
    }
  }, [trigger]);

  
  const ClearImage = useCallback(() => {
    onChange(null);                  
    setImageUrl(null);                  
  }, []);

  const uploadImageFromSystem = useCallback(() => {
    const a = document.getElementById("inputimage" + name);
    if (a) {
      a.click();
    }
  }, []);

  const changeFileInput = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      setCompressing(true);
      if (e.target.files && e.target.files.length > 0) {
        const processedFiles = await Promise.all(
          Array.from(e.target.files).map(async (file: any) => {
            if (/\.pdf$/i.test(file.name)) {
              return file;
            } else {
              if (compressImage !== false) {
                const newFile: any = await compressImageFile(file);
                newFile.name = file.name;
                return newFile;
              } else {
                return file;
              }
            }
          })
        );
        if (multiple !== true) {
          onChange(processedFiles[0]);
        } else {
          onChange(processedFiles);
        }
      }
      setCompressing(false);
    },
    []
  );

  const webcamRef: any = useRef(null);
  const capture = useCallback(async () => {
    if (webcamRef.current) {
      const imageSrc = (webcamRef.current).getScreenshot();
      const ImageName = name + Date.now() + ".jpeg";
      const imageFile: any = await ImageCompression.getFilefromDataUrl(
        imageSrc,
        ImageName
      );
      if (compressImage !== false) {
        const newFile: any = await compressImageFile(imageFile);
        if (multiple === true) {
          onChange([newFile]);
        } else {
          onChange(newFile);
        }
      } else {
        if (multiple === true) {
          onChange([imageFile]);
        } else {
          onChange(imageFile);
        }
      }
    }
  }, [webcamRef]);

  const [permissionError, setPermissionError] = useState({
    status: true,
    msg: "",
  });

  const rotateRight = useCallback(() => {
    let x = rotateDeg;
    x += 90;
    setRotateDeg(x);
  }, [rotateDeg]);

  const rotateLeft = useCallback(() => {
    let x = rotateDeg;
    x -= 90;
    setRotateDeg(x);
  }, [rotateDeg]);

  const zoom = useCallback(() => {
    setZoomDisplay(!zoomDisplay);
  }, [zoomDisplay]);

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ video: true }).then(
      (stream) => {
        setPermissionError({ status: false, msg: "" });
        stream.getTracks().forEach(function(track) {
          if (track.readyState == "live" && track.kind === "video") {
            track.stop();
          }
        });
      },
      (_error) => {
        setPermissionError({ status: true, msg: _error.message });
      }
    );
  }, []);

  const WrappedIcon: React.ElementType = useMemo(
    () =>
      React.forwardRef<any>((props, ref) => {
        return <Icon {...props} ref={ref} />;
      }),
    [Icon]
  );

  const renderFilePreview = (url: any) => {
    // Handle PDF file differently
    if (url instanceof File && url.type === 'application/pdf') {
      // Create a URL for the PDF file
      const pdfUrl = URL.createObjectURL(url);
      return (
        <div style={{ width: "100%", height: "500px", overflow: "hidden" }}>
          <object 
            data={pdfUrl} 
            type="application/pdf" 
            width="100%" 
            height="100%"
          >
            <p>Your browser doesn't support PDF preview. 
              <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
                Click here to download the PDF
              </a>
            </p>
          </object>
        </div>
      );
    }
    
    // Existing image preview logic
    if (typeof url === "string" && url.includes(".pdf")) {
      return (
        <div style={{ width: "100%", height: "500px", overflow: "hidden" }}>
          <object 
            data={url} 
            type="application/pdf" 
            width="100%" 
            height="100%"
          >
            <p>Your browser doesn't support PDF preview. 
              <a href={url} target="_blank" rel="noopener noreferrer">
                Click here to download the PDF
              </a>
            </p>
          </object>
        </div>
      );
    } else if (typeof url === "string") {
      return (
        <img
          className={zoomDisplay ? "zoom" : ""}
          src={url}
          alt="Preview"
          style={{
            width: "auto",
            height: "auto",
            maxWidth:'800px',
            maxHeight:'800px',
            objectFit: "contain",
            transform: `rotate(${rotateDeg}deg)`,
          }}
          onDoubleClick={zoom}
        />
      );
    } else {
      // Fallback for unexpected url types
      return (
        <div style={{ textAlign: "center", color: "red" }}>
          <p>Unsupported content type</p>
        </div>
      );
    }
  };


  return (
    <>
      <div style={{ border: label  ? "1px dashed var(--clr-white-400)" : "", padding : label ? '1rem' : '0' }} onClick={toggleImageDialog} title={wantTooltip ? name : ""} className="fs-link flex ai-center gap-1 br-4">
        <WrappedIcon
          onClick={toggleImageDialog}
          style={{
            ...(width ? { width: width } : {}),
            ...(height ? { height: height } : {}),
            borderRadius: 4,
            cursor: "pointer",
            flexShirnk: 0,
            ...(margin?{margin: margin}:{}),
            ...(style?{...style}:{}),
            color: imageUrl || value ? "green" : "var(--clr-black-900)"
          }}
        />
        {label && <label className="m-0 fw-500">{label}</label>}
        {children}
      </div>
      <input
        id={"inputimage" + name}
        type="file"
        multiple={multiple === true}
        onChange={changeFileInput}
        accept={
          acceptPdf
            ? "application/pdf, image/png, image/jpg, image/jpeg"
            : "image/png, image/jpg, image/jpeg"
        }
        style={{ display: "none" }}
      />
      <Dialog
        state={openImageDialog}
        setState={setImageDialog}
        header="Uploading Image"
        size="regular"
        body={
          <>
            {info && <h4>{info}</h4>}
            {compressing === true ? (
              <div style={{ textAlign: "center" }}>
                <ProgressBar />
              </div>
            ) : imageUrl ? (
              Array.isArray(imageUrl) ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    overflowX: "scroll",
                    overflowY: "hidden",
                  }}
                >
                  {imageUrl.map((src: any) => (
                    <div key={src}>
                      {renderFilePreview(src)}
                      <div className="pointer">
                        <Tooltip title="Rotate left" position="top">
                          <ArrowRotateLeftIcon onClick={rotateLeft} />
                        </Tooltip>
                        <Tooltip title="Rotate right" position="top">
                          <ArrowRotateRightIcon onClick={rotateRight} />
                        </Tooltip>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  {renderFilePreview(imageUrl)}
                  <div className="flex jc-center fs-link ai-center my-1 pointer gap-1">
                    <Tooltip title="Rotate left" position="top">
                      <ArrowRotateLeftIcon onClick={rotateLeft} />
                    </Tooltip>
                    <Tooltip title="Rotate right" position="top">
                      <ArrowRotateRightIcon onClick={rotateRight} />
                    </Tooltip>
                  </div>
                </div>
              )
            ) : permissionError.status === true ? (
              <div>
                <h3>Could not access Camera</h3>
                <h4>
                  Ensure that camera exists and permission to access camera is
                  given.
                </h4>
                <h4 className="bg-danger-50 text-danger-900 max-content py-8 px-1 br-4">
                  <span>Error: {permissionError.msg}</span>
                </h4>
              </div>
            ) : (
              <>
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  width={"100%"}
                  videoConstraints={{ deviceId }}
                />
                <div>
                  {devices.map((device, key) => (
                    <button
                      key={device.deviceId}
                      onClick={() => setDeviceId(device.deviceId)}
                    >
                      {`Camera ${key + 1}`}
                    </button>
                  ))}
                </div>
              </>
            )}
          </>
        }
        footer={
          <>
            {imageUrl ? (
              <Fragment>
                <div
                  onClick={() =>
                    !disableExtrasClickClose && toggleImageDialog()
                  }
                >
                  {extras}
                </div>
                <div className="flex gap-1 ai-center">
                  {isDownload && (
                    <a href={imageUrl}>
                      <DownloadButton />
                    </a>
                  )}
                  <Button
                    className="flex gap-1 ai-center"
                    variant="danger"
                    action="secondary"
                    onClick={ClearImage}
                  >
                    <DeleteIcon />
                    <p>Remove</p>
                  </Button>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                {permissionError.status !== true && (
                  <Fragment>
                    {!disableExtrasClickClose && (
                      <div onClick={() => toggleImageDialog()}>{extras}</div>
                    )}
                    <div>
                      <div style={{ textAlign: "center" }}>
                        <button
                          className="button-primary-secondary fs-popup-btn flex-center gap-8 p-1 br-4"
                          onClick={capture}
                        >
                          <GalleryIcon />
                          <p style={{ textTransform: "uppercase" }}>capture</p>
                        </button>
                      </div>
                    </div>
                  </Fragment>
                )}
                {!onlyCamera && (
                  <div
                    style={{
                      display: "inline-block",
                      margin: "0 4px",
                      textAlign: "center",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <button
                      className={`button-primary-secondary fs-popup-btn flex-center gap-8 p-1 br-4`}
                      onClick={uploadImageFromSystem}
                    >
                      <GalleryIcon className="text-primary-900" />
                      <p
                        className="text-primary-900"
                        style={{ textTransform: "uppercase" }}
                      >
                        upload
                      </p>
                    </button>
                  </div>
                )}
              </Fragment>
            )}
          </>
        }
      />

      
    </>
  );
};

export default ImageInput;
