import {
  CCTVIcon,
  CashOnDeliveryIcon,
  ConveniantDeliveryIcon,
  NotificationIcon,
  OnTimeDeliveryIcon,
  QualityMattersIcon,
} from "../../../../constants/icons";

export const ourFeatures = [
  {
    id: 0,
    icon: OnTimeDeliveryIcon,
    heading: "On time, every time",
    isLock: false,
    description:
      "Our priority is your time. We know how important it is for you to get your things on time, every time.",
  },
  {
    id: 1,
    icon: QualityMattersIcon,
    heading: "Quality Matters",
    isLock: false,
    description:
      "We only work with the best quality products to ensure that you get the best experience possible.",
  },
  {
    id: 2,
    icon: ConveniantDeliveryIcon,
    heading: "Convenient Delivery",
    isLock: false,
    description:
      "We deliver to your doorstep, so you can just sit back and relax.",
  },
  {
    id: 3,
    icon: CashOnDeliveryIcon,
    heading: "Cash On Delivery(COD)",
    isLock: false,
    description:
      "We provide you with the option to pay so that you can pay us only when you receive your product.",
  },
  {
    id: 4,
    icon: NotificationIcon,
    heading: "Notification at Every Stage",
    isLock: false,
    description:
      "You will be updated and notified at every stage of the packing and moving process so that you know where your product is at all times.",
  },
  {
    id: 5,
    icon: CCTVIcon,
    heading: "CCTV Surveillance",
    isLock: false,
    description:
      "We have set up in our facility so that your product is always safe and secure.",
  },
];

export const individualWithFleetFeatures = [
  {
    id: 0,
    icon: OnTimeDeliveryIcon,
    heading: "Easy Signup Process",
    description:
      "Get started quickly online with a simple registration process and no additional costs.",
    isLock: false,
  },
  {
    id: 1,
    icon: QualityMattersIcon,
    heading: "Easy & Convenient Application",
    description:
      "Become today's delivery partners through an easy and convenient application process.",
    isLock: false,
  },
  {
    id: 2,
    icon: ConveniantDeliveryIcon,
    heading: "Training & support",
    description:
      "Dedicated driver partner assistance ensures support at every step of your journey.",
    isLock: false,
  },
  {
    id: 3,
    icon: CashOnDeliveryIcon,
    heading: "Your Flexible Time",
    description:
      "Take charge of your ride and city with a flexible schedule, full-time or part-time options, and the freedom of using your vehicle.",
    isLock: false,
  },
  {
    id: 4,
    icon: NotificationIcon,
    heading: "Monthly High Earning",
    description:
      "Boost your income with regular trips, ensuring consistent monthly earnings of 50,000+.",
    isLock: false,
  },
  {
    id: 5,
    icon: CCTVIcon,
    heading: "Competitive Pay",
    description:
      "Our company offers competitive pay to reward our delivery partners' hard work.",
    isLock: false,
  },
  {
    id: 6,
    icon: OnTimeDeliveryIcon,
    heading: "Weekly Payout",
    description:
      "You can enjoy hassle-free and regular cashouts with easy weekly payments.",
    isLock: false,
  },
  {
    id: 7,
    icon: QualityMattersIcon,
    heading: "Leave Policy",
    description:
      "Become today's delivery partners through an easy and convenient application process.",
    isLock: true,
  },
  {
    id: 8,
    icon: ConveniantDeliveryIcon,
    heading: "Essentials welcome kit",
    description:
      "Your welcome kit includes an ID card, safety kit, mobile cover, raincoat, hoodie, helmet, cap, water bottle, and shoes.",
    isLock: true,
  },
  {
    id: 9,
    icon: CashOnDeliveryIcon,
    heading: "Discount Bundle",
    description:
      "Enjoy discounts on fuel, vehicle purchases, and spare parts, including tyres.",
    isLock: true,
  },
  {
    id: 10,
    icon: NotificationIcon,
    heading: "Insurance",
    description:
      "Your insurance package includes accidental, medical, life, and vehicle coverage for comprehensive employee protection.",
    isLock: true,
  },
  {
    id: 11,
    icon: CCTVIcon,
    heading: "Community Building",
    description:
      "Join the movement and enjoy your city like never before, where boredom is far from your experience.",
    isLock: true,
  },
  {
    id: 12,
    icon: CCTVIcon,
    heading: "Incentive Income",
    description:
      "Join Delivery Partners for bonuses and incentives, exciting contests, and fulfilling missions to advance your career.",
    isLock: true,
  },
];

export const intraCityFeatures = [
  {
    id: 0,
    icon: OnTimeDeliveryIcon,
    heading: "API Integration",
    description:
      "Integrate our delivery into your website to fully automate the ordering process.",
    isLock: false,
  },
  {
    id: 1,
    icon: QualityMattersIcon,
    heading: "Modules Platform",
    description:
      "Add the TapTap delivery module to the check-out form of your Shopify-based store.",
    isLock: false,
  },
  {
    id: 2,
    icon: ConveniantDeliveryIcon,
    heading: "Bulk Ordering",
    description:
      "For all registered users = upload a spreadsheet to automatically create a list of orders.",
    isLock: false,
  },
  {
    id: 3,
    icon: CashOnDeliveryIcon,
    heading: "TapTap Wallet",
    description:
      "Store funds securely for quick transactions within TapTap, simplifying payments.",
    isLock: false,
  },
  {
    id: 4,
    icon: NotificationIcon,
    heading: "Real-time Tracking",
    description: "Providing customers with live updates on the location.",
    isLock: false,
  },
  {
    id: 5,
    icon: CCTVIcon,
    heading: "Save more on logistical costs",
    description:
      "Easy management to fleet with just a click. Book a TapTap rider whenever needed any time of the day.",
    isLock: false,
  },
  {
    id: 6,
    icon: OnTimeDeliveryIcon,
    heading: "Free Consultation and Registration",
    description:
      "Let us help you and give you the right business services and offer the best package.",
    isLock: false,
  },
  {
    id: 7,
    icon: QualityMattersIcon,
    heading: "Full Product Insurance",
    description:
      "We will compensate the value of lost items within three working days according to the rules. Maximum compensation - 50.000.",
    isLock: false,
  },
  {
    id: 8,
    icon: ConveniantDeliveryIcon,
    heading: "Order Scheduling",
    description:
      "Schedule your orders up to 2 days in advance for planned pick-up and drop.",
    isLock: false,
  },
  {
    id: 9,
    icon: CashOnDeliveryIcon,
    heading: "Multiple Drop Points",
    description:
      "Use one pick-up to deliver to multiple customers in the same order and save money.",
    isLock: false,
  },
  {
    id: 10,
    icon: NotificationIcon,
    heading: "24/7 Support",
    description:
      "Get instant help for all your order-related queries over live chat.",
    isLock: false,
  },
  {
    id: 11,
    icon: CCTVIcon,
    heading: "Customer Pays",
    description:
      "Pass on the delivery charges to your customer, who can pay when collecting the order.",
    isLock: false,
  },
  {
    id: 12,
    icon: CCTVIcon,
    heading: "Proof of Delivery",
    description: "Get proof of delivery via OTP & image verification.",
    isLock: false,
  },
  {
    id: 13,
    icon: NotificationIcon,
    heading: "Download the Android App",
    description: "Use the Android app to create & track orders easily.",
    isLock: false,
  },
  {
    id: 14,
    icon: CCTVIcon,
    heading: "Use the Web Dashboard",
    description:
      "Access our online dashboard using a mobile or desktop browser.",
    isLock: false,
  },
  {
    id: 15,
    icon: OnTimeDeliveryIcon,
    heading: "Low Fare",
    description: "Affordable shipping options with competitive pricing.",
    isLock: false,
  },
  {
    id: 16,
    icon: QualityMattersIcon,
    heading: "Fast Delivery",
    description: "Swift and punctual transportation services.",
    isLock: false,
  },
  {
    id: 17,
    icon: ConveniantDeliveryIcon,
    heading: "Reliable Service",
    description: "Consistent and trustworthy logistics solutions.",
    isLock: false,
  },
  {
    id: 18,
    icon: CashOnDeliveryIcon,
    heading: "Vehicle Variety",
    description: " Diverse fleet to handle various logistical requirements.",
    isLock: false,
  },
  {
    id: 19,
    icon: NotificationIcon,
    heading: "Cash on Delivery",
    description: "Same Day COD amount remittance to your account.",
    isLock: false,
  },
  {
    id: 20,
    icon: CCTVIcon,
    heading: "Buyout by our Courier",
    description: "Buy items directly from the courier delivering your package.",
    isLock: false,
  },
  {
    id: 21,
    icon: CCTVIcon,
    heading: "Additional Cost",
    description:
      "If you want labor we will provide with at an Additional Cost.",
    isLock: false,
  },
];

export const partLoadAndFullLoadFeatures = [
  {
    id: 0,
    icon: OnTimeDeliveryIcon,
    heading: "Verified drivers with secure insurance",
    description: "",
    isLock: false,
  },
  {
    id: 1,
    icon: QualityMattersIcon,
    heading: "24/7 support with tracking",
    description: "",
    isLock: false,
  },
  {
    id: 2,
    icon: ConveniantDeliveryIcon,
    heading: "Real-time GPS order tracking",
    description: "",
    isLock: false,
  },
  {
    id: 3,
    icon: CashOnDeliveryIcon,
    heading: "SIM-enabled tracking",
    description: "",
    isLock: false,
  },
  {
    id: 4,
    icon: NotificationIcon,
    heading: "FASTag Gold feature to avoid blacklisting",
    description: "",
    isLock: false,
  },
  {
    id: 5,
    icon: CCTVIcon,
    heading: "Tech-driven process for credit eligibility",
    description: "",
    isLock: false,
  },
  {
    id: 6,
    icon: OnTimeDeliveryIcon,
    heading: "Commercial vehicle loans",
    description: "",
    isLock: false,
  },
  {
    id: 7,
    icon: QualityMattersIcon,
    heading: "Attractive cash back",
    description: "",
    isLock: false,
  },
  {
    id: 8,
    icon: ConveniantDeliveryIcon,
    heading: "Comprehensive logistics solutions",
    description: "",
    isLock: false,
  },
  {
    id: 9,
    icon: CashOnDeliveryIcon,
    heading: "Material handling & packaging",
    description: "",
    isLock: false,
  },
  {
    id: 10,
    icon: NotificationIcon,
    heading: "On-time delivery",
    description: "",
    isLock: false,
  },
  {
    id: 11,
    icon: CCTVIcon,
    heading: "Pay for the space of your cargo",
    description: "",
    isLock: false,
  },
  {
    id: 12,
    icon: CCTVIcon,
    heading: "Insights for better decisions",
    description: "",
    isLock: false,
  },
  {
    id: 13,
    icon: NotificationIcon,
    heading: "Advance booking",
    description: "",
    isLock: false,
  },
  {
    id: 14,
    icon: NotificationIcon,
    heading: "Lowest Out of Delivery Area (ODA) Pincode",
    description: "",
    isLock: false,
  },
  {
    id: 15,
    icon: NotificationIcon,
    heading: "Appointment-based deliveries to warehouses and malls.",
    description: "",
    isLock: false,
  },
  {
    id: 16,
    icon: NotificationIcon,
    heading: "e-POD, OTP verified delivery, and more value-added services",
    description: "",
    isLock: false,
  },
  {
    id: 17,
    icon: NotificationIcon,
    heading: "Reduced risk on Freight with consignment protection",
    description: "",
    isLock: false,
  },
];

export const expressParcelBranchToBranchFeature = [
  {
    id: 0,
    icon: OnTimeDeliveryIcon,
    heading: "Next day morning branch delivery",
    description: "",
    isLock: false,
  },
  {
    id: 1,
    icon: QualityMattersIcon,
    heading: "Courteous and helping staff at our branches",
    description: "",
    isLock: false,
  },
  {
    id: 2,
    icon: ConveniantDeliveryIcon,
    heading: "Computerized receipt",
    description: "",
    isLock: false,
  },
  {
    id: 3,
    icon: CashOnDeliveryIcon,
    heading: "Booking notification via SMS",
    description: "",
    isLock: false,
  },
  {
    id: 4,
    icon: NotificationIcon,
    heading: "SMS notification when consignment unloaded at the destination branch",
    description: "",
    isLock: false,
  },
  {
    id: 5,
    icon: CCTVIcon,
    heading: "SMS notification at the time of delivery",
    description: "",
    isLock: false,
  },
  {
    id: 6,
    icon: OnTimeDeliveryIcon,
    heading: "All our branches are under CCTV surveillance",
    description: "",
    isLock: false,
  },
  {
    id: 7,
    icon: QualityMattersIcon,
    heading: "Monthly transaction report",
    description: "",
    isLock: false,
  },
  {
    id: 8,
    icon: ConveniantDeliveryIcon,
    heading: "Competitive rate",
    description: "",
    isLock: false,
  },
  {
    id: 9,
    icon: CashOnDeliveryIcon,
    heading: "Insurance service available",
    description: "",
    isLock: false,
  },
  {
    id: 10,
    icon: NotificationIcon,
    heading: "Credit Facility",
    description: "",
    isLock: false,
  },
  {
    id: 11,
    icon: CCTVIcon,
    heading: "Customer care number to resolve customer grievances",
    description: "",
    isLock: false,
  },
  {
    id: 12,
    icon: CCTVIcon,
    heading: "Cash on delivery service available",
    description: "",
    isLock: false,
  },
  {
    id: 13,
    icon: NotificationIcon,
    heading: "Bulk discount",
    description: "",
    isLock: false,
  },
  {
    id: 14,
    icon: NotificationIcon,
    heading: "Contact with local intracity transporter for door delivery",
    description: "",
    isLock: false,
  },
  {
    id: 15,
    icon: NotificationIcon,
    heading: "Closed container to safeguard against theft and rain",
    description: "",
    isLock: false,
  },
  {
    id: 16,
    icon: NotificationIcon,
    heading: "Pay to Pay",
    description: "",
    isLock: false,
  },
];

export const expressParcelDoorDeliveryFeature = [
  {
    id: 0,
    icon: OnTimeDeliveryIcon,
    heading: "Door-to-Door Delivery",
    description: "",
    isLock: false,
  },
  {
    id: 1,
    icon: QualityMattersIcon,
    heading: "Specialized courier service for remote locations",
    description: "",
    isLock: false,
  },
  {
    id: 2,
    icon: ConveniantDeliveryIcon,
    heading: "Bulk order creation",
    description: "",
    isLock: false,
  },
  {
    id: 3,
    icon: CashOnDeliveryIcon,
    heading: "Auto-documentation",
    description: "",
    isLock: false,
  },
  {
    id: 4,
    icon: NotificationIcon,
    heading: "Shipping rate calculator",
    description: "",
    isLock: false,
  },
  {
    id: 5,
    icon: CCTVIcon,
    heading: "AI recommendations",
    description: "",
    isLock: false,
  },
  {
    id: 6,
    icon: OnTimeDeliveryIcon,
    heading: "Real-time tracking",
    description: "",
    isLock: false,
  },
  {
    id: 7,
    icon: QualityMattersIcon,
    heading: "Affordable rates",
    description: "",
    isLock: false,
  },
  {
    id: 8,
    icon: ConveniantDeliveryIcon,
    heading: "Dedicated support",
    description: "",
    isLock: false,
  },
  {
    id: 9,
    icon: CashOnDeliveryIcon,
    heading: "Place an order in under 5 minutes",
    description: "",
    isLock: false,
  },
  {
    id: 10,
    icon: NotificationIcon,
    heading: "Address validation",
    description: "",
    isLock: false,
  },
  {
    id: 11,
    icon: CCTVIcon,
    heading: "Proof of Delivery",
    description: "",
    isLock: false,
  },
  {
    id: 12,
    icon: CCTVIcon,
    heading: "30+ years of experience with a wide network",
    description: "",
    isLock: false,
  }
];

export const ourFeaturesData = [
  {
    id: 0,
    page: "partners",
    partner: "Individual With Fleet",
    features: individualWithFleetFeatures,
  },
  {
    id: 1,
    page: "services",
    service: "Intracity",
    features: intraCityFeatures,
  },
  {
    id: 2,
    page: "services",
    service: "Part Load & Full Load",
    features: partLoadAndFullLoadFeatures,
  },
  {
    id: 3,
    page: "services",
    service: "Intercity Express Parcel",
    expressParcelType: "Branch to Branch",
    features: expressParcelBranchToBranchFeature,
  },
  {
    id: 4,
    page: "services",
    service: "Intercity Express Parcel",
    expressParcelType: "Door Delivery",
    features: expressParcelDoorDeliveryFeature,
  },
];
