import { fromJS } from "immutable";

import { validateCompanydoc } from "./schemas/companydoc";

import {
  GET_COMPANY_REQ_SUCCESS,
  EDIT_COMPANY_REQ_SUCCESS,
  CHANGE_ADD_EXPENSE_TYPE,
  SUBMIT_EXPENSE_SUCCESS,
  GET_EXPENSE_TYPES,
  CHANGE_FIXED_EXPENSE,
  CHANGE_ENTITY_ID,
  SUBMIT_FIXED_EXPENSE_SUCCESS,
  CHANGE_ADD_INCOME_TYPE,
  SUBMIT_INCOME_TYPE_FAILED,
  SUBMIT_INCOME_TYPE_SUCCESS,
  CHANGE_SUB_INCOME_TYPE,
  ADD_SUB_INCOME_TYPE,
  ADD_SUB_INCOME_TYPE_FAILED,
  SET_INCOME_TYPE_FOR_EDIT,
  EDIT_INCOME_TYPE_SUCCESS,
  INCOME_TYPE_DELETED,
  RESET_INCOME_TYPE_FORM,
  SET_SUBTYPES_FOR_EDIT,
  GET_POSTED_SUB_TYPES,
  EDIT_SUB_INCOME_DATA,
  RESET_SUB_INCOME_TYPE,
  SET_EXPENSE_TYPE,
  RESET_EXPENSE_TYPE,
  DELETE_EXPENSE_TYPE,
  RESET_FIXED_EXPENSE,
  GET_FIXED_EXPENSE_BY_ID,
  SET_FIXED_EXPENSE_TYPE,
  EDIT_FIXED_EXPENSE_SUCCESS,
  DELETE_FIXED_EXPENSE_TYPE,
  SET_ASSET_TYPES,
  EDIT_EXPENSE_SUCCESS,
  GET_COMPANY_LIST,
  GET_VEHICLE_ID,
  CHANGE_COMPANY_DOC,
  SET_COMPANY_DOC,
  CLEAR_COMPANY_DOC,
} from "../constants/company";
import { showMessage, failed, success } from "../utils/message";
import {
  add_Expense_Boolean,
  add_Fixed_Expense_Boolean,
  add_Expense_Validation,
  add_Fixed_Expense_Validation,
  add_Sub_Income_Boolean,
  add_Sub_Income_Validation,
  validateExpense,
} from "./schemas/addExpense";

const initExpenseType = {
  type: "add",
  name: "",
  fleet: false,
  branch: false,
  general: false,
  variance: "V",
  sub: "",
  isVendorOtpService: false,
  is_route: "",
  duration: false,
  monthly_duration: false,
  company: "",
  category: "",
  voucher_required: false,
  bill_required: false,
  verification_required: false,
  transfer_required: false,
  vendor_required: false,
  noncash_only: false,
  branchWise: false,
  repeatable: false,
  // department: "",
  businessType: "",
  overHead: "",
  asset_wise: false,
  asset_type: false,
  addLogs: false
};

const initIncomeType: any = {
  name: "",
  fleet: false,
  branch: false,
  general: false,
  sub: "",
  is_route: false,
  duration: false,
  company: "",
  type: "add",
  monthly_duration: false,
  voucher_required: false,
  bill_required: false,
  verification_required: false,
  transfer_required: false,
  vendor_required: false,
  noncash_only: false,
  businessType: "",
  overHead: "",
};

export const initCompanyDoc = {
  isNew: true,
  active: false,
  twoFactorAuthentication: false,
  isDocVerified: false,
  individual: false,
  bodyType: "",
  fleetType: "",
  assetSubType: "",
  regNumber: "",
  _id: "",
  companyName: "",
  kind: "",
  displayName: "",
  url: "",
  email: "",
  contactPerson: {
    contact: "",
    name: {
      fName: "",
      lName: "",
    },
    password: "",
    email: "",
  },
  address: {
    l1: "",
    l2: "",
    city: "",
    pincode: "",
  },
  bankAccount: "",
  branchName: "",
  shortName: "",
  ifscCode: "",
  // licenseImage: "",
  // licenseExpiry: date,
  // panNumber: "",
  // panCardImage: "",
  // companyLogo: "",
  errors: {},
  modelNumber: "",
  capacity: "",
  volume: {
    l: "",
    b: "",
    h: "",
  },
  distanceCovered: "",
  fuelType: "",
  ownerShip: "",
  idealFuel: "",
  insuranceDate: new Date(),
  fitnessDate: new Date(),
  permitDate: new Date(),
  emiDate: new Date(),
  allowedOFDCities: [],
  overhead: "",
  businessType: "",
  fleetId: "",
  noOfSeats: "",
  //changes for attachements
  // bannerImage: "",
  userProfileImage: "",
  userProfile: {
    verified: false,
  },
  license: {},
  licenseImage: "",
  aadharCardImage: "",
  aadharCard: {},
  banner: {},
  bannerImage: "",
  panCard: {},
  panCardImage: "",
  companyLogo: {},
  companyLogoImage: "",
  contactVerified: false,
  agreement: {},
  agreementImage: "",
  userAttendance: false,
  branchTiming: false,
  cameraStatus: false,
  phoneStatus: false,
  customerVerification: false,
  allDocketVerification: false,
  withoutEWayBillDocketVerification: false,
  photoVerification: false,
  otpVerification: false,
  receiverAllDocketVerification: false,
  receiverPhotoVerification: false,
  receiverOtpVerification: false,
  pendingDelivery: false,
  verifiedPackages: false,
  myDuty: false,
  creditCustomer: false,
  pendingVoucher: false,
  userPendingMemo: false,
  userPendingReport: false,
  branchPendingMemo: false,
  branchPendingReport: false,
  mandatoryExpVerification: false,
  // interCompanyCashReceiver: false,
  // intraCompanyCashReceiver: false,
  // interCompanyCashUser: [],
  // intraCompanyCashUser: [],

  cancelBooking: false,
  loading: false,
  unloading: false,
  backToHub: false,
  userVerification: false,
  fleetDocVerification: false,
  qrPrinter: false,
  //changes for Dqr
  deliveryWithQr: false,
  rcBook: {},
  allIndiaPermit: {},
  insurance: {},
  rcBookImage: "",
  allIndiaPermitImage: "",
  insuranceImage: "",
  businessSubType: "",
  //changes
  settledBranch: "",
  companyType: "",
  settleCompany: "",
  primaryCompany: "",
  messages: {

    branchTimingAlert: false
  },
  themePreference: {
    clrPrimaryHue: 204,
    clrSecondaryHue: 172
  },
  autoEwayBillPartBVerification: false,
  eWaybillValidityCheck: false,
  cashDiscount: false,
  cashDemurrageDiscount: false
};

const initState = fromJS({
  expense_type: initExpenseType,
  assetTypes: [],
  expense_type_erorrs: { ...add_Expense_Boolean(true) },
  expense_type_touched: { ...add_Expense_Boolean(true) },
  fetched_fixed_expensesById: [],
  fixed_expenses: {
    assetType: "",
    fetched_expense_types: [],
    fetched_entity_types: [],
    post_fixed_expense: {
      type: "add",
      entity: "",
      assetType: "",
      entity_id: "",
      ref: "",
      duration: 1,
      amt: "",
      company: "",
      salary_days: null,
      extra_salary_days: null
    },
    fixed_expense_type_erorrs: { ...add_Fixed_Expense_Boolean(true) },
    fixed_expense_type_touched: { ...add_Fixed_Expense_Boolean(true) },
  },
  income_type: initIncomeType,
  income_type_errors: { ...add_Expense_Boolean(true) },
  income_type_touched: { ...add_Expense_Boolean(false) },
  fetched_sub_income_types: [],
  sub_income_type: {
    type: "add",
    name: "",
    ref: "",
  },
  sub_income_errors: { ...add_Sub_Income_Boolean(true) },
  sub_income_touched: { ...add_Sub_Income_Boolean(false) },
  companyList: [],
  company_doc: initCompanyDoc,
});

export default (state = initState, action: { type: any; payload: any }) => {
  switch (action.type) {
    case CHANGE_COMPANY_DOC: {
      return state
        .setIn(["company_doc", ...action.payload.what], action.payload.value)
        .setIn(
          ["company_doc", "errors", ...action.payload.what],
          validateCompanydoc(action.payload.what, action.payload.value)
        );
    }
    case "SET_COMPANY_ERROR": {
      return state.setIn(
        ["company_doc", "errors", ...action.payload.what],
        validateCompanydoc(action.payload.what, "")
      );
    }
    case SET_COMPANY_DOC: {
      return state.setIn(["company_doc"], fromJS(action.payload));
    }
    case CLEAR_COMPANY_DOC: {
      return state.setIn(
        ["company_doc"],
        fromJS({ ...initCompanyDoc, individual: action.payload.individual })
      );
    }
    case GET_COMPANY_REQ_SUCCESS: {
      showMessage("Fetched company successfully", success);
      return state.merge(action.payload);
      // ...action.payload, expense_type: initState.expense_type, fixed_expenses:initState.fixed_expenses }
    }
    case EDIT_COMPANY_REQ_SUCCESS: {
      showMessage("Company saved successfully!", success);

      return state.merge(action.payload);
      // return { ...action.payload, expense_type: initState.expense_type, fixed_expenses:initState.fixed_expenses }
    }
    case CHANGE_ADD_EXPENSE_TYPE: {
      let { what, val } = action.payload;

      return state
        .setIn(["expense_type", ...what], val)
        .setIn(
          ["expense_type_erorrs", ...what],
          validateExpense(add_Expense_Validation.getIn(what), val)
        )
        .setIn(["expense_type_touched", ...what], true);
    }
    case GET_VEHICLE_ID: {
      return state.setIn(["fixed_expenses", "assetType"], action.payload);
    }
    case SUBMIT_EXPENSE_SUCCESS: {
      showMessage("Expense type created successfully!", success);
      let data: any = {
        type: "add",
        name: "",
        fleet: false,
        branch: false,
        general: false,
        variance: "V",
        sub: "",
        is_route: "",
        duration: false,
        company: "",
        verification_required: false,
        bill_required: false,
        voucher_required: false,
        transfer_required: false,
        vendor_required: false,
        noncash_only: false,
        // department: "",
        businessType: "",
        overHead: "",
        asset_wise: false,
        asset_type: false,
        addLogs: false
      };
      return state.set("expense_type", data);
    }
    case EDIT_EXPENSE_SUCCESS: {
      showMessage("Expense Type Edited Successfully!", success);
      let data: any = {
        type: "add",
        name: "",
        fleet: false,
        branch: false,
        general: false,
        variance: "V",
        sub: "",
        is_route: "",
        duration: false,
        company: "",
        verification_required: false,
        bill_required: false,
        vendor_required: false,
        noncash_only: false,
        voucher_required: false,
        transfer_required: false,
        // department: "",
        businessType: "",
        overHead: "",
        asset_wise: false,
        asset_type: false,
        addLogs: false
      };
      return state.set("expense_type", data);
    }
    case SET_ASSET_TYPES: {
      let asset = action.payload;
      return state.set("assetTypes", asset);
    }

    case GET_EXPENSE_TYPES: {
      return state.setIn(
        ["fixed_expenses", "fetched_expense_types"],
        action.payload
      );
    }
    case SET_EXPENSE_TYPE: {
      let data = action.payload;
      data["type"] = "edit";
      return state.set("expense_type", data);
    }
    case RESET_EXPENSE_TYPE: {
      let data: any = { ...initExpenseType };

      let expense_type_erorrs: any = { ...add_Expense_Boolean(true) };

      return state
        .set("expense_type", data)
        .set("expense_type_erorrs", expense_type_erorrs);
    }
    case DELETE_EXPENSE_TYPE: {
      let data: any = {
        type: "add",
        name: "",
        fleet: false,
        branch: false,
        general: false,
        variance: "V",
        sub: "",
        is_route: "",
        duration: false,
        company: "",
        voucher_required: false,
        bill_required: false,
        verification_required: false,
        vendor_required: false,
        noncash_only: false,
        transfer_required: false,
        // department: "",
        businessType: "",
        overHead: "",
        asset_wise: false,
        asset_type: false,
        addLogs: false
      };
      return state.set("expense_type", data);
    }
    case CHANGE_FIXED_EXPENSE: {
      let { what, val } = action.payload;
      return state
        .setIn(["fixed_expenses", "post_fixed_expense", ...what], val)
        .setIn(
          ["fixed_expenses", "fixed_expense_type_erorrs", ...what],
          validateExpense(add_Fixed_Expense_Validation.getIn(what), val)
        )
        .setIn(["fixed_expenses", "fixed_expense_type_touched", ...what], true);
    }
    case DELETE_FIXED_EXPENSE_TYPE: {
      showMessage("Fixed Expense Type Deleted !", success);
      let payload = {
        type: "add",
        entity: "",
        entity_id: "",
        ref: "",
        duration: 1,
        amt: "",
        company: "",
        salary_days: null,
        extra_salary_days: null
      };
      return state.setIn(["fixed_expenses", "post_fixed_expense"], payload);
    }
    case GET_POSTED_SUB_TYPES: {
      return state.set("fetched_sub_income_types", action.payload);
    }
    case SET_INCOME_TYPE_FOR_EDIT: {
      let payload = action.payload;
      payload["type"] = "edit";
      return state.set("income_type", payload);
    }
    case EDIT_INCOME_TYPE_SUCCESS: {
      showMessage("Income Type Edited Successfully ", success);
      let payload: any = {
        name: "",
        fleet: false,
        branch: false,
        general: false,
        sub: "",
        is_route: false,
        duration: false,
        company: "",
        type: "add",
        voucher_required: false,
        bill_required: false,
        verification_required: false,
        transfer_required: false,
        vendor_required: false,
        noncash_only: false,
        businessType: "",
        overhead: "",
      };
      return state.set("income_type", payload);
    }
    case INCOME_TYPE_DELETED: {
      showMessage("Income Type Deleted ", success);
      let payload: any = {
        name: "",
        fleet: false,
        branch: false,
        general: false,
        sub: "",
        is_route: false,
        duration: false,
        company: "",
        type: "add",
        voucher_required: false,
        bill_required: false,
        verification_required: false,
        transfer_required: false,
        vendor_required: false,
        noncash_only: false,
        businessType: "",
        overhead: "",
      };
      return state.set("income_type", payload);
    }
    case RESET_INCOME_TYPE_FORM: {
      let income_type_errors = { ...add_Expense_Boolean(true) };
      return state
        .set("income_type", initIncomeType)
        .set("income_type_errors", income_type_errors);
    }
    case RESET_FIXED_EXPENSE: {
      let payload = {
        type: "add",
        entity: "",
        entity_id: "",
        ref: "",
        duration: 1,
        amt: "",
        company: "",
        salary_days: null,
        extra_salary_days: null
      };
      let fixed_expense_type_erorrs = { ...add_Fixed_Expense_Boolean(true) };

      return state
        .setIn(["fixed_expenses", "post_fixed_expense"], payload)
        .setIn(
          ["fixed_expenses", "fixed_expense_type_erorrs"],
          fixed_expense_type_erorrs
        );
    }
    case SET_FIXED_EXPENSE_TYPE: {
      let data = action.payload;
      // delete data.ref
      data["type"] = "edit";
      // delete data.name
      const resetPayload = {
        type: "add",
        entity: "",
        entity_id: "",
        ref: "",
        duration: 1,
        amt: "",
        company: "",
        salary_days: 0,
        extra_salary_days: 0
      };
      return state.setIn(["fixed_expenses", "post_fixed_expense"], {
        ...resetPayload,
        ...data
      });
    }
    case GET_FIXED_EXPENSE_BY_ID: {
      return state.set(
        "fetched_fixed_expensesById",
        action.payload.fixedExpenses
      );
    }
    case RESET_SUB_INCOME_TYPE: {
      let payload = {
        type: "add",
        name: "",
        ref: "",
      };
      let sub_income_errors = { ...add_Sub_Income_Boolean(true) };

      return state
        .set("sub_income_type", payload)
        .set("sub_income_errors", sub_income_errors);
    }
    case SET_SUBTYPES_FOR_EDIT: {
      let data = action.payload;
      data["type"] = "edit";
      return state.set("sub_income_type", action.payload);
    }
    case CHANGE_ENTITY_ID: {
      return state.setIn(
        ["fixed_expenses", "fetched_entity_types"],
        action.payload
      );
    }
    case SUBMIT_FIXED_EXPENSE_SUCCESS: {
      // showMessage('Fixed Expense type created successfully!', success)
      let payload = {
        type: "add",
        entity: "",
        entity_id: "",
        ref: "",
        duration: 1,
        amt: "",
        company: "",
        salary_days: null,
        extra_salary_days: null
      };
      return state.setIn(["fixed_expenses", "post_fixed_expense"], payload);
    }
    case EDIT_FIXED_EXPENSE_SUCCESS: {
      showMessage("Fixed Expense type Edited Successfully!", success);
      let payload = {
        type: "add",
        entity: "",
        entity_id: "",
        ref: "",
        duration: 1,
        amt: "",
        route: "",
        company: "",
        salary_days: null,
        extra_salary_days: null
      };
      return state.setIn(["fixed_expenses", "post_fixed_expense"], payload);
    }
    case CHANGE_ADD_INCOME_TYPE: {
      let { what, val } = action.payload;
      let result = state
        .setIn(["income_type", ...what], val)
        .setIn(
          ["income_type_errors", ...what],
          validateExpense(add_Expense_Validation.getIn(what), val)
        )
        .setIn(["income_type_touched", ...what], true);
      if (what[0] === "fleet" || what[0] === "branch") {
        return result.setIn(["income_type", "general"], false);
      }
      if (what[0] === "general") {
        return result
          .setIn(["income_type", "fleet"], false)
          .setIn(["income_type", "branch"], false);
      }
      return result;
    }
    case SUBMIT_INCOME_TYPE_SUCCESS: {
      showMessage("Income type created successfully!", success);
      let payload = {
        name: "",
        fleet: false,
        branch: false,
        general: false,
        sub: "",
        is_route: false,
        duration: false,
        company: "",
        type: "add",
        voucher_required: false,
        bill_required: false,
        verification_required: false,
        transfer_required: false,
        vendor_required: false,
        noncash_only: false,
        businessType: "",
        overhead: "",
      };
      return state.set("income_type", payload);
    }
    case SUBMIT_INCOME_TYPE_FAILED: {
      showMessage("Could Not Add Income Type !", failed);
      return state;
    }
    case CHANGE_SUB_INCOME_TYPE: {
      let { what, val } = action.payload;
      return state
        .setIn(["sub_income_type", ...what], val)
        .setIn(
          ["sub_income_errors", ...what],
          validateExpense(add_Sub_Income_Validation.getIn(what), val)
        )
        .setIn(["sub_income_touched", ...what], true);
    }
    case EDIT_SUB_INCOME_DATA: {
      showMessage("Sub Income type Edited Successfully ", success);
      let payload = {
        type: "add",
        name: "",
        ref: "",
      };
      return state.set("sub_income_type", payload);
    }
    case ADD_SUB_INCOME_TYPE: {
      showMessage("Sub Income type added Successfully", success);
      let payload = {
        type: "add",
        name: "",
        ref: "",
      };
      return state.set("sub_income_type", payload);
      return state;
    }
    case ADD_SUB_INCOME_TYPE_FAILED: {
      showMessage("Something Went Wrong !", success);

      return state;
    }
    case GET_COMPANY_LIST: {
      return state.set("companyList", action.payload);
    }
    case "CHANGE_OFD_CITIES_INDIVIDUAL": {
      console.log(action.payload);
      return state.setIn(["company_doc", "allowedOFDCities"], action.payload);
    }
    default: {
      return state;
    }
  }
};