import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useNavigate, } from 'react-router-dom';
import CustomerTrackMapView from '../../CustomerSection/CustomerBookingTracking/CustomerTrackMapView';
import formatDate from "../../../services/FormatDate";
import { setTimeFormat } from '../../../services/FormatTime';
import ImageInput from "../../../Components/ImageInput";
import { getCustomerdocketDataTrack, getOtpForBranchOrderTracking } from '../../../services/customersection';
import { showMessage, success, failed } from "../../../utils/message";
import { addQrReprintPackageLog, fetchDocketDetails } from '../../../services/operations';
import DoorDeliveryDialog from "../../operations/Booking/DoorDeliveryDialog";
import { showHttpError } from "../../../utils/message";
import ShareToWhatsapp from "../../sharing/WhatsAppSharing";
import { illegalGoodsFoundAndEwayBillMissing , sendBackToNewDestination, sendBackToOrigin, setReceivedPackages } from '../../../services/bookTrack';
import { addCustomerComplaintForBranchOrder, createComplaintGuest, createComplaintUser, customerReviewRating, getGuestComplaint, sendGuestMessage, sendUserMessage } from '../../../services/complaint';
import Complaint from '../../Complaint/complaint';
import StatusHeader from '../../operations/StatusHeader';
import MyImage from '../../Image/MyImage';
import classNames from "classnames";
import Dot from '../../generics/Dot';
import { setMissingPackages } from '../../../services/bookTrack';
import { printQRDocket } from '../../operations/bookutils/steps/builty';
import { listUsersService } from '../../../services/paymentType';
import { listAllUsersForCustomers } from '../../../services/user';
import { sendCustomerOTP } from '../../../services/auth';
import { setCustomerPassword } from '../../../services/auth';
import OnlyImage from '../../Image/OnlyImage';
import ChatContainer from '../../../Components/ChatModule/ChatContainer';
import jsonToFormdata from '../../../utils/jsonToFormdata';
import DoorDeliveryVoucher from "../../expense/DoorDeliveryVoucher";
import Printd from "printd";
import formatTime from "../../../Components/FormatTime";
import { createCustomerCCAvenueTxn } from "../../../services/paymentGateWay";
import ReactGA4 from 'react-ga4';
import { CalendarIcon, CallIcon, CashIcon, DocketNumberIcon,  HoursIcon, RupeeIcon, TimelineIcon, UserIcon, BackIcon, DownloadIcon, CancelIcon, MessageAltIcon, SendIcon, MovingTruckIcon, LocationIcon, ChatIcon, PackageIcon,  ImagesIcon, ThreeDotsIcon, InfoIcon, DebugDisconnectIcon, TagsIcon, OrderPlayLineIcon, UngroupByRefTypeIcon, QRCodeIcon, BranchIcon, PincodeIcon, BranchCallIcon, CityIcon, UserInfoIcon, CalendarCheckIcon, SharpLocationIcon, PrintIcon, DropboxIcon } from "../../../newSrc/constants/icons";
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, Dialog, InfoPill,  PaperCard, Rating, Select, Switch, TextArea, TextField, Tooltip } from "../../../newSrc/components/UI";
import PageTitle from "../../../Components/Title/PageTitle";
import ComplaintButtons from "./ComplaintButtons";
import { socket } from "../../../store";
import { getCustomerDocketLocation } from "../../../actions/liveLocation";
import { customerLogin } from "../../../actions/customerLogin";
import { setCustomerBookingTracking } from "../../../actions/customerBooking";
import { ResetCustomerTracking } from "../../../actions/trackingModule";
import { hideLoader, resetDoorDelivery, setDoorDelivery, showLoader } from "../../../actions/UserActions";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { MdVerified } from "react-icons/md";
import { CancelButton, CloseButton, SubmitButton } from "../../../Components/Button/AllButton";
import ImageInputPackgeWise from "../../../Components/ImageInputPackgeWise";
// import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import SingleStatus from "./SingleStatus";
// import { AccordionGroup } from "../../../newSrc/components/UI/accordian/accordian";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { AccordionGroup } from "../../../newSrc/components/UI/accordian/accordian";
import { useWidth } from "../../../newSrc/hooks/useMobile";
// import Stepper from "../../../newSrc/components/UI/stepper/stepper";


export enum DocketIssueType {
    IllegalGoodsFound = "IllegalGoodsFound",
    EwaybillDetailsMissing = "EwaybillDetailsMissing",
    PartBUpdateFailure = "PartBUpdateFailure",
    PackageSizeUpdate = "PackageSizeUpdate",
    FakeMissing = "FakeMissing",
    EwayBillExpired = "EwayBillExpired",
  }
  
  export enum DocketIssueCreatedOnType {
    U = "U",
    C = "C"
  }

const meterTOkmChange = (meter: any) => {
    return (meter / 1000).toFixed(2)
}

const secondTominConvert = (time: any, index: any) => {
    let t = new Date();
    t.setSeconds(t.getSeconds() + time + index * 30 * 60);
    return `${formatDate(t)}- ${setTimeFormat(t)}`
}

const timeConvert = (time: any) => {
    const timeString12hr = new Date(time).toLocaleTimeString("en-US", {
        hour12: true,
        hour: "numeric",
        minute: "numeric",
    });
    return timeString12hr;
}

var iconsClassName = `text-primary-400 fs-link flex-shrink-0`;

export const BranchTrackingView = (newProps: any) => {
    const BranchData: any = newProps?.data?.customerTrackingData?.orderData;
    const SourceName: any = newProps?.data?.customerTrackingData?.source;
    const [_activeDest, setActiveDest] = useState<any>(null);
    const [uniqueLogs, setUniqueLogs] = useState<any>("");
    const [packageMap, setPackageMap] = useState<any>({});
    const [illegalIssue,setIllegalIssue] = useState<any>({})
    const [ewayBillIssue,setEwayBillIssue] = useState<any>({})
    const [doorDeliveryDocketData, setDoorDeliveryDocketData] = useState<any>({});
    const [orderDetails, setOrderDetails] = useState<any>({})
    const [latComplaint, setLastComplaint] = useState<any>();
    const [isDialogOpen, setIsDialogOpen] = useState(0);
    const [ratingFromUser, setRatingFromUser] = useState(0);
    const [reviewFromUser, setReviewFromUser] = useState("");
    const [openDoorDelDialog, setOpenDoorDelDialg] = useState(false);
    const [DoorDelBookingCheckBox, setDoorDelBookingCheckBox] = useState(false);
    const [mainBookingCheckBoxPart, setMainBookingCheckBoxPart] = useState(true);
    const [imagePackages, setImagePackages] = useState<any>([]);
    const [newDestOpen, setNewDestOpen] = useState(false);
    const [liggleGoodsDialog, setLiggleGoodsDialog] = useState(false);
    const [ewayBillMissiingDialog, setEwayBillMissiingDialog] = useState(false);
    const [imagedPackageData, setImagedPackageData] = useState<any>([]);
    const [arrayOfImage, setArrayOfImage] = useState<any>([]);
    const [NDAmount, setNDAmount] = useState<any>("");
    const [selectedND, setSelectedND] = useState<any>("");
    const [newDestOptions, setNewDestOptions] = useState<any>([]);
    const [backToOriginOpen, setBackToOriginOpen] = useState(false);
    const [showPackages, setShowPackages] = useState(false);
    const [ratingDone, setRatingDone] = useState(false);
    const [moreBtns, showMoreBtns] = useState(false);
    const [packageImageDialog, setPackageImageDialog] = useState(false);
    const [selectedImage, setSelectedImage] = useState({ label: 'Select Any One Image', value: "Select Any One Image" });
    const [btoAmount, setbtoAmount] = useState<any>("");
    const [orderArrayOption, setorderArrayOption] = useState<any>("");
    const [branchOptions, setBranchOptions] = useState<any>("");
    const [userOptions, setUserOptions] = useState<any>("");
    const [fleetOrderNumbers, setFleetOrderNumbers] = useState<any>([]);
    const [openAction, setOpenAction] = useState<any>(null);
    const [openActionForRecieved, setOpenActionForRecieved] = useState<any>(false);
    const [reason, setReason] = useState("");
    const [Image, setImage] = useState<any>("");
    const [feedbackResponse, setFeedbackResponse] = useState<any>("");
    // const [originalDocket, setOriginalDocket] = useState<any>()
    const [allPackages, setAllPackages] = useState<any>([]);
    const [packageOrderForRecieved, setPackageOrderForRecieved] = useState<any>([]);
    const [openFleetOrder, setOpenFleetOrder] = useState(false)
    const [outsideDialogOpen, setOutsideDialogOpen] = useState(false)
    const [outsidePaymentDialogOpen, setOutsidePaymentDialogOpen] = useState(false)
    const [outsideRating, setOutsideRating] = useState(false)
    const [firstPopup, setFirstPopup] = useState(false)
    const [chatPopup, setChatPopup] = useState(false)
    const [firstPopupTabs, setFirstPopupTabs] = useState(0)
    const [firstPopupPassword, setFirstPopupPassword] = useState("")
    const [firstPopupNewPassword, setFirstPopupNewPassword] = useState("")
    const [firstPopupConfirmPassword, setFirstPopupConfirmPassword] = useState("")
    const [branchAdd, setBranchAdd] = useState(false)
    const [paymentInfo, setPaymentInfo] = useState(false)
    const [customerType, setCustomerType] = useState<any>("");
    const [customerTypeForRating, setCustomerTypeForRating] = useState<any>("");
    const [name, setName] = useState("");
    const [contact, setContact] = useState("");
    const [misBehaveUser, setMissBehaveUSer] = useState<any>("");
    const [missBehaveBranch, setMissBehaveBranch] = useState<any>("");
    const [details, setDetails] = useState("");
    const [ImageTwo, setImageTwo] = useState<any>("");
    const [feedBack, setFeedBack] = useState("");
    const [missingParcel, setMissingParcel] = useState(false);
    const [notReceivedDisabled, setNotReceivedDisabled] = useState(false);
    const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    const [open, setOpen] = useState(false);
    const [firstPopupOtp, setFirstPopupOtp] = useState("");
    const [senderOptIn, setSenderOptIn] = useState("");
    const [senderOptInForPayment, setSenderOptInForPayment] = useState("");
    const [selectedNumber, setSelectedNumber] = useState<any>()
    const [selectedNumberForPayment, setSelectedNumberForPayment] = useState<any>()
    const [userHasPass, setUserHasPass] = useState(false);
    const [otpForDelivery, setOtpForDelivery] = useState<any>()
    const [replyImage, setReplyImage] = useState("");
    const [guestMessage, setGuestMessage] = useState("");
    const [printDoorDelVoucher, setPrintDoorDelVoucher] = useState(false)
    const [selectPaymentOptionBtn2, setSelectPaymentOptionBtn2] = useState(BranchData?.docket.paymentMode);
    const {mobileView} = useWidth();
    let isDelivered = false;
    const navigate = useNavigate()
    let recordsMap = new Map();

    BranchData?.packages.map((d: any) => {
        if (recordsMap.has(`${d?.materialType?.name}${d?.packingType?.name}`)) {
            recordsMap.get(`${d?.materialType?.name}${d?.packingType?.name}`).push(d);
        } else {
            recordsMap.set(`${d?.materialType?.name}${d?.packingType?.name}`, [d]);
        }
    });

    let imagesOptions = [...arrayOfImage?.map((x: any) => { return { label: x?.name, value: x?.url } })];

    const { branchListData } = useAppSelector((state: any) => ({ branchListData: state.branch.filteredBranches.data.Branches }));
    useEffect(()=>{

        if(BranchData){
            if(BranchData?.docketIssues?.length>0){
                BranchData?.docketIssues?.map((ele:any)=>{
                    if(ele?.issueType === 'IllegalGoodsFound'){
                        setIllegalIssue(ele);
                    }else if(ele?.issueType === 'EwaybillDetailsMissing'){
                        setEwayBillIssue(ele);
                    }
                    
                })
            }   
        }
    },[])
        
    const giveUser = function (user: any) {
        if (
            user &&
            typeof user === "object" &&
            user.hasOwnProperty("name") &&
            user.name.fName &&
            user.name.lName
        ) {
            return [user.name.fName, user.name.lName].join(" ");
        }
        return "Anonymous";
    };

    const setIsDialogOpenFunc = (num: number) => {
        // if (allPackages.length < 1) {
        //     return showHttpError('Turn on Show Packages, ThenKindly Select Any One or More Package')
        // }
        if (num === 1) {

            if (SourceName === "Customer-NotAuthenticated-BranchOrder") {
                // setIsDialogOpen(1)
                setOutsideDialogOpen(true);
            }
            else {
                setIsDialogOpen(1), setOpenAction("M")
            }
        }
        if (num === 2) {
            if (SourceName === "Customer-NotAuthenticated-BranchOrder") {
                // setIsDialogOpen(2) 
                setOutsideDialogOpen(true);
            }
            else {
                setIsDialogOpen(2), setOpenAction("PF")
            }
        }
        if (num === 3) {
            if (SourceName === "Customer-NotAuthenticated-BranchOrder") {
                // setIsDialogOpen(3), 
                setOutsideDialogOpen(true);
            }
            else {
                setIsDialogOpen(3), setOpenAction("DM")
            }
        }
        if (num === 4) {
            if (SourceName === "Customer-NotAuthenticated-BranchOrder") {
                // setIsDialogOpen(4), 
                setOutsideDialogOpen(true);
            }
            else {
                setIsDialogOpen(4), setOpenAction("MB")
            }
        }
        if (num === 5) {
            if (SourceName === "Customer-NotAuthenticated-BranchOrder") {
                // setIsDialogOpen(5), 
                setOutsideDialogOpen(true);
            }
            else {
                setIsDialogOpen(5), setOpenAction("LD")
            }
        }
    }

    const processLogs = (packages: any) => {
        let uniqueLogs: any = {},
            packageMap: any = {},
            global: any = {};
        packages.forEach((item: any) => {
            let { logs: tLogs, pod, idProof, destBranch, originBranch } = item;

            let current = "";
            if (tLogs) {
                tLogs.forEach((item: any) => {
                    let {
                        regNumber,
                        branch,
                        // trackingTime,
                        user,
                        action,
                        remarkImage,
                        remarks,
                        secondVerified,
                        secondActionBy,
                        secondRemark,
                        secondaryActionAt,
                        isQrScan,
                        branch: fromBranch
                    } = item;

                    let entity = regNumber || (branch ? branch.branchName : "ENTITY");
                    let presentBranch = branch ? branch._id : "";

                    current +=
                        [
                            action == "unloading"
                                ? originBranch == presentBranch
                                    ? "booking"
                                    : destBranch !== presentBranch
                                        ? "loading"
                                        : action
                                : action,
                            entity,
                            giveUser(user),
                            // trackingTime,
                            pod || "NOPOD",
                            idProof || "NOID",
                            remarks || "RTEXT",
                            remarkImage || "RIMAGE",
                            action,
                            secondVerified,
                            giveUser(secondActionBy),
                            secondRemark,
                            secondaryActionAt,
                            isQrScan === true ? "YES" : "NO",
                            fromBranch ? fromBranch.branchName : "ENTITY",
                        ].join("@") + ";";
                    if (action === "inGstCustody" && entity === 'GJ38T5676') {
                    }
                });
                if (current in global) {
                    packageMap[current].push(item.order);
                } else {
                    global[current] = 1;
                    uniqueLogs[current] = current;
                    packageMap[current] = [item.order];
                }
            }
        });
        return {
            uniqueLogs,
            packageMap,
            global,
        };
    };
    const getBranchOrderData = () => {

        let resPackages = BranchData?.packages.map((p: any, i: any) => {
            isDelivered = isDelivered || p.status === "D";
            return {
                ...p,
                logs: p.logs.map((l: any, li: any) => ({
                    ...l,
                    remarkImage: BranchData?.logImages[i][li],
                })),
            };
        });

        const { uniqueLogs, packageMap } = processLogs(resPackages);

        setPackageMap(packageMap);
        setUniqueLogs(uniqueLogs);

        let isOrderStarted = BranchData?.doorDeliveryDocket?.Route?.find((x: any) => {
            return x.checkIn
        }) ? true : false

        setDoorDeliveryDocketData({
            ...BranchData?.doorDeliveryDocket.doorDelAddress,
            docket: BranchData?.docket?.docketNumber,
            doorDelDocketNumber: BranchData?.docket?.doorDeliveryDocketNumber,
            serviceType: BranchData?.doorDeliveryDocket.serviceType,
            assetType: BranchData?.doorDeliveryDocket.assetType,
            fromPlaceId: BranchData?.docket?.destBranch?.address?.placeId,
            toPlaceId: BranchData?.doorDeliveryDocket.toPlace,
            packages: BranchData?.docket?.packages,
            delCity: BranchData?.docket?.destBranch?.address?.city?._id,
            city: { value: BranchData?.doorDeliveryDocket?.doorDelAddress?.city?._id, label: BranchData?.doorDeliveryDocket?.doorDelAddress?.city?.name },
            state: { value: BranchData?.doorDeliveryDocket?.doorDelAddress?.city?.state?._id, label: BranchData?.doorDeliveryDocket?.doorDelAddress?.city?.state?.name },
            doorDeliveryPaymentMode: BranchData?.doorDeliveryDocket.paymentMode,
            deliveryCharge: BranchData?.doorDeliveryDocket.doorDelCharges,

            placeValue: BranchData?.doorDeliveryDocket?.doorDelAddress?.place?.placeValue,
            placeId: BranchData?.doorDeliveryDocket?.doorDelAddress?.place?.placeId,
            coordinate: BranchData?.doorDeliveryDocket?.doorDelAddress?.place?.coordinate,
            formatted_address: BranchData?.doorDeliveryDocket?.doorDelAddress?.place?.formatted_address,
            name: BranchData?.doorDeliveryDocket?.doorDelAddress?.place?.name,
            area: BranchData?.doorDeliveryDocket?.doorDelAddress?.place?.area,
            isOrderStarted
        })

        setInState(BranchData);

        setOrderDetails({
            packages: BranchData?.doorDeliveryDocket.packages,
            totalDuration: BranchData?.doorDeliveryDocket.totalDuration,
            totalDistance: BranchData?.doorDeliveryDocket.totalDistance,
        })
        if (BranchData?.doorDeliveryDocket.Route && BranchData?.doorDeliveryDocket.Route.length > 0) {
            let estimation = BranchData?.doorDeliveryDocket?.Route.sort((a: any, b: any) => {
                return a?.placeIndex - b?.placeIndex
            }).map((x: any) => {
                return { ...x, placeId: [x.place] }
            }) || []
            if (isOrderStarted) {
                setActiveDest(estimation.find((x: any) => !x.checkIn && !x.checkOut)?.placeIndex)
            }

            let origin = {
                distance: 0,
                duration: 0,
                doorDelDocketId: estimation[0].doorDelDocketId,
                placeId: [BranchData?.docket?.actDestBranch?.place],
                placeIndex: 0,
                place: BranchData?.docket?.actDestBranch?.place
            }
            newProps?.data?.setCustomerBookingTracking({
                route: [origin, ...estimation]
            })
        }
    }

    const checkSelected = (pId: any) => {
        let x = [...allPackages];
        if (x.includes(pId)) {
            let id = x.indexOf(pId);
            x.splice(id, 1);
        } else {
            x.push(pId);
        }
        setAllPackages(x);
    };

    // const handleDetailedLogsForBranch = (uniquelogsArr: any, allProps: any, orderNumber?: number) => {
    //     if (SourceName === "Customer-Authenticated-BranchOrder") {
    //         // historyData.push({
    //         //   pathname: "/CustomerDashBoard/detailedLogs",
    //         //   state: {
    //         //     from: "Customer-Order-Track",
    //         //     logsInfo: uniquelogsArr,
    //         //     AllProps: allProps,
    //         //     BranchDetails: BranchData,
    //         //     view: "Branch-Order"
    //         //   }
    //         // })
    //         navigate("/CustomerDashBoard/detailedLogs", {
    //             state: {
    //                 from: "Customer-Order-Track",
    //                 logsInfo: uniquelogsArr,
    //                 AllProps: allProps,
    //                 BranchDetails: BranchData,
    //                 view: "Branch-Order",
    //                 orderNum: orderNumber,
    //             }
    //         })
    //     }
    //     else if (SourceName === "Customer-Authenticated-CustomerOrder") {
    //         // historyData.push({
    //         //   pathname: "/CustomerDashBoard/detailedLogs",
    //         //   state: {
    //         //     from: "Customer-Order-Track",
    //         //     logsInfo: uniquelogsArr,
    //         //     view: "Customer-Order"
    //         //   }
    //         // })
    //         navigate("/CustomerDashBoard/detailedLogs", {
    //             state: {
    //                 from: "Customer-Order-Track",
    //                 logsInfo: uniquelogsArr,
    //                 view: "Customer-Order"
    //             }
    //         })
    //     }
    //     else if (SourceName === "Branch-Authenticated-BranchOrder") {
    //         // historyData.push({
    //         //   pathname: "/dashboard/detailedLogs",
    //         //   state: {
    //         //     from: "Branch-Order-Track",
    //         //     logsInfo: uniquelogsArr,
    //         //     AllProps: allProps,
    //         //     BranchDetails: BranchData,
    //         //     view: "Branch-Order"
    //         //   }
    //         // })
    //         navigate("/dashboard/detailedLogs", {
    //             state: {
    //                 from: "Branch-Order-Track",
    //                 logsInfo: uniquelogsArr,
    //                 AllProps: allProps,
    //                 BranchDetails: BranchData,
    //                 view: "Branch-Order",
    //                 orderNum: orderNumber,
    //             }
    //         })
    //     }
    //     else if (SourceName === "Branch-Authenticated-CustomerOrder") {
    //         // historyData.push({
    //         //   pathname: "/dashboard/detailedLogs",
    //         //   state: {
    //         //     from: "Branch-Order-Track",
    //         //     logsInfo: uniquelogsArr,
    //         //     view: "Customer-Order"
    //         //   }
    //         // })
    //         navigate("/dashboard/detailedLogs", {
    //             state: {
    //                 from: "Branch-Order-Track",
    //                 logsInfo: uniquelogsArr,
    //                 view: "Customer-Order"
    //             }
    //         })
    //     }
    //     else if (SourceName === "Customer-NotAuthenticated-CustomerOrder") {
    //         // historyData.push({
    //         //   pathname: "/detailedLogs",
    //         //   state: {
    //         //     from: "Customer-Order-Track",
    //         //     logsInfo: uniquelogsArr,
    //         //     view: "Customer-Order"
    //         //   }
    //         // })
    //         navigate("/detailedLogs", {
    //             state: {
    //                 from: "Customer-Order-Track",
    //                 logsInfo: uniquelogsArr,
    //                 view: "Customer-Order"
    //             }
    //         })
    //     }
    //     else if (SourceName === "Customer-NotAuthenticated-BranchOrder") {
    //         // historyData.push({
    //         //   pathname: "/detailedLogs",
    //         //   state: {
    //         //     from: "Branch-Order-Track",
    //         //     logsInfo: uniquelogsArr,
    //         //     AllProps: allProps,
    //         //     BranchDetails: BranchData,
    //         //     view: "Branch-Order"
    //         //   }
    //         // })
    //         navigate("/detailedLogs", {
    //             state: {
    //                 from: "Branch-Order-Track",
    //                 logsInfo: uniquelogsArr,
    //                 AllProps: allProps,
    //                 BranchDetails: BranchData,
    //                 view: "Branch-Order",
    //                 orderNum: orderNumber,
    //             }
    //         })
    //     }
    // }   


    // Old Design

    // const SingleStatus = (props: any) => {
    //     const [isDialogOpen, setIsDialogOpen] = useState(false);

    //     let lastStatus = props.statusArr[props.statusArr.length - 1];
    //     const statusSplit = lastStatus.split("@");
    //     let ParcelReachedLastBranch: any = statusSplit[1];
    //     // // Steps mapping
    //     // const steps = getSteps();
    //     // function getSteps() {
    //     //     return [1, 2, 3, 4, 5]; // Replace with dynamic values if needed
    //     // }

    //     // Map status to procStatus and percentage progress
    //     let result = "";
    //     // let procStatus = 0;
    //     let progressValue = 0; // Percentage for progress bar

    //     switch (statusSplit[0]) {
    //         case "loading": {
    //             result = "In transit";
    //             // procStatus = 1;
    //             progressValue = 40
    //             break;
    //         }
    //         case "payment": {
    //             result = "Waiting for Delivery";
    //             progressValue = 60
    //             break;
    //         }
    //         case "unloading": {
    //             result = "Waiting for Delivery";
    //             progressValue = 60
    //             break;
    //         }
    //         case "booking": {
    //             result = "Booked";
    //             progressValue = 20
    //             break;
    //         }
    //         case "delivery": {
    //             result = "Delivered";
    //             progressValue = 100
    //             break;
    //         }
    //         case "cancel": {
    //             result = "Canceled";
    //             progressValue = 0
    //             break;
    //         }
    //         case "cancelPending": {
    //             result = "Waiting for cancellation";
    //             progressValue = 0
    //             break;
    //         }
    //         case "review": {
    //             result = "Review";
    //             progressValue = 100
    //             break;
    //         }
    //         case "missing": {
    //             result = "Missing";
    //             progressValue = 0
    //             break;
    //         }
    //         case "missingRequest": {
    //             result = "Missing Request";
    //             progressValue = 0
    //             break;
    //         }
    //         default: {
    //             result = "Booked";
    //             progressValue = 20
    //         }
    //     }

    //     return (
    //         <PaperCard className="overflow-auto">
    //             <>

    //                 <div className="flex ai-center jc-between | m-flex-col m-ai-start gap-1">
    //                     <p className="fs-link flex ai-center gap-4">
    //                         <div><PackageIcon className={`${iconsClassName}`}/> </div>
    //                         <div> {props.totalPkg} </div>
    //                     </p>
    //                     <p className="fs-link flex ai-center gap-4">
    //                         <span><DropboxIcon className={`${iconsClassName}`} /> </span>
    //                         {props?.materialType && props?.materialType.name}
    //                     </p>
    //                     <p className="fs-link flex ai-center gap-4">
    //                         <span><PackageIcon className={`${iconsClassName}`}/> </span>
    //                         {props?.packingType && props?.packingType.name}
    //                     </p>

    //                     <p className="fs-link flex ai-center gap-4">
    //                         <span><TimelineIcon className={`${iconsClassName}`} />{" "}</span>
    //                         <div>
    //                             {ParcelReachedLastBranch}
    //                         </div>
    //                     </p>
    //                     <p className="fs-link flex ai-center gap-8">
    //                         <div style={{ width: 50, height: 50}}>
    //                             <CircularProgressbar
    //                                 className="flex-center"
    //                                 value={progressValue}
    //                                 text={`${progressValue}%`}
    //                                 styles={buildStyles({
    //                                     textSize: "28px",
    //                                     pathColor: progressValue === 100 ? "#4caf50" : "#2196f3",
    //                                     textColor: "#000",
    //                                     trailColor: "#dcdcdc",
    //                                 })}
    //                             />
    //                         </div>
    //                         <p style={{color:progressValue === 100 ? "#4caf50" : "#2196f3",fontWeight:progressValue === 100 ? "600" : " ",}}>
    //                             {result}
    //                         </p>
    //                     </p>
    //                     {/* <p className='fs-link flex ai-center gap-4'>
    //                         <HistoryIcon className="text-primary-400 pointer" onClick={() => { setIsDialogOpen(true) }} />
    //                     </p> */}
    //                     <Dialog state={isDialogOpen} header='List of Packge Numbers' onClose={() => { setIsDialogOpen(false) }}
    //                         body={
    //                             <div className="pkgDialogueContent">
    //                                 <div className="listOfPackages flex gap-1 flex-wrap">
    //                                     {props.PkgList.map((c: any) => {
    //                                         return (<Button onClick={() => { handleDetailedLogsForBranch(c.logs, props, c?.order) }} variant="primary" action="secondary">
    //                                             {c?.order}
    //                                         </Button>
    //                                         )
    //                                     })}
    //                                 </div>
    //                             </div>
    //                         }
    //                         footer={
    //                             <CancelButton onClick={() => setIsDialogOpen(false)} />
    //                         }
    //                     />
    //                 </div>
    //                 {/* <div className="progressBarDiv my-1 p-1 border-white shadow-small br-4">
    //                     <Stepper activeStep={procStatus} className={"singleProgressBar"}>
    //                         {steps.map((label) => (
    //                             <Step key={label} className={"singleStepz"} >
    //                                 <StepLabel></StepLabel>
    //                             </Step>
    //                         ))}
    //                         <p className="lastResultName">{result}</p>
    //                     </Stepper>
    //                 </div> */}
    //             </>
    //             {/* } */}

    //             {showPackages &&
    //                 <div className="packageBtnsForComplaints">
    //                     {props?.PkgList && props?.PkgList?.map((i: any, index: any) => {
    //                         return (<div
    //                             key={index}
    //                             className={`${classNames(
    //                                 {
    //                                     inner_selected: allPackages.includes(i._id),
    //                                 },
    //                                 {
    //                                     inner_package: !allPackages.includes(i._id),
    //                                 }
    //                             )}`}
    //                             style={{
    //                                 margin: "0",
    //                                 marginRight: "1rem"
    //                             }}
    //                             onClick={() => {
    //                                 checkSelected(i._id);
    //                                 // checkboxSelected(props.data.packages, props.index);
    //                             }}
    //                         >
    //                             <div style={{ display: "inline" }}>
    //                                 <Dot
    //                                     root={
    //                                         allPackages.includes(i._id)
    //                                             ? "verified_dot"
    //                                             : "unverified_dot"
    //                                     }
    //                                 />
    //                             </div>

    //                             <div style={{ display: "inline" }}>
    //                                 {i.order}
    //                             </div>
    //                         </div>)
    //                     })}
    //                 </div>
    //             }
    //         </PaperCard>
    //     )
    // }


    //New Design

    


    

    const checkingPastComplaints = async () => {
        if (SourceName === "Branch-Authenticated-BranchOrder") {
            setLastComplaint(BranchData?.docket?.feedBacks?.complaints);
        }
        if (SourceName === "Customer-Authenticated-BranchOrder") {
            const response: any = await getCustomerdocketDataTrack({
                docket: BranchData?.docket?.docketNumber,
                customer: newProps?.data?.user?._id,
                isFromMainPage: false
            });
            setLastComplaint(response?.docket?.feedBacks?.complaints);
        }
    }

    const trackFromTrackingID = async (docketNo: any) => {
        const payload = {
            docket: docketNo,
            sub: "B",
            entity: "5e8ec1a63657974a54b5d56a",
            queryType: "tracking",
            old: false,
        };
        const res = await fetchDocketDetails(payload);
        let {
            // docket,
            packages,
            // logImages,
            // consigneeIdImage,
            // podImage: delPOD,
            // reSolveIssues,
            // doorDeliveryDocket,
            // receiverDoorDeliveryAddr,
        } = res;
        // setOriginalDocket(docket)

        setorderArrayOption([
            ...packages.map((i: any) => ({
                label: i.order,
                value: i._id,
            })),
        ]);

        let uniqueFleetOrders = [
            ...new Map(
                res?.packages.map((item: any) => [
                    item["fleetOrderNumber"],
                    item.fleetOrderNumber,
                ])
            ).values(),
        ];
        setFleetOrderNumbers(
            uniqueFleetOrders && uniqueFleetOrders.length > 0
                ? uniqueFleetOrders
                : []
        );
    }

    // const printBuilty = async () => {
    //   try {
    //     const elem: any = document.getElementById("track_builty_download");

    //     if (typeof elem === null || elem === "undefined") {
    //     } else {
    //       elem.style.opacity = 1;
    //       let style = `
    //       @page { size: auto;  margin: 0mm; }
    //                   table, th, td {
    //                       border: 1px solid black;
    //                       border-collapse: collapse;
    //                   }
    //                   th, td {
    //                       text-align: justify;
    //                       padding: 5px;
    //                   }`;
    //       let divContents = elem.innerHTML;
    //       var a: any = window.open("", "", "");
    //       a.document.write("<head><style>" + style + "</style>");
    //       a.document.write('<body onload="window.print()">');
    //       a.document.write(divContents);
    //       a.document.write("</body>");
    //       a.document.write("</head>");
    //       a.document.close();
    //       elem.style.opacity = 0;
    //     }
    //   } catch (err) {
    //     const elem: any = document.getElementById("track_builty_download");
    //     if (typeof elem === null || elem === "undefined") {
    //     } else {
    //       elem.style.opacity = 0;
    //     }
    //   }
    // };

    const downloadBuilty = async () => {
        try {
            const elem: any = document.getElementById("track_builty_download");

            if (typeof elem === null || elem === "undefined") {
            } else {
                elem.style.opacity = 1;
                let style = `
              @page { size: auto;  margin: 0mm; }
                          table, th, td {
                              border: 1px solid black;
                              border-collapse: collapse;
                          }
                          th, td {
                              text-align: justify;
                              padding: 5px;
                          }`;
                let divContents = elem.innerHTML;
                var a: any = window.open("", "", "");
                a.document.write("<head><style>" + style + "</style>");
                a.document.write('<body onload="window.print()">');
                a.document.write(divContents);
                a.document.write("</body>");
                a.document.write("</head>");
                a.document.close();
                elem.style.opacity = 0;
            }
        } catch (err) {
            const elem: any = document.getElementById("track_builty_download");
            if (typeof elem === null || elem === "undefined") {
            } else {
                elem.style.opacity = 0;
            }
        }
    };

    const handleViewDocketBtn = () => {
        navigate("/dashboard/operations/fetchDocket", {
            state: {
                from: "tracking",
                data: BranchData?.docket.docketNumber,
            }
        })
    }

    const handleImagePackages = () => {
        setImagePackages(
            BranchData?.packages.map((r: any) => ({
                order: r.order,
                _id: r._id,
            }))
        );
    };

    const handleSubmitND = async () => {
        if (imagedPackageData?.length <= 0) {
            return showHttpError(`All package Images are required !`)
        }
        newProps.data.showLoader();
        try {
            const payload = {
                docket: BranchData?.docket._id,
                branch: newProps?.entity,
                NDCharge: NDAmount,
                newDestination: selectedND && selectedND.value,
                packages: imagedPackageData,
            };
            if (payload.NDCharge % 10 !== 0) {
                showMessage(
                    "New Destination charge should be a multiple of 10",
                    failed
                );
                newProps.data.hideLoader();
                return null;
            }
            await sendBackToNewDestination(payload);
            newProps.data.hideLoader();
            showMessage("Successfull !!", success);
            handleNDClose();
        } catch (err) {
            newProps.data.hideLoader();
            showHttpError(err);
        }
    };
    interface Payload {
        entity: any;
        createdOnType: DocketIssueCreatedOnType;
        issueType?: DocketIssueType; // Optional because not every payload might have issueType
        docketId?: string; // Optional because not every payload might have docket
        userId?: string; // Optional because not every payload might have docket
    }
    const handleSubmitIllegalGood = async (type: any) => {
        newProps.data.showLoader();
        try {
            const payload: Payload = {
                entity: newProps?.entity,
                createdOnType: DocketIssueCreatedOnType.U,
                docketId: BranchData?.docket._id
            };
    
            // Determine issueType based on the type
            if (type === 'ewayBillMissing') {
                payload.issueType = DocketIssueType.EwaybillDetailsMissing;
            } else if (type === 'illegalGoods') {
                payload.issueType = DocketIssueType.IllegalGoodsFound;
            }
    
            // Send API request
            await illegalGoodsFoundAndEwayBillMissing(payload);
    
            // Hide loader after successful API call
            newProps.data.hideLoader();
    
            // Show success message
            const successMessage = type === 'ewayBillMissing'
                ? 'Successfully Added this Docket as Eway bill Details Missing Docket!'
                : 'Successfully Added this Docket as Illegal Goods Found!!';
            showMessage(successMessage, success);
    
            // Close dialogs
            setLiggleGoodsDialog(false);
            setEwayBillMissiingDialog(false);
    
            // Set the respective state based on the type
            // if (type === 'ewayBillMissing') {
            //     setEwayBillIssue(()=>{

            //     });
            // } else if (type === 'illegalGoods') {
            //     setIllegalIssue(true);
            // }
        } catch (err) {
            // Hide loader and show error
            newProps.data.hideLoader();
            showHttpError(err);
        }
    };
    

    const addImagedPackage = (ele: any) => {
        const data = [...imagedPackageData];
        data.map((r: any, i: number) => {
            if (r.order === ele.order) {
                data.splice(i, 1);
            }
        });
        data.push(ele);
        setImagedPackageData(data);
    };

    const handleNDClose = () => {
        setNewDestOpen(false);
        setNDAmount("");
        setSelectedND("");
        setImagedPackageData([]);
        setImagePackages([]);
    };

    const handleSubmitBTO = async () => {
        if (imagedPackageData?.length <= 0) {
            return showHttpError(`All package Images are required !`)
        }
        newProps.data.showLoader();
        try {
            const payload = {
                docket: BranchData?.docket._id,
                branch: BranchData?.docket.entity,
                BTOCharge: btoAmount,
                packages: imagedPackageData,
            };
            await sendBackToOrigin(payload);
            newProps.data.hideLoader();
            showMessage("Successfull !!", success);
            handleBTOClose();
        } catch (err) {
            newProps.data.hideLoader();
            showHttpError(err);
        }
    };

    const handleBTOClose = () => {
        setBackToOriginOpen(false);
        setbtoAmount("");
        setImagedPackageData([]);
        setImagePackages([]);
    };

    const getGuestUpdatedData = async (docket: any) => {
        let feedBackPayload = {
            docketId: docket,
        };
        getGuestComplaint(feedBackPayload)
            .then((feedbackRes: any) => {
                setFeedbackResponse(feedbackRes);
            })
            .catch(() => setFeedbackResponse(""));
    };

    const handleDialogClose = () => {
        setIsDialogOpen(0)
        setOpenActionForRecieved(false)
        setOutsideDialogOpen(false)
        setOutsidePaymentDialogOpen(false)
        setOutsideRating(false)
        setOpen(false)
        setOpenAction(null);
        setReason("");
        setCustomerType("");
        setName("");
        setContact("");
        setDetails("");
        setImageTwo("");
        setMissBehaveUSer("");
        setMissBehaveBranch("");
        setFeedBack("");
        setImage("");
    };

    const handleReceived = async () => {
        newProps?.data?.showLoader();
        try {
            let selectedPackages = packageOrderForRecieved && packageOrderForRecieved?.map((x: any) => x?.value);
            const payload = {
                packages: selectedPackages,
                sub: newProps?.sub,
                entity: newProps?.entity,
                remarkText: reason,
            };
            await setReceivedPackages(payload);
            await trackFromTrackingID(BranchData?.docket?.docketNumber);
            handleDialogClose();
            // setPackageStatus(null);
            newProps?.data?.hideLoader();
        } catch (err) {
            newProps?.data?.hideLoader();
            showHttpError(err);
        }
    };

    const handleSubmitRemarks = async () => {
        newProps?.data?.showLoader();
        try {
            if (reason === "") {
                newProps?.data?.hideLoader();
                return showHttpError(`Remarks Should Not Be Empty !~`);
            }
            if (allPackages.length > 0) {
                let payload: { [k: string]: any } = {
                    packages: allPackages,
                    remarkType: openAction,
                    loginType: newProps?.sub,
                    remarkText: reason,
                    branch: newProps?.entity
                };
                if (Image) {
                    payload.remarkImage = Image;
                }

                await setMissingPackages(jsonToFormdata(payload));
                showMessage('Remarks Added Successfully!', success, 2000);
                newProps?.data?.hideLoader();
                setIsDialogOpen(0)
                setOpenAction(null);
                setReason("");
                setImage("");
                setAllPackages([])
                setPackageOrderForRecieved([])
            }
            else {
                newProps?.data?.hideLoader();
                showMessage("Kindly Select Any Package", failed)
            }
        } catch (err) {
            showHttpError(err);
            newProps?.data?.hideLoader();
            setIsDialogOpen(0)
            setOpenAction(null);
            setReason("");
            setImage("");
            setAllPackages([])
            setPackageOrderForRecieved([])
        }
    };

    const qrRePrint = async () => {
        try {
            let payload: { [k: string]: any } = {
                docket: BranchData?.docket?.docketNumber,
                queryType: "tracking",
                old: false,
                sub: "B",
                entity: newProps?.entity
            }
            const res = await fetchDocketDetails(payload);
            await addQrReprintPackageLog({
                entity: newProps?.entity,
                docketNumber: BranchData?.docket?.docketNumber
            })
            printQRDocket(res.docket, res.packages);
        } catch (Err) {
            showMessage("Qr print failed!", failed);
        }
    }

    const fetchUser = async () => {
        newProps?.data?.showLoader();
        try {
            let tempArr: any = [];
            let res = await listUsersService()
            res.map((ele: any) => {
                tempArr.push({ label: `${ele?.name?.fName} ${ele?.name?.lName}`, value: ele?._id })
            })
            setUserOptions(tempArr);
            newProps?.data?.hideLoader();
        } catch (error) {
        }
    };

    const fetchUserForCustomer = async () => {
        newProps?.data?.showLoader();
        try {
            let tempArr: any = [];
            let res = await listAllUsersForCustomers()
            res.map((ele: any) => {
                tempArr.push({ label: `${ele?.name?.fName} ${ele?.name?.lName}`, value: ele?._id })
            })
            setUserOptions(tempArr);
            newProps?.data?.hideLoader();
        } catch (error) {
        }
    };

    const customerOptions = [
        { label: "Sender", value: "sender" },
        { label: "Receiver", value: "receiver" },
    ];

    const openInNewTab = (url: any) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const handleMBandLDcomplaints = async (value: any) => {
        newProps?.data?.showLoader();
        try {
            if (details === "") {
                newProps?.data?.hideLoader();
                return showHttpError(`Remarks Should Not Be Empty !~`);
            }
            let payload: any = {
                feedbackType: "COMP",
                complaintType: value,
                docketNumber: BranchData?.docket?.docketNumber,
                contactType: customerType && customerType.value,
                contactNumber: contact,
                contactName: name,
                messageText: details,
            };
            if (missBehaveBranch && missBehaveBranch.value) {
                payload.misbehaveBranch = missBehaveBranch.value;
            }
            if (misBehaveUser && misBehaveUser.value) {
                payload.misbehaveBy = misBehaveUser.value;
            }
            if (ImageTwo) {
                payload.media = ImageTwo;
            }
            const response: any = await createComplaintUser(payload)
            setFeedBack(response.feedbackNumber);
            setOpen(true);
            newProps?.data?.hideLoader();
        } catch (err) {
            showHttpError(err);
            newProps?.data?.hideLoader();
        }
    };

    const handleCustomerComplaintOutSide = async (value: any) => {
        newProps?.data?.showLoader();
        try {
            let payload: any = {
                feedbackType: "COMP",
                complaintType: value,
                docketNumber: BranchData?.docket?.docketNumber,
                contactType: customerType && customerType.value,
                contactNumber: contact,
                contactName: name,
                messageText: details,
            };

            await createComplaintGuest(payload);
            newProps?.data?.hideLoader();
            handleDialogClose()
        } catch (error) {
            showHttpError(error);
            newProps?.data?.hideLoader();
        }
    }

    const hanldeCustomerComaplints = async (value: any) => {
        newProps?.data?.showLoader();
        try {
            if (details === "") {
                newProps?.data?.hideLoader();
                return showHttpError(`Remarks Should Not Be Empty !~`);
            }
            let payload: any = {
                feedbackType: "COMP",
                docketNumber: BranchData?.docket?.docketNumber,
                complaintType: value,
                messageText: details
            }
            const response: any = await addCustomerComplaintForBranchOrder(payload)
            showMessage("Your Complaint has been created, To see all Complaints Click on the Message Icon", success, 3000)
            setFeedBack(response.feedbackNumber);
            setOpen(true);
            setDetails("")
            handleDialogClose()
            newProps?.data?.hideLoader();
        } catch (err) {
            showHttpError(err);
            newProps?.data?.hideLoader();
        }
    };

    const handleLogin = async () => {
        // newProps?.data?.showloader();
        try {
            localStorage.removeItem("refreshToken")
            localStorage.removeItem("refreshTokenExpiresAt")
            socket.emit("logout", localStorage.getItem("kabra:customertoken"));
            await newProps?.data?.customerLogin(selectedNumber, userHasPass ? firstPopupPassword : firstPopupConfirmPassword, false, localStorage.getItem("deviceId"), localStorage.getItem("latitude"), localStorage.getItem("longitude"))
            navigate("/customerDashboard/booking")
            return;
            // newProps?.data?.hideloader();
        } catch (error) {
            // newProps?.data?.hideloader();
            showHttpError(error)
        }
    }

    const handleSignUp = async () => {
        // newProps?.data?.showloader();
        try {
            if (firstPopupNewPassword === firstPopupConfirmPassword) {
                let payload: any = {
                    password: firstPopupNewPassword,
                    contact: selectedNumber,
                    OTP: firstPopupOtp
                }
                await setCustomerPassword(payload);
                // let payloadForOtp:any = {
                //   contact: selectedNumber,
                //   OTP: firstPopupOtp
                // }
                // let response = await verifyOTP(payloadForOtp)
                handleLogin()
                // historyData.push("/customerDashboard/booking")
                // newProps?.data?.hideloader();
            }
            else {
                return showMessage("New Password and Confirm Password is not Matched ~!", failed, 3000);
            }
        } catch (error) {
            // newProps?.data?.hideloader();
            showHttpError(error)
        }
    }

    const getPhoneNumberPrefix = (contact: any) => {
        let newcontact = contact.toString();
        let firstThreeNumber: any = newcontact.substr(0, 3)
        let lastThreeNumber: any = newcontact.substr(7, 9)
        return `${firstThreeNumber}****${lastThreeNumber}`
    }

    const handleRatingForBranchOrder = async () => {
        if (!customerTypeForRating) {
            return showHttpError("Select Any One Customer Type !")
        }
        try {
            let payload = {
                "feedbackType": "ALL",
                "feedbackTitle": reviewFromUser,
                "docketNumber": BranchData?.docket.docketNumber,
                "rating": ratingFromUser,
                "customerContact": customerTypeForRating?.value === "sender" ? BranchData?.docket?.sender?.contact : BranchData?.docket?.receiver?.contact,
                "customerName": customerTypeForRating?.value === "sender" ? BranchData?.docket?.sender?.name : BranchData?.docket?.receiver?.name,
            }
            await customerReviewRating(payload);
            setRatingDone(true);
            showMessage("Review & Rating Succesfully Given", success, 2000);
            handleDialogClose();
        } catch (error) {
            showHttpError(error);
        }
    }

    const getOtpForBranchorder = async () => {
        try {
            let payload = { docketNumber: BranchData?.docket.docketNumber };
            let response = await getOtpForBranchOrderTracking(payload);
            setOtpForDelivery(response);
        } catch (error) {
            showHttpError(error);
        }
    }

    const handleIndividualBranchOrderChat = (extraData: any, feedbackNumber: any, bookingtype?: any) => {
        // historyData.push({
        //   pathname: "customerChat/individualChat",
        //   state: {
        //     from: "redirect-chat-from-customerchat-list",
        //     data: { feedbackNumber },
        //     anotherData: extraData,
        //     bookingType: bookingtype
        //   }
        // })
        navigate("/CustomerDashboard/customerChat/individualChat", {
            state: {
                from: "redirect-chat-from-customerchat-list",
                data: { feedbackNumber },
                anotherData: extraData,
                bookingType: bookingtype
            }
        })
    }

    useEffect(() => {
        getBranchOrderData();
        getOtpForBranchorder();
        // newProps.data.resetDoorDelivery();
        if (SourceName === "Customer-Authenticated-BranchOrder") {
            fetchUserForCustomer();
            checkingPastComplaints();
        }
        if (SourceName === "Branch-Authenticated-BranchOrder") {
            fetchUser();
            checkingPastComplaints();
        }
        if (SourceName === "Customer-NotAuthenticated-BranchOrder") {
            setFirstPopup(true);
            // initGA4();
        }

        branchListData &&
            branchListData.length > 0 &&
            setNewDestOptions([
                ...branchListData.map((i: any) => ({
                    label: i.branchName,
                    value: i._id,
                })),
            ]);
        branchListData &&
            branchListData.length > 0 &&
            setBranchOptions([
                ...branchListData.map((i: any) => ({
                    label: i.branchName,
                    value: i._id,
                })),
            ]);
        // 
        showMessage("Order Tracked Succesfully", success, 2000);
        if (SourceName === "Branch-Authenticated-BranchOrder") {
            trackFromTrackingID(BranchData?.docket.docketNumber)
        }

        BranchData?.packages?.map((x: any) => {
            if (x.status === "MR") {
                setMissingParcel(true)
            }
            if (x.status === "M") {
                setMissingParcel(true)
            }
            if (x.status === "INIT") {
                setNotReceivedDisabled(true);
            }
        }
        )

        if (BranchData?.docket?.feedBacks && BranchData?.docket?.feedBacks?.review?.length > 0) {
            setReviewFromUser(BranchData?.docket?.feedBacks?.review[0]?.feedbackTitle)
            setRatingFromUser(BranchData?.docket?.feedBacks?.review[0]?.rating)
            setRatingDone(true)
        }
    }, [])

    useEffect(() => {
        if (senderOptIn === "Sender") {
            if (BranchData?.docket?.sender?.isPassWordExists === true) {
                setUserHasPass(true);
            }
        }
        else if (senderOptIn === "Reciever") {
            if (BranchData?.docket?.receiver?.isPassWordExists === true) {
                setUserHasPass(true);
            }
        }
    }, [senderOptIn])

    useEffect(() => {
    }), [newProps?.data?.customerChatHistory, ratingDone]

    const goToMap = () => {
        if (iOS === true) {
            window.open(
                `comgooglemapsurl://maps.google.com/maps?z=12&t=m&q=loc:${BranchData?.docket?.actDestBranch?.bLocation?.coordinates[1]}+${BranchData?.docket?.actDestBranch?.bLocation?.coordinates[0]}`
                , '_blank');
        }
        else {
            window.open(
                `http://maps.google.com/maps?z=12&t=m&q=loc:${BranchData?.docket?.actDestBranch?.bLocation?.coordinates[1]}+${BranchData?.docket?.actDestBranch?.bLocation?.coordinates[0]}`
                , '_blank');
        }

    }

    const setInState = (Data: any) => {

        const doorDeliveryDocketData = Data?.doorDeliveryDocket?.doorDelAddress
        newProps.data.setDoorDelivery("officeName", doorDeliveryDocketData?.officeName);
        newProps.data.setDoorDelivery("floor", doorDeliveryDocketData?.floor);
        newProps.data.setDoorDelivery("tower", doorDeliveryDocketData?.tower);
        newProps.data.setDoorDelivery("nearby_landmark", doorDeliveryDocketData?.nearby_landmark);
        if (Data?.doorDeliveryDocket?.doorDelDocketNumber)
            newProps.data.setDoorDelivery("city", { label: Data?.docket?.destBranch?.address?.city?.name, value: Data?.docket?.destBranch?.address?.city?._id });
        newProps.data.setDoorDelivery("delCity", { label: Data?.docket?.destBranch?.address?.city?.name, value: Data?.docket?.destBranch?.address?.city?._id });
        newProps.data.setDoorDelivery("state", { label: Data?.docket?.destBranch?.address?.city?.state?.name, value: Data?.docket?.destBranch?.address?.city?.state?._id });
        newProps.data.setDoorDelivery("pincode", doorDeliveryDocketData?.pincode);
        newProps.data.setDoorDelivery("origin", {
            place_id: doorDeliveryDocketData?.place?.placeId,
            formatted_address: doorDeliveryDocketData?.place?.placeValue,
            name: doorDeliveryDocketData?.place?.name,
            geometry: {
                location: {
                    lat: doorDeliveryDocketData?.place?.coordinate?.latitude,
                    lng: doorDeliveryDocketData?.place?.coordinate?.longitude,
                },
            },
        })
    }

    function toHoursAndMinutes(totalSeconds: any) {
        let hours = Math.floor(totalSeconds / 3600);
        totalSeconds %= 3600;
        let minutes = Math.floor(totalSeconds / 60);
        return `${hours} hr ${minutes} min`
    }

    const handleSend = async () => {
        try {
            let payload: any = {
                messageText: guestMessage,
            };
            if (replyImage) {
                payload.media = replyImage;
            }
            newProps?.data?.userInfo?.uid
                ? await sendUserMessage(
                    latComplaint?.feedbackNumber,
                    jsonToFormdata(payload)
                )
                : await sendGuestMessage(
                    latComplaint?.feedbackNumber,
                    jsonToFormdata(payload)
                );
            setGuestMessage("");
            setReplyImage("");

            await getGuestUpdatedData(BranchData?.docket?._id);
        } catch (err) {
            showHttpError(err);
        }
    };

    //   const handleIndividualChatBranchOrder = (otherData:any,feedBackNumber:any,type:any) => {
    //     historyData.push({pathname:"/dashboard/individualChat",
    //                         state:{
    //                           from: "redirect-chat-from-customerchat-list",
    //                           data: feedBackNumber,
    //                           anotherData : otherData,
    //                           bookingType : type
    //                         }
    //                       })
    //  }

    const rePrintVoucher = () => {
        const csstext: any = `
          h2 {
            color: black;
            font-family: sans-serif;
          }
          `;
        const d = new Printd();
        let doc: any = document.getElementById("door_delivery_voucher_print");
        d.print(doc, csstext);
    }

    const handleClose = async (ind: any) => {
        try {
            let payload: any = {
                messageText: guestMessage,
                complaintStatus: newProps?.data?.userInfo?.uid ? "CU" : "CC",
            };
            if (replyImage) {
                payload.media = replyImage;
            }
            newProps?.data?.userInfo.uid
                ? await sendUserMessage(
                    BranchData?.docket?.feedBacks?.complaints[ind]?.feedbackNumber,
                    jsonToFormdata(payload)
                )
                : await sendGuestMessage(
                    BranchData?.docket?.feedBacks?.complaints[ind]?.feedbackNumber,
                    jsonToFormdata(payload)
                );
            setGuestMessage("");
            setReplyImage("");
            await getGuestUpdatedData(BranchData?.docket?._id);
        } catch (err) {
            showHttpError(err);
        }
    };

    const getFinalDeliveryTime = (value: any) => {
        var flag = true;
        BranchData?.packages?.map((val: any, index: any) => {
            let ref = val[0]?.expectedReachTime;
            if (val[index + 1]?.expectedReachTime !== ref) {
                flag = false;
            }
        });
        if (flag) {
            if (value === "Branch-Authenticated-BranchOrder") {
                return (<div className="text-primary-400 fs-link">
                    <p className="mx-auto fs-link">{`Your Parcel is Expected to Reach the Destination on`}</p> {" "}
                    <span>
                        {`${formatDate(BranchData?.packages[0]?.expectedReachTime)}`}
                    </span>
                    <span>
                        {` at `}
                    </span>
                    <span>
                        {`${formatTime(BranchData?.packages[0]?.expectedReachTime)}.`}
                    </span>
                </div>)
            }
            else if (value === "Customer-Authenticated-BranchOrder") {
                return <p style={{ color: "var(--clr-primary-400)" }} className="mx-auto fs-link">{`Your Parcel is Expected to Reach the  Destination on ${formatDate(BranchData?.packages[0]?.expectedReachTime)} at ${formatTime(BranchData?.packages[0]?.expectedReachTime)}.`}</p>
            }
            else {
                return <p style={{ color: "var(--clr-primary-400)" }} className="mx-auto fs-link">{`Your Parcel is Expected to Reach the Destination on ${formatDate(BranchData?.packages[0]?.expectedReachTime)} at ${formatTime(BranchData?.packages[0]?.expectedReachTime)}.`}</p>
            }
        }
        else {
            return <div></div>
        }
    }
    //amount,docketNumber,txnsNumber,nonCashPaymentId
    const handlePayment = async () => {
        newProps?.data?.showLoader();
        try {
            const currentURLWithPort = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
            let body: any = {
                "merchant_id": 174716,
                "amount": process.env.NODE_ENV != "development" ? (DoorDelBookingCheckBox ? BranchData?.doorDeliveryDocket?.deliveryCharge : BranchData?.docket.gross) : 5,

                "frontend_redirect_url": `${currentURLWithPort}/customerDashboard/booking`,
                nonCashPaymentId: "655dabeb1baae4150e9ad853",// for testing need to call api
                docketNumber: BranchData?.docket.docketNumber,
                contact: BranchData?.docket?.sender?.contact,// default set for testing
                txnsType: DoorDelBookingCheckBox ? "DoorDeliveryCustomerOnline" : "CustomerOnline"
            }
            let response = await createCustomerCCAvenueTxn(body);
            const encRequest = response && response?.encRequest;
            const accessCode20 = response && response?.accessCode;
            // setTxnsNumber(response.CCAvenueTxns.txnsNumber)
            if (encRequest) {
                // const redirectURL = `https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction&encRequest=${encRequest}&access_code=${accessCode}`;
                // window.location.href = redirectURL;
                const a = document.createElement("a");
                // a.setAttribute("target", "_blank");
                a.href = `https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction&encRequest=${encRequest}&access_code=${accessCode20}`;
                document.body.appendChild(a);
                a.click();
            }

            newProps?.data?.hideLoader();
        } catch (error) {
            newProps?.data?.hideLoader();
            showHttpError(error)
        }
    };

    const handleOutsidePayment = async () => {
        if (!senderOptInForPayment) {
            return showHttpError(`Select Sender Or Reciever Is Mandatory !`);
        }
        newProps?.data?.showLoader();
        try {
            const currentURLWithPort = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
            let body: any = {
                "merchant_id": 174716,
                "amount": process.env.NODE_ENV === "production" ? (DoorDelBookingCheckBox ? BranchData?.doorDeliveryDocket?.deliveryCharge : BranchData?.docket.gross) : 5,
                "frontend_redirect_url": `${currentURLWithPort}/BlindPage`,
                nonCashPaymentId: "655dabeb1baae4150e9ad853",// for testing need to call api
                docketNumber: BranchData?.docket.docketNumber,
                txnsType: DoorDelBookingCheckBox ? "DoorDeliveryCustomerOnline" : "CustomerOnline",
                contact: selectedNumberForPayment
            }
            let response = await createCustomerCCAvenueTxn(body);
            const encRequest = response && response?.encRequest;
            const accessCode20 = response && response?.accessCode;
            // setTxnsNumber(response.CCAvenueTxns.txnsNumber)
            if (encRequest) {
                // const redirectURL = `https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction&encRequest=${encRequest}&access_code=${accessCode}`;
                // window.location.href = redirectURL;
                const a = document.createElement("a");
                // a.setAttribute("target", "_blank");
                a.href = `https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction&encRequest=${encRequest}&access_code=${accessCode20}`;
                document.body.appendChild(a);
                a.click();
            }

            newProps?.data?.hideLoader();
        } catch (error) {
            newProps?.data?.hideLoader();
            showHttpError(error)
        }
    };

    // const makePayment=async()=>{

    //     showLoader();
    //     try {
    //       const payload={
    //         "amount":5,
    //         nonCashPaymentId:"655dabeb1baae4150e9ad853",
    //         docketNumber:BranchData?.docket.docketNumber,
    //         txnsNumber:"000250"
    //       }
    //   await  BranchBookingMakePayment(payload)
    //   hideLoader();
    // } catch (error) {
    //   hideLoader();
    //   showHttpError(error)
    // }
    //   }

    let checkValidity = (date: string) => {
        let validDate = new Date(date)
        validDate.setDate(validDate.getDate() + 1)

        return validDate >= new Date()
    }

    let branchDeliveryCharge =
        (BranchData?.docket?.settled == false && !BranchData?.docket?.isDeliveryChargePaid &&
            typeof BranchData?.docket?.branchDeliveryChargeInfo?.acceptedBranchDeliveryCharge === "number" && BranchData?.docket?.branchDeliveryChargeInfo.status === "A" &&
            checkValidity(BranchData?.docket?.branchDeliveryChargeInfo?.processedAt)) ?
            BranchData?.docket?.branchDeliveryChargeInfo?.acceptedBranchDeliveryCharge :
            typeof BranchData?.docket?.deliveryCharge === "number" ?
                BranchData?.docket?.deliveryCharge : 0;


    let demurrage = typeof BranchData?.docket?.paidDemurrage === "number"
        ? BranchData?.docket?.paidDemurrage
        : typeof BranchData?.docket?.actualDemurrage === "number"
            ? BranchData?.docket?.actualDemurrage
            : 0;

    let discount = typeof BranchData?.docket?.discount === "number" ? BranchData?.docket?.discount : typeof BranchData?.docket?.discountInfo?.acceptedDiscount === "number" && checkValidity(BranchData?.docket?.discountInfo?.processedAt) && BranchData?.docket?.discountInfo?.status === "A" ? BranchData?.docket?.discountInfo?.acceptedDiscount : 0;

    let gstCustodyPenalty = typeof BranchData?.docket?.gstCustodyPenalty === "number"
        ? BranchData?.docket?.gstCustodyPenalty
        : 0;

    let deliveryCharge = BranchData?.docket?.doorDelCharges;


    let additionalCost =
        BranchData &&
        BranchData?.docket?.bilty +
        BranchData?.docket?.handling +
        (BranchData?.docket?.gstAmount ? BranchData?.docket?.gstAmount : 0);


    let FinalAsn = (BranchData &&
        BranchData?.docket?.settled &&
        BranchData?.packages &&
        BranchData?.packages?.length > 0
        ? BranchData?.packages?.reduce((a: any, b: any) => a + b.amount, 0)
        : BranchData?.packages?.reduce(
            (a: any, b: any) => a + b.amount,
            0
        )) +
        additionalCost +
        (BranchData && (BranchData?.docket?.pickupCharge ? BranchData?.docket?.pickupCharge : 0)) +
        (BranchData && (BranchData?.docket?.colAmt ? BranchData?.docket?.colAmt : 0)) +
        (BranchData &&
            (BranchData?.docket?.BTOCharge
                ? BranchData?.docket?.BTOCharge
                : 0)) +
        (BranchData &&
            (BranchData?.docket?.BTHCharge
                ? BranchData?.docket?.BTHCharge
                : 0)) +
        (BranchData &&
            (BranchData?.docket?.NDCharge
                ? BranchData?.docket?.NDCharge
                : 0)) +
        (BranchData &&
            (BranchData?.docket?.intermediateCharge
                ? BranchData?.docket?.intermediateCharge
                : 0)) +
        (BranchData?.packages && BranchData?.packages?.length > 0 ?
            BranchData?.packages?.reduce(
                (a: any, b: any) => a + (b.extraGrossFreight || 0),
                0
            ) : 0
        )

    let TotalAmount: any = Math.round(FinalAsn / 10) * 10;

    let totalAmount =
        BranchData &&
        (Math.round(
            (parseInt(TotalAmount) +
                +Number(branchDeliveryCharge) +
                Number(deliveryCharge) +
                Number(gstCustodyPenalty) +
                Number(demurrage) -
                Number(discount)) / 10
        ) * 10);


    let totalNetCost =
        BranchData &&
        (Math.round(
            (parseInt(TotalAmount) +
                +Number(branchDeliveryCharge) +
                Number(deliveryCharge) -
                Number(discount)) / 10
        ) * 10);

    let totalDoorDelCost = BranchData &&
        (Math.round(
            Number(!BranchData?.doorDeliveryDocket?.settled ? Number(BranchData?.doorDeliveryDocket?.totalDoorDelCharges) : 0) / 10
        ) * 10);

    let totalAmountWithoutTopay = totalAmount - BranchData?.docket?.gross;

    // let combinedTotalAmount =
    //     BranchData &&
    //     (Math.round(
    //         (parseInt(TotalAmount) +
    //             +Number(branchDeliveryCharge) +
    //             Number(deliveryCharge) +
    //             (!BranchData?.doorDeliveryDocket?.settled ? Number(BranchData?.doorDeliveryDocket?.deliveryCharge) : 0) +
    //             Number(gstCustodyPenalty) +
    //             Number(demurrage) -
    //             Number(discount)) / 10
    //     ) * 10);

    // let combinedAmountWithoutTopay = combinedTotalAmount - TotalAmount;

    let showOnlyTotalAmount =
        BranchData &&
        (Math.round(
            (parseInt(TotalAmount) +
                +Number(branchDeliveryCharge) +
                Number(deliveryCharge) +
                (BranchData?.doorDeliveryDocket?.docketNumber ? Number(BranchData?.doorDeliveryDocket?.deliveryCharge) : 0) +
                Number(gstCustodyPenalty) +
                Number(demurrage) -
                Number(discount)) / 10
        ) * 10);

    // let onlyDoorDeliveryAmount = BranchData && BranchData?.doorDeliveryDocket && (Math.round(((!BranchData?.doorDeliveryDocket?.settled ? Number(BranchData?.doorDeliveryDocket?.deliveryCharge) : 0)) / 10) * 10);

    useEffect(() => {
        const { billUrl, idUrl, consigneeIdImage, receiverPhoto, docketUrls } = BranchData?.imageGallery;

        const Arr: any = [];

        if (billUrl) {
            Arr.push({ name: "Bill Image", url: billUrl });
        }
        if (docketUrls?.length > 0) {
            docketUrls?.map((x: any, index: any) => {
                Arr.push({ name: `Docket Image ${index + 1}`, url: x });
            })
        }
        if (idUrl) {
            Arr.push({ name: "Id Image", url: idUrl });
        }
        if (consigneeIdImage) {
            Arr.push({ name: "Consignee Id Image", url: consigneeIdImage });
        }
        if (receiverPhoto) {
            Arr.push({ name: "Receiver Image", url: receiverPhoto });
        }

        setArrayOfImage(Arr);

    }, [BranchData?.imageGallery]);



    return (
        // <div id="BranchPersonAuthenticatedViewContainer">
        <>
            <PaperCard className="max-w-1200 mx-auto">
                {/* Header start */}
                <div className="flex jc-between ai-center flex-wrap mb-8 | m-jc-center">
                    <BackIcon className="text-primary-400" style={{fontSize:'25px'}} onClick={() => {
                        if (SourceName === "Branch-NotAuthenticated-BranchOrder") {
                            navigate('/');
                        }
                        if (SourceName === "Customer-NotAuthenticated-BranchOrder") {
                            navigate('/');
                        }
                        else {
                            navigate(newProps?.data?.customerTrackingData?.backToOriginPath);
                        }
                    }} />
                    <PageTitle title="Order Tracking" style={{fontSize:'1.8rem'}} />
                    <div className="flex gap-1 ai-center jc-center fs-link | m-w-100 m-mt-1">
                        {arrayOfImage?.length > 0 &&
                            <Tooltip title="Photo Gallery">
                                <span onClick={() => { setPackageImageDialog(true) }}><ImagesIcon className={`${iconsClassName}`} /></span>
                            </Tooltip>
                        }
                        {SourceName === "Branch-Authenticated-BranchOrder" &&
                            <>
                                <Tooltip title="QR Print">
                                    <button onClick={() => { qrRePrint() }}><QRCodeIcon className={`${iconsClassName}`} /></button>
                                </Tooltip>
                            {/* <Tooltip title="Print Builty">
                                <button onClick={printBuilty} ><FaPrint style={{fontSize:"x-large"}} /></button>
                                </Tooltip> */}
                            </>
                        }
                        {SourceName === "Customer-Authenticated-BranchOrder" ?
                            // SourceName === "Branch-Authenticated-BranchOrder" || 
                            <Tooltip title="Download Builty">
                                <button className="bg-transparent border-none outline-none" onClick={downloadBuilty}><DownloadIcon className={`${iconsClassName}`} /></button>
                            </Tooltip>
                            :
                            <></>
                        }
                        <ShareToWhatsapp docketId={BranchData?.docket?._id} />
                        {SourceName === "Branch-Authenticated-BranchOrder" &&
                            <Tooltip title="BTO , Fetch , New Dest , Complaint">
                                <button onClick={() => { showMoreBtns(!moreBtns) }}><ThreeDotsIcon className={`${iconsClassName}`} /></button>
                            </Tooltip>
                        }
                        <Dialog state={packageImageDialog} header='Gallery' onClose={() => { setPackageImageDialog(false) }}
                            body={
                                <div>
                                    <Select
                                        placeholder="Select Image Type"
                                        onChange={(e: any) => {
                                            setSelectedImage(e);
                                        }}
                                        value={selectedImage}
                                        options={imagesOptions}
                                        styles={{
                                            menu: (base) => ({ ...base, zIndex: 10000000000000 }),  // High z-index for dropdown
                                            menuList: (base) => ({ ...base, zIndex: 10000000000000 }),  // High z-index for dropdown
                                          }}
                                          // menuPortalTarget={document.body}  // Render outside dialog
                                          menuPosition="fixed"
                                    />
                                    {selectedImage?.value !== ''
                                        &&
                                        <MyImage
                                            rotate={false}
                                            isSigned={true}
                                            pagination={false}
                                            objectID={selectedImage?.value}
                                        />
                                    }
                                </div>
                            }
                            footer={
                                <CloseButton onClick={() => { setPackageImageDialog(false) }} />
                            }
                        />

                    </div>
                </div>
                {/* Header End */}

                {/* Info Section One start */}
                <div>
                    <div className='fs-link text-primary-400 fw-600 m-0 w-100 m-fs-list-item-heading'>
                        <PaperCard className='four-column t-two-column mt-1 m-two-column gap-1'>
                            <div className="flex gap-4 ai-center">
                                <DocketNumberIcon className={`${iconsClassName}`} />
                                <p>
                                    {SourceName === "Branch-Authenticated-BranchOrder" ? BranchData?.docket.docketNumber : BranchData?.docket.trackingNumber}
                                </p>
                            </div>
                            <div className="flex gap-4 ai-center">
                                <CalendarIcon className={`${iconsClassName}`} />
                                <p>
                                    {formatDate(BranchData?.docket.createdAt)}
                                </p>
                            </div>
                            <div className="flex gap-4 ai-center">
                                    {BranchData?.docket.deliveredAt ?
                                        <CalendarCheckIcon className={`${iconsClassName}`} />
                                        :
                                        <TimelineIcon className={`${iconsClassName}`} />
                                    }
                                <p>
                                    {BranchData?.docket.deliveredAt ? formatDate(BranchData?.docket.deliveredAt) : "In Transit"}
                                </p>
                            </div>
                            <div className="flex gap-4 ai-center">
                                <CashIcon className={`${iconsClassName}`} />
                                {BranchData?.docket.canceledInfo ?
                                    <p>
                                        Cancelled
                                    </p>
                                    :
                                    <p>
                                        {!BranchData?.docket.settled ? (BranchData?.docket.paymentMode === "topay" ?
                                            <div className="flex gap-4 ai-center mb-4 m-flex-col">
                                                <button className={selectPaymentOptionBtn2 === BranchData?.docket.paymentMode ? "bg-primary br-4 white px-1 py-6 border-none" : ""} onClick={() => { setSelectPaymentOptionBtn2(BranchData?.docket.paymentMode) }} > {BranchData?.docket.paymentMode} </button>
                                                <button className={selectPaymentOptionBtn2 === "online" ? "px-1 py-6  br-4 " : "payOnlineAttractive  br-4  px-1 py-6 .border-success"} onClick={() => {
                                                    if (SourceName === "Customer-NotAuthenticated-BranchOrder" || SourceName === "Customer-NotAuthenticated-CustomerOrder") {
                                                        setOutsidePaymentDialogOpen(true)
                                                    }
                                                    else {
                                                        handlePayment()
                                                    }
                                                }}>Online</button>
                                            </div>
                                            :
                                            <>{" "} {BranchData?.docket.paymentMode}</>) : <>{" "} {BranchData?.docket.paymentMode}</>
                                        }
                                    </p>
                                }
                                {/* <button onClick={()=>{
makePayment()
            }}>make payment</button> */}
                            </div>
                            {/* : */}
                            {/* <div style={{border : "0.2px solid transparent"}}>
            <span style={{fontSize:"x-large",padding:'1%',border : "0.2px solid transparent"}}><CashIcon style={{backgroundColor:"white",color:"var(--clr-primary-400)"}} />{" "}</span>
            <div style={{border : "0.2px solid transparent" , display : "flex" , justifyContent : "left" , width : "100%"}}>
                <p style={{border : "0.2px solid transparent" , width : "30%"}}>
                {" "} {BranchData?.docket.paymentMode}
                </p>
                <p style={{border : "0.2px solid transparent", width : "50%" , textAlign : "left"}} className="payOnlineAttractive">
                {" "} Pay Online
                </p>
            </div>
            </div> */}
                            <div className="flex ai-center gap-4">
                                <PackageIcon className={`${iconsClassName}`} />
                                <p>
                                    {BranchData?.packages?.length}
                                </p>
                            </div>
                            <div className="flex ai-center gap-4">
                                <LocationIcon className={`${iconsClassName}`} />
                                <p>{BranchData?.docket.originBranch.branchName}</p>
                            </div>
                            <div className="flex ai-center gap-4">
                                <SharpLocationIcon className={`${iconsClassName}`} />
                                <p>{BranchData?.docket?.actDestBranch ? BranchData?.docket?.actDestBranch?.branchName : BranchData?.docket.destBranch.branchName}{" "}
                                    {!BranchData?.doorDeliveryDocket.doorDelDocketNumber && BranchData?.docket?.deliveredAt === undefined && <></>}
                                </p>
                                    <InfoIcon className={`${iconsClassName}`} onClick={() => { setBranchAdd(true) }} />
                            </div>
                            <div className="flex ai-center gap-4">
                                <RupeeIcon className={`${iconsClassName}`} />
                                {showOnlyTotalAmount}
                                <InfoIcon className={`${iconsClassName} mt-0`} onClick={() => { setPaymentInfo(true) }} />
                            </div>
                        </PaperCard>
                    </div>

                    <Dialog
                        state={branchAdd}
                        size='small'
                        onClose={() => { setBranchAdd(false) }}
                        header='Branch Address'
                        body={
                            <div className='popupForbranchAddress'>
                                {!BranchData?.doorDeliveryDocket.doorDelDocketNumber &&
                                    <>
                                        <div className='flex-col gap-1 fs-regular'>
                                            <div className="flex ai-center gap-1"><BranchIcon className="text-primary-400 fs-link" /><span>{BranchData?.docket?.actDestBranch?.branchName}</span></div>
                                            <div className="flex gap-1 ai-center">
                                                <LocationIcon className="text-primary-400 fs-link" />
                                                <div className="flex-col">
                                                    <span className="fw-500">{BranchData?.docket?.actDestBranch?.address?.l1}</span>
                                                    <span className="fw-500">{BranchData?.docket?.actDestBranch?.address?.l2}</span>
                                                </div>
                                            </div>
                                            <div className="flex ai-center gap-1">
                                                <PincodeIcon className="text-primary-400 fs-link" />
                                                <span className="fw-500">{BranchData?.docket?.actDestBranch?.address?.pincode}</span>
                                            </div>
                                            <div className="flex ai-center gap-1">
                                                <BranchCallIcon className="text-primary-400 fs-link" />
                                                <span className="fw-500">{BranchData?.docket?.actDestBranch?.pContact}</span>
                                            </div>
                                            <div className="flex ai-center gap-1">
                                                <CityIcon className="text-primary-400 fs-link" />
                                                <div className="">
                                                    <span className="fw-500">{BranchData?.docket?.actDestBranch?.address?.city?.name},</span>
                                                    <span className="fw-500">{BranchData?.docket?.actDestBranch?.address?.city?.state?.name}</span>
                                                </div>
                                            </div>
                                            <div className="flex ai-center gap-1">
                                                <UserInfoIcon className="text-primary-400 fs-link" />
                                                <span className="fw-500">
                                                    {BranchData?.docket?.actDestBranch?.manager?.name?.fName} - {BranchData?.docket?.actDestBranch?.manager?.contact}
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        }
                        footer={
                            <div className='flex jc-end gap-1 ai-center'>
                                {BranchData?.docket?.actDestBranch?.bLocation &&
                                    <Button variant="secondary" action="secondary"
                                        onClick={() => { goToMap() }}
                                    >
                                        Navigate
                                    </Button>
                                }
                                <CloseButton onClick={() => { setBranchAdd(false) }} />
                            </div>
                        }
                    />

                    <Dialog
                        state={paymentInfo}
                        size="small"
                        onClose={() => {
                            setPaymentInfo(false);
                        }}
                        header="Payment Details"
                        body={
                            <div>
                                {!BranchData?.doorDeliveryDocket.doorDelDocketNumber && (
                                    <div className="grid gap-8">
                                        <InfoPill className="flex jc-between w-100 fs-link fw-600">
                                            <p>Net Cost</p>
                                            <p>{TotalAmount}</p>
                                        </InfoPill>
                                        <InfoPill className="flex jc-between w-100 fs-link fw-600">
                                            <p>Branch Delivery</p>
                                            <p>
                                                {BranchData?.docket?.doorDelivery ? 0 : branchDeliveryCharge}
                                            </p>
                                        </InfoPill>
                                        <InfoPill className="flex jc-between w-100 fs-link fw-600">
                                            <p>Demurrage</p>
                                            <p>{demurrage ? demurrage : 0}</p>
                                        </InfoPill>
                                        <InfoPill className="flex jc-between w-100 fs-link fw-600">
                                            <p>Discount</p>
                                            <p>{discount ? discount : 0}</p>
                                        </InfoPill>
                                        <InfoPill className="flex jc-between w-100 fs-link fw-600">
                                            <p>GST Penalty</p>
                                            <p>{gstCustodyPenalty ? gstCustodyPenalty : 0}</p>
                                        </InfoPill>
                                    </div>
                                )}
                            </div>
                        }
                        footer={<CloseButton onClick={() => setPaymentInfo(false)} />}
                    />
                </div>
                {/* Info Section One End */}

                {/*  Fetch Details Section start */}
                {SourceName === "Branch-Authenticated-BranchOrder" &&
                    <>
                        {moreBtns &&
                            <PaperCard className="flex gap-1 flex-wrap mt-1 w-100">
                                <div className="m-w-100">
                                    {BranchData?.docket && (
                                        <Button className="m-w-100" onClick={() => { handleViewDocketBtn() }}> Fetch Docket </Button>
                                    )}
                                </div>
                                {!missingParcel &&
                                    <div className="m-w-100">
                                        {BranchData?.docket &&
                                            (BranchData?.docket.entity === BranchData?.docket.destBranch._id ||
                                                BranchData?.docket.entity === BranchData?.docket.originBranch._id ||
                                                BranchData?.docket.entity === BranchData?.docket.hub) &&
                                            !BranchData?.docket.settled &&
                                            BranchData?.docket?.packages[0].status !== "D" &&
                                            BranchData?.docket?.packages[0].status !== "CR" &&
                                            BranchData?.docket?.packages[0].status !== "CC" &&
                                            BranchData?.docket?.packages[0].status !== "SOLD" && (
                                                <Button
                                                    className="m-w-100"
                                                    onClick={() => {
                                                        setBackToOriginOpen(true);
                                                        handleImagePackages();
                                                    }}
                                                >
                                                    Send Back to Origin
                                                </Button>
                                            )
                                        }
                                        <Dialog
                                            state={backToOriginOpen}
                                            onClose={() => handleBTOClose()}
                                            size="small"
                                            header='Send  back to Origin'
                                            body={
                                                <div>
                                                    <TextField
                                                        placeholder="Back to origin charges"
                                                        className="py-8 px-1 border-white br-4 w-100 my-1"
                                                        value={btoAmount}
                                                        onChange={(e: any) => setbtoAmount(e.target.value)}
                                                        title={`Back to origin charges must be greater than ${BranchData?.docket.gross - 1
                                                            }`}
                                                    />
                                                    <ImageInputPackgeWise
                                                        packages={imagePackages}
                                                        showLoader={newProps.data.showLoader}
                                                        hideLoader={newProps.data.hideLoader}
                                                        reqType="BTO"
                                                        addImagedPackage={addImagedPackage}
                                                    />
                                                </div>

                                            }
                                            footer={
                                                <div className="flex ai-center gap-1">
                                                    <SubmitButton variant="secondary" action="secondary"
                                                        onClick={() => handleSubmitBTO()}
                                                        disabled={btoAmount > BranchData?.docket.gross - 1 ? false : true}
                                                    />
                                                    <CancelButton onClick={() => handleBTOClose()} />
                                                </div>
                                            }
                                        />

                                    </div>
                                }
                                {!missingParcel &&
                                    <div className="m-w-100">
                                        {BranchData?.docket &&
                                            (BranchData?.docket.entity === BranchData?.docket.destBranch._id ||
                                                BranchData?.docket.entity === BranchData?.docket.originBranch._id ||
                                                BranchData?.docket.entity === BranchData?.docket.hub) &&
                                            !BranchData?.docket.settled &&
                                            BranchData?.packages[0].status !== "D" &&
                                            BranchData?.packages[0].status !== "CR" &&
                                            BranchData?.packages[0].status !== "CC" &&
                                            BranchData?.packages[0].status !== "SOLD" && (
                                                <Button
                                                    className="m-w-100"
                                                    onClick={() => {
                                                        setNewDestOpen(true);
                                                        handleImagePackages();
                                                    }}
                                                >
                                                    Send to New destination
                                                </Button>
                                            )
                                        }
                                        <Dialog
                                            state={newDestOpen}
                                            onClose={() => handleNDClose()}
                                            size="small"
                                            header='Send to New destination'
                                            body={
                                                <div>
                                                    <Select
                                                        label="New Destinaiton"
                                                        placeholder="Select New Destinaiton"
                                                        options={newDestOptions}
                                                        value={selectedND}
                                                        onChange={(e: any) => setSelectedND(e)}
                                                        styles={{
                                                            menu: (base) => ({ ...base, zIndex: 10000000000000 }),  // High z-index for dropdown
                                                            menuList: (base) => ({ ...base, zIndex: 10000000000000 }),  // High z-index for dropdown
                                                          }}
                                                          // menuPortalTarget={document.body}  // Render outside dialog
                                                          menuPosition="fixed"
                                                    />
                                                    <br />
                                                    <input
                                                        placeholder="New Destination Charges"
                                                        className="w-100 py-8 px-1 br-4 border-white mb-1"
                                                        value={NDAmount}
                                                        onChange={(e: any) => setNDAmount(e.target.value)}
                                                        // placeholder={`${BranchData?.docket.gross}`}
                                                        style={{ zIndex: 0 }}
                                                    />
                                                    <br />
                                                    <ImageInputPackgeWise
                                                        packages={imagePackages}
                                                        showLoader={newProps.data.showLoader}
                                                        hideLoader={newProps.data.hideLoader}
                                                        reqType="ND"
                                                        addImagedPackage={addImagedPackage}
                                                    />
                                                </div>

                                            }
                                            footer={
                                                <div className="flex gap-1 ai-center">
                                                    <SubmitButton variant="secondary" action="secondary" onClick={() => handleSubmitND()} />
                                                    <CancelButton onClick={() => handleNDClose()} />
                                                </div>
                                            }
                                        />
                                    </div>
                                }
                                <div className="m-w-100">
                                    <Complaint
                                        chat={feedbackResponse}
                                        getGuestUpdatedData={getGuestUpdatedData}
                                        docketID={BranchData?.docket?._id}
                                        docketNumber={BranchData?.docket?.docketNumber}
                                        orderArrayOption={orderArrayOption}
                                        branchOptions={branchOptions}
                                        userOptions={userOptions}
                                    />
                                </div>
                                {!missingParcel &&
                                    <div className="m-w-100">
                                        {BranchData?.docket &&
                                            (BranchData?.docket.entity === BranchData?.docket.destBranch._id ||
                                                BranchData?.docket.entity === BranchData?.docket.originBranch._id ||
                                                BranchData?.docket.entity === BranchData?.docket.hub) &&
                                            !BranchData?.docket.settled &&
                                            BranchData?.packages[0].status !== "D" &&
                                            BranchData?.packages[0].status !== "CR" &&
                                            BranchData?.packages[0].status !== "CC" &&
                                            BranchData?.packages[0].status !== "SOLD" && (
                                                <Button
                                                    className="m-w-100"
                                                    disabled={illegalIssue?.issueType === 'IllegalGoodsFound' ? true : false}
                                                    onClick={() => {
                                                        setLiggleGoodsDialog(true);
                                                        handleImagePackages();
                                                    }}
                                                    >
                                                    Illegal Goods
                                                </Button>
                                            )
                                        }
                                    </div>
                                }
                                {!missingParcel &&
                                    <div className="m-w-100">
                                        {BranchData?.docket &&
                                            (BranchData?.docket.entity === BranchData?.docket.destBranch._id ||
                                                BranchData?.docket.entity === BranchData?.docket.originBranch._id ||
                                                BranchData?.docket.entity === BranchData?.docket.hub) &&
                                                !BranchData?.docket.settled &&
                                                BranchData?.packages[0].status !== "D" &&
                                                BranchData?.packages[0].status !== "CR" &&
                                                BranchData?.packages[0].status !== "CC" &&
                                                BranchData?.packages[0].status !== "SOLD" && (
                                                    <Button
                                                    className="m-w-100" 
                                                    disabled={ewayBillIssue ? ewayBillIssue : false}                                                    
                                                    onClick={() => {
                                                        setEwayBillMissiingDialog(true);
                                                        handleImagePackages();
                                                    }}
                                                >
                                                    Eway Bill Missing
                                                </Button>
                                            )
                                        }
                                        <Dialog
                                            state={ewayBillMissiingDialog || liggleGoodsDialog}
                                            onClose={() => {setEwayBillMissiingDialog(false);setLiggleGoodsDialog(false)}}
                                            size="small"
                                            header={liggleGoodsDialog ? 'Illegal Goods Founds' : 'Eway Bill Details Missing' }
                                            body=
                                                {
                                                    ewayBillMissiingDialog ? 

                                                        <div>
                                                    Are you sure you want to mark this docket as Eway bill Details Missing?
                                                    </div> : liggleGoodsDialog ? <div>
                                                        Are you sure you want to mark this docket as illegal Goods Found?
                                                </div> :''
                                            }
                                            footer={
                                                <div className="flex gap-1 ai-center">
                                                    <SubmitButton variant="secondary" action="secondary" onClick={() => {
                                                        if(liggleGoodsDialog) handleSubmitIllegalGood('illegalGoods');
                                                        else if(ewayBillMissiingDialog) handleSubmitIllegalGood('ewayBillMissing');
                                                        }} />
                                                    <CancelButton onClick={() => {setEwayBillMissiingDialog(false);setLiggleGoodsDialog(false)}} />
                                                </div>
                                            }
                                        />
                                        
                                    </div>
                                }
                                
                                {notReceivedDisabled && BranchData?.docket?.originBranch?._id === newProps?.data?.userDetails?.opBranch?._id &&
                                    <div className="m-w-100">
                                        <Button
                                            onClick={() => setOpenActionForRecieved(true)}
                                            className="m-w-100"
                                        >
                                            Recieved
                                        </Button>
                                    </div>
                                }
                            </PaperCard>
                        }
                        <Dialog
                            state={Boolean(openActionForRecieved)}
                            onClose={() => handleDialogClose()}
                            size="small"
                            header='Open Branch'
                            body={
                                <div>
                                    <Select
                                        value={packageOrderForRecieved}
                                        options={orderArrayOption}
                                        onChange={(e: any) => setPackageOrderForRecieved(e)}
                                        placeholder="Select Packages"
                                        isMulti={true}
                                        styles={{
                                            menu: (base) => ({ ...base, zIndex: 10000000000000 }),  // High z-index for dropdown
                                            menuList: (base) => ({ ...base, zIndex: 10000000000000 }),  // High z-index for dropdown
                                          }}
                                          // menuPortalTarget={document.body}  // Render outside dialog
                                          menuPosition="fixed"
                                    />
                                    <ImageInput
                                        name="Upload Image"
                                        label="Image Upload"
                                        Icon={(props: any) => (
                                            <i {...props} className={"fas fa-id-card file_icon"} />
                                        )}
                                        onChange={setImage}
                                        value={Image}
                                        extras={<div></div>}
                                    />
                                    <br />
                                    <TextField
                                        label="Remarks"
                                        value={reason}
                                        onChange={(e: any) => setReason(e.target.value)}
                                    />
                                </div>
                            }
                            footer={
                                <div>
                                    <SubmitButton
                                        variant="secondary" action="secondary"
                                        onClick={() => handleReceived()}
                                    />
                                    <CancelButton onClick={() => handleDialogClose()} />
                                </div>
                            }
                        />
                    </>
                }
                {/*  Fetch Details Section End */}

                {SourceName === "Branch-Authenticated-BranchOrder" &&
                    <PaperCard className="my-1">
                        <div className='flex jc-between m-one-column gap-1 ai-center | m-flex-col m-jc-start m-ai-start'>
                            <div className='flex gap-4 ai-center fs-link'><DebugDisconnectIcon className="text-primary-400 fs-list-item-heading" />{" "}{BranchData?.docket?.crossingBranch ? BranchData?.docket?.crossingBranch?.branchName : "No Crossing Branch"}{" "}</div>
                            <div className='flex gap-4 ai-center fs-link'><OrderPlayLineIcon className="text-primary-400 fs-list-item-heading" />
                                {fleetOrderNumbers && fleetOrderNumbers?.length === 0 ?
                                    "No Fleet Order No Generated"
                                    : fleetOrderNumbers.length > 0 ?
                                    <div>{fleetOrderNumbers[0]} </div>
                                         : fleetOrderNumbers.length > 1 ? 
                                          <div onClick={() => { setOpenFleetOrder(true) }}>{fleetOrderNumbers[0]} <span className="infoIcon">... More</span> </div>
                                        : ""}
                            </div>
                            <div className='flex gap-4 ai-center fs-link'><TagsIcon className="text-primary-400 fs-list-item-heading" />
                                {BranchData?.docket &&
                                    (BranchData?.docket?.destChng ||
                                        BranchData?.docket?.cancelRequestInfo ||
                                        BranchData?.docket?.demurrageReqInfo ||
                                        BranchData?.docket?.docketNumber?.discountInfo || illegalIssue?.issueType || ewayBillIssue?.issueType) && (
                                        <div className='flex flex-wrap gap-4 m-flex-col fit-content'>
                                            {BranchData?.docket?.destChng && BranchData?.docket?.destChng.reqType === "ND" && (
                                                <StatusHeader
                                                    title="New Destination"
                                                    info={BranchData?.docket?.destChng}
                                                />
                                            )}
                                            {BranchData?.docket?.destChng && BranchData?.docket?.destChng.reqType === "BTO" && (
                                                <StatusHeader
                                                    title="Back To Origin"
                                                    info={BranchData?.docket?.destChng}
                                                />
                                            )}
                                            {BranchData?.docket?.demurrageReqInfo && (
                                                <StatusHeader
                                                    title="Demurrage"
                                                    info={BranchData?.docket?.demurrageReqInfo}
                                                />
                                            )}
                                            {BranchData?.docket?.discountInfo && (
                                                <StatusHeader
                                                    title="Discount"
                                                    info={BranchData?.docket?.discountInfo}
                                                />
                                            )}
                                            {BranchData?.docket?.cancelRequestInfo && (
                                                <StatusHeader
                                                    title="Cancel Booking"
                                                    info={BranchData?.docket?.cancelRequestInfo}
                                                />
                                            )}
                                            {illegalIssue?.issueType && (
                                                <StatusHeader
                                                    title="illegal Goods Founds"
                                                    info={illegalIssue}
                                                />
                                            )}
                                            {ewayBillIssue?.issueType && (
                                                <StatusHeader
                                                    title="Eway Bill Details Missing"
                                                    info={ewayBillIssue}
                                                />
                                            )}
                                        </div>
                                    )}
                                {/* <InfoIcon className="infoIcon"></InfoIcon> */}
                            </div>
                        </div>

                        <Dialog
                            state={Boolean(openFleetOrder)}
                            size='regular'
                            onClose={() => { setOpenFleetOrder(false) }}
                            header='Fleet Order List'
                            body={
                                <div className="popupForComplaint2">
                                    {fleetOrderNumbers && fleetOrderNumbers.map((ele: any) => {
                                        return (
                                            <h4>{ele}</h4>
                                        )
                                    })}
                                </div>
                            }
                            footer={
                                <CloseButton
                                    onClick={() => { setOpenFleetOrder(false) }}
                                />
                            }
                        />
                    </PaperCard>
                }

                {BranchData?.packages && BranchData?.packages?.length > 0 && ((BranchData?.docket?.deliveredAt === undefined && !BranchData?.docket?.isCancelRequired && !BranchData?.docket?.isBackToHub) && BranchData?.packages[0]?.expectedReachTime) ?
                    <PaperCard className='my-1 flex-center text-center'>
                        {getFinalDeliveryTime(SourceName)}
                    </PaperCard>
                    : null}

                {SourceName === "Customer-Authenticated-BranchOrder" && BranchData?.docket?.deliveredAt === undefined && otpForDelivery &&
                    <InfoPill className='fs-600 p-1'>
                        Otp For Delivery :
                        {otpForDelivery && otpForDelivery?.otp &&
                            new Date(otpForDelivery?.expireAt).getTime() > new Date().getTime() ? otpForDelivery?.otp : `Request for another otp by the provider`}

                    </InfoPill>
                }

                {BranchData?.doorDeliveryDocket && BranchData?.driver && BranchData?.driver?.userProfile?.image &&
                    SourceName !== "Customer-NotAuthenticated-BranchOrder" && BranchData?.docket?.deliveredAt === undefined ?
                    <PaperCard className="mt-1">
                        {BranchData?.doorDeliveryDocket && BranchData?.driver?.userProfile?.image &&
                            <div className="flex jc-between ai-center gap-1 flex-wrap">
                                <OnlyImage className='fs-h5 text-primary-400' objectID={BranchData?.driver?.userProfile?.image} />
                                <p className="flex gap-4 ai-center">
                                    <span> <UserIcon className="text-primary-400 fs-h5 pointer" /> </span>
                                    <span> {`${BranchData?.driver?.name?.fName} ${BranchData?.driver?.name?.lName}`}  </span>
                                </p>
                                <p className="flex gap-4 ai-center">
                                    <span> <CallIcon className="text-primary-400 fs-h5 pointer" /> </span>
                                    <span> {BranchData?.doorDeliveryDocket && BranchData?.doorDeliveryDocket?.driverContact} </span>
                                </p>
                                <p className="flex gap-4 ai-center">
                                    <span> <MovingTruckIcon className="text-primary-400 fs-h5 pointer" /> </span>
                                    <span> {BranchData?.doorDeliveryDocket && BranchData?.doorDeliveryDocket?.regNumber} </span>
                                </p>
                            </div>
                        }
                    </PaperCard>
                    :
                    <></>
                }

                <>
                    {BranchData?.docket?.deliveredAt === undefined ?
                        BranchData?.doorDeliveryDocket?.fleetOrder &&
                        BranchData?.doorDeliveryDocket?.Route?.length > 0 &&
                        <PaperCard className="grid gap-4 text-center" label='Door Delivery Fleet Order'>
                            {/* <div>Door Delivery Fleet Order</div> */}
                            <div>
                                <div style={{ flex: "1", }} className='doorDeliveryLocationContainer'>
                                    <div className='doordeliveryView'>
                                        {newProps?.data?.customerTracking?.route?.map((e: any, i: any) => {
                                            return (
                                                <Accordion>
                                                    <AccordionSummary>
                                                        <div style={{ width: "100%" }}>
                                                            <div style={{ display: "flex", alignItems: 'center', gap: "5px", fontWeight: "bold" }}>
                                                                <div style={{ width: "7.5%", height: "20px", backgroundColor: "var(--clr-primary-400)", color: "var(--clr-white-900)", textAlign: "center", borderRadius: "50%", fontWeight: "bold" }}>{i + 1}</div>
                                                                <div style={{ width: "85%", height: "fit-content", textAlign: "left", fontWeight: "bold" }}>{e?.placeId[0]?.name}</div>
                                                                <div style={{ width: "7.5%", height: "20px", textAlign: "center", fontWeight: "bold" }} >
                                                                    <InfoIcon style={{ fontSize: "large", color: "var(--clr-primary-500)", cursor: "pointer" }} />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: "5px" }}>
                                                                <div><span><i className="fa fa-truck" style={{ marginRight: 5, color: 'var(--clr-primary-400)' }} ></i></span>{"  "}{meterTOkmChange(e?.distance)} km away from {i == 0 ? "Driver" : "Previous Location"}
                                                                    <div><span><i className="fa fa-clock" style={{ marginRight: 5, color: 'var(--clr-primary-400)' }} ></i></span> {" "}
                                                                        {secondTominConvert(e?.duration, i)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div><div style={{ margin: "5px 0 0 25px" }}><div>Km Total Trip</div></div></div>
                                                            {orderDetails?.packages && orderDetails?.packages.length == i + 1 ? <div style={{ margin: "5px 0 0 25px" }}><div>{orderDetails?.totalDistance / 1000}Km  Total Trip and {toHoursAndMinutes(orderDetails?.totalDuration)}  Time</div></div>
                                                                : <div></div>}

                                                        </div>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}><div><LocationIcon style={{ color: "green", fontSize: "19px" }} /></div>{e?.placeId[0]?.placeValue}</div>
                                                    </AccordionDetails>
                                                </Accordion>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className='w-100'>
                                    {<CustomerTrackMapView isStarted={doorDeliveryDocketData.isOrderStarted} />}

                                </div>
                            </div>
                        </PaperCard>
                        :
                        <div className="paper bg-success-50 fw-900 text-success-900 flex-col-center fs-tagline text-center mt-1">
                            <MdVerified /> Your Order is Delivered Successfully
                            <div className="flex-center gap-1 fs-h6 mt-1 text-primary-400">{`Your Order is Delivered to : ${BranchData?.docket?.deliveredTo}`}</div>
                        </div>
                    }
                </>

                {/* {!BranchData?.docket?.cancelRequestInfo && BranchData?.docket?.deliveredAt === undefined && */}
                <div className="my-1">
                    {BranchData?.docket &&
                        <PaperCard>
                            {SourceName === "Branch-Authenticated-BranchOrder" &&
                                <Switch
                                    label="Show Packages"
                                    id='sho-packages-switch'
                                    checked={showPackages}
                                    onChange={() => setShowPackages(!showPackages)}
                                />
                            }
                            {/* {Object.keys(uniqueLogs).map((key, index) => {
                                let item = uniqueLogs[key];
                                let arr = item.split(";");
                                arr.splice(arr.length - 1, 1);
                                let totalPackagesArr: any = [];
                                let finalAns = Array.from(recordsMap).map(([_, value]: any) => {
                                    let asnFind: any = [];
                                    value?.forEach((i: any) => {
                                        if (packageMap[key].includes(i.order)) {
                                            asnFind.push(i);
                                        }
                                    });
                                    totalPackagesArr.push(asnFind);
                                    return asnFind;
                                });
                                return (
                                    <AccordionGroup className="overflow-auto">
                                        {finalAns.map((f: any, i) => {
                                            const lastStatus = arr[arr?.length - 1];
                                            const statusSplit = lastStatus?.split('@');
                                            // const ParcelReachedLastBranch: any = statusSplit[1];
                                            let result = '';
                                            let progressValue = 0; // Percentage for progress bar
                                            const ParcelReachedLastBranch: any = statusSplit[1];
                                            switch (statusSplit[0]) {
                                                case 'loading':
                                                    result = 'In transit';
                                                    progressValue = 40;
                                                    break;
                                                case 'payment':
                                                case 'unloading':
                                                    result = 'Waiting for Delivery';
                                                    progressValue = 60;
                                                    break;
                                                case 'booking':
                                                    result = 'Booked';
                                                    progressValue = 20;
                                                    break;
                                                case 'delivery':
                                                    result = 'Delivered';
                                                    progressValue = 100;
                                                    break;
                                                case 'cancel':
                                                    result = 'Canceled';
                                                    progressValue = 0;
                                                    break;
                                                case 'cancelPending':
                                                    result = 'Waiting for cancellation';
                                                    progressValue = 0;
                                                    break;
                                                case 'review':
                                                    result = 'Review';
                                                    progressValue = 100;
                                                    break;
                                                case 'missing':
                                                    result = 'Missing';
                                                    progressValue = 0;
                                                    break;
                                                case 'missingRequest':
                                                    result = 'Missing Request';
                                                    progressValue = 0;
                                                    break;
                                                default:
                                                    result = 'Booked';
                                                    progressValue = 20;
                                            }
                                            console.log(finalAns, 'finalAns finalAns finalAns')
                                            if (f?.length > 0) {
                                                return (
                                                    <Accordion key={i} className="overflow-auto">
                                                        <AccordionSummary>
                                                            <div className="flex gap-2 fs-link ai-center m-flex-col m-ai-start m-gap-4">
                                                                <p className="flex gap-4 ai-center" style={{ minWidth: '5rem', maxWidth: '5rem' }}><PackageIcon className="text-primary-400" />{f.length}</p>
                                                                <p className="flex gap-4 ai-center" style={{ minWidth: mobileView ? 'fit-content' : '15rem', maxWidth: '15rem', wordWrap: 'break-word' }}><DropboxIcon className="text-primary-400" />{f[0]?.materialType && f[0]?.materialType.name}</p>
                                                                <p className="flex gap-4 ai-center" style={{ minWidth: mobileView ? 'fit-content' : '17rem', maxWidth: '17rem', wordWrap: 'break-word' }}><PackageIcon className="text-primary-400" />{f[0]?.packingType && f[0]?.packingType.name}</p>
                                                                <p className="flex gap-4 ai-center" style={{ minWidth: mobileView ? 'fit-content' : '17rem', maxWidth: '17rem', wordWrap: 'break-word' }}><TimelineIcon className="text-primary-400" />{ParcelReachedLastBranch}</p>
                                                                <div className="flex gap-4 ai-center">
                                                                    <div style={{ width: 50, height: 50 }}>
                                                                        <CircularProgressbar
                                                                            value={progressValue}
                                                                            text={`${progressValue}%`}
                                                                            styles={buildStyles({
                                                                                textSize: '28px',
                                                                                pathColor: progressValue === 100 ? '#4caf50' : '#2196f3',
                                                                                textColor: '#000',
                                                                                trailColor: '#dcdcdc',
                                                                            })}
                                                                        />
                                                                    </div>
                                                                    <p
                                                                        style={{
                                                                            color: progressValue === 100 ? '#4caf50' : '#2196f3',
                                                                            fontWeight: progressValue === 100 ? '600' : '',
                                                                        }}
                                                                    >
                                                                        {result}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            {showPackages &&
                                                                <div className="packageBtnsForComplaints">
                                                                    {f && f?.map((i: any, index: any) => {
                                                                        return (<div
                                                                            key={index}
                                                                            className={`${classNames(
                                                                                {
                                                                                    inner_selected: allPackages.includes(i._id),
                                                                                },
                                                                                {
                                                                                    inner_package: !allPackages.includes(i._id),
                                                                                }
                                                                            )}`}
                                                                            style={{
                                                                                margin: "0",
                                                                                marginRight: "1rem"
                                                                            }}
                                                                            onClick={() => {
                                                                                checkSelected(i._id);
                                                                                // checkboxSelected(props.data.packages, props.index);
                                                                            }}
                                                                        >
                                                                            <div style={{ display: "inline" }}>
                                                                                <Dot
                                                                                    root={
                                                                                        allPackages.includes(i._id)
                                                                                            ? "verified_dot"
                                                                                            : "unverified_dot"
                                                                                    }
                                                                                />
                                                                            </div>

                                                                            <div style={{ display: "inline" }}>
                                                                                {i.order}
                                                                            </div>
                                                                        </div>)
                                                                    })}
                                                                </div>
                                                            }
                                                            <SingleStatus
                                                                i={i}
                                                                totalPkg={f.length}
                                                                allowedOrders={packageMap[key]}
                                                                setAllPackage={setAllPackages}
                                                                materialType={f[0]?.materialType}
                                                                packingType={f[0]?.packingType}
                                                                finalAns={finalAns}
                                                                BranchDetails={BranchData}
                                                                orderNum={f.map((c: any) => c.order)}
                                                                showPackages={showPackages}
                                                                allPackages={allPackages}
                                                                statusArr={arr}
                                                                PkgList={f}
                                                                recordsMap={recordsMap}
                                                                index={index}
                                                                EachlogsArr={BranchData.packages[index].logs}
                                                            />
                                                        </AccordionDetails>
                                                    </Accordion>
                                                );
                                            }
                                        })}
                                    </AccordionGroup>
                                );
                            })} */}
                            <AccordionGroup className="overflow-auto grid gap-1">
                                {Object.keys(uniqueLogs).map((key, index) => {
                                    let item = uniqueLogs[key];
                                    let arr = item.split(";");
                                    arr.splice(arr.length - 1, 1);
                                    let totalPackagesArr: any = [];
                                    let finalAns = Array.from(recordsMap).map(([_, value]: any) => {
                                        let asnFind: any = [];
                                        value?.forEach((i: any) => {
                                            if (packageMap[key].includes(i.order)) {
                                                asnFind.push(i);
                                            }
                                        });
                                        totalPackagesArr.push(asnFind);
                                        return asnFind;
                                    });
                                    console.log(finalAns, 'finalAns finalAns finalAns finalAns');
                                    if (finalAns.length > 1) {
                                        return (
                                            <AccordionGroup className="overflow-auto grid gap-4">
                                                {finalAns.map((f: any, i) => {
                                                    const lastStatus = arr[arr?.length - 1];
                                                    const statusSplit = lastStatus?.split("@");
                                                    let result = "";
                                                    let progressValue = 0; // Percentage for progress bar
                                                    const ParcelReachedLastBranch: any = statusSplit[1];
                                                    switch (statusSplit[0]) {
                                                        case "loading":
                                                            result = "In transit";
                                                            progressValue = 40;
                                                            break;
                                                        case "payment":
                                                        case "unloading":
                                                            result = "Waiting for Delivery";
                                                            progressValue = 60;
                                                            break;
                                                        case "booking":
                                                            result = "Booked";
                                                            progressValue = 20;
                                                            break;
                                                        case "delivery":
                                                            result = "Delivered";
                                                            progressValue = 100;
                                                            break;
                                                        case "cancel":
                                                            result = "Canceled";
                                                            progressValue = 0;
                                                            break;
                                                        case "cancelPending":
                                                            result = "Waiting for cancellation";
                                                            progressValue = 0;
                                                            break;
                                                        case "review":
                                                            result = "Review";
                                                            progressValue = 100;
                                                            break;
                                                        case "missing":
                                                            result = "Missing";
                                                            progressValue = 0;
                                                            break;
                                                        case "missingRequest":
                                                            result = "Missing Request";
                                                            progressValue = 0;
                                                            break;
                                                        default:
                                                            result = "Booked";
                                                            progressValue = 20;
                                                    }

                                                    if (f?.length > 0) {
                                                        return (
                                                            <Accordion key={i} className="overflow-auto">
                                                                
                                                                <AccordionSummary>
                                                                    <div className="flex gap-2 fs-link ai-center m-flex-col m-ai-start m-gap-4">
                                                                        <p className="flex gap-4 ai-center" style={{ minWidth: "5rem", maxWidth: "5rem" }}>
                                                                            <PackageIcon className="text-primary-400" />
                                                                            {f.length}
                                                                        </p>
                                                                        <p className="flex gap-4 ai-center" style={{ minWidth: mobileView ? "fit-content" : "15rem", maxWidth: "15rem", wordWrap: "break-word" }}>
                                                                            <DropboxIcon className="text-primary-400" />
                                                                            {f[0]?.materialType && f[0]?.materialType.name}
                                                                        </p>
                                                                        <p className="flex gap-4 ai-center" style={{ minWidth: mobileView ? "fit-content" : "17rem", maxWidth: "17rem", wordWrap: "break-word" }}>
                                                                            <PackageIcon className="text-primary-400" />
                                                                            {f[0]?.packingType && f[0]?.packingType.name}
                                                                        </p>
                                                                        <p className="flex gap-4 ai-center" style={{ minWidth: mobileView ? "fit-content" : "17rem", maxWidth: "17rem", wordWrap: "break-word" }}>
                                                                            <TimelineIcon className="text-primary-400" />
                                                                            {ParcelReachedLastBranch}
                                                                        </p>
                                                                        <div className="flex gap-4 ai-center">
                                                                            <div style={{ width: 50, height: 50 }}>
                                                                                <CircularProgressbar
                                                                                    value={progressValue}
                                                                                    text={`${progressValue}%`}
                                                                                    styles={buildStyles({
                                                                                        textSize: "28px",
                                                                                        pathColor: progressValue === 100 ? "#4caf50" : "#2196f3",
                                                                                        textColor: "#000",
                                                                                        trailColor: "#dcdcdc",
                                                                                    })}
                                                                                />
                                                                            </div>
                                                                            <p
                                                                                style={{
                                                                                    color: progressValue === 100 ? "#4caf50" : "#2196f3",
                                                                                    fontWeight: progressValue === 100 ? "600" : "",
                                                                                }}
                                                                            >
                                                                                {result}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </AccordionSummary>
                                                                {showPackages && (
                                                                        <div className="flex gap-4 p-1">
                                                                            {f?.map((i: any, idx: any) => (
                                                                                <div
                                                                                    key={idx}
                                                                                    className={`${classNames(
                                                                                        { inner_selected: allPackages.includes(i._id) },
                                                                                        { inner_package: !allPackages.includes(i._id) }
                                                                                    )}`}
                                                                                    style={{ margin: "0", marginRight: "1rem" }}
                                                                                    onClick={() => checkSelected(i._id)}
                                                                                >
                                                                                    <div style={{ display: "inline" }}>
                                                                                        <Dot
                                                                                            root={
                                                                                                allPackages.includes(i._id)
                                                                                                    ? "verified_dot"
                                                                                                    : "unverified_dot"
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                    <div style={{ display: "inline" }}>{i.order}</div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    )}
                                                                <AccordionDetails>
                                                                    
                                                                    <SingleStatus
                                                                        i={i}
                                                                        totalPkg={f.length}
                                                                        allowedOrders={packageMap[key]}
                                                                        setAllPackage={setAllPackages}
                                                                        materialType={f[0]?.materialType}
                                                                        packingType={f[0]?.packingType}
                                                                        finalAns={finalAns}
                                                                        BranchDetails={BranchData}
                                                                        orderNum={f.map((c: any) => c.order)}
                                                                        showPackages={showPackages}
                                                                        allPackages={allPackages}
                                                                        statusArr={arr}
                                                                        PkgList={f}
                                                                        recordsMap={recordsMap}
                                                                        index={index}
                                                                        EachlogsArr={BranchData.packages[index].logs}
                                                                    />
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        );
                                                    }
                                                })}
                                            </AccordionGroup>
                                        );
                                    } else {
                                        return finalAns.map((f: any, i) => {
                                            const lastStatus = arr[arr?.length - 1];
                                            const statusSplit = lastStatus?.split("@");
                                            let result = "";
                                            let progressValue = 0; // Percentage for progress bar
                                            const ParcelReachedLastBranch: any = statusSplit[1];
                                            switch (statusSplit[0]) {
                                                case "loading":
                                                    result = "In transit";
                                                    progressValue = 40;
                                                    break;
                                                case "payment":
                                                case "unloading":
                                                    result = "Waiting for Delivery";
                                                    progressValue = 60;
                                                    break;
                                                case "booking":
                                                    result = "Booked";
                                                    progressValue = 20;
                                                    break;
                                                case "delivery":
                                                    result = "Delivered";
                                                    progressValue = 100;
                                                    break;
                                                case "cancel":
                                                    result = "Canceled";
                                                    progressValue = 0;
                                                    break;
                                                case "cancelPending":
                                                    result = "Waiting for cancellation";
                                                    progressValue = 0;
                                                    break;
                                                case "review":
                                                    result = "Review";
                                                    progressValue = 100;
                                                    break;
                                                case "missing":
                                                    result = "Missing";
                                                    progressValue = 0;
                                                    break;
                                                case "missingRequest":
                                                    result = "Missing Request";
                                                    progressValue = 0;
                                                    break;
                                                default:
                                                    result = "Booked";
                                                    progressValue = 20;
                                            }

                                            if (f?.length > 0) {
                                                return (
                                                    <Accordion key={i} className="overflow-auto">
                                                        <AccordionSummary>
                                                            <div className="flex gap-2 fs-link ai-center m-flex-col m-ai-start m-gap-4">
                                                                <p className="flex gap-4 ai-center" style={{ minWidth: "5rem", maxWidth: "5rem" }}>
                                                                    <PackageIcon className="text-primary-400" />
                                                                    {f.length}
                                                                </p>
                                                                <p className="flex gap-4 ai-center" style={{ minWidth: mobileView ? "fit-content" : "15rem", maxWidth: "15rem", wordWrap: "break-word" }}>
                                                                    <DropboxIcon className="text-primary-400" />
                                                                    {f[0]?.materialType && f[0]?.materialType.name}
                                                                </p>
                                                                <p className="flex gap-4 ai-center" style={{ minWidth: mobileView ? "fit-content" : "17rem", maxWidth: "17rem", wordWrap: "break-word" }}>
                                                                    <PackageIcon className="text-primary-400" />
                                                                    {f[0]?.packingType && f[0]?.packingType.name}
                                                                </p>
                                                                <p className="flex gap-4 ai-center" style={{ minWidth: mobileView ? "fit-content" : "17rem", maxWidth: "17rem", wordWrap: "break-word" }}>
                                                                    <TimelineIcon className="text-primary-400" />
                                                                    {ParcelReachedLastBranch}
                                                                </p>
                                                                <div className="flex gap-4 ai-center">
                                                                    <div style={{ width: 50, height: 50 }}>
                                                                        <CircularProgressbar
                                                                            value={progressValue}
                                                                            text={`${progressValue}%`}
                                                                            styles={buildStyles({
                                                                                textSize: "28px",
                                                                                pathColor: progressValue === 100 ? "#4caf50" : "#2196f3",
                                                                                textColor: "#000",
                                                                                trailColor: "#dcdcdc",
                                                                            })}
                                                                        />
                                                                    </div>
                                                                    <p
                                                                        style={{
                                                                            color: progressValue === 100 ? "#4caf50" : "#2196f3",
                                                                            fontWeight: progressValue === 100 ? "600" : "",
                                                                        }}
                                                                    >
                                                                        {result}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </AccordionSummary>
                                                        {showPackages && (
                                                                <div className="flex gap-4 p-1">
                                                                    {f?.map((i: any, idx: any) => (
                                                                        <div
                                                                            key={idx}
                                                                            className={`${classNames(
                                                                                { inner_selected: allPackages.includes(i._id) },
                                                                                { inner_package: !allPackages.includes(i._id) }
                                                                            )}`}
                                                                            style={{ margin: "0", marginRight: "1rem" }}
                                                                            onClick={() => checkSelected(i._id)}
                                                                        >
                                                                            <div style={{ display: "inline" }}>
                                                                                <Dot
                                                                                    root={
                                                                                        allPackages.includes(i._id)
                                                                                            ? "verified_dot"
                                                                                            : "unverified_dot"
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            <div style={{ display: "inline" }}>{i.order}</div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        <AccordionDetails>
                                                            <SingleStatus
                                                                i={i}
                                                                totalPkg={f.length}
                                                                allowedOrders={packageMap[key]}
                                                                setAllPackage={setAllPackages}
                                                                materialType={f[0]?.materialType}
                                                                packingType={f[0]?.packingType}
                                                                finalAns={finalAns}
                                                                BranchDetails={BranchData}
                                                                orderNum={f.map((c: any) => c.order)}
                                                                showPackages={showPackages}
                                                                allPackages={allPackages}
                                                                statusArr={arr}
                                                                PkgList={f}
                                                                recordsMap={recordsMap}
                                                                index={index}
                                                                EachlogsArr={BranchData.packages[index].logs}
                                                            />
                                                        </AccordionDetails>
                                                    </Accordion>
                                                );
                                            }
                                        });
                                    }
                                })}
                            </AccordionGroup>
                        </PaperCard>
                    }
                </div>  
                {/* } */}

                {!BranchData?.docket?.cancelRequestInfo &&
                    <>
                        {SourceName === "Branch-Authenticated-BranchOrder" || SourceName === "Customer-Authenticated-BranchOrder" ?
                            <PaperCard>
                                <Button variant="secondary" action="secondary" style={mobileView ? { width: "100%" } : {}} onClick={() => { setInState(BranchData), setOpenDoorDelDialg(true) }}>
                                    {BranchData?.doorDeliveryDocket.doorDelDocketNumber ? `Door Delivery` : `Book Door Delivery`}
                                </Button>
                                <div className='flex-center flex-wrap gap-1'>
                                    {/* <p className={`${BranchData?.doorDeliveryDocket.doorDelDocketNumber ? 'doorDeliveryTextWithout' : `doorDeliveryText`} bg-secondary-50 text-secondary-900 border-white cursor-pointer p-1 br-4 fw-500 fs-link m-mx-auto`}>   </p> */}
                                    {BranchData?.doorDeliveryDocket.doorDelDocketNumber &&
                                        <div className="flex ai-center jc-between mt-1 w-100 | m-flex-col m-text-left m-ai-start m-gap-4">
                                            <p className='flex ai-center gap-4 fs-link'>
                                                <CalendarIcon className="text-primary-400 fs-h5" />
                                                {BranchData?.doorDeliveryDocket.doorDelDocketNumber ? BranchData?.doorDeliveryDocket.doorDelDocketNumber : "-"}
                                            </p>
                                            <p className='flex ai-center gap-4 fs-link'>
                                                <HoursIcon className="text-primary-400 fs-h5" />
                                                {BranchData?.doorDeliveryDocket.createdAt ? (`${formatDate(BranchData?.doorDeliveryDocket.createdAt)} , ${timeConvert(BranchData?.doorDeliveryDocket.createdAt)}`) : "-"}
                                            </p>
                                            <p className='flex ai-center gap-4 fs-link'>
                                                <RupeeIcon className="text-primary-400 fs-h5" />
                                                {BranchData?.doorDeliveryDocket.doorDelCharges ? BranchData?.doorDeliveryDocket.doorDelCharges :
                                                    BranchData?.doorDeliveryDocket.extraDoorDelCharges ? BranchData?.doorDeliveryDocket.extraDoorDelCharges : "-"}
                                            </p>
                                            <p className="flex ai-center gap-4 fs-link">
                                                <TimelineIcon className="text-primary-400 fs-h5" />
                                                {BranchData?.doorDeliveryDocket.status && BranchData?.doorDeliveryDocket.status === "B" ? "Booked" : BranchData?.doorDeliveryDocket.status === "O" ? "Out For Delivery" :
                                                    BranchData?.doorDeliveryDocket.status === "D" ? "Delivered" : BranchData?.doorDeliveryDocket.status === "P" ? "Payment settled" : "-"}
                                            </p>
                                        </div>
                                    }
                                    {BranchData?.docket?.deliveredAt !== undefined &&
                                        <Tooltip title={`Print Reciept`}>
                                            <p onClick={() => { setPrintDoorDelVoucher(true) }}>
                                                <PrintIcon className="text-primary-400 fs-h5" />
                                            </p>
                                        </Tooltip>
                                    }
                                    <DoorDeliveryDialog
                                        delCity={newProps?.bookingReducer?.doorDelivery?.delCity}
                                        fromPlaceId={doorDeliveryDocketData.fromPlaceId}
                                        serviceType={doorDeliveryDocketData?.serviceType}
                                        assetType={doorDeliveryDocketData?.assetType}
                                        toPlaceId={newProps.data?.doorDelivery?.place_id}
                                        packages={doorDeliveryDocketData.packages}
                                        open={openDoorDelDialog}
                                        setOpen={setOpenDoorDelDialg}
                                        update={!doorDeliveryDocketData?.doorDelDocketNumber}
                                        docket={BranchData?.docket.docketNumber}
                                        doorDeliveryDocketId={BranchData?.doorDeliveryDocket._id}
                                        doorDeliveryPaymentMode={doorDeliveryDocketData?.doorDeliveryPaymentMode}
                                        deliveryCharge={doorDeliveryDocketData.deliveryCharge}
                                        l1={newProps?.bookingReducer?.doorDelivery?.l1 || ""}
                                        l2={newProps?.bookingReducer?.doorDelivery?.l2 || ""}
                                        officeName={newProps?.bookingReducer?.doorDelivery?.officeName || ""}
                                        floor={newProps?.bookingReducer?.doorDelivery?.floor || ""}
                                        tower={newProps?.bookingReducer?.doorDelivery?.tower || ""}
                                        nearby_landmark={newProps?.bookingReducer?.doorDelivery?.nearby_landmark || ""}
                                        city={newProps?.bookingReducer?.doorDelivery?.city}
                                        state={newProps?.bookingReducer?.doorDelivery?.state}
                                        pincode={newProps?.bookingReducer?.doorDelivery?.pincode || ""}
                                        area={doorDeliveryDocketData.area}
                                        onChange={(what: any, val: any) => {
                                            setDoorDeliveryDocketData((doorDeliveryDocketData: any) => ({ ...doorDeliveryDocketData, [what]: val }))
                                            newProps?.data?.setDoorDelivery(what, val)
                                        }
                                        }
                                        onReset={() => newProps?.data?.resetDoorDelivery()}
                                        locationType={doorDeliveryDocketData.locationType}
                                        placeValue={newProps.data?.bookingReducer?.doorDelivery?.origin?.formatted_address}
                                        placeId={newProps.data?.bookingReducer?.doorDelivery?.origin?.place_id}
                                        name={newProps.data?.bookingReducer?.doorDelivery?.origin?.name}
                                        coordinate={newProps?.data?.bookingReducer?.doorDelivery?.origin?.geometry?.location}
                                        formatted_address={newProps.data?.bookingReducer?.doorDelivery?.origin?.formatted_address}
                                        paymentOptions={[{ label: "ToPay", value: "topay" }, { label: "Credit", value: "credit" }]}
                                        isCustomerTracking={SourceName === "Branch-Authenticated-BranchOrder" ? false : true}
                                        doorDelDocketNumber={doorDeliveryDocketData?.doorDelDocketNumber}
                                        whereFrom={"Customer"}
                                        receiver={BranchData?.docket?.receiver}
                                        searchLocation={doorDeliveryDocketData.searchLocation}
                                    >

                                        {/* {parseInt(doorDeliveryDocketData.doorDelCharges) > 0 && (
                    <ButtonDefault style={{ marginLeft: 25 }} onClick={() => setOpenDoorDelDialg(true)}>
                      Door Delivery Charge {doorDeliveryDocketData?.paymentMode?.value}{" "}
                      {doorDeliveryDocketData.doorDelCharges > 0 && doorDeliveryDocketData.doorDelCharges}
                    </ButtonDefault>
                  )}
                  {!(parseInt(doorDeliveryDocketData.doorDelCharges) > 0) && (
                    <ButtonPrimary style={{ marginLeft: 25 }} onClick={() => setOpenDoorDelDialg(true)}>
                        Door Delivery Charge {doorDeliveryDocketData?.paymentMode?.value}{" "}
                      {doorDeliveryDocketData.doorDelCharges > 0 && doorDeliveryDocketData.doorDelCharges}
                    </ButtonPrimary>
                  )} */}
                                    </DoorDeliveryDialog>
                                </div>
                            </PaperCard>
                            :
                            <div className='doorDeliveryPopupContainer p-1 br-4 shadow-small border-white flex-center'>
                                <button className="doorDeliveryText" onClick={() => { navigate("/login") }}> Book Door Delivery Service Now </button>
                            </div>
                        }
                    </>
                    // :
                    // <></>
                }

                {!BranchData?.docket?.deliveredAt ? ((!BranchData?.docket?.cancelRequestInfo || BranchData?.docket?.cancelRequestInfo?.status === "REJECTED") ?
                    //  <>
                    //   {BranchData?.docket?.deliveredAt === undefined ?
                    <PaperCard className="mt-1">

                        <div className="flex ai-center gap-1 flex-wrap">
                            <div onClick={() => { }} className="p-8 br-4 fs-link bg-primary-400 text-white-900 max-content mx-auto">Complaints</div>
                            <ComplaintButtons setIsDialogOpenFunc={setIsDialogOpenFunc} numbers={5} />
                        </div>

                        {latComplaint !== undefined &&
                            <div className="grid gap-1 mt-1">
                                {latComplaint && latComplaint?.length > 0 && latComplaint?.map((xy: any, index: any) => {
                                    return (
                                        <PaperCard  label={index + 1} className="w-100 overflow-auto " >
                                            <div className="flex gap-2 ai-center fs-link w-100 | m-flex-col m-gap-4 m-ai-start">

                                            {/* <p className='lastComplaint'> {index + 1} </p> */}
                                            <p className='flex-center gap-4'>
                                                    <CalendarIcon className="text-primary-400 fs-h6"  />
                                                    {formatDate(xy?.createdAt)}
                                            </p>
                                            <p className=' flex-center gap-4'>
                                                    <HoursIcon className="text-primary-400 fs-h6" />
                                                    {timeConvert(xy?.createdAt)}
                                            </p>
                                            <p className="flex ai-center gap-4">
                                                    <TimelineIcon className="text-primary-400 fs-h6" />
                                                    {xy?.complaintStatus === "O" ? `Opened` : xy?.complaintStatus === "I" ? `In progress` :
                                                        xy?.complaintStatus === "CP" ? `Compensation Paid` : xy?.complaintStatus === "CG" ? `Compensation Granted` :
                                                            xy?.complaintStatus === "CU" ? `Closed By Executive` : xy?.complaintStatus === "CC" ? `Closed By Customer` :
                                                                xy?.complaintStatus === "CS" ? `Closed By System` : xy?.complaintStatus === "RE" ? `Re Opened` : ``}
                                            </p>
                                            <p className='flex ai-center gap-4' style={{maxWidth:'18rem',minWidth:'18rem'}}>
                                                    <UngroupByRefTypeIcon className="text-primary-400 fs-h6" style={{  minHeight: !mobileView ?  '1rem' : '', minWidth: !mobileView ? '2rem' : ''}}  />
                                                    {xy.complaintType === "LD" ? `Late Delivery` : xy.complaintType === "PM" ? `Parcel Missing` :
                                                        xy.complaintType === "OT" ? `Other` : xy.complaintType === "DMG" ? `Parcel Damaged` :
                                                            xy.complaintType === "PF" ? `Pilferege` : xy.complaintType === "MB" ? `Misbehavior by Kabra Employees` : ``}
                                            </p>
                                            <p className='flex ai-center gap-4' style={{maxWidth:'16rem',minWidth:'16rem'}}>
                                                    <MessageAltIcon className="text-primary-400 fs-h6" />
                                                    {xy?.messages[xy.messages.length - 1]?.text}
                                            </p>
                                            <p className="flex ai-center gap-4">
                                                <div onClick={() => {
                                                    if (SourceName === "Branch-Authenticated-BranchOrder") {
                                                        setChatPopup(true)
                                                        // handleIndividualChatBranchOrder(latComplaint,latComplaint?.feedbackNumber,"BranchBooking")
                                                        //  historyData.push({pathname:"individualChat",
                                                        //   state:{
                                                        //     from: "redirect-chat-from-customerchat-list",
                                                        //     data: latComplaint?.feedbackNumber,
                                                        //     anotherData : latComplaint,
                                                        //     bookingType : "BranchBooking"
                                                        //   }
                                                        // })
                                                    }
                                                    if (SourceName === "Customer-Authenticated-BranchOrder") {
                                                        handleIndividualBranchOrderChat(BranchData?.docket?.feedBacks?.complaints[index],
                                                            BranchData?.docket?.feedBacks?.complaints[index]?.feedbackNumber, "BranchBooking")
                                                    }
                                                    // handleIndividualChat()
                                                    // SourceName === "Customer-Authenticated-BranchOrder" ? handleIndividualChat() : alert("This Feature Will be Live Soon !")
                                                }}>
                                                    <ChatIcon className="text-primary-400" />
                                                </div>
                                                {xy.complaintType === "LD" || xy.complaintType === "PM" ?
                                                    null
                                                    :
                                                    <div onClick={() => handleClose(index)}>
                                                        <CancelIcon />
                                                    </div>
                                                }
                                            </p>
                                            </div>

                                        </PaperCard>)
                                })}
                            </div>
                        }

                        <Dialog state={open} header='Complaint Status' onClose={handleDialogClose} size="small"
                            body={
                                <div style={{ margin: "10px" }}>
                                    {
                                        "Your Complaint has been registered, we will get back to you shortly."
                                    }
                                    <br />
                                    Your Complaint Number is {feedBack}.
                                </div>
                            }
                            footer={
                                <CloseButton onClick={handleDialogClose} />
                            }
                        />

                        <Dialog
                            state={Boolean(isDialogOpen)}
                            size='small'
                            header='Raise Your Concern'
                            onClose={() => { handleDialogClose() }}
                            body={
                                <div>
                                    {isDialogOpen === 1 ?
                                        <div>
                                            {SourceName === "Customer-Authenticated-BranchOrder" ?
                                                <TextArea
                                                    label="Detailed Information"
                                                    rows={3}
                                                    value={details}
                                                    placeholder="Provide detail information regarding your issue"
                                                    onChange={(e: any) => setDetails(e.target.value)}
                                                />
                                                :
                                                <TextArea
                                                    label="Remarks"
                                                    value={reason}
                                                    rows={2}
                                                    onChange={(e: any) => setReason(e.target.value)}
                                                />
                                            }
                                        </div>
                                        :
                                        isDialogOpen === 2 ?
                                            <div>
                                                {SourceName === "Customer-Authenticated-BranchOrder" ?
                                                    <TextArea
                                                        label="Detailed Information"
                                                        rows={3}
                                                        value={details}
                                                        placeholder="Provide detail information regarding your issue"
                                                        onChange={(e: any) => setDetails(e.target.value)}
                                                    />
                                                    :
                                                    <div className="grid gap-1">
                                                        <TextArea
                                                            label="Remarks"
                                                            value={reason}
                                                            rows={2}
                                                            onChange={(e: any) => setReason(e.target.value)}
                                                        />
                                                        <ImageInput
                                                            label="Upload Image"
                                                            name="Upload Image"
                                                            Icon={(props: any) => (
                                                                <i {...props} className={"fas fa-id-card file_icon"} />
                                                            )}
                                                            onChange={setImage}
                                                            value={Image}
                                                            extras={<div></div>}
                                                        />

                                                    </div>
                                                }
                                            </div>
                                            :
                                            isDialogOpen === 3 ?
                                                <div>
                                                    {SourceName === "Customer-Authenticated-BranchOrder" ?
                                                        <TextArea
                                                            label="Detailed Information"
                                                            rows={3}
                                                            value={details}
                                                            placeholder="Provide detail information regarding your issue"
                                                            onChange={(e: any) => setDetails(e.target.value)}
                                                        />
                                                        :
                                                        <div className="grid gap-1">
                                                            <TextArea
                                                                label="Remarks"
                                                                value={reason}
                                                                rows={2}
                                                                onChange={(e: any) => setReason(e.target.value)}
                                                            />
                                                            <ImageInput
                                                                label="Upload Image"
                                                                name="Upload Image"
                                                                Icon={(props: any) => (
                                                                    <i {...props} className={"fas fa-id-card file_icon"} />
                                                                )}
                                                                onChange={setImage}
                                                                value={Image}
                                                                extras={<div></div>}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                                :
                                                isDialogOpen === 4 ?
                                                    <div>
                                                        {SourceName === "Customer-Authenticated-BranchOrder" ?
                                                            <TextArea
                                                                label="Detailed Information"
                                                                rows={3}
                                                                value={details}
                                                                placeholder="Provide detail information regarding your issue"
                                                                onChange={(e: any) => setDetails(e.target.value)}
                                                            />
                                                            :
                                                            <div className="grid gap-1">
                                                                <Select
                                                                    value={customerType}
                                                                    options={customerOptions}
                                                                    onChange={(e: any) => setCustomerType(e)}
                                                                    label="Customer"
                                                                    placeholder="Select Customer"
                                                                    styles={{
                                                                        menu: (base) => ({ ...base, zIndex: 10000000000000 }),  // High z-index for dropdown
                                                                        menuList: (base) => ({ ...base, zIndex: 10000000000000 }),  // High z-index for dropdown
                                                                      }}
                                                                      // menuPortalTarget={document.body}  // Render outside dialog
                                                                      menuPosition="fixed"
                                                                />
                                                                {customerType && customerType.value === "other" && (
                                                                    <TextField
                                                                        value={name}
                                                                        label="Name"
                                                                        placeholder="Enter Name"
                                                                        onChange={(e: any) => setName(e.target.value)}
                                                                    />
                                                                )}
                                                                {customerType && customerType.value === "other" && (
                                                                    <TextField
                                                                        value={contact}
                                                                        label="Mobile Number"
                                                                        placeholder="Enter Mobile Number"
                                                                        onChange={(e: any) => setContact(e.target.value)}
                                                                    />
                                                                )}
                                                                <TextArea
                                                                    label="Detailed Information"
                                                                    rows={3}
                                                                    value={details}
                                                                    placeholder="Provide detail information regarding your issue"
                                                                    onChange={(e: any) => setDetails(e.target.value)}
                                                                />
                                                                <Select
                                                                    value={misBehaveUser}
                                                                    label="Branch"
                                                                    placeholder="Select Branch"
                                                                    options={branchOptions}
                                                                    onChange={(e: any) => setMissBehaveUSer(e)}
                                                                    styles={{
                                                                        menu: (base) => ({ ...base, zIndex: 10000000000000 }),  // High z-index for dropdown
                                                                        menuList: (base) => ({ ...base, zIndex: 10000000000000 }),  // High z-index for dropdown
                                                                      }}
                                                                      // menuPortalTarget={document.body}  // Render outside dialog
                                                                      menuPosition="fixed"
                                                                />
                                                                <Select
                                                                    options={userOptions}
                                                                    label="User"
                                                                    placeholder="Select User"
                                                                    value={missBehaveBranch}
                                                                    onChange={(e: any) => setMissBehaveBranch(e)}
                                                                    styles={{
                                                                        menu: (base) => ({ ...base, zIndex: 10000000000000 }),  // High z-index for dropdown
                                                                        menuList: (base) => ({ ...base, zIndex: 10000000000000 }),  // High z-index for dropdown
                                                                      }}
                                                                      // menuPortalTarget={document.body}  // Render outside dialog
                                                                      menuPosition="fixed"
                                                                />
                                                                <ImageInput
                                                                    label="Upload Image"
                                                                    name="Add Image"
                                                                    Icon={(props: any) => (
                                                                        <i {...props} className={"fas fa-id-card file_icon"} />
                                                                    )}
                                                                    onChange={(e: any) => setImageTwo(e)}
                                                                    value={ImageTwo}
                                                                    extras={<div></div>}
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                    :
                                                    isDialogOpen === 5 ?
                                                        <div>
                                                            {SourceName === "Customer-Authenticated-BranchOrder" ?
                                                                <TextArea
                                                                    label="Detailed Information"
                                                                    rows={3}
                                                                    value={details}
                                                                    placeholder="Provide detail information regarding your issue"
                                                                    onChange={(e: any) => setDetails(e.target.value)}
                                                                />
                                                                :
                                                                <div className="grid gap-1">
                                                                    <Select
                                                                        value={customerType}
                                                                        options={customerOptions}
                                                                        onChange={(e: any) => setCustomerType(e)}
                                                                        placeholder="Select Customer"
                                                                        label="Contact Person"
                                                                        styles={{
                                                                            menu: (base) => ({ ...base, zIndex: 10000000000000 }),  // High z-index for dropdown
                                                                            menuList: (base) => ({ ...base, zIndex: 10000000000000 }),  // High z-index for dropdown
                                                                          }}
                                                                          // menuPortalTarget={document.body}  // Render outside dialog
                                                                          menuPosition="fixed"
                                                                    />
                                                                    {customerType && customerType.value === "other" && (

                                                                        <TextField
                                                                            value={name}
                                                                            placeholder="Select Name"
                                                                            label="Name"
                                                                            onChange={(e: any) => setName(e.target.value)}
                                                                        />
                                                                    )}
                                                                    {customerType && customerType.value === "other" && (
                                                                        <TextField
                                                                            value={contact}
                                                                            label="Mobile Number"
                                                                            placeholder="Enter Mobile Number"
                                                                            onChange={(e: any) => setContact(e.target.value)}
                                                                        />
                                                                    )}
                                                                    <TextArea
                                                                        label="Detailed Information"
                                                                        rows={3}
                                                                        value={details}
                                                                        placeholder="Provide detail information regarding your issue"
                                                                        onChange={(e: any) => setDetails(e.target.value)}
                                                                    />
                                                                    <ImageInput
                                                                        label="Upload Image"
                                                                        name="Add Image"
                                                                        Icon={(props: any) => (
                                                                            <i {...props} className={"fas fa-id-card file_icon"} />
                                                                        )}
                                                                        onChange={(e: any) => setImageTwo(e)}
                                                                        value={ImageTwo}
                                                                        extras={<div></div>}
                                                                    />
                                                                </div>
                                                            }
                                                        </div>
                                                        :
                                                        isDialogOpen === 6 ?
                                                            <div className="grid gap-1">
                                                                <TextArea
                                                                    label="Remarks"
                                                                    rows={2}
                                                                    value={reason}
                                                                    onChange={(e: any) => setReason(e.target.value)}
                                                                />
                                                                <ImageInput
                                                                    label="Upload Image"
                                                                    name="Upload Image"
                                                                    Icon={(props: any) => (
                                                                        <i {...props} className={"fas fa-id-card file_icon"} />
                                                                    )}
                                                                    onChange={setImage}
                                                                    value={Image}
                                                                    extras={<div></div>}
                                                                />
                                                            </div>
                                                            :
                                                            <></>
                                    }
                                </div>

                            }
                            footer={<div className='flex jc-end gap-8'>
                                <SubmitButton variant="secondary" action="secondary" onClick={() => {
                                    if (SourceName === "Branch-Authenticated-BranchOrder") {
                                        if (isDialogOpen === 4) {
                                            handleMBandLDcomplaints("MB")
                                            handleDialogClose()
                                        }
                                        if (isDialogOpen === 5) {
                                            handleMBandLDcomplaints("LD")
                                            handleDialogClose()
                                        }
                                        else {
                                            handleSubmitRemarks()
                                            handleDialogClose()
                                        }
                                    }
                                    if (SourceName === "Customer-Authenticated-BranchOrder") {
                                        if (details?.length <= 0) {
                                            showHttpError(`Kindly Enter Remarks regarding Complaint.`);
                                            return;
                                        }
                                        else {
                                            if (isDialogOpen === 1) {
                                                hanldeCustomerComaplints("PM")
                                            }
                                            if (isDialogOpen === 2) {
                                                hanldeCustomerComaplints("PF")
                                            }
                                            if (isDialogOpen === 3) {
                                                hanldeCustomerComaplints("DMG")
                                            }
                                            if (isDialogOpen === 4) {
                                                hanldeCustomerComaplints("MB")
                                            }
                                            if (isDialogOpen === 5) {
                                                hanldeCustomerComaplints("LD")
                                            }
                                        }
                                    }
                                    if (SourceName === "Customer-NotAuthenticated-BranchOrder") {
                                        if (details?.length <= 0) {
                                            return showHttpError(`Kindly Enter Remarks regarding Complaint.`)
                                        }
                                        else if (customerType === "") {
                                            return showHttpError(`Kindly Select Type Of Customer To Register Complaint.`)
                                        }
                                        else if (customerType?.value === "other") {
                                            if (contact === "" || contact?.length <= 9 || contact?.length > 10) {
                                                return showHttpError(`Contact Number Should Not Be Empty Or Less / Greater Than 10.`)
                                            }
                                            if (name === "") {
                                                return showHttpError(`Contact Name Should Not Be Empty.`)
                                            }
                                        }
                                        else {
                                            handleCustomerComplaintOutSide("")
                                        }
                                    }
                                    setIsDialogOpen(0)
                                }} />
                                <CloseButton onClick={() => { handleDialogClose() }} />
                            </div>}
                        />

                        <Dialog
                            state={Boolean(outsideDialogOpen)}
                            size='small'
                            header='Raise Your Concern'
                            onClose={() => { handleDialogClose() }}
                            body={
                                <div>
                                    <Select
                                        label="Contact Person"
                                        value={customerType}
                                        options={customerOptions}
                                        onChange={(e: any) => setCustomerType(e)}
                                        placeholder="Select Customer"
                                        styles={{
                                            menu: (base) => ({ ...base, zIndex: 10000000000000 }),  // High z-index for dropdown
                                            menuList: (base) => ({ ...base, zIndex: 10000000000000 }),  // High z-index for dropdown
                                        }}
                                        // menuPortalTarget={document.body}  // Render outside dialog
                                        menuPosition="fixed"
                                    />
                                    {customerType && customerType.value === "other" &&
                                        <div className="grid gap-1 my-1">

                                            <TextArea
                                                label="Name"
                                                value={name}
                                                placeholder="Name"
                                                onChange={(e: any) => setName(e.target.value)}
                                            />

                                            <TextField
                                                label="Mobile Number"
                                                type={"number"}
                                                value={contact}
                                                placeholder="Mobile Number"
                                                onChange={(e: any) => setContact(e.target.value)}
                                            />
                                        </div>
                                    }

                                    <TextArea
                                        label="Detailed Information"
                                        // multiline={true}
                                        rows={3}
                                        value={details}
                                        placeholder="Provide detail information regarding your issue"
                                        onChange={(e: any) => setDetails(e.target.value)}
                                    />
                                </div>
                            }
                            footer={
                                <div className='flex jc-end gap-8'>
                                    <SubmitButton variant="secondary" action="secondary" onClick={() => {
                                        if (SourceName === "Customer-NotAuthenticated-BranchOrder") {
                                            if (details?.length <= 0) {
                                                return showHttpError(`Kindly Enter Remarks regarding Complaint.`)
                                            }
                                            else if (customerType === "") {
                                                return showHttpError(`Kindly Select Type Of Customer To Register Complaint.`)
                                            }
                                            else if (customerType?.value === "other") {
                                                if (contact === "" || contact?.length <= 9 || contact?.length > 10) {
                                                    return showHttpError(`Contact Number Should Not Be Empty Or Less / Greater Than 10.`)
                                                }
                                                if (name === "") {
                                                    return showHttpError(`Contact Name Should Not Be Empty.`)
                                                }
                                            }
                                            else {
                                                if (isDialogOpen === 1) {
                                                    handleCustomerComplaintOutSide("PM")
                                                }
                                                if (isDialogOpen === 2) {
                                                    handleCustomerComplaintOutSide("PF")
                                                }
                                                if (isDialogOpen === 3) {
                                                    handleCustomerComplaintOutSide("DMG")
                                                }
                                                if (isDialogOpen === 4) {
                                                    handleCustomerComplaintOutSide("MB")
                                                }
                                                if (isDialogOpen === 5) {
                                                    handleCustomerComplaintOutSide("LD")
                                                }
                                            }
                                        }
                                    }} />
                                    <CloseButton onClick={() => { handleDialogClose() }} />
                                </div>
                            }
                        />

                        <Dialog
                            state={Boolean(outsidePaymentDialogOpen)}
                            size='extraLarge'
                            header='Online Payment Details'
                            onClose={() => { handleDialogClose() }}
                            body={
                                <div className='popupForComplaintForOutside'>
                                    <div className="p-1 border-white shadow-small br-4">
                                        <div className='commonSelectSendReciever flex ai-center'>
                                            <Checkbox
                                                id='online-payment-Sender'
                                                checked={senderOptInForPayment === "Sender" ? true : false}
                                                onChange={() => { setSenderOptInForPayment("Sender"), setSelectedNumberForPayment(BranchData?.docket?.sender?.contact) }}
                                                color="primary"
                                                label='Sender'
                                            />
                                            <p className="m-0">{getPhoneNumberPrefix(BranchData?.docket?.sender?.contact)}</p>
                                        </div>
                                    </div>
                                    <div className="p-1 border-white shadow-small br-4 mt-1">
                                        <div className='commonSelectSendReciever flex ai-center'>
                                            <Checkbox
                                                id='online-payment-receiver'
                                                checked={senderOptInForPayment === "Reciever" ? true : false}
                                                onChange={() => { setSenderOptInForPayment("Reciever"), setSelectedNumberForPayment(BranchData?.docket?.receiver?.contact) }}
                                                color="primary"
                                            />

                                            <p>{getPhoneNumberPrefix(BranchData?.docket?.receiver?.contact)}</p>
                                        </div>
                                    </div>
                                    <hr />
                                    {(BranchData.docket?.paymentMode === "topay" && !BranchData.docket?.settled) &&
                                        <div className='commonSelectSendReciever flex ai-center'>
                                            <Checkbox
                                                checked={mainBookingCheckBoxPart}
                                                onChange={() => setMainBookingCheckBoxPart(!mainBookingCheckBoxPart)}
                                                color="primary"
                                                label="Normal Docket Payment"
                                                disabled={(BranchData.docket.settled || (BranchData?.docket?.paymentMode !== "topay")) ? true :
                                                    (!BranchData.docket.settled && TotalAmount - totalAmount > 0) ? true : false}
                                            />

                                            {/* <p>{getPhoneNumberPrefix(BranchData?.docket?.sender?.contact)}</p> */}
                                        </div>
                                    }
                                    {mainBookingCheckBoxPart && <div className="text-black-400">If Demmurage And Gst Penalty Implemented, It will Be Taken At Branch ~!</div>}

                                    {(BranchData?.doorDeliveryDocket.paymentMode === "topay" && !BranchData?.doorDeliveryDocket.settled) &&
                                        <div className='commonSelectSendReciever flex ai-center'>
                                            <Checkbox
                                                checked={DoorDelBookingCheckBox}
                                                onChange={() => setDoorDelBookingCheckBox(!DoorDelBookingCheckBox)}
                                                disabled={(BranchData?.doorDeliveryDocket.settled || BranchData?.doorDeliveryDocket.paymentMode !== "topay") ? true : false}
                                                color="primary"
                                                label='Door Delivery Payment'
                                            />

                                            {/* <p>{getPhoneNumberPrefix(BranchData?.docket?.sender?.contact)}</p> */}
                                        </div>
                                    }

                                    <PaperCard className="two-column m-one-column m-one-column gap-1">
                                        <div>
                                            <div>
                                                Total
                                            </div>
                                            <div>
                                                <input
                                                    id="Total"
                                                    type="number"
                                                    className="py-8 px-1 br-4 border-white w-100"
                                                    disabled={true}
                                                    placeholder="Total"
                                                    value={showOnlyTotalAmount}
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <div style={{}}>
                                                Pending
                                            </div>
                                            <div>
                                                <input
                                                    id="Total"
                                                    type="number"
                                                    className="py-8 px-1 br-4 border-white w-100"
                                                    disabled={true}
                                                    placeholder="Total"
                                                    value={(BranchData?.docket?.settled && BranchData?.doorDeliveryDocket?.settled) ? 0 :
                                                        (BranchData?.docket?.paymentMode === "topay" ?
                                                            (mainBookingCheckBoxPart && DoorDelBookingCheckBox) ? (totalNetCost + totalDoorDelCost) :
                                                                (mainBookingCheckBoxPart && !DoorDelBookingCheckBox) ? (totalNetCost) :
                                                                    (DoorDelBookingCheckBox && !mainBookingCheckBoxPart) ? (totalDoorDelCost) :
                                                                        0 :
                                                            BranchData?.docket?.paymentMode !== "topay" && !BranchData.doorDeliveryDocket?.settled &&
                                                                (mainBookingCheckBoxPart && DoorDelBookingCheckBox) ? (totalNetCost + totalDoorDelCost) :
                                                                (DoorDelBookingCheckBox && !mainBookingCheckBoxPart) ? (totalDoorDelCost) :
                                                                    totalAmountWithoutTopay)}
                                                />
                                            </div>
                                        </div>

                                        {/* <div style={{border : "0.2px solid transparent" , width : "30%" , padding : "1%" , display : "block" , marginLeft : "5%"}}>
                    <PaperCard style={{border : "0.2px solid transparent" , padding : "0.5%"}}>
                      {/* <TextField
                        inputProps={{
                          style: { height: "3px" },
                        }}
                        style={{ zIndex: 0 }}
                        fullWidth={true}
                        id="total"
                        placeholder="Total"
                        InputProps={{
                          readOnly: true,
                        }}
                        value={doorDeliveryDocket?.deliveryCharge === undefined || doorDeliveryDocket?.paymentMode === "paid" ? totalAmount : combinedTotalAmount}
                        margin="normal"
                      /> 
  
                      <input
                        id="Total"
                        type="number"
                        disabled={true}
                        placeholder="Total"
                        style={{border : "0.2px solid grey" , borderRadius : '2.5%' , padding : "7.5% 5%" , margin : "auto" , width : "100%"}}
                        value={(bookingData?.docket?.settled && doorDeliveryDocket?.settled) ? 0 : doorDeliveryDocket?.deliveryCharge === undefined ? 
                                (bookingData?.docket?.paymentMode !== "topay" ? 0 : totalAmount) : 
                                (DoorDelBookingCheckBox && !bookingData?.docket?.settled) ? combinedTotalAmount : 
                                (!DoorDelBookingCheckBox && !bookingData?.docket?.settled) ? totalAmount : 
                                (DoorDelBookingCheckBox && bookingData?.docket?.settled) ? onlyDoorDeliveryAmount : 0}
                      />
                    </div>
                  </div>*/}
                                    </PaperCard>

                                </div>
                            }
                            footer={
                                <div className='flex jc-end gap-8'>
                                    <Button variant="secondary" action="secondary" onClick={handleOutsidePayment} disabled={(DoorDelBookingCheckBox && mainBookingCheckBoxPart) ? true : false}>
                                        Pay Online
                                    </Button>
                                    <CloseButton
                                        onClick={() => { handleDialogClose() }}
                                    />
                                </div>
                            }
                        />

                    </PaperCard>
                    // :
                    // }
                    //  </>
                    :
                    null) : null
                }

                {newProps?.data?.userInfo?.loginType !== "B" && BranchData?.docket?.deliveredAt !== undefined &&
                    <PaperCard className="flex gap-1 ai-end mt-1">
                        <div className="grid gap-4 m-0 mr-1 ">
                            <label htmlFor="">Rating</label>
                            <Rating
                                max={3}
                                name="hover-feedback1"
                                className="fs-h5"
                                value={ratingFromUser}
                                precision={1}
                                readonly={ratingDone ? true : false}
                                onChange={(e: any) => {
                                    setRatingFromUser(e.target.value);
                                }}
                            />
                        </div>

                        <TextField
                            label='Review'
                            containerClassName="flex-grow-1"
                            placeholder="Give a Review"
                            className="border-white br-4 outline-none py-8 px-1"
                            value={reviewFromUser}
                            disabled={ratingDone ? true : false}
                            onChange={(el: any) => { setReviewFromUser(el.target.value) }}
                        />
                        {
                            !ratingDone &&
                            <div className="submitRating" onClick={() => {
                                if (reviewFromUser.length < 2) {
                                    return showHttpError("Review Is Mandatory !")
                                }
                                else if (ratingFromUser === 0) {
                                    return showHttpError("Rating Is Mandatory !")
                                }
                                setOutsideRating(true)
                            }}>
                                <button className="suubmitIcon">
                                    <SendIcon className="text-primary-400 fs-h5" />
                                </button>

                            </div>
                        }
                        <Dialog
                            state={Boolean(outsideRating)}
                            size='small'
                            onClose={() => { handleDialogClose() }}
                            header='Review And Rating'
                            body={
                                <Select
                                    label=" Rating Given By"
                                    value={customerTypeForRating}
                                    options={customerOptions}
                                    onChange={(e: any) => setCustomerTypeForRating(e)}
                                    placeholder="Select Customer"
                                    styles={{
                                        menu: (base) => ({ ...base, zIndex: 10000000000000 }),  // High z-index for dropdown
                                        menuList: (base) => ({ ...base, zIndex: 10000000000000 }),  // High z-index for dropdown
                                    }}
                                    // menuPortalTarget={document.body}  // Render outside dialog
                                    menuPosition="fixed"
                                />
                            }
                            footer={
                                <div className='onLinePkgbtnMobile'>
                                    <SubmitButton variant="secondary" action="secondary" onClick={() => {
                                        handleRatingForBranchOrder();
                                    }} />
                                    <CloseButton onClick={() => { handleDialogClose() }} />
                                </div>
                            }
                        />
                    </PaperCard>
                }   

                {/* Outside Tracking First Popup Started */}
                <Dialog
                    state={firstPopup}
                    size='small'
                    header='Login In'
                    onClose={() => { setFirstPopup(false) }}
                    body={
                        <div>
                            {firstPopupTabs === 0 &&
                                <div className="firstPopupFirstTab">
                                    {/* Choose AnyOne */}
                                    {/* <br /> */}
                                    <div className='grid gap-1'>
                                        <Button variant={senderOptIn === "Sender" ? 'secondary' : 'primary'} action={senderOptIn === "Sender" ? 'secondary' : 'primary'} onClick={() => {
                                                    setSenderOptIn("Sender"), setSelectedNumber(BranchData?.docket?.sender?.contact),
                                                        ReactGA4.event({
                                                            category: 'Button',
                                                            action: `sender_check_box`,
                                                            label: 'Btn Clicked From Tracking Outside',
                                                            value: BranchData?.docket.trackingNumber
                                                        });
                                                }} className='flex gap-1 ai-center fs-link p-1 border-white br-4'>
                                            <Checkbox
                                                label="Sender"
                                                id="SenderCheckBox"
                                                checked={senderOptIn === "Sender" ? true : false}
                                                onChange={() => {
                                                    setSenderOptIn("Sender"), setSelectedNumber(BranchData?.docket?.sender?.contact),
                                                        ReactGA4.event({
                                                            category: 'Button',
                                                            action: `sender_check_box`,
                                                            label: 'Btn Clicked From Tracking Outside',
                                                            value: BranchData?.docket.trackingNumber
                                                        });
                                                }}
                                                color="primary"
                                                labelPosition = "right"
                                                className="border-none gap-4"
                                            />

                                            <p className="flex ai-center gap-4"><CallIcon /> {getPhoneNumberPrefix(BranchData?.docket?.sender?.contact)}</p>
                                        </Button>
                                        <Button variant={senderOptIn === "Reciever" ? 'secondary' : 'primary'} action={senderOptIn === "Reciever" ? 'secondary' : 'primary'} onClick={() => {
                                                    setSenderOptIn("Reciever"), setSelectedNumber(BranchData?.docket?.receiver?.contact),
                                                        ReactGA4.event({
                                                            category: 'Button',
                                                            action: `reciever_check_box`,
                                                            label: 'Btn Clicked From Tracking Outside',
                                                            value: BranchData?.docket.trackingNumber
                                                        });
                                                }} className='flex gap-1 ai-center fs-link p-1 border-white br-4'>
                                            <Checkbox
                                                label="Reciver"
                                                id="RecieverCheckBox"
                                                checked={senderOptIn === "Reciever" ? true : false}
                                                onChange={() => {
                                                    setSenderOptIn("Reciever"), setSelectedNumber(BranchData?.docket?.receiver?.contact),
                                                        ReactGA4.event({
                                                            category: 'Button',
                                                            action: `reciever_check_box`,
                                                            label: 'Btn Clicked From Tracking Outside',
                                                            value: BranchData?.docket.trackingNumber
                                                        });
                                                }}
                                                color="primary"
                                                labelPosition = "right"
                                                className="border-none gap-4"
                                            />

                                            <p className="flex ai-center gap-4"><CallIcon />{getPhoneNumberPrefix(BranchData?.docket?.receiver?.contact)}</p>
                                        </Button>
                                        <Button variant={senderOptIn === "Other" ? 'secondary' : 'primary'} action={senderOptIn === "Other" ? 'secondary' : 'primary'} onClick={() => {
                                                    setSenderOptIn("Other"),
                                                        ReactGA4.event({
                                                            category: 'Button',
                                                            action: `new_customer_check_box`,
                                                            label: 'Btn Clicked From Tracking Outside',
                                                            value: BranchData?.docket.trackingNumber
                                                        });
                                                }} className='flex gap-1 ai-center fs-link p-1 border-white br-4'>
                                            <Checkbox
                                                checked={senderOptIn === "Other" ? true : false}
                                                onChange={() => {
                                                    setSenderOptIn("Other"),
                                                        ReactGA4.event({
                                                            category: 'Button',
                                                            action: `new_customer_check_box`,
                                                            label: 'Btn Clicked From Tracking Outside',
                                                            value: BranchData?.docket.trackingNumber
                                                        });
                                                }}
                                                label="New To TapTap"
                                                id="NewOnTapTapCheckBox"
                                                color="primary"
                                                labelPosition = "right"
                                                className="border-none gap-4"
                                            />

                                        </Button>
                                    </div>
                                </div>
                            }
                            {firstPopupTabs === 1 &&
                                <div className='firstPopupSecondTab'>
                                    {userHasPass ?
                                        <div className=''>
                                            {/* <Input6
                        label="Enter OTP"
                        style={{width:"250px"}}
                        value={firstPopupOtp}
                        onChange={(e: any) => setFirstPopupOtp(e.target.value)}
                      />  */}
                                            {/* <Input6
                    label="Enter Password"
                    style={{ width: "250px" }}
                    className={`fs-link`}
                    value={firstPopupPassword}
                    onChange={(e: any) => setFirstPopupPassword(e.target.value)}
                    onFocus={() => {
                      return ReactGA4.event({
                        category: 'Button',
                        action: `password_entered`,
                        label: 'Btn Clicked From Tracking Outside',
                        value: BranchData?.docket.trackingNumber
                      })
                    }}
                  /> */}
                                            <input
                                                type="password"
                                                className="br-4 border-white py-10 px-1 w-100"
                                                placeholder="Enter password"
                                                value={firstPopupPassword}
                                                onChange={(e: any) => setFirstPopupPassword(e.target.value)}
                                                onFocus={() => {
                                                    return ReactGA4.event({
                                                        category: 'Button',
                                                        action: `password_entered`,
                                                        label: 'Btn Clicked From Tracking Outside',
                                                        value: BranchData?.docket.trackingNumber
                                                    })
                                                }}
                                            />
                                            <Link to="/login" className="fp-span">
                                                <p onClick={() => {
                                                    ReactGA4.event({
                                                        category: 'Button',
                                                        action: `forget_pass_clicked`,
                                                        label: 'Btn Clicked From Tracking Outside',
                                                        value: BranchData?.docket.trackingNumber
                                                    });
                                                }}>
                                                    Forgot Password?
                                                </p>
                                            </Link>
                                        </div>
                                        :
                                        <div className='flex-col gap-1'>
                                            <input
                                                className="border-white br-4 w-100 px-1 py-8"
                                                placeholder="Enter OTP"
                                                value={firstPopupOtp}
                                                onChange={(e: any) => setFirstPopupOtp(e.target.value)}
                                            />
                                            <input
                                                className="border-white br-4 w-100 px-1 py-8"
                                                placeholder="New Password"
                                                value={firstPopupNewPassword}
                                                onChange={(e: any) => setFirstPopupNewPassword(e.target.value)}
                                            />
                                            <input
                                                className="border-white br-4 w-100 px-1 py-8"
                                                placeholder="Confirm Password"
                                                value={firstPopupConfirmPassword}
                                                onChange={(e: any) => setFirstPopupConfirmPassword(e.target.value)}
                                            />
                                            <Link to="/login" className="">
                                                <p className="text-primary-400">
                                                    Forgot Password
                                                </p>
                                            </Link>
                                        </div>
                                    }
                                </div>
                            }
                        </div>

                    }
                    footer={
                        <div className='flex gap-1 ai-center jc-end'>
                            {senderOptIn !== "" && firstPopupTabs === 0 &&
                                <Button variant="secondary" action="secondary"
                                    onClick={() => {
                                        ReactGA4.event({
                                            category: `Button`,
                                            action: `${senderOptIn === "Sender" || senderOptIn === "Reciever" ? "proceed_button_clicked" : "register_now_button_clicked"}`,
                                            label: 'Btn Clicked From Tracking Outside',
                                            value: BranchData?.docket.trackingNumber
                                        });
                                        if (senderOptIn === "Sender" || senderOptIn === "Reciever") {
                                            if (!userHasPass && senderOptIn === "Sender") {
                                                sendCustomerOTP({ contact: BranchData?.docket?.sender?.contact })
                                            }
                                            else if (!userHasPass && senderOptIn === "Reciever") {
                                                sendCustomerOTP({ contact: BranchData?.docket?.receiver?.contact })
                                            }
                                            setFirstPopupTabs(firstPopupTabs + 1)
                                        }
                                        else {
                                            openInNewTab('https://taptap.in/customer')
                                        }
                                    }}
                                >
                                    {senderOptIn === "Sender" || senderOptIn === "Reciever" ? "Proceed" : "Register Now"}
                                </Button>
                            }
                            {firstPopupTabs === 1 &&
                                <div className='flex gap-1 ai-center'>
                                    <Button variant="secondary" action="secondary"
                                        onClick={() => {
                                            setFirstPopupTabs(0),
                                                ReactGA4.event({
                                                    category: 'Button',
                                                    action: `back_button_clicked`,
                                                    label: 'Btn Clicked From Tracking Outside',
                                                    value: BranchData?.docket.trackingNumber
                                                });
                                        }}
                                    >
                                        Back
                                    </Button>
                                    {userHasPass ?
                                        <Button variant="secondary" action="secondary"
                                            onClick={() => {
                                                handleLogin(),
                                                    ReactGA4.event({
                                                        category: 'Button',
                                                        action: `sign_in_button_clicked`,
                                                        label: 'Btn Clicked From Tracking Outside',
                                                        value: BranchData?.docket.trackingNumber
                                                    });
                                            }}
                                        >
                                            Sign In
                                        </Button>
                                        :
                                        <Button variant="secondary" action="secondary"
                                            onClick={() => {
                                                handleSignUp(),
                                                    ReactGA4.event({
                                                        category: 'Button',
                                                        action: `sign_up_button_clicked`,
                                                        label: 'Btn Clicked From Tracking Outside',
                                                        value: BranchData?.docket.trackingNumber
                                                    });
                                            }}
                                        >
                                            Sign Up
                                        </Button>
                                    }
                                </div>
                            }
                            <Button variant="danger"
                                onClick={() => {
                                    setFirstPopup(false),
                                        ReactGA4.event({
                                            category: 'Button',
                                            action: `close_button_clicked`,
                                            label: 'Btn Clicked From Tracking Outside',
                                            value: BranchData?.docket.trackingNumber
                                        });
                                }}
                            >
                                Skip
                            </Button>
                        </div>
                    }
                />
                {/* Outside Tracking First Popup Ended */}

                {/* Outside Tracking First Popup Started */}
                <Dialog
                    state={chatPopup}
                    header='Chat Container'
                    size='medium'
                    onClose={() => { setChatPopup(false) }}
                    body={
                            <ChatContainer
                                chatHistory={newProps?.data?.customerChatHistory && newProps?.data?.customerChatHistory?.chat}
                                value={guestMessage}
                                onChange={setGuestMessage}
                                onKeyDown={handleSend}
                                onClick={handleSend}
                                isClosedComplaint={true}
                            />
                    }
                    footer={
                        <CloseButton onClick={() => { setChatPopup(false);setGuestMessage('') }} />
                    }
                />
                {/* Outside Tracking First Popup Ended */}

                {/* Door Delivery Voucher Popup Started */}
                <Dialog
                    state={printDoorDelVoucher}
                    onClose={() => { setPrintDoorDelVoucher(false) }}
                    header='Door Delivery Voucher'
                    body={
                        <div>
                            {printDoorDelVoucher && (
                                <div id="door_delivery_voucher_print">
                                    <DoorDeliveryVoucher
                                        data={BranchData}
                                    />
                                </div>
                            )}
                        </div>
                    }
                    footer={
                        <Button variant="secondary" action="secondary" onClick={rePrintVoucher}>
                            Print
                        </Button>
                    }
                />

                {/* Door Delivery Voucher Popup Ended */}

            </PaperCard>
        </>
    )
}

const mapStateToProps = (state: any) => ({
    sub: state.user.loginType,
    userDetails: state.user,
    entity:
        state.user.loginType === "B"
            ? state.user.opBranch._id
            : state.user.opFleet._id,
    customerTracking: state.customerBooking.tracking,
    user: state.customerLoginReducer,
    userInfo: state.user,
    customerChatHistory: state.customerChatHistory,
    customerTrackingData: state.customerTracking,
    branchListData: state.branch.filteredBranches.data.Branches,
    bookingReducer: state.booking,
})

const mapDispatchToProps = {
    getCustomerDocketLocation,
    customerLogin,
    setCustomerBookingTracking,
    ResetCustomerTracking,
    showLoader,
    hideLoader,
    setDoorDelivery,
    resetDoorDelivery
}

export default connect(mapStateToProps, mapDispatchToProps)(BranchTrackingView)