import React from "react";
import "./docketNumber.css";
import { Link } from "react-router-dom";

interface IProps {
  docketNumber: string;
  customerSection?: boolean
  // color?: string; 
}

const DocketNumberRedirect = (props: IProps) => {
  return (
    <Link
      to={{
        pathname: props?.customerSection ? "/customerDashboard/track" : "/dashboard/TempTracking",
      }}
      state={{
        from: "Branch-Authenticated-BranchOrder",
        data: props.docketNumber,
        docketNumber: { docket: props.docketNumber && props.docketNumber },
        typeOfDocket: "Branch Docket",
      }}
      style={{
        textDecoration: "none", color:
          // props.color ? props.color :
          "var(--clr-primary-400)"
        // "black"
      }}
    >
      <div className="docket">{props.docketNumber && props.docketNumber.toUpperCase()}</div>
    </Link>
  );
};

export default DocketNumberRedirect;
