import React, { useEffect, useState } from 'react'
import { Button, Dialog, NoDataFound, PaperCard, Select, Tooltip } from "../../../newSrc/components/UI";
import { CalendarIcon, GalleryIcon, HoursIcon, InfoIcon, LocationIcon, QRCodeIcon, TimelineIcon, UserIcon } from "../../../newSrc/constants/icons";
import setTimeFormat from "../../../services/FormatTime";
import { showHttpError } from "../../../utils/message";
import MyImage from "../../Image/MyImage";
import formatDate from "../../../services/FormatDate";
import { useWidth } from "../../../newSrc/hooks/useMobile";
import { CloseButton } from "../../../Components/Button/AllButton";

const SingleStatus = (props: any) => {
  const [logsData, setLogsData] = useState<any>([]);
  const [selectedPackageForLogs, setSelectedPackageForLogs] = useState<any>({ label: "Select Order Number", value: "Select Order Number" });
  const {mobileView} =useWidth();
  useEffect(() => {
    if (props?.PkgList?.length > 0) {
      const firstOrder = props.PkgList[0];
      setSelectedPackageForLogs({ label: firstOrder?.order, value: firstOrder?.order });
      setLogsData(firstOrder?.logs || []);
    }
  }, [props?.PkgList]);

  useEffect(() => {
    console.log('Selected Package:', selectedPackageForLogs?.value);
    console.log('Package List:', props?.PkgList);

    let data = props?.PkgList?.find((g: any) => {
      if (g.order === selectedPackageForLogs.value) {
        setLogsData(g?.logs);  // Updating logsData state
        console.log('Found Order:', g.order);
        console.log('Logs:', g?.logs);
      }
    });

    console.log('Data:', data);  // Logs if any matching package is found

  }, [selectedPackageForLogs, props?.PkgList]);  // Added props.PkgList to dependency array

  var iconsClassName = `text-primary-400 fs-link flex-shrink-0 mt-4`;
  const [isDeliveryImage, setIsDeliveryImage] = useState(false);
  const [showRemarkImages, setShowRemarkImages] = useState(false);
  const [remarkImage, setRemarkImage] = useState('');

  const handleOrderNumberChange = (e: any) => {
    setSelectedPackageForLogs(e); // Keep existing logic
  };
  let currentDate: any = "";

  const handleSetDate = (data: any) => {
    currentDate = data;
    return <div className='max-content flex ai-center gap-4 bg-primary-50 text-primary-900 p-10 br-4 fs-link'><CalendarIcon />{data}</div>;
  };


  return (
    <>
      <div>
        <PaperCard className='mt-1 none-if-empty'>
          <Select
            label="Order Number"
            placeholder="Select Order Number"
            onChange={handleOrderNumberChange}
            value={selectedPackageForLogs}
            options={props?.PkgList?.map((x: any) => { return { label: x?.order, value: x?.order } })}
            styles={{
              menu: (base) => ({ ...base, zIndex: 10000000000000 }),  // High z-index for dropdown
              menuList: (base) => ({ ...base, zIndex: 10000000000000 }),  // High z-index for dropdown
            }}
            // menuPortalTarget={document.body}  // Render outside dialog
            menuPosition="fixed"
          />
          {logsData?.length > 0 ?
            <div className="mt-1">
              {logsData?.map((item: any) => {
                return (
                  <div className="grid gap-4">
                    <div>
                      {formatDate(item?.trackingTime) !== currentDate
                        ? handleSetDate(formatDate(item?.trackingTime))
                        : ""}
                    </div>
                    <PaperCard>
                      <div className="flex gap-1 flex-wrap fs-regular">
                        <div className="flex ai-center gap-4" style={{ minWidth:  mobileView ? 'fit-content': '10rem', maxWidth: '10rem' }}>
                          <HoursIcon className={`${iconsClassName} mt-0`} />{setTimeFormat(item?.trackingTime)}
                        </div>
                        <div className="flex ai-center gap-4 flex-wrap" style={{ minWidth: mobileView ? 'fit-content':'20rem', maxWidth: '20rem', wordWrap: 'break-word' }}>
                          <TimelineIcon className={`${iconsClassName}  mt-0`} />{item?.action && item?.action.toUpperCase()}{" "}
                          {item?.isQrScan && (
                            <QRCodeIcon
                              className={`${iconsClassName}  mt-0`}
                            />
                          )}
                          {(item?.action === "loading" || item?.action === "unloading") && `${item?.branch?.branchName ? `( ${item?.branch?.branchName} )` : ''}`}
                        </div>
                        <div className="flex ai-center gap-4" style={{ minWidth:  mobileView ? 'fit-content' : '17rem', maxWidth: '17rem', wordWrap: 'break-word' }}>
                          <LocationIcon className={`${iconsClassName}  mt-0`} /> at {(item?.action === "loading" || item?.action === "inGstCustody") ? item?.regNumber : (item?.branch?.branchName ? item?.branch?.branchName : "No Branch Found")}
                        </div>
                        <div className="flex ai-center gap-4" style={{ minWidth: mobileView ? 'fit-content':  '17rem', maxWidth: '17rem', wordWrap: 'break-word' }}>
                          <UserIcon className={`${iconsClassName}  mt-0`} />{item?.user?.name ? `${item?.user?.name?.fName} ${item?.user?.name?.lName}` : "No Name Found"}
                        </div>
                        {props.isResolveIssue &&
                          <div className="flex-center gap-4">
                            <Tooltip
                              title={
                                <div style={{ fontSize: "14px" }}>Complaint Verification</div>
                              }
                            >
                              <InfoIcon className={`${iconsClassName}  mt-0`} />
                            </Tooltip>
                          </div>}
                        {item?.action === "delivered" && <div>
                          <Button onClick={() => console.log(`NOPOD`)}>
                            Attachments
                          </Button>
                        </div>}
                        <div
                          onClick={() => {
                            if (item?.remarkImage && item?.remarkImage?.length > 20) {
                              setRemarkImage(item.remarkImage), setShowRemarkImages(true)
                            }else {
                              showHttpError(`No Images Found`)
                            }
                            if (item.action === "delivery") {
                              setRemarkImage(props?.customerTrackingData?.orderData?.docket?.receiverPhoto),
                                setShowRemarkImages(true)
                              setIsDeliveryImage(true)
                            }
                           
                          }}
                          className={`flex-center gap-4 pointer`}
                        >
                          <GalleryIcon className={`fs-link ${item?.remarkImage && item?.remarkImage?.length > 20 ? `text-primary-400` :
                            item.action === "delivery" && props?.customerTrackingData?.orderData?.docket?.receiverPhoto?.length > 20 ? `text-primary-400` :
                              `text-black-400`}`
                          } />
                          <p className={`${item?.remarkImage && item?.remarkImage?.length > 20 ? `text-primary-400` :
                            item.action === "delivery" && props?.customerTrackingData?.orderData?.docket?.receiverPhoto?.length > 20 ? `text-primary-400` :
                              `text-black-400`
                            }`}>
                            Image
                          </p>
                        </div>
                      </div>
                      {item?.action === "booking" && props?.BranchDetails?.docket?.remarks !== '' &&
                        <div className="track_remark_text">Remarks : {props?.BranchDetails?.docket?.remarks}</div>
                      }
                      {item?.action === "delivery" && props?.BranchDetails?.docket?.deliveryRemarks !== '' &&
                        <div className="track_remark_text">Remarks : {props?.BranchDetails?.docket?.deliveryRemarks}</div>
                      }
                      {item?.remarks && item?.remarks !== "" &&
                        <div className="track_remark_text">Remarks : {item?.remarks}</div>
                      }
                    </PaperCard>
                  </div>
                );
              })}
            </div>
            :
            <div>
              <div className="heading">Select Any one Package Order Number</div>
              <NoDataFound />
            </div>
          }
        </PaperCard>

      </div>
      <Dialog 
        state={showRemarkImages}
        header={`${isDeliveryImage ? 'Delivery Person Image' : "Remark Image"}`}
        size={`${isDeliveryImage ? 'small' : 'large'}`} 
        body={<MyImage objectID={remarkImage} pagination={false} />}
        footer={<CloseButton onClick={()=>setShowRemarkImages(false)}/>} 
        />
    </>
  );
};


export default SingleStatus;