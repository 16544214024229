import React from 'react'
import { PageHeader } from "../../../../components/UI";
import { termsAndConditionsUserLogin } from "../../../../constants/data";

const TermsAndConditionsUserLogin = () => {
    const disableCopy = (e: any) => {
        e.preventDefault();
    };

    return (
        <div className="page-container-grid no-copy" onCopy={disableCopy} onContextMenu={disableCopy} onSelectCapture={disableCopy}>
            <PageHeader title="Terms & Conditions For Services" imgSrc="terms-and-conditions/bg.jpg" />
            <section className="p-3 my-container flex-center">
                <ul className="terms-and-conditions-list flex-col gap-1" style={{ listStyle: "disc" }}>
                    {termsAndConditionsUserLogin.length > 0 && termsAndConditionsUserLogin.map(item => <li key={item} className="fs-medium fw-400" style={{ maxWidth: "65ch" }}>{item}</li>)}
                </ul>
            </section>
        </div>
    )
}

export default TermsAndConditionsUserLogin;