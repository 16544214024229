// react
import React from "react";
// context
import { useLandingPageContext } from "../../../../hooks/useLandingPageContext";
// constants (data | icon)
import { ourFeatures, ourFeaturesData } from "./data";
// components
import { Heading, Icon } from "../../../../components/UI";
import { LockIcon } from "../../../../constants/icons";

// FC: Features Component
const Features = () => {
  // state
  const {
    activePage,
    activeService,
    activePartner,
    activeExpressParcelDeliveryType,
  } = useLandingPageContext();
  // data
  const data = ourFeaturesData.find((ourFeature) => {
    if (ourFeature.page === activePage) {
      if (ourFeature.service === "Intercity Express Parcel") {
        return ourFeature.expressParcelType === activeExpressParcelDeliveryType;
      }
      if (ourFeature.service) {
        return ourFeature.service === activeService;
      }
      if (ourFeature.partner) {
        return ourFeature.partner === activePartner;
      }
    }
    return false;
  })?.features || ourFeatures;

  // return component
  return (
    <main className="features | p-3 bg-primary-400">
      <section className="| flex-col gap-component my-container">
        <Heading className="text-white-900">Features</Heading>
        <ul className="features-grid | gap-2 m-0 my-container">
          {data &&
            Array.isArray(data) &&
            data.length > 0 &&
            data?.map((item) => {
              const { heading, description, icon, isLock } = item;
              return (
                <li
                  key={heading}
                  className={`our-feature-item | py-1 px-20 br-4 bg-primary-500 relative flex-col ai-center gap-8 relative pointer shadow-small`}
                >
                  {/* <span className="| aspect-ratio-1 circle p-1 bg-primary-600 text-white-900 fs-icon text-white-900 flex-center">{ourFeaturesIcon[index]}</span> */}
                  <span className="| aspect-ratio-1 circle p-1 bg-primary-600 text-white-900 fs-icon text-white-900 flex-center">
                    <Icon Icon={icon} />
                  </span>
                  <h1
                    className="| fw-600 text-white-800 text-center fs-list-item-heading line-height-100 flex ai-baseline gap-4"
                    style={{ margin: 0 }}
                  >
                    {heading}{" "}
                    {isLock && <LockIcon className="text-primary-700" />}
                  </h1>
                  <p
                    className="| fw-400 text-white-700 text-center fs-list-item-text text-justify"
                    style={{ margin: 0 }}
                  >
                    {description}
                  </p>
                </li>
              );
            })}
        </ul>
      </section>
    </main>
  );
};

export default Features;
