// react
import React from "react";
// ui components
import { Button, Dialog, Input } from "../../../components/UI";
// constants (icons)
import { BackIcon, GetDetailsIcon } from "../../../constants/icons";
// custom state
import { useDirectSalesAgentForm } from "./useCustomState";
// utils
import {
  validateAddress1,
  validateAddress2,
  validateCity,
  validateContactNumber,
  validateEmail,
  validateFirstName,
  validateLastName,
  validatePassword,
  validatePincode,
} from "../../../utils/validation";
import showMessage, { showHttpError, success } from "../../../../utils/message";
// services
import {
  getOtp,
  individualData,
  verifyOtpForReg,
} from "../../../../services/area";
import { getCityByPincode } from "../../../../services/area";
// component
import { OTPInput } from "./OTPInput";
import { resetState } from "../../../utils/helper";
import { OtpTypes } from "../../../../utils/otp";
import AlreadyHaveAnAccount from "./AlreadyHaveAnAccount";

// FC: DirectSalesAgentForm Component
const DirectSalesAgentForm = () => {
  // State
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    password,
    setPassword,
    contactNumber,
    setContactNumber,
    otp,
    setOtp,
    addressLine1,
    setAddressLine1,
    addressLine2,
    setAddressLine2,
    pincode,
    setPincode,
    city,
    setCity,
    isOtp,
    setIsOtp,
    currentPage,
    setCurrentPage,
  } = useDirectSalesAgentForm();

  // does: fetch city by pin
  const fetchCityByPin = async () => {
    try {
      if (typeof pincode !== "object" || typeof city !== "object") return;
      const response = await getCityByPincode(pincode.value);
      // setCityId(response?._id)
      return [city.value == response?.name, response?._id];
    } catch (error) {
      showHttpError(error);
    }
  };
  // does: get the otp
  async function handleGetOtp(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    let flag =
      validateFirstName(firstName, setFirstName) &&
      validateLastName(lastName, setLastName) &&
      validateEmail(email, setEmail) &&
      validatePassword(password, setPassword) &&
      validateContactNumber(contactNumber, setContactNumber);
    if (
      typeof firstName === "string" ||
      typeof lastName === "string" ||
      typeof email === "string" ||
      typeof password === "string" ||
      typeof contactNumber === "string"
    )
      return;
    if (
      !contactNumber.value ||
      !firstName.value ||
      !lastName.value ||
      !email.value ||
      !password.value
    ) {
      alert("Please provie all fields!");
      return;
    }
    if (
      contactNumber.error ||
      firstName.error ||
      lastName.error ||
      email.error ||
      password.error
    ) {
      if (!flag) {
        return;
      }
      alert("Please provide all field correctly!");
      return;
    }
    if (!flag) {
      return;
    }
    setIsOtp(true);
    if (typeof contactNumber !== "object") return;
    await getOtp({
      contact: +contactNumber.value,
      otpType: OtpTypes.IndividualVerification
    });
    showMessage("Otp sent successfully!", success, 2000);
  }
  // does: handle the OTP
  async function handleOtp() {
    if (typeof otp !== "object") return;
    if (!otp.value) {
      alert("Please enter OTP");
      return;
    } else if (otp.error) {
      alert(otp.errorMessage);
      return;
    } else {
      if (typeof contactNumber !== "object") return;
      const payload = { contact: +(contactNumber.value), otp: +(otp.value), otpType: OtpTypes.IndividualVerification };
      const response: any = await verifyOtpForReg(payload);
      if (response) {
        showMessage("Otp verfied successfully!", success, 2000);
        setCurrentPage(2);
        setIsOtp(false);
      } else {
        showHttpError("Otp MissMatched , Kindly Resend Otp Or Enter Valid Otp");
      }
    }
    // setCurrentPage(2);
  }
  // does: handle the form submit
  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (
      typeof firstName !== "object" ||
      typeof lastName !== "object" ||
      typeof email !== "object" ||
      typeof password !== "object" ||
      typeof contactNumber !== "object" ||
      typeof addressLine1 !== "object" ||
      typeof addressLine2 !== "object" ||
      typeof pincode !== "object" ||
      typeof city !== "object" ||
      typeof otp !== "object"
    )
      return;
    try {
      let res: any = await fetchCityByPin();
      let payload: any = {
        contactPerson: {
          name: {
            fName: firstName.value,
            lName: lastName.value,
          },
          contact: contactNumber.value,
          password: password.value,
          email: email.value,
        },
        address: {
          l1: addressLine1.value,
          l2: addressLine2.value,
          pincode: pincode.value,
          city: res[1],
        },
        otp: otp.value,
        individual: true,
      };
      await individualData(payload);
      showMessage("Direct Sales Agent Registered Successfully", success, 2000);
      setCurrentPage(1);
    } catch (error) {
      showHttpError(error);
    }
    resetState(
      setFirstName,
      setLastName,
      setContactNumber,
      setEmail,
      setAddressLine1,
      setAddressLine2,
      setPincode,
      setOtp
    );
  }

  // component return
  // General Details Form
  if (currentPage === 1) {
    return (
      <>
        {/* Dialog for OTP */}
        <Dialog
          header={<>Enter your OTP</>}
          body={
            <>
              <OTPInput otp={otp} setOtp={setOtp} />
            </>
          }
          footer={
            <>
              <Button variant="primary" action="secondary" onClick={handleOtp}>
                Enter
              </Button>
            </>
          }
          state={isOtp}
          setState={setIsOtp}
          variant="primary"
          size="small"
          isOutsideClickOff={false}
        />
        {/* Form for Direct Sales Agent */}
        <form
          className="| flex-col gap-1 m-gap-20 w-100"
          onSubmit={handleGetOtp}
        >
          <div className="| flex-between fs-link text-white-400 fw-700">
            <span>General Details</span>
            <span>1/2</span>
          </div>
          <div className="| two-column gap-8 t-one-column m-gap-20">
            {/* First Name */}
            <Input
              title="First Name"
              variant="white"
              weight={400}
              width="1px"
              state={{ value: firstName, setValue: setFirstName }}
              onBlur={() => validateFirstName(firstName, setFirstName)}
              warningWeight={400}
              required
            />
            {/* Last Name */}
            <Input
              title="Last Name"
              variant="white"
              weight={400}
              width="1px"
              state={{ value: lastName, setValue: setLastName }}
              onBlur={() => validateLastName(lastName, setLastName)}
              warningWeight={400}
              required
            />
          </div>
          {/* Email */}
          <Input
            title="Email"
            type="email"
            variant="white"
            weight={400}
            width="1px"
            state={{ value: email, setValue: setEmail }}
            onBlur={() => validateEmail(email, setEmail)}
            warningWeight={400}
            required
          />
          {/* Password */}
          <Input
            title="Password"
            type="password"
            variant="white"
            weight={400}
            width="1px"
            state={{ value: password, setValue: setPassword }}
            onBlur={() => validatePassword(password, setPassword)}
            warningWeight={400}
            required
          />
          {/* Contact Number */}
          <Input
            title="Contact Number"
            variant="white"
            weight={400}
            width="1px"
            state={{ value: contactNumber, setValue: setContactNumber }}
            onBlur={() => validateContactNumber(contactNumber, setContactNumber)}
            warningWeight={400}
            required
            type="tel"
            maxLength={10}
          />
          {/* Get OTP Button */}
          <Button
            variant="secondary"
            action="secondary"
            Icon={<GetDetailsIcon />}
            className="| as-start fs-button my-1"
          >
            Get OTP
          </Button>
        </form>
        <AlreadyHaveAnAccount from={'Transport Company'} />
      </>
    );
  }
  // Address Details Form
  else {
    return (
      <>
        <form className="| flex-col gap-1 w-100" onSubmit={handleSubmit}>
          <div className="| flex-between fs-link text-white-400 fw-900">
            <span>Address Details</span>
            <span>2/2</span>
          </div>
          {/* Address Line 1 */}
          <Input
            title="Address Line 1"
            variant="primary"
            weight={400}
            width="1px"
            state={{ value: addressLine1, setValue: setAddressLine1 }}
            onBlur={() => validateAddress1(addressLine1, setAddressLine1)}
            warningWeight={400}
            required
          />
          {/* Address Line 2 */}
          <Input
            title="Address Line 2"
            variant="primary"
            weight={400}
            width="1px"
            state={{ value: addressLine2, setValue: setAddressLine2 }}
            onBlur={() => validateAddress2(addressLine2, setAddressLine2)}
            warningWeight={400}
            required
          />
          <div className="| two-column gap-8 t-one-column">
            {/* Pincode */}
            <Input
              title="Pincode"
              type="number"
              variant="primary"
              weight={400}
              width="1px"
              state={{ value: pincode, setValue: setPincode }}
              onBlur={() => validatePincode(pincode, setPincode)}
              warningWeight={400}
              required
            />
            {/* City */}
            <Input
              title="City"
              variant="primary"
              weight={400}
              width="1px"
              state={{ value: city, setValue: setCity }}
              onBlur={() => validateCity(city, setCity)}
              warningWeight={400}
              required
            />
          </div>
          <div className="| flex jc-end ai-center gap-1">
            {/* Previous Button */}
            <Button
              variant="primary"
              action="primary"
              type="button"
              Icon={<BackIcon />}
              className="| fs-button"
              onClick={() => setCurrentPage(1)}
            >
              Previous
            </Button>
            {/* Submit Button */}
            <Button
              variant="primary"
              action="secondary"
              Icon={<GetDetailsIcon />}
              className="| fs-button"
            >
              Submit
            </Button>
          </div>
        </form>
        <AlreadyHaveAnAccount from={'Transport Company'} />
      </>
    );
  }
};

export default DirectSalesAgentForm;
