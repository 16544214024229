import React  from 'react'
import './loader.css';
import Lottie from 'lottie-react';
import logoData from "./logo.json";

const Loader = () => {
  return (
    <section className="my-app-loader" style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}>
      <img src="/newAssets/logo/logo.png" alt="Logo" style={{width: "100%", maxWidth: "400px"}}  />
      <section className="my-app-loader-section" style={{ height: "250px", maxWidth: "400px" }}>
        <Lottie animationData={logoData} loop={true}/>
      </section>
    </section>
  )
}

export default Loader