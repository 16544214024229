import React from 'react'
import { connect } from 'react-redux'
import formatDate from "../../../services/FormatDate";
import setTimeFormat from "../../../services/FormatTime";
import { HoursIcon, InfoIcon, VehicleIcon } from "../../../newSrc/constants/icons"
import { PaperCard } from "../../../newSrc/components/UI";
import { useWidth } from "../../../newSrc/hooks/useMobile";

export const AddressComponent = (props: any) => {
    const {mobileView} =useWidth()
    const meterTOkmChange = (meter: any) => {
        return (meter / 1000).toFixed(2)
    }

    const secondTominConvert = (time: any, index: any) => {
        let t = new Date();
        t.setSeconds(t.getSeconds() + time + index * 30 * 60);
        return `${formatDate(t)}- ${setTimeFormat(t)}`
    }

    return (
        <PaperCard className="mt-8">
            <div className="fs-link fw-600 flex ai-center gap-1">
                <p className="flex-center p-1 bg-primary white circle" style={{ width: "25px", height: "25px" }}>{props?.iData + 1}</p>
                <p style={{width:'90%'}}>{props?.eData?.placeId?.name}</p>
                <InfoIcon  style={{ width: "20px", height: "20px" }}
                    className="fs-h5 text-primary-400 pointer" 
                    onClick={() => {
                        props?.setAdressPickUpInfo(props?.iData + 1),
                        props?.setAdressPackageData({ serviceType: props?.eData.placeType, placeId: props?.eData.placeId?.placeId })
                    }} 
                />
            </div>
            <div className="flex-col gap-4 fs-regular m-fs-link mt-1" style={{paddingLeft:mobileView ? '' : '4.5rem'}}>
                <div className="flex ai-center gap-4"><VehicleIcon className="flex-shrink-0 fs-h5 m-fs-h4 text-primary-400" />{"  "}{meterTOkmChange(props?.eData?.distance)} km away from {props?.iData == 0 ? "Driver" : "Previous Location"}
                </div>
                <div className="flex ai-center gap-4"><HoursIcon className="flex-shrink-0 fs-h5 m-fs-h4 text-primary-400" /> {" "}
                    {secondTominConvert(props?.eData.duration, props?.iData)}
                </div>
            </div>
        </PaperCard >   
    )
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AddressComponent)