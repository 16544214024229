import React from 'react'
import { connect } from 'react-redux'

export const ComplaintButtons = (props: any) => {
    return (
        <div className="btnsForComplaints w-auto flex-grow-1 gap-1 jc-between flex-wrap">
            <button onClick={() => props?.setIsDialogOpenFunc(1)}>Missing</button>
            <button onClick={() => props?.setIsDialogOpenFunc(2)}>Pilferage</button>
            <button onClick={() => props?.setIsDialogOpenFunc(3)}>Damage</button>
            <button onClick={() => props?.setIsDialogOpenFunc(4)}>MisBehave</button>
            <button onClick={() => props?.setIsDialogOpenFunc(5)}>Late Delivery</button>
            {props?.numbers === 6 &&
                <button onClick={() => props?.setIsDialogOpenFunc(6)}>Others</button>
            }
        </div>
    )
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ComplaintButtons)