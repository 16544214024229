import { FormikErrors, FormikTouched } from "formik";
import {
  BookingValueType,
  EwayBillPartLoadFullLoadType,
  InitialValuesLocationType,
  PackagesTypeIntraCity,
  RegEXType,
} from "../types/booking.type";
import showMessage, { showHttpError } from "../../../../../../utils/message";
import { toastMessage } from "../../../../../../Components/showMessages/toastMessage";

export const dummyLocation: InitialValuesLocationType = {
  contactPerson: {
    contact: null,
    gstin: "",
    isSelfSelected: false,
    name: "",
    senderType: null,
  },
  id: 0,
  isSelfSelected: false,
  isSubmited: false,
  // packages: [
  //   {
  //     qty: 0,
  //     codAmount: 0,
  //     orderNumber: "",
  //     materialType: "",
  //   },
  // ],
  place: {
    searchLocation: undefined,
    l1: "",
    l2: "",
    locationRemark: "",
    locationType: "",
  },
};

export const dummyLocationTouched: FormikTouched<InitialValuesLocationType> = {
  contactPerson: {
    contact: false,
    gstin: false,
    isSelfSelected: false,
    name: false,
  },
  id: false,
  isSubmited: false,
  // packages: [],
  place: {
    searchLocation: false,
    l1: false,
    l2: false,
  },
};

export const dummyPackageTouched: FormikTouched<PackagesTypeIntraCity> = {
  goodsValue: false,
  materialType: false,
  orderNumber: false,
  itemName: false,
  qty: false,
};

export const dummyPackages: PackagesTypeIntraCity = {
  id: 0,
  goodsValue: 0,
  materialType: "",
  orderNumber: "",
  qty: 0,
  itemName: "",
  toPlaceRef: null,
  fromPlaceRef: null,
  isSelected: false,
  isValid: false,
  rAddressId: undefined,
  sAddressId: undefined,
  weight : 0,
};

export const dummyEwayBill: EwayBillPartLoadFullLoadType = {
  eWaybillNo : "",
  goodsValue1 : "",
  invoiceNo : "",
  index : 0
};

export const isObjectError = (
  error: any
): error is FormikErrors<InitialValuesLocationType> => {
  return typeof error === "object" && error !== null;
};

export const isObjectErrorEwayBill = (
  error: any
): error is FormikErrors<EwayBillPartLoadFullLoadType> => {
  return typeof error === "object" && error !== null;
};

export const isObjectPackageError = (
  error: any
): error is FormikErrors<PackagesTypeIntraCity> => {
  return typeof error === "object" && error !== null;
};

export const regEX: RegEXType = {
  CONTACT_REGEX: /^[0-9]{10}$/,
  GST_NUMBER_REGEX: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9]{1}$/,
};

export const ObjectNormlizer = <T>(object: T): T => {
  return JSON.parse(JSON.stringify(object));
};

export const multipleExist = (arr: any, values: any) => {
  return values.every((value: any) => {
    return arr.includes(value);
  });
};

export const calculateGross = (
  distance: string | number,
  price: string | number
) => {
  const distance1 = (Number(distance) / 1000).toFixed(2);
  let calculatedPrice = (Number(distance1) * Number(price)).toFixed(0);

  return Number(calculatedPrice);
};

export const processPayload = (
  pickupDropLocation: any[],
  packages: any[],
  city: string,
  gross: number,
  bookingType?: {
    label: string;
    value: BookingValueType;
  },
  assetType?: {
    assetType: string;
    assetSubType: string;
  }
) => {
  let errorMsg = "";

  try {
    // Map over packages and process data
    const newData = packages.map((pkg: any) => {
      // Error handling for missing locations
      if (pkg.fromPlaceRef === null) {
        errorMsg = `${pkg.id}, Order Number: ${pkg.orderNumber} does not have a Pickup Location`;
      }

      if (pkg.toPlaceRef === null) {
        errorMsg = `Drop Location required for Package No: ${pkg.id}, Order Number: ${pkg.orderNumber}`;
      }

      // Extract from and to locations based on the references
      const from = pickupDropLocation[pkg.fromPlaceRef]?.place?.searchLocation;
      const to = pickupDropLocation[pkg.toPlaceRef]?.place?.searchLocation;

      return {
        weight: 2,
        id: pkg.id,
        materialType: pkg?.materialType?.value,
        itemName: bookingType?.value === "B" ? pkg?.itemName : undefined,
        orderNumber: bookingType?.value === "C" ? pkg?.orderNumber : undefined,
        goods: bookingType?.value !== "B" ? pkg?.goodsValue : undefined,
        qty: pkg.qty || 1, // Set a default value of 1 for qty if it's not present
        codAmount: pkg.codAmount,
        customerPkgIdentifier: String(pkg.id + 1),
        fromPlace: {
          ...from?.place,
        },
        toPlace: {
          ...to?.place,
        },
        sender: {
          name: pickupDropLocation[pkg.fromPlaceRef]?.contactPerson?.name,
          contact: pickupDropLocation[pkg.fromPlaceRef]?.contactPerson?.contact?.value,
        },
        receiver: {
          name: pickupDropLocation[pkg.toPlaceRef]?.contactPerson?.name,
          contact: pickupDropLocation[pkg.toPlaceRef]?.contactPerson?.contact?.value,
        },
        sAddress: {
          pincode: from?.place?.pincode || "",
          city: from?.place?.city || "",
          placeId: from?.place?.place_id,
          l1: from?.l1,
          l2: from?.l2,
          locationType: from?.locationType,
          remarks: from?.locationRemark,
        },
        rAddress: {
          pincode: to?.place?.pincode || "",
          city: to?.place?.city || "",
          placeId: to?.place?.place_id,
          l1: to?.l1,
          l2: to?.l2,
          locationType: to?.locationType,
          remarks: to?.locationRemark,
        },
        fromPlaceRemarks: pkg?.fromPlaceRemarks,
        toPlaceRemarks: pkg?.toPlaceRemarks,
        sAddressId: pkg?.sAddressId,
        rAddressId: pkg?.rAddressId,
      };
    });

    // If any error message was set, show an error message and return false
    if (errorMsg) {
      showMessage(errorMsg, "failed");
      toastMessage(errorMsg, {
        theme: "colored",
        type: "error",
      });
      return false;
    }

    // Return the processed payload object
    return {
      type: "IntraCity",
      packages: newData,
      places: newData.map((pkg: any) => ({
        fromPlace: pkg.fromPlace.placeId,
        toPlace: pkg.toPlace.placeId,
      })),
      city: city,
      gross: gross,
      assetType: assetType,
    };
  } catch (err: any) {
    // Handle errors
    showHttpError(err);
  }
};

export const processLocationPayload = (
  pickupDropLocation: any[],
  index: number
) => {
  let errorMsg = "";

  try {
    const from = pickupDropLocation[index]?.place?.searchLocation;

    if (!from) {
      errorMsg = "Location data is missing.";
      throw new Error(errorMsg);
    }

    const areaFrom = from.address_components.find((x: any) =>
      multipleExist(x.types, [
        "sublocality_level_1",
        "sublocality",
        "political",
      ])
    );

    const pincodeFrom = from.address_components.find((x: any) =>
      multipleExist(x.types, ["postal_code"])
    );

    const cityFrom = from.address_components.find(
      (x: any) =>
        x.types.length === 2 &&
        multipleExist(x.types, ["political", "administrative_area_level_3"])
    );

    const stateFrom = from.address_components.find((x: any) =>
      multipleExist(x.types, ["administrative_area_level_1", "political"])
    );

    // Prepare the return object for the processed location
    const processedLocation = {
      placeId: from?.place_id,
      coordinate: {
        latitude: from?.geometry?.location?.lat,
        longitude: from?.geometry?.location?.lng,
      },
      placeValue: from?.formatted_address,
      city: cityFrom?.long_name || "",
      area: areaFrom?.long_name || "",
      name: from?.name,
      pincode: pincodeFrom?.long_name || "",
      formatted_address: from?.formatted_address,
      state: stateFrom?.long_name || "",
    };

    // Assuming you need to extract contact information as well

    return {
      place: processedLocation,
    };
  } catch (err: any) {
    errorMsg =
      err.message || "An error occurred while processing the location payload.";
    showHttpError(err);
    toastMessage(errorMsg, {
      theme: "colored",
      type: "error",
    });
  }
};

export const calculateRemainingAmount = (
  totalAmount: number,
  advancedAmount: number
) => {
  // Ensure the amounts are valid numbers
  if (typeof totalAmount === "number" && typeof advancedAmount === "number") {
    // If advancedAmount is 0, return totalAmount
    if (advancedAmount === 0) {
      return totalAmount;
    }
    // If both amounts are >= 0, return the difference
    if (totalAmount >= 0 && advancedAmount >= 0) {
      return totalAmount - advancedAmount;
    }
  }
  // Default value if conditions are not met
  return 0;
};

export const processPartLoadPayload = (
  pickupDropLocation: any,
  packages: any,
  city: any,
  gross: any,
  assetType? : any,
  purpose? : any,
  eWayBillDetails? : any,
  isMultipleEWayBill? : any,
  payMentModeVal? : any,
  grossVal? : any,
) => {
  let errorMsg = "";
  try {
    let newData = packages.map((x: any) => {
      if (x.fromPlaceRef === null) {
        errorMsg = `${x.id}, Order Number: ${x.orderNumber} Has Not Pickup Location`;
      }
      console.log(gross)
      if (x.toPlaceRef === null) {
        errorMsg = `Drop Location Required For Package No: ${x.id}, Order Number: ${x.orderNumber}`;
      }

      if (x?.qty <= 0) {
        errorMsg = `Quantity must be larger than or equal to 1`;
      }

      if (!x?.weight) {
        errorMsg = `Weight must be larger than or equal to 1`;
      }

      if (x?.weight <= 0) {
        errorMsg = `Weight must be larger than or equal to 1`;
      }

      const from = pickupDropLocation[x.fromPlaceRef]?.place?.searchLocation;
      const to = pickupDropLocation[x.toPlaceRef]?.place?.searchLocation;
      console.log(`eWayBillDetails 20` , eWayBillDetails , `eWayBillDetails 20` , x)

      console.log(from, "from11111111111111111111111");
      console.log(to, "from111111111111111111111112222222222222");

      console.log(x, "asdcahsdhsag");
      return {
        id: x.id,
        materialType: x?.materialType?.value,
        qty: x?.qty,
        weight: x?.weight,
        codAmount: x.codAmount,
        // customerPkgIdentifier: '30',
        fromPlace: {
          placeId: from?.place?.placeId,
          coordinate: {
            latitude: from?.place?.coordinate.latitude,
            longitude: from?.place?.coordinate.longitude,
          },
          placeValue: from?.place?.placeValue,
          city: from?.place?.city,
          area: from?.place?.area,
          name: from?.place?.name,
          pincode: from?.place?.pincode,
          formatted_address: from?.place?.formatted_address,
          state: "Gujarat",
        },
        toPlace: {
          placeId: to?.place?.placeId,
          coordinate: {
            latitude: to?.place?.coordinate.latitude,
            longitude: to?.place?.coordinate.longitude,
          },
          placeValue: to?.place?.placeValue,
          city: to?.place?.city,
          area: to?.place?.area,
          name: to?.place?.name,
          pincode: to?.place?.pincode,
          formatted_address: to?.place?.formatted_address,
          state: "Gujarat",
        },
        sender: {
          name: pickupDropLocation[x.fromPlaceRef]?.contactPerson?.name,
          contact: pickupDropLocation[x.fromPlaceRef]?.contactPerson?.contact?.value,
          senderGst : from?.contactPerson?.gstin,
          // floor: from?.contactPerson?.floor,
          // l1: from?.contactPerson?.l1,
          // l2: from?.contactPerson?.l2,
          // officeName: from?.contactPerson?.officeName,
          // nearby_landmark: from?.contactPerson?.nearby_landmark,
          // pincode: from?.contactPerson?.pincode,
          // city: from?.contactPerson?.city
        },
        receiver: {
          name: pickupDropLocation[x.toPlaceRef]?.contactPerson?.name,
          contact: pickupDropLocation[x.toPlaceRef]?.contactPerson?.contact?.value,
          receiverGst : to?.contactPerson?.gstin,
          // floor: to?.contactPerson?.floor,
          // l1: to?.contactPerson?.l1,
          // l2: to?.contactPerson?.l2,
          // officeName: to?.contactPerson?.officeName,
          // nearby_landmark: to?.contactPerson?.nearby_landmark,
          // pincode: to?.contactPerson?.pincode,
          // city: to?.contactPerson?.city
        },
        sAddress: {
          pincode: from?.place?.pincode,
          city: from?.place?.city,
          placeId: from?.place?.placeId,
          l1: from?.l1,
          l2: from?.l2,
          locationType : from?.locationType
          // officeName: from?.contactPerson?.officeName,
          // floor: from?.contactPerson?.floor,
          // nearby_landmark: from?.contactPerson?.nearby_landmark,
        },
        rAddress: {
          pincode: to?.place?.pincode,
          city: to?.place?.city,
          placeId: to?.place?.placeId,
          l1: to?.l1,
          l2: to?.l2,
          locationType : to?.locationType
          // officeName: to?.contactPerson?.officeName,
          // floor: to?.contactPerson?.floor,
          // nearby_landmark: to?.contactPerson?.nearby_landmark,
        },
        sAddressId: x?.sAddressId,
        rAddressId: x?.rAddressId,
      };
    });

    console.log(newData,"newDataqjwhevjqvheq")

    if (errorMsg) {
      showHttpError(errorMsg)
      return false;
    }

    if(purpose?.value === "P"){
      return {
        type: "IntraCity",
        packages: newData,
        places: newData.map((x: any) => {
          return { fromPlace: x.fromPlace.placeId, toPlace: x.toPlace.placeId };
        }),
        city: city,
        assetType : assetType,
        purpose : purpose?.value,
        goods: eWayBillDetails[0]?.goods,
        paymentMode : payMentModeVal, 
        gross : grossVal,
      };
    }
    if(purpose?.value === "C"){
      return {
        type: "IntraCity",
        packages: newData,
        places: newData.map((x: any) => {
          return { fromPlace: x.fromPlace.placeId, toPlace: x.toPlace.placeId };
        }),
        city: city,
        assetType : assetType,
        purpose : purpose?.value,
        eWayBillInfo : [{
          goods: isMultipleEWayBill ? eWayBillDetails?.map((x : any) => x?.goods) : eWayBillDetails[0]?.goods,
          eWayBill : isMultipleEWayBill ? eWayBillDetails?.map((x : any) => x?.eWaybill) : eWayBillDetails[0]?.eWaybill,
          billNo : isMultipleEWayBill ? eWayBillDetails?.map((x : any) => x?.billNo) : eWayBillDetails[0]?.billNo,
        }],
        goods: isMultipleEWayBill ? eWayBillDetails?.map((x : any) => x?.goods) : eWayBillDetails[0]?.goods,
        paymentMode : payMentModeVal, 
        gross : grossVal,
      };
    }
  } catch (err : any) {
    showHttpError(err);
  }
};

export const setDeep = (obj: any, path: any, value: any) => {
  const pList = path.split(".");
  const key = pList.pop();
  const pointer = pList.reduce((accumulator: any, currentValue: any) => {
    if (accumulator[currentValue] === undefined) accumulator[currentValue] = {};
    return accumulator[currentValue];
  }, obj);
  pointer[key] = value;
  return obj;
};
