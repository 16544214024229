import React, { ReactNode, useEffect, useRef, useState } from "react";
import "../Card/estimatedCard.css";
import { useLandingPageContext } from "../../../../hooks/useLandingPageContext";
import { calculateDuration, getDateMonthType } from "../../../../utils/helper";
import { useMediaQuery, useTheme } from "@mui/material";
import { StateType } from "../../partner-forms/useCustomState";
import { Button, NoDataFound, Select } from "../../../../components/UI";
import { GetDetailsIcon } from "../../../../constants/icons";
import { useAppDispatch } from "../../../../../hooks/useAppDispatch";
import { hideLoader, showLoader } from "../../../../../actions/UserActions";
import {
  createFullLoadLeadexpressParcelLeadBookingGetBranchToBranchPrice,
  sendOTPForLandingForms,
  verifyContact,
} from "../../../../../services/landingPageForms";
import { toastMessage } from "../../../../../Components/showMessages/toastMessage";
import { showHttpError } from "../../../../../utils/message";
import { useNavigate } from "react-router-dom";
import { customerLoginByOTP } from "../../../../../actions/customerLogin";
import { getMaterialList } from "../../../../../services/intraCityRate";

type CardType = {
  index: number;
  innerData: any;
  mobileView?: any;
  standardRate?: any;
  materialTypeList: any[];
  selectedRoute: {
    bordingPoint: any[];
    droppingPoint: any[];
  };
  setSelectedRoute: React.Dispatch<
    React.SetStateAction<{
      bordingPoint: any[];
      droppingPoint: any[];
    }>
  >;
};

type CardBottomType = {
  index: number;
  mobileView: boolean;
  materialTypeList: any[];
  innerRoutes: any;
  ref: any;
  selectedRoute: {
    bordingPoint: any[];
    droppingPoint: any[];
  };
  setSelectedRoute: React.Dispatch<
    React.SetStateAction<{
      bordingPoint: any[];
      droppingPoint: any[];
    }>
  >;
};

type CardListType = {
  index: number;
  selectedRoute: {
    bordingPoint: any[];
    droppingPoint: any[];
  };
  setSelectedRoute: React.Dispatch<
    React.SetStateAction<{
      bordingPoint: any[];
      droppingPoint: any[];
    }>
  >;
  pickUpTimeMinutes: string;
  pickUpTimeHours: string;
  res: any;
  type: "bordingPoint" | "droppingPoint";
};

const BookingForm = ({ innerRoutes, materialTypeList }: CardBottomType) => {
  const [pickUpPoint, setPickUpPoint] = useState<
    { label?: string; value?: string } | undefined
  >();
  const [dropOffPoint, setDropOffPoint] = useState<
    { label?: string; value?: string } | undefined
  >();
  const [materialType, setMaterialType] = useState<
    { label?: string; value?: string } | undefined
  >();
  const [noOfParcel, setNofParcel] = useState<StateType>("");
  const [totalKG, setTotalKG] = useState<StateType>("");
  const [contact, setContact] = useState<StateType>("");
  const [otp, setOTP] = useState<StateType>("");
  const [name, setName] = useState<StateType>("");
  const [isOTPClick, setIsOTPClick] = useState<boolean>(false);
  const [isCustomer, setIsCustomer] = useState<boolean>(false);
  const [isName, setIsName] = useState<boolean>(false);
  const [time, setTime] = useState(30);
  // const [isCustomer, setIsCustomer] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { activeSubService, setActiveSubService } = useLandingPageContext();
  const searchedLabelDate = activeSubService?.data?.date;

  const uniqueDroppigPoints: any[] = [];
  const uniqueInnerRoutes: any[] = [];

  const pickUpOptions = innerRoutes?.map((res: any) => {
    return {
      label: res?.originBranch?.branchName,
      value: res?.originBranch?._id,
    };
  });
  const dropOffOptions: any[] = [];

  console.log(activeSubService, "activeSubService");

  let isVerified =
    contact &&
    contact?.toString()?.length === 10 &&
    pickUpPoint &&
    dropOffPoint &&
    materialType &&
    noOfParcel &&
    totalKG &&
    (isCustomer ? name : true);

  let isVerifiedBooking =
    contact &&
    otp &&
    contact?.toString()?.length === 10 &&
    pickUpPoint &&
    dropOffPoint &&
    materialType &&
    noOfParcel &&
    totalKG &&
    (isCustomer ? name : true);

  innerRoutes?.forEach((item: any) => {
    const destBranchId = item?.destBranch?._id;
    if (!uniqueDroppigPoints.includes(destBranchId)) {
      uniqueDroppigPoints.push(destBranchId);
      uniqueInnerRoutes.push(item);
      dropOffOptions.push({
        label: item?.destBranch?.branchName,
        value: item?.destBranch?._id,
      });
    }
  });

  useEffect(() => {
    if (contact?.toString()?.length === 10) {
      verifyContactApi();
    } else {
      setIsName(false);
      setName("");
    }
  }, [contact?.toString()?.length]);

  const verifyContactApi = async () => {
    dispatch(showLoader());
    const payload: {
      contact: number;
    } = {
      contact: Number(contact),
    };
    // const res = await verifyOTPLandingForms(payload);

    try {
      const res = await verifyContact(payload);

      if (res) {
        setIsName(true);
        setName(res.name);
      }
    } catch (error) {
      showHttpError(error);
      return error;
    } finally {
      dispatch(hideLoader());
    }
  };

  const verifyOTP = async () => {
    dispatch(showLoader());
    let deviceId = localStorage.getItem("deviceId");
    const payload: {
      contact: number;
      OTP: string;
      rememberMe: boolean;
      deviceId?: string;
      name?: string;
    } = {
      contact: Number(contact),
      OTP: otp as string,
      rememberMe: true,
    };

    if (deviceId) {
      payload.deviceId = deviceId;
    }
    if (name) {
      payload.name = name as string;
    }

    try {
      // const res = await verifyOTPLandingForms(payload);
      const res: any = await dispatch(
        customerLoginByOTP(
          Number(contact),
          otp as string,
          true,
          deviceId ? deviceId : undefined,
          name ? (name as string) : ""
        )
      );

      if (res.data?.response?.accessToken && res.data?.response?.customer) {
        console.log(res, "afsfdgasdgacsdgcasgdcas");
        navigate("/customerDashboard/booking");
        setIsOTPClick(false);
        setIsCustomer(false);
        setActiveSubService({
          module: "expressParcelRate",
          isActive: false,
          data: {},
        });
      }
    } catch (error) {
      navigate("/");
      showHttpError(error);
      return error;
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleContactSubmit = async () => {
    dispatch(showLoader());

    const payload = { contact };

    try {
      const res = await verifyContact(payload);

      if (res) {
        const leadPayload = {
          originCity: activeSubService?.data?.b2bSourceCity?.value,
          destCity: activeSubService?.data?.b2bDestinationCity?.value,
          originBranch: dropOffPoint?.value,
          destBranch: pickUpPoint?.value,
          date: searchedLabelDate,
          contact: contact,
          qty: noOfParcel,
          weight: totalKG,
          materialType: materialType?.value,
        };

        setTime(30);
        setIsOTPClick(true);
        await sendOTPForLandingForms(payload);

        await createFullLoadLeadexpressParcelLeadBookingGetBranchToBranchPrice(
          leadPayload
        );

        // dispatch({
        //   type: SET_INTRACITY,
        //   payload: {
        //     what: "isBookedWithoutToken",
        //     val: true,
        //   },
        // });
        // dispatch({
        //   type: SET_INTRACITY,
        //   payload: {
        //     what: "lead",
        //     val: leadPayload,
        //   },
        // });
        // navigate("/login", {
        //   state: {
        //     action: "isBookedWithoutToken",
        //     data: { contact },
        //   },
        // });
        toastMessage("OTP sent successfully", {
          theme: "colored",
          type: "success",
          autoClose: 1000,
        });
        // } else {
      } else {
        // setActivePage("customer");
        await sendOTPForLandingForms(payload);
        setIsCustomer(true);
        toastMessage("Please Register Customer", {
          theme: "colored",
          type: "error",
        });
      }
    } catch (error) {
      showHttpError(error);
      return error;
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    if (time > 0) {
      const intervalId = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(intervalId); // Clear interval on component unmount
    } else {
      setIsOTPClick(false);
    }
  }, [time]);

  return (
    <div className="bookingFormContainer">
      {/* <p>Package Details</p> */}
      <div className="bookingFormTab | m-one-column t-two-column d-two-column five-column gap-1 w-100">
        {/* <form onSubmit={handleSubmit} className="| m-one-column t-two-column d-two-column four-column gap-1 w-100"> */}
        {/* <Select
          isClearable={true}
          isSearchable={true}
          options={[]}
          value={pickUpPoint}
          onChange={(e: any) => setPickUpPoint(e)}
          placeholder={"Select Destination City"}
        /> */}
        {/* <Select isClearable={true} isSearchable={true} options={options} placeholder={"Source Location"} /> */}
        <Select
          isClearable={true}
          isSearchable={true}
          options={pickUpOptions}
          value={pickUpPoint}
          onChange={(e: any) => setPickUpPoint(e)}
          placeholder={"Select Origin Branch"}
        />
        <Select
          isClearable={true}
          isSearchable={true}
          options={dropOffOptions}
          value={dropOffPoint}
          onChange={(e: any) => setDropOffPoint(e)}
          placeholder={"Select Destination Branch"}
        />
        <Select
          isClearable={true}
          isSearchable={true}
          options={materialTypeList}
          value={materialType}
          onChange={(e: any) => setMaterialType(e)}
          placeholder={"Select Material Type"}
        />
        {/* <input type="date" className="date-select" value={selectedDate} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelectedDate(e.target.value)} /> */}
        <input
          type="text"
          placeholder="No Of Parcel"
          className="date-select"
          value={noOfParcel.toString()}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setNofParcel(e.target.value)
          }
        />
        <input
          type="text"
          placeholder="Total KG"
          className="date-select"
          value={totalKG.toString()}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setTotalKG(e.target.value)
          }
        />

        {/* Dialog for display Intra City Rates */}
        {/* </form> */}
      </div>
      <div className="flex-center p-10">
        <div className="devider"></div>
      </div>
      <div className="bookingFormTab | m-one-column t-two-column d-two-column four-column gap-1 w-100">
        {/* <form onSubmit={handleSubmit} className="| m-one-column t-two-column d-two-column four-column gap-1 w-100"> */}
        {/* <SelectContainer label="Contact"> */}
        <input
          type="text"
          placeholder="Contact"
          className="date-select"
          value={contact.toString()}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setContact(e.target.value)
          }
        />
        <input
          type="text"
          placeholder="Name"
          disabled={isName}
          className="date-select"
          value={name.toString()}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setName(e.target.value)
          }
        />
        <div className="flex w-100 gap-10">
          {!isOTPClick ? (
            <Button
              type="submit"
              variant="secondary"
              action="secondary"
              Icon={<GetDetailsIcon />}
              className="as-start fs-button"
              onClick={() => {
                if (isVerified) {
                  handleContactSubmit();
                } else if (contact?.toString()?.length === 10) {
                  showHttpError("contact must be 10 digits");
                } else {
                  showHttpError("All Fields Are Required");
                }
              }}
            >
              Send OTP
            </Button>
          ) : (
            <div className="flex ai-center">
              <p>Resend OTP in {time}</p>
            </div>
          )}
          {/* </SelectContainer> */}
          <input
            type="text  "
            placeholder="OTP"
            className="date-select"
            value={otp.toString()}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setOTP(e.target.value)
            }
          />
        </div>
        <div className="flex jc-end">
          <Button
            type="submit"
            variant="primary"
            action="secondary"
            Icon={<GetDetailsIcon />}
            className="as-start fs-button"
            onClick={() => {
              if (isVerifiedBooking) {
                verifyOTP();
              }
            }}
          >
            Submit
          </Button>
        </div>
        {/* Dialog for display Intra City Rates */}
        {/* </form> */}
      </div>
      {/* <div className="bookingFormTab | m-one-column t-two-column d-two-column one-column gap-1 w-100"> */}
      {/* </div> */}
    </div>
  );
};

const CardList = ({
  index,
  pickUpTimeHours,
  pickUpTimeMinutes,
  res,
  type,
  selectedRoute,
  setSelectedRoute,
}: CardListType) => {
  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (cardRef.current && selectedRoute[type]?.includes({ ...res, index })) {
      cardRef.current.classList.add("activeCheck");
    } else {
      cardRef.current && cardRef.current.classList.remove("activeCheck");
    }
  }, [selectedRoute, res, type]);

  const handleCardClick = () => {
    setSelectedRoute((prevState) => {
      type select = {
        bordingPoint: any[];
        droppingPoint: any[];
      };
      const prev = prevState as select;
      return {
        ...prev,
        bordingPoint: [{ ...res, index }],
      };
    });
  };

  return (
    <div
      ref={cardRef}
      className={`card-list flex ai-center gap-10`}
      onClick={handleCardClick}
    >
      {/* <div>
        <input
          type="checkbox"
          className="card-checkbox"
          checked={selectedRoute[type]?.includes(res)}
          onChange={handleCheckboxChange}
        />
      </div> */}
      <div className="flex ai-center gap-10">
        <p className="fs-regular fw-700">
          {pickUpTimeHours} : {pickUpTimeMinutes}
        </p>
        <p className="fs-regular fw-500">{res?.originBranch?.branchName}</p>
      </div>
    </div>
  );
};

const CardBottom = ({
  innerRoutes,
  mobileView,
  selectedRoute,
  setSelectedRoute,
}: CardBottomType) => {
  const uniqueDroppigPoints: any[] = [];
  const uniqueInnerRoutes: any[] = [];

  const [isActiveTab, setIsActiveTab] = useState<"bording" | "dropping">(
    "bording"
  );
  const refTab1 = useRef<HTMLDivElement | null>(null);
  const refTab2 = useRef<HTMLDivElement | null>(null);

  innerRoutes?.forEach((item: any) => {
    const destBranchId = item?.destBranch?._id;
    if (!uniqueDroppigPoints.includes(destBranchId)) {
      uniqueDroppigPoints.push(destBranchId);
      uniqueInnerRoutes.push(item);
    }
  });

  useEffect(() => {
    if (isActiveTab !== "bording") {
      refTab1?.current?.classList?.add("bordingtab");
      refTab2?.current?.classList?.remove("dropingtab");
    } else {
      refTab1?.current?.classList?.remove("bordingtab");
      refTab2?.current?.classList?.add("dropingtab");
    }
  }, [isActiveTab, mobileView]);

  return (
    <div>
      {mobileView ? (
        <div className="card-bottom-container p-10">
          <div className="flex ai-center jc-around mb-20 h-100">
            <div
              className={`selectTabPCard fs-button fw-700 uppercase ${isActiveTab === "bording" ? "isActiveTab" : ""
                }`}
              onClick={() => setIsActiveTab("bording")}
            >
              Boarding Point
            </div>
            <div
              className={`selectTabPCard fs-button fw-700 uppercase ${isActiveTab === "dropping" ? "isActiveTab" : ""
                }`}
              onClick={() => setIsActiveTab("dropping")}
            >
              Dropping Point
            </div>
          </div>
          <div className="flex ai-center jc-around">
            <div ref={refTab1} className="flex ai-center jc-evenly w-100">
              <div className="inner-module fs-button">
                {innerRoutes
                  ?.slice(0, Math.floor(innerRoutes?.length / 2))
                  .map((res: any, index: number) => {
                    const pickUpTimeHours = getDateMonthType(
                      new Date(res?.pickUpTime),
                      { type: "onlyHours" }
                    );
                    const pickUpTimeMinutes = getDateMonthType(
                      new Date(res?.pickUpTime),
                      { type: "onlyMinutes" }
                    );

                    return (
                      <CardList
                        key={index}
                        index={index}
                        selectedRoute={selectedRoute}
                        setSelectedRoute={setSelectedRoute}
                        pickUpTimeHours={pickUpTimeHours}
                        pickUpTimeMinutes={pickUpTimeMinutes}
                        res={res}
                        type="bordingPoint"
                      />
                    );
                  })}
              </div>
              <div className="inner-module fs-button">
                {innerRoutes
                  ?.slice(Math.floor(innerRoutes?.length / 2))
                  ?.map((res: any, index: number) => {
                    const pickUpTimeHours = getDateMonthType(
                      new Date(res?.pickUpTime),
                      { type: "onlyHours" }
                    );
                    const pickUpTimeMinutes = getDateMonthType(
                      new Date(res?.pickUpTime),
                      { type: "onlyMinutes" }
                    );

                    return (
                      <CardList
                        key={index}
                        index={index}
                        selectedRoute={selectedRoute}
                        setSelectedRoute={setSelectedRoute}
                        pickUpTimeHours={pickUpTimeHours}
                        pickUpTimeMinutes={pickUpTimeMinutes}
                        res={res}
                        type="bordingPoint"
                      />
                    );
                  })}
              </div>
            </div>
            <div className="separator"></div>
            <div ref={refTab2} className="flex ai-center jc-evenly w-100">
              <div className="inner-module fs-button">
                {uniqueInnerRoutes?.map((res: any, index: number) => {
                  const dropTimeHours = getDateMonthType(
                    new Date(res?.dropTime),
                    {
                      type: "onlyHours",
                    }
                  );
                  const dropTimeMinutes = getDateMonthType(
                    new Date(res?.dropTime),
                    { type: "onlyMinutes" }
                  );
                  const dropTimeDay = getDateMonthType(
                    new Date(res?.dropTime),
                    {
                      format: "DD JAN",
                      monthType: "short",
                    }
                  );

                  return (
                    <div
                      key={index}
                      className={`card-list flex ai-center gap-10 ${selectedRoute?.droppingPoint?.includes({
                        ...res,
                        index,
                      })
                        ? "activeCheck"
                        : ""
                        }`}
                      onClick={() => {
                        setSelectedRoute((prevState) => {
                          type select = {
                            bordingPoint: any[];
                            droppingPoint: any[];
                          };
                          const prev = prevState as select;
                          return {
                            ...prev,
                            droppingPoint: [{ ...res, index }],
                          };
                        });
                        // setSelectedRoute({
                        //   bordingPoint: [],
                        //   droppingPoint: [res],
                        // });
                      }}
                    >
                      <div>
                        {/* <input
                        type="checkbox"
                        className="card-checkbox"
                        checked={selectedRoute.droppingPoint.includes({
                          ...res,
                          index,
                        })}
                        onChange={(e) => {
                          e.stopPropagation();
                          setSelectedRoute((prevState) => {
                            type select = {
                              bordingPoint: any[];
                              droppingPoint: any[];
                            };
                            const prev = prevState as select;
                            return {
                              ...prev,
                              droppingPoint: [{ ...res, index }],
                            };
                          });
                          // setSelectedRoute({
                          //   bordingPoint: [],
                          //   droppingPoint: [res],
                          // });
                        }}
                      /> */}
                      </div>
                      <div className="flex ai-center gap-10">
                        <p className="fw-700">
                          {dropTimeHours} : {dropTimeMinutes}
                        </p>
                        <p className="fw-600 fs-small">({dropTimeDay})</p>
                      </div>
                      <p className="fs-regular fw-500">
                        {res?.destBranch?.branchName}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="card-bottom-container p-10">
          <div className="flex ai-center jc-around mb-20 h-100">
            <div className="fs-button fw-700 uppercase">Boarding Point</div>
            <div className="fs-button fw-700 uppercase">Dropping Point</div>
          </div>
          <div className="flex ai-center jc-around">
            <div className="bordingtab flex ai-center jc-evenly w-100">
              <div className="inner-module fs-button">
                {innerRoutes
                  ?.slice(0, Math.floor(innerRoutes?.length / 2))
                  .map((res: any, index: number) => {
                    const pickUpTimeHours = getDateMonthType(
                      new Date(res?.pickUpTime),
                      { type: "onlyHours" }
                    );
                    const pickUpTimeMinutes = getDateMonthType(
                      new Date(res?.pickUpTime),
                      { type: "onlyMinutes" }
                    );

                    return (
                      <CardList
                        key={index}
                        index={index}
                        selectedRoute={selectedRoute}
                        setSelectedRoute={setSelectedRoute}
                        pickUpTimeHours={pickUpTimeHours}
                        pickUpTimeMinutes={pickUpTimeMinutes}
                        res={res}
                        type="bordingPoint"
                      />
                    );
                  })}
              </div>
              <div className="inner-module fs-button">
                {innerRoutes
                  ?.slice(Math.floor(innerRoutes?.length / 2 + 1))
                  ?.map((res: any, index: number) => {
                    const pickUpTimeHours = getDateMonthType(
                      new Date(res?.pickUpTime),
                      { type: "onlyHours" }
                    );
                    const pickUpTimeMinutes = getDateMonthType(
                      new Date(res?.pickUpTime),
                      { type: "onlyMinutes" }
                    );

                    return (
                      <CardList
                        key={index}
                        index={index}
                        selectedRoute={selectedRoute}
                        setSelectedRoute={setSelectedRoute}
                        pickUpTimeHours={pickUpTimeHours}
                        pickUpTimeMinutes={pickUpTimeMinutes}
                        res={res}
                        type="bordingPoint"
                      />
                    );
                  })}
              </div>
            </div>
            <div className="separator"></div>
            <div className="dropingtab flex jc-center w-100">
              <div className="inner-module fs-button">
                {uniqueInnerRoutes?.map((res: any, index: number) => {
                  const dropTimeHours = getDateMonthType(
                    new Date(res?.dropTime),
                    {
                      type: "onlyHours",
                    }
                  );
                  const dropTimeMinutes = getDateMonthType(
                    new Date(res?.dropTime),
                    { type: "onlyMinutes" }
                  );
                  const dropTimeDay = getDateMonthType(
                    new Date(res?.dropTime),
                    {
                      format: "DD JAN",
                      monthType: "short",
                    }
                  );

                  return (
                    <div
                      key={index}
                      className={`card-list flex ai-center gap-10 ${selectedRoute?.droppingPoint?.includes({
                        ...res,
                        index,
                      })
                        ? "activeCheck"
                        : ""
                        }`}
                      onClick={() => {
                        setSelectedRoute((prevState) => {
                          type select = {
                            bordingPoint: any[];
                            droppingPoint: any[];
                          };
                          const prev = prevState as select;
                          return {
                            ...prev,
                            droppingPoint: [{ ...res, index }],
                          };
                        });
                        // setSelectedRoute({
                        //   bordingPoint: [],
                        //   droppingPoint: [res],
                        // });
                      }}
                    >
                      <div className="flex ai-center gap-10">
                        <p className="fw-700">
                          {dropTimeHours} : {dropTimeMinutes}
                        </p>
                        <p className="fw-600 fs-small">({dropTimeDay})</p>
                      </div>
                      <p className="fs-regular fw-500">
                        {res?.destBranch?.branchName}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const Card = ({
  innerData,
  selectedRoute,
  setSelectedRoute,
  index,
  mobileView,
  standardRate,
  materialTypeList
}: CardType) => {
  const [isActive, setIsActive] = useState(false);
  const listRef = useRef<HTMLDivElement | null>(null);
  const bgRef1 = useRef<HTMLDivElement | null>(null);
  const bgRef2 = useRef<HTMLDivElement | null>(null);
  const [modals, setModals] = useState<"booking" | "pickDropPoints">(
    "booking"
  );

  const starthours = getDateMonthType(new Date(innerData?.mainRouteStartTime), {
    type: "onlyHours",
  });
  const startminutes = getDateMonthType(
    new Date(innerData?.mainRouteStartTime),
    { type: "onlyMinutes" }
  );
  const endHours = getDateMonthType(new Date(innerData?.mainRouteEndTime), {
    type: "onlyHours",
  });
  const endMinutes = getDateMonthType(new Date(innerData?.mainRouteEndTime), {
    type: "onlyMinutes",
  });
  const endDay = getDateMonthType(new Date(innerData?.mainRouteEndTime), {
    format: "DD JAN",
    monthType: "short",
  });
  const duration = calculateDuration(
    innerData?.mainRouteStartTime,
    innerData?.mainRouteEndTime
  );

  let component: ReactNode = null;

  switch (modals) {
    case "booking":
      component = (
        <BookingForm
          index={index}
          ref={listRef}
          mobileView={mobileView}
          materialTypeList={materialTypeList}
          innerRoutes={innerData?.pickupAndDropPoints}
          selectedRoute={selectedRoute}
          setSelectedRoute={setSelectedRoute}
        />
      );
      break;
    case "pickDropPoints":
      component = (
        <CardBottom
          index={index}
          ref={listRef}
          materialTypeList={materialTypeList}
          mobileView={mobileView}
          innerRoutes={innerData?.pickupAndDropPoints}
          selectedRoute={selectedRoute}
          setSelectedRoute={setSelectedRoute}
        />
      );
      break;
    default:
      component = <div>coming soon !!!!</div>;
      break;
  }

  useEffect(() => {
    if (modals === "booking") {
      if (bgRef1?.current && bgRef2?.current) {
        bgRef1?.current.classList.remove("active");
        bgRef2?.current.classList.add("active");
      }
    }
    else {
      if (bgRef1?.current && bgRef2?.current) {
        bgRef1?.current.classList.add("active");
        bgRef2?.current.classList.remove("active");
      }
    }
  }, [isActive, modals]);
  return (
    <div ref={listRef} className="card-wrapper">
      {mobileView ?
        <div className={`relative main-container active p-8`}>
          <div className={`m-one-column`}>
            <div className="fs-heading flex-center">
              <p>Kabra Express</p>
            </div>
            <div className="m-two-column flex-evenly mt-10 fs-link">
              <div className="flex-center fw-700">
                {starthours} : {startminutes}
              </div>
              <div className="flex-center" style={{ gap: "3px" }}>
                {duration?.days > 0 && <p className="fw-500">{duration?.days}d</p>}
                <p className="fw-700">{duration?.hours}h</p>
                <p className="fw-700">{duration?.minutes}min</p>
              </div>
            </div>
            <div className="m-two-column flex-evenly mt-10 fs-link">
              <div className=" flex-center fw-600">
                {endHours} : {endMinutes} ({endDay})
              </div>
              <p className=" flex-center fw-500">Price : {standardRate?.price} / Kg</p>
            </div>
          </div>
          <div className="main-card-bottom-items mt-10">
            <div className="card-bottom-items">
              <div className="toggler fs-regular">
                <span
                  ref={bgRef1}
                  className="expand-button-tab fw-600"
                  onClick={() => {
                    if (modals === "booking") {
                      setIsActive(true);
                    } else {
                      setIsActive(!isActive);
                    }
                    setModals("pickDropPoints");
                    if (listRef?.current) {
                      listRef?.current?.classList.toggle("active");
                    }
                  }}
                >
                  Pick Up and Drop Off
                </span>
              </div>
            </div>
            <div className="toggler fs-regular">
              <span
                ref={bgRef2}
                className="expand-button fw-600"
                onClick={() => {
                  setModals("booking");
                  if (modals === "pickDropPoints") {
                    setIsActive(true);
                  } else {
                    setIsActive(!isActive);
                  }
                  if (listRef?.current) {
                    listRef?.current?.classList.toggle("active");
                  }
                }}
              >
                Inquiry
              </span>
            </div>
          </div>
        </div>
        :
        <div className={`relative main-container ${isActive ? "active" : ""}`}>
          <div className={`card-container`}>
            <div className="main-card-heading flex-center">
              <p>Kabra Express</p>
            </div>
            {/* <div className="main-card-col flex-center"> */}
            <div className="flex-center fw-700">
              {starthours} : {startminutes}
            </div>
            <div className="flex-center" style={{ gap: "3px" }}>
              {duration?.days > 0 && <p className="fw-500">{duration?.days}d</p>}
              <p className="fw-700">{duration?.hours}h</p>
              <p className="fw-700">{duration?.minutes}min</p>
            </div>
            <div className=" flex-center fw-600">
              {endHours} : {endMinutes} ({endDay})
            </div>
            <p className=" flex-center fw-500">Price : {standardRate?.price} / Kg</p>
          </div>
          <div className="main-card-bottom-items">
            <div className="card-bottom-items">
              <div className="toggler fs-regular">
                <span
                  ref={bgRef1}
                  className="expand-button-tab fw-600"
                  onClick={() => {
                    if (modals === "booking") {
                      setIsActive(true);
                    } else {
                      setIsActive(!isActive);
                    }
                    setModals("pickDropPoints");
                    if (listRef?.current) {
                      listRef?.current?.classList.toggle("active");
                    }
                  }}
                >
                  Pick Up and Drop Off
                </span>
              </div>
            </div>
            <div className="toggler fs-regular">
              <span
                ref={bgRef2}
                className="expand-button fw-600"
                onClick={() => {
                  setModals("booking");
                  if (modals === "pickDropPoints") {
                    setIsActive(true);
                  } else {
                    setIsActive(!isActive);
                  }
                  if (listRef?.current) {
                    listRef?.current?.classList.toggle("active");
                  }
                }}
              >
                Inquiry
              </span>
            </div>
          </div>
        </div>
      }
      {isActive && component}
    </div>
  );
};

export const EstimatedCard = () => {
  const { activeSubService } = useLandingPageContext();
  const [materialTypeList, setMaterialTypeList] = useState<any>([]);
  const [selectedRoute, setSelectedRoute] = useState<{
    bordingPoint: any[];
    droppingPoint: any[];
  }>({
    bordingPoint: [],
    droppingPoint: [],
  });
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down("lg"));

  console.log(mobileView, "aghsdvhagdvshgasvd");

  const dispatch = useAppDispatch();

  const intracityMaterialTypeList = async () => {
    dispatch(showLoader());
    try {
      let payload: any = { type: "express" };
      let response = await getMaterialList("", payload);
      setMaterialTypeList(
        response?.map((x: any) => {
          return { value: x._id, label: x.name };
        })
      );

      dispatch(hideLoader());
    } catch (error) {
      showHttpError(error);
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    intracityMaterialTypeList();
  }, []);

  return (
    <div>
      {activeSubService?.data?.routes?.length > 0 ? (
        activeSubService?.data?.routes?.map((res: any, index: number) => (
          <Card
            key={index}
            index={index}
            innerData={res}
            selectedRoute={selectedRoute}
            mobileView={mobileView}
            materialTypeList={materialTypeList}
            setSelectedRoute={setSelectedRoute}
            standardRate={activeSubService?.data?.standardRate}
          />
        ))
      ) : (
        <NoDataFound content="No Route Available" />
      )}
    </div>
  );
};
