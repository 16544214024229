import React from 'react'
import { useNavigate } from "react-router-dom";

const AlreadyHaveAnAccount = (from?: any) => {
    const navigate = useNavigate();
    return (
        <p className="| m-0 text-center text-black-100 fs-link m-fs-small fw-500 flex gap-4 ai-center jc-center">
            <span>Already have an account ?</span>
            <span
                className="| text-primary-400 pointer hover-underline"
                onClick={() => navigate("/login", {
                    state: {
                        from: { from },
                    }
                })}
            >
                SignIn Now
            </span>
        </p>
    )
};

export default AlreadyHaveAnAccount;