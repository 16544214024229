// react
import React, { useRef, useState, useEffect } from 'react';
// react-router-dom
import { useNavigate } from "react-router-dom";
// constants (icon)
import { GetDetailsIcon, NetworkIcon, QuickAccessIcon, TransportCompanyIcon } from "../../constants/icons";
// css
import './quick-access.css';
import { useLandingPageContext } from "../../hooks/useLandingPageContext";


// variables
// Define your list items here
const items = [
    { icon: <TransportCompanyIcon />, label: 'About Us', href: "about-us" },
    { icon: <GetDetailsIcon />, label: 'GST Details', href: "gst-details" },
    { icon: <NetworkIcon />, label: 'Our Networks', href: "our-network" }
];


// FC: QuickAccess Component
const QuickAccess = () => {
    // State
    const [isQuickAccessOpen, setIsQuickAccessOpen] = useState(false);
    // from landing page context
    const { isMobileMenuOpen } = useLandingPageContext();
    // Ref
    const contentRef = useRef<HTMLDivElement | null>(null);
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    // useHistory
    const navigate = useNavigate();
    // useLocation
    // const { pathname } = useLocation();


    // useEffect
    // does: whenever user click outside the quick access container it will be off
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                contentRef.current &&
                !contentRef.current.contains(event.target as Node) &&
                buttonRef.current !== event.target
            ) {
                setIsQuickAccessOpen(false);
            }
        }
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    // does: closes quick access container whenever item inside it is clicked
    const handleClick = () => {
        setIsQuickAccessOpen(false);
    };

    // component return
    return (
        <>
            <div ref={contentRef} className={`quick-access-content ${isQuickAccessOpen ? "active" : ""}`}>
                <ul>
                    {items.map((item, index) => (
                        <li key={index} onClick={() => {
                            handleClick();
                            navigate(`/${item.href}`,{replace : true})
                        }}
                        >
                            {item.icon} {item.label}
                        </li>
                    ))}
                </ul>
            </div>
            <button className={`quick-access-btn ${isMobileMenuOpen ? "none" : ""}`} ref={buttonRef} onClick={() => setIsQuickAccessOpen(prev => !prev)}>
                <QuickAccessIcon onClick={(e) => { e.stopPropagation(); setIsQuickAccessOpen(prev => !prev) }} /></button>
        </>
    );
};

export default QuickAccess;
