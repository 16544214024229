import { Dispatch, SetStateAction } from "react";
import {
  StateObjType,
  StateType,
} from "../pages/Landing/partner-forms/useCustomState";
import { getCityByPincode } from "../../services/area";

export function resetState(
  ...setStateArray: Array<Dispatch<SetStateAction<StateType>>>
) {
  setStateArray.forEach((setState) => {
    setState((prevState) => {
      const prev = prevState as StateObjType;
      return { ...prev, value: "" };
    });
  });
}

type Option = {
  value: string;
  label: string;
};

export function getOptions(list: any[], filterRegex: RegExp): Option[] {
  return list
    ?.map((item: any) => ({
      value: item._id,
      label: item.name,
    }))
    ?.filter((item: any) => filterRegex.test(item.label));
}

export function getCityOnPincode(pincode: string, cities: any, setState: any) {
  try {
    let GetCity = async () => {
      let SCity = await getCityByPincode(pincode);
      cities.find((e: any) => {
        if (e._id === SCity._id) {
          setState({ value: e.name, label: e.name });
        }
      });
    };
    GetCity();
  } catch (err: any) {
    console.log(err);
  }
}

// Function to extract unique tags
export function getUniqueTags(tags: any) {
  const uniqueTags = new Set(); // Using Set to ensure uniqueness

  // Iterate through each option and add tag to the set
  tags.forEach((tag: any) => {
    uniqueTags.add(tag);
  });

  return Array.from(uniqueTags); // Convert Set to Array and return
}

export function formatTimeToHHMM(dateString: any) {
  const date = new Date(dateString);

  // Get hours and minutes
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Ensure leading zero for single digit hours and minutes
  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  // Concatenate hours and minutes with a colon to get HH:MM format
  const formattedTime = `${formattedHours}:${formattedMinutes}`;

  return formattedTime;
}

export enum ComplaintStatus {
  O = "O",
  I = "I",
  CG = "CG",
  CP = "CP",
  CU = "CU",
  CC = "CC",
  CO = "CO",
  CS = "CS",
  RE = "RE",
}

const ComplaintStatusDescriptions: { [key in ComplaintStatus]: string } = {
  [ComplaintStatus.O]: "Open",
  [ComplaintStatus.I]: "In progress",
  [ComplaintStatus.CG]: "Compensation Granted",
  [ComplaintStatus.CP]: "Compensation Paid",
  [ComplaintStatus.CU]: "Closed by user",
  [ComplaintStatus.CC]: "Closed by customer",
  [ComplaintStatus.CO]: "Closed by CRON",
  [ComplaintStatus.CS]: "Closed by system",
  [ComplaintStatus.RE]: "Re-opened",
};

export function getComplaintStatus(status: ComplaintStatus): string {
  return ComplaintStatusDescriptions[status];
}

export enum ComplaintType {
  LD = "LD",
  PM = "PM",
  OT = "OT",
  DMG = "DMG",
  PF = "PF",
  MB = "MB",
}

const ComplaintTypeDescriptions: { [key in ComplaintType]: string } = {
  [ComplaintType.LD]: "Late Delivery",
  [ComplaintType.PM]: "Parcel Missing",
  [ComplaintType.OT]: "Other",
  [ComplaintType.DMG]: "Parcel Damaged",
  [ComplaintType.PF]: "Pilferage",
  [ComplaintType.MB]: "Misbehavior",
};

export function getComplaintType(type: ComplaintType): string {
  return ComplaintTypeDescriptions[type];
}

export enum PackageStatus {
  INIT = "INIT",
  B = "B",
  I = "I",
  P = "P",
  W = "W",
  O = "O",
  D = "D",
  MR = "MR",
  M = "M",
  CR = "CR",
  CC = "CC",
  BTH = "BTH",
  UC = "UC",
  BTO = "BTO",
  ND = "ND",
  SOLD = "SOLD",
  DM = "DM",
  PF = "PF",
  BR = "BR",//Booking Request
  CD = "CD",//Cancel By Driver
  CU = "CU",//Cancel By User
}

// Create a mapping of the status codes to their full descriptions
const packageStatusDescriptions: Record<PackageStatus, string> = {
  [PackageStatus.INIT]: "Booked by Individual",
  [PackageStatus.B]: "Booked",
  [PackageStatus.I]: "In Transit",
  [PackageStatus.P]: "Pending Delivery",
  [PackageStatus.W]: "Waiting For Delivery",
  [PackageStatus.O]: "Out For Delivery",
  [PackageStatus.D]: "Delivered",
  [PackageStatus.MR]: "Missing Requested",
  [PackageStatus.M]: "Missing",
  [PackageStatus.CR]: "Cancel Requested",
  [PackageStatus.CC]: "Canceled",
  [PackageStatus.BTH]: "Back To Hub",
  [PackageStatus.UC]: "Unclaimed, Lying at Hub",
  [PackageStatus.BTO]: "Back To Origin",
  [PackageStatus.ND]: "New Destination",
  [PackageStatus.SOLD]: "Unclaimed Package Sold",
  [PackageStatus.DM]: "Damaged Parcel",
  [PackageStatus.PF]: "Pilferage Parcel",
  [PackageStatus.BR]: "Booking Request",
  [PackageStatus.CD]: "Cancel By Driver",
  [PackageStatus.CU]: "Cancel By User",
};

export const branchBookingPackageStatusOptions = [
  { value: "INIT", label: "Booked by Individual" },
  { value: "B", label: "Booked" },
  { value: "I", label: "In Transit" },
  { value: "P", label: "Pending Delivery" },
  { value: "W", label: "Waiting For Delivery" },
  { value: "O", label: "Out For Delivery" },
  { value: "D", label: "Delivered" },
  { value: "MR", label: "Missing Requested" },
  { value: "M", label: "Missing" },
  { value: "CR", label: "Cancel Requested" },
  { value: "CC", label: "Canceled" },
  { value: "BTH", label: "Back To Hub" },
  { value: "UC", label: "Unclaimed, Lying at Hub" },
  { value: "BTO", label: "Back To Origin" },
  { value: "ND", label: "New Destination" },
  { value: "SOLD", label: "Unclaimed Package Sold" },
  { value: "DM", label: "Damaged Parcel" },
  { value: "PF", label: "Pilferage Parcel" },
];

// Function to get the full status description
export function getPackageStatus(status: PackageStatus): string {
  return packageStatusDescriptions[status] || "Unknown Status";
}

type GetDateMonthOptionType = {
  monthType?: "long" | "short";
  format?:
    | "DD.MM.yyyy"
    | "DDMMYYYY"
    | "DD-MM-YYYY"
    | "DD JAN"
    | "DD JAN YEAR"
    | "DD JANUARY YEAR"
    | "DD JANUARY"
    | "YYYY-MM-DD";
  type?:
    | "full"
    | "onlyDate"
    | "onlyMonth"
    | "onlyYear"
    | "onlyMonthShortName"
    | "onlyMonthFullName"
    | "onlyHours"
    | "onlyMinutes";
};

export function getDateMonthType(
  date: string | Date,
  args?: GetDateMonthOptionType
): string {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const monthShortNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Error handling for invalid date strings
  const d = new Date(date);
  if (isNaN(d.getTime())) {
    return "Invalid date";
  }

  const day = d.getDate();
  const monthIndex = d.getMonth();
  const year = d.getFullYear();

  const monthName =
    args?.monthType === "short"
      ? monthShortNames[monthIndex]
      : monthNames[monthIndex];

  // Function to format the date according to the specified format
  const formatDate = (format: string): string => {
    switch (format) {
      case "DD.MM.yyyy":
        return `${String(day).padStart(2, "0")}.${String(
          monthIndex + 1
        ).padStart(2, "0")}.${year}`;
      case "DDMMYYYY":
        return `${String(day).padStart(2, "0")}${String(
          monthIndex + 1
        ).padStart(2, "0")}${year}`;
      case "DD-MM-YYYY":
        return `${String(day).padStart(2, "0")}-${String(
          monthIndex + 1
        ).padStart(2, "0")}-${year}`;
      case "YYYY-MM-DD":
        return `${year}-${String(monthIndex + 1).padStart(2, "0")}-${String(
          day
        ).padStart(2, "0")}`;
      case "DD JAN":
        return `${String(day).padStart(2, "0")} ${monthName}`;
      case "DD JAN YEAR":
        return `${String(day).padStart(2, "0")} ${monthName} ${year}`;
      case "DD JANUARY YEAR":
        return `${String(day).padStart(2, "0")} ${monthName} ${year}`;
      case "DD JANUARY":
        return `${String(day).padStart(2, "0")} ${monthName}`;
      default:
        return d.toString();
    }
  };

  // Return the output based on the specified type
  switch (args?.type) {
    case "full":
      return formatDate(args?.format ? args.format : "");
    case "onlyDate":
      return String(day);
    case "onlyMonth":
      return String(monthIndex + 1);
    case "onlyYear":
      return String(year);
    case "onlyMonthShortName":
      return monthShortNames[monthIndex];
    case "onlyMonthFullName":
      return monthNames[monthIndex];
    case "onlyHours":
      return new Date(formatDate(args?.format ? args.format : ""))
        .getHours()
        ?.toString()?.length < 2
        ? `0${new Date(formatDate(args?.format ? args.format : ""))
            .getHours()
            ?.toString()}`
        : new Date(formatDate(args?.format ? args.format : ""))
            .getHours()
            ?.toString();
    case "onlyMinutes":
      return new Date(formatDate(args?.format ? args.format : ""))
        .getMinutes()
        ?.toString()?.length < 2
        ? `0${new Date(formatDate(args?.format ? args.format : ""))
            .getMinutes()
            ?.toString()}`
        : new Date(formatDate(args?.format ? args.format : ""))
            .getMinutes()
            ?.toString();
    default:
      return formatDate(args?.format ? args.format : "");
  }
}

export function calculateDuration(
  startTime: string | Date,
  endTime: string | Date
) {
  const start = new Date(startTime);
  const end = new Date(endTime);

  const duration = Number(end) - Number(start);

  const millisecondsInSecond = 1000;
  const millisecondsInMinute = millisecondsInSecond * 60;
  const millisecondsInHour = millisecondsInMinute * 60;
  const millisecondsInDay = millisecondsInHour * 24;

  const days = Math.floor(duration / millisecondsInDay);
  const hours = Math.floor((duration % millisecondsInDay) / millisecondsInHour);
  const minutes = Math.floor(
    (duration % millisecondsInHour) / millisecondsInMinute
  );

  return {
    days,
    hours,
    minutes,
  };
}

type FormatCurrentTimeProps = {
  time: Date;
  format: "12 Hour" | "24 Hour";
};
export function formatCurrentTime({ time = new Date(), format = "12 Hour" }: FormatCurrentTimeProps) {
  const now = time;

  let hours = now.getHours();
  let minutes: string | number = now.getMinutes();

  let period = "";

  if (format === "12 Hour") {
    period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert to 12-hour format, ensuring 0 hours is shown as 12
  }

  // Pad single digit minutes with a leading zero
  minutes = minutes < 10 ? "0" + minutes : minutes;

  const timeString = `${hours}:${minutes} ${format === "12 Hour" ? period : ""}`;

  return timeString;
}

export function debounce<T extends (...args: any[]) => void>(func: T, delay: number): T {
  let timeoutId: NodeJS.Timeout;
  return function (this: any, ...args: Parameters<T>) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  } as T;
}

export function throttle<T extends (...args: any[]) => void>(func: T, limit: number): T {
  let inThrottle: boolean;
  return function (this: any, ...args: Parameters<T>) {
    if (!inThrottle) {
      func.apply(this, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  } as T;
}

export const currentYear = new Date().getFullYear();
export const currentMonth = new Date().getMonth() + 1;

export function camelCaseToLabel(str: string) {
  // Insert a space before all capital letters and capitalize the first letter
  return str
    .replace(/([A-Z])/g, " $1") // Add space before uppercase letters
    .replace(/^./, (firstLetter) => firstLetter.toUpperCase()); // Capitalize the first letter
}