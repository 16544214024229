// react
import React from 'react'
// ui components
import { PageHeader } from "../UI"
// constants (data | icon)
import { aboutUs } from "../../constants/data"
import { CustomerIcon, TransporterIcon, VehicleIcon, BranchIcon } from "../../constants/icons"
// css
import './about-us.css';
import '../styles.css';

// variables
const factsIcon = [<CustomerIcon />, <TransporterIcon />, <VehicleIcon />, <BranchIcon />];

// FC: About Us Component
const AboutUs = () => {
  // component
  return (
    <main className="about-us-container page-container-grid | relative">
      {/* About Us Header */}
      <PageHeader title="About Us" imgSrc="about-us/bg.jpg" />
      {/* About Us Information */}
      <div className="| bg-primary-400 p-3 flex-col gap-2 text-white-900" style={{ paddingBlock: "3rem" }}>
        {/* About Us Headline */}
        <p className="| fs-body text-white-900 m-0 mx-auto text-left fw-400 text-justify">{aboutUs.headline}</p>
        {/* About Us Facts */}
        <ul className="| w-100 four-column t-two-column m-one-column gap-2 text-primary-900">
          {aboutUs.facts.length > 0 && aboutUs.facts.map((item, index) => <li key={item.title} className="| fs-medium flex-col-center gap-8 p-1 br-1 bg-primary-500">
            <span className="| fs-icon text-white-900">{factsIcon[index]}</span>
            <span className="| text-white-900 fw-400">{item.number}<strong>{item.title}</strong></span>
          </li>)}
        </ul>
        {/* About Us Products */}
        <ul className="| w-100 two-column m-one-column gap-2 text-primary-900">
          {aboutUs.products.length > 0 && aboutUs.products.map((item) => <li key={item.title} className="fs-medium flex-col gap-8 text-white-900">
            <span className="| fs-subheading fw-600">{item.title}</span>
            <span className="| fs-medium fw-400">{item.description}</span>
          </li>)}
        </ul>
      </div>
    </main >
  )
}

export default AboutUs