import React, { useEffect, useState } from "react";
import "../EstimatedListHeader/header.css";
import { Button, Select } from "../../../../components/UI";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowRightLongIcon,
  CloseIcon,
  EditIcon,
} from "../../../../constants/icons";
import { useExpressParcel } from "../../get-estimate-forms/useCustomeState";
import { getDateMonthType, getOptions } from "../../../../utils/helper";
import { getCityList } from "../../../../../services/area";
import { hideLoader, showLoader } from "../../../../../actions/UserActions";
import { showHttpError } from "../../../../../utils/message";
import { expressParcelBookingGetBranchToBranchPrice } from "../../../../../services/landingPageForms";
import { useLandingPageContext } from "../../../../hooks/useLandingPageContext";
import { useAppDispatch } from "../../../../../hooks/useAppDispatch";
import PageTitle from "../../../../../Components/Title/PageTitle";
import DatePicker from "../../../../../Components/Datecomponents/DatePicker";

const Header = () => {
  const {
    cities,
    setCities,
    b2bSourceCity,
    setB2bSourceCity,
    b2bDestinationCity,
    setB2bDestinationCity,
  } = useExpressParcel();

  const { activeSubService, setActiveSubService } = useLandingPageContext();

  const [date, setDate] = useState("");
  const [isEdit, setIsEdit] = useState<boolean>(false);
  // const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const originCity = activeSubService?.data?.b2bSourceCity;
  const destCity = activeSubService?.data?.b2bDestinationCity;
  const searchedDate = activeSubService?.data?.defaultDate;
  const searchedLabelDate = activeSubService?.data?.date;

  console.log(activeSubService, "b2bSourceCity");

  const cityOptions = getOptions(cities, /^[a-zA-Z]+$/);
  // does: find the cities
  const setUpData = async () => {
    try {
      const [cityList] = await Promise.all([getCityList()]);
      setCities(cityList);
    } catch (err: any) {
      return err;
    }
  };

  useEffect(() => {
    setUpData();
  }, []);

  useEffect(() => {
    setB2bSourceCity(originCity);
    setB2bDestinationCity(destCity);
    setDate(searchedLabelDate);
  }, []);

  // useEffect(() => {
  //   if (new Date(searchedDate).getTime() <= new Date(date).getTime()) {
  //     setIsDisabled(true);
  //   } else {
  //     setIsDisabled(false);
  //   }
  // }, [date]);

  const getEstimatedRateTime = async (dateArgs?: string) => {
    dispatch(showLoader());
    try {
      let payload = {
        originCity: b2bSourceCity.value,
        destCity: b2bDestinationCity.value,
        date: dateArgs ? dateArgs : date,
      };
      console.log(payload, "payload111111111");
      const res = await expressParcelBookingGetBranchToBranchPrice(payload);
      if (res) {
        setActiveSubService({
          module: "expressParcelRate",
          isActive: false,
          data: {
            b2bSourceCity,
            b2bDestinationCity,
            defaultDate: searchedDate,
            date,
            ...res,
          },
        });
        // toastMessage("Get Estimated Rate and Time",{
        //   type: "success",
        //   theme: "colored"
        // });
        setIsEdit(false);
      }
    } catch (err: any) {
      showHttpError(err);
      return err;
    } finally {
      dispatch(hideLoader());
    }
  };

  console.log(date, "date111111111111");

  const handleDateChange = (type?: "inc" | "dec") => {
    if (type === "inc") {
      const newDate = new Date(date);
      newDate.setDate(newDate.getDate() + 1);
      // if(newDate.getTime() < new Date(date).getTime()){
      //   showHttpError("Date not Valid")
      //   return
      // }
      console.log(
        getDateMonthType(newDate, { format: "YYYY-MM-DD" }),
        "newDateeeeeeeeeeeeee",
        newDate.toISOString()
      );
      setDate(getDateMonthType(newDate, { format: "YYYY-MM-DD" }));
      getEstimatedRateTime(getDateMonthType(newDate, { format: "YYYY-MM-DD" }));
    } else if (type === "dec") {
      const newDate = new Date(date);
      newDate.setDate(newDate.getDate() - 1);
      if (newDate.getTime() < new Date(searchedDate).getTime()) {
        showHttpError("Date not Valid");
        return;
      }
      setDate(getDateMonthType(newDate, { format: "YYYY-MM-DD" }));
      getEstimatedRateTime(getDateMonthType(newDate, { format: "YYYY-MM-DD" }));
    } else {
      getEstimatedRateTime(date);
    }
  };

  return (
    <div>
      <PageTitle title="Express Parcel And Estimated Rate" className="my-1" />
      <div className="header-container flex flex-column ai-center jc-center w-100">
        <div className="header2 flex ai-center w-100">
          <div className="headerSub gap-10">
            {!isEdit ? (
              <>
                <p className="fs-button header-font">{originCity?.label}</p>
                <p className="fs-button header-font flex-center">
                  <ArrowRightLongIcon />
                </p>
                <p className="fs-button header-font">{destCity?.label}</p>
                <p className="fs-button header-font flex-center">
                  <ArrowLeftIcon
                    className="pointer"
                    onClick={() => handleDateChange("dec")}
                  />
                </p>
                <p className="fs-button header-font">
                  {getDateMonthType(new Date(date), { format: "DD-MM-YYYY" })}
                </p>
                <p className="fs-button header-font flex-center">
                  <ArrowRightIcon
                    className="pointer"
                    onClick={() => handleDateChange("inc")}
                  />
                </p>
              </>
            ) : (
              <>
                <div className="three-column max-w-1024 gap-1 flex-grow-1 header-filter">
                  <Select
                    isSearchable={true}
                    options={cityOptions}
                    value={b2bSourceCity.value && b2bSourceCity}
                    onChange={(e: any) => setB2bSourceCity(e)}
                    placeholder={"Source City"}
                  />
                  {/* Destination City */}
                  <Select
                    isSearchable={true}
                    value={b2bDestinationCity.value && b2bDestinationCity}
                    onChange={(e: any) => setB2bDestinationCity(e)}
                    options={cityOptions}
                    placeholder={"Destination City"}
                  />
                  <DatePicker
                    selected={date !== '' ? date : new Date()}
                    placeholderText="Select Date"
                    dateFormat="dd-MM-yyyy"
                    name={"pickupDate"}
                    // disabled={(originCity === undefined || destCity === undefined) ? true : false}
                    value={date}
                    className="schedule-your-booking-input py-8 px-1 br-4 border-white react-datepicker-wrapper"
                    minDate={new Date().toISOString().split("T")[0]}
                    onChange={(e: any) => {
                      if (
                        new Date(e.target.value as string).getTime() <
                        new Date(searchedDate).getTime()
                      ) {
                        showHttpError("Date not Valid");
                        return;
                      }
                      setDate(e.target.value);
                    }}
                    fullWidth={true}
                    isClearable={false}
                  />
                </div>
                <div className="flex ai-center gap-10">
                  <Button
                    variant="danger"
                    action="primary"
                    type="button"
                    className="flex-center"
                    onClick={() => setIsEdit(false)}
                  >
                    <CloseIcon />
                  </Button>
                </div>
              </>
            )}
            <Button
              variant="primary"
              action="primary"
              type="button"
              className="edit-btn"
              onClick={() => {
                if (isEdit) {
                  getEstimatedRateTime();
                } else {
                  setIsEdit(true);
                }
              }}
            >
              {!isEdit ? <EditIcon /> : "search"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
