// react
import React from "react";
// custom state
import { type StateObjType, useTransportCompany } from "./useCustomState";
// ui components
import { Button, Dialog, Input, Select } from "../../../components/UI";
// utils (validation)
import {
  validateAddress1,
  validateAddress2,
  validateBranchName,
  validateCity,
  validateCompanyEmail,
  validateCompanyName,
  validateCompanyType,
  validateContactNumber,
  validateEmail,
  validateFirstName,
  validateLastName,
  validatePassword,
  validatePincode,
  validateShortName,
  validateState,
} from "../../../utils/validation";
// constants (icon)
import { BackIcon, GetDetailsIcon } from "../../../constants/icons";
// component
import { OTPInput } from "./OTPInput";
import AlreadyHaveAnAccount from "./AlreadyHaveAnAccount";

// variables
const typesOfCompany = [
  { value: 1, label: "LLC" },
  { value: 2, label: "Pvt Ltd" },
  { value: 3, label: "Sole Partnership" },
  { value: 4, label: "LLP" },
  { value: 5, label: "Public Ltd" },
];

// FC: Transport Company Component
const TransportCompany = () => {
  // State
  const {
    companyName,
    setCompanyName,
    companyType,
    setCompanyType,
    companyEmail,
    setCompanyEmail,
    companyWebsite,
    setCompanyWebsite,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    contactNumber,
    setContactNumber,
    password,
    setPassword,
    addressLine1,
    setAddressLine1,
    addressLine2,
    setAddressLine2,
    city,
    setCity,
    pincode,
    setPincode,
    state,
    setState,
    branchName,
    setBranchName,
    shortName,
    setShortName,
    currentPage,
    setCurrentPage,
    otp,
    setOtp,
    isOtp,
    setIsOtp,
  } = useTransportCompany();

  // does: handle the form submit
  function handleSubmit() {
    alert("Submitted!");
  }
  // does: get the otp
  function getOtp(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    let flag =
      validateFirstName(firstName, setFirstName) &&
      validateLastName(lastName, setLastName) &&
      validateEmail(email, setEmail) &&
      validatePassword(password, setPassword) &&
      validateContactNumber(contactNumber, setContactNumber);
    if (
      typeof firstName === "string" ||
      typeof lastName === "string" ||
      typeof email === "string" ||
      typeof password === "string" ||
      typeof contactNumber === "string"
    )
      return;
    if (
      !contactNumber.value ||
      !firstName.value ||
      !lastName.value ||
      !email.value ||
      !password.value
    ) {
      alert("Please provide all fields!");
      return;
    }
    if (
      contactNumber.error ||
      firstName.error ||
      lastName.error ||
      email.error ||
      password.error
    ) {
      if (!flag) {
        return;
      }
      alert("Please provide all field correctly!");
      return;
    }
    if (!flag) {
      return;
    }
    setIsOtp(true);
  }
  // does: handle the OTP
  function handleOtp() {
    if (typeof otp !== "object") return;
    if (!otp.value) {
      alert("Please enter OTP");
      return;
    } else if (otp.error) {
      alert(otp.errorMessage);
      return;
    }
    setIsOtp(false);
    setCurrentPage(2);
  }

  // component return
  // Company Details Form
  if (currentPage === 1) {
    // component
    return (
      <>
        <form
          className="| flex-col gap-1 w-100"
          onSubmit={() => setCurrentPage(2)}
        >
          <div className="| flex-between fs-link text-white-400 fw-700">
            <span>Company Details</span>
            <span className="">1/4</span>
          </div>
          <div className="| two-column gap-8 t-one-column m-gap-20">
            {/* Company Name */}
            <Input
              title="Company Name"
              variant="white"
              weight={400}
              width="1px"
              state={{ value: companyName, setValue: setCompanyName }}
              onBlur={() => validateCompanyName(companyName, setCompanyName)}
              warningWeight={400}
              required
            />
            {/* Company Type */}
            <Select
              isClearable={true}
              isSearchable={true}
              options={typesOfCompany}
              placeholder={"Company Type"}
              value={typeof companyType === "object" && companyType.value}
              onChange={(e: any) =>
                setCompanyType((prevCompanyType) => {
                  const prev = prevCompanyType as StateObjType;
                  return { ...prev, value: e };
                })
              }
              onBlur={() => validateCompanyType(companyType, setCompanyType)}
            />
          </div>
          {/* Company Email */}
          <Input
            title="Company Email"
            type="email"
            variant="white"
            weight={400}
            width="1px"
            state={{ value: companyEmail, setValue: setCompanyEmail }}
            onBlur={() => validateCompanyEmail(companyEmail, setCompanyEmail)}
            warningWeight={400}
            required
          />
          {/* Company Website */}
          <Input
            title="Company Website"
            type="text"
            variant="white"
            weight={400}
            width="1px"
            state={{ value: companyWebsite, setValue: setCompanyWebsite }}
            warningWeight={400}
            required
          />
          {/* Next Button */}
          <Button
            variant="secondary"
            action="secondary"
            Icon={<GetDetailsIcon />}
            className="| fs-button as-start"
          >
            Next
          </Button>
        </form>
        <AlreadyHaveAnAccount from={'Transport Company'} />
      </>
    );
  }
  // General Details Form
  else if (currentPage === 2) {
    // component
    return (
      <>
        {/* Dialog for OTP */}
        <Dialog
          header={<>Enter your OTP</>}
          body={
            <>
              <OTPInput otp={otp} setOtp={setOtp} />
            </>
          }
          footer={
            <>
              <Button variant="primary" action="secondary" onClick={handleOtp}>
                Enter
              </Button>
            </>
          }
          state={isOtp}
          setState={setIsOtp}
          variant="primary"
          size="small"
          isOutsideClickOff={false}
        />
        <form className="| flex-col gap-1 m-gap-20 w-100" onSubmit={getOtp}>
          <div className="| flex-between fs-link text-white-400 fw-700">
            <span>General Details</span>
            <span className="">2/4</span>
          </div>
          <div className="| two-column gap-8 t-one-column m-gap-20">
            {/* First Name */}
            <Input
              title="First Name"
              variant="white"
              weight={400}
              width="1px"
              state={{ value: firstName, setValue: setFirstName }}
              onBlur={() => validateFirstName(firstName, setFirstName)}
              warningWeight={400}
              required
            />
            {/* Last Name */}
            <Input
              title="Last Name"
              variant="white"
              weight={400}
              width="1px"
              state={{ value: lastName, setValue: setLastName }}
              onBlur={() => validateLastName(lastName, setLastName)}
              warningWeight={400}
              required
            />
          </div>
          {/* Email */}
          <Input
            title="Email"
            type="email"
            variant="white"
            weight={400}
            width="1px"
            state={{ value: email, setValue: setEmail }}
            onBlur={() => validateEmail(email, setEmail)}
            warningWeight={400}
            required
          />
          {/* Password */}
          <Input
            title="Password"
            type="password"
            variant="white"
            weight={400}
            width="1px"
            state={{ value: password, setValue: setPassword }}
            onBlur={() => validatePassword(password, setPassword)}
            warningWeight={400}
            required
          />
          {/* Contact Number */}
          <Input
            title="Contact Number"
            variant="white"
            weight={400}
            width="1px"
            state={{ value: contactNumber, setValue: setContactNumber }}
            onBlur={() =>
              validateContactNumber(contactNumber, setContactNumber)
            }
            warningWeight={400}
            required
            type="tel"
            maxLength={10}
          />
          <div className="| flex jc-end ai-center gap-1">
            {/* Previous Button */}
            <Button
              variant="primary"
              type="button"
              action="secondary"
              Icon={<BackIcon />}
              className="| fs-button"
              onClick={() => setCurrentPage(1)}
            >
              Previous
            </Button>
            {/* Get OTP Button */}
            <Button
              variant="secondary"
              action="secondary"
              Icon={<GetDetailsIcon />}
              className="| fs-button"
            >
              Get OTP
            </Button>
          </div>
        </form>
        <AlreadyHaveAnAccount from={'Transport Company'} />
      </>
    );
  }
  // Address Details Form
  else if (currentPage === 3) {
    return (
      <>
        <form className="| flex-col gap-1 w-100">
          <div className="| flex-between fs-link text-white-400 fw-900">
            <span>Address Details</span>
            <span>3/4</span>
          </div>
          {/* Address Line 1 */}
          <Input
            title="Address Line 1"
            variant="white"
            weight={400}
            width="1px"
            state={{ value: addressLine1, setValue: setAddressLine1 }}
            onBlur={() => validateAddress1(addressLine1, setAddressLine1)}
            warningWeight={400}
            required
          />
          {/* Address Line 2 */}
          <Input
            title="Address Line 2"
            variant="white"
            weight={400}
            width="1px"
            state={{ value: addressLine2, setValue: setAddressLine2 }}
            onBlur={() => validateAddress2(addressLine2, setAddressLine2)}
            warningWeight={400}
            required
          />
          <div className="| three-column gap-8 t-one-column">
            {/* Pincode */}
            <Input
              title="Pincode"
              type="number"
              variant="white"
              weight={400}
              width="1px"
              state={{ value: pincode, setValue: setPincode }}
              onBlur={() => validatePincode(pincode, setPincode)}
              warningWeight={400}
              required
            />
            {/* City */}
            <Input
              title="City"
              variant="white"
              weight={400}
              width="1px"
              state={{ value: city, setValue: setCity }}
              onBlur={() => validateCity(city, setCity)}
              warningWeight={400}
              required
            />
            {/* State */}
            <Input
              title="State"
              variant="white"
              weight={400}
              width="1px"
              state={{ value: state, setValue: setState }}
              onBlur={() => validateState(state, setState)}
              warningWeight={400}
              required
            />
          </div>
          <div className="| flex jc-end ai-center gap-1">
            {/* Previous Button */}
            <Button
              variant="primary"
              type="button"
              action="secondary"
              Icon={<BackIcon />}
              className="fs-button"
              onClick={() => setCurrentPage(2)}
            >
              Previous
            </Button>
            {/* Next Button */}
            <Button
              variant="secondary"
              action="secondary"
              Icon={<GetDetailsIcon />}
              className="fs-button"
              onClick={() => setCurrentPage(4)}
              type="button"
            >
              Next
            </Button>
          </div>
        </form>
        <AlreadyHaveAnAccount from={'Transport Company'} />
      </>
    );
  }
  // Branch Details Form
  else {
    return (
      <>
        <form className="| flex-col gap-1 w-100">
          <div className="| flex-between fs-link text-white-400 fw-900">
            <span>Branch Details</span>
            <span>4/4</span>
          </div>
          {/* Branch Name */}
          <Input
            title="Branch Name"
            variant="white"
            weight={400}
            width="1px"
            state={{ value: branchName, setValue: setBranchName }}
            onBlur={() => validateBranchName(branchName, setBranchName)}
            warningWeight={400}
            required
          />
          {/* Branch Short Name */}
          <Input
            title="Short Name"
            variant="white"
            weight={400}
            width="1px"
            state={{ value: shortName, setValue: setShortName }}
            onBlur={() => validateShortName(shortName, setShortName)}
            warningWeight={400}
            required
          />
          <div className="| flex jc-end ai-center gap-1">
            {/* Previous Button */}
            <Button
              variant="primary"
              type="button"
              action="secondary"
              Icon={<BackIcon />}
              className="| fs-button"
              onClick={() => setCurrentPage(3)}
            >
              Previous
            </Button>
            {/* Submit Button */}
            <Button
              variant="secondary"
              action="secondary"
              Icon={<GetDetailsIcon />}
              className="| fs-button"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </form>
        <AlreadyHaveAnAccount from={'Transport Company'} />
      </>
    );
  }
};

export default TransportCompany;
