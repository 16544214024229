// react
import React, { useEffect, useState } from "react";
// ui component
import { Button, Dialog, Input, Select } from "../../../components/UI";
// constants (icon)
import { GetDetailsIcon } from "../../../constants/icons";
// import { ReactComponent as WarehouseSvg } from "../assets/warehouse.svg";
// import { ReactComponent as CourierSvg } from "../assets/courier.svg";
// services
import { getCityList } from "../../../../services/area";
import {
  listDestinationBranches,
  listSourceBranches,
} from "../../../../services/inquiry";
// custom state
import { useExpressParcel } from "./useCustomeState";
// utils (helper | validation)
import { getCityOnPincode, getOptions } from "../../../utils/helper";
import { validatePincode } from "../../../utils/validation";
import { useLandingPageContext } from "../../../hooks/useLandingPageContext";
import ContactOTPForm from "../components/CommonForms/ContactOTPForm";
import { StateType } from "../partner-forms/useCustomState";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { hideLoader, showLoader } from "../../../../actions/UserActions";
import {
  expressParcelBookingGetBranchToBranchPrice,
  verifyOTPLandingForms,
} from "../../../../services/landingPageForms";
import { toastMessage } from "../../../../Components/showMessages/toastMessage";
import { showHttpError } from "../../../../utils/message";
import { customerLoginByOTP } from "../../../../actions/customerLogin";
import { useWidth } from "../../../hooks/useMobile";
import DatePicker from "../../../../Components/Datecomponents/DatePicker";

// const first = [<WarehouseSvg className="navigation-icon text-white-900" style={{width: "18px", height: "18px"}} />, <CourierSvg style={{width: "18px", height: "18px"}} className="navigation-icon text-black-900" />];
// const second = [<WarehouseSvg style={{width: "18px", height: "18px"}} className="navigation-icon text-black-900" />, <CourierSvg style={{width: "18px", height: "18px"}} className="navigation-icon text-white-900" />];

// type
const deliveryType = ["Branch to Branch", "Door Delivery"] as const;
export type ActiveDeliveryType = (typeof deliveryType)[number];

// FC: Domestic Get Estimate Component
export default function ExpressParcel() {
  const { activeExpressParcelDeliveryType } = useLandingPageContext();
  // State
  const {
    cities,
    setCities,
    setSourceBranchList,
    setDestinationBranchList,
    sourcePincode,
    setSourcePincode,
    destinationPincode,
    setDestinationPincode,
    sourceCity,
    setSourceCity,
    destinationCity,
    setDestinationCity,
    sourceBranch,
    b2bSourceCity,
    setB2bSourceCity,
    b2bDestinationCity,
    setB2bDestinationCity,
  } = useExpressParcel();

  const [isContactDialogOpen, setIsContactDialogOpen] =
    useState<boolean>(false);
  const [otp, setOTP] = useState<StateType>("");
  const [isOTPClick, setIsOTPClick] = useState<boolean>(false);
  const [contact, setContact] = useState<StateType>("");
  const [date, setDate] = useState("");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { setActiveSubService } = useLandingPageContext();
  const [name, setName] = useState<StateType>("");
  const [isCustomer, setIsCustomer] = useState<boolean>(false)
  const { isMobile } = useWidth();

  // does: set the city options
  const cityOptions = getOptions(cities, /^[a-zA-Z]+$/);
  // does: find the cities
  const setUpData = async () => {
    try {
      const [cityList, branchesList] = await Promise.all([
        getCityList(),
        listSourceBranches(),
      ]);
      setCities(cityList);
      setSourceBranchList(branchesList);
    } catch (err: any) { }
  };
  // does: fetch destination branch list according to the source branch list (B2B)
  const fetchDestinationBranchList = async () => {
    try {
      if (sourceBranch.value && sourceBranch.value.length == 24) {
        const res = await listDestinationBranches(sourceBranch.value);
        setDestinationBranchList(res);
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  // useEffect(() => {
  //   if(isOTPClick){
  //     handleContactSubmit()
  //   }
  // },[isOTPClick])

  // console.log(sourceBranch, "sourceBranch", b2bSourceCity);

  const getEstimatedRateTime = async () => {
    dispatch(showLoader());
    try {
      let payload = {
        originCity: b2bSourceCity.value,
        destCity: b2bDestinationCity.value,
        date,
      };
      const res = await expressParcelBookingGetBranchToBranchPrice(payload);
      if (res) {
        setActiveSubService({
          module: "expressParcelRate",
          isActive: false,
          data: {
            b2bSourceCity,
            b2bDestinationCity,
            date,
            defaultDate: date,
            ...res,
          },
        });
        navigate("/expressParcelRate");
        toastMessage("Get Estimated Rate and Time", {
          type: "success",
          theme: "colored",
        });
      }
    } catch (err: any) {
      showHttpError(err);
      return err;
    } finally {
      dispatch(hideLoader());
    }
  };

  // const handleContactSubmit = async () => {
  //     dispatch(showLoader());

  //     const payload = { contact };

  //     try {
  //       const res = await verifyContact(payload);

  //       if (res) {

  //         const leadPayload = {
  //             originCity : originCity?.value,
  //             destCity : destCity?.value,
  //             assetType: assetType?.value,
  //             assetSubType: vehicleName?.value,
  //             vehicleQty: vehicleNumber?.value,
  //             date,
  //             materialType: materialType?.value,
  //             qty: quantity.value,
  //             weight: weight.value,
  //             contact
  //         }

  //         await sendOTPForLandingForms(payload)

  //         await createExpressParcelBooking(leadPayload);

  //         dispatch(setFullLoadVehicleDetails({ "assetType": assetType }))
  //         dispatch(setFullLoadVehicleDetails({ "assetSubType": vehicleName }))
  //         dispatch(setFullLoadVehicleDetails({ "vehicleModel": vehicleModal }))
  //         dispatch(setFullLoadVehicleDetails({ "truckNos": vehicleNumber.value }))
  //         // setPackagesFeature('qty', parseInt(quantity.value), val.id)
  //         // setPackagesFeature('materialType', materialType, val.id)

  //         dispatch({
  //           type: SET_PARTLOAD,
  //           payload: {
  //             what: "isBookedWithoutToken",
  //             val: true,
  //           },
  //         });
  //         dispatch({
  //           type: SET_PARTLOAD,
  //           payload: {
  //             what: "lead",
  //             val: leadPayload,
  //           },
  //         });
  //         // navigate("/login", {
  //         //   state: {
  //         //     action: "isBookedWithoutToken",
  //         //     data: { contact },
  //         //   },
  //         // });
  //         toastMessage("OTP sent successfully", {
  //             theme: "colored",
  //             type: "success",
  //           });
  //       } else {
  //         setActivePage("customer");
  //         toastMessage("Please Register Customer", {
  //           theme: "colored",
  //           type: "error",
  //         });
  //       }

  //       console.log(res, "response11111111");

  //     } catch (error) {
  //       showHttpError(error);
  //       return error;
  //     } finally {
  //       dispatch(hideLoader());
  //     }
  //   };

  const verifyOTP = async () => {
    dispatch(showLoader());
    let deviceId = localStorage.getItem("deviceId");
    const payload = {
      contact,
      OTP: otp,
      rememberMe: true,
      deviceId,
    };

    try {
      const res = await verifyOTPLandingForms(payload);
      if (res.accessToken && res.customer) {
        dispatch(
          customerLoginByOTP(
            Number(contact),
            otp as string,
            true,
            deviceId ? deviceId : undefined
          )
        );
        navigate("/customerDashboard/booking");
        toastMessage("Customer Login Successfully", {
          theme: "colored",
          type: "success",
        });
      } else {
        toastMessage("failed to Login", {
          theme: "colored",
          type: "error",
        });
      }
      console.log(res, "response11111111");
    } catch (error) {
      showHttpError(error);
      return error;
    } finally {
      dispatch(hideLoader());
    }
  };

  const backButtonClick = () => {
    setIsContactDialogOpen(false);
    setContact("");
  };

  // useEffect
  // does: call the setUpData on the first render
  useEffect(() => {
    setUpData();
  }, []);
  // does: fetch destination branch list whenever user inputs source branch value
  useEffect(() => {
    fetchDestinationBranchList();
  }, [sourceBranch.value]);
  // does: get the source city on source pincode value enter (D2D)
  useEffect(() => {
    if (typeof sourcePincode !== "object") return;
    getCityOnPincode(sourcePincode.value, cities, setSourceCity);
  }, [typeof sourcePincode === "object" && sourcePincode.value]);
  // does: get the destination city on destination pincode value enter (D2D)
  useEffect(() => {
    if (typeof destinationPincode !== "object") return;
    getCityOnPincode(destinationPincode.value, cities, setDestinationCity);
  }, [typeof destinationPincode === "object" && destinationPincode]);

  // component return
  // Branch to Branch Delivery
  if (activeExpressParcelDeliveryType === "Branch to Branch") {
    return (
      <div className="| five-column t-two-column m-one-column d-flex-center gap-1 w-100 paper">
        {/* <ul className="| two-column t-one-column m-0">
          {deliveryType.length > 0 &&
            deliveryType.map((item, index) => (
              <li
                key={item}
                className={`domestic-form-delivery-item | flex-col-center text-center fw-500 px-1 py-8 pointer fs-link ${
                  item === activeExpressParcelDeliveryType ? "active" : ""
                }`}
                onClick={() => setActiveExpressParcelDeliveryType(item)}
              >
                <span style={{ width: "18px" }}> */}
        {/* <img loading="lazy" src={first[index]} alt="img" className="custom-img" /> */}
        {/* {first[index]}
                </span>
                <span className="user-select-none">{item}</span>
              </li>
            ))}
        </ul> */}
        {isMobile && <div className="flex-col gap-8">
          <h1 className="fs-heading text-left fw-900 text-primary-400">Get an Estimate</h1>
          {/* <p className="text-center">Delivery your packages to another cities with options such as branch delivery and door delivery</p> */}
        </div>}
        {/* <div className="| two-column gap-1 t-one-column"> */}
        {/* Source City */}
        <Select
          isSearchable={true}
          options={cityOptions}
          value={b2bSourceCity.value && b2bSourceCity}
          onChange={(e: any) => setB2bSourceCity(e)}
          placeholder={"Origin City"}
        />
        {/* Destination City */}
        <Select
          isSearchable={true}
          value={b2bDestinationCity.value && b2bDestinationCity}
          onChange={(e: any) => setB2bDestinationCity(e)}
          options={cityOptions}
          placeholder={"Destination City"}
        />
        {/* <input
          type="date"
          min={date}
          value={date}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            // if (
            //   new Date(e.target.value as string).getTime() <=
            //   new Date().getTime()
            // ) {
            //   showHttpError("Date not Valid");
            //   return;
            // }
            setDate(e.target.value);
          }}
          name="full-load-date"
          className="| p-8 border-white outline-none br-4"
          id="full-load-date"
        /> */}
        <DatePicker
          selected={date !== '' ? date : new Date()}
          placeholderText="Select Date"
          dateFormat="dd-MM-yyyy"
          name={"full-load-date"}
          // disabled={(originCity === undefined || destCity === undefined) ? true : false}
          value={date}
          className="schedule-your-booking-input py-8 px-1 br-4 border-white react-datepicker-wrapper"
          minDate={new Date().toISOString().split("T")[0]}
          onChange={(e: any) => { setDate(e) }}
          fullWidth={true}
          isClearable={false}
        />
        {/* </div> */}
        {/* <div className="| two-column gap-1 t-one-column"> */}
        {/* Source Branch */}
        {/* <Select
            isSearchable={true}
            value={sourceBranch.value && sourceBranch}
            options={filteredSourceBranchListOptions}
            onChange={(e: any) => setSourceBranch(e)}
            placeholder={"Source Branch"}
          /> */}
        {/* Destination Branch */}
        {/* <Select
            isSearchable={true}
            value={destinationBranch.value && destinationBranch}
            options={filteredDestinationBranchListOptions}
            onChange={(e: any) => setDestinationBranch(e)}
            placeholder={"Destination Branch"}
          /> */}
        {/* </div> */}
        <Button
          variant="secondary"
          action="secondary"
          Icon={<GetDetailsIcon />}
          onClick={getEstimatedRateTime}
          className={`| as-start fs-button ${isMobile ? "flex-center" : ""}`}
          fullWidth={isMobile ? true : false}
          disabled={(b2bSourceCity.value === '' || b2bDestinationCity.value === '' || date === '') ? true : false}
        >
          Get Estimated Rate and Time
        </Button>
      </div>
    );
  }
  // Door to Door Delivery
  else {
    console.log("sdflvksdvmnsdlv", destinationCity?.value !== '', sourceCity);
    return (
      <div className="| five-column m-one-column d-flex-center gap-1 w-100 paper">
        {/* <ul className="| two-column t-one-column m-0">
          {deliveryType.length > 0 &&
            deliveryType.map((item, index) => (
              <li
                key={item}
                className={`domestic-form-delivery-item flex-col-center text-center fw-500 px-1 py-8 pointer fs-link ${
                  item === activeExpressParcelDeliveryType ? "active" : ""
                }`}
                onClick={() => setActiveExpressParcelDeliveryType(item)}
              >
                <span style={{ width: "18px" }}> */}
        {/* <img loading="lazy" src={second[index]} alt="img" className="custom-img" /> */}
        {/* {second[index]}
                </span>
                <span className="user-select-none">{item}</span>
              </li>
            ))}
        </ul> */}
        {/* <div className="| two-column gap-8 t-one-column"> */}
        {/* Source Pincode */}
        {isMobile && <div className="flex-col gap-8">
          <h1 className="fs-heading text-left fw-900 text-primary-400">Get an Estimate</h1>
          {/* <p className="text-center">Delivery your packages to another cities with options such as branch delivery and door delivery</p> */}
        </div>}
        <Input
          title="Source Pincode"
          variant="white"
          weight={400}
          width="1px"
          state={{ value: sourcePincode, setValue: setSourcePincode }}
          onBlur={() => validatePincode(sourcePincode, setSourcePincode)}
          warningWeight={400}
          required
          type="tel"
          maxLength={6}
        />
        {/* Destination Pincode */}
        <Input
          title="Destination Pincode"
          variant="white"
          weight={400}
          width="1px"
          state={{
            value: destinationPincode,
            setValue: setDestinationPincode,
          }}
          onBlur={() =>
            validatePincode(destinationPincode, setDestinationPincode)
          }
          warningWeight={400}
          required
          type="tel"
          maxLength={6}
        />
        {/* </div> */}
        {/* <div className="| two-column gap-8 t-one-column"> */}
        {/* Source city */}
        <Select
          isSearchable={true}
          value={sourceCity.value && sourceCity}
          options={cityOptions}
          isDisabled={true}
          placeholder={"Source City"}
          onChange={(e: any) => setSourceCity(e)}
        />
        {/* Destination city */}
        <Select
          isSearchable={true}
          isDisabled={true}
          value={destinationCity.value && destinationCity}
          options={cityOptions}
          placeholder={"Destination City"}
          onChange={(e: any) => setDestinationCity(e)}
        />
        {/* </div> */}
        <Button
          variant="secondary"
          disabled={(destinationCity?.value === '' || sourceCity?.value === '') ? true : false}
          action="secondary"
          Icon={<GetDetailsIcon />}
          className={`| as-start fs-button ${isMobile ? "flex-center" : ""}`}
          fullWidth={isMobile ? true : false}
        >
          Get Estimated Rate and Time
        </Button>
        <Dialog
          header={"Verify Contact"}
          body={
            <ContactOTPForm
              isOTPModal={isContactDialogOpen}
              contact={contact}
              setContact={setContact}
              otp={otp}
              setOTP={setOTP}
              name={name}
              setName={setName}
              isCustomer={isCustomer}
              setIsCustomer={setIsCustomer}
              isOTPClick={isOTPClick}
              setIsOTPClick={setIsOTPClick}
            />
          }
          footer={
            <div className="flex gap-1">
              <div className="flex gap-1">
                <div className="flex gap-1">
                  <Button
                    onClick={() => {
                      backButtonClick();
                    }}
                    variant="info"
                    action="secondary"
                    type="button"
                  >
                    close
                  </Button>
                </div>
                {isOTPClick ? (
                  <div className="flex gap-1">
                    <Button
                      onClick={() => {
                        verifyOTP();
                      }}
                      variant="primary"
                      action="secondary"
                      type="button"
                    >
                      submit
                    </Button>
                  </div>
                ) : null}
              </div>
            </div>
          }
          size="regular"
          state={isContactDialogOpen}
          setState={setIsContactDialogOpen}
          closeIcon
        ></Dialog>
      </div>
    );
  }
}
