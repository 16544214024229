import { useState } from "react";
import { StateType } from "../../partner-forms/useCustomState";

export function useLogin() {
  const forms = ["Transport Company", "Customer", 'Delivery Partner'] as const;

  // const cards = ["Transport Company", "Customer", 'Driver' , ''] as const;

  const [activeForm, setActiveForm] =
    useState<(typeof forms)[number]>("Transport Company");
  // const [activeCard, setActiveCard] =
  //   useState<(typeof cards)[number]>("");
  const [number, setNumber] = useState({
    value: "",
    error: false,
    errorMessage: "Number is required!",
  });
  const [password, setPassword] = useState({
    value: "",
    error: false,
    errorMessage: "Password is required!",
  });
  const [oldPassword, setOldPassword] = useState({
    value: "",
    error: false,
    errorMessage: "Password is required!",
  });
  const [newPassword, setNewPassword] = useState({
    value: "",
    error: false,
    errorMessage: "Password is required!",
  });
  const [confirmPassword, setConfirmPassword] = useState({
    value: "",
    error: false,
    errorMessage: "Password is required!",
  });
  const [frNewPassword, setFrNewPassword] = useState({
    value: "",
    error: false,
    errorMessage: "Password is required!",
  });
  const [frConfirmPassword, setFrConfirmPassword] = useState({
    value: "",
    error: false,
    errorMessage: "Password is required!",
  });
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isOldPasswordVisible, setIsOldPasswordVisible] = useState(false);
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const [isFrNewPasswordVisible, setIsFrNewPasswordVisible] = useState(false);
  const [isFrConfirmPasswordVisible, setIsFrConfirmPasswordVisible] =
    useState(false);
  const [passwordForm, setPasswordForm] = useState<
    "Change Password" | "Forget Password" | "Login"
  >("Login");
  const [otp, setOtp] = useState<StateType>(() => {
    return {
      value: "",
      error: false,
      errorMessage: "OTP is required!",
    };
  });
  const [isOtp, setIsOtp] = useState<boolean>(false);
  const [isOtpVerified, setIsOtpVerified] = useState<boolean>(false);
  return {
    activeForm,
    setActiveForm,
    // activeCard,
    // setActiveCard,
    setNumber,
    setPassword,
    setIsPasswordVisible,
    number,
    password,
    isPasswordVisible,
    passwordForm,
    setPasswordForm,
    oldPassword,
    setOldPassword,
    newPassword,
    setNewPassword,
    confirmPassword,
    setConfirmPassword,
    isOldPasswordVisible,
    setIsOldPasswordVisible,
    isNewPasswordVisible,
    setIsNewPasswordVisible,
    isConfirmPasswordVisible,
    setIsConfirmPasswordVisible,
    otp,
    setOtp,
    isOtp,
    setIsOtp,
    frNewPassword,
    setFrNewPassword,
    frConfirmPassword,
    setFrConfirmPassword,
    isFrNewPasswordVisible,
    setIsFrNewPasswordVisible,
    isFrConfirmPasswordVisible,
    setIsFrConfirmPasswordVisible,
    isOtpVerified, setIsOtpVerified
  };
}
