import React from 'react'
import { PageHeader } from "../../../../components/UI";
import { termsAndConditionsCustomerBooking } from "../../../../constants/data";

const TermsAndConditionsCustomerBooking = () => {
    return (
        <div>
            <PageHeader title="Terms & Conditions For Services" imgSrc="terms-and-conditions/bg.jpg" />
            <section className="p-3 my-container flex-center">
                <ul className="terms-and-conditions-list flex-col gap-1" style={{ listStyle: "disc" }}>
                    {termsAndConditionsCustomerBooking.length > 0 && termsAndConditionsCustomerBooking.map((item: any) => <li key={item} className="fs-medium fw-400" style={{ maxWidth: "65ch" }}>
                        {item}
                    </li>)}
                </ul>
            </section>
        </div>
    )
}

export default TermsAndConditionsCustomerBooking;
