import { Dispatch } from "redux";
import { RootState } from "../../store";
import {
  GET_COMPANY_REQ_SUCCESS,
  EDIT_COMPANY_REQ_SUCCESS,
  CHANGE_ADD_EXPENSE_TYPE,
  SUBMIT_EXPENSE_SUCCESS,
  GET_EXPENSE_TYPES,
  CHANGE_FIXED_EXPENSE,
  CHANGE_ENTITY_ID,
  CHANGE_ADD_INCOME_TYPE,
  SUBMIT_FIXED_EXPENSE_SUCCESS,
  SUBMIT_INCOME_TYPE_SUCCESS,
  CHANGE_SUB_INCOME_TYPE,
  ADD_SUB_INCOME_TYPE,
  SET_INCOME_TYPE_FOR_EDIT,
  EDIT_INCOME_TYPE_SUCCESS,
  ADD_SUB_INCOME_TYPE_FAILED,
  RESET_INCOME_TYPE_FORM,
  INCOME_TYPE_DELETED,
  RESET_SUB_INCOME_TYPE,
  EDIT_SUB_INCOME_DATA,
  GET_POSTED_SUB_TYPES,
  SET_SUBTYPES_FOR_EDIT,
  DELETE_SUB_TYPE,
  SET_EXPENSE_TYPE,
  RESET_EXPENSE_TYPE,
  DELETE_EXPENSE_TYPE,
  RESET_FIXED_EXPENSE,
  GET_FIXED_EXPENSE_BY_ID,
  SET_FIXED_EXPENSE_TYPE,
  DELETE_FIXED_EXPENSE_TYPE,
  SET_ASSET_TYPES,
  EDIT_EXPENSE_SUCCESS,
  GET_COMPANY_LIST,
  GET_VEHICLE_ID,
  CHANGE_COMPANY_DOC,
  SET_COMPANY_DOC,
  CLEAR_COMPANY_DOC,
} from "../../constants/company";
import {
  getExpenseList,
  expenseSubmitHandler,
  fixedExpenseSubmitHandler,
  deleteExpense,
  getFixedExpenseTypeById,
  editFixedExpenseType,
  deleteFixedExpenseType,
  expenseEditHandler,
} from "../../services/expenses";
import {
  incomeSubmitHandler,
  incomeSubTypeSubmitHandler,
  editIncomeType,
  deleteIncomeTypes,
  deleteSubIncomeTypes,
  getSubTypes,
  putSubIncome,
} from "../../services/income";
import { getAssetBalance } from "../../services/asset";
import { listAssetType } from "../../services/asset";
import { getIncomeTypes } from "../income/index";
import { getCategoryList, vehicleCategory } from "../../services/category";
import { viewAssetBalanceDetails } from "../../services/asset";
import {
  listCompanyService,
  createCompanyService,
  updateCompanyService,
  viewCompanyService,
  updateCompanyMessage,
} from "../../services/company";
import { listUsersService } from "../../services/user";
import { getCityByPincode } from "../../services/area";
import { resolveAPI, showError } from "../../services/helpers/error";
import { toast } from "react-toastify";
import { ADD_INCOME_TYPE, REMOVE_INCOME_TYPE } from "../../constants/income";
import { showLoader, hideLoader } from "../UserActions";
import { showMessage, failed, success } from "../../utils/message";
import jsonToFormdata from "../../utils/jsonToFormdata";
import { initCompanyDoc } from "../../reducers/company";

export const getCompany = (company: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(showLoader());
      let companyDoc = await viewCompanyService(company);
      await dispatch({
        type: GET_COMPANY_REQ_SUCCESS,
        payload: companyDoc,
      });
      dispatch(hideLoader());
    } catch (err : any) {
      dispatch(hideLoader());
      showMessage(err.message || "No such company exists", failed);
    }
  };
};

export const editCompanyMessages = (id: any, payload: any) => {
  console.log(id);
  return async (dispatch: Dispatch) => {
    try {
      dispatch(showLoader());
      let company = await updateCompanyMessage(payload);
      await dispatch({
        type: EDIT_COMPANY_REQ_SUCCESS,
        payload: company.company,
      });
      dispatch(hideLoader());
    } catch (err : any) {
      dispatch(hideLoader());
      showMessage(err.message || "Couldn't update message!", failed);
    }
  };
};

export const editCompany = (id: string, payload: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(showLoader());
      let company = await updateCompanyService(id, payload);
      await dispatch({
        type: EDIT_COMPANY_REQ_SUCCESS,
        payload: company.company,
      });
      dispatch(hideLoader());
    } catch (err : any) {
      dispatch(hideLoader());
      showMessage(err.message || "Couldn't update message!", failed);
    }
  };
};

export const changesExpenseType = (what: any, val: any) => {
  let payload = { what, val };
  return async (dispatch: Dispatch) => {
    try {
      dispatch(showLoader());
      await dispatch({
        type: CHANGE_ADD_EXPENSE_TYPE,
        payload: payload,
      });
      if (what == "sub" && val == "A") {
        let response = await getCategoryList();
        await dispatch({ type: SET_ASSET_TYPES, payload: response });
      }
      dispatch(hideLoader());
    } catch (error : any) {
      dispatch(hideLoader());
      showMessage(error.message || "Couldn't update message!", failed);
    }
  };
};

export const ExpenseSubmitHandler = (payload: any) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(showLoader());
      await expenseSubmitHandler(payload);
      dispatch({
        type: SUBMIT_EXPENSE_SUCCESS,
      });
      let company = getState().user.company.id;
      await dispatch(fetchExpenseList({ company }));
      dispatch(hideLoader());
    } catch (error : any) {
      dispatch(hideLoader());
      showMessage(error.message || "Couldn't Add Expense Type !", failed);
    }
  };
};

export const expenseTypeEdit = (payload: any) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(showLoader());
      await expenseEditHandler(payload);
      await dispatch({
        type: EDIT_EXPENSE_SUCCESS,
      });
      let company = getState().user.company.id;
      await dispatch(fetchExpenseList({ company }));
      dispatch(hideLoader());
    } catch (error : any) {
      dispatch(hideLoader());
      showMessage(error.message || "Couldn't Edit Expense Type", failed);
    }
  };
};

export const fixedExpenseSubmit = (payload: any) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(showLoader());
      let data = {
        ...payload,
        company: getState().user.company._id,
      };
      let res: any = await fixedExpenseSubmitHandler(data);
      if (res.status !== 200) throw "err";
      dispatch({
        type: SUBMIT_FIXED_EXPENSE_SUCCESS,
      });
      await dispatch(fetchFixedExpenseTypesById(payload.ref));
      dispatch(hideLoader());
      showMessage("Fixed Expense type created successfully!", success);
    } catch (error : any) {
      dispatch(hideLoader());
      showMessage(
        (error.data && error.data.message) || "Couldn't create fixed expense",
        failed
      );
    }
  };
};

export const fetchExpenseList = (data: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(showLoader());
      const expenseList = await getExpenseList(data);
      await dispatch({ type: GET_EXPENSE_TYPES, payload: expenseList });
      dispatch(hideLoader());
    } catch (error : any) {
      // await dispatch({ type: GET_EXPENSE_TYPES_FAILED })
      dispatch(hideLoader());
      showMessage(error.message || "Couldn't fetch expenses.", failed);
    }
  };
};

const setSubType = (what: any, val: any) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: CHANGE_FIXED_EXPENSE, payload: { what, val } });
  };
};

export const changeFixedExpense = (what: any, val: any) => {
  return async (dispatch: any, getState: () => RootState) => {
    const { user, company, branch } = getState();
    try {
      let payload = { what, val };
      let type = { ...what };
      if (type[0] == "entity") {
        dispatch(setSubType(what, val));
        let subTypes;
        let assetTypes;
        switch (val) {
          case "G": {
            subTypes = [];
            let data = {
              what: ["entity_id"],
              val: getState().user.company._id,
            };
            await dispatch({
              type: CHANGE_FIXED_EXPENSE,
              payload: data,
            });
            break;
          }
          case "B": {
            company.toJS();
            subTypes = branch.allbranches;
            break;
          }
          case "U": {
            subTypes = await listUsersService();
            break;
          }
          case "A": {
            let companyJS : any = company.toJS();
            let { value } : any = companyJS.fixed_expenses.post_fixed_expense.ref;
            let { fetched_expense_types } : any = companyJS.fixed_expenses;
            let categoryId;
            Array.isArray(fetched_expense_types) &&
              fetched_expense_types.forEach((e) => {
                if (e.id == value) {
                  categoryId = e.category;
                }
              });
            assetTypes = await listAssetType({
              company: user.company._id,
              categoryId,
            });
            break;
          }
          default:
            break;
        }
        if (val == "A") {
          await dispatch({ type: SET_ASSET_TYPES, payload: assetTypes });
        } else {
          await dispatch({ type: CHANGE_ENTITY_ID, payload: subTypes });
        }
      } else {
        dispatch({
          type: CHANGE_FIXED_EXPENSE,
          payload: payload,
        });
      }
    } catch (error : any) {
      console.log(error);
    }
  };
};

export const selectAssetTypes = (ref: any, asset: any) => {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch(showLoader());
      let assetTypeId = getState().company.toJS().fixed_expenses.assetType
        ._id;
      let category;
      let { fetched_expense_types } = getState().company.toJS().fixed_expenses;
      Array.isArray(fetched_expense_types) &&
        fetched_expense_types.forEach((e) => {
          if (ref == e.id) {
            category = e.category;
          }
        });
      let response;

      if (assetTypeId == category) {
        let data = {
          assetType: asset,
          company: getState().user.company._id,
        };
        response = await getAssetBalance(data);
      } else {
        let data = {
          assetType: asset,
          company: getState().user.company._id,
          branch: getState().user.opBranch._id,
        };
        response = await getAssetBalance(data);
      }

      await dispatch({ type: CHANGE_ENTITY_ID, payload: response });
      dispatch(hideLoader());
    } catch (error : any) {
      dispatch(hideLoader());
      showMessage(error.message, failed);
    }
  };
};

export const getVehicleId = () => {
  return async (dispatch: Dispatch) => {
    try {
      let response = await vehicleCategory();
      dispatch({ type: GET_VEHICLE_ID, payload: response });
    } catch (error) {
      console.log("error: ", error);
    }
  };
};

export const changeIncomeType = (what: any, val: any) => {
  let payload = { what, val };
  return async (dispatch: Dispatch) => {
    try {
      dispatch(showLoader());

      let response = await getCategoryList();
      await dispatch({ type: SET_ASSET_TYPES, payload: response });

      await dispatch({
        type: CHANGE_ADD_INCOME_TYPE,
        payload: payload,
      });
      dispatch(hideLoader());
    } catch (error : any) {
      dispatch(hideLoader());
    }
  };
};

export const incomeSubmit = (data: any) => {
  return async (dispatch: Dispatch) => {
    try {
      await dispatch(showLoader());
      let response = await resolveAPI(incomeSubmitHandler, [data]);
      dispatch({
        type: ADD_INCOME_TYPE,
        payload: response.data,
      });
      await dispatch({
        type: SUBMIT_INCOME_TYPE_SUCCESS,
      });
      await dispatch(hideLoader());
    } catch (error : any) {
      showError(error);
      await dispatch(hideLoader());
    }
  };
};

export const setIncomeTypesForEdit = (data: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(showLoader());
      dispatch({
        type: SET_INCOME_TYPE_FOR_EDIT,
        payload: data,
      });
      switch (data.sub) {
        case "A":
          let categoryList = await getCategoryList();
          await dispatch({ type: SET_ASSET_TYPES, payload: categoryList });
          let val = {};
          if (Array.isArray(categoryList)) {
            categoryList.map((c) => {
              if (c._id == data.category) {
                val = { label: c.name, value: c._id };
              }
            });
          }
          await dispatch({
            type: CHANGE_ADD_INCOME_TYPE,
            payload: { what: ["category"], val },
          });
          break;
        default:
          break;
      }
      dispatch(hideLoader());
    } catch (error : any) {
      dispatch(hideLoader());
      showMessage(error.message, failed);
    }
  };
};

export const resetIncomeType = () => ({ type: RESET_INCOME_TYPE_FORM });

export const incomeTypeEdit = (data: any) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(showLoader());
      await editIncomeType(data);
      dispatch({ type: EDIT_INCOME_TYPE_SUCCESS });
      let companyId = getState().user.company.id;
      await dispatch(getIncomeTypes(companyId));
      dispatch(hideLoader());
    } catch (error : any) {
      dispatch(hideLoader());
      showMessage(error.message || "Couldn't edit income type.", failed);
    }
  };
};

export const getSubIncomeTypesForList = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(showLoader());
      let response: any = await getSubTypes();
      dispatch({ type: GET_POSTED_SUB_TYPES, payload: response.response });
      dispatch(hideLoader());
    } catch (error : any) {
      dispatch(hideLoader());
      showMessage(error.message || "No sub incomes fetched.", failed);
    }
  };
};

export const deleteIncomeType = (id: any) => {
  return async (dispatch: Dispatch) => {
    try {
      await dispatch(showLoader());
      await resolveAPI(deleteIncomeTypes, [id]);
      await dispatch({ type: INCOME_TYPE_DELETED });
      await dispatch({
        type: REMOVE_INCOME_TYPE,
        payload: id,
      });
      await dispatch(hideLoader());
    } catch (error : any) {
      if (typeof error === "string") toast.error(error);
      await dispatch(hideLoader());
    }
  };
};
export const deleteSubIncomeType = (id: any) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch(showLoader());
      let companyId = getState().user.company._id;
      await deleteSubIncomeTypes(id, companyId);
      dispatch({ type: DELETE_SUB_TYPE });
      dispatch(hideLoader());
    } catch (error : any) {
      dispatch(hideLoader());
      showMessage(error.message || "Couldn't delete sub Income.", failed);
    }
  };
};
export const setSubTypeEdit = (data: any) => ({
  type: SET_SUBTYPES_FOR_EDIT,
  payload: data,
});

export const editSubIncome = (data: any) => {
  return async (dispatch: Dispatch) => {
    try {
      putSubIncome(data);
      dispatch({ type: EDIT_SUB_INCOME_DATA });
    } catch (error : any) {
      console.log("error: ", error);
    }
  };
};
export const resetSubIncomeType = () => ({ type: RESET_SUB_INCOME_TYPE });

export const changeSubType = (what: any, val: any) => {
  let payload = { what, val };

  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: CHANGE_SUB_INCOME_TYPE,
        payload: payload,
      });
    } catch (error : any) {
      console.log("error: ", error);
    }
  };
};
export const submitIncomeSubType = (data: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(showLoader());
      let response: any = await incomeSubTypeSubmitHandler(data);
      if (response.status == 1) {
        dispatch({
          type: ADD_SUB_INCOME_TYPE,
        });
      } else {
        dispatch({ type: ADD_SUB_INCOME_TYPE_FAILED });
      }
      dispatch(hideLoader());
    } catch (error : any) {
      dispatch(hideLoader());
      showMessage(error.message || "Couldn't create sub income type.", failed);
    }
  };
};
export const setExpenseType = (data: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(showLoader());
      await dispatch({ type: SET_EXPENSE_TYPE, payload: data });
      switch (data.sub) {
        case "A":
          let categoryList = await getCategoryList();
          await dispatch({ type: SET_ASSET_TYPES, payload: categoryList });
          let val = {};
          if (Array.isArray(categoryList)) {
            categoryList.map((c) => {
              if (c._id == data.category) {
                val = { label: c.name, value: c._id };
              }
            });
          }
          await dispatch({
            type: CHANGE_ADD_EXPENSE_TYPE,
            payload: { what: ["category"], val },
          });
          break;
        default:
          break;
      }
      dispatch(hideLoader());
    } catch (error) {
      showMessage("Couldn't set expense type", failed);
      dispatch(hideLoader());
    }
  };
};
export const resetExpenseType = () => ({ type: RESET_EXPENSE_TYPE });

export const deleteExpenseType = (id: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(showLoader());
      await deleteExpense(id);
      dispatch({ type: DELETE_EXPENSE_TYPE });
      showMessage("Expense Type Deleted !", success);
      dispatch(hideLoader());
    } catch (error : any) {
      dispatch(hideLoader());
      // dispatch({
      //   type: 'DELETE_EXPENSE_TYPE_FAILED',
      //   payload: error.message
      // })
      showMessage(error.message || "Couldn't delete expense type", failed);
    }
  };
};
export const resetFixedExpense = () => ({ type: RESET_FIXED_EXPENSE });

export const fetchFixedExpenseTypesById = (id: any) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      await dispatch(showLoader());
      let company = getState().user.company.id;
      let data = { id, company };
      let response = await getFixedExpenseTypeById(data);
      await dispatch({ type: GET_FIXED_EXPENSE_BY_ID, payload: response });
      await dispatch(hideLoader());
    } catch (error : any) {
      dispatch(hideLoader());
      showMessage(
        error && error.message
          ? error.message
          : "couldn't fetch fixed expense.",
        failed
      );
    }
  };
};
export const editFixedExpense = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      await editFixedExpenseType(data);
      // dispatch({ type: EDIT_FIXED_EXPENSE_SUCCESS })
      await dispatch(fetchFixedExpenseTypesById(data.ref));

      dispatch(hideLoader());
      showMessage("Fixed Expense edited!");
    } catch (error : any) {
      dispatch(hideLoader());
      showMessage(error.message || "Could Not Edit Fixed Expense !", failed);
      // dispatch({ type: EDIT_FIXED_EXPENSE_FAILURE, payload: error.message })
    }
  };
};
export const deleteFixedExpense = (data: any, id: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      await deleteFixedExpenseType(id);
      dispatch({ type: DELETE_FIXED_EXPENSE_TYPE });
      await dispatch(fetchFixedExpenseTypesById(data.ref.value));

      dispatch(hideLoader());
    } catch (error : any) {
      dispatch(hideLoader());
      showMessage(error.message || "Couldn't delete expense.", failed);
    }
  };
};

export const setFixedExpenseType = (data: any) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch(showLoader());
      const { user, company } = getState();
      let newData = { ...data };
      // let fixedExpenseById = {...newData}
      let fetched_expense_types = getState().company.getIn([
        "fixed_expenses",
        "fetched_expense_types",
      ]);
      // fixedExpenseById.ref = {label: newData.name, value: newData.id}
      // await dispatch(changeFixedExpense(['entity'], newData.sub))
      let entity_types = getState().company.getIn([
        "fixed_expenses",
        "fetched_entity_types",
      ]);
      switch (newData.sub) {
        case "U": {
          let getuserPayload = {
            // branch: user.opBranch._id,
          };
          let users = await listUsersService(getuserPayload);
          await dispatch({ type: CHANGE_ENTITY_ID, payload: users });
          if (Array.isArray(users) && users.length > 0) {
            users.map((e : any) => {
              if (e._id == newData.entity_id) {
                newData["entity_id"] = {
                  label: e.name.fName + " " + e.name.lName,
                  value: e._id,
                };
              }
            });
          }
          break;
        }
        case "B": {
          company.toJS();
          let subTypes = user.allbranches;
          await dispatch({ type: CHANGE_ENTITY_ID, payload: subTypes });
          if (Array.isArray(entity_types)) {
            entity_types.map((b) => {
              if (b._id == newData.entity_id) {
                newData["entity_id"] = { label: b.branchName, value: b._id };
              }
            });
          }
          break;
        }
        case "A": {
          let companyJS : any = company.toJS();
          // let { value } = companyJS.fixed_expenses.post_fixed_expense.ref
          let { fetched_expense_types } : any = companyJS.fixed_expenses;
          let categoryId = newData;
          Array.isArray(fetched_expense_types) &&
            fetched_expense_types.forEach((e:any) => {
              if (e.name == newData.name) {
                categoryId = e.category;
              }
            });
          let assetTypes = await listAssetType({
            company: user.company._id,
            categoryId,
          });
          await dispatch({ type: SET_ASSET_TYPES, payload: assetTypes });

          let assetBal = await viewAssetBalanceDetails(newData.entity_id);
          newData["assetType"] = {
            label: assetBal.type.assetName,
            value: assetBal.type._id,
          };
          newData["entity_id"] = {
            label: assetBal.uniqueId
              ? assetBal.uniqueId
              : assetBal.qty + " " + assetBal.type.assetName,
            value: assetBal._id,
          };
          let response;
          let data = {
            assetType: newData["assetType"].value,
            company: getState().user.company._id,
          };
          response = await getAssetBalance(data);

          await dispatch({ type: CHANGE_ENTITY_ID, payload: response });
          // await dispatch({ type: CHANGE_ENTITY_ID, payload: assetBal })
          break;
        }
        default:
          break;
      }
      if (Array.isArray(fetched_expense_types)) {
        fetched_expense_types.map((e) => {
          if (e.name == newData.name) {
            newData.ref = { label: e.name, value: e.id };
          }
        });
      }
      await dispatch({ type: SET_FIXED_EXPENSE_TYPE, payload: newData });
      dispatch(hideLoader());
    } catch (error : any) {
      dispatch(hideLoader());
      showMessage(error.message || "Set Fixed expense failed.", failed);
    }
  };
};
export const getCompanyList = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(showLoader());
      let response = await listCompanyService();
      dispatch({ type: GET_COMPANY_LIST, payload: response });
      dispatch(hideLoader());
    } catch (error : any) {
      showMessage(error.message || "Couldn't list company", failed);
      dispatch(hideLoader());
    }
  };
};

export const setCompanyError = (what: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: "SET_COMPANY_ERROR",
      payload: {
        what: what,
      },
    });
  };
};

export const changeCompanyDoc = (what: any, value: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CHANGE_COMPANY_DOC,
      payload: {
        what: what,
        value: value,
      },
    });
    if (
      what[0] == "address" &&
      what[1] == "pincode" &&
      value &&
      value.length == 6
    ) {
      try {
        const city = await getCityByPincode(value);
        dispatch({
          type: CHANGE_COMPANY_DOC,
          payload: {
            what: ["address", "city"],
            value: city._id,
          },
        });
      } catch (err : any) {
        console.log("citybypincode error", err);
      }
    }
  };
};

export const createCompanyDocAction = () => {
  return async (dispatch: any, getstate: any) => {
    const companyObj = getstate().company.get("company_doc").toJS();
    let company = companyObj;
    company.fleetType = companyObj.fleetType.value
    company.assetSubType = companyObj.assetSubType.value
    company.primaryCompany = companyObj.primaryCompany.value
    // company['user'] = getstate().user.uid
    delete company["errors"];
    if (company.individual) {
      delete company["companyName"];
      delete company["email"];
      delete company["url"];
      delete company["kind"];
      delete company["companyLogo"];
      delete company["_id"];
      delete company["isNew"];
      if (!company["regNumber"]) {
        delete company["modelNumber"];
        delete company["capacity"];
        delete company["volume"];
        delete company["distanceCovered"];
        delete company["ownerShip"];
        delete company["idealFuel"];
        delete company["insuranceDate"];
        delete company["fitnessDate"];
        delete company["permitDate"];
        delete company["emiDate"];
        delete company["allowedOFDCities"];
        delete company["overhead"];
        delete company["fleetId"];
        delete company["noOfSeats"];
        delete company["businessType"];
        delete company["fuelType"];
        delete company["bodyType"];
        delete company["fleetType"];
        delete company["assetSubType"];
      }
    } else {
      // delete company["licenseImage"];
      // delete company["licenseExpiry"];
      // delete company["license"];
      // delete company["licenseImage"];
      delete company["userProfileImage"];
      delete company["userProfile"];
      delete company["_id"];
      delete company["isNew"];

      delete company["bodyType"];
      delete company["regNumber"];
      delete company["fleetType"];
      delete company["assetSubType"];

      delete company["modelNumber"];
      delete company["capacity"];
      delete company["volume"];
      delete company["distanceCovered"];
      delete company["ownerShip"];
      delete company["idealFuel"];
      delete company["insuranceDate"];
      delete company["fitnessDate"];
      delete company["permitDate"];
      delete company["emiDate"];
      delete company["allowedOFDCities"];
      delete company["overhead"];
      delete company["fleetId"];
      delete company["noOfSeats"];
      delete company["businessType"];
      delete company["fuelType"];
    }
    delete company["user"];
    try {
      if (company.regNumber) company.individualFleet = true;
      dispatch(showLoader());
      await createCompanyService(jsonToFormdata(company));
      dispatch(getCompanyList());
      dispatch(
        clearCompanyDoc(getstate().company.getIn(["company_doc", "individual"]))
      );
      dispatch(hideLoader());
      showMessage("Company Created.", success);
    } catch (err : any) {
      dispatch(hideLoader());
      showMessage(err.message || "Company creation failed.", failed);
    }
  };
};
export const updateCompanyDocAction = () => {
  return async (dispatch: Dispatch, getstate: any) => {
    let company = getstate()
      .company.get("company_doc")
      .toJS();
    const companyId = company._id;
    // company['user'] = getstate().user.uid
    company["contactPerson"] = {
      ...company["contactPerson"],
    };
    delete company["errors"];
    delete company.contactPerson["password"];
    if (company.individual) {
      delete company["companyName"];
      delete company["email"];
      delete company["url"];
      delete company["kind"];
      delete company["companyLogo"];
      delete company["_id"];
      delete company["isNew"];
      if (!company["regNumber"]) {
        delete company["modelNumber"];
        delete company["capacity"];
        delete company["volume"];
        delete company["distanceCovered"];
        delete company["ownerShip"];
        delete company["idealFuel"];
        delete company["insuranceDate"];
        delete company["fitnessDate"];
        delete company["permitDate"];
        delete company["emiDate"];
        delete company["allowedOFDCities"];
        delete company["overhead"];
        delete company["fleetId"];
        delete company["noOfSeats"];
        delete company["businessType"];
        delete company["fuelType"];
        delete company["bodyType"];
        delete company["fleetType"];
        delete company["assetSubType"];
      }
      if (!company["fleetId"]) delete company["fleetId"];
    } else {
      delete company["bodyType"];
      delete company["regNumber"];
      delete company["fleetType"];
      delete company["assetSubType"];
      // delete company["licenseImage"];
      // delete company["licenseExpiry"];
      delete company["license"];
      delete company["licenseImage"];
      delete company["userProfileImage"];
      delete company["userProfile"];
      delete company["_id"];
      delete company["isNew"];

      delete company["modelNumber"];
      delete company["capacity"];
      delete company["volume"];
      delete company["distanceCovered"];
      delete company["ownerShip"];
      delete company["idealFuel"];
      delete company["insuranceDate"];
      delete company["fitnessDate"];
      delete company["permitDate"];
      delete company["emiDate"];
      delete company["allowedOFDCities"];
      delete company["overhead"];
      delete company["fleetId"];
      delete company["noOfSeats"];
      delete company["businessType"];
      delete company["fuelType"];
    }
    try {
      dispatch(showLoader());
      if(company.assetSubType){
        company.assetSubType=company.assetSubType._id
      }
      if(company.fleetType) {
        company.fleetType=company.fleetType._id;
      }
      company.primaryCompany = company.primaryCompany?.value;
      let finalCompany: any = jsonToFormdata(company);
      await updateCompanyService(companyId, finalCompany);
      dispatch(
        clearCompanyDoc(getstate().company.getIn(["company_doc", "individual"]))
      );
      dispatch(hideLoader());
      showMessage("Company Updated Successfull", success);
    } catch (err : any) {
      dispatch(hideLoader());
      showMessage(err.message || "Couldn't update company.", failed);
    }
  };
};

// export const deleteCompanyDocAction = () => {
//   return async (dispatch, getstate: () => RootState) => {
//     try {
//       dispatch(showLoader())
//       const result = await deleteCompanyService(getstate().company.getIn(['company_doc', '_id']))
//       dispatch(getCompanyList())
//       dispatch(clearCompanyDoc(getstate().company.getIn(['company_doc', 'individual'])))
//       dispatch(hideLoader())
//       showMessage("Company Deleted Successfully.",success);
//     }
//     catch (err : any) {
//       dispatch(hideLoader())
//       showMessage(err.message || "Couldn't delete Company.",failed);
//     }
//   }
// }

export const setCompanyDocAction = (id: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(showLoader());
      const result = await viewCompanyService(id);
      let newresult: any = {
        isNew: false,
        individual: result.individual || false,
        individualFleet: result.individualFleet || false,
        _id: result._id,
        companyName: result.companyName,
        active: result.active,
        isDocVerified: result.isDocVerified,
        twoFactorAuthentication: result.twoFactorAuthentication,
        userAttendance: result.userAttendance,
        branchTiming: result.branchTiming,
        cameraStatus: result.cameraStatus,
        phoneStatus: result.phoneStatus,
        customerVerification: result.customerVerification,
        allDocketVerification: result.allDocketVerification,
        withoutEWayBillDocketVerification: result.withoutEWayBillDocketVerification,
        photoVerification: result.photoVerification,
        otpVerification: result.otpVerification,
        receiverAllDocketVerification: result.receiverAllDocketVerification,
        receiverPhotoVerification: result.receiverPhotoVerification,
        receiverOtpVerification: result.receiverOtpVerification,
        pendingDelivery: result.pendingDelivery,
        cancelBooking: result.cancelBooking,
        loading: result.loading,
        unloading: result.unloading,
        backToHub: result.backToHub,
        myDuty: result.myDuty,
        creditCustomer: result.creditCustomer,
        pendingVoucher: result.pendingVoucher,
        userPendingMemo: result.userPendingMemo,
        userPendingReport: result.userPendingReport,
        branchPendingMemo: result.branchPendingMemo,
        branchPendingReport: result.branchPendingReport,
        mandatoryExpVerification: result.mandatoryExpVerification,
        contactVerified: result.contactVerified,
        // interCompanyCashReceiver: result.interCompanyCashReceiver,
        // intraCompanyCashReceiver: result.intraCompanyCashReceiver,
        // interCompanyCashUser: result.interCompanyCashUser || [],
        // intraCompanyCashUser: result.intraCompanyCashUser || [],

        verifiedPackages: result.verifiedPackages,
        qrPrinter: result.qrPrinter,
        userVerification: result.userVerification,
        fleetDocVerification: result.fleetDocVerification,
        deliveryWithQr: result.deliveryWithQr,
        contactPerson: {
          contact: result.contactPerson && result.contactPerson.contact,
          name: {
            fName: result.contactPerson && result.contactPerson.name.fName,
            lName: result.contactPerson && result.contactPerson.name.lName,
          },
          email: result.contactPerson && result.contactPerson.email,
          password: result.contactPerson && result.contactPerson.password,
        },
        address: {
          l1: result.address && result.address.l1,
          l2: result.address && result.address.l2,
          city: result.address && result.address.city,
          pincode: result.address && result.address.pincode,
        },
        bankAccount: result.bankAccount,
        ifscCode: result.ifscCode,
        // panNumber: result.panNumber,
        // panCardImage: result.panCardImage,
        displayName: result.displayName,
        businessSubType: result?.businessSubType,
        companyType: result?.companyType,
        settleCompany: result?.settleCompany,
        messages: { ...result?.messages },
        primaryCompany: result.primaryCompany?._id,
        themePreference: result?.themePreference ? result?.themePreference : initCompanyDoc.themePreference,
        autoEwayBillPartBVerification: result?.autoEwayBillPartBVerification ? result?.autoEwayBillPartBVerification : initCompanyDoc.autoEwayBillPartBVerification,
        eWaybillValidityCheck: result?.eWaybillValidityCheck ? result?.eWaybillValidityCheck : initCompanyDoc.eWaybillValidityCheck,
        cashDiscount: result?.cashDiscount ? result?.cashDiscount : initCompanyDoc.cashDiscount,
        cashDemurrageDiscount: result?.cashDemurrageDiscount ? result?.cashDemurrageDiscount : initCompanyDoc.cashDemurrageDiscount
      };
      if (newresult.individual == false && newresult.individualFleet == false) newresult.banner = result?.banner ? result?.banner : {}
      if (newresult.individual == false && newresult.individualFleet == false) newresult.bannerImage = result?.banner?.image ? result?.banner?.image : "";
      newresult.panCard = result?.panCard ? result?.panCard : {}
      newresult.panCardImage = result?.panCard?.image ? result?.panCard?.image : ""
      //  aadharCardImage:"",
      //aadharCard:{},
      newresult.aadharCard = result?.aadharCard ? result?.aadharCard : {}
      newresult.aadharCardImage = result?.aadharCard?.image ? result?.aadharCard?.image : ""
      if (newresult.individual == false && newresult.individualFleet == false) newresult.companyLogo = result?.companyLogo ? result?.companyLogo : {}
      if (newresult.individual == false && newresult.individualFleet == false) newresult.companyLogoImage = result?.companyLogo?.image ? result.companyLogo?.image : ""
      if (newresult.individual == false) newresult.agreement = result?.contactPerson?.agreement?.image ? result?.contactPerson?.agreement : {}
      if (newresult.individual == false) newresult.agreementImage = result?.contactPerson?.agreement?.image ? result?.contactPerson?.agreement?.image : ""
      if (newresult.individual == true || newresult.individualFleet == true) newresult.license = result?.license ? result?.license : {};
      if (newresult.individual == true || newresult.individualFleet == true) newresult.licenseImage = result?.license?.image ? result.license?.image : "";
      if (!(newresult.individual == false && newresult.individualFleet == false)) newresult.userProfile = result?.contactPerson?.userProfile ? result?.contactPerson?.userProfile : {};
      if (!(newresult.individual == false && newresult.individualFleet == false)) newresult.userProfileImage = result?.contactPerson?.userProfile?.image ? result?.contactPerson?.userProfile?.image : "";
      if (newresult.individual == false && newresult.individualFleet == true) newresult.rcBook = result?.contactPerson?.fleets[0]?.rcBook ? result?.contactPerson?.fleets[0]?.rcBook : {};
      if (newresult.individual == false && newresult.individualFleet == true) newresult.rcBookImage = result?.contactPerson?.fleets[0]?.rcBook?.image ? result?.contactPerson?.fleets[0]?.rcBook?.image : "";
      if (newresult.individual == false && newresult.individualFleet == true) newresult.insurance = result?.contactPerson?.fleets[0]?.insurance ? result?.contactPerson?.fleets[0]?.insurance : {};
      if (newresult.individual == false && newresult.individualFleet == true) newresult.insuranceImage = result?.contactPerson?.fleets[0]?.insurance?.image ? result?.contactPerson?.fleets[0]?.insurance?.image : "";
      if (newresult.individual == false && newresult.individualFleet == true) newresult.allIndiaPermit = result?.contactPerson?.fleets[0]?.allIndiaPermit ? result?.contactPerson?.fleets[0]?.allIndiaPermit : {};
      if (newresult.individual == false && newresult.individualFleet == true) newresult.allIndiaPermitImage = result?.contactPerson?.fleets[0]?.allIndiaPermit?.image ? result?.contactPerson?.fleets[0]?.allIndiaPermit?.image : "";
      if (newresult.individualFleet === true) newresult.individual = true;
      if (newresult.individualFleet === true) newresult.settledBranch = result.settledBranch
      if (newresult.individual === true || newresult.individualFleet === true) {

        newresult.licenseExpiry = result.licenseExpiry;

        if (
          newresult.individualFleet === true &&
          result.contactPerson &&
          result.contactPerson.fleets &&
          result.contactPerson.fleets.length > 0
        ) {
          newresult.bodyType = result.contactPerson.fleets[0].bodyType;
          newresult.regNumber = result.contactPerson.fleets[0].regNumber;
          newresult.fleetType = result.contactPerson.fleets[0].fleetType;
          newresult.assetSubType = result.contactPerson.fleets[0].assetSubType?._id;
          newresult.modelNumber = result.contactPerson.fleets[0].modelNumber;
          newresult.capacity = result.contactPerson.fleets[0].capacity;
          newresult.settledBranch = result.settledBranch;
          newresult.distanceCovered =
            result.contactPerson.fleets[0].distanceCovered;
          newresult.ownerShip = result.contactPerson.fleets[0].ownerShip;
          newresult.idealFuel = result.contactPerson.fleets[0].idealFuel;
          newresult.insuranceDate = result.contactPerson.fleets[0].insuranceDate
            ? result.contactPerson.fleets[0].insuranceDate
            : new Date();
          newresult.fitnessDate = result.contactPerson.fleets[0].fitnessDate
            ? result.contactPerson.fleets[0].fitnessDate
            : new Date();
          newresult.permitDate = result.contactPerson.fleets[0].permitDate
            ? result.contactPerson.fleets[0].permitDate
            : new Date();
          newresult.emiDate = result.contactPerson.fleets[0].emiDate
            ? result.contactPerson.fleets[0].emiDate
            : new Date();
          newresult.allowedOFDCities =
            result.contactPerson.fleets[0].allowedOFDCities;
          newresult.volume = result.contactPerson.fleets[0].volume;
          newresult.businessType = result.contactPerson.fleets[0].businessType;
          newresult.overhead = result.contactPerson.fleets[0].overhead;
          newresult.fleetId = result.contactPerson.fleets[0]._id;
          newresult.noOfSeats = result.contactPerson.fleets[0].noOfSeats;
          newresult.fuelType = result.contactPerson.fleets[0].fuelType;
        } else {
          newresult.bodyType = "";
          newresult.regNumber = "";
          newresult.fleetType = "";
          newresult.assetSubType = "";
          newresult.modelNumber = "";
          newresult.capacity = "";
          newresult.settledBranch = "";
          newresult.distanceCovered = "";
          newresult.ownerShip = "";
          newresult.idealFuel = "";
          newresult.insuranceDate = new Date();
          newresult.fitnessDate = new Date();
          newresult.permitDate = new Date();
          newresult.emiDate = new Date();
          newresult.allowedOFDCities = "";
          newresult.volume = { l: "", b: "", h: "" };
          newresult.businessType = "";
          newresult.overhead = "";
          newresult.fleetId = "";
          newresult.noOfSeats = "";
          newresult.fuelType = "";
        }
      } else {
        newresult.kind = result.kind;
        newresult.url = result.url;
        newresult.email = result.email;
      }
      await dispatch(setCompanyDoc(newresult));
      dispatch(hideLoader());
    } catch (err : any) {
      console.log(err);
      dispatch(hideLoader());
      showMessage("Couldn't fetch company data", failed);
    }
  };
};

export const setCompanyDoc = (company: any) => {
  return {
    type: SET_COMPANY_DOC,
    payload: {
      ...company,
      errors: {},
    },
  };
};

export const clearCompanyDoc = (individual: any) => {
  return {
    type: CLEAR_COMPANY_DOC,
    payload: {
      individual: individual,
    },
  };
};

export const changeSelectedCitiesIndividual = (data: any) => ({
  type: "CHANGE_OFD_CITIES_INDIVIDUAL",
  payload: data,
});
