import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { showHttpError } from "../../utils/message";
import { showLoader, hideLoader } from "../../actions/UserActions";
import { RootState } from "../../store";
import ImageInput from "../../Components/ImageInput";
import {
  createComplaintGuest,
  createComplaintUser,
} from "../../services/complaint";
import jsonToFormdata from "../../utils/jsonToFormdata";
import { Button, Dialog, Select, TextArea, TextField } from "../../newSrc/components/UI";
import { CloseButton, SubmitButton } from "../../Components/Button/AllButton";


const Complaint = (props: any) => {
  const [openComplaint, setOpenComplaint] = useState(false);
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [details, setDetails] = useState("");
  const [image, setImage] = useState("");
  const [complaintType, setComplaintType] = useState<any>("");
  const [open, setOpen] = useState(false);
  const [customerType, setCustomerType] = useState<any>("");
  const [packageOrder,setPackageOrder] = useState<any>("")
  const [feedBack, setFeedBack] = useState("");
  const [misBehaveUser, setMissBehaveUSer] = useState<any>("");
  const [missBehaveBranch, setMissBehaveBranch] = useState<any>("");
 
  
  const complaintOptions = [
    { label: "Late delivery", value: "LD" },
    { label: "Damage", value: "DMG" },
    // { label: "Receipt Missing", value: "RM" },
    { label: "Missing", value: "PM" },
    {
      label: "Pilferage",
      value: "PF",
    },
    {
      label: "Misbehavior",
      value: "MB",
    },
    // { label: "Late Pickup ", value: "LP" },
    // { label: "Other ", value: "OT" },
  ];

  const customerOptions = [
    { label: "Sender", value: "sender" },
    { label: "Receiver", value: "receiver" },
    { label: "Other", value: "other" },
  ];
 
  const handleSubmit = async () => {
    props.showLoader();
    try {
      if(details === ""){
        props.hideLoader();
        return showHttpError(`Remarks Should Not Be Empty !~`);
      }
      let payload: any = {
        feedbackType: "COMP",
        complaintType: complaintType && complaintType.value,
        docketNumber: props.docketNumber,
        contactType: customerType && customerType.value,
        contactNumber: contact,
        contactName: name,
        messageText: details,
      };
      if (missBehaveBranch && missBehaveBranch.value) {
        payload.misbehaveBranch = missBehaveBranch.value;
      }
      if (misBehaveUser && misBehaveUser.value) {
        payload.misbehaveBy = misBehaveUser.value;
      }
      if (image) {
        payload.media = image;
      }
      const response: any = props.user.uid
        ? await createComplaintUser(payload)
        : await createComplaintGuest(jsonToFormdata(payload));
      setFeedBack(response.feedbackNumber);
      setOpen(true);
      props.hideLoader();
    } catch (err : any) {
      showHttpError(err);
      props.hideLoader();
    }
  };

  const handleClose = () => {
    setOpen(false);
    handleComplaintClose();
  };

  const handleComplaintClose = () => {
    setOpenComplaint(false);
    setName("");
    setContact("");
    setDetails("");
    setComplaintType("");
    setImage("");
    setCustomerType("");
    setFeedBack("");
    setMissBehaveBranch("")
    setMissBehaveUSer("")
    setPackageOrder("")
    props.getGuestUpdatedData(props.docketID);
  };
 const showPkg = ["PM","DMG","PF"]

  return (
    <Fragment>
      <Dialog 
        state={openComplaint}
        onClose={handleComplaintClose}
        header='Register Complaint'
        size="small"
        body={
          
          <div className="grid gap-4">
            <Select
              label='Select type of Complaint '
              value={complaintType}
              options={complaintOptions}
              onChange={(e: any) => {
                setComplaintType(e)
                setMissBehaveUSer("");
                setMissBehaveBranch("");
                setPackageOrder("")
              }}
              placeholder="Select Complaint"
            />
           
            {showPkg.includes(complaintType?.value) &&
              <Select
                value={packageOrder}
                options={props?.orderArrayOption ||[]}
                onChange={(e: any) => setPackageOrder(e)}
                isMulti={true}
                placeholder="Select Packages"
                label="Packages"
              />
           
            }
              <Select
                value={customerType}
                options={customerOptions}
                onChange={(e: any) => setCustomerType(e)}
                placeholder="Select Customer"
                label='Contact Person'
              />
            {customerType && customerType.value === "other" && (
                <TextField
                  value={name}
                  label="Name"
                  placeholder="Enter Name"
                  onChange={(e: any) => setName(e.target.value)}
                />
            )}

            {customerType && customerType.value === "other" && (
                <TextField
                  value={contact}
                  placeholder="Enter Mobile Number"
                  label="Mobile Number"
                  onChange={(e: any) => setContact(e.target.value)}
                />
          
            )}

            <TextArea
            label=" Detailed Information"
              value={details}
              placeholder="Provide detail information regarding your issue"
              onChange={(e: any) => setDetails(e.target.value)}
            />
           
            {complaintType && complaintType.value === "MB" && (
              <Select
                label="Branch"
                placeholder="Select Branch"
                options={props?.branchOptions || []}
                value={missBehaveBranch}
                onChange={(e: any) => setMissBehaveBranch(e)}
              />
            )}
            {complaintType && complaintType.value === "MB" && (
                <Select
                  value={misBehaveUser}
                  label="Select User"
                  placeholder="Select User"
                  options={props?.userOptions ||[]}
                  onChange={(e: any) => setMissBehaveUSer(e)}
                />
            )}
            <ImageInput
              name="Add Image"
              label="Attach Image"
              Icon={(props: any) => (
                <i {...props} className={"fas fa-id-card file_icon"} />
              )}
              onChange={(e: any) => setImage(e)}
              value={image}
              extras={<div></div>}
            />
          </div>
      
        }
        footer={
          <div className="flex gap-1 ">
          <SubmitButton variant="secondary" action="secondary" onClick={() => {
              if(details?.length <= 0 ){
                return showHttpError(`Kindly Enter Remarks regarding Complaint.`)
              }
              else{
                handleSubmit();
              }
          }}/>
          <CloseButton onClick={handleComplaintClose} />
        </div>
        }
      
      />

      <Dialog 
        state={open} 
        onClose={handleClose}
        size="small" 
        header='Complaint Details'
        body={
          <div>
          {
            "Your Complaint has been registered, we will get back to you shortly."
          }
          <br />
          Your Complaint Number is {feedBack}.
        </div>
        }
        footer={
          <CloseButton onClick={handleClose}/>
        }
      />

      {(!props.chat || !props.chat.COMP) && (
        <Button className="m-w-100" variant="primary" action="primary" onClick={() => setOpenComplaint(true)}>
          Register Complaint
        </Button>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state.user,
});
const mapDispatchToProps = {
  showLoader,
  hideLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(Complaint);
