// react
import React, { useCallback, useEffect, useMemo, useState } from "react";
// ui components
import { Button, Select, Dialog } from "../../../components/UI";
// constants (icon)
import { GetDetailsIcon } from "../../../constants/icons";
import { useWidth } from "../../../hooks/useMobile";
import { StateType } from "../partner-forms/useCustomState";
import DatePicker from "../../../../Components/Datecomponents/DatePicker";
import ContactOTPForm from "../components/CommonForms/ContactOTPForm";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { hideLoader, showLoader } from "../../../../actions/UserActions";
import { toastMessage } from "../../../../Components/showMessages/toastMessage";
import { showHttpError } from "../../../../utils/message";
import { customerLoginByOTP } from "../../../../actions/customerLogin";
import { createFullLoadLead, sendOTPForLandingForms, verifyContact, verifyOTPLandingForms } from "../../../../services/landingPageForms";
import { useNavigate } from "react-router-dom";
import { getAssetTypeByCategoryList, listAssetSubType } from "../../../../services/assetType";
import { getMaterialList } from "../../../../services/intraCityRate";


// type
type StateTypeVal = {
    value: string;
    error: true | false;
    errorMessage?: string;
}

type SelectType = {
    label: string | undefined,
    value: string | undefined
}

type VehicleOption = {
    _id: string;
    label: string;
    value: string;
};

// FC: Full Load Get Estimate Component
export default function FullLoadForm() {
    const dispatch = useAppDispatch()
    // State
    const cityList = [
        { value: '5bb083d7296d79da33653fb6', label: 'Ahmedabad' },
        { value: '5bb083d7296d79da33653f3e', label: 'Surat' }
    ]
    const [date, setDate] = useState("")
    const navigate = useNavigate()
    const [originCity, setOriginCity] = useState<SelectType>()
    const [destCity, setDestCity] = useState<SelectType>()
    const [vehicletype, setVehicletype] = useState<SelectType>()
    const [vehicleSubtype, setVehicleSubtype] = useState<SelectType>()
    const [cityOptions, setCityOptions] = useState<any[]>(cityList)
    const [isContactDialogOpen, setIsContactDialogOpen] = useState<boolean>(false)
    const [contact, setContact] = useState<StateType>("")
    const [materialTypeList, setMaterialTypeList] = useState<any>([]);

    const [otp, setOTP] = useState<StateType>("");
    const [materialType, setMaterialType] = useState<SelectType>()
    const [isOTPClick, setIsOTPClick] = useState<boolean>(false)
    const [name, setName] = useState<StateType>("");
    const [isCustomer, setIsCustomer] = useState<boolean>(false)

    const [assetTypeList, setAssetTypeList] = useState<Array<VehicleOption>>([]);
    const [assetSubTypeList, setAssetSubTypeList] = useState<
        Array<VehicleOption>
    >([]);

    const vehicleTypeOptions = useMemo(() => assetTypeList, [assetTypeList]);
    const vehicleSubTypeOptions = useMemo(
        () => assetSubTypeList,
        [assetSubTypeList]
    );

    // const [quantity, setQuantity] = useState<StateTypeVal>(() => ({
    //     value: "",
    //     error: false,
    //     errorMessage: ""
    // }));
    const [weight, setWeight] = useState<StateTypeVal>(() => ({
        value: "",
        error: false,
        errorMessage: ""
    }));
    // const [vehicleNumber, setVehicleNumber] = useState<StateTypeVal>(() => ({
    //     value: "",
    //     error: false,
    //     errorMessage: ""
    // }));
    const { isMobile } = useWidth();


    // does: validate quantity
    // function quantityValidation(e: React.FocusEvent<HTMLInputElement, Element>) {
    //     const quantityValue = e.target.value;
    //     if (quantityValue === "0") {
    //         return setQuantity(prevQuantity => {
    //             return { ...prevQuantity, error: true, errorMessage: "Quantity should be greater than 0" }
    //         })
    //     }
    //     if (!quantityValue || quantityValue == null || quantityValue === " ") {
    //         return setQuantity(prevQuantity => {
    //             return { ...prevQuantity, error: true, errorMessage: "Quantity is required!" }
    //         })
    //     } else {
    //         return setQuantity(prevQuantity => {
    //             return { ...prevQuantity, error: false }
    //         })
    //     }
    // }
    // does: validate weight
    function weightValidation(e: React.FocusEvent<HTMLInputElement, Element>) {
        const weightValue = e.target.value;
        if (weightValue === "0") {
            return setWeight(prevWeight => {
                return { ...prevWeight, error: true, errorMessage: "Weight should be greater than 0" }
            })
        }
        if (!weightValue || weightValue == null || weightValue === " ") {
            return setWeight(prevWeight => {
                return { ...prevWeight, error: true, errorMessage: "Weight is required!" }
            })
        } else {
            return setWeight(prevWeight => {
                return { ...prevWeight, error: false }
            })
        }
    }

    const backButtonClick = () => {
        setIsContactDialogOpen(false)
        setContact("")
    }

    const intracityMaterialTypeList = async () => {
        dispatch(showLoader())
        try {
            let payload: any = { type: "intraCity" };
            let response = await getMaterialList("", payload);
            setMaterialTypeList(
                response?.map((x: any) => {
                    return { value: x._id, label: x.name };
                })
            );

            dispatch(hideLoader())
        } catch (error) {
            showHttpError(error);
            dispatch(hideLoader())
        }
    };

    const verifyOTP = async () => {
        dispatch(showLoader());
        let deviceId = localStorage.getItem("deviceId");
        const payload = {
            contact,
            OTP: otp,
            rememberMe: true,
            deviceId
        };

        try {
            const res = await verifyOTPLandingForms(payload);
            if (res.accessToken && res.customer) {
                dispatch(customerLoginByOTP(Number(contact), otp as string, true, deviceId ? deviceId : undefined))
                navigate("/customerDashboard/booking")
                setIsOTPClick(false)
                setIsContactDialogOpen(false)
                setIsCustomer(false)
                toastMessage("Customer Login Successfully", {
                    theme: "colored",
                    type: "success",
                });
            }
            else {
                toastMessage("failed to Login", {
                    theme: "colored",
                    type: "error",
                });
            }
            console.log(res, "response11111111");

        } catch (error) {
            showHttpError(error);
            return error;
        } finally {
            dispatch(hideLoader());
        }
    };

    const handleContactSubmit = async () => {
        dispatch(showLoader());

        const payload = { contact };

        try {
            const res = await verifyContact(payload);

            if (res) {

                const leadPayload = {
                    originCity: originCity?.value,
                    destCity: destCity?.value,
                    date,
                    materialType: materialType?.value,
                    // qty: quantity.value,
                    assetType: vehicletype?.value,
                    assetSubType: vehicleSubtype?.value,
                    weight: weight.value,
                    contact
                }

                await sendOTPForLandingForms(payload)
                await createFullLoadLead(leadPayload);
                // setPackagesFeature('qty', parseInt(quantity.value), val.id)
                // setPackagesFeature('materialType', materialType, val.id)
                // navigate("/login", {
                //   state: {
                //     action: "isBookedWithoutToken",
                //     data: { contact },
                //   },
                // });
                toastMessage("OTP sent successfully", {
                    theme: "colored",
                    type: "success",
                });
            } else {
                // setActivePage("customer");
                await sendOTPForLandingForms(payload)
                setIsCustomer(true)
                toastMessage("Please Register Customer", {
                    theme: "colored",
                    type: "error",
                });
            }

            console.log(res, "response11111111");

        } catch (error) {
            showHttpError(error);
            return error;
        } finally {
            dispatch(hideLoader());
        }
    };

    const fetchAssetTypes = useCallback(async () => {
        try {
            dispatch(showLoader());
            const response = await getAssetTypeByCategoryList({
                categoryId: "5c304e42296d79da335ada16",
            });
            const uniqueAssetTypes = response.reduce(
                (acc: VehicleOption[], currentValue: any) => {
                    if (!acc.some((item) => item.label === currentValue.assetName)) {
                        acc.push({
                            _id: currentValue._id,
                            label: currentValue.assetName,
                            value: currentValue._id,
                        });
                    }
                    return acc;
                },
                []
            );
            setAssetTypeList(uniqueAssetTypes);
            setAssetSubTypeList([]);
        } catch (error) {
            console.error("Error fetching asset types", error);
        } finally {
            dispatch(hideLoader());
        }
    }, [dispatch]);

    const fetchAssetSubTypes = useCallback(async () => {
        // if (!values.vehicleDetails.assetType) return; // Exit early if no vehicle type is selected

        try {
            dispatch(showLoader());
            const response = await listAssetSubType();
            const assetSubTypeOptions = response.reduce(
                (acc: VehicleOption[], currentValue: any) => {
                    if (
                        currentValue.assetType._id ===
                        vehicletype?.value &&
                        !acc.some((item) => item._id === currentValue.assetType._id)
                    ) {
                        acc.push({
                            _id: currentValue._id,
                            label: currentValue.name,
                            value: currentValue._id,
                        });
                    }
                    return acc;
                },
                []
            );
            setAssetSubTypeList(assetSubTypeOptions);
        } catch (error) {
            console.error("Error fetching asset subtypes", error);
        } finally {
            dispatch(hideLoader());
        }
    }, [vehicletype, dispatch]);

    useEffect(() => {
        fetchAssetSubTypes();
    }, [fetchAssetSubTypes]);

    useEffect(() => {
        fetchAssetTypes();
    }, [fetchAssetTypes]);

    useEffect(() => {
        if (isOTPClick) {
            handleContactSubmit()
        }
    }, [isOTPClick])

    useEffect(() => {
        let destOptions: any = cityList?.filter((x: any) => x?.value !== originCity?.value);
        setCityOptions(destOptions);
    }, [originCity])

    useEffect(() => {
        intracityMaterialTypeList();
    }, []);

    // component return
    return <div className={`| grid gap-1 ${isMobile ? "paper mt-1" : ""}`}>
        {isMobile && <div className="flex-col gap-8">
            <h1 className="fs-heading text-left fw-900 text-primary-400">Get an Estimate</h1>
            {/* <p className="text-center">Delivery your less than truck load to another cities with door pickup and door delivery service</p> */}
        </div>}
        <div className="| four-column gap-1 t-one-column">
            <Select
                value={originCity}
                options={cityOptions}
                isClearable={true}
                isSearchable={true}
                placeholder={"Source Location"}
                onChange={(e: any) => setOriginCity(e)}
            />
            <Select
                value={destCity}
                options={cityOptions}
                isClearable={true}
                isSearchable={true}
                placeholder={"Destination Location"}
                onChange={(e: any) => setDestCity(e)}
            />
            <Select isClearable={true} isSearchable={true} placeholder={"Vehicle Type"}
                options={vehicleTypeOptions}
                value={vehicletype}
                onChange={(e: any) => setVehicletype(e)}
            />
            <Select isClearable={true} isSearchable={true} options={vehicleSubTypeOptions} placeholder={"Vehicle Sub Type"}
                value={vehicleSubtype}
                onChange={(e: any) => setVehicleSubtype(e)}
            />
        </div>
        <div className="| four-column gap-1 t-one-column">
            {/* <Select isClearable={true} isSearchable={true} options={options} placeholder={"Vehicle Model"} /> */}
            {/* <input type="date" name="full-load-date" id="full-load-date" className="| p-8 border-white outline-none br-4" /> */}
            <Select isClearable={true} isSearchable={true} options={materialTypeList} placeholder={"Select Material Type"}
                onChange={(e: any) => setMaterialType(e)} />
            {/* <div className="| flex-col">
                <input type="number" placeholder="Quantity" name="full-load-quantity" id="full-load-quantity" value={quantity.value} onChange={e => setQuantity(prevQuantity => {
                    return { ...prevQuantity, value: e.target.value }
                })} onBlur={quantityValidation} className="| p-8 br-4 border-white outline-none" min={0} />
                <p className="| text-danger-400 fs-extrasmall" style={{ marginTop: "2px" }}>{quantity.error && quantity.errorMessage}</p>
                </div> */}
            <div className="flex-col">
                <input type="number" placeholder="Weight(KG)" name="full-load-weight" id="full-load-weight" value={weight.value} onChange={e => setWeight(prevWeight => {
                    return { ...prevWeight, value: e.target.value }
                })} onBlur={weightValidation} className="| p-8 br-4 border-white outline-none" min={0} />
                <p className="| text-danger-400 fs-extrasmall" style={{ marginTop: "2px" }}>{weight.error && weight.errorMessage}</p>
            </div>
            {/* <input type="number" placeholder="Number Of Vehicle" name="full-load-vehicle-number" id="full-load-vehicle-number" value={vehicleNumber.value} onChange={e => setVehicleNumber(prevVehicleNumber => {
                return { ...prevVehicleNumber, value: e.target.value }
            })} className="| p-8 br-4 border-white outline-none" min={0} /> */}
            <DatePicker
                selected={date !== '' ? date : new Date()}
                placeholderText="Select Date"
                dateFormat="dd-MM-yyyy"
                name={"pickupDate"}
                // disabled={(originCity === undefined || destCity === undefined) ? true : false}
                value={date}
                className="schedule-your-booking-input py-8 px-1 br-4 border-white react-datepicker-wrapper"
                minDate={new Date().toISOString().split("T")[0]}
                onChange={(e: any) => { setDate(e) }}
                fullWidth={true}
                isClearable={false}
            />
            <Button variant="secondary" action="secondary" Icon={<GetDetailsIcon />}
                onClick={() => { setIsContactDialogOpen(true) }}
                fullWidth={isMobile ? true : false} className={`| as-start fs-button ${isMobile ? "flex-center" : ""}`}
                disabled={(originCity === undefined || destCity === undefined || materialType === undefined || vehicletype === undefined ||
                    vehicleSubtype === undefined || date === ''
                    || weight.value === '') ? true : false}
            >Get Estimated Rate</Button>
        </div>
        {/* <div className="| four-column gap-1 t-one-column">
        </div> */}

        <Dialog
            header={"Verify Contact"}
            body={
                <ContactOTPForm
                    isOTPModal={isContactDialogOpen}
                    contact={contact}
                    setContact={setContact}
                    otp={otp}
                    setOTP={setOTP}
                    name={name}
                    setName={setName}
                    isCustomer={isCustomer}
                    setIsCustomer={setIsCustomer}
                    isOTPClick={isOTPClick}
                    setIsOTPClick={setIsOTPClick}
                />
            }
            footer={
                <div className="flex gap-1">
                    <div className="flex gap-1">
                        <div className="flex gap-1">
                            <Button onClick={() => {
                                backButtonClick()
                            }} variant="info" action="secondary" type="button">
                                close
                            </Button>
                        </div>
                        {
                            isOTPClick ? <div className="flex gap-1">
                                <Button onClick={() => {
                                    verifyOTP()
                                }} variant="primary" action="secondary" type="button">
                                    submit
                                </Button>
                            </div> : null
                        }

                    </div>
                </div>
            }
            size="regular"
            state={isContactDialogOpen}
            setState={setIsContactDialogOpen}
            closeIcon
        ></Dialog>
    </div>
}