// react
import React, { useEffect, useState } from "react";
// ui component
import { Button, Dialog, Select } from "../../../components/UI";
// constants (icons)
import { GetDetailsIcon } from "../../../constants/icons";
import AutoComplete from "../../../../Components/AutoComplete";
import {
  getIntraCityBookingPriceEstimated,
  getIntraCityRates,
} from "../../../../services/intraCityRate";
import { showHttpError } from "../../../../utils/message";
import IntraCityRates, {
  IntraCityRateEstimateItemType,
} from "./IntraCityRates";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import ContactOTPForm from "../components/CommonForms/ContactOTPForm";
import { hideLoader, showLoader } from "../../../../actions/UserActions";
import { createIntracityLead, sendOTPForLandingForms, verifyContact } from "../../../../services/landingPageForms";
import { StateType } from "../partner-forms/useCustomState";
import { useNavigate } from "react-router-dom";
import { SET_INTRACITY } from "../../../../constants/customerBooking";
import { toastMessage } from "../../../../Components/showMessages/toastMessage";
import { setIntraCityPickupDropLocation } from "../../../../actions/customerBooking";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { customerLoginByOTP } from "../../../../actions/customerLogin";
import { useWidth } from "../../../hooks/useMobile";

// FC: Intra City Get Estimate Component
export default function IntraCityForm() {
  const [cities, setCities] = useState<any>(null);
  const { isMobile } = useWidth();
  const [selectedCity, setSelectedCity] = useState<any>(null);
  const [sourceLocation, setSourceLocation] = useState<any>(null);
  const [clearSourceLocation, setClearSourceLocation] = useState<any>(false);
  const [destinationLocation, setDestinationLocation] = useState<any>(null);
  const [clearDestLocation, setClearDestLocation] = useState<any>(false);
  const [selectedEstimatedRate, setSelectedEstimatedRate] =
    useState<number>(-1);
  const [isIntraCityRateDialogOpen, setIsIntraCityRateDialogOpen] =
    useState<boolean>(() => false);
  const [
    intraCityBookingPriceEstimateArray,
    setIntraCityBookingPriceEstimateArray,
  ] = useState<IntraCityRateEstimateItemType[]>(() => []);
  const [selectedAssetType, setSelectedAssetType] =
    useState<IntraCityRateEstimateItemType>({
      _id: "",
      assetSubType: {
        name: "",
        assetType: {
          assetName: "",
          _id: "",
        }
      },
      distance: 0,
      durations: 0,
      availableFreeMinutes: 0,
      perMinuteCharge: 0,
      price: 0,
    });
  const [isOTPModal, setIsOTPModal] =
    useState<boolean>(false);
  const dispatch = useAppDispatch()
  const [contact, setContact] = useState<StateType>("");
  const [otp, setOTP] = useState<StateType>("");
  const [name, setName] = useState<StateType>("");
  const [isOTPClick, setIsOTPClick] = useState<boolean>(false)
  const [isCustomer, setIsCustomer] = useState<boolean>(false)
  // const { setActivePage } = useLandingPageContext();
  const navigate = useNavigate()
  const {
    intracity
  } = useAppSelector(state => {
    return {
      intracity: state.customerBooking?.intraCity
    }
  })

  // find cities options
  const citiesOption = Array.from(new Set(cities?.map((e: any) => e.name))).map(
    (name) => {
      const city = cities.find((e: any) => e.name === name);
      return {
        label: city.name,
        value: city._id,
      };
    }
  );

  // does: get the city list
  async function getCityList() {
    try {
      let payload = { type: "EXPRESS" };
      const responseForCity = await getIntraCityRates(payload);
      // Using a Map to ensure uniqueness based on _id
      const cityMap = new Map();
      responseForCity.forEach((item: any) => {
        // Skip the item if city is undefined
        if (item.city) {
          const city = item.city;
          if (!cityMap.has(city._id)) {
            cityMap.set(city._id, { _id: city._id, name: city.name });
          }
        }
      });
      // Converting the Map values to an array
      const uniqueCities = Array.from(cityMap.values());
      setCities(uniqueCities);
    } catch (error) {
      showHttpError(error);
    }
  }

  // does: handle submit
  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    dispatch(showLoader())
    try {
      e.preventDefault()
      if (!sourceLocation || !destinationLocation || !selectedCity.label) {
        return alert("All details are required!");
      }
      // const payload = {
      //   city: selectedCity.label,
      //   toPlace: "ChIJD21ZqI-GXjkRBbphR_iIwd4",
      //   fromPlace: "ChIJ5TEbRcmGXjkR3nDjntT5teI",
      // };
      const payload = {
        city: selectedCity?.label,
        toPlace: destinationLocation?.place_id,
        fromPlace: sourceLocation?.place_id,
      };
      const response = await getIntraCityBookingPriceEstimated(payload);
      const data: IntraCityRateEstimateItemType[] = response.price.map(
        (item: any) => {
          return {
            assetSubType: item.assetSubType,
            price: item.price,
            availableFreeMinutes: item?.availableFreeMinutes,
            perMinuteCharge: item.perMinuteCharge,
            _id: item.vehicleType,
            assetType: item?.assetSubType?.assetType,
            distance: response?.distance,
            durations: response?.duration
          };
        }
      );
      setIntraCityBookingPriceEstimateArray(data);
      if (response) {
        setIsIntraCityRateDialogOpen(true);
      }
    } catch (error) {
      showHttpError("Intra city booking rate error");
    }
    finally {
      dispatch(hideLoader())
    }
  }

  const handleContactSubmit = async () => {
    dispatch(showLoader());

    const payload = { contact };

    try {
      const res = await verifyContact(payload);

      if (res) {
        const leadPayload = {
          city: selectedCity?.value,
          fromPlace: { ...intracity?.pickDropLocation?.[0]?.place },
          toPlace: { ...intracity?.pickDropLocation?.[1]?.place },
          date: new Date(),
          assetType: selectedAssetType?.assetSubType?.assetType?._id,
          contact
        }
        await sendOTPForLandingForms(payload)

        await createIntracityLead(leadPayload);
        dispatch({
          type: SET_INTRACITY,
          payload: {
            what: "locationDataFromLandingForm",
            val: {
              selectedCity: selectedCity,
              sourceLocation,
              destinationLocation
            },
          },
        });
        dispatch({
          type: SET_INTRACITY,
          payload: {
            what: "isBookedWithoutToken",
            val: true,
          },
        });
        dispatch({
          type: SET_INTRACITY,
          payload: {
            what: "lead",
            val: leadPayload,
          },
        });
        // navigate("/login", {
        //   state: {
        //     action: "isBookedWithoutToken",
        //     data: { contact },
        //   },
        // });
        toastMessage("OTP sent successfully", {
          theme: "colored",
          type: "success",
          autoClose: 1000
        });
        // } else {
      }
      else {
        // setActivePage("customer");
        await sendOTPForLandingForms(payload)
        setIsCustomer(true)
        toastMessage("Please Register Customer", {
          theme: "colored",
          type: "error",
        });
      }

    } catch (error) {
      showHttpError(error);
      return error;
    } finally {
      dispatch(hideLoader());
    }
  };

  const verifyOTP = async () => {
    dispatch(showLoader());
    let deviceId = localStorage.getItem("deviceId");
    const payload: {
      contact: number,
      OTP: string,
      rememberMe: boolean,
      deviceId?: string,
      name?: string
    } = {
      contact: Number(contact),
      OTP: otp as string,
      rememberMe: true
    };

    if (deviceId) {
      payload.deviceId = deviceId
    }
    if (name) {
      payload.name = name as string
    }

    try {
      // const res = await verifyOTPLandingForms(payload);
      const res: any = await dispatch(customerLoginByOTP(Number(contact), otp as string, true, deviceId ? deviceId : undefined, name ? name as string : ""))

      if (res.data?.response?.accessToken && res.data?.response?.customer) {
        navigate("/customerDashboard/booking")
        setIsOTPModal(false)
        setIsOTPClick(false)
        setIsCustomer(false)
      }
    } catch (error) {
      navigate("/")
      showHttpError(error);
      return error;
    } finally {
      dispatch(hideLoader());
    }
  };


  const backButtonClick = () => {
    setIsOTPModal(false)
    setContact("")
  }

  // useEffect
  // does: get the city list and vechile type list
  useEffect(() => {
    getCityList();
  }, []);

  useEffect(() => {
    if (isOTPClick) {
      handleContactSubmit()
    }
  }, [isOTPClick])

  const multipleExist = (arr: any, values: any) => {
    return values.every((value: any) => {
      return arr.includes(value);
    });
  };

  const matchCity = (e: any, from: any) => {
    let city = e.address_components.find((x: any) => {
      return (
        x.types.length === 2 && multipleExist(x.types, ["locality", "political"])
      );
    });

    let additionCity = e.address_components.find((x: any) => {
      // console.log(`zdmjkfjvvf`, x, `jhkSbv`, e);
      return (
        x.types.length === 2 && multipleExist(x.types, ["administrative_area_level_3", "political"])
      );
    });

    // console.log("sdfvjkdnhvflzdfvb 0.1", additionCity, ` Sdvsdv`, city);
    if (
      ((city?.long_name !== selectedCity?.label) || (city?.short_name !== selectedCity?.label))
    ) {
      // console.log("sdfvjkdnhvflzdfvb 0.2", additionCity, ` Sdvsdv`, city);
      if (((additionCity?.long_name !== selectedCity?.label) || (additionCity?.short_name !== selectedCity?.label))) {
        // console.log("sdfvjkdnhvflzdfvb 0.3", additionCity, ` Sdvsdv`, city);
        if (from == 'source') {
          return false;
        }
        if (from == 'dest') {
          return false;
        }
      }
    }
    return true
  }

  const setLocationByTypingRes = (e: any, index: number, from: any) => {

    // console.log(`zdmjkfjvvf 1.0`, e);
    let city = e.address_components.find((x: any) => {
      // console.log(`zdmjkfjvvf`, x, `jhkSbv`, e);
      return (
        x.types.length === 2 && multipleExist(x.types, ["locality", "political"])
      );
    });

    // let additionCity = e.address_components.find((x: any) => {
    //   // console.log(`zdmjkfjvvf`, x, `jhkSbv`, e);
    //   return (
    //     x.types.length === 2 && multipleExist(x.types, ["administrative_area_level_3", "political"])
    //   );
    // });

    // console.log(`zdmjkfjvvf 30`, city);
    // if (
    //   ((city?.long_name !== selectedCity?.label) || (city?.short_name !== selectedCity?.label))
    //   && ((additionCity?.long_name !== selectedCity?.label) || (additionCity?.short_name !== selectedCity?.label))
    // ) {
    //   if (from == 'source') {
    //     setClearSourceLocation(true);
    //     setSourceLocation(null);
    //     return showHttpError(`City Should Be Same!`);
    //   }
    //   if (from == 'dest') {
    //     setClearDestLocation(true);
    //     setDestinationLocation(null);
    //     return showHttpError(`City Should Be Same!`);
    //   }
    // }
    if (from == 'source') {
      setSourceLocation({ name: e.name, place_id: e.place_id, data: e });
    }
    if (from == 'dest') {
      setDestinationLocation({ name: e.name, place_id: e.place_id, data: e });
    }
    // let area = e.address_components.find((x: any) => {
    //   return multipleExist(x.types, [
    //     "sublocality_level_1",
    //     "sublocality",
    //     "political",
    //   ]);
    // });

    const setPickDropFeature = (name: any, value: any, index: any) => {
      dispatch(setIntraCityPickupDropLocation(name, value, index));
    };

    // let pincode = e.address_components.find((x: any) => {
    //   return multipleExist(x.types, ["postal_code"]);
    // });
    let state = e.address_components.find((x: any) => {
      return multipleExist(x.types, ["administrative_area_level_1", "political"]);
    });

    setPickDropFeature("place.placeId", e.place_id, index);
    setPickDropFeature(
      "place.coordinate.latitude",
      e.geometry.location.lat,
      index
    );
    setPickDropFeature(
      "place.coordinate.longitude",
      e.geometry.location.lng,
      index
    );
    // console.log(`nhSBJdvsdv`, city.long_name, `ZJKDFVDF`, pincode, `jhSDdbcv`, area, `jzdfvbh`, state);
    setPickDropFeature("place.placeValue", e.name, index);
    setPickDropFeature("place.formatted_address", e.formatted_address, index);
    setPickDropFeature("place.name", e.name, index);
    setPickDropFeature("place.city", city.long_name, index);
    setPickDropFeature("place.state", state.long_name, index);
    setPickDropFeature("contactPerson.state", state.long_name, index);
    // if (pincode.long_name) {
    // setPickDropFeature("place.area", area.long_name, index);
    //   setPickDropFeature("place.pincode", pincode.long_name, index);
    //   setPickDropFeature("contactPerson.pincode", pincode.long_name, index);
    //   setPickDropFeature(
    //     "contactPerson.isDisabled",
    //     state.long_name && city.long_name && pincode.long_name ? true : false,
    //     index
    //   );
    // }
    setPickDropFeature("contactPerson.city", city.long_name, index);
    setPickDropFeature("contactPerson.floor", "", index);
    setPickDropFeature("contactPerson.officeName", "", index);
    setPickDropFeature("contactPerson.l1", "", index);
    setPickDropFeature("contactPerson.l2", "", index);
    setPickDropFeature("contactPerson.nearby_landmark", "", index);
  };

  useEffect(() => {
    if (selectedCity === null) {
      setClearSourceLocation(true);
      setClearDestLocation(true);
    }
    if (selectedCity !== null) {
      setClearSourceLocation(false);
      setClearDestLocation(false);
    }
  }, [selectedCity])

  return (
    <>
      <form onSubmit={handleSubmit} className="four-column  gap-1 w-100 paper m-flex-col">
        {isMobile && <h1 className="| fs-heading fw-900 text-primary-400 text-left  m-0 capitalize">
          Get an Estimate
        </h1>}
        <Select
          isClearable={true}
          isSearchable={true}
          options={citiesOption}
          value={selectedCity}
          onChange={(e: any) => setSelectedCity(e)}
          placeholder={"Select Your City"}
        />
        {/* <Select isClearable={true} isSearchable={true} options={options} placeholder={"Source Location"} /> */}
        <AutoComplete
          placeHolder={"Source Location..."}
          value={sourceLocation && sourceLocation.name}
          isDisabled={selectedCity === null ? true : false}
          onChange={(e: any) => {
            let ans = matchCity(e, 'source');
            // console.log(`kbdrvdfv`, ans)
            if (!ans) {
              setClearSourceLocation(true);
              setTimeout(() => setClearSourceLocation(false), 0);
              return showHttpError(`City Should Be Same!`);
            }
            setLocationByTypingRes(e, 0, 'source')
          }}
          clearEverything={clearSourceLocation}
        // setValue={clearValuesFunc}
        ></AutoComplete>
        <AutoComplete
          value={destinationLocation && destinationLocation.name}
          placeHolder={"Destination Location..."}
          isDisabled={selectedCity === null ? true : sourceLocation === null ? true : false}
          onChange={(e: any) => {
            let ans = matchCity(e, 'source');
            // console.log(`kbdrvdfv`, ans)
            if (!ans) {
              setClearDestLocation(true);
              setTimeout(() => setClearDestLocation(false), 0);
              return showHttpError(`City Should Be Same!`);
            }
            setLocationByTypingRes(e, 1, 'dest')
          }}
          clearEverything={clearDestLocation}
        // setValue={clearValuesFunc}
        ></AutoComplete>
        <Button
          type="submit"
          variant="secondary"
          action="secondary"
          Icon={<GetDetailsIcon />}
          className={`as-start fs-button ${isMobile ? "flex-center" : ""}`}
          fullWidth={isMobile ? true : false}
          disabled={!selectedCity || !sourceLocation || !destinationLocation}
        >
          Get Estimated Rate
        </Button>

        {/* Dialog for display Intra City Rates */}
        <Dialog
          header={!isOTPModal ? "Intra City Rates" : "Verify Contact"}
          body={
            !isOTPModal ?
              <IntraCityRates
                setSelectedVehicle={setSelectedAssetType}
                arr={intraCityBookingPriceEstimateArray}
                selectedItem={selectedEstimatedRate}
                setSelectedItem={setSelectedEstimatedRate}
                selectedAssetType={selectedAssetType}
                setSelectedAssetType={setSelectedAssetType}
              /> :
              <ContactOTPForm
                isOTPModal={isOTPModal}
                contact={contact}
                setContact={setContact}
                otp={otp}
                setOTP={setOTP}
                name={name}
                setName={setName}
                isCustomer={isCustomer}
                setIsCustomer={setIsCustomer}
                isOTPClick={isOTPClick}
                setIsOTPClick={setIsOTPClick}
              />
          }
          footer={
            <div className="flex gap-1">
              <div className="flex gap-1">
                {
                  isOTPModal ? <> <div className="flex gap-1">
                    <Button onClick={() => {
                      backButtonClick()
                    }} variant="info" action="secondary" type="button">
                      Back
                    </Button>
                  </div>
                    {
                      isOTPClick ? <div className="flex gap-1">
                        <Button onClick={() => {
                          if ((isCustomer ? name : true) && contact && otp) {
                            verifyOTP()
                          }
                          else {
                            toastMessage("All Fields are Required", {
                              theme: "colored",
                              type: "error",
                              autoClose: 1000
                            })
                          }
                        }} variant="primary" action="secondary" type="button">
                          verify
                        </Button>
                      </div> : null
                    }
                  </> : <div className="flex gap-1">
                    <Button onClick={() => {
                      setIsOTPModal(true)
                    }} variant="primary" action="secondary" type="button">
                      Login To Book Now
                    </Button>
                    <Button onClick={() => {
                      setIsIntraCityRateDialogOpen(false)
                    }} variant="danger" action="secondary" type="button">
                      Close
                    </Button>
                  </div>
                }
              </div>
            </div>
          }
          size="regular"
          state={isIntraCityRateDialogOpen}
          setState={setIsIntraCityRateDialogOpen}
          closeIcon={false}
        ></Dialog>
      </form>
    </>
  );
}
