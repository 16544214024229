import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import React, { lazy, Suspense } from "react";
import { Layout } from "./Layout/Layout";
import PartnerUrl from "./containers/web/Components/PartnerUrl";
import CustomerUrl from "./containers/web/Components/CustomerUrl";
import SubPartners from "./containers/web/Components/SubPartners";
import NewCustomerLoginPage from "./containers/Login/NewCustomerLoginPage";
import Blog from "./containers/web/Components/Blog";
import Faq from "./containers/web/Components/Faq";
import Individual from "./containers/web/Components/Individual";
import InsuaranceFaqs from "./containers/web/Components/InsuaranceFaqs";
import IntraCityForm from "./containers/web/Components/IntraCityForm";
import DomesticForm from "./containers/web/Components/DomesticForm";
import PartloadForm from "./containers/web/Components/PartloadForm";
import FullloadForm from "./containers/web/Components/FullloadForm";
import PackersAndMoversForm from "./containers/web/Components/PackersAndMoversForm";
import InterNationalForm from "./containers/web/Components/InterNationalForm";
import IndivisualRegistration from "./containers/web/Components/indivisualRegistration";
import { Page_404 } from "./Components/ErrorHandling/Page_404/Page_404";
import { useSelector } from "react-redux";
import { RootState } from "./store";
import CustomerReport from "./containers/report/customer/hoc";
import STracking from "./containers/operations/STrack";
import CustomerFirst from "./containers/report/customer/customerFirst";
import TrackAuth from "./containers/TrackingModule/TrackAuth";
import BlindPage from "./containers/TrackingModule/blindPage";
import CustomerTrackView from "./containers/TrackingModule/CustomerTrackView";
import CustomerTrackLogsDetails from "./containers/CustomerSection/CustomerBookingTracking/CustomerTrackLogsDetails";
import CustomerTrackMobileView from "./containers/TrackingModule/CustomerTrackMobileView";
import TriggerExample from "./googleTagManager/TagManager";
import PaymentGateWaySuccess from "./Components/PaymentGateWay/PaymentGateWaySuccess";
import CreditBillSettle from "./containers/CreditBillSettle/CreditBillSettle";
// import Loader from "./Components/Loader/Loader";
// import LandingPageContextProvider from "./newSrc/context/LandingPageContextProvider";
import LandingPageMainComponent from "./newSrc/pages/Landing/components/LandingPageMainComponent/LandingPageMainComponent";
import { AboutUs, Careers, ContactUs, GSTDetails, Investors, OurNetworks, OurTeam, PrivacyPolicy, TermsAndConditions, UserAgreement, Values } from "./newSrc/components";
import { LandingPageHome, MobileForms } from "./newSrc/pages/Landing/Landing";
import { Footer, Login } from "./newSrc/pages/Landing/components";

// const AsyncDashBoard = React.lazy(() =>import("./containers/DashBoard"))
// const Home = lazy(() => import("./containers/web/index"))
const AsyncTrack = lazy(() => import("./containers/CustomerTracking/customerTrack"))
// const NewApp = lazy(() => import("./newSrc/NewApp"));
const AsyncDashBoard = React.lazy(() => import("./containers/DashBoard"))
const AsyncCustomerDashBoard = React.lazy(() => import("./containers/CustomerDashboard"))
const AsyncDriverDashBoard = React.lazy(() => import("./containers/DriverDashboard"))
import '../src/newSrc/styles/reset.css';
import '../src/newSrc/styles/utils.css';
import '../src/newSrc/styles/colors.css';
import '../src/newSrc/styles/fonts.css';
import '../src/newSrc/styles/index.css';
import '../src/newSrc/styles/media-query.css';
import ExpressParcelRate from "./newSrc/pages/Landing/get-estimate-forms/ExpressParcelRate";
import { Ripple } from "./containers/generics/Ripple";
import { useAppSelector } from "./hooks/useAppSelector";
import classNames from "classnames";
import TermsAndConditionsUserLogin from "./newSrc/pages/Landing/components/TermsAndConditionsUserLogin/TermsAndConditionsUserLogin";
import TermsAndConditionsCustomerBooking from "./newSrc/pages/Landing/components/TermsAndConditionsCustomerBooking/TermsAndConditionsCustomerBooking";

const AllRoutes = () => {

  const user = useSelector((state: RootState) => state.user)
  const customerReducer = useSelector((state: RootState) => state.customerLoginReducer)
  const loading = useAppSelector(state => state?.global?.loading);
  const locationDetails: any = useLocation();

  if (locationDetails.pathname.endsWith("/index.html")) {
    return <Navigate to="/" />
  }

  const isAuthenticated = () => {

    if (user.token && user.userName && sessionStorage.getItem("kabra:token")) {
      if (user.branches.length === 0 && user.fleets.length === 0) {
        return true
      }
      else {
        return true
      }
    };
    return false
  }

  const isAuthenticatedCustomer = () => {
    if (customerReducer && customerReducer?.token && customerReducer?.name && localStorage.getItem("kabra:customertoken")) {
      if (locationDetails && locationDetails?.state?.from === "Payment_Success") {
        return true
      }
      else {
        return true
      }
    }
    return false
  }

  const publicRoutes = [
    {
      path: "/",
      element: <main className="landing-page"><Layout /></main>,
      errorElement: <Page_404 />,
      children: [
        {
          path: "",
          element: <LandingPageHome />
        },
        {
          path: "form",
          element: <MobileForms />
        },
        {
          path: "about-us",
          element: <LandingPageMainComponent>
            {/* About Us */}
            <AboutUs />
            {/* Values */}
            <Values />
            {/* Our Team */}
            <OurTeam />
            {/* Investors */}
            <Investors />
            {/* Footer */}
            <Footer />
          </LandingPageMainComponent>
        },
        {
          path: "/expressParcelRate",
          element: <LandingPageMainComponent>
            <ExpressParcelRate />
          </LandingPageMainComponent>
        },
        {
          path: "partners",
          element: <PartnerUrl />,
          children: [
            {
              path: "direct-sales",
              element: <SubPartners />
            },
            {
              path: "individual-with-fleet",
              element: <SubPartners />
            },
            {
              path: "transport-company",
              element: <SubPartners />
            }
          ]
        },
        {
          path: "customer",
          element: <CustomerUrl />
        },
        {
          path: "/track/:docketNumber",
          element: <AsyncTrack />
        },
        {
          path: "/customer/report",
          element: <CustomerReport />
        },
        {
          path: "/track/:docketNumber",
          element: <AsyncTrack />
        },
        {
          path: "/sTrack/:trackingCode",
          element: <STracking />
        },
        {
          path: "/pTrack/:trackingCode",
          element: <STracking />
        },
        {
          path: "/c/:customerId",
          element: <CustomerFirst />
        },
        {
          path: "/TempTracking",
          element: <TrackAuth />
        },
        {
          path: "/BlindPage",
          element: <BlindPage />
        },
        {
          path: "/tracking",
          element: <TrackAuth />
        },
        {
          path: "/detailedLogs",
          element: <CustomerTrackLogsDetails />
        },
        {
          path: "/Customer-trackmobile",
          element: <CustomerTrackMobileView />
        },
        {
          path: "/TagManager",
          element: <TriggerExample />
        },
        {
          path: "/paymentSuccess",
          element: <PaymentGateWaySuccess />
        },
        {
          path: "/creditBill",
          element: <CreditBillSettle />
        },
        {
          path: "our-network",
          element: <LandingPageMainComponent>
            {/* Our Network */}
            <OurNetworks />
            {/* Footer */}
            <Footer />
          </LandingPageMainComponent>
        },
        {
          path: "login",
          element: <LandingPageMainComponent>
            <Login />
          </LandingPageMainComponent>
        },
        {
          path: "terms-of-service",
          element: <TermsAndConditionsUserLogin />
        },
        {
          path: "terms-of-booking",
          element: <TermsAndConditionsCustomerBooking />
        },
        {
          path: "customerLogin",
          element: <NewCustomerLoginPage />
        },
        {
          path: "blog",
          element: <Blog />
        },
        {
          path: "gst-details",
          element: <LandingPageMainComponent>
            {/* GST Details */}
            <GSTDetails />
            {/* Footer */}
            <Footer />
          </LandingPageMainComponent>
        },
        {
          path: "contact-us",
          element: <LandingPageMainComponent>
            {/* Contact Us */}
            <ContactUs />
            {/* Footer */}
            <Footer />
          </LandingPageMainComponent>
        },
        {
          path: "careers",
          element: <LandingPageMainComponent>
            {/* Careers */}
            <Careers />
            {/* Footer */}
            <Footer />
          </LandingPageMainComponent>
        },
        {
          path: "t&c",
          element: <LandingPageMainComponent>
            {/* Terms and Conditions */}
            <TermsAndConditions />
            {/* Footer */}
            <Footer />
          </LandingPageMainComponent>
        },
        {
          path: "faq",
          element: <Faq />
        },
        {
          path: "/privacy-policy",
          element: <LandingPageMainComponent>
            {/* Privacy Policy */}
            <PrivacyPolicy />
            {/* Footer */}
            <Footer />
          </LandingPageMainComponent>
        },
        {
          path: "individual",
          element: <Individual />
        },
        {
          path: "user-agreement",
          element: <LandingPageMainComponent>
            {/* User Agreement */}
            <UserAgreement />
            {/* Footer */}
            <Footer />
          </LandingPageMainComponent>
        },
        {
          path: "insuarancefaqs",
          element: <InsuaranceFaqs />
        },
        {
          path: "intra-city",
          element: <IntraCityForm />
        },
        {
          path: "domestic",
          element: <DomesticForm />
        },
        {
          path: "part-load",
          element: <PartloadForm />
        },
        {
          path: "full-load",
          element: <FullloadForm />
        },
        {
          path: "packers-and-movers",
          element: <PackersAndMoversForm />
        },
        {
          path: "international",
          element: <InterNationalForm />
        },
        {
          path: "indivisualRegistration",
          element: <IndivisualRegistration />
        },
        {
          path: "international",
          element: <InterNationalForm />
        },
        {
          path: "paymentSuccess",
          element: <PaymentGateWaySuccess />
        },
        {
          path: "creditBill",
          element: <CreditBillSettle />
        },
        {
          path: "/Customer-track",
          element: <CustomerTrackView />
        },
        {
          path: "/Customer-track/:trackingCode",
          element: <TrackAuth />,
        },
        {
          path: "/tracking/:trackingCode",
          element: <TrackAuth />,
        },
        {
          path: "/t/:trackingCode",
          element: <TrackAuth />,
        },
      ]
    },
  ];

  const privateRoutes = [
    {
      path: 'dashboard',
      element: isAuthenticated() ? <AsyncDashBoard /> : <Navigate to="/" />,
      children: [
        {
          path: '*',
          element: isAuthenticated() ? <AsyncDashBoard /> : <Navigate to="/" />,
          index: true
        }
      ]
    },
    {
      path: 'customerDashboard',
      element: isAuthenticatedCustomer() ? <AsyncCustomerDashBoard /> : <Navigate to="/" />,
      children: [
        {
          path: '*',
          element: isAuthenticatedCustomer() ? <AsyncCustomerDashBoard /> : <Navigate to="/" />,
          index: true
        }
      ]
    },
    {
      path: 'DriverDashboard',
      element: isAuthenticated() ? <AsyncDriverDashBoard /> : <Navigate to="/" />,
      children: [
        {
          path: '*',
          element: isAuthenticated() ? <AsyncDriverDashBoard /> : <Navigate to="/" />,
          index: true
        }
      ]
    },
  ]

  // type RouteType = {
  //   path: string;
  //   element: React.ReactElement;
  //   children: RouteType[]; 
  // }

  const routing = (
    <Suspense fallback={<Ripple />}>
      <Routes>
        {[...privateRoutes, ...publicRoutes].map((route: any, index: number) => (
          <Route
            key={index}
            path={route.path}
            element={route.element}
          >
            {/* Render child routes */}
            {route.children && route.children.map((childRoute: any, childIndex: any) => (
              <Route
                key={childIndex}
                path={childRoute.path}
                element={childRoute.element}
              />
            ))}
          </Route>
        ))}
        {/* Handle 404 Not Found */}
        <Route path="*" element={<Page_404 />} />
      </Routes>
      <div
        className={classNames(
          { disabling_div: loading == true },
          { display_none: loading == false }
        )}
      >
        <Ripple />
      </div>
    </Suspense>
  );

  return routing;
};

export default AllRoutes;


