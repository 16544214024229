import ReactDatePicker from "react-datepicker";
import React from "react";
import { CalendarIcon, HoursIcon } from "../../newSrc/constants/icons";

type Iprops = {
  name?: any;
  disabled?: any;
  showMonthYearPicker?: any;
  dateFormat?: any;
  selected?: any;
  onChange?: any;
  value?: any;
  placeholderText?: any;
  autoComplete?: any;
  showTimeSelect?: any;
  showTimeSelectOnly?: any;
  timeIntervals?: any;
  timeCaption?: any;
  timeFormat?: any;
  className?: any;
  minDate?: any;
  maxDate?: any;
  customInput?: any;
  icon?:
  | string
  | React.ReactElement<any, string | React.JSXElementConstructor<any>>
  | undefined;
  isClearable?: boolean;
  showIcon?: boolean;
  toggleCalendarOnIconClick?: boolean;
  label?: string;
  fullWidth?: boolean;
};
export default function DatePicker({
  disabled,
  name,
  showTimeSelect,
  showTimeSelectOnly,
  timeIntervals,
  timeCaption,
  timeFormat,
  showMonthYearPicker,
  dateFormat,
  selected,
  onChange,
  placeholderText,
  minDate,
  maxDate,
  className = "",
  showIcon,
  fullWidth,
  icon,
  label,
  ...props
}: Iprops) {
  //   const years = range(1990, new Date().getFullYear() + 1, 1);
  //   const months = [
  //     "January",
  //     "February",
  //     "March",
  //     "April",
  //     "May",
  //     "June",
  //     "July",
  //     "August",
  //     "September",
  //     "October",
  //     "November",
  //     "December",
  //   ];

  const Icon = showTimeSelect ? HoursIcon : CalendarIcon;

  return (
    <div className={`p-0 ${label ? "flex-col gap-4" : fullWidth ? "flex-col" : ''}`}>
      {label && <label className="fw-500 m-0">{label}</label>}
      <ReactDatePicker
        disabled={disabled}
        name={name}
        showTimeSelect={showTimeSelect}
        showTimeSelectOnly={showTimeSelectOnly}
        timeIntervals={timeIntervals}
        timeCaption={timeCaption}
        timeFormat={timeFormat}
        showMonthYearPicker={showMonthYearPicker}
        dateFormat={dateFormat}
        selected={selected}
        onChange={onChange}
        placeholderText={placeholderText}
        autoComplete={"off"}
        minDate={minDate}
        maxDate={maxDate}
        className={`${className} w-100 px-8 py-1 border-white outline-none br-4 custom-react-datepicker-input`}
        showIcon={true}
        icon={<Icon className="text-primary-400"
          style={{ borderBottom: "none", marginTop: "6px" }} />} // added calendar icon
        isClearable
        toggleCalendarOnIconClick
        {...props}
      />
    </div>
  );
}
