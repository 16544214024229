const formatDate = (date:any,time:boolean=false) => {

  let day=new Date(date).getDate();
  let d=day<10?('0'+day):day;
  let month=new Date(date).getMonth()+1;
  let mo=month<10?('0'+month):month;
  let year=new Date(date).getFullYear();
  let hour=new Date(date).getHours()
  let h=hour<10?('0'+ hour):hour;
  let minute=new Date(date).getMinutes()
  let m=minute<10?('0'+ minute):minute;
  let second=new Date(date).getSeconds()
  let s=second<10?('0'+ second):second;

   if (time) {
    return d +'/'+ mo+'/'+ year+' on '+h+':' + m + ':' + s;
   } else if (date) { 
    return d +'/'+ mo+'/'+ year;
  } else return '--';
};

// export function formatDateTime(isoString: any) {
//   const date = new Date(isoString);
  
//   const year = date.getFullYear();
//   const month = String(date.getMonth() + 1).padStart(2, '0');
//   const day = String(date.getDate()).padStart(2, '0');
  
//   const hours = String(date.getHours()).padStart(2, '0');
//   const minutes = String(date.getMinutes()).padStart(2, '0');
//   const seconds = String(date.getSeconds()).padStart(2, '0');
  
//   return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
// }

export function formatDateTime(isoString: any) {
  // Check if the input is a valid date
  const date = new Date(isoString);
  if (isNaN(date.getTime())) {
    // Return a fallback value or handle the error as needed
    return '';
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
}


export const getYYYYmmDDFormate = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
  const day = String(date.getDate()).padStart(2, '0'); 

  return `${year}-${month}-${day}`;
};

export const hoursToMin = (hours: number) => {
  return hours * 60;
}

export const getMonthYear = (date: Date) => {
  return {
    month: date.getMonth(),
    year: date.getFullYear()
  }
}

export default formatDate;
