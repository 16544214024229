import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import showMessage, { showHttpError, success } from "../../../utils/message";
import { cancelIntracityOrder, GetReviewRatingFromCustomer } from "../../../services/customer";
import { getFeedbackOfCustomerComplaint } from "../../../services/complaint";
import { CalendarIcon, CallIcon, CashIcon, DistanceIcon, DocketNumberIcon, GalleryIcon, HistoryIcon, HoursIcon, MapLocationIcon, RupeeIcon, ServiceTypeIcon, TimeToReachIcon, TimelineIcon, UserIcon, BackIcon, PrintIcon, DownloadIcon, CancelIcon, MessageAltIcon, SendIcon, MovingTruckIcon, ChatIcon} from "../../../newSrc/constants/icons";
import { payOnlineBycustomer } from "../../../services/verifyPOD";
import { addComplaintForCustomer, processPartLoadRateApprovalByCustomer } from "../../../services/customersection";
import getImageUrl from "../../../utils/getImageUrl";
import ImageInput from "../../../Components/ImageInput";
import PageTitle from "../../../Components/Title/PageTitle";
import { printBuilty2 } from '../../operations/bookutils/steps/builty';
import formatDate from "../../../services/FormatDate";
import ShareToWhatsapp from "../../sharing/WhatsAppSharing";
import { Button, Dialog, InfoPill, PaperCard, Rating, Select, TextArea} from "../../../newSrc/components/UI";
import OnlyImage from "../../Image/OnlyImage";
import ViewShipmentTrack from "../../CustomerFleetOrders/ViewShipmentTrack";
import { MdVerified } from "react-icons/md";
import AddressComponent from "./AddressComponent";
import ComplaintButtons from "./ComplaintButtons";
import { CloseButton, SubmitButton } from "../../../Components/Button/AllButton";
import { useWidth } from "../../../newSrc/hooks/useMobile";

const CustomerTrackingView = (newProps: any) => {

    const timeConvert = (time: any) => {
        const timeString12hr = new Date(time).toLocaleTimeString("en-US", {
            hour12: true,
            hour: "numeric",
            minute: "numeric",
        });
        return timeString12hr;
    }

    interface PackgePopup {
        serviceType: any,
        placeId?: any
    }
    const CustomerData: any = newProps?.data?.customerTrackingData?.orderData;
    const navigate = useNavigate()
    const [isPickupInfoOpen, setIsPickupInfoOpen] = useState(0);
    const [packageDataInfo, setPackageDataInfo] = useState<PackgePopup>({ serviceType: "", placeId: "" });
    const [logs, setLogs] = useState<any>([]);
    const [activeDest, setActiveDest] = useState<any>(null);
    const [statusForOrder, setStatusForOrder] = useState("");
    const [ratingFromUser, setRatingFromUser] = useState(0);
    const [reviewFromUser, setReviewFromUser] = useState("");
    const [latComplaint, setLastComplaint] = useState<any>();
    const [isDialogOpen, setIsDialogOpen] = useState(0);
    const [complaintRaised, setComplaintRaised] = useState(false);
    const [paymentModechange, setPaymentModechange] = useState(false);
    const [selectRateOfPartLoad, setSelectRateOfPartLoad] = useState(false);
    const [cancelOrder, setCancelOrder] = useState(false);
    const [customerOrderNoList, setCustomerOrderNoList] = useState<any>();
    const [description, setDescription] = useState("");
    const [customerDocket, setCustomerDocket] = useState<any>();
    const [complaintType, setComplaintType] = useState("");
    const [image, setImage] = useState("");
    const [customerComplaintRefOrderNo, setCustomerComplaintRefOrderNo] = useState<any>();
    const [handleFeedbackButton, setHandleFeedbackButton] = useState(true);
    const [onLineQr, setOnlineQr] = useState("Paytm");
    const [_selectPaymentOptionBtn, setSelectPaymentOptionBtn] = useState("cash");
    const [transactionId, setTransactionId] = useState("")
    const [cancelOrderRemarks, setCancelOrderRemarks] = useState('');
    const [_, setOrderStarted] = useState(false)
    const [driverImageUrl, setDriverImageUrl] = useState();
    const [selectedRateInPopup, setSelectedRateInPopup] = useState(0);
    const [selectedRateInId, setSelectedRateInId] = useState("");
    const [selectedRateRemarks, setSelectedRateRemarks] = useState("");
    const {mobileView} = useWidth()

    // console.log(`jzdmbndzfb`, selectPaymentOptionBtn);

    const setAdressPickUpInfo = (val: any) => {
        setIsPickupInfoOpen(val);
    }

    const setAdressPackageData = (obj: any) => {
        setPackageDataInfo(obj)
    }

    const setIsDialogOpenFunc = (num: number) => {
        setIsDialogOpen(num)
    }
    const secondsToHms = (d: any) => {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var hDisplay = h > 0 ? h + (h == 1 ? " Hr, " : " Hr, ") : "";
        var mDisplay = m > 0 ? m + (m == 1 ? " Min " : " Min ") : "";
        return hDisplay + mDisplay;
    }

    const ans = CustomerData?.route &&
        CustomerData?.route.sort((a: any, b: any) => {
            return a?.placeIndex - b?.placeIndex
        }) || []

    const someCoords = CustomerData?.route && [CustomerData?.route[0]?.placeId, CustomerData?.route[1]?.placeId];

    const handleDetailedLogsForCustomer = () => {
        console.log("Clicked From New", newProps.data.customerTrackingData.source)
        if (newProps.data.customerTrackingData.source === "Customer-Authenticated-BranchOrder") {
            navigate("/CustomerDashBoard/detailedLogs", {
                state: {
                    from: "Customer-Order-Track",
                    logsInfo: logs,
                    view: "Branch-Order"
                }
            })
        }
        else if (newProps.data.customerTrackingData.source === "Customer-Authenticated-CustomerOrder") {
            navigate("/CustomerDashBoard/detailedLogs", {
                state: {
                    from: "Customer-Order-Track",
                    BranchDetails: CustomerData,
                    logsInfo: logs,
                    view: "Customer-Order"
                }
            })
        }
        else if (newProps.data.customerTrackingData.source === "Customer-NotAuthenticated-CustomerOrder") {
            navigate("/detailedLogs", {
                state: {
                    from: "Customer-Order-Track",
                    BranchDetails: CustomerData,
                    logsInfo: logs,
                    view: "Customer-Order"
                }
            })
        }
        else if (newProps.data.customerTrackingData.source === "Customer-NotAuthenticated-BranchOrder") {
            navigate("/detailedLogs", {
                state: {
                    from: "Branch-Order-Track",
                    logsInfo: logs,
                    view: "Branch-Order"
                }
            })
        }
        else if (newProps.data.customerTrackingData.source === "Branch-Authenticated-BranchOrder") {
            navigate("/dashboard/detailedLogs", {
                state: {
                    from: "Branch-Order-Track",
                    logsInfo: logs,
                    view: "Branch-Order"
                }
            })
        }
        {
            newProps.data.customerTrackingData.source === "Branch-Authenticated-CustomerOrder" &&
                navigate("/dashboard/detailedLogs", {
                    state: {
                        from: "Branch-Order-Track",
                        BranchDetails: CustomerData,
                        logsInfo: logs,
                        view: "Customer-Order"
                    }
                })
        }
    }

    const handleRatings = async (value: any) => {
        if (reviewFromUser.length < 2) {
            return showHttpError("All Fields Are Mandatory !")
        }
        if (ratingFromUser === 0) {
            return showHttpError("All Fields Are Mandatory !")
        }
        try {
            // let payload = {
            //     feedbackType: "REV",
            //     feedbackTitle: reviewFromUser,
            //     shipmentNumber: value,
            //     rating: ratingFromUser
            // }
            let payload = {
                // feedbackType: "REV",
                review: reviewFromUser,
                shipmentNumber: value,
                rating: ratingFromUser
              }
            await GetReviewRatingFromCustomer(payload);
            navigate("pastBookings")
        } catch (error: any) {
            if (error.message === "You cannot set review for this booking!") {
                alert("You are not eleigible to give review Or rating. Thank You !")
                setReviewFromUser("")
                setRatingFromUser(0)
            } else {
                alert("You are not eleigible to give review Or rating. Thank You !")
                setReviewFromUser("")
                setRatingFromUser(0)
            }
        }
    }

    const handleIndividualChat = () => {
        {
            newProps.data.customerTrackingData.source === "Branch-Authenticated-CustomerOrder" ?
                (alert("This Chat Module Only Valid For Customer Kindly Login as a Customer !"))
                :
                (
                    navigate("customerChat")
                )
        }
    }

    const handleIndividualCustomerOrderChat = (extraData: any, feedbackNumber: any, bookingtype?: any) => {
        navigate("/customerChat/individualChat", {
            state: {
                from: "redirect-chat-from-customerchat-list",
                data: { feedbackNumber },
                anotherData: extraData,
                bookingType: bookingtype
            }
        })
    }

    const raiseComplaint = async () => {
        newProps?.data?.showLoader();
        try {
            let payloadForcomplaint: any = {
                feedbackType: "COMP",
                complaintType: complaintType,
                shipmentNumber: customerDocket.toString(),
                messageText: description,
                customerPackageOrder: customerComplaintRefOrderNo
            }
            let TempResponse = await addComplaintForCustomer(payloadForcomplaint);
            if (TempResponse.feedbackNumber !== "") {
                setComplaintRaised(true);
                showMessage("Your Complaint has been created, To see all Complaints Click on the Message Icon", success, 3000)
            }
            newProps?.data?.hideLoader();
        } catch (error) {
            newProps?.data?.hideLoader();
            let newError: any = error;
            alert(newError.message);
        }
    }

    const getRaringResponse = async () => {
        newProps?.data?.showLoader();
        try {
            let Payload: any = { "feedbackNumber": `C-${CustomerData?.shipmentNumber}`, "feedbackType": "REV", customer: newProps?.data?.user?._id }
            let ans = await getFeedbackOfCustomerComplaint(Payload);
            if (ans.length > 0) {
                setRatingFromUser(ans[0].rating)
                setReviewFromUser(ans[0].feedbackTitle)
                setHandleFeedbackButton(false)
            }
            console.log("ans 2020", ans)
            newProps?.data?.hideLoader();
        } catch (error) {
            newProps?.data?.hideLoader();
            showHttpError(error);
            console.log("error 2020", error)
        }
    }

    const handleCustomerOnlinePayment = async () => {
        newProps?.data?.showLoader();
        try {
            let payload = {
                "shipmentNumber": CustomerData?.shipmentNumber,
                "nonCashPmntTypeId": "60d19948c8140f31b7f89a8d",
                "txnId": transactionId,
                "settledCollectedAmount": CustomerData?.gross
            }
            await payOnlineBycustomer(payload);
            setTransactionId("");
            showMessage("Online Payment Request Created, Kindly Wait For confirmation !", success, 3000)
            newProps?.data?.hideLoader();
        } catch (error) {
            newProps?.data?.hideLoader();
            showHttpError(error);
        }
    }

    const handleCustomerPartLoadRateApproval = async (id: any) => {
        console.log(`newProps?.data sdfbadfb`, CustomerData)
        newProps?.data?.showLoader();
        try {
            let payload = {
                remarks: selectedRateRemarks,
                status: "A"
            }
            await processPartLoadRateApprovalByCustomer(id, payload);
            showMessage(`Thank you for Accepting the bid, Transporter will contact you soon ~!`)
            newProps?.data?.hideLoader();
            setSelectedRateInId("");
            setSelectedRateInPopup(0);
            setSelectedRateRemarks("");
            navigate("/CustomerDashboard/pastBookings")
        } catch (error) {
            newProps?.data?.hideLoader();
            showHttpError(error);
        }
    }

    const cancelIntracityBooking = () => {
        newProps?.data?.showLoader();
        try {
            let payload = {
                "reqId": CustomerData?._id,
                "remark": cancelOrderRemarks,
                "reqType": "CC"
            }
            let response = cancelIntracityOrder(payload)
            showMessage(`${response} cancelIntracityOrder Api Called`, success)
            navigate(newProps?.data?.customerTrackingData?.backToOriginPath)
            newProps?.data?.hideLoader();
        } catch (error) {
            newProps?.data?.hideLoader();
            showHttpError(error)
        }
    }

    useEffect(() => {
        setLogs(CustomerData?.logs);
        if (CustomerData?.feedbacks?.complaints?.length > 0) {
            let Temp2: any = CustomerData?.feedbacks?.complaints
            setLastComplaint(Temp2[Temp2.length - 1]);
        }
        showMessage("Order Tracked Succesfully", success, 2000);
    }, [])

    useEffect(() => {
        if (CustomerData?.orderStatus === "D") {
            getRaringResponse();
        }
    }, [newProps.data.customerTrackingData])

    useEffect(() => {
        if (isDialogOpen === 0) {
            setComplaintType("")
        }
        if (isDialogOpen === 1) {
            setComplaintType("PM")
        }
        if (isDialogOpen === 2) {
            setComplaintType("PF")
        }
        if (isDialogOpen === 3) {
            setComplaintType("DMG")
        }
        if (isDialogOpen === 4) {
            setComplaintType("MB")
        }
        if (isDialogOpen === 5) {
            setComplaintType("LD")
        }
        if (isDialogOpen === 6) {
            setComplaintType("OT")
        }
    }, [isDialogOpen])

    const getImageOfDriver = async () => {
        newProps?.data?.showLoader();
        try {
            console.log("driver image response:", CustomerData);
            let response = await getImageUrl(CustomerData?.driver?.userProfile?.image);
            console.log("driver image response:", response, CustomerData, CustomerData?.driver?.userProfile?.image);
            setDriverImageUrl(response);
            newProps?.data?.hideLoader();
        } catch (error) {
            newProps?.data?.hideLoader();
            showHttpError(error);
        }
    }

    useEffect(() => {
        getImageOfDriver();
    }, [])

    useEffect(() => {
        if (CustomerData?.orderStatus === null || CustomerData?.orderStatus === "BR") {
            setStatusForOrder("Upcoming")
        }
        if (CustomerData?.orderStatus === "CC") {
            setStatusForOrder("Cancelled")
        }
        if (CustomerData?.orderStatus === "CRD") {
            setStatusForOrder("Cancel BY Driver")
        }
        if (CustomerData?.orderStatus === "B" || CustomerData?.orderStatus === "I") {
            setStatusForOrder("OnGoing")
        }
        if (CustomerData?.orderStatus === "D") {
            setStatusForOrder("Delivered")
        }

        setCustomerDocket(CustomerData?.shipmentNumber);

        if (CustomerData?.startOrder) {
            newProps.data.getCustomerDocketLocation(CustomerData?.driver?._id)
        }
        let TempCustomerOrderNoList: any = [];
        {
            CustomerData?.packages?.map((elem: any) => {
                TempCustomerOrderNoList.push({ key: elem?.customerPkgIdentifier, value: elem?.customerPackageOrder })
            })
        }
        setCustomerOrderNoList(TempCustomerOrderNoList);
    }, [])

    useEffect(() => {
        if (CustomerData.route && CustomerData.route.length > 0) {
            let estimation = CustomerData?.route.sort((a: any, b: any) => {
                return a?.placeIndex - b?.placeIndex
            }) || []

            if (CustomerData?.startOrder) {
                setOrderStarted(CustomerData?.startOrder)
                setActiveDest(estimation.find((x: any) => x.checkIn && !x.checkOut)?.placeIndex)
            }
            newProps?.data?.setCustomerBookingTracking({
                route: estimation,
                lastStation: activeDest
            })

        }
    }, [CustomerData?.route])


    return (
        <div className="flex-center">
            <PaperCard className={`${newProps.data.customerTrackingData.source === "Branch-Authenticated-CustomerOrder" ? 'trackingForBranchPersonContainer' : `trackingForCustomerContainer`} br-4 m-1 max-w-1440`}>
                {/* Header Section start */}
                <div className="flex jc-evenly ai-center flex-wrap mb-8 | m-jc-center m-ai-center mx-auto">
                    <BackIcon className="text-primary-400" style={{fontSize:'25px'}} onClick={() => {
                        if (newProps.data.customerTrackingData.source === "Customer-NotAuthenticated-CustomerOrder") {
                            navigate("/")
                        } else if (newProps.data.customerTrackingData.source === "Branch-NotAuthenticated-CustomerOrder") {
                            navigate("/")
                        } else {
                            navigate(newProps?.data?.customerTrackingData?.backToOriginPath)
                        }
                    }
                    } />
                    <PageTitle title="Live Tracking"  style={{fontSize:'1.8rem'}}/>
                    {newProps.data.customerTrackingData.source === "Customer-Authenticated-CustomerOrder"
                        || newProps.data.customerTrackingData.source === "Branch-Authenticated-CustomerOrder" ?
                        <div className="flex gap-1 ai-center jc-end fs-link | m-jc-center m-w-100 m-mt-1">
                            <button className="bg-transparent border-none" onClick={() => {
                                const routes = CustomerData.route
                                console.log("routes :", routes)
                                const firstPlace = routes.find((route: { placeIndex: number; }) => route.placeIndex == 1)
                                const lastPlace = routes.sort((routeA: any, routeB: any) => routeB.placeIndex - routeA.placeIndex)[0]
                                let printData = CustomerData;
                                printData.firstPlace = firstPlace?.placeId
                                printData.lastPlace = lastPlace?.placeId
                                printData.firstCustomer = firstPlace?.customer
                                printData.lastCustomer = lastPlace?.customer

                                printBuilty2({ ...printData, actualBookingType: printData.type });
                            }}><PrintIcon style={{ fontSize: "x-large" }} className="text-primary-400" /></button>
                            <button className="bg-transparent border-none"><DownloadIcon style={{ fontSize: "x-large" }} className="text-primary-400" /></button>
                            <ShareToWhatsapp docketId={CustomerData?._id} />
                            {!(CustomerData?.orderStatus === "CC" || CustomerData?.orderStatus === "CRD" || CustomerData?.orderStatus === "D"  ) &&
                                <button className="bg-transparent border-none" onClick={() => { setCancelOrder(true) }}><CancelIcon style={{ fontSize: "xx-large" }} className="text-danger-400" /></button>
                            }
                            {(CustomerData?.orderStatus === "CC" || CustomerData?.orderStatus === "CRD") &&
                                <Button variant="primary" action="primary" onClick={() => showHttpError('Hello Brother !')}>Book Again</Button>
                            }
                        </div>
                        : <></>
                    }

                    <Dialog
                        state={cancelOrder}
                        size='regular'
                        onClose={() => {
                            setCancelOrder(false)
                        }}
                        header='Are You Sure You Want To Cancel Order'
                        body={
                            <div>
                            <input
                                className="br-4 border-white py-8 px-1 w-100"
                                placeholder="Reason For Cancelation Remark"
                                value={cancelOrderRemarks}
                                onChange={(e: any) => setCancelOrderRemarks(e.target.value)}
                            />
                        </div>
                        }
                        footer={
                            <div className="flex gap-1 ai-center">
                            <Button variant="danger" onClick={() => {
                                cancelIntracityBooking(), setCancelOrder(false)
                            }}>
                                Cancel Order
                            </Button>
                            <CloseButton onClick={() => {
                                setCancelOrder(false)
                            }}/>
                        </div>
                        }
                    />
                </div>
                {/* Header Section End */}

                <PaperCard>
            
                    <div className='five-column t-three-column m-two-column gap-1'>
                        <div className="flex ai-center gap-4 fs-link">
                            <CalendarIcon className="fs-h4 text-primary-400" />
                            <p>
                                {formatDate(CustomerData?.createdAt)}
                            </p>
                        </div>
                        <div className="flex ai-center gap-4 fs-link">
                            <DocketNumberIcon className="fs-h4 text-primary-400" />
                            <p>
                                {" "}{CustomerData?.shipmentNumber}
                            </p>
                        </div>
                        <div className="flex ai-center gap-4 fs-link">
                            <ServiceTypeIcon className="fs-h4 text-primary-400" />
                            <p>
                                {" "} {CustomerData?.type}
                            </p>
                        </div>
                        <div className="flex ai-center gap-4 fs-link">
                            <MapLocationIcon className="fs-h4 text-primary-400" />
                            <p>
                                {" "}{CustomerData?.route?.length}
                            </p>
                        </div>
                        <div className="flex ai-center gap-4 fs-link">
                            <CashIcon className="fs-h4 text-primary-400" />
                            {statusForOrder === "Cancelled" ?
                                <p>
                                        Order Cancelled
                                </p>
                                :
                                <p>
                                    {CustomerData?.settled ?
                                        <span>
                                            {CustomerData?.paymentType}
                                        </span>
                                        :
                                        <span>
                                            Cash
                                        </span>
                                    }
                                </p>
                            }
                        </div>
                        <div className="flex ai-center gap-4 fs-link">
                            <HoursIcon className="fs-h4 text-primary-400" />
                            <p>
                                {timeConvert(CustomerData?.createdAt)}
                            </p>
                        </div>
                        <div className="flex ai-center gap-4 fs-link">
                            <TimelineIcon className="fs-h4 text-primary-400" />
                            <p>
                                {" "}{statusForOrder}
                            </p>

                        </div>
                        <div className="flex ai-center gap-4 fs-link">
                            <TimeToReachIcon className="fs-h4 text-primary-400" />
                            <p>
                                {" "}{secondsToHms(ans[ans?.length - 1]?.duration)}
                            </p>
                        </div>
                        {CustomerData?.route?.length > 0 &&
                            <div className="flex ai-center gap-4 fs-link">
                                <DistanceIcon className="fs-h4 text-primary-400" />
                                <p>
                                    {" "} {ans[ans?.length - 1]?.distance / 1000} KM
                                </p>
                            </div>
                        }
                        <div className="flex ai-center gap-4 fs-link">
                            <RupeeIcon className="fs-h5 text-primary-400" />
                            <p>
                                {" "}
                                {(CustomerData?.type === "PartLoad" || CustomerData?.type === "FullLoad") ?
                                    (CustomerData?.gross ? CustomerData?.gross
                                        :
                                        (CustomerData?.customerBookingGrossReq[0]?._id ?
                                            <Button onClick={() => setSelectRateOfPartLoad(true)}>Select Rate</Button>
                                            :
                                            <div>Wait For the Transporters to Bid</div>
                                        )
                                    )
                                    :
                                    CustomerData?.gross
                                }
                            </p>
                        </div>

                    </div>               
            
                    <Dialog
                        state={paymentModechange}
                        size='regular'
                        onClose={() => {
                            setPaymentModechange(false);
                            setSelectPaymentOptionBtn("cash")
                        }}
                        header='Pay Us Via Online'
                        body={
                            <div>
                                <div className="ownBtn">
                                    <button className={onLineQr === "Paytm" ? "activeButton" : ""} onClick={() => { setOnlineQr("Paytm") }} > Paytm </button>
                                    <button className={onLineQr === "Gpay" ? "activeButton" : ""} onClick={() => { setOnlineQr("Gpay") }}> G Pay </button>
                                </div>
                                <div className='flex-col gap-1 max-w-300'>
                                    <input placeholder='Transaction Id' value={transactionId} type={"text"} onChange={(e: any) => { setTransactionId(e.target.value) }} className="w-100 border-white outline-none br-4 py-8 px-1" />
                                    {onLineQr === "Gpay" ?
                                        <img src='https://i.ibb.co/FbGQ3jZ/GPay-Qr-Code.jpg' alt='GPay Qr Code' className="custom-img" />
                                        :
                                        <img src='https://i.ibb.co/x8V3knw/paytm-Qr-Code.jpg' alt='Paytm Qr Code' className="custom-img" />
                                    }
                                </div>
                            </div>
                        }
                        footer={
                            <div className='flex gap-1 ai-center flex-wrap'>
                                <SubmitButton variant="secondary" action="secondary" onClick={() => {
                                    if (transactionId === "") {
                                        showHttpError("Transaction Id Is Madatory");
                                        return;
                                    }
                                    handleCustomerOnlinePayment()
                                    setPaymentModechange(false)
                                }}/>
                                <CloseButton onClick={() => {
                                    setPaymentModechange(false),
                                        setSelectPaymentOptionBtn("cash")
                                }}/>
                            </div>
                        }
                    />
                    
                    <Dialog
                        state={selectRateOfPartLoad}
                        size='regular'
                        onClose={() => { setSelectRateOfPartLoad(false); }}
                        header='Select Your Suitable Rate'
                        body={
                            <div>
                            <div>
                                {CustomerData?.customerBookingGrossReq?.map((x: any, index: any) => {
                                    return (
                                        <div
                                            style={{
                                                display: "flex", padding: "1.25rem 1.5rem", borderRadius: "5px", width: "100%", gap: "3rem", justifyContent: "space-evenly",
                                                boxShadow: "0 2px 1px rgba(0, 0, 0, 0.5)", alignItems: "center",
                                                backgroundColor: selectedRateInPopup === (index + 1) ? "var(--clr-primary-400)" : "", color: selectedRateInPopup === (index + 1) ? "white" : ""
                                            }}
                                            onClick={() => { return setSelectedRateInPopup(index + 1), setSelectedRateInId(x?._id) }}
                                        >
                                            <p style={{ margin: 0 }}> {x?.driverCompany?.companyName} </p>
                                            <p style={{ margin: 0 }}> {x?.gross} </p>
                                            <p style={{ margin: 0 }}>
                                                {x?.senderRemarks}
                                            </p>
                                        </div>)
                                })}
                            </div>
                            <br />
                            <div>
                                <input
                                    className="border-white br-4 w-100 p-8"
                                    placeholder="Remarks"
                                    value={selectedRateRemarks}
                                    onChange={(e: any) => setSelectedRateRemarks(e.target.value)}
                                />
                            </div>
                        </div>
                        }
                        footer={
                            <div className='flex gap-8 ai-center'>
                            <SubmitButton
                                variant="secondary" action="secondary"
                                onClick={() => {
                                    if (selectedRateRemarks !== "") {
                                        if (selectedRateInPopup > 0) {
                                            handleCustomerPartLoadRateApproval(selectedRateInId);
                                            setSelectRateOfPartLoad(false);
                                        }
                                        else {
                                            showHttpError("Minimum 1 Bid Should be Selected")
                                        }
                                    }
                                    else {
                                        showHttpError("Remarks Should Not Be Empty")
                                    }
                                }}
                            >
                                Submit
                            </SubmitButton>
                            <CloseButton onClick={() => { setSelectRateOfPartLoad(false) }}/>
                        </div>
                        }
                    />
                        
                </PaperCard>

                {statusForOrder === "Upcoming" ? <></> :
                    <PaperCard className="mt-1">
                            {CustomerData?.driver &&
                                <div className="flex jc-between ai-center gap-4 flex-wrap m-flex-col">
                                    <div>
                                        <OnlyImage objectID={driverImageUrl}  />
                                    </div>
                                    <p className="flex ai-center gap-4 fs-link">
                                        <span> <UserIcon className="fs-h4 text-primary-400"/> </span>
                                        <span> {`${CustomerData?.driver?.name?.fName} ${CustomerData?.driver?.name?.lName}`} </span>
                                    </p>
                                    <p className="flex ai-center gap-4 fs-link">
                                        <span> <CallIcon className="fs-h4 text-primary-400"/> </span>
                                        <span> {CustomerData?.driver?.contact} </span>
                                    </p>
                                    <p className="flex ai-center gap-4 fs-link">
                                        <span> <MovingTruckIcon className="fs-h4 text-primary-400"/> </span>
                                        <span> {CustomerData?.regNumber} </span>
                                    </p>
                                </div>
                            }
                    </PaperCard>
                }
                {CustomerData?.logs[CustomerData?.logs?.length - 1].action === 'generateCancelPin' &&
                    <div className="paper bg-primary-50 text-primary-900 mt-1 fs-link">
                        <p className="flex-center gap-4 fs-link">
                            Your Pin to cancel the order is {" "}
                            <strong> {CustomerData?.cancelBookingPin}</strong>
                        </p>
                    </div>
                }
                {CustomerData?.orderStatus === "I" && CustomerData?.orderStatus !== "D" &&
                    <div>
                        {CustomerData?.route?.map((e: any) => {
                            console.log(`jhSdvjnsdv`, e, `zxcnlkfvdfv`, activeDest);
                            if (e?.placeIndex === activeDest) {
                                console.log(`jhSdvjnsdv 0.0.2`, e, `zxcnlkfvdfv`, activeDest, `ddfd`);
                                return (<div className="paper bg-primary-50 text-primary-900 mt-1 fs-link">
                                    <p className="flex-center gap-4 fs-link">
                                        Your Pin is {" "}
                                        <strong> {e?.pin}</strong>
                                    </p>
                                </div>)
                            }
                        })}
                    </div>
                }

                {
                    statusForOrder !== "Upcoming" && CustomerData?.otp && new Date(CustomerData?.otp?.expireDate).getTime() > new Date().getTime() && CustomerData?.orderStatus !== "D" &&
                    <div className="paper bg-primary-50 text-primary-900 mt-1 fs-link"><p className="flex-center gap-4 fs-link">
                        Your OTP Number is {" "}
                        <strong> {CustomerData?.otp?.otp}</strong>
                    </p></div>
                }

                {CustomerData?.orderStatus !== "D" ?
                    <div className="mt-1 br-4">
                        <div className='overflow-hidden two-column m-one-column t-one-column gap-1 shadow-none'>
                            <PaperCard style={{ overflowY: "auto", height: "100%", maxHeight: mobileView ? "450px" : "450px" }}>
                                    {newProps?.data?.customerTrackingData?.orderData?.route?.map((e: any, i: any) => {
                                        return (
                                            <AddressComponent activeDest={activeDest} eData={e} iData={i} setAdressPickUpInfo={setAdressPickUpInfo} setAdressPackageData={setAdressPackageData} />
                                        )
                                    })}
                            </PaperCard>
                                <PaperCard style={{ minHeight: "450px" }}>
                                    {CustomerData && CustomerData?.route &&
                                        <ViewShipmentTrack Route={someCoords} from={"customerTrackingMap"} encoded={CustomerData?.googleMapPolyLine} />
                                    }
                                </PaperCard>
                        </div>
                        <div className='mt-1'>
                            <div className='p-1 shadow-small border-white br-4 flex-col'>
                                {logs[0] &&
                                    <div className={`${newProps.data.customerTrackingData.source === "Branch-Authenticated-CustomerOrder" ? "" : ""} flex-col m-0 w-100`}>
                                        <div className="grid gap-1">
                                            <PageTitle title='Last Activity' style={{fontSize:'1.8rem'}}/>
                                            <div className="flex gap-1 flex-grow-1 jc-between m-two-column fs-link">
                                                <div className="flex ai-center gap-4">
                                                    <p>
                                                        <CalendarIcon className="fs-h4 text-primary-400" />
                                                    </p>
                                                    <p>
                                                        {formatDate(logs[logs?.length - 1].trackingTime)}
                                                    </p>
                                                </div>
                                                <div className="flex ai-center gap-4">
                                                    <p>
                                                        <HoursIcon className="fs-h4 text-primary-400"  />
                                                    </p>
                                                    <p>
                                                        {timeConvert(logs[logs?.length - 1].trackingTime)}
                                                    </p>
                                                </div>
                                                <div className="flex ai-center gap-4">
                                                    <p>
                                                        <TimelineIcon className="fs-h4 text-primary-400"  />
                                                    </p>
                                                    <p>
                                                        {logs[logs?.length - 1].action}
                                                    </p>
                                                </div>
                                                {logs[logs?.length - 1].action !== "Accepted" ?
                                                    <div className="flex ai-center gap-4">
                                                        <p>
                                                            <UserIcon className="fs-h4 text-primary-400" />
                                                        </p>
                                                        <p>
                                                            {logs[0].customer?.name ? logs[0].customer?.name : logs[logs?.length - 1].user?.name?.fName}
                                                        </p>
                                                    </div>
                                                    :
                                                    <div className="flex ai-center gap-4">
                                                        <p>
                                                            <UserIcon className="fs-h4 text-primary-400" />
                                                        </p>
                                                        <p>
                                                            {logs[logs?.length - 1].user?.name?.fName}
                                                        </p>
                                                    </div>
                                                }
                                                {logs[logs?.length - 1].action !== "Accepted" ?
                                                    <div className="flex ai-center gap-4 text-primary-400">
                                                        <p>
                                                            <CallIcon className="fs-h4" />
                                                        </p>
                                                        <p>
                                                            {logs[0].customer?.contact ? logs[0].customer?.contact : logs[logs?.length - 1].user?.contact}
                                                        </p>
                                                    </div>
                                                    :
                                                    <div className="flex ai-center gap-4 text-primary-400">
                                                        <p>
                                                            <CallIcon className="fs-h4" />
                                                        </p>
                                                        <p>
                                                            {logs[logs?.length - 1].user?.contact}
                                                        </p>
                                                    </div>
                                                }
                                                <p className="flex-center gap-4 p-8 br-4 bg-primary-50 text-primary-900" onClick={() => alert("Testing Ig Going On !")}>
                                                    <GalleryIcon style={{ fontSize: "x-large" }} />
                                                    Gallery
                                                </p>
                                                <p className='flex-center gap-4 p-8 br-4 bg-primary-50 text-primary-900' onClick={() => handleDetailedLogsForCustomer()} >
                                                    <HistoryIcon />
                                                    History
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <div className="paper bg-success-50 fw-900 text-success-900 flex-col-center fs-tagline text-center mt-1">
                        <MdVerified /> Your Order is Delivered Successfully
                    </div>
                }
 
                {newProps.data.customerTrackingData.source === "Customer-Authenticated-CustomerOrder"
                    || newProps.data.customerTrackingData.source === "Branch-Authenticated-CustomerOrder" ?
                    <>
                        {statusForOrder === "Upcoming" ? <></> :
                            <>
                                {CustomerData?.orderStatus === "D" ?
                                    <PaperCard className="mt-1 flex gap-1   ai-end m-flex-col m-ai-start">
                                        <div className="flex-col gap-8 m-0">
                                            <div className="fs-link">
                                                <label htmlFor="">Rating</label>
                                                <Rating
                                                    max={3}
                                                    style={{ fontSize: "2.5rem" }}
                                                    name="hover-feedback1"
                                                    value={ratingFromUser}
                                                    precision={1}
                                                    onChange={(e: any) => {
                                                        setRatingFromUser(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-100">
                                            <TextArea
                                                rows={1}
                                                label='Review'
                                                value={reviewFromUser}
                                                placeholder="Write your review here..."
                                                onChange={(el: any) => { setReviewFromUser(el.target.value) }}
                                            />
                                        </div>
                                        {handleFeedbackButton && reviewFromUser &&
                                        <Button className="flex-center" variant="primary" action="primary" Icon={<SendIcon className="fs-h3" />} onClick={() => handleRatings(CustomerData?.shipmentNumber)}>
                                            Send
                                        </Button>
                                        }
                                    </PaperCard>
                                    : 
                                     <>
                                        <PaperCard className="mt-1">
                                                <div className="flex ai-center gap-1 flex-wrap">
                                                    <div className='bg-primary-400 text-white-900 p-8 br-4 fs-link mx-auto'>Complaints</div>
                                                  
                                                    <ComplaintButtons setIsDialogOpenFunc={setIsDialogOpenFunc} numbers={6} />
                                                    {complaintRaised &&
                                                        <div className="chatIconContainer" onClick={() => { handleIndividualChat() }}>
                                                            <ChatIcon className="text-primary-400" />
                                                        </div>
                                                    }
                                                </div>
                                                {/* } */}
                                            <Dialog
                                                state={Boolean(isDialogOpen)}
                                                size='regular'
                                                onClose={() => {
                                                    setIsDialogOpen(0);
                                                    setCustomerComplaintRefOrderNo('')
                                                    setDescription('');
                                                }}
                                                header='Raise Your Concern'
                                                body={
                                                    <div>
                                                        {isDialogOpen === 1 ?
                                                            <div className="flex-col gap-1">

                                                                <Select
                                                                    label='Order No'
                                                                    id="inputState"
                                                                    options={customerOrderNoList.map((c: any) => ({
                                                                        label: c.value,
                                                                        value: c.value
                                                                    }))}
                                                                    value={customerComplaintRefOrderNo}
                                                                    onChange={(e: any) => {
                                                                        setCustomerComplaintRefOrderNo(e);
                                                                    }}
                                                                />
                                                                <TextArea
                                                                    label="Detail information"
                                                                    rows={3}
                                                                    value={description}
                                                                    placeholder="Provide detail information regarding your issue"
                                                                    onChange={(e: any) => setDescription(e.target.value)}
                                                                />
                                                            </div>
                                                            :
                                                            isDialogOpen === 2 ?
                                                                <div className="flex-col gap-1">
                                                                    <Select
                                                                        label='Order No'
                                                                        id="inputState"
                                                                        options={customerOrderNoList.map((c: any) => ({
                                                                            label: c.value,
                                                                            value: c.value
                                                                        }))}
                                                                        value={customerComplaintRefOrderNo}
                                                                        onChange={(e: any) => {
                                                                            setCustomerComplaintRefOrderNo(e);
                                                                        }}
                                                                    />
                                                                    <TextArea
                                                                        label="Detail information"
                                                                        rows={3}
                                                                        value={description}
                                                                        placeholder="Provide detail information regarding your issue"
                                                                        onChange={(e: any) => setDescription(e.target.value)}
                                                                    />
                                                                    <ImageInput
                                                                        name="Add Image"
                                                                        label="Image"
                                                                        Icon={(props: any) => (
                                                                            <GalleryIcon {...props} />
                                                                        )}
                                                                        onChange={(e: any) => setImage(e)}
                                                                        value={image}
                                                                        extras={<div></div>}
                                                                    />
                                                                </div>
                                                                :
                                                                isDialogOpen === 3 ?
                                                                    <div className="flex-col gap-1">
                                                                        <TextArea
                                                                            rows={3}
                                                                            value={description}
                                                                            label="Detail information"
                                                                            placeholder="Provide detail information regarding your issue"
                                                                            onChange={(e: any) => setDescription(e.target.value)}
                                                                        />
                                                                        <ImageInput
                                                                            name="Add Image"
                                                                            label="Image"
                                                                            Icon={(props: any) => (
                                                                                <GalleryIcon {...props} />
                                                                            )}
                                                                            onChange={(e: any) => setImage(e)}
                                                                            value={image}
                                                                            extras={<div></div>}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    isDialogOpen === 4 ?
                                                                        <div>
                                                                            <label>

                                                                                Driver Name : {" "}
                                                                                {CustomerData?.driver?.name?.fName} {" "}
                                                                                {CustomerData?.driver?.name?.lName}
                                                                            </label>
                                                                            <TextArea
                                                                                className="w-100"
                                                                                rows={3}
                                                                                value={description}
                                                                                placeholder="Provide detail information regarding your issue"
                                                                                onChange={(e: any) => setDescription(e.target.value)}
                                                                            />
                                                                        </div>
                                                                        :
                                                                        isDialogOpen === 5 ?
                                                                            <TextArea
                                                                                className="w-100"
                                                                                rows={3}
                                                                                value={description}
                                                                                placeholder="Provide detail information regarding your issue"
                                                                                onChange={(e: any) => setDescription(e.target.value)}
                                                                            />
                                                                            :
                                                                            isDialogOpen === 6 ?
                                                                                <div className="grid gap-1">
                                                                                    <TextArea
                                                                                        className="w-100"
                                                                                        rows={3}
                                                                                        value={description}
                                                                                        placeholder="Provide detail information regarding your issue"
                                                                                        onChange={(e: any) => setDescription(e.target.value)}
                                                                                    />
                                                                                    <ImageInput
                                                                                        name="Add Image"
                                                                                        label="Image"
                                                                                        Icon={(props: any) => (
                                                                                            <GalleryIcon {...props} />
                                                                                        )}
                                                                                        onChange={(e: any) => setImage(e)}
                                                                                        value={image}
                                                                                        extras={<div></div>}
                                                                                    />
                                                                                </div>
                                                                                :
                                                                                <></>
                                                        }
                                                    </div>
                                                }
                                                footer={
                                                    <div className='flex jc-end gap-8'>
                                                        <SubmitButton variant="secondary" action="secondary" onClick={() => {
                                                            setIsDialogOpen(0),
                                                                raiseComplaint();
                                                        }}/>
                                                        <CloseButton onClick={() => setIsDialogOpen(0)}/>
                                                    </div>
                                                }
                                            />
                                            
                                        </PaperCard>
                                        {latComplaint !== undefined &&
                                            <PaperCard className="grid gap-1 mt-1">
                                                <PageTitle title='Last Complaint' style={{ fontSize: '1.8rem' }} />
                                                <div className="flex jc-between ai-center m-two-column m-gap-1">

                                                    <p className='flex ai-center gap-4'>
                                                        <div>
                                                            <CalendarIcon style={{ fontSize: "x-large", backgroundColor: "white", color: "var(--clr-primary-400)" }} />
                                                        </div>
                                                        <div>
                                                            {formatDate(latComplaint?.createdAt)}
                                                        </div>
                                                    </p>
                                                    <p className='flex ai-center gap-4'>
                                                        <div>
                                                            <HoursIcon style={{ fontSize: "x-large", backgroundColor: "white", color: "var(--clr-primary-400)" }} />
                                                        </div>
                                                        <div>
                                                            {timeConvert(latComplaint?.createdAt)}
                                                        </div>
                                                    </p>
                                                    <p className='flex ai-center gap-4'>
                                                        <div>
                                                            <ServiceTypeIcon style={{ fontSize: "x-large", backgroundColor: "white", color: "var(--clr-primary-400)" }} />
                                                        </div>
                                                        <div>
                                                            {latComplaint?.complaintType === "LD" ? `Late Delivery` : latComplaint?.complaintType === "PM" ? `Parcel Missing` :
                                                                latComplaint?.complaintType === "OT" ? `Other` : latComplaint?.complaintType === "DMG" ? `Parcel Damaged` :
                                                                    latComplaint?.complaintType === "PF" ? `Pilferege` : latComplaint?.complaintType === "MB" ? `Misbehavior by Kabra Employees` : ``}
                                                        </div>
                                                    </p>
                                                    <p className="flex ai-center gap-4">
                                                        <div>
                                                            <TimelineIcon style={{ fontSize: "x-large", backgroundColor: "white", color: "var(--clr-primary-400)" }} />
                                                        </div>
                                                        <div>
                                                            {latComplaint?.complaintStatus === "O" ? `Opened` : latComplaint?.complaintStatus === "I" ? `In progress` :
                                                                latComplaint?.complaintStatus === "CP" ? `Compensation Paid` : latComplaint?.complaintStatus === "CG" ? `Compensation Granted` :
                                                                    latComplaint?.complaintStatus === "CU" ? `Closed By Executive` : latComplaint?.complaintStatus === "CC" ? `Closed By Customer` :
                                                                        latComplaint?.complaintStatus === "CS" ? `Closed By System` : latComplaint?.complaintStatus === "RE" ? `Re Opened` : ``}
                                                        </div>
                                                    </p>
                                                    <p className='flex ai-center gap-4'>
                                                        <div>
                                                            <MessageAltIcon style={{ fontSize: "x-large", backgroundColor: "white", color: "var(--clr-primary-400)" }} />
                                                        </div>
                                                        <div>
                                                            {latComplaint?.messages[latComplaint?.messages.length - 1].text}
                                                        </div>
                                                    </p>
                                                    <p className="flex ai-center gap-4" onClick={() => {
                                                        handleIndividualCustomerOrderChat(CustomerData?.feedbacks?.complaints[CustomerData?.feedbacks?.complaints.length - 1],
                                                            CustomerData?.feedbacks?.complaints[CustomerData?.feedbacks?.complaints.length - 1]?.feedbackNumber, "CustomerBooking")
                                                    }}>
                                                        <ChatIcon className="text-primary-400" style={{ fontSize: "large" }} />
                                                    </p>
                                                </div>
                                            </PaperCard>
                                        }
                                    </>
                                }
                            </>
                        }
                    </> : <></>
                }

                <Dialog
                    state={Boolean(isPickupInfoOpen)}
                    size='small'
                    onClose={() => {
                        setIsPickupInfoOpen(0);
                    }}
                    header='View Package Details'
                    body={CustomerData?.packages?.map((neELe: any) => {
                        if (neELe.fromPlace == packageDataInfo?.placeId) {
                            return (
                                <div className="grid gap-4">
                                    <InfoPill className="flex jc-between mb-1 fw-600 gap-4 fs-link w-100 ai-center flex-wrap">
                                        <p>Service Type </p> <p> {packageDataInfo?.serviceType}</p>
                                    </InfoPill>
                                    <InfoPill className="flex jc-between mb-1 fw-600 gap-4 fs-link w-100 ai-center flex-wrap">
                                        <p>Package Number</p> <p> {neELe?.customerPkgIdentifier}</p>
                                    </InfoPill>
                                    <InfoPill className="flex jc-between mb-1 fw-600 gap-4 fs-link w-100 ai-center flex-wrap">
                                        <p>Material Name</p> <p> {neELe?.materialType?.name}</p>
                                    </InfoPill>
                                    <InfoPill className="flex jc-between mb-1 fw-600 gap-4 fs-link w-100 ai-center flex-wrap">
                                        <p>Quantity</p> <p> {neELe?.qty}</p>
                                    </InfoPill>
                                    <InfoPill className="flex jc-between mb-1 fw-600 gap-4 fs-link w-100 ai-center flex-wrap">
                                        <p>COD Amount</p> <p> {neELe?.codAmount}</p>
                                    </InfoPill>
                                </div>
                            )
                        } else if (neELe.toPlace == packageDataInfo?.placeId) {
                            return (
                                <div className="grid gap-4">
                                    <InfoPill className="flex jc-between mb-1 fw-600 gap-4 fs-link w-100 ai-center flex-wrap">
                                        <p>Service Type </p> <p> {packageDataInfo?.serviceType}</p>
                                    </InfoPill>
                                    <InfoPill className="flex jc-between mb-1 fw-600 gap-4 fs-link w-100 ai-center flex-wrap">
                                        <p>Package Number</p> <p> {neELe?.customerPkgIdentifier}</p>
                                    </InfoPill>
                                    <InfoPill className="flex jc-between mb-1 fw-600 gap-4 fs-link w-100 ai-center flex-wrap">
                                        <p>Material Name</p> <p> {neELe?.materialType?.name}</p>
                                    </InfoPill>
                                    <InfoPill className="flex jc-between mb-1 fw-600 gap-4 fs-link w-100 ai-center flex-wrap">
                                        <p>Quantity</p> <p> {neELe?.qty}</p>
                                    </InfoPill>
                                    <InfoPill className="flex jc-between mb-1 fw-600 gap-4 fs-link w-100 ai-center flex-wrap">
                                        <p>COD Amount</p> <p> {neELe?.codAmount}</p>
                                    </InfoPill>
                                </div>
                            )
                        }
                        <hr />
                    })}

                    footer={
                        <CloseButton onClick={() => {
                            setIsPickupInfoOpen(0)
                        }} />
                    }
                />


            </PaperCard>
        </div>
    )
}

export default CustomerTrackingView