// react
import React, { Dispatch, SetStateAction, useState } from 'react'
// react-router-dom
// css
import './mobile-menu.css';
// constants (icon)
import { HomeIcon, SearchIcon, SignInIcon } from "../../constants/icons";
// ui components
import { Button, Dialog, Input } from "../UI";
// utils (validation)
import { branchDocketValidate, branchTrackingIdValidate, customerDocketValidate } from "../../utils/validation";
import { StateObjType, StateType } from "../../pages/Landing/partner-forms/useCustomState";
import { useNavigate } from "react-router-dom";
import { useWidth } from "../../hooks/useMobile";
import { useLandingPageContext } from "../../hooks/useLandingPageContext";


// FC: MobileFooterNavigation Component
const MobileFooterNavigation = () => {
  // State
  const { setActiveCard } = useLandingPageContext();
  const { width } = useWidth();
  const [isTrackSearch, setIsTrackSearch] = useState(false);
  // State: Tracking Number
  const [trackingNumber, setTrackingNumber] = useState<StateType>(() => (
    {
      value: "",
      error: false,
      errorMessage: "Tracking Number is required!"
    }
  ));
  // useHistory
  const navigate = useNavigate();

  // does: handle tracking search
  const trackDocket = () => {
    if (typeof trackingNumber !== "object") return;
    const docket = trackingNumber.value.toUpperCase();
    const BranchDocketRegex = (str: any) => {
      const branchDocketRegex = /^[A-Z]{2,5}[1-9]{1}[0-9]{9}$/;
      return branchDocketRegex.test(str);
    }
    const BrnachTrackingIdRegex = (str: any) => {
      const trakingIdRegex = /^[A-Z]{3,5}-[0-9]{3,5}-[0-9]{0,10}$/;
      return trakingIdRegex.test(str);
    }
    const CustomerDocketRegex = (str: any) => {
      const customerDocketRegex = /^[A-Z]{2,4}-[A-Z]{1,3}-[0-9]{1,5}$/;
      return customerDocketRegex.test(str);
    }

    if (BranchDocketRegex(docket) === true) {
      alert("Tracking Number is Not Valid !")
      navigate({ pathname: "/" })
      setTrackingNumber((prevTrackingNumber) => {
        const prev = prevTrackingNumber as StateObjType;
        return { ...prev, value: "" }
      })
    }
    else if (BrnachTrackingIdRegex(docket) === true) {
      navigate("/TempTracking", {
        state: {
          from: "Customer-NotAuthenticated-BranchOrder",
          docketNumber: { docket },
          typeOfDocket: "Branch Order Tracking Id"
        }
      })
    }
    else if (CustomerDocketRegex(docket) === true) {
      navigate("/TempTracking", {
        state: {
          from: "Customer-NotAuthenticated-CustomerOrder",
          docketNumber: { docket },
          typeOfDocket: "Customer Docket"
        }
      })
    }
    else if (CustomerDocketRegex(docket) === false || BrnachTrackingIdRegex(docket) === false || BranchDocketRegex(docket) === false) {
      alert("Docket Number Or Tracking Number is Not Valid !")
      navigate({ pathname: "/" })
    }
  };
  // does: call the trackDocket function
  function handleTracking() {
    trackDocket();
  }

  // component return only on width less than 600
  if (width <= 600) {
    const iconSize = "20px";
    return <>
      <Dialog header={<>Search Tracking Number</>} body={<><TrackingNumber trackingNumber={trackingNumber} setTrackingNumber={setTrackingNumber} /></>} footer={<><Button variant="primary" action="secondary" onClick={() => handleTracking()} Icon={<SearchIcon />}>Search</Button></>} state={isTrackSearch} setState={setIsTrackSearch} variant="primary" size="small" isOutsideClickOff={false} />
      <nav className='mobile-menu-navigation'>
        <ul className='mobile-menu-list-container mobile-menu-list-1 | fs-link'>
          <li className="mobile-menu-list-item | pointer" onClick={() => navigate("/")}>
            <span style={{ fontSize: iconSize }}><HomeIcon /></span>
            <span>Home</span>
          </li>
          <button className="mobile-menu-list-item | pointer bg-transparent border-none outline-none" onClick={() => {
            setIsTrackSearch(true);
          }}>
            <span style={{ fontSize: iconSize }}><SearchIcon /></span>
            <span>Track</span>
          </button>
          <li className="mobile-menu-list-item | pointer" onClick={() => {navigate("/login");
            setActiveCard("")
          }} >
            <span style={{ fontSize: iconSize }}><SignInIcon /></span>
            <span>Account</span>
          </li>
        </ul>
      </nav>
    </>
  }

  return null;
}

function TrackingNumber({ trackingNumber, setTrackingNumber }: { trackingNumber: StateType, setTrackingNumber: Dispatch<SetStateAction<StateType>> }) {
  // Validation: Tracking Number
  // does: validate tracking number
  function trackingNumberValidation(e: React.FocusEvent<HTMLInputElement, Element>) {
    const value = e.target.value;
    if (value && !branchDocketValidate(value) && !customerDocketValidate(value) && !branchTrackingIdValidate(value)) {
      setTrackingNumber(prevTrackingNumber => {
        const prev = prevTrackingNumber as StateObjType;
        return { ...prev, error: true }
      })
      return alert("Please provide a valid tracking number!")
    }
  }

  return (<div className="relative">
    {/* <input type="search" placeholder="Tracking Number" className="tracking-number-search outline-none w-100" onBlur={e => trackingNumberValidation(e)} value={typeof trackingNumber === "object" ? trackingNumber.value : ""} onChange={e => setTrackingNumber(prevTrackingNumber => {
            const prev = prevTrackingNumber as StateObjType;
            return { ...prev, value: e.target.value }
        })} style={{ padding: "1rem 1.25rem" }} /> */}
    <Input title="Tracking Number" variant="primary" weight={600} state={{ value: trackingNumber, setValue: setTrackingNumber }} onBlur={trackingNumberValidation} warningWeight={50} required />
    {/* <SearchIcon className="tracking-number-search-icon" /> */}
  </div>)
}

export default MobileFooterNavigation