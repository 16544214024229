import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { hideLoader } from "../../../actions/UserActions";
import { showLoader } from "./../../../actions/UserActions";
import { connect, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { getCity } from "../../../actions/UserActions";
import {
  setDoorDelivery,
} from "../../../services/operations";
import showMessage, {
  failed,
  showHttpError,
  // showHttpError,
  success,
} from "../../../utils/message";
// import { getCityByPincode } from "../../../services/area";
import { getAvailableServicesIntraCityPrice } from "../../../services/intraCityRate";
import { setCustomerDoorDelivery } from "../../../services/customersection";
import { reqForcancelDoorDelivery, reqForcancelDoorDeliveryFromCustomer } from "../../../services/doorDelivery";
import { reqForDoorDeliveryDiscount } from "../../../services/discountRequest";
import { getDoorDeliveryRatesByCustomer } from "../../../services/rate";
// import {Paper} from "../../../Components/Paper/Paper";
import { BiRefresh } from 'react-icons/bi';
import { FiPackage } from 'react-icons/fi';
import { GiWeight } from 'react-icons/gi';
import MovableMap from '../../CustomerSection/MovableMap/MovableMap';
// import {AiOutlineClose} from 'react-icons/ai'
import { useGoogleMapJsLoader } from "../../../hooks/useGoogleMapJsLoader";
import { HoursIcon, InfoIcon, PackageIcon, PencilIcon, VehicleIcon, WeightIcon } from "../../../newSrc/constants/icons";
import GoogleAutoComplete from "../../../newSrc/components/GoogleAutoComplete/GoogleAutoComplete";
import { CreateEditLocationType, LocationType } from "../../../newSrc/pages/CustomerModule/Components/CustomerBooking/types/booking.type";
import { processLocationPayload } from "../../../newSrc/pages/CustomerModule/Components/CustomerBooking/utils/booking.utils";
import { Button, Dialog, InfoPill, PaperCard, Select, TextField } from "../../../newSrc/components/UI";
import { addAddressSuggetion, addAddressSuggetionByUser, editAddressSuggetion, editAddressSuggetionByUser, listAddressSuggetion, listAddressSuggetionByUser } from "../../../newSrc/components/GoogleAutoComplete/api";
import { LocationtypeEnums } from "../../../newSrc/pages/CustomerModule/Components/CustomerBooking/enums/booking.enums";
import { CancelButton, CloseButton, OkButton, SubmitButton, UpdateButton } from "../../../Components/Button/AllButton";
import { useWidth } from "../../../newSrc/hooks/useMobile";
// import { CloseIcon } from "../../../newSrc/constants/icons";

interface IProps {
  doorDeliveryPaymentMode: any;
  deliveryCharge: any;
  officeName: any;
  floor: any;
  tower: any;
  nearby_landmark: any;
  city: any;
  pincode: any;
  onChange: any;
  cityValue: any;
  citylist: any;
  locationType: any;
  isDeliveryModule?: any;
  extraDeliveryCharge?: any;
  showLoader?: any;
  hideLoader?: any;
  user?: any;
  docket?: any;
  origin?: any;
  paymentOptions?: any;
  update?: any;
  placeId?: any;
  coordinate?: any;
  placeValue?: any;
  name?: any;
  formatted_address?: any;
  area?: any;
  isSettled?: any;
  isBookingModule?: any;
  onMakePaymentSuccess?: any;
  width?: any;
  state?: any;
  shouldDoorDeliveryBook?: any;
  children?: any;
  open?: any;
  setOpen?: any;
  disablePayment?: any;
  disableAddress?: any;
  showPaymentInfo?: boolean;
  fromPlaceId?: any;
  toPlaceId?: any;
  delCity?: any;
  packages?: any;
  serviceType?: any;
  assetType?: any;
  isCustomerTracking?: any;
  doorDeliveryDocketId?: any;
  doorDeliveryDiscount?: any;
  receiverId?: any;
  onReset?: any;
  doorDelDocketNumber?: any;
  whereFrom?: any;
  handleAfterBooking?: any;
  receiver?: any;
  searchLocation?: any
  l1: any;
  l2: any;
}

// type PaymentMode = {
//   label: string;
//   value: string;
// };

const DoorDelivery = forwardRef(
  ({ showPaymentInfo = true, ...props }: IProps, ref: any) => {
    // let {open, setOpen} = props
    // const { isLoaded } = useJsApiLoader({
    //   googleMapsApiKey: "AIzaSyA5kw1jdPoWOGfUDL0o2vW0Ezf2Cvq12OU",
    //   libraries: ["places"],
    //   nonce: "nonce",
    // });
    const { isLoaded } = useGoogleMapJsLoader()
    console.log(`from Main 2029`, props);
    const [extraDoorDeliveryCharge, setExtraDoorDeliveryCharge] = useState(0);
    const [newExtraDoorDeliveryCharge, setNewExtraDoorDeliveryCharge] =
      useState(0);
    const [extraDoorDeliveryDialog, setExtraDoorDeliveryDialog] =
      useState(false);
    const [doorDeliveryCharge, setDoorDeliveryCharge] = useState<any>(props?.deliveryCharge);
    const [doorDeliveryDiscount, setDoorDeliveryDiscount] = useState<any>(0);
    const [city, setCity] = useState<any>("");
    const [paymentMode, setPaymentMode] = useState<any>("");
    const [state, setState] = useState<any>("");
    const [servicesOptions, setServicesOptions] = useState<any>("");
    const [expressServicesOptions, setExpressServicesOptions] = useState<any>("");
    const [service, setService] = useState<any>("");
    const [fleetTypeWiseRate, setFleetTypeWiseRate] = useState<any>("");
    const [totalDuration, setTotalDuration] = useState(0);
    const [totalDistance, setTotalDistance] = useState<any>(0);
    // const [totalKms, setTotalKms] = useState<any>(0);
    const [totalKg, setTotalKg] = useState<any>(0);
    const [totalPkg, setTotalPkg] = useState<any>(0);
    const [cancelOrderRemarks, setCancelOrderRemarks] = useState<any>("");
    const [discountRemarks, setDiscountRemarks] = useState<any>("");
    const [cancelOrderDialog, setCancelOrderDialog] = useState<any>("");
    const [discountDialog, setDiscountDialog] = useState<any>("");
    const [discountAmount, setDiscountAmount] = useState<any>("");
    const [customerPrice, setCustomerPrice] = useState<any>([]);
    // const [paymentModes, setPaymentModes] = useState<PaymentMode[]>([]);
    const [showDoorDelCharge, setShowDoorDelCharge] = useState<any>(false);
    const [showExtra, setShowExtra] = useState<any>(true);
    const [openPkgDetail, setOpenPkgDetail] = useState<any>(false);
    const [savedAddresses, setSavedAddresses] = useState<any[]>([]);
    const [selectedAddress, setSelectedAddress] = useState<any>();
    const [activeLocationType, setActiveLocationType] = useState<LocationType | null>(null);
    const [googleAutoComplete, setGoogleAutoComplete] = useState<any>(null);
    const { mobileView } = useWidth();


    const paymentRights: any = useSelector<
      RootState,
      {
        credit: boolean;
        foc: boolean;
        paid: boolean;
        toPay: boolean;
        nonCash: boolean;
      }
    >((state) => state.user.opBranch.booking);

    useEffect(() => {
      setPaymentModeOptions();
    }, [paymentRights]);

    useEffect(() => {
      handleChargesDisable("charge");
      handleChargesDisable("extra");
    }, [paymentMode]);

    const setPaymentModeOptions = () => {

      // const paymentModesTemp: any = paymentRights
      //   ? Object.keys(paymentRights)
      //       .map((r: any) => {
      //         if (r != "credit" && r!="foc" && r!="toPay") return null;
      //         return  { label: r, value: r.toLowerCase() }
      //       })
      //       .filter((p) => !!p)
      //       .sort((a: any, b: any) => b.label.localeCompare(a.label))
      //   : [];
      // setPaymentModes(paymentModesTemp);
    };

    const handleDialogClose = () => {
      props.setOpen(false);
      if (!props.isBookingModule && !props.doorDelDocketNumber && !props.isCustomerTracking) props.onReset();
    };

    useImperativeHandle(ref, () => ({
      handleOpen() {
        props.setOpen(true);
      },
    }));

    useEffect(() => {
      if (props.open === false && !props.isCustomerTracking && !props?.isDeliveryModule && !props?.isBookingModule) {
        setService("");
        setTotalDuration(0);
        setTotalDistance(0);
        setTotalKg(0);
        setTotalPkg(0);
      }
    }, [props.open]);

    useEffect(() => {
      props.onChange("city", props.delCity);
    }, [props.doorDelDocketNumber])

    const getcustomerPrice = async () => {
      const payload: any = {
        fromPlace: props?.fromPlaceId,
        toPlace: props?.toPlaceId,
        receiver: props?.receiverId
      }

      try {
        const response = await getDoorDeliveryRatesByCustomer(payload);
        let charges: any = [];
        response.map((e: any) => {
          charges.push({ label: e?.totalDoorDelCharges, value: e?.totalDoorDelCharges })
        })
        setCustomerPrice(charges);
      }
      catch (err: any) {
        showHttpError(err.message);
      }
    }

    useEffect(() => {
      if (props.fromPlaceId && props.toPlaceId && props.receiverId) getcustomerPrice();
    }, [props.fromPlaceId, props.toPlaceId, props.receiverId]);

    useEffect(() => {
      if (props.serviceType) {
        setService({
          label: props.serviceType,
          value: doorDeliveryCharge,
          serviceType: props.serviceType,
          assetType: props.assetType?._id,
        });

        if (props.serviceType === 'EXPRESS' && props.assetType?.assetName) {
          setFleetTypeWiseRate({
            label: `${props.assetType?.assetName} , ₹${Number(doorDeliveryCharge)} `,
            value: doorDeliveryCharge,
            serviceType: "EXPRESS",
            assetType: props.assetType?._id
          })
        }
      }
    }, [props.serviceType, doorDeliveryCharge]);

    useEffect(() => {
      setExtraDoorDeliveryCharge(
        parseInt(props.extraDeliveryCharge)
          ? parseInt(props.extraDeliveryCharge)
          : 0
      );


      console.log("checking 21212", props.deliveryCharge, props.doorDeliveryDiscount)
      setDoorDeliveryCharge(
        parseInt(props.deliveryCharge) ? parseInt(props.deliveryCharge) : 0
      );
      setDoorDeliveryDiscount(
        parseInt(props.doorDeliveryDiscount) ? parseInt(props.doorDeliveryDiscount) : 0
      );
    }, [props.extraDeliveryCharge, props.deliveryCharge, props.doorDeliveryDiscount]);

    console.log("totalDistanceMeter", totalDistance, totalDuration);
    // const getCityFromPincode = async (pin: any) => {
    //   try {
    //     if (props.pincode && props.pincode.toString().length === 6) {
    //       let data = await getCityByPincode(pin);
    //       props.onChange("city", {
    //         label: data.name,
    //         value: data._id,
    //       });
    //     }
    //   } catch (err : any) {
    //     showHttpError(err);
    //   }
    // };
    // // useEffect(() => {
    // //   getCityFromPincode(props.pincode);
    // // }, [props.pincode]);

    useEffect(() => {
      if (props.receiver?.contact && props?.open) {
        if (props.isCustomerTracking) {
          getSuggestionsForCustomers()
        }
        else {
          getSuggestions()
        }
      }
    }, [props.receiver, props?.open])

    useEffect(() => {
      props.city && typeof props.city === "string"
        ? // ? setCity(allCities.find((x: any) => x.value === props.city))
        setCity({ value: props.city, label: props.city })
        : setCity(props.city);
    }, [props.city, props.open]);

    console.log("set city", city);
    useEffect(() => {
      props.state && typeof props.state === "string"
        ? // ? setCity(allCities.find((x: any) => x.value === props.city))
        setState({ value: props.state, label: props.state })
        : setState(props.state);
    }, [props.state, props.open]);

    useEffect(() => {
      props.doorDeliveryPaymentMode &&
        typeof props.doorDeliveryPaymentMode === "string"
        ? setPaymentMode({
          value: props.doorDeliveryPaymentMode,
          label: props.doorDeliveryPaymentMode,
        })
        : setPaymentMode(props.doorDeliveryPaymentMode);
    }, [props.doorDeliveryPaymentMode]);

    console.log(isLoaded, origin);

    // useEffect(() => {
    //   if(parseInt(doorDeliveryCharge) <= 0){
    //     props.onChange('doorDeliveryPaymentMode', {value: 'topay', label: 'topay'})
    //   }
    // }, [doorDeliveryCharge])

    // const paymentDiv = () => {
    //   return (
    //     <div>
    //       {props.isSettled && (
    //         <p style={{ textAlign: "center", fontSize: 17 }}>
    //           Door Delivery Payment Is Already Settled
    //         </p>
    //       )}
    //       <Grid container spacing={0} style={{ padding: "4px" }}>
    //         <Grid
    //           md={6}
    //           xs={9}
    //           item
    //           style={{
    //             paddingTop: "10px",
    //             fontSize: "16px",
    //             fontWeight: "bold",
    //           }}
    //         >
    //           Particulars
    //         </Grid>
    //         <Grid
    //           md={3}
    //           xs={3}
    //           item
    //           style={{
    //             paddingTop: "10px",
    //             fontSize: "16px",
    //             fontWeight: "bold",
    //           }}
    //         >
    //           Amount
    //         </Grid>
    //       </Grid>

    //       <Grid container spacing={0} style={{ padding: "4px" }}>
    //         <Grid md={3} xs={1} item></Grid>
    //       </Grid>
    //       <Grid container spacing={0} style={{ padding: "4px" }}>
    //         <Grid md={6} xs={9} item style={{ paddingTop: "10px" }}>
    //           Door Delivery Charge{" "}
    //         </Grid>
    //         <Grid md={3} xs={2} item>
    //           <TextField
    //             variant="standard"
    //             inputProps={{
    //               style: { height: "3px" },
    //             }}
    //             style={{ zIndex: 0 }}
    //             disabled={props.doorDeliveryPaymentMode === "paid"}
    //             value={doorDeliveryCharge}
    //             margin="normal"
    //             fullWidth={true}
    //             onChange={(e: any) =>
    //               setDoorDeliveryCharge(parseInt(e.target.value))
    //             } />
    //         </Grid>
    //       </Grid>
    //       <Grid container spacing={0} style={{ padding: "4px" }}>
    //         <Grid md={6} xs={9} item style={{ paddingTop: "10px" }}>
    //           Extra Delivery Charge{" "}
    //         </Grid>
    //         <Grid md={3} xs={2} item>
    //           <TextField
    //             variant="standard"
    //             inputProps={{
    //               style: { height: "3px" },
    //             }}
    //             style={{ zIndex: 0 }}
    //             disabled={true}
    //             value={extraDoorDeliveryCharge}
    //             margin="normal"
    //             fullWidth={true} />
    //         </Grid>
    //         <Grid md={3} xs={1} item>
    //           <i
    //             className="fas fa-pencil-alt"
    //             onClick={() => setExtraDoorDeliveryDialog(true)}
    //           ></i>
    //         </Grid>
    //       </Grid>
    //       <Grid container spacing={0} style={{ padding: "4px" }}>
    //         <Grid
    //           md={6}
    //           xs={9}
    //           item
    //           style={{
    //             paddingTop: "10px",
    //             fontSize: "16px",
    //             fontWeight: "bold",
    //           }}
    //         >
    //           Total
    //         </Grid>
    //         <Grid md={3} xs={2} item>
    //           <TextField
    //             variant="standard"
    //             inputProps={{
    //               style: { height: "3px" },
    //             }}
    //             style={{ zIndex: 0 }}
    //             fullWidth={true}
    //             id="total"
    //             placeholder="Total"
    //             InputProps={{
    //               readOnly: true,
    //             }}
    //             value={doorDeliveryCharge + extraDoorDeliveryCharge}
    //             margin="normal" />
    //         </Grid>
    //         <Grid md={3} xs={1} item></Grid>
    //       </Grid>
    //     </div>
    //   );
    // };

    // console.log(paymentDiv());
    
    // const handleMakePayment = async (paymentMode:any) => {
    //   try {
    //     props.showLoader();

    //     let payload: any = {
    //       docket: props.docket,
    //       doorDeliveryAddr: {},
    //       discount:doorDeliveryDiscount,
    //       settledCollectedAmount:paymentMode==="paid" ? extraDoorDeliveryCharge : Number(doorDeliveryCharge) + Number(extraDoorDeliveryCharge) - Number(doorDeliveryDiscount)  ,
    //       settledAmount: Number(doorDeliveryCharge) + Number(extraDoorDeliveryCharge) - Number(doorDeliveryDiscount) 
    //     };

    //     if (props.user.opBranch && props.user.opBranch._id) {
    //       payload.sub = "B";
    //       payload.entity = props.user.opBranch._id;
    //     } else {
    //       payload.sub = "F";
    //       payload.entity = props.user.opFleet._id;
    //     }

    //     payload.doorDeliveryAddr.placeId =
    //       props.origin && props.origin.place_id;
    //     payload.doorDeliveryAddr.coordinate = {
    //       latitude: props?.coordinate?.latitude,
    //       longitude: props?.coordinate?.longitude,
    //     };
    //     if(paymentMode==="credit") payload.paymentMode= paymentMode;
    //     payload.doorDeliveryAddr.placeValue = props.placeValue;
    //     payload.doorDeliveryAddr.city = city?.value;
    //     payload.doorDeliveryAddr.name = props.name;
    //     if(props.pincode)
    //       payload.doorDeliveryAddr.pincode = props.pincode;
    //     payload.doorDeliveryAddr.formatted_address = props.formatted_address;
    //     await doorDeliveryMakePayment(payload);
    //     props.setOpen(false);
    //     props.onMakePaymentSuccess();
    //     showMessage("Make Payment Success", success);
    //     props.hideLoader();
    //   } catch (err:any) {
    //     showMessage(err.message, failed);
    //     props.hideLoader();
    //   }
    // };


    const getSuggestionsForCustomers = async () => {
      console.log(`kjsdfsdncwdc`)
      let data: any[] = [];
      let payload = {
        customerContact: props?.receiver?.contact,
      };
      try {
        const response = await listAddressSuggetion(payload);
        response?.forEach((address: any) => {
          data.push({
            value: address.placeId,
            label: address?.place?.formatted_address,
            locationType: address?.locationType || null,
            l1: address?.l1,
            l2: address?.l2,
            isDataFromSuggetion: true,
            place: address?.place,
            city: address?.city,
            customerName: address?.customerName,
            customerContact: address?.customerContact,
            _id: address?._id,
          });
        });

        // Add "Use current location" option to the beginning of the list
        data.unshift({
          value: "current_location",
          label: "",
          _id: undefined,
          isDataFromSuggetion: false,
        });
        setSavedAddresses(data);
      } catch (error) {
        console.error("Error fetching address suggestions", error);
        showHttpError(error);
      }
    };

    const getSuggestions = async () => {
      let contact = /[1-9]{1}[0-9]{9}/;
      if (!contact.test(props?.receiver?.contact)) {
        return showHttpError("Contact number Must be 10 Digits !");
      }
      let data: any[] = [];
      let payload = {
        customerContact: props?.receiver?.contact,
      };
      try {
        const response = await listAddressSuggetionByUser(payload);
        response?.forEach((address: any) => {
          data.push({
            value: address.placeId,
            label: address?.place?.formatted_address,
            locationType: address?.locationType || null,
            l1: address?.l1,
            l2: address?.l2,
            isDataFromSuggetion: true,
            place: address?.place,
            city: address?.city,
            customerName: address?.customerName,
            customerContact: address?.customerContact,
            _id: address?._id,
          });
        });

        // Add "Use current location" option to the beginning of the list
        data.unshift({
          value: "current_location",
          label: "",
          _id: undefined,
          isDataFromSuggetion: false,
        });
        setSavedAddresses(data);
      } catch (error) {
        console.error("Error fetching address suggestions", error);
        showHttpError(error);
      }
    };

    console.log('new address data', savedAddresses, props.receiver, state, props?.searchLocation)

    const handleCancel = () => {
      props.onChange('searchLocation', undefined);
    };

    const handleCreateEditLocationSuggetion = async (
      data: CreateEditLocationType,
      id?: string
    ) => {
      props.showLoader()
      try {
        let response;
        let locationData: any = {};
        if (!props?.searchLocation?.isEdit) {
          if (props.isCustomerTracking) {
            response = await addAddressSuggetion(data);
          }
          else {
            response = await addAddressSuggetionByUser(data);
          }
        } else {
          if (props.isCustomerTracking) {
            response = await editAddressSuggetion(id ? id : "", data);
          }
          else {
            response = await editAddressSuggetionByUser(id ? id : "", data);
            locationData.isEdit = false
          }
        }
        if (props.isCustomerTracking) {
          getSuggestionsForCustomers()
        }
        else {
          getSuggestions()
        }
        locationData = { ...locationData, isDataFromSuggetion: true }
        props.onChange('searchLocation', { ...locationData, l1: '', l2: '' })
        setGoogleAutoComplete(null)
        setActiveLocationType(response?.[0]?.locationType);
      } catch (error) {
        showHttpError(error);
      } finally {
        props.hideLoader()
      }
    };

    const handleLocationSave = async () => {
      const coreData: any = {
        place: {
          searchLocation: props?.searchLocation
        }
      }
      let data;
      if (!coreData?.place?.searchLocation?.isEdit) {
        data = processLocationPayload([coreData], 0);
      }
      
      let locationObj = coreData;
      let processedLocation;
      if (data || coreData?.place?.searchLocation?.isEdit) {
        if (coreData?.place?.searchLocation?.isEdit) {
          processedLocation = {
            placeId: locationObj?.place?.searchLocation?.place
              .placeId as string,
            coordinate: {
              latitude: locationObj.place.searchLocation?.place?.coordinate
                ?.latitude as number,
              longitude: locationObj.place.searchLocation?.place?.coordinate
                ?.longitude as number,
            },
            placeValue: locationObj.place.searchLocation?.place?.placeValue,
            city: locationObj.place.searchLocation?.place?.city?.name || "",
            area: locationObj.place.searchLocation?.place?.area?.name || "",
            name: locationObj.place.searchLocation?.place?.name,
            pincode: locationObj.place.searchLocation?.place?.pincode || "",
            formatted_address:
              locationObj.place.searchLocation?.place?.formatted_address,
            state: locationObj.place.searchLocation?.place?.state?.name || "",
          };
        }

        let payload = {
          l1: locationObj?.place?.searchLocation?.l1,
          l2: locationObj?.place?.searchLocation?.l2,
          locationType: activeLocationType as LocationType,
          place: !coreData?.place?.searchLocation?.isEdit ? data?.place : processedLocation,
          remarks: locationObj?.place?.searchLocation?.locationRemark,
          customerName: props.receiver?.name,
          customerContact: Number(props.receiver?.contact),
          _id: locationObj?.place?.searchLocation?._id,
        };
        await handleCreateEditLocationSuggetion(
          payload as CreateEditLocationType,
          locationObj?.place?.searchLocation?._id
        );
      }
    };

    const handleCancelDoorDelivery = async () => {
      console.log("come", props.doorDeliveryDocketId)
      const payload = {
        "doorDeliveryDocketId": props.doorDeliveryDocketId,
        "entity": props.user.opBranch._id,
        "sub": "B",
        "cancelReason": cancelOrderRemarks,

      }
      console.log(`props 20251`, props)
      const payloadFromCustomer = {
        doorDeliveryDocketId: props.doorDeliveryDocketId,
        cancelReason: cancelOrderRemarks
      }

      try {
        if (props?.user?.uid === null || props?.user?.uid === undefined) {
          await reqForcancelDoorDeliveryFromCustomer(payloadFromCustomer);
          showMessage("Successfully Cancelled");
        }
        else {
          await reqForcancelDoorDelivery(payload);
          showMessage("Successfully Cancelled");
        }
      }
      catch (err) {
        showHttpError(err);
        setCancelOrderDialog(false)
      }
    }

    const handleDoorDeliveryDiscount = async () => {
      const payload = {
        "entity": props.user.opBranch._id,
        "sub": "B",
        "amount": discountAmount,
        "remarks": discountRemarks,

      }

      try {
        console.log("checked", props.doorDeliveryDocketId)
        await reqForDoorDeliveryDiscount(props.doorDeliveryDocketId, payload);
        showMessage("Successfully Discount Approved");
      }
      catch (err) {
        showHttpError(err);
        setCancelOrderDialog(false)
      }
    }

    const handleUpdate = async () => {
      try {
        props.showLoader();

        let payload: any = {
          docketNumber: props.docket,
          doorDeliveryAddr: {},
          doorDelCharges: props.deliveryCharge,
          place: {},
          serviceType: service.serviceType,
          doorDelAddressId: props.searchLocation?._id
        };

        if (service.serviceType === "EXPRESS") {
          payload.assetType = fleetTypeWiseRate?.assetType || props.assetType;
        }
        if (props.user.opBranch && props.user.opBranch._id) {
          payload.sub = "B";
          payload.entity = props.user.opBranch._id;
        } else {
          payload.sub = "B";
          payload.entity = props.user.opBranch._id;
        }

        console.log("pirops", props, payload);
        // payload.doorDeliveryAddr.city = props.city.value;
        payload.doorDeliveryAddr.pincode = props.searchLocation?.place?.pincode;
        payload.doorDeliveryAddr.placeId = props.searchLocation?.place?.placeId;
        payload.doorDeliveryAddr.l1 = props.searchLocation?.l1;
        payload.doorDeliveryAddr.l2 = props.searchLocation?.l2;
        payload.doorDeliveryAddr.locationType = props.searchLocation?.locationType;

        payload.place.placeId = props.searchLocation?.place?.placeId;
        payload.place.coordinate = {
          latitude: props.searchLocation?.place?.coordinate?.latitude,
          longitude: props.searchLocation?.place?.coordinate?.longitude,
        };
        payload.place.placeValue = props.searchLocation?.place?.formatted_address;
        payload.place.city = props.searchLocation?.place?.city?.name;
        payload.place.state = props.searchLocation?.place?.state?.name;
        payload.place.name = props.searchLocation?.place?.name;
        payload.place.area = props.searchLocation?.place?.area?.name;
        payload.place.pincode = props.searchLocation?.place?.pincode;

        payload.place.formatted_address = props.searchLocation?.place?.formatted_address;
        if (props.isCustomerTracking) {
          await setCustomerDoorDelivery(payload);
        } else {
          await setDoorDelivery(payload);
        }
        if (props.whereFrom === "callRecords") props.handleAfterBooking();
        showMessage("Success", success);
        handleDialogClose();
        props.hideLoader();
      } catch (err: any) {
        showHttpError(err);
        props.hideLoader();
      }
    };
    
    console.log(googleAutoComplete,'googleAutoComplete googleAutoComplete googleAutoComplete googleAutoComplete');

    const secondsToHms: any = (d: any) => {
      d = Number(d);
      var h = Math.floor(d / 3600);
      var m = Math.floor((d % 3600) / 60);

      var hDisplay = h > 0 ? h + (h == 1 ? " Hour, " : " Hours, ") : "";
      var mDisplay = m > 0 ? m + (m == 1 ? " Minute, " : " Minutes") : "";
      return hDisplay + mDisplay;
    };

    console.log(props,'props props props props props')

    const getIntraCityPrice = async () => {

      console.log(`hzsdcnsdf`, props.searchLocation)
      let size: any = [];
      setExpressServicesOptions("");
      props.packages.map((x: any) => {
        size.push(typeof x?.size === "string" ? x?.size : x?.size?.value)
      });

      try {
        let payload = {
          places: [
            {
              fromPlace: props.fromPlaceId,
              toPlace: props.isCustomerTracking ? (props.searchLocation?.isDataFromSuggetion ? props.searchLocation?.place?.placeId : props.searchLocation?.place_id) : props.searchLocation?.place?.placeId,
            },
          ],
          city: props?.delCity?.label,
          size: size,
          kg: Number(props.packages.reduce((acc: any, curr: any) => acc + curr.weight * curr.qty, 0)),
        };
        let data = await getAvailableServicesIntraCityPrice(payload);

        let { intraCityPrice, totalDistanceMeter, totalDuration }: any = data;

        setTotalDistance(`${(totalDistanceMeter / 1000).toFixed(1)} KM`);
        // setTotalKms(parseInt(totalDistanceMeter/1000));
        setTotalDuration(secondsToHms(totalDuration));
        if (intraCityPrice.length === 0) {
          showMessage("No IntraCity Rate Found", failed);
        }
        setTotalKg(Number(props.packages.reduce((acc: any, curr: any) => acc + curr.weight * curr.qty, 0)))
        setTotalPkg(Number(props.packages.reduce((acc: any, curr: any) => acc + curr.qty, 0)))

        let isRegularPriceNotExist = false;
        console.log(isRegularPriceNotExist)
        let regularCost = 0;
        props.packages.map((x: any) => {
          let size = typeof x?.size === "string" ? x?.size : x?.size?.value;
          let cost = intraCityPrice.find(
            (y: any) => y.type === "REGULAR" && y.size === size
          )?.price;
          if (!cost) {
            isRegularPriceNotExist = true;
          }
          console.log(
            "cost, x.qty, cost & x.qty",
            cost,
            x.qty,
            cost & x.qty,
            props.packages
          );
          regularCost += cost * x.qty;
        });


        setServicesOptions([{
          value: regularCost,
          label: `₹${regularCost}`,
          serviceType: "REGULAR",
        }]);

        let expressCost: any = [], lowerRate: any;
        intraCityPrice
          .filter((x: any) => x.type === "EXPRESS")
          .sort(
            (a: any, b: any) => a.assetSubType?.assetType.capacity - b.assetSubType?.assetType.capacity
          )
          .map((x: any) => {
            // if(x.assetType.capacity >= totalKg && expressCost.length<2) 
            expressCost.push(x)
            // else lowerRate=x;
          });
        if (lowerRate) expressCost.push(lowerRate);
        expressCost.map((e: any) => {
          setExpressServicesOptions((expressServicesOptions: any) => [...expressServicesOptions, {
            label: `${e?.assetSubType?.assetType?.assetName} - ₹${Math.round(Number(e?.price * totalDistanceMeter / 1000))} `,
            value: Math.round(Number(e?.price * totalDistanceMeter / 1000)),
            serviceType: "EXPRESS",
            assetType: e?.assetSubType?.assetType?._id,
            availableFreeMinutes: e?.availableFreeMinutes,
            perMinuteCharge: e?.perMinuteCharge,
          }]);
        }
        );


        // if (servicesOptions.length > 0) {
        //   let cheapest = servicesOptions.sort(
        //     (a: any, b: any) => a.value - b.value
        //   )[0];
        //   props.onChange("deliveryCharge", cheapest.value);
        //   if (parseInt(doorDeliveryCharge) <= 0) {
        //     props.onChange("doorDeliveryPaymentMode", {
        //       value: "topay",
        //       label: "topay",
        //     });
        //   }
        //   props.onChange("serviceType", cheapest.serviceType);
        //   if (cheapest.serviceType === "EXPRESS") {
        //     props.onChange("assetType", cheapest.assetType);
        //   }
        //   setService(cheapest);
        //   setServicesOptions(servicesOptions);
        // } else {
        //   props.onChange("deliveryCharge", 0);

        //   props.onChange("doorDeliveryPaymentMode", {
        //     value: "topay",
        //     label: "topay",
        //   });

        //   setService("");
        //   setServicesOptions(servicesOptions);
        // }

      } catch (err: any) {
        showHttpError(err);
      }
    };

    useEffect(() => {
      // if(props.coordinate && props.open && props.formatted_address)getIntraCityPrice();
    }, [props.doorDelDocketNumber, props.coordinate, props.open])

    const handleChargesDisable = (typeOfCharge: any) => {

      if (typeOfCharge === "charge") {
        console.log("check ing", props.isDeliveryModule);
        if (props.isCustomerTracking) setShowDoorDelCharge(false);
        else if (paymentMode && typeof paymentMode === "string" && paymentMode === 'topay') setShowDoorDelCharge(true);
        else if (paymentMode && typeof paymentMode === "object" && paymentMode.value === 'topay') setShowDoorDelCharge(true);

      }
      else {
        if (props.isBookingModule || props.isCustomerTracking || !props.doorDelDocketNumber) setShowExtra(false);
        else if (paymentMode && typeof paymentMode === "string" && paymentMode === 'topay') setShowExtra(false);
        else if (paymentMode && typeof paymentMode === "object" && paymentMode.value === 'topay') setShowExtra(false);
      }
    };

    useEffect(() => {

      if (props.open) {
        if (props?.searchLocation) {
          if (props.searchLocation?.place) {
            setSelectedAddress({
              formatted_address: props.searchLocation?.place?.formatted_address,
              placeId: props.searchLocation?.place?.placeId
            })
          }
        }
        console.log("check ing 9.556", props.searchLocation, `props.open`, props.open, googleAutoComplete);
      }
      console.log("check ing 9.557", doorDeliveryCharge, `jhusdfnsv`, googleAutoComplete, `sdvjsdfdfvsdvf`, props);
    }, [googleAutoComplete, props.open]);

    console.log("doooorDe", doorDeliveryCharge, `jhusdfnsv`, googleAutoComplete);

    const isDisabled: boolean = Boolean(props.searchLocation?.isDataFromSuggetion && !props.searchLocation?.isEdit);
    return <>
      <Dialog 
        state={extraDoorDeliveryDialog}
        onClose={()=> setExtraDoorDeliveryDialog(false)} 
        header='Extra Door Delivery Charge'
        size="small"
        body={
          <TextField
            label="Extra Door Delivery Charge"
            type="Number"
            value={newExtraDoorDeliveryCharge}
            onChange={(e: any) => {
              setNewExtraDoorDeliveryCharge(parseInt(e.target.value));
            }}
          />
        }
        footer={
          <OkButton
            variant="secondary"
            action="secondary"
            onClick={() => {
              setExtraDoorDeliveryCharge(
                newExtraDoorDeliveryCharge + extraDoorDeliveryCharge
              );
              setExtraDoorDeliveryDialog(false);
            }}
          />
        }
      />
      {props.children}
      {/* {
      props.isSettled && 
      <div style={{display: 'flex'}}>
      <ButtonPrimary disabled={true}>
        Door Delivery Settled : {doorDeliveryCharge} 
       
      </ButtonPrimary>
       <i
       className="fa fa-info-circle"
       style={{
         color: "blue",
         fontSize: "20px",
         marginTop: "6px",
       }}
       onClick={() => setOpen(true)}
     ></i>
      </div>
    }
    {(!props.shouldDoorDeliveryBook && !props.isSettled) && (
      <ButtonDefault style={{ marginLeft: 25 }} onClick={() => setOpen(true)}>
        Door Delivery Charge {paymentMode?.value}{" "}
        {doorDeliveryCharge > 0 && doorDeliveryCharge}
      </ButtonDefault>
    )}
    {(props.shouldDoorDeliveryBook && !props.isSettled) && (
      <ButtonPrimary style={{ marginLeft: 25 }} onClick={() => setOpen(true)}>
        Door Delivery Charge {paymentMode && paymentMode?.value}{" "}
        {doorDeliveryCharge}
      </ButtonPrimary>
    )} */}

      <Dialog
        state={props.open}
        onClose={handleDialogClose}
        size="medium"
        header={<>Door Delivery {props.doorDelDocketNumber}</>}
        body=   {!props.disableAddress && (
          <div >
            {/* <Paper elevation={2} style={{padding:"0px 15px 10px 0px",margin:"5px 0px"}}> */}
              <PaperCard>
                <div className="flex gap-1 m-flex-col">

              <GoogleAutoComplete
                general={false}
                where={"Customer"}
                className={`settledAddresses`}
                value={googleAutoComplete !== null ? googleAutoComplete : ''}
                onChange={(data: any) => {
                  try {
                    setGoogleAutoComplete({
                      formatted_address: data?.formatted_address || data?.place?.formatted_address,
                      placeId: data?.place_id || data?.place?.placeId
                    })
                    if (data.isDataFromSuggetion) {
                      props.onChange("searchLocation", data)
                      props.onChange("l1", data.l1);
                      props.onChange("l2", data.l2);
                      props.onChange("locationType", data.locationType);
                      props.onChange("isEdit", data.isEdit);


                      setActiveLocationType(data.locationType);
                    } else {
                      if (data?.type === "current_location") {
                        props.onChange("searchLocation", data)
                      } else {
                        props.onChange("searchLocation", data)
                        props.onChange("l1", data.l1);
                        props.onChange("l2", data.l2);
                        props.onChange("locationType", data.locationType);
                        props.onChange("isEdit", data.isEdit);

                        setActiveLocationType(null);
                      }
                    }
                  } catch (err: any) {
                    showHttpError(err);
                  }
                }}
                extraField={{
                  index: 0,
                  savedAddresses,
                  selectedAddress,
                  setSavedAddresses,
                  getSuggestions,
                  getSuggestionsForCustomers,
                }}
              />
              <Button className="flex-center p-4" onClick={() => { props.onReset(), setCity(""), setState(""),setGoogleAutoComplete(null) }}>
                <BiRefresh className="fs-h5" />{mobileView && "Refresh"}
              </Button>
              </div>

              {props.isCustomerTracking &&
                <MovableMap driverMode={true}
                  latLongData={{
                    "latFrom": props?.coordinate?.lat || props.user.latitude,
                    "lngFrom": props?.coordinate?.lng || props.user.longitude
                  }}
                />
              }

            </PaperCard>
            {/* </Paper> */}

            {/* <Paper style={{padding:"0px 15px 10px 0px",margin:"5px 0px"}} elevation={2}> */}
            <PaperCard className="two-column gap-1 m-flex-col mt-1">           
                  <TextField
                    disabled={props.isSettled || isDisabled}
                    placeholder="Enter Office Name / Number"
                    className="br-4 py-8 px-1 border-white w-100"
                    label='Office Name / Number'
                    value={props?.searchLocation?.l1}
                    onChange={(e: any) => {
                      props.onChange("l1", e.target.value)
                      props.onChange("searchLocation", { ...props.searchLocation, l1: e.target.value })
                    }}
                  />
                  <TextField
                    disabled={props.isSettled || isDisabled}
                    label='Landmark (optional)'
                    placeholder="Enter Landmark"
                    className="br-4 py-8 px-1 border-white w-100"
                    value={props?.searchLocation?.l2}
                    onChange={(e: any) => {
                      props.onChange("l2", e.target.value)
                      props.onChange("searchLocation", { ...props.searchLocation, l2: e.target.value })
                    }}
                  />
                {/* <Grid item xs={6}>
                  <input
                    disabled={true}
                    placeholder="Pincode"
                    className="br-4 py-8 px-1 border-white w-100"
                    value={props.pincode}
                    onChange={(e: any) =>
                      props.onChange("pincode", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <Select
                    isDisabled={true}
                    options={allCities}
                    value={city}
                    styles={selectStyle}
                    onChange={(e: any) => {
                      console.log(e);

                      props.onChange("city", e);
                    }}
                    placeholder="Select City"
                  />
                </Grid> */}
           
            </PaperCard>

            {!isDisabled &&
              <PaperCard className="mt-1">
                {!isDisabled ? (
                  <div className="flex ai-center gap-10 my-1">
                    {LocationtypeEnums?.map((locationType) => {
                      const isActive = activeLocationType === locationType;
                      return (
                        <div
                          key={locationType}
                          onClick={() => {
                            !isDisabled && setActiveLocationType(locationType);
                          }}
                          className={`flex ai-center jc-center location-header ${isActive ? "active" : ""
                            }`}
                        >
                          <div
                            className="location-text"
                            onClick={() => {
                              !isDisabled &&
                                props.onChange("searchLocation", { ...props.searchLocation, locationType })
                            }}
                          >
                            {locationType}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : null}

                {((!isDisabled && props?.searchLocation)) || props.searchLocation?.isEdit ? (
                  <div className="flex ai-center jc-end gap-10 mt-2">
                    <Button
                      type="button"
                      variant="secondary"
                      action="secondary"
                      onClick={handleLocationSave}
                    >
                      {props?.searchLocation?.isEdit ? "Update" : "Save"}
                    </Button>
                    <CancelButton
                      onClick={handleCancel}
                      type="button"
                    />
                  </div>
                ) : null}
              </PaperCard>}   

            {/* </Paper> */}
            {/* <Grid item xs={4}>
                <Select
                  isDisabled={true}
                  value={state}
                  styles={selectStyle}
                  placeholder="Select State"
                />
              </Grid> */}

              {!props.disablePayment && (
                  <PaperCard className="flex gap-1 ai-center mt-1">
                    {/* {(props.isBookingModule || !props.doorDelDocketNumber || props.isDeliveryModule) && ( */}
                    <Button onClick={() => getIntraCityPrice()} >
                      Get Price
                    </Button>
                    {/* // )} */}

                    {!mobileView && <div className="flex ai-center gap-1 flex-wrap px-1">
                      <div className="flex ai-center gap-4">
                        <HoursIcon style={{ width: "20px", height: "20px" }} /> :{" "}
                        {totalDuration}
                      </div>
                      <div className="flex ai-center gap-4">
                        {/* <i className="fa fa-truck" aria-hidden="true"></i> :{" "} */}
                        <VehicleIcon style={{ width: "20px", height: "20px" }} /> :{" "}
                        {totalDistance}
                      </div>
                      <div className="flex ai-center gap-4">
                        <PackageIcon style={{ width: "20px", height: "20px" }} />:{" "}
                        {totalPkg}
                      </div>
                      <div className="flex ai-center gap-4">
                        <WeightIcon style={{ width: "20px", height: "20px" }} />:{" "}
                        {totalKg} Kg
                      </div>
                    </div>}
                      <Select
                        value={{ label: service?.serviceType || "Select Service", value: service?.serviceType || "Select Service" }}
                        onChange={(e: any) => {
                          props.onChange("serviceType", e.value);
                          setService(e);
                          if (e.label === "EXPRESS") props.onChange("deliveryCharge", expressServicesOptions ? expressServicesOptions[0].value : "");
                          else props.onChange("deliveryCharge", servicesOptions ? servicesOptions[0].value : "");
                        }}
                        options={props?.packages?.[0]?.size === "custom" ? [{ label: "EXPRESS", value: "EXPRESS" }] : [{ label: "REGULAR", value: "REGULAR" }, { label: "EXPRESS", value: "EXPRESS" }]}
                        label="Service"
                        // placeholder="Select Service"
                        isDisabled={
                          (paymentMode?.value === "paid" && !props.isBookingModule) || props.doorDelDocketNumber
                        }
                      />
                    {service.serviceType === 'EXPRESS' && <div>
                      <Select
                        value={fleetTypeWiseRate}
                        onChange={(e: any) => {
                          if (e.serviceType === "EXPRESS") {
                            props.onChange("assetType", e.assetType);
                          }
                          props.onChange("deliveryCharge", e.value);

                          setFleetTypeWiseRate(e);
                        }}
                        options={expressServicesOptions.length > 0  ? expressServicesOptions : []}
                        label="Cost"
                        placeholder="Select Cost"
                        isDisabled={
                          (paymentMode?.value === "paid" && !props.isBookingModule) || props.doorDelDocketNumber
                        }
                      />
                    </div>}
                  </PaperCard> 
              )}

              {
                (service?.perMinuteCharge || service?.availableFreeMinutes) &&
                <PaperCard className="flex gap-1 mt-1">
                  {
                    service?.availableFreeMinutes &&
                    <InfoPill className="flex-center gap-4 fs-link" >
                      Available Free Minutes<HoursIcon /> : {service?.availableFreeMinutes}
                    </InfoPill>
                  } 
                  {
                    service?.perMinuteCharge && 
                    <InfoPill className="flex-center gap-4  fs-link">
                      Per Minute Charge<VehicleIcon /> : {service?.perMinuteCharge}
                    </InfoPill>
                  }
                </PaperCard>
              } 

              <PaperCard className="mt-1 overflow-auto">
                  {!props.disablePayment && (
                    <div>
                      <div>
                        {/* {console.log("checking",props.isDeliveryModule)} */}
                        <Select
                          isDisabled={props.doorDelDocketNumber}
                          label="Payment Mode"
                          placeholder="Payment Mode"
                          value={paymentMode}
                          // options={(props.isCustomerTracking) ? props.paymentOptions : paymentModes}
                          options={props.paymentOptions}
                          // isDisabled={props.isDeliveryModule}
                          onChange={(e: any) => {
                            props.onChange("doorDeliveryPaymentMode", e),
                              setPaymentMode(e)
                          }
                          }
                        />
                      </div>
                      <div className="four-column ai-end gap-4 mt-1 m-flex-col">

                  
                      <TextField
                        placeholder='Charge'
                        label='Charge'
                        list='suggestion'
                        onChange={(e: any) => {
                          console.log("deliveryCharge", e.target.value);
                          if (parseInt(e.target.value) > 0) {
                            props.onChange("deliveryCharge", e.target.value);
                          } else {
                            props.onChange("deliveryCharge", 0);
                          }
                          if (parseInt(doorDeliveryCharge) <= 0) {
                            props.onChange("doorDeliveryPaymentMode", {
                              value: "topay",
                              label: "topay",
                            });
                          }
                          props.onChange("deliveryCharge", e.target.value);
                          setDoorDeliveryCharge(e.target.value);
                        }}
                        disabled={!showDoorDelCharge}
                        value={doorDeliveryCharge}
                      />
                      <datalist id='suggestion'>
                        {customerPrice.map((e: any, index: any) => {
                          return (<option key={index} value={e.value}>{e.value}</option>)
                        })}
                      </datalist>


                      {showExtra &&
                        <div className="flex gap-4 fs-link ai-center">
                          <p>+</p>
                          <div className="flex ai-end gap-4">
                            <TextField 
                              label='Extra'
                              disabled={true}
                              value={extraDoorDeliveryCharge}
                            />
                            {!props.isSettled && (
                              <PencilIcon className='text-primary-400 mb-1' onClick={() => setExtraDoorDeliveryDialog(true)}/>
                            )}
                          </div>
                        </div>
                      }
                      {(!props?.isBookingModule && !props?.isCustomerTracking && props.doorDelDocketNumber) && paymentMode?.value !== `paid` &&
                        <div className="flex ai-center fs-link gap-4">
                          <p>-</p>
                          <div className="flex ai-end gap-4">
                            <TextField
                              label='Discount'
                              disabled={true}
                              value={doorDeliveryDiscount}
                            />
                            {!props.isSettled && (
                              <PencilIcon className='text-primary-400 mb-1' onClick={() => setDiscountDialog(true)} />
                            )}
                          </div>
                        </div>
                      }
                      {props.isSettled && (
                        <div className="flex ai-center gap-4">
                          <p>=</p>
                          <TextField
                            label='Total'
                            disabled={true}
                            value={(doorDeliveryCharge) + extraDoorDeliveryCharge}
                          />
                        </div>
                      )}

                      {mobileView && <div className="flex-center">
                        <InfoIcon className="text-primary-400 fs-link " onClick={() => setOpenPkgDetail(true)} />
                      </div>}
                      </div>
                    </div>
                  )}
              </PaperCard>

          </div>
        )}
        footer={

            <div className="flex ai-center jc-end gap-8">
  
              {(props.whereFrom === "readyForDispatch") && props.doorDelDocketNumber && (
                <CancelButton  className={`button-danger-primary fs-popup-btn`} onClick={() => setCancelOrderDialog(true)}>
                  Cancel Door Delivery
                </CancelButton>
              )}
              {props.update && (
                <UpdateButton variant='secondary' action="secondary" className={`button-primary-secondary fs-popup-btn`} onClick={() => handleUpdate()}/>
              )}
              <CloseButton className={`button-danger-secondary fs-popup-btn`} onClick={() => handleDialogClose()}/>
  
              {/* {props.isDeliveryModule && !props.isSettled && (
              <ButtonPrimary
                style={{ width: 200}}
                onClick={() => handleMakePayment(paymentMode?.value)}
              >
                {paymentMode && paymentMode?.value === "paid"
                  ? "Settle"
                  : "Make Payment"}{" "}
                {Number(doorDeliveryCharge) + Number(extraDoorDeliveryCharge) - Number(doorDeliveryDiscount)}
              </ButtonPrimary>
             )} */}
              {props.isBookingModule && paymentMode && paymentMode?.value && (
                <Button variant="secondary" action="secondary"
                  disabled={
                    props.shouldDoorDeliveryBook && paymentMode?.value === "paid"
                  }
                  style={{ width: 200 }}
                  onClick={() => {
                    handleDialogClose();
                    props.onChange("shouldDoorDeliveryBook", true);
                  }}
                >
                  {paymentMode?.value === "paid" ? "Payment Received" : "Book"} ₹:{" "}
                  {doorDeliveryCharge}
                </Button>
              )}
            </div>
        }
      />

      <Dialog
        open={cancelOrderDialog}
        onClose={() => setCancelOrderDialog(false)}
        size="small"
        header='Remarks'
        body={
          <TextField
          onChange={(e: any) => setCancelOrderRemarks(e.target.value)}
          value={cancelOrderRemarks}
          placeholder="Enter Add Reasons"
          label="Add Reasons"
        />
        }
        footer={
          <div className="flex gap-1 ai-center">
          <SubmitButton variant="secondary" action="secondary" onClick={() => { handleCancelDoorDelivery(); setCancelOrderDialog(false) }}/>
          <CloseButton onClick={() => { setCancelOrderDialog(false), setCancelOrderRemarks("") }}/>
        </div>
        }
      />


      <Dialog
        open={discountDialog}
        onClose={() => setDiscountDialog(false)}
        size="small"
        header='Discount'
        body={
          <>
           <TextField
            onChange={(e: any) => setDiscountAmount(e.target.value)}
            value={discountAmount}
            placeholder="Enter Discount Amount"
            label="Discount Amount"
          />
          <TextField
            onChange={(e: any) => setDiscountRemarks(e.target.value)}
            value={discountRemarks}
            placeholder="Enter Add Reasons"
            label="Add Reasons"
          />
            </>
        }
        footer={
          <div className="flex gap-1 ai-center">
          <SubmitButton variant="secondary" action="secondary" onClick={() => { handleDoorDeliveryDiscount(), setDiscountRemarks(""), setDiscountAmount("") }}/>
          <CloseButton onClick={() => { setDiscountDialog(false), setDiscountRemarks(""), setDiscountAmount(""), props.onReset() }}/>
        </div>
        }
      />


      <Dialog 
        state={openPkgDetail}
        onClose={() => setOpenPkgDetail(false)}
        header='Package Details'
        body={
          <div>
          <div>
            <i className="fa fa-clock" aria-hidden="true"></i> :{" "} {totalDuration}
          </div>
          <div>
            <i className="fa fa-truck" aria-hidden="true"></i> :{" "} {totalDistance}
          </div>
          <div>
            <FiPackage />:{" "} {totalPkg}
          </div>
          <div>
            <GiWeight />:{" "}{totalKg} Kg
          </div>
        </div>
        }
        footer={
          <CloseButton onClick={() => setOpenPkgDetail(false)}/>
        } 
        />

    </>;
  }
);

const mapStateToProps = (state: RootState) => ({
  cityValue: state.branch.branchForm,
  citylist: state.user.allcities,
  user: state.user,
});

const mapDispatchToProps = {
  showLoader,
  hideLoader,
  getCity,
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(DoorDelivery);
