import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import { RootState } from "../../store";
import { FaMapMarkedAlt, FaPrint, FaRegMoneyBillAlt, FaUserClock, FaRegImage, FaHistory } from 'react-icons/fa';
import { BiDownload, BiReceipt, BiTimeFive, BiRupee, BiUser, BiPhoneCall, BiMessageAlt, } from 'react-icons/bi';
import { MdDateRange, MdTimeline } from 'react-icons/md';
import formatDate from "../../services/FormatDate";
import { VscGroupByRefType, VscUngroupByRefType } from 'react-icons/vsc';
import { FiTruck } from 'react-icons/fi';
import { Accordion, AccordionDetails, AccordionSummary, TextField } from '@mui/material'
import { BsChatSquareDotsFill, BsFillCaretLeftFill, BsFillChatSquareDotsFill, BsFillInfoCircleFill, BsImages } from "react-icons/bs";
import { HiLocationMarker, HiOutlineLocationMarker } from 'react-icons/hi';
import CustomerTrackMapView from '../CustomerSection/CustomerBookingTracking/CustomerTrackMapView';
import { setTimeFormat } from './../../services/FormatTime';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '../../Components/Dialog/DialogComponent';
import ImageInput from "../../Components/ImageInput";
import { addComplaintForCustomer, trackCustomerBooking, trackCustomerBookingWithDocket } from './../../services/customersection';
import { setCustomerBookingTracking } from './../../actions/customerBooking';
import { getCustomerDocketLocation } from "../../actions/liveLocation";
import Rating from '@mui/material/Rating';
import { GetReviewRatingFromCustomer } from '../../services/customer';
import { AiOutlineDropbox, AiOutlineSend } from 'react-icons/ai';
import { ButtonDefault, ButtonPrimary } from './../../Components/Button/Buttoncontainer';
import { showMessage, success, failed } from "../../utils/message";
import { GoPackage } from 'react-icons/go';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { ResetCustomerTracking } from '../../actions/trackingModule';
import { fetchDocketDetails } from '../../services/operations';
import DoorDeliveryDialog from "../operations/Booking/DoorDeliveryDialog";
import { showHttpError } from "../../utils/message";
import { Input12, Input } from "../../Components/Input/Inputcomponents";
import { showLoader, hideLoader } from "../../actions/UserActions";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ImageInputPackgeWise from "../../Components/ImageInputPackgeWise";
import { Select12 } from "../../Components/Selectcomponents/Select";
import ShareToWhatsapp from "../sharing/WhatsAppSharing";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { sendBackToNewDestination, sendBackToOrigin } from '../../services/bookTrack';
import "./CustomerTrackView.css";
import { useNavigate } from "react-router-dom";
// Mobile Version Css
// import "./CustomerTrackMobileView.css";

const meterTOkmChange = (meter: any) => {
  return (meter / 1000).toFixed(2)
}

const secondTominConvert = (time: any, index: any) => {
  let t = new Date();
  t.setSeconds(t.getSeconds() + time + index * 30 * 60);
  return `${formatDate(t)}- ${setTimeFormat(t)}`
}

const timeConvert = (time: any) => {
  const timeString12hr = new Date(time).toLocaleTimeString("en-US", {
    hour12: true,
    hour: "numeric",
    minute: "numeric",
  });
  return timeString12hr;
};

const CustomerAuthenticatedView = (newProps: any) => {

  interface PackgePopup {
    serviceType: any,
    placeId?: any
  }
  const [isPickupInfoOpen, setIsPickupInfoOpen] = useState(0);
  const [packageDataInfo, setPackageDataInfo] = useState<PackgePopup>({ serviceType: "", placeId: "" });
  const [logs, setLogs] = useState<any>([]);
  const navigate = useNavigate()
  const [placeIdData, setPlaceIdData] = useState<any>([]);
  const [activeDest, setActiveDest] = useState<any>(null);
  const [statusForOrder, setStatusForOrder] = useState("");
  const [ratingFromUser, setRatingFromUser] = useState(0);
  const [reviewFromUser, setReviewFromUser] = useState("");
  const [latComplaint, setLastComplaint] = useState<any>();
  const [isDialogOpen, setIsDialogOpen] = useState(0);
  const [complaintRaised, setComplaintRaised] = useState(false);
  const [customerOrderNoList, setCustomerOrderNoList] = useState<any>();
  const [description, setDescription] = useState("");
  const [customerDocket, setCustomerDocket] = useState<any>();
  const [complaintType, setComplaintType] = useState("");
  const [image, setImage] = useState("");
  const [customerComplaintRefOrderNo, setCustomerComplaintRefOrderNo] = useState<any>();
  const [docketDate, setDocketDate] = useState<any>();
  const [docketTime, setDocketTime] = useState<any>();

  const getCompleteRoute = async () => {
    const res = await trackCustomerBooking({ shipmentNumber: newProps.data.customerTrackingData?.orderData?.shipmentNumber });
    setPlaceIdData(res?.bookingTracker[0])
  }

  const secondsToHms = (d: any) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    // var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " Hr, " : " Hr, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " Min " : " Min ") : "";
    // var sDisplay = s > 0 ? s + (s == 1 ? " Second" : " Seconds") : "";
    return hDisplay + mDisplay;
  }

  const ans = newProps.data.customerTrackingData?.orderData?.route &&
    newProps.data.customerTrackingData?.orderData?.route.sort((a: any, b: any) => {
      return a?.placeIndex - b?.placeIndex
    }) || []

  const handleDetailedLogs = () => {
    {
      newProps.data.customerTrackingData.source === "Customer-Authenticated-BranchOrder" &&
        // historyData.push({
        //     pathname:"/CustomerDashBoard/detailedLogs",
        //     state: {
        //       from: "Customer-Order-Track", 
        //       logsInfo : logs
        //     }
        //   })
        navigate("/CustomerDashBoard/detailedLogs", {
          state: {
            from: "Customer-Order-Track",
            logsInfo: logs
          }
        })
    }
    {
      newProps.data.customerTrackingData.source === "Customer-Authenticated-CustomerOrder" &&
        // historyData.push({
        //     pathname:"/CustomerDashBoard/detailedLogs",
        //     state: {
        //       from: "Customer-Order-Track", 
        //       logsInfo : logs
        //     }
        //   })
        navigate("/CustomerDashBoard/detailedLogs", {
          state: {
            from: "Customer-Order-Track",
            logsInfo: logs
          }
        })
    }
    {
      newProps.data.customerTrackingData.source === "Branch-Authenticated-BranchOrder" &&
        // historyData.push({
        //     pathname:"/dashboard/detailedLogs",
        //     state: {
        //       from: "Branch-Order-Track", 
        //       logsInfo : logs
        //     }
        //   })
        navigate("/dashboard/detailedLogs", {
          state: {
            from: "Branch-Order-Track",
            logsInfo: logs
          }
        })
    }
    {
      newProps.data.customerTrackingData.source === "Branch-Authenticated-CustomerOrder" &&
        // historyData.push({
        //     pathname:"/dashboard/detailedLogs",
        //     state: {
        //       from: "Branch-Order-Track", 
        //       logsInfo : logs
        //     }
        //   })
        navigate("/dashboard/detailedLogss", {
          state: {
            from: "Branch-Order-Track",
            logsInfo: logs
          }
        })
    }
  }

  const handleRatings = async (value: any) => {
    try {
      // let payload = {
      //   feedbackType: "REV",
      //   feedbackTitle: reviewFromUser,
      //   shipmentNumber: value,
      //   rating: ratingFromUser
      // }
      let payload = {
        // feedbackType: "REV",
        review: reviewFromUser,
        shipmentNumber: value,
        rating: ratingFromUser
      }
      let giveRating = await GetReviewRatingFromCustomer(payload);
      console.log("30.0", giveRating);
    } catch (error: any) {
      if (error.message === "You cannot set review for this booking!") {
        alert(" 12223 You are not eleigible to give review Or rating. Thank You !")
        setReviewFromUser("")
        setRatingFromUser(0)
      } else {
        alert("12722  You are not eleigible to give review Or rating. Thank You !")
        setReviewFromUser("")
        setRatingFromUser(0)
      }
    }
  }

  const checkingPastComplaints = async () => {
    let TempResponse: any = await trackCustomerBookingWithDocket({ "shipmentNumber": newProps.data.customerTrackingData?.orderData?.shipmentNumber });
    setDocketTime(timeConvert(TempResponse[0].createdAt))
    setDocketDate(formatDate(TempResponse[0].createdAt))
    if (TempResponse[0].feedbacks.complaints.length > 0) {
      let Temp2: any = TempResponse[0].feedbacks.complaints
      setLastComplaint(Temp2[Temp2.length - 1]);
    }
  }

  const handleIndividualChat = () => {
    {
      newProps.data.customerTrackingData.source === "Branch-Authenticated-CustomerOrder" ?
        (alert("This Chat Module Only Valid For Customer Kindly Login as a Customer !"))
        :
        (
          navigate("customerChat")
        )
    }
  }

  const raiseComplaint = async () => {
    try {
      let payloadForcomplaint: any = {
        feedbackType: "COMP",
        complaintType: complaintType,
        shipmentNumber: customerDocket.toString(),
        messageText: description,
        customerPackageOrder: customerComplaintRefOrderNo
      }
      let TempResponse = await addComplaintForCustomer(payloadForcomplaint);
      if (TempResponse.feedbackNumber !== "") {
        setComplaintRaised(true);
        alert("Your Complaint has been created, To see all Complaints Click on the Message Icon")
      }
    } catch (error) {
      let newError: any = error;
      alert(newError.message);
    }
  }

  useEffect(() => {
    setLogs(newProps.data.customerTrackingData?.orderData?.logs);
    getCompleteRoute();
    showMessage("Order Tracked Succesfully", success, 2000);
  }, [])

  useEffect(() => {
    if (isDialogOpen === 0) {
      setComplaintType("")
    }
    if (isDialogOpen === 1) {
      setComplaintType("PM")
    }
    if (isDialogOpen === 2) {
      setComplaintType("PF")
    }
    if (isDialogOpen === 3) {
      setComplaintType("DMG")
    }
    if (isDialogOpen === 4) {
      setComplaintType("MB")
    }
    if (isDialogOpen === 5) {
      setComplaintType("LD")
    }
    if (isDialogOpen === 6) {
      setComplaintType("OT")
    }
  }, [isDialogOpen])

  useEffect(() => {
    checkingPastComplaints()
    if (newProps.data.customerTrackingData?.orderData?.orderStatus === null) {
      setStatusForOrder("Upcoming")
    }
    if (newProps.data.customerTrackingData?.orderData?.orderStatus === "B" || newProps.data.customerTrackingData?.orderData?.orderStatus === "I") {
      setStatusForOrder("OnGoing")
    }
    if (newProps.data.customerTrackingData?.orderData?.orderStatus === "D") {
      setStatusForOrder("Delivered")
    }

    setCustomerDocket(newProps.data.customerTrackingData?.orderData?.shipmentNumber);

    if (newProps.data.customerTrackingData?.orderData?.startOrder) {
      newProps.data.getCustomerDocketLocation(newProps.data.customerTrackingData?.orderData?.driver?._id)
    }
    let TempCustomerOrderNoList: any = [];
    {
      newProps.data.customerTrackingData?.orderData?.packages?.map((elem: any) => {
        TempCustomerOrderNoList.push({ key: elem?.customerPkgIdentifier, value: elem?.customerPackageOrder })
      })
    }
    setCustomerOrderNoList(TempCustomerOrderNoList);
  }, [])

  useEffect(() => {
    if (placeIdData.route && placeIdData.route.length > 0) {
      let estimation = placeIdData?.route.sort((a: any, b: any) => {
        return a?.placeIndex - b?.placeIndex
      }) || []

      if (placeIdData?.startOrder) {
        setActiveDest(estimation.find((x: any) => !x.checkIn && !x.checkOut)?.placeIndex)
      }
      newProps?.data?.setCustomerBookingTracking({
        route: estimation,
        lastStation: activeDest
      })

    }
  }, [placeIdData?.route])

  return (
    <div className={newProps.data.customerTrackingData.source === "Branch-Authenticated-CustomerOrder" ? 'trackingForBranchPersonContainer' : `trackingForCustomerContainer`}>

      <div className="headingTracking">
        {newProps.data.customerTrackingData.source === "Customer-NotAuthenticated-CustomerOrder" &&
          <button className="returnTohomePage" onClick={() => { navigate("/") }}><BsFillCaretLeftFill />Home Page</button>
        }
        <div className={newProps.data.customerTrackingData.source === "Customer-NotAuthenticated-CustomerOrder" ? "actualTitleCenter" : "actualTitle"}>Live Tracking</div>
        {newProps.data.customerTrackingData.source === "Customer-Authenticated-CustomerOrder"
          || newProps.data.customerTrackingData.source === "Branch-Authenticated-CustomerOrder" ?
          <div className="optionButtonsDiv">
            <button><FaPrint style={{ fontSize: "x-large" }} /></button>
            <button><BiDownload style={{ fontSize: "x-large" }} /></button>
            <ShareToWhatsapp docketId={newProps.data.customerTrackingData?.orderData?._id} />
          </div>
          : <></>
        }
      </div>

      <div className="generalContainer">
        <div className='generalInfoForCustomerTracking'>
          <div className='fromAndToDetails'>
            <div className='containerCustomerForMob'>
              <div className='commonForBranchMobTrack'>
                <span style={{ fontSize: "x-large", padding: '1%' }}><BiTimeFive style={{ backgroundColor: "white", color: "#2728ff" }} />{" "}</span>
                <p>
                  {docketDate ? docketDate : "docketDate"}
                </p>
              </div>
              <div className='commonForBranchMobTrack'>
                <span style={{ fontSize: "x-large", padding: '1%' }}><BiReceipt style={{ backgroundColor: "white", color: "#2728ff" }} /></span>
                <p>
                  {" "}{newProps.data.customerTrackingData?.orderData?.shipmentNumber}
                </p>
              </div>
              <div className='commonForBranchMobTrack'>
                <span style={{ fontSize: "x-large", padding: '1%' }}><MdTimeline style={{ backgroundColor: "white", color: "#2728ff" }} />{" "}</span>
                <p>
                  {" "}{statusForOrder}
                </p>
              </div>
              {newProps.data.customerTrackingData?.orderData?.route?.length > 0 &&
                <div className='commonForBranchMobTrack'>
                  <span style={{ fontSize: "x-large", padding: '1%' }}><FiTruck style={{ backgroundColor: "white", color: "#2728ff" }} />{" "}</span>
                  <p>
                    {" "} {ans[ans?.length - 1]?.distance / 1000} KM
                  </p>
                </div>
              }
              <div className='commonForBranchMobTrack'>
                <span style={{ fontSize: "x-large", padding: '1%' }}><FaRegMoneyBillAlt style={{ backgroundColor: "white", color: "#2728ff" }} />{" "}</span>
                <p>
                  {" "} {newProps.data.customerTrackingData?.orderData?.paymentMode ? newProps.data.customerTrackingData?.orderData?.paymentMode : "Payment Mode"}
                </p>
              </div>
            </div>
            <div className='containerCustomerForMob'>
              <div className='commonForBranchMobTrack'>
                <span style={{ fontSize: "x-large", padding: '1%' }}><MdDateRange style={{ backgroundColor: "white", color: "#2728ff" }} />{" "}</span>
                <p>
                  {docketTime ? docketTime : "docketTime"}
                </p>
              </div>
              <div className='commonForBranchMobTrack'>
                <span style={{ fontSize: "x-large", padding: '1%' }}><VscGroupByRefType style={{ backgroundColor: "white", color: "#2728ff" }} />{" "}</span>
                <p>
                  {" "} {newProps.data.customerTrackingData?.orderData?.type}
                </p>
              </div>
              <div className='commonForBranchMobTrack'>
                <span style={{ fontSize: "x-large", padding: '1%', color: "#2728ff" }}><FaMapMarkedAlt style={{ backgroundColor: "white", color: "#2728ff" }} />{" "}</span>
                <p>
                  {" "}{newProps.data.customerTrackingData?.orderData?.route?.length}
                </p>
              </div>
              <div className='commonForBranchMobTrack'>
                <span style={{ fontSize: "x-large", padding: '1%' }}><FaUserClock style={{ backgroundColor: "white", color: "#2728ff" }} />{" "}</span>
                <p>
                  {" "}{secondsToHms(ans[ans?.length - 1]?.duration)}
                </p>
              </div>
              <div className='commonForBranchMobTrack'>
                <span style={{ fontSize: "x-large", padding: '1%' }}><BiRupee style={{ backgroundColor: "white", color: "#2728ff" }} />{" "}</span>
                <p>
                  {" "} {newProps.data.customerTrackingData?.orderData?.gross}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {newProps.data.customerTrackingData?.orderData?.orderStatus !== "D" ?
        <div className={newProps.data.customerTrackingData?.orderData?.orderStatus !== "D" ? "mapAndLogsContainerWithoutRR" : "mapAndLogsContainer"}>
          <div className='mapContainerForTracking'>
            <div style={{ flex: "1", border: "0.2px solid transparent" }}>
              <div style={{ overflowY: "scroll", height: "220px", padding: "7px" }}>
                {newProps?.data?.customerTracking?.route.map((e: any, i: any) => {
                  return (
                    <Accordion style={{ ...(activeDest === e.placeIndex ? { border: '1px solid blue' } : activeDest < e.placeIndex ? {} : { border: '2px solid blue' }) }} expanded={false}  >
                      <AccordionSummary>
                        <div style={{ width: "100%" }}>
                          <div style={{ display: "flex", alignItems: 'center', gap: "5px", fontWeight: "bold" }}>
                            <div style={{ width: "7.5%", border: "1px solid black", color: "#2720ff", textAlign: "center", borderRadius: "50%", fontWeight: "bold" }}>{i + 1}</div>
                            <div style={{ width: "85%", textAlign: "left", fontWeight: "bold" }}>{e?.placeId[0]?.name}</div>
                            <div style={{ width: "7.5%", height: "20px", textAlign: "center", fontWeight: "bold" }} onClick={() => {
                              setIsPickupInfoOpen(i + 1),
                                setPackageDataInfo({ serviceType: e.placeType, placeId: e.placeId[0].placeId })
                            }}>
                              <BsFillInfoCircleFill style={{ fontSize: "large", color: "rgb(92,14,225)", cursor: "pointer" }} />
                            </div>
                          </div>
                          <div style={{ marginLeft: "20px", marginTop: "5px" }}>
                            <div><span><i className="fa fa-truck" style={{ marginRight: 5, color: 'blue' }} ></i></span>{"  "}{meterTOkmChange(e?.distance)} km away from {i == 0 ? "Driver" : "Previous Location"}
                              <div><span><i className="fa fa-clock" style={{ marginRight: 5, color: 'blue' }} ></i></span> {" "}
                                {secondTominConvert(e?.duration, i)}
                              </div>
                            </div>
                          </div>
                          {/* <div><div style={{margin:"5px 0 0 25px"}}><div>Km Total Trip</div></div></div> */}
                          {/* {orderDetail?.packages.length == i+1 ?<div style={{margin:"5px 0 0 25px"}}><div>{orderDetail?.totalDistance}Km  Total Trip and {toHoursAndMinutes(orderDetail?.totalDuration)}  Time</div></div>
                        :<div></div>} */}

                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}><div><HiLocationMarker style={{ color: "green", fontSize: "19px" }} /></div>{e?.placeId[0]?.placeValue}</div>

                      </AccordionDetails>
                    </Accordion>
                  )
                })}
              </div>
            </div>
            <div className='mapDiv'>
              {newProps.data.customerTrackingData?.orderData && newProps.data.customerTrackingData?.orderData?.route &&
                <CustomerTrackMapView isStarted={newProps.data.customerTrackingData?.orderData?.startOrder} />
              }
            </div>
          </div>
          <div className='logsContainer'>
            <div className='singleLog'>
              <p className="lastActivityTitle">
                Last Activity
              </p>
              {logs[0] &&
                <div className={newProps.data.customerTrackingData.source === "Branch-Authenticated-CustomerOrder" ? "ownLogBranchContainer" : "ownLogContainer"}>
                  <div className='firstContainer'>
                    <p className="lastActivityDate">
                      <div className='commonComplaintFisrstDiv'>
                        <MdDateRange style={{ fontSize: "x-large", backgroundColor: "white", color: "#2728ff" }} />
                      </div>
                      <div className='commonComplaintSecondDiv'>
                        {formatDate(logs[0].trackingTime)}
                      </div>
                    </p>
                    {logs[0].action !== "Accepted" ?
                      <p className="lastActivityUser">
                        <div className='commonComplaintFisrstDiv'>
                          <BiUser style={{ fontSize: "x-large", backgroundColor: "white", color: "#2728ff" }} />
                        </div>
                        <div className='commonComplaintSecondDiv'>
                          {logs[0].customer?.name}
                        </div>
                      </p>
                      :
                      <p className="lastActivityUser">
                        <div className='commonComplaintFisrstDiv'>
                          <BiUser style={{ fontSize: "x-large", backgroundColor: "white", color: "#2728ff" }} />
                        </div>
                        <div className='commonComplaintSecondDiv'>
                          {logs[0].user?.name?.fName}
                        </div>
                      </p>
                    }
                    <p className="lastActivityStatus">
                      <div className='commonComplaintFisrstDiv'>
                        <MdTimeline style={{ fontSize: "x-large", backgroundColor: "white", color: "#2728ff" }} />
                      </div>
                      <div className='commonComplaintSecondDiv'>
                        {logs[0].action}
                      </div>
                    </p>

                  </div>
                  <div className='secondContainer'>
                    <p className="lastActivityTime">
                      <div className='commonComplaintFisrstDiv'>
                        <BiTimeFive style={{ fontSize: "x-large", backgroundColor: "white", color: "#2728ff" }} />
                      </div>
                      <div className='commonComplaintSecondDiv'>
                        {timeConvert(logs[0].trackingTime)}
                      </div>
                    </p>
                    {logs[0].action !== "Accepted" ?
                      <p style={{ color: "#2728ff" }} className="lastActivityCall">
                        <div className='commonComplaintFisrstDiv'>
                          <BiPhoneCall style={{ fontSize: "x-large", backgroundColor: "white", color: "#2728ff" }} />
                        </div>
                        <div className='commonComplaintSecondDiv'>
                          {logs[0].customer?.contact}
                        </div>
                      </p>
                      :
                      <p style={{ color: "#2728ff" }} className="lastActivityCall">
                        <div className='commonComplaintFisrstDiv'>
                          <BiPhoneCall style={{ fontSize: "x-large", backgroundColor: "white", color: "#2728ff" }} />
                        </div>
                        <div className='commonComplaintSecondDiv'>
                          {logs[0].user?.contact}
                        </div>
                      </p>
                    }
                    <p className="lastActivityImage">
                      <FaRegImage style={{ fontSize: "x-large", backgroundColor: "white", color: "grey" }} onClick={() => alert("Testing Ig Going On !")} />
                    </p>
                  </div>
                </div>
              }
              <p className='lastActivityBTN'>
                <FaHistory onClick={() => handleDetailedLogs()} />
              </p>
              {/* <CustomerTrackLogs data={[logs[0]]}/> */}
            </div>
          </div>
        </div>
        :
        <div className="mapAndLogsContainer">
          <h1 style={{ color: "#2728ff" }}>Your Order Is Delivered Succesfully</h1>
        </div>
      }

      {newProps.data.customerTrackingData.source === "Customer-Authenticated-CustomerOrder"
        || newProps.data.customerTrackingData.source === "Branch-Authenticated-CustomerOrder" ?
        <>
          {statusForOrder === "Upcoming" ? <></> :
            <>
              {newProps.data.customerTrackingData?.orderData?.orderStatus === "D" ?
                <div className="rAndrParentContainer">
                  <div className="rAndrContainer">
                    <div className="reviewRatingDiv">
                      <div className="applyRating">
                        <Rating
                          max={3}
                          style={{ fontSize: "xx-large", marginTop: "2.5%" }}
                          name="hover-feedback1"
                          value={ratingFromUser}
                          precision={1}
                          onChange={(e: any) => {
                            setRatingFromUser(e.target.value);
                          }}
                        // onChangeActive={(event, newHover) => {
                        //   setHoverWork(newHover);
                        // }}
                        />
                      </div>
                      <div className="applyReview">
                        <TextField
                          style={{ width: "100%", marginTop: 7, fontSize: "large" }}
                          value={reviewFromUser}
                          disabled={false}
                          multiline={true}
                          variant="outlined"
                          placeholder="Give a Review"
                          rows={1}
                          onChange={(el: any) => { setReviewFromUser(el.target.value) }}
                        />
                      </div>
                      <div className="submitRating" onClick={() => handleRatings(newProps.data.customerTrackingData?.orderData?.shipmentNumber)}>
                        <button className="suubmitIcon">
                          <AiOutlineSend />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                :
                <div className="complaintParentContainer">
                  {latComplaint !== undefined &&
                    <div className="lastComplaintArr">
                      <p className='lastComplaint'> Last Complaint </p>
                      <div className='eachcomplaintContainer'>
                        <div className="middleDiv">
                          <div className='firstComplaintDiv'>
                            <p className='complaintNo'>
                              <div className='commonDicCSsForMob'>
                                <MdDateRange style={{ fontSize: "x-large", backgroundColor: "white", color: "#2728ff" }} />
                              </div>
                              <div className='commonDivCSsForMob'>
                                {formatDate(latComplaint?.createdAt)}
                              </div>
                            </p>
                            <p className='comaplintTime'>
                              <div className='commonDicCSsForMob'>
                                <BiTimeFive style={{ fontSize: "x-large", backgroundColor: "white", color: "#2728ff" }} />
                              </div>
                              <div className='commonDivCSsForMob'>
                                {timeConvert(latComplaint?.createdAt)}
                              </div>
                            </p>
                            <p className="complaintStatus">
                              <div className='commonDicCSsForMob'>
                                <MdTimeline style={{ fontSize: "x-large", backgroundColor: "white", color: "#2728ff" }} />
                              </div>
                              <div className='commonDivCSsForMob'>
                                Status
                              </div>
                            </p>
                          </div>
                          <div className='secondComplaintDiv'>
                            <p className='complaintTypeText'>
                              <div className='commonDicCSsForMob'>
                                <VscGroupByRefType style={{ fontSize: "x-large", backgroundColor: "white", color: "#2728ff" }} />
                              </div>
                              <div className='commonDivCSsForMob'>
                                {latComplaint.complaintType === "LD" ? `Late Delivery` : latComplaint.complaintType === "PM" ? `Parcel Missing` :
                                  latComplaint.complaintType === "OT" ? `Other` : latComplaint.complaintType === "DMG" ? `Parcel Damaged` :
                                    latComplaint.complaintType === "PF" ? `Pilferege` : latComplaint.complaintType === "MB" ? `Misbehavior by Kabra Employees` : ``}
                              </div>
                            </p>
                            <p className='textOverFlowed'>
                              <div className='commonDicCSsForMob'>
                                <BiMessageAlt style={{ fontSize: "x-large", backgroundColor: "white", color: "#2728ff" }} />
                              </div>
                              <div className='commonDivCSsForMob'>
                                {latComplaint.messages[latComplaint.messages.length - 1].text}
                              </div>
                            </p>
                            <p className="goToChat" onClick={() => { handleIndividualChat() }}>
                              <BsFillChatSquareDotsFill style={{ fontSize: "x-large" }} />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  <div className="complaintContainer">
                    <div className="btnsForComplaints">
                      <button onClick={() => setIsDialogOpen(1)}>Missing</button>
                      <button onClick={() => setIsDialogOpen(2)}>Pilferage</button>
                      <button onClick={() => setIsDialogOpen(3)}>Damage</button>
                      <button onClick={() => setIsDialogOpen(4)}>MisBehave</button>
                      <button onClick={() => setIsDialogOpen(5)}>Late Delivery</button>
                      <button onClick={() => setIsDialogOpen(6)}>Others</button>
                    </div>
                    {complaintRaised &&
                      <div className="chatIconContainer" onClick={() => { handleIndividualChat() }}>
                        <BsChatSquareDotsFill />
                      </div>
                    }
                  </div>
                  <Dialog
                    open={Boolean(isDialogOpen)}
                    maxWidth={"xl"}
                    onClose={() => {
                      setIsDialogOpen(0);
                    }}
                  >
                    <div className='tac bold'>
                      <DialogTitle
                        style={{ textAlign: "center" }}
                        id="draggable-dialog-title"
                      >
                        Raise Your Concern
                      </DialogTitle>
                    </div>
                    <DialogContent>
                      {isDialogOpen === 1 ?
                        <div className="PopupDialogComlaint">
                          <div className="refrenceImageComplaint mt5">
                            <select
                              id="inputState"
                              className="form-control form_one"
                              value={customerComplaintRefOrderNo}
                              onChange={(e) => {
                                setCustomerComplaintRefOrderNo(e.target.value);
                              }}
                            >
                              <option label="Select Order No" value="" />
                              {customerOrderNoList.map((c: any) => (
                                <option
                                  key={c.key}
                                  label={c.key}
                                  value={c.value}
                                />
                              ))}
                            </select>
                          </div>
                          <div className="descriptionInputCompaint">
                            <Input
                              multiline={true}
                              rows={3}
                              value={description}
                              placeholder="Provide detail information regarding your issue"
                              onChange={(e: any) => setDescription(e.target.value)}
                            />
                          </div>
                        </div>
                        :
                        isDialogOpen === 2 ?
                          <div className="PopupDialogComlaint">
                            <div className="refrenceImageComplaint mt5">
                              <select
                                id="inputState"
                                className="form-control form_one"
                                value={customerComplaintRefOrderNo}
                                onChange={(e) => {
                                  setCustomerComplaintRefOrderNo(e.target.value);
                                }}
                              >
                                <option label="Select Order No" value="" />
                                {customerOrderNoList.map((c: any) => (
                                  <option
                                    key={c.key}
                                    label={c.key}
                                    value={c.value}
                                  />
                                ))}
                              </select>
                            </div>
                            <div className="descriptionInputCompaint">
                              <Input
                                multiline={true}
                                rows={3}
                                value={description}
                                placeholder="Provide detail information regarding your issue"
                                onChange={(e: any) => setDescription(e.target.value)}
                              />
                            </div>
                            <div className="refrenceImageComplaint">
                              <ImageInput
                                name="Add Image"
                                style={{ marginTop: "25%", marginLeft: "25%" }}
                                Icon={(props: any) => (
                                  <i {...props} className={"fas fa-id-card file_icon"} />
                                )}
                                onChange={(e: any) => setImage(e)}
                                value={image}
                                extras={<div></div>}
                              />
                            </div>
                          </div>
                          :
                          isDialogOpen === 3 ?
                            <div className="PopupDialogComlaint">
                              <div className="descriptionInputCompaint">
                                <Input
                                  multiline={true}
                                  rows={3}
                                  value={description}
                                  placeholder="Provide detail information regarding your issue"
                                  onChange={(e: any) => setDescription(e.target.value)}
                                />
                              </div>
                              <div className="refrenceImageComplaint">
                                <ImageInput
                                  name="Add Image"
                                  style={{ marginTop: "25%", marginLeft: "25%" }}
                                  Icon={(props: any) => (
                                    <i {...props} className={"fas fa-id-card file_icon"} />
                                  )}
                                  onChange={(e: any) => setImage(e)}
                                  value={image}
                                  extras={<div></div>}
                                />
                              </div>
                            </div>
                            :
                            isDialogOpen === 4 ?
                              <div className="PopupDialogComlaint4">
                                <div className="refrenceImageComplaint4">
                                  Driver Name : {" "}
                                  {newProps.data.customerTrackingData?.orderData?.driver?.name?.fName} {" "}
                                  {newProps.data.customerTrackingData?.orderData?.driver?.name?.lName}
                                </div>
                                <div className="descriptionInputCompaint">
                                  <Input
                                    multiline={true}
                                    rows={3}
                                    value={description}
                                    placeholder="Provide detail information regarding your issue"
                                    onChange={(e: any) => setDescription(e.target.value)}
                                  />
                                </div>
                              </div>
                              :
                              isDialogOpen === 5 ?
                                <div className="PopupDialogComlaint">
                                  <div className="descriptionInputCompaint">
                                    <Input
                                      multiline={true}
                                      rows={3}
                                      value={description}
                                      placeholder="Provide detail information regarding your issue"
                                      onChange={(e: any) => setDescription(e.target.value)}
                                    />
                                  </div>
                                </div>
                                :
                                isDialogOpen === 6 ?
                                  <div className="PopupDialogComlaint">
                                    <div className="descriptionInputCompaint">
                                      <Input
                                        multiline={true}
                                        rows={3}
                                        value={description}
                                        placeholder="Provide detail information regarding your issue"
                                        onChange={(e: any) => setDescription(e.target.value)}
                                      />
                                    </div>
                                    <div className="refrenceImageComplaint">
                                      <ImageInput
                                        name="Add Image"
                                        style={{ marginTop: "25%", marginLeft: "25%" }}
                                        Icon={(props: any) => (
                                          <i {...props} className={"fas fa-id-card file_icon"} />
                                        )}
                                        onChange={(e: any) => setImage(e)}
                                        value={image}
                                        extras={<div></div>}
                                      />
                                    </div>
                                  </div>
                                  :
                                  <></>
                      }
                    </DialogContent>
                    <DialogActions>
                      <div className='onLinePkgbtnMobile'>
                        <ButtonPrimary onClick={() => {
                          setIsDialogOpen(0),
                            raiseComplaint();
                        }}>
                          Submit
                        </ButtonPrimary>
                        <ButtonDefault
                          className=""
                          onClick={() => setIsDialogOpen(0)}
                        >
                          close
                        </ButtonDefault>
                      </div>
                    </DialogActions>
                  </Dialog>
                </div>
              }
            </>
          }
        </> : <></>
      }

      <Dialog
        open={Boolean(isPickupInfoOpen)}
        maxWidth={"xl"}
        onClose={() => {
          setIsPickupInfoOpen(0);
        }}
      >
        <div className='tac bold'>
          <DialogTitle
            style={{ textAlign: "center" }}
            id="draggable-dialog-title"
          >
            View Package Details
          </DialogTitle>
        </div>
        <DialogContent>
          {newProps.data.customerTrackingData?.orderData?.packages?.map((neELe: any) => {
            if (neELe.fromPlace == packageDataInfo?.placeId) {
              return (
                <div className="PopupPackageDataContainer">
                  <div>
                    <p>
                      Service Type :
                    </p>
                    <p>
                      {packageDataInfo?.serviceType}
                    </p>
                  </div>
                  <div>
                    <p>
                      Package Number :
                    </p>
                    <p>
                      {neELe?.customerPkgIdentifier}
                    </p>
                  </div>
                  <div>
                    <p>
                      Material Name :
                    </p>
                    <p>
                      {neELe?.materialType?.name}
                    </p>
                  </div>
                  <div>
                    <p>
                      Quantiy :
                    </p>
                    <p>
                      {neELe?.qty}
                    </p>
                  </div>
                  <div>
                    <p>
                      COD Amount :
                    </p>
                    <p>
                      {neELe?.codAmount}
                    </p>
                  </div>
                  <br />
                </div>
              )
            } else if (neELe.toPlace == packageDataInfo?.placeId) {
              return (
                <div className="PopupPackageDataContainer">
                  <div>
                    <p>
                      Service Type :
                    </p>
                    <p>
                      {packageDataInfo?.serviceType}
                    </p>
                  </div>
                  <div>
                    <p>
                      Package Number :
                    </p>
                    <p>
                      {neELe?.customerPkgIdentifier}
                    </p>
                  </div>
                  <div>
                    <p>
                      Material Name :
                    </p>
                    <p>
                      {neELe?.materialType?.name}
                    </p>
                  </div>
                  <div>
                    <p>
                      Quantiy :
                    </p>
                    <p>
                      {neELe?.qty}
                    </p>
                  </div>
                  <div>
                    <p>
                      COD Amount :
                    </p>
                    <p>
                      {neELe?.codAmount}
                    </p>
                  </div>
                  <br />
                </div>
              )
            }
            <hr />
          })}
        </DialogContent>
        <DialogActions>
          <div className='onLinePkgbtnMobile'>
            <ButtonPrimary onClick={() => {
              setIsPickupInfoOpen(0)
            }}>
              Close
            </ButtonPrimary>
          </div>
        </DialogActions>
      </Dialog>

    </div>
  )
}

const BranchPersonAuthenticatedView = (newProps: any) => {

  const BranchData: any = newProps?.data?.customerTrackingData?.orderData;
  const [activeDest, setActiveDest] = useState<any>(null);
  const [uniqueLogs, setUniqueLogs] = useState<any>("");
  const [packageMap, setPackageMap] = useState<any>({});
  const [doorDeliveryDocketData, setDoorDeliveryDocketData] = useState<any>({});
  const [latComplaint, setLastComplaint] = useState<any>();
  const [isDialogOpen, setIsDialogOpen] = useState(0);
  const [ratingFromUser, setRatingFromUser] = useState(0);
  const [reviewFromUser, setReviewFromUser] = useState("");
  const [openDoorDelDialog, setOpenDoorDelDialg] = useState(false);
  const [imagePackages, setImagePackages] = useState<any>([]);
  const [newDestOpen, setNewDestOpen] = useState(false);
  const [imagedPackageData, setImagedPackageData] = useState<any>([]);
  const [NDAmount, setNDAmount] = useState<any>("");
  const [selectedND, setSelectedND] = useState<any>("");
  const [newDestOptions] = useState<any>([]);
  const [backToOriginOpen, setBackToOriginOpen] = useState(false);
  const [moreBtns, showMoreBtns] = useState(false);
  const [btoAmount, setbtoAmount] = useState<any>("");
  let isDelivered = false;
  // const historyData = useHistory();
  const navigate = useNavigate()
  let recordsMap = new Map();

  BranchData?.packages.map((d: any) => {
    if (recordsMap.has(`${d.materialType.name}${d.packingType.name}`)) {
      recordsMap.get(`${d.materialType.name}${d.packingType.name}`).push(d);
    } else {
      recordsMap.set(`${d.materialType.name}${d.packingType.name}`, [d]);
    }
  });

  const giveUser = function (user: any) {
    if (
      user &&
      typeof user === "object" &&
      user.hasOwnProperty("name") &&
      user.name.fName &&
      user.name.lName
    ) {
      return [user.name.fName, user.name.lName].join(" ");
    }
    return "Anonymous";
  };

  const processLogs = (packages: any) => {
    // console.log("packages", packages);
    let uniqueLogs: any = {},
      packageMap: any = {},
      global: any = {};
    packages.forEach((item: any) => {
      // console.log("item", item);
      let { logs: tLogs, pod, idProof, destBranch, originBranch } = item;

      let current = "";
      if (tLogs) {
        tLogs.forEach((item: any) => {
          let {
            regNumber,
            branch,
            trackingTime,
            user,
            action,
            remarkImage,
            remarkText,
            secondVerified,
            secondActionBy,
            secondRemark,
            secondaryActionAt,
            isQrScan,
          } = item;

          let entity = regNumber || (branch ? branch.branchName : "ENTITY");
          let presentBranch = branch ? branch._id : "";

          current +=
            [
              action == "unloading"
                ? originBranch == presentBranch
                  ? "booking"
                  : destBranch !== presentBranch
                    ? "loading"
                    : action
                : action,
              entity,
              giveUser(user),
              trackingTime,
              pod || "NOPOD",
              idProof || "NOID",
              remarkText || "RTEXT",
              remarkImage || "RIMAGE",
              action,
              secondVerified,
              giveUser(secondActionBy),
              secondRemark,
              secondaryActionAt,
              isQrScan === true ? "YES" : "NO",
            ].join("@") + ";";
        });
        if (current in global) {
          packageMap[current].push(item.order);
        } else {
          global[current] = 1;
          uniqueLogs[current] = current;
          packageMap[current] = [item.order];
        }
      }
    });
    return {
      uniqueLogs,
      packageMap,
      global,
    };
  };

  const getBranchOrderData = () => {

    let resPackages = BranchData?.packages.map((p: any, i: any) => {
      isDelivered = isDelivered || p.status === "D";
      return {
        ...p,
        logs: p.logs.map((l: any, li: any) => ({
          ...l,
          remarkImage: BranchData?.logImages[i][li],
        })),
      };
    });

    const { uniqueLogs, packageMap } = processLogs(resPackages);

    setPackageMap(packageMap);
    setUniqueLogs(uniqueLogs);

    let isOrderStarted = BranchData?.doorDeliveryDocket?.Route?.find((x: any) => {
      return x.checkIn
    }) ? true : false

    setDoorDeliveryDocketData({
      ...BranchData?.doorDeliveryDocket.doorDelAddress,
      docket: BranchData?.docket?.docketNumber,
      serviceType: BranchData?.doorDeliveryDocket.serviceType,
      assetType: BranchData?.doorDeliveryDocket.assetType,
      fromPlaceId: BranchData?.docket?.destBranch?.address?.placeId,
      toPlaceId: BranchData?.doorDeliveryDocket.toPlace,
      packages: BranchData?.docket?.packages,
      delCity: BranchData?.docket?.destBranch?.address?.city?._id,
      city: { value: BranchData?.doorDeliveryDocket?.doorDelAddress?.city?._id, label: BranchData?.doorDeliveryDocket?.doorDelAddress?.city?.name },
      state: { value: BranchData?.doorDeliveryDocket?.doorDelAddress?.city?.state?._id, label: BranchData?.doorDeliveryDocket?.doorDelAddress?.city?.state?.name },
      doorDeliveryPaymentMode: BranchData?.doorDeliveryDocket.paymentMode,
      deliveryCharge: BranchData?.doorDeliveryDocket.doorDelCharges,

      placeValue: BranchData?.doorDeliveryDocket?.doorDelAddress?.place?.placeValue,
      placeId: BranchData?.doorDeliveryDocket?.doorDelAddress?.place?.placeId,
      coordinate: BranchData?.doorDeliveryDocket?.doorDelAddress?.place?.coordinate,
      formatted_address: BranchData?.doorDeliveryDocket?.doorDelAddress?.place?.formatted_address,
      name: BranchData?.doorDeliveryDocket?.doorDelAddress?.place?.name,
      area: BranchData?.doorDeliveryDocket?.doorDelAddress?.place?.area,
      isOrderStarted,
      receiver: BranchData?.docket?.receiver
    })

    if (BranchData?.doorDeliveryDocket.Route && BranchData?.doorDeliveryDocket.Route.length > 0) {
      let estimation = BranchData?.doorDeliveryDocket?.Route.sort((a: any, b: any) => {
        return a?.placeIndex - b?.placeIndex
      }).map((x: any) => {
        return { ...x, placeId: [x.place] }
      }) || []
      if (isOrderStarted) {
        setActiveDest(estimation.find((x: any) => !x.checkIn && !x.checkOut)?.placeIndex)
      }
      newProps?.data?.setCustomerBookingTracking({
        route: estimation
      })
    }
  }

  const handleDetailedLogs = (uniquelogsArr: any) => {
    console.log("logsArr", uniquelogsArr)
    {
      newProps.data.customerTrackingData.source === "Customer-Authenticated-BranchOrder" &&
        // historyData.push({
        //     pathname:"/CustomerDashBoard/detailedLogs",
        //     state: {
        //       from: "Customer-Order-Track", 
        //       logsInfo : uniquelogsArr
        //     }
        //   })
        navigate("/CustomerDashBoard/detailedLogs", {
          state: {
            from: "Customer-Order-Track",
            logsInfo: uniquelogsArr
          }
        })
    }
    {
      newProps.data.customerTrackingData.source === "Customer-Authenticated-CustomerOrder" &&
        // historyData.push({
        //     pathname:"/CustomerDashBoard/detailedLogs",
        //     state: {
        //       from: "Customer-Order-Track", 
        //       logsInfo : uniquelogsArr
        //     }
        //   })
        navigate("/CustomerDashBoard/detailedLogs", {
          state: {
            from: "Customer-Order-Track",
            logsInfo: uniquelogsArr
          }
        })
    }
    {
      newProps.data.customerTrackingData.source === "Branch-Authenticated-BranchOrder" &&
        // historyData.push({
        //     pathname:"/dashboard/detailedLogs",
        //     state: {
        //       from: "Branch-Order-Track", 
        //       logsInfo : uniquelogsArr
        //     }
        //   })
        navigate("/dashboard/detailedLogs", {
          state: {
            from: "Branch-Order-Track",
            logsInfo: uniquelogsArr
          }
        })
    }
    {
      newProps.data.customerTrackingData.source === "Branch-Authenticated-CustomerOrder" &&
        // historyData.push({
        //     pathname:"/dashboard/detailedLogs",
        //     state: {
        //       from: "Branch-Order-Track", 
        //       logsInfo : uniquelogsArr
        //     }
        //   })
        navigate("/dashboard/detailedLogs", {
          state: {
            from: "Branch-Order-Track",
            logsInfo: uniquelogsArr
          }
        })
    }
  }

  const SingleStatus = (props: any) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    let lastStatus = props.statusArr[props.statusArr.length - 1];
    const statusSplit = lastStatus.split("@");
    let ParcelReachedLastBranch: any = statusSplit[1];
    const steps = getSteps();
    function getSteps() {
      return [1, 2, 3, 4, 5];
    }

    let result, procStatus;

    switch (statusSplit[0]) {
      case "loading": {
        result = "In transit";
        procStatus = 2;
        break;
      }
      case "payment": {
        result = "Waiting for Delivery";
        // procStatus = "Waiting for Delivery";
        break;
      }
      case "unloading": {
        result = "Waiting for Delivery";
        // procStatus = "Waiting for Delivery";
        break;
      }
      case "booking": {
        result = "Booked";
        procStatus = 1;
        break;
      }
      case "delivery": {
        result = "Delivered";
        // procStatus = "Delivered";
        break;
      }
      case "cancel": {
        result = "Canceled";
        // procStatus = "Canceled";
        break;
      }
      case "cancelPending": {
        result = "Waiting for cancellation";
        // procStatus = "Waiting for cancellation";
        break;
      }
      case "review": {
        result = "Review";
        // procStatus = "Review";
        break;
      }
      case "missing": {
        result = "Missing";
        // procStatus = "Missing";
        break;
      }
      case "missingRequest": {
        result = "Missing Request";
        // procStatus = "Missing Request";
        break;
      }
      default: {
        result = "In transit";
        procStatus = 2;
      }
    }

    return (
      <div className="eachStatusContainer">
        <div className="singleStatusForBranchTracking">
          {/* <div style={{display:"flex",border:"0.2px solid red",width:"50%",margin:"auto"}}>
          </div> */}
          <p className="noOfPackagesForBookingTrack" onClick={() => { setIsDialogOpen(true) }}>
            <div style={{ marginTop: "7.5%", color: "black", fontSize: "medium" }}><GoPackage style={{ color: "rgb(92,14,225)" }} /> </div>
            <div> {props.totalPkg} </div>
          </p>
          <p className="materialTypeType">
            {Array.from(props?.recordsMap).map(([, value]: any) => {
              const orderNum = value.filter((e: any) =>
                props?.PkgList.includes(e.order)
              );
              const statusSplit = lastStatus.split("@");

              switch (statusSplit[0]) {
                case "loading": {
                  result = "In transit";
                  break;
                }
                case "payment": {
                  result = "Waiting for Delivery";
                  break;
                }
                case "unloading": {
                  result = "Waiting for Delivery";
                  break;
                }
                case "booking": {
                  result = "Booked";
                  break;
                }
                case "delivery": {
                  result = "Delivered";
                  break;
                }
                case "cancel": {
                  result = "Canceled";
                  break;
                }
                case "cancelPending": {
                  result = "Waiting for cancellation";
                  break;
                }
                case "review": {
                  result = "Review";
                  break;
                }
                case "missing": {
                  result = "Missing";
                  break;
                }
                case "missingRequest": {
                  result = "Missing Request";
                  break;
                }
                default: {
                  result = "In transit";
                }
              }
              return (
                orderNum.length > 0 && (
                  <div className="ContainerForMaterial">
                    <div>
                      <div>
                        <span style={{ marginTop: "1%", marginRight: "2%", color: "black", fontSize: "large" }}><AiOutlineDropbox style={{ color: "#2720ff" }} /> </span>
                        {value.length > 0 &&
                          value[0] &&
                          value[0].materialType &&
                          value[0].materialType.name}
                      </div>
                    </div>
                  </div>
                )
              );
            })}
          </p>
          <p className="materialTypeAndPackageType">
            {Array.from(props?.recordsMap).map(([, value]: any) => {
              const orderNum = value.filter((e: any) =>
                props?.PkgList.includes(e.order)
              );
              const statusSplit = lastStatus.split("@");

              switch (statusSplit[0]) {
                case "loading": {
                  result = "In transit";
                  break;
                }
                case "payment": {
                  result = "Waiting for Delivery";
                  break;
                }
                case "unloading": {
                  result = "Waiting for Delivery";
                  break;
                }
                case "booking": {
                  result = "Booked";
                  break;
                }
                case "delivery": {
                  result = "Delivered";
                  break;
                }
                case "cancel": {
                  result = "Canceled";
                  break;
                }
                case "cancelPending": {
                  result = "Waiting for cancellation";
                  break;
                }
                case "review": {
                  result = "Review";
                  break;
                }
                case "missing": {
                  result = "Missing";
                  break;
                }
                case "missingRequest": {
                  result = "Missing Request";
                  break;
                }
                default: {
                  result = "In transit";
                }
              }
              return (
                orderNum.length > 0 && (
                  <div className="ContainerForMaterial">
                    <div style={{ marginLeft: "2.5%" }}>
                      <div>
                        <span style={{ marginTop: "1%", marginRight: "2%", color: "black", fontSize: "large" }}><GoPackage style={{ color: "#2720ff" }} /> </span>
                        {value.length > 0 &&
                          value[0] &&
                          value[0].packingType &&
                          value[0].packingType.name}
                      </div>
                    </div>
                  </div>
                )
              );
            })}
          </p>
          <p className="StatusConatinerForBookingTracking">
            <span style={{ fontSize: "x-large", padding: '1%' }}><MdTimeline style={{ backgroundColor: "white", color: "#2728ff" }} />{" "}</span>
            <div>
              {ParcelReachedLastBranch}
            </div>
          </p>
          <p className="progressBarDiv">
            <Stepper activeStep={procStatus} className={"singleProgressBar"}>
              {steps.map((label) => (
                <Step key={label} className={"singleStepz"} >
                  <StepLabel></StepLabel>
                </Step>
              ))}
              <p className="lastResultName">{result}</p>
            </Stepper>
          </p>
          <p className='lastActivityBTNForBranch'>
            {newProps.data.customerTrackingData.source === "Customer-NotAuthenticated-BranchOrder" ? <></> :
              <FaHistory onClick={() => handleDetailedLogs(props.EachlogsArr)} />
            }
          </p>
          <Dialog open={isDialogOpen} onClose={() => { setIsDialogOpen(false) }}>
            <DialogTitle style={{ cursor: "move", textAlign: "center" }} id="draggable-dialog-title">
              List of Packge Numbers.
            </DialogTitle>
            <DialogContent>
              <div className="pkgDialogueContent">
                <div className="listOfPackages">
                  {props.PkgList.map((c: any) => {
                    return (<div>{c}</div>)
                  })}
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <ButtonDefault onClick={() => setIsDialogOpen(false)}>
                Cancel
              </ButtonDefault>
            </DialogActions>
          </Dialog>
          <>
            {/*
            <p>
              <select
                id="inputState"
                className="form-control citySelectForCustomer"
                // value={props?.customerBooking?.intraCity?.city}
                // onChange={(e) => {
                //   props.setIntraCity('city',e.target.value);
                //   getTempoTypeList(e.target.value);
                // }}
              >
                <option label="Packages List" value="" />
                {props.PkgList.map((c: any) => (
                  <option
                    key={c}
                    label={c}
                    value={c}
                  />
                ))}
              </select>
            </p> 
            */}
          </>
        </div>
      </div>
    )
  }

  const SingleStatusForComplaint = (props: any) => {

    let lastStatus = props.statusArr[props.statusArr.length - 1];

    return (
      <div className="eachStatusContainerForComplaint">

        <div className="singleStatusForBranchTracking">

          <p className="noOfPackagesForBookingTrack" >
            <div style={{ marginTop: "7.5%", color: "black", fontSize: "medium" }}><GoPackage style={{ color: "rgb(92,14,225)" }} /> </div>
            <div> {props.totalPkg} </div>
          </p>

          <p className="materialTypeAndPackageType">
            {Array.from(props?.recordsMap).map(([, value]: any) => {
              const orderNum = value.filter((e: any) =>
                props?.PkgList.includes(e.order)
              );
              const statusSplit = lastStatus.split("@");
              let result: any;
              console.log(result)
              switch (statusSplit[0]) {
                case "loading": {
                  result = "In transit";
                  break;
                }
                case "payment": {
                  result = "Waiting for Delivery";
                  break;
                }
                case "unloading": {
                  result = "Waiting for Delivery";
                  break;
                }
                case "booking": {
                  result = "Booked";
                  break;
                }
                case "delivery": {
                  result = "Delivered";
                  break;
                }
                case "cancel": {
                  result = "Canceled";
                  break;
                }
                case "cancelPending": {
                  result = "Waiting for cancellation";
                  break;
                }
                case "review": {
                  result = "Review";
                  break;
                }
                case "missing": {
                  result = "Missing";
                  break;
                }
                case "missingRequest": {
                  result = "Missing Request";
                  break;
                }
                default: {
                  result = "In transit";
                }
              }
              return (
                orderNum.length > 0 && (
                  <div className="ContainerForMaterial">
                    <div>
                      <div>
                        <span style={{ marginTop: "1%", marginRight: "2%", color: "black", fontSize: "large" }}><AiOutlineDropbox style={{ color: "#2720ff" }} /> </span>
                        {value.length > 0 &&
                          value[0] &&
                          value[0].materialType &&
                          value[0].materialType.name}
                      </div>
                    </div>
                    <div style={{ marginLeft: "2.5%" }}>
                      <div>
                        <span style={{ marginTop: "1%", marginRight: "2%", color: "black", fontSize: "large" }}><GoPackage style={{ color: "#2720ff" }} /> </span>
                        {value.length > 0 &&
                          value[0] &&
                          value[0].packingType &&
                          value[0].packingType.name}
                      </div>
                    </div>
                  </div>
                )
              );
            })}
          </p>

        </div>

        <div className="pkgDialogueContentForComplaint">
          <div className="listOfPackages">
            {props.PkgList.map((c: any) => {
              return (<button className="complaintBtn">{c}</button>)
            })}
          </div>
        </div>

      </div>
    )
  }

  const checkingPastComplaints = async () => {
    let docketNo = BranchData?.docket.docketNumber
    let TempResponse: any = await trackCustomerBookingWithDocket({ "shipmentNumber": docketNo });
    if (TempResponse[0].feedbacks.complaints.length > 0) {
      let Temp2: any = TempResponse[0].feedbacks.complaints
      console.log("900000", Temp2)
      setLastComplaint(Temp2[Temp2.length - 1]);
    }
  }

  const trackFromTrackingID = async (docketNo: any) => {
    console.log("2020 30", docketNo)
    const payload = {
      docket: docketNo,
      sub: "B",
      entity: "5e8ec1a63657974a54b5d56a",
      queryType: "tracking",
      old: false,
    };
    const res = await fetchDocketDetails(payload);
    console.log("2020 40", res)
  }

  const printBuilty = async () => {
    try {
      const elem: any = document.getElementById("track_builty_download");

      if (typeof elem === null || elem === "undefined") {
      } else {
        elem.style.opacity = 1;
        let style = `
        @page { size: auto;  margin: 0mm; }
                    table, th, td {
                        border: 1px solid black;
                        border-collapse: collapse;
                    }
                    th, td {
                        text-align: justify;
                        padding: 5px;
                    }`;
        let divContents = elem.innerHTML;
        var a: any = window.open("", "", "");
        a.document.write("<head><style>" + style + "</style>");
        a.document.write('<body onload="window.print()">');
        a.document.write(divContents);
        a.document.write("</body>");
        a.document.write("</head>");
        a.document.close();
        elem.style.opacity = 0;
      }
    } catch (err: any) {
      const elem: any = document.getElementById("track_builty_download");

      if (typeof elem === null || elem === "undefined") {
      } else {
        elem.style.opacity = 0;
        console.log("eror:--", err);
      }
    }
  };

  const downloadBuilty = async () => {
    try {
      const elem: any = document.getElementById("track_builty_download");

      if (typeof elem === null || elem === "undefined") {
      } else {
        elem.style.opacity = 1;

        let mm: any = $("#track_builty_download");

        if (typeof mm === null || mm === "undefined") {
        } else {
          let HTML_Width = mm.width();
          let HTML_Height = mm.height();

          html2canvas($("#track_builty_download")[0]).then(function (canvas) {
            let imgData = canvas.toDataURL("image/jpeg", 1);
            console.log(imgData);
            let pdf = new jsPDF("p", "pt", [
              HTML_Width + 400,
              HTML_Height + 100,
            ]);
            pdf.addImage(imgData, "JPG", 0, 0, HTML_Width, HTML_Height);

            pdf.save("Invoice.pdf");
            elem.style.opacity = 0;
          });
        }
      }
    } catch (err: any) {
      console.log("got error while printing:---", err);
      const elem: any = document.getElementById("track_builty_download");

      if (typeof elem === null || elem === "undefined") {
      } else {
        elem.style.opacity = 0;
      }
    }
  };

  const handleViewDocketBtn = () => {
    // historyData.push({
    //   pathname: "/dashboard/operations/fetchDocket",
    //     state: {
    //       from: "tracking",
    //       data: BranchData?.docket.docketNumber,
    //     }
    // })
    navigate("/dashboard/operations/fetchDockets", {
      state: {
        from: "tracking",
        data: BranchData?.docket.docketNumber,
      }
    })
  }

  const handleImagePackages = () => {
    setImagePackages(
      BranchData?.packages.map((r: any) => ({
        order: r.order,
        _id: r._id,
      }))
    );
  };

  const handleSubmitND = async () => {
    if (imagedPackageData?.length <= 0) {
      return showHttpError(`All package Images are required !`)
    }
    newProps.data.showLoader();
    try {
      const payload = {
        docket: BranchData?.docket._id,
        branch: newProps.data.entity,
        NDCharge: NDAmount,
        newDestination: selectedND && selectedND.value,
        packages: imagedPackageData,
      };
      if (payload.NDCharge % 10 !== 0) {
        showMessage(
          "New Destination charge should be a multiple of 10",
          failed
        );
        newProps.data.hideLoader();
        return null;
      }
      await sendBackToNewDestination(payload);
      newProps.data.hideLoader();
      showMessage("Successfull !!", success);
      handleNDClose();
    } catch (err: any) {
      newProps.data.hideLoader();
      showHttpError(err);
    }
  };

  const addImagedPackage = (ele: any) => {
    const data = [...imagedPackageData];
    data.map((r: any, i: number) => {
      if (r.order === ele.order) {
        data.splice(i, 1);
      }
    });
    data.push(ele);
    setImagedPackageData(data);
  };

  const handleNDClose = () => {
    setNewDestOpen(false);
    setNDAmount("");
    setSelectedND("");
    setImagedPackageData([]);
    setImagePackages([]);
  };

  const handleSubmitBTO = async () => {
    if (imagedPackageData?.length <= 0) {
      return showHttpError(`All package Images are required !`)
    }
    newProps.data.showLoader();
    try {
      const payload = {
        docket: BranchData?.docket._id,
        branch: BranchData?.docket.entity,
        BTOCharge: btoAmount,
        packages: imagedPackageData,
      };
      await sendBackToOrigin(payload);
      newProps.data.hideLoader();
      showMessage("Successfull !!", success);
      handleBTOClose();
    } catch (err: any) {
      newProps.data.hideLoader();
      showHttpError(err);
    }
  };

  const handleBTOClose = () => {
    setBackToOriginOpen(false);
    setbtoAmount("");
    setImagedPackageData([]);
    setImagePackages([]);
  };

  useEffect(() => {
    getBranchOrderData();
    checkingPastComplaints();
    showMessage("Order Tracked Succesfully", success, 2000);
    { newProps.data.customerTrackingData.source === "Customer-NotAuthenticated-BranchOrder" && trackFromTrackingID(BranchData?.docket.docketNumber) }
  }, [])

  return (
    <div id="BranchPersonAuthenticatedViewContainer">

      <div className="headingBranchTrackingMob">
        <div className="actualTitle">Order Tracking</div>
        <div className="optionButtonsDiv">
          <button><BsImages style={{ fontSize: "x-large" }} /></button>
          <button onClick={printBuilty} ><FaPrint style={{ fontSize: "x-large" }} /></button>
          <button onClick={downloadBuilty}><BiDownload style={{ fontSize: "x-large" }} /></button>
          <ShareToWhatsapp docketId={BranchData?.docket?._id} />
          {newProps.data.customerTrackingData.source === "Branch-Authenticated-BranchOrder" &&
            <button onClick={() => { showMoreBtns(!moreBtns) }}><MoreVertIcon style={{ fontSize: "x-large" }} /></button>
          }
        </div>
      </div>

      {newProps.data.customerTrackingData.source === "Branch-Authenticated-BranchOrder" &&
        <>
          {moreBtns &&
            <div className='branchExecutiveBtnsContainer'>
              <div className='branchExecutiveBtns'>
                {/* Fetch Docket Button Started*/}
                {BranchData?.docket && (
                  <ButtonPrimary onClick={() => { handleViewDocketBtn() }}> Fetch Docket </ButtonPrimary>
                )}
                {/* Fetch Docket Button Ended*/}

                {/* Send Back To Origin Button Ended*/}
                {BranchData?.docket &&
                  (BranchData?.docket.entity === BranchData?.docket.destBranch._id ||
                    BranchData?.docket.entity === BranchData?.docket.originBranch._id ||
                    BranchData?.docket.entity === BranchData?.docket.hub) &&
                  !BranchData?.docket.settled &&
                  BranchData?.docket?.packages[0].status !== "D" &&
                  BranchData?.docket?.packages[0].status !== "CR" &&
                  BranchData?.docket?.packages[0].status !== "CC" &&
                  BranchData?.docket?.packages[0].status !== "SOLD" && (
                    <ButtonPrimary
                      style={{ marginLeft: 25 }}
                      onClick={() => {
                        setBackToOriginOpen(true);
                        handleImagePackages();
                      }}
                    >
                      Send Back to Origin
                    </ButtonPrimary>
                  )
                }
                <Dialog
                  open={backToOriginOpen}
                  onClose={() => handleBTOClose()}
                  maxWidth="sm"
                  fullWidth={true}
                >
                  <DialogContent>
                    {/* <div>
                        <Paper>
                          <Row style={{ padding: "16px", fontSize: "14px" }}>
                            <input
                              type="file"
                              style={{ display: "none" }}
                              ref={(refParam) => (inputRef = refParam)}
                              onChange={(e) => console.log(e)}
                            />
                            <Col md={6}>Hello</Col>
                            <Col md={6}>
                              <ButtonLight onClick={() => inputRef.click()}>
                                <ImageIcon />
                              </ButtonLight>
                            </Col>
                          </Row>
                        </Paper>
                      </div> */}
                    <Input12
                      label="Back to origin charges"
                      value={btoAmount}
                      onChange={(e: any) => setbtoAmount(e.target.value)}
                      helperText={`Back to origin charges must be greater than ${BranchData?.docket.gross - 1
                        }`}
                    />
                    <ImageInputPackgeWise
                      packages={imagePackages}
                      showLoader={newProps.data.showLoader}
                      hideLoader={newProps.data.hideLoader}
                      reqType="BTO"
                      addImagedPackage={addImagedPackage}
                    />
                  </DialogContent>
                  <DialogActions>
                    <ButtonPrimary
                      onClick={() => handleSubmitBTO()}
                      disabled={btoAmount > BranchData?.docket.gross - 1 ? false : true}
                    >
                      Submit
                    </ButtonPrimary>
                    <ButtonDefault onClick={() => handleBTOClose()}>Cancel</ButtonDefault>
                  </DialogActions>
                </Dialog>
                {/* Send Back To Origin Button Ended*/}

                {/* Send Back To DEstination Button Ended*/}
                {BranchData?.docket &&
                  (BranchData?.docket.entity === BranchData?.docket.destBranch._id ||
                    BranchData?.docket.entity === BranchData?.docket.originBranch._id ||
                    BranchData?.docket.entity === BranchData?.docket.hub) &&
                  !BranchData?.docket.settled &&
                  BranchData?.packages[0].status !== "D" &&
                  BranchData?.packages[0].status !== "CR" &&
                  BranchData?.packages[0].status !== "CC" &&
                  BranchData?.packages[0].status !== "SOLD" && (
                    <ButtonPrimary
                      style={{ marginLeft: 25, marginRight: 25 }}
                      onClick={() => {
                        setNewDestOpen(true);
                        handleImagePackages();
                      }}
                    >
                      Send to New destination
                    </ButtonPrimary>
                  )
                }
                <Dialog
                  open={newDestOpen}
                  onClose={() => handleNDClose()}
                  maxWidth="sm"
                  fullWidth={true}
                >
                  <DialogContent>
                    <Select12
                      options={newDestOptions}
                      value={selectedND}
                      onChange={(e: any) => setSelectedND(e)}
                    />
                    <br />
                    <Input12
                      label="New Destination Charges"
                      value={NDAmount}
                      onChange={(e: any) => setNDAmount(e.target.value)}
                      placeholder={`${BranchData?.docket.gross}`}
                      style={{ zIndex: 0 }}
                    />
                    <br />
                    <ImageInputPackgeWise
                      packages={imagePackages}
                      showLoader={newProps.data.showLoader}
                      hideLoader={newProps.data.hideLoader}
                      reqType="ND"
                      addImagedPackage={addImagedPackage}
                    />
                  </DialogContent>
                  <DialogActions>
                    <ButtonPrimary onClick={() => handleSubmitND()}>Submit</ButtonPrimary>
                    <ButtonDefault onClick={() => handleNDClose()}>Cancel</ButtonDefault>
                  </DialogActions>
                </Dialog>
                {/* Send Back To Destination Button Ended*/}

              </div>
            </div>
          }
        </>
      }

      <div className="generalContainer">
        <div className='generalInfoForBranchTracking'>
          <div className='fromAndToBranchDetails'>
            <div className='containerBranchForMob'>
              <div className='commonForBranchMobTrack'>
                <span style={{ fontSize: "x-large", padding: '1%' }}><BiReceipt style={{ backgroundColor: "white", color: "#2728ff" }} /></span>
                <p>
                  {" "}{BranchData?.docket.trackingNumber}
                </p>
              </div>
              <div className='commonForBranchMobTrack'>
                <span style={{ fontSize: "x-large", padding: '1%' }}><MdDateRange style={{ backgroundColor: "white", color: "#2728ff" }} />{" "}</span>
                <p>
                  {formatDate(BranchData?.docket.createdAt)}
                </p>
              </div>
              <div className='commonForBranchMobTrack'>
                <span style={{ fontSize: "x-large", padding: '1%' }}><MdDateRange style={{ backgroundColor: "white", color: "#2728ff" }} />{" "}</span>
                <p>
                  {BranchData?.docket.deliveredAt ? formatDate(BranchData?.docket.deliveredAt) : "In Transit"}
                </p>
              </div>
              <div className='commonForBranchMobTrack'>
                <span style={{ fontSize: "x-large", padding: '1%' }}><FaRegMoneyBillAlt style={{ backgroundColor: "white", color: "#2728ff" }} />{" "}</span>
                <p>
                  {" "} {BranchData?.docket.paymentMode}
                </p>
              </div>
            </div>
            <div className='containerBranchForMob'>
              <div className='commonForBranchMobTrack'>
                <span style={{ fontSize: "x-large", padding: '1%' }}><GoPackage style={{ backgroundColor: "white", color: "#2728ff" }} />{" "}</span>
                <p>
                  {BranchData?.docket.packages.length + 1}
                </p>
              </div>
              <div className='commonForBranchMobTrack'>
                <span style={{ fontSize: "x-large", padding: '1%' }}><HiOutlineLocationMarker style={{ backgroundColor: "white", color: "#2728ff" }} />{" "}</span>
                <p>
                  {BranchData?.docket.originBranch.branchName}{" "}
                </p>
              </div>
              <div className='commonForBranchMobTrack'>
                <span style={{ fontSize: "x-large", padding: '1%' }}><HiOutlineLocationMarker style={{ backgroundColor: "white", color: "#2728ff" }} />{" "}</span>
                <p>
                  {BranchData?.docket.destBranch.branchName}{" "}
                </p>
              </div>
              <div className='commonForBranchMobTrack'>
                <span style={{ fontSize: "x-large", padding: '1%' }}><BiRupee style={{ backgroundColor: "white", color: "#2728ff" }} />{" "}</span>
                <p>
                  {" "} {BranchData?.docket.gross}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='doorDeliveryPopupContainer'>
        <div className='doorDeliveryPopup'>
          <p className='doorDeliveryText' onClick={() => { setOpenDoorDelDialg(true) }}> Door Delivery </p>
          <div className='firstDoorDContainer'>
            <p className='doorDeliveryNo'>
              <div className='commonIconForDoorD'>
                <BiReceipt style={{ fontSize: "x-large", backgroundColor: "white", color: "#2728ff" }} />
              </div>
              <div className='commonTextForDoorD'>
                -
              </div>
            </p>
            <p className='doorDeliveryTime'>
              <div className='commonIconForDoorD'>
                <BiTimeFive style={{ fontSize: "x-large", backgroundColor: "white", color: "#2728ff" }} />
              </div>
              <div className='commonTextForDoorD'>
                -
              </div>
            </p>
          </div>
          <div className='scondDoorDContainer'>
            <p className='doorDeliveryAmount'>
              <div className='commonIconForDoorD'>
                <BiRupee style={{ fontSize: "x-large", backgroundColor: "white", color: "#2728ff" }} />
              </div>
              <div className='commonTextForDoorD'>
                -
              </div>
            </p>
            <p className="doorDeliverystatus">
              <div className='commonIconForDoorD'>
                <MdTimeline style={{ fontSize: "x-large", backgroundColor: "white", color: "#2728ff" }} />
              </div>
              <div className='commonTextForDoorD'>
                -
              </div>
            </p>
          </div>

          <DoorDeliveryDialog
            delCity={doorDeliveryDocketData?.delCity}
            fromPlaceId={doorDeliveryDocketData.fromPlaceId}
            serviceType={doorDeliveryDocketData.serviceType}
            assetType={doorDeliveryDocketData.assetType}
            toPlaceId={doorDeliveryDocketData?.toPlaceId}
            packages={doorDeliveryDocketData.packages}
            open={openDoorDelDialog}
            setOpen={setOpenDoorDelDialg}
            update={!doorDeliveryDocketData?.doorDelDocketNumber}
            docket={BranchData?.docket.docketNumber}
            doorDeliveryPaymentMode={doorDeliveryDocketData.paymentMode}
            deliveryCharge={doorDeliveryDocketData.deliveryCharge}
            officeName={doorDeliveryDocketData.officeName}
            floor={doorDeliveryDocketData.floor}
            tower={doorDeliveryDocketData.tower}
            nearby_landmark={doorDeliveryDocketData.nearby_landmark}
            city={doorDeliveryDocketData.city}
            state={doorDeliveryDocketData.state}
            pincode={doorDeliveryDocketData.pincode}
            area={doorDeliveryDocketData.area}
            onChange={(what: any, val: any) => {
              console.log('doorDeliveryDocketData changes', what, val)
              setDoorDeliveryDocketData((doorDeliveryDocketData: any) => ({ ...doorDeliveryDocketData, [what]: val }))
            }
            }
            locationType={doorDeliveryDocketData.locationType}
            placeValue={doorDeliveryDocketData?.placeValue}
            placeId={doorDeliveryDocketData.placeId}
            name={doorDeliveryDocketData.name}
            coordinate={doorDeliveryDocketData.coordinate}
            formatted_address={doorDeliveryDocketData.placeValue}
            // open = {doordeldialog}
            // setOpen = {(args:boolean= false)=>setDoordeldialog(args)}
            paymentOptions={[{ label: "To Pay", value: "topay" }]}
            l1={doorDeliveryDocketData.l1}
            l2={doorDeliveryDocketData.l2}
            receiver={doorDeliveryDocketData?.receiver}
            searchLocation={doorDeliveryDocketData?.searchLocation}
            doorDelDocketNumber={doorDeliveryDocketData?.doorDelDocketNumber}
          >
            {/* {parseInt(doorDeliveryDocketData.doorDelCharges) > 0 && (
              <ButtonDefault style={{ marginLeft: 25 }} onClick={() => setOpenDoorDelDialg(true)}>
                Door Delivery Charge {doorDeliveryDocketData?.paymentMode?.value}{" "}
                {doorDeliveryDocketData.doorDelCharges > 0 && doorDeliveryDocketData.doorDelCharges}
              </ButtonDefault>
            )}
            {!(parseInt(doorDeliveryDocketData.doorDelCharges) > 0) && (
              <ButtonPrimary style={{ marginLeft: 25 }} onClick={() => setOpenDoorDelDialg(true)}>
                  Door Delivery Charge {doorDeliveryDocketData?.paymentMode?.value}{" "}
                {doorDeliveryDocketData.doorDelCharges > 0 && doorDeliveryDocketData.doorDelCharges}
              </ButtonPrimary>
            )} */}
          </DoorDeliveryDialog>
        </div>
      </div>

      {BranchData?.docket?.deliveredAt === undefined ?
        <div className="mapAndLogsContainer">
          <div className='mapContainerForTracking'>
            <div style={{ flex: "1", }}>
              <div style={{ overflowY: "scroll", height: "250px", padding: "7px" }}>
                {newProps?.data?.customerTracking?.route?.map((e: any, i: any) => {
                  return (
                    <Accordion style={{ ...(activeDest === e.placeIndex ? { border: '1px solid blue' } : activeDest < e.placeIndex ? {} : { border: '2px solid blue' }) }} expanded={false} >
                      <AccordionSummary>
                        <div style={{ width: "100%" }}>
                          <div style={{ display: "flex", alignItems: 'center', gap: "5px", fontWeight: "bold" }}>
                            <div style={{ width: "7.5%", height: "20px", border: "1px solid black", textAlign: "center", borderRadius: "50%", fontWeight: "bold" }}>{i + 1}</div>
                            <div style={{ width: "85%", height: "20px", textAlign: "left", fontWeight: "bold" }}>{e?.placeId[0]?.name}</div>
                            <div style={{ width: "7.5%", height: "20px", textAlign: "center", fontWeight: "bold" }} >
                              <BsFillInfoCircleFill style={{ fontSize: "large", color: "rgb(92,14,225)", cursor: "pointer" }} />
                            </div>
                          </div>
                          <div style={{ marginLeft: "20px", marginTop: "5px" }}>
                            <div><span><i className="fa fa-truck" style={{ marginRight: 5, color: 'blue' }} ></i></span>{"  "}{meterTOkmChange(e?.distance)} km away from {i == 0 ? "Driver" : "Previous Location"}
                              <div><span><i className="fa fa-clock" style={{ marginRight: 5, color: 'blue' }} ></i></span> {" "}
                                {secondTominConvert(e?.duration, i)}
                              </div>
                            </div>
                          </div>
                          {/* <div><div style={{margin:"5px 0 0 25px"}}><div>Km Total Trip</div></div></div> */}
                          {/* {orderDetail?.packages.length == i+1 ?<div style={{margin:"5px 0 0 25px"}}><div>{orderDetail?.totalDistance}Km  Total Trip and {toHoursAndMinutes(orderDetail?.totalDuration)}  Time</div></div>
                          :<div></div>} */}

                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}><div><HiLocationMarker style={{ color: "green", fontSize: "19px" }} /></div>{e?.placeId[0]?.placeValue}</div>
                      </AccordionDetails>
                    </Accordion>
                  )
                })}
              </div>
            </div>
            <div className='mapDiv'>
              {<CustomerTrackMapView isStarted={doorDeliveryDocketData.isOrderStarted} />}
            </div>
          </div>
        </div>
        :
        <div className="mapAndLogsContainer">
          <h1 style={{ color: "#2728ff" }}>Your Order Is Delivered Succesfully</h1>
        </div>
      }

      <div className="customisedResponse">
        {BranchData?.docket &&
          Object.keys(uniqueLogs).map((key, index) => {
            let item = uniqueLogs[key];
            let arr = item.split(";"), num = packageMap[key] ? packageMap[key].length : 0;
            arr.splice(arr.length - 1, 1);
            var NewAllowedArr: any = packageMap[key] && packageMap[key].length > 0 ? packageMap[key] : []
            return (
              <>
                {/* <Statuses
                  canceled={!docket.status}
                  cancelPending={docket && docket.cancelRequested}
                  key={index}
                  // branches={user.allbranches}
                  statusArr={arr}
                  packageMap={packageMap}
                  isResolveIssue={isResolveIssue}
                  uniqueLogs={uniqueLogs}
                  allowedOrders={
                    packageMap[key] && packageMap[key].length > 0
                      ? packageMap[key]
                      : []
                  }
                  num={num}
                  remarks={docket.remarks}
                  deliveryRemarks={deliveryRemarks}
                  recordsMap={recordsMap}
                  packageStatus={packageStatus}
                  setPackages={setPackages}
                  packages={packages}
                  mobileView={mobileView}
                /> */}
                <div className="mainContainerForBranchTrack">
                  <SingleStatus totalPkg={num} statusArr={arr} PkgList={NewAllowedArr} recordsMap={recordsMap} index={index} EachlogsArr={BranchData.packages[index].logs} />
                </div>
              </>
            );
          })}
      </div>

      {BranchData?.docket?.deliveredAt === undefined ?
        <div className="complaintParentContainer">
          {latComplaint !== undefined &&
            <div>
              {/* {console.log("202112", latComplaint)} */}
              <div className='eachcomplaintContainer'>
                <div className="middleDiv">
                  <p className='lastComplaint'> Last Complaint </p>
                  <p className='complaintNo'>
                    <div>
                      <MdDateRange style={{ fontSize: "x-large", backgroundColor: "white", color: "#2728ff" }} />
                    </div>
                    <div>
                      {formatDate(latComplaint?.createdAt)}
                    </div>
                  </p>
                  <p className='comaplintTime'>
                    <div>
                      <BiTimeFive style={{ fontSize: "x-large", backgroundColor: "white", color: "#2728ff" }} />
                    </div>
                    <div>
                      {timeConvert(latComplaint?.createdAt)}
                    </div>
                  </p>
                  <p className="complaintStatus">
                    <div>
                      <MdTimeline style={{ fontSize: "x-large", backgroundColor: "white", color: "#2728ff" }} />
                    </div>
                    <div>
                      Status
                    </div>
                  </p>
                  <p className='complaintTypeText'>
                    <div>
                      <VscUngroupByRefType style={{ fontSize: "x-large", backgroundColor: "white", color: "#2728ff" }} />
                    </div>
                    <div>
                      {latComplaint.complaintType === "LD" ? `Late Delivery` : latComplaint.complaintType === "PM" ? `Parcel Missing` :
                        latComplaint.complaintType === "OT" ? `Other` : latComplaint.complaintType === "DMG" ? `Parcel Damaged` :
                          latComplaint.complaintType === "PF" ? `Pilferege` : latComplaint.complaintType === "MB" ? `Misbehavior by Kabra Employees` : ``}
                    </div>
                  </p>
                  <p className='textOverFlowed'>
                    <div>
                      <BiMessageAlt style={{ fontSize: "x-large", backgroundColor: "white", color: "#2728ff" }} />
                    </div>
                    <div>
                      {latComplaint.messages[latComplaint.messages.length - 1].text}
                    </div>
                  </p>
                  <p className="goToChat" onClick={() => { alert("Hello Bro !") }}>
                    <BsFillChatSquareDotsFill style={{ fontSize: "x-large" }} />
                  </p>
                </div>
              </div>
            </div>
          }
          <div className="complaintContainer">
            <div className="btnsForComplaints">
              <button onClick={() => setIsDialogOpen(1)}>Missing</button>
              <button onClick={() => setIsDialogOpen(2)}>Pilferage</button>
              <button onClick={() => setIsDialogOpen(3)}>Damage</button>
              <button onClick={() => setIsDialogOpen(4)}>MisBehave</button>
              <button onClick={() => setIsDialogOpen(5)}>Late Delivery</button>
              <button onClick={() => setIsDialogOpen(6)}>Others</button>
            </div>
          </div>
          {/* <div className="chatIconContainer" 
            // onClick={()=>{historyData.push("/customerDashboard/customerChat")}}
            >
            <BsChatSquareDotsFill />
          </div>   */}
          <Dialog
            open={Boolean(isDialogOpen)}
            maxWidth={"xl"}
            onClose={() => {
              setIsDialogOpen(0);
            }}
          >
            <div className='tac bold'>
              <DialogTitle
                style={{ textAlign: "center" }}
                id="draggable-dialog-title"
              >
                Raise Your Concern
              </DialogTitle>
            </div>
            <DialogContent>
              {isDialogOpen === 1 ?
                <div>
                  {BranchData?.docket &&
                    Object.keys(uniqueLogs).map((key, index) => {
                      let item = uniqueLogs[key];
                      let arr = item.split(";"), num = packageMap[key] ? packageMap[key].length : 0;
                      arr.splice(arr.length - 1, 1);
                      var NewAllowedArr: any = packageMap[key] && packageMap[key].length > 0 ? packageMap[key] : []
                      return (
                        <>
                          <div className="mainContainerForBranchTrack">
                            <SingleStatusForComplaint totalPkg={num} statusArr={arr} PkgList={NewAllowedArr} recordsMap={recordsMap} index={index} />
                          </div>
                        </>
                      );
                    })}
                </div>
                :
                isDialogOpen === 2 ?
                  <div>
                    {BranchData?.docket &&
                      Object.keys(uniqueLogs).map((key, index) => {
                        let item = uniqueLogs[key];
                        let arr = item.split(";"), num = packageMap[key] ? packageMap[key].length : 0;
                        arr.splice(arr.length - 1, 1);
                        var NewAllowedArr: any = packageMap[key] && packageMap[key].length > 0 ? packageMap[key] : []
                        return (
                          <>
                            <div className="mainContainerForBranchTrack">
                              <SingleStatusForComplaint totalPkg={num} statusArr={arr} PkgList={NewAllowedArr} recordsMap={recordsMap} index={index} />
                            </div>
                          </>
                        );
                      })}
                  </div>
                  :
                  isDialogOpen === 3 ?
                    <div>
                      {BranchData?.docket &&
                        Object.keys(uniqueLogs).map((key, index) => {
                          let item = uniqueLogs[key];
                          let arr = item.split(";"), num = packageMap[key] ? packageMap[key].length : 0;
                          arr.splice(arr.length - 1, 1);
                          var NewAllowedArr: any = packageMap[key] && packageMap[key].length > 0 ? packageMap[key] : []
                          return (
                            <>
                              <div className="mainContainerForBranchTrack">
                                <SingleStatusForComplaint totalPkg={num} statusArr={arr} PkgList={NewAllowedArr} recordsMap={recordsMap} index={index} />
                              </div>
                            </>
                          );
                        })}
                    </div>
                    :
                    isDialogOpen === 4 ?
                      <div>Staff Person Name</div>
                      :
                      isDialogOpen === 5 ?
                        <div>
                          {BranchData?.docket &&
                            Object.keys(uniqueLogs).map((key, index) => {
                              let item = uniqueLogs[key];
                              let arr = item.split(";"), num = packageMap[key] ? packageMap[key].length : 0;
                              arr.splice(arr.length - 1, 1);
                              var NewAllowedArr: any = packageMap[key] && packageMap[key].length > 0 ? packageMap[key] : []
                              return (
                                <>
                                  <div className="mainContainerForBranchTrack">
                                    <SingleStatusForComplaint totalPkg={num} statusArr={arr} PkgList={NewAllowedArr} recordsMap={recordsMap} index={index} />
                                  </div>
                                </>
                              );
                            })}
                        </div>
                        :
                        isDialogOpen === 6 ?
                          <div>Other Complaint Details</div>
                          :
                          <></>
              }
            </DialogContent>
            <DialogActions>
              <div className='onLinePkgbtnMobile'>
                <ButtonPrimary onClick={() => {
                  setIsDialogOpen(0)
                }}>
                  Submit
                </ButtonPrimary>
                <ButtonDefault
                  className=""
                  onClick={() => setIsDialogOpen(0)}
                >
                  close
                </ButtonDefault>
              </div>
            </DialogActions>
          </Dialog>
        </div>
        :
        <div className="rAndrParentContainer">
          <div className="rAndrContainer">
            <div className="reviewRatingDiv">
              <div className="applyRating">
                <Rating
                  max={3}
                  style={{ fontSize: "xx-large", marginTop: "2.5%" }}
                  name="hover-feedback1"
                  value={ratingFromUser}
                  precision={1}
                  onChange={(e: any) => {
                    setRatingFromUser(e.target.value);
                  }}
                />
              </div>
              <div className="applyReview">
                <TextField
                  style={{ width: "100%", marginTop: 7, fontSize: "large" }}
                  value={reviewFromUser}
                  disabled={false}
                  multiline={true}
                  variant="outlined"
                  placeholder="Give a Review"
                  rows={1}
                  onChange={(el: any) => { setReviewFromUser(el.target.value) }}
                />
              </div>
              <div className="submitRating" onClick={() => alert("Heloo 123")}>
                <button className="suubmitIcon">
                  <AiOutlineSend />
                </button>
              </div>
            </div>
          </div>
        </div>
      }

    </div>
  )
}

const CustomerTrackView = (props: any) => {
  console.log("customerTracking 5000", props);

  return (
    <div>
      {props.customerTrackingData.source === "Customer-Authenticated-CustomerOrder"
        || props.customerTrackingData.source === "Customer-NotAuthenticated-CustomerOrder"
        || props.customerTrackingData.source === "Branch-Authenticated-CustomerOrder" ? <CustomerAuthenticatedView data={props} /> : <></>}
      {props.customerTrackingData.source === "Customer-Authenticated-BranchOrder"
        || props.customerTrackingData.source === "Customer-NotAuthenticated-BranchOrder"
        || props.customerTrackingData.source === "Branch-Authenticated-BranchOrder" ? <BranchPersonAuthenticatedView data={props} /> : <></>}
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  customerTracking: state.customerBooking.tracking,
  user: state.customerLoginReducer,
  customerChatHistory: state.customerChatHistory,
  customerTrackingData: state.customerTracking,
  entity:
    state.user.loginType === "B"
      ? state.user.opBranch._id
      : state.user.opFleet._id,
});

const mapDispatchToProps = {
  getCustomerDocketLocation,
  setCustomerBookingTracking,
  ResetCustomerTracking,
  showLoader,
  hideLoader
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerTrackView);
