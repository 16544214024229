import React from "react";
import Styled from "styled-components";
const TABLE = (props) => <table {...props}>{props.children}</table>;
const TH = (props) => <th {...props}>{props.children}</th>;
const TD = (props) => <td {...props}>{props.children}</td>;
const Table = Styled(TABLE)`
    border: 1px solid black;
    border-collapse: collapse;
`;

const Th = Styled(TH)`
    border: 1px solid red;
    border-collapse: collapse;
    text-align: justify;
    padding: 5px;
`;
const Td = Styled(TD)`
    border: 1px solid red;
    border-collapse: collapse;
    text-align: justify;
    padding: 5px;
`;

const BuiltyPrint = (props) => {
  // let { fromBranch, toBranch, lrNo, bookDate, fleetNo, sname, scontact, sgst, rname, rcontact, rgst, dValue, invoiceNo,
  //     rAddress, gross, builty, insurance, hamali, damrage, gst, total
  // } = props
  let { docket } = props;
  let {
    trackingNumber,
    docketNumber,
    paymentMode,
    createdAt,
    packages,
    sender,
    receiver,
    sAddress,
    rAddress: raddress,
    senderName,
    senderGst,
    receiverName,
    receiverGst,
    originBranch,
    destBranch,
    goods,
    eWayBill,
    gstAmount,
    billNo,
    insAmt,
    gross,
    handling,
    bilty,
    actualDemurrage,
    paidDemurrage,
    settledAmount,
    pickupCharge,
    doorDelCharges,
  } = docket;

  let fromBranch = originBranch.branchName,
    toBranch = destBranch
      ? destBranch.branchName
      : destBranch.address && destBranch.address.pincode,
    lrNo = props.isDocket ? docketNumber : trackingNumber,
    bookDate = new Date(createdAt).toLocaleDateString(),
    fleetNo = "",
    sname = senderName,
    scontact = sender ? sender.contact : "",
    sgst = senderGst,
    rname = receiverName,
    rcontact = receiver ? receiver.contact : "",
    rgst = receiverGst,
    dValue = goods,
    invoiceNo = billNo,
    rAddress = destBranch.address
      ? destBranch.address.l1 +
        " , " +
        destBranch.address.l2 +
        " , " +
        destBranch.address.pincode +
        " , " +
        destBranch.address.city?.name
      : "",
    freight = (packages || []).reduce(
      (pv, cv) =>
        pv +
        (cv.rate === "Per package"
          ? cv.qty * cv.amount +
            (cv.intermediateCharge ? cv.intermediateCharge : 0)
          : cv.rate === "Per Kg"
          ? (cv.intermediateCharge ? cv.intermediateCharge : 0) +
            cv.qty * cv.amount * cv.weight
          : (cv.intermediateCharge ? cv.intermediateCharge : 0) +
            cv.amount),
      0
    ) +
      (docket.handling || 0) +
      (docket.pickupCharge ? "," + docket.pickupCharge : "") +
      (docket.doorDelCharges
        ? docket.pickupCharge
          ? "," + docket.doorDelCharges
          : "," + 0 + "," + docket.doorDelCharges
        : ""),
    builty = bilty || 0,
    discount = docket.discount || 0,
    deliveryCharge = docket.deliveryCharge || 0,
    calculatedDemurrage = docket.paidDemurrage || 0,
    otherCharges = (docket.BTOCharge || 0) + (docket.BTHCharge || 0) + (docket.NDCharge || 0),
    insurance = insAmt || 0,
    hamali = handling || 0,
    damrage = paidDemurrage ? paidDemurrage : actualDemurrage || 0,
    gst = gstAmount || 0,
    total = settledAmount || docket.collectedGross || gross +
      (docket.BTOCharge || 0) +
      (docket.BTHCharge || 0) +
      (docket.NDCharge || 0);
    
    let {address, pContact} = originBranch
    let {l1, l2, city, pincode} = address

  let Span = (props) => (
    <span style={{ fontWeight: "lighter", whiteSpace: "nowrap" }}>
      {props.children}
    </span>
  );

  return (
    <Table
      style={{
        width: "21cm",
        height: "14.8cm",
        backgroundImage: 'url("/taptap_watermark.png")',
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "12cm 12cm",
      }}
    >
      {/* <div style={{ backgroundImage: "url(kabra-express.png)" }}>
            </div> */}
      <tbody style={{border: '2px solid red'}}>
        <tr>
          <Th colspan="21">
            <img style={{width: '-webkit-fill-available'}} src="/logo_header.png" />
          </Th>
        </tr>
        <tr>
          <Th
            colspan="6"
            style={{
              backgroundColor: "#3E4095",
              textAlign: "center",
              color: "cornsilk",
            }}
          >
            {`${l1}, ${l2}, ${city?.name} - ${pincode}`} <br/> {pContact}
          </Th>
          <Th colspan="3" style={{ textAlign: "center" }}>
            Consignment Note
          </Th>
          <Th
            colspan="5"
            style={{
              backgroundColor: "#3E4095",
              textAlign: "center",
              color: "cornsilk",
            }}
          >
            Helpline:-7575857777
          </Th>
          <Th
            colspan="7"
            style={{
              backgroundColor: "#3E4095",
              color: "cornsilk",
              textAlign: "center",
            }}
          >
            Website:-www.kabraexpress.com
          </Th>
        </tr>
        <tr>
          <Th rowspan="2" colspan="6">
            From:-&nbsp;<Span>{fromBranch}</Span>
          </Th>
          <Th rowspan="2" colspan="6">
            To:-&nbsp;<Span>{toBranch}</Span>
          </Th>
          <Th rowspan="2" colspan="5">
            LR. No:-&nbsp;<Span>{lrNo}</Span>
          </Th>
          <Th colspan="2">Date</Th>
          <Th colspan="2">
            <Span>{bookDate}</Span>
          </Th>
        </tr>
        <tr>
          <Th colspan="2">Truck No</Th>
          <Td colspan="2">
            <Span>{fleetNo}</Span>
          </Td>
        </tr>
        <tr>
          <Th colspan="2">Consigner</Th>
          <Td colspan="9">
            <Span>{sname}</Span>
          </Td>
          <Th colspan="2" style={{ textAlign: "center" }}>
            Consignee
          </Th>
          <Td colspan="8">
            <Span>{rname}</Span>
          </Td>
        </tr>
        <tr>
          <Th colspan="2">Mobile No.</Th>
          <Td colspan="9">
            <Span>{scontact}</Span>
          </Td>
          <Th colspan="2" style={{ textAlign: "center" }}>
            Mobile No.
          </Th>
          <Td colspan="6">
            <Span>{rcontact}</Span>
          </Td>
          <Td
            colspan="2"
            rowspan="11"
            style={{
              transform: "rotate(-75deg)",
              border: "none",
              fontWeight: "lighter",
            }}
          >
            {"ACKNOWLEDGMENT"}
          </Td>
        </tr>
        <tr>
          <Th colspan="2">GSTIN</Th>
          <Td colspan="9">
            <Span>{sgst}</Span>
          </Td>
          <Th colspan="2" style={{ textAlign: "center" }}>
            GSTIN
          </Th>
          <Td colspan="6">
            <Span>{rgst}</Span>
          </Td>
        </tr>
        <tr>
          <Th colspan="2">D.Vales Rs.</Th>
          <Td colspan="3">
            <Span>{dValue}</Span>
          </Td>
          <Th colspan="3" style={{ textAlign: "center" }}>
            Inv No.
          </Th>
          <Td colspan="3">
            <Span>{invoiceNo}</Span>
          </Td>
          <Th colspan="2"></Th>
          <Th colspan="6"></Th>
        </tr>
        <tr>
          <Th colspan="2">Packages</Th>
          <Th colspan="11" style={{ textAlign: "center" }}>
            Description
          </Th>
          <Th colspan="2" style={{ textAlign: "center" }}>
            Weight
          </Th>
          <Th colspan="2" style={{ textAlign: "center" }}>
            Freight
          </Th>
          <Td colspan="2">
            <Span>{freight}</Span>
          </Td>
        </tr>
        <tr>
          <Th colspan="2">
            <Span>{packages && packages[0] ? packages[0].qty : "-"}</Span>
          </Th>
          <Th colspan="4">
            <Span>
              {packages && packages[0] ? packages[0].materialType?.name : "-"}
            </Span>
          </Th>
          <Th colspan="4">
            <Span>
              {packages && packages[0] ? packages[0].packingType?.name : "-"}
            </Span>
          </Th>
          <Th colspan="3">
            <Span>
              {packages && packages[0]
                ? `${packages[0].dimension.l}*${packages[0].dimension.b}*${packages[0].dimension.h} ${packages[0].unit}`
                : "-"}
            </Span>
          </Th>
          <Th colspan="2">
            <Span>
              {packages && packages[0] ? packages[0].weight + " KG" : "-"}
            </Span>
          </Th>
          <Th colspan="2" style={{ textAlign: "center" }}>
            Bilty Charge
          </Th>
          <Th colspan="2">
            <Span>{builty}</Span>
          </Th>
        </tr>
        <tr>
          <Th colspan="2">
            <Span>{packages && packages[1] ? packages[1].qty : "-"}</Span>
          </Th>
          <Th colspan="4">
            <Span>
              {packages && packages[1] ? packages[1].materialType?.name : "-"}
            </Span>
          </Th>
          <Th colspan="4">
            <Span>
              {packages && packages[1] ? packages[1].packingType?.name : "-"}
            </Span>
          </Th>
          <Th colspan="3">
            <Span>
              {packages && packages[1]
                ? `${packages[1].dimension.l}*${packages[1].dimension.b}*${packages[1].dimension.h} ${packages[1].unit}`
                : "-"}
            </Span>
          </Th>
          <Th colspan="2" style={{ textAlign: "center" }}>
            <Span>
              {packages && packages[1] ? packages[1].weight + " KG" : "-"}
            </Span>
          </Th>
          <Th colspan="2" style={{ textAlign: "center" }}>
            DISCOUNT
          </Th>
          <Th colspan="2">
            <Span>{discount}</Span>
          </Th>
        </tr>
        <tr>
          <Th colspan="2">
            <Span>{packages && packages[2] ? packages[2].qty : "-"}</Span>
          </Th>
          <Th colspan="4">
            <Span>
              {packages && packages[2] ? packages[2].materialType?.name : "-"}
            </Span>
          </Th>
          <Th colspan="4">
            <Span>
              {packages && packages[2] ? packages[2].packingType?.name : "-"}
            </Span>
          </Th>
          <Th colspan="3">
            <Span>
              {packages && packages[2]
                ? `${packages[2].dimension.l}*${packages[2].dimension.b}*${packages[2].dimension.h} ${packages[2].unit}`
                : "-"}
            </Span>
          </Th>
          <Th colspan="2" style={{ textAlign: "center" }}>
            <Span>
              {packages && packages[2] ? packages[2].weight + " KG" : "-"}
            </Span>
          </Th>
          <Th colspan="2" style={{ textAlign: "center" }}>BRANCH DELIVERY</Th>
          <Th colspan="2">{deliveryCharge}</Th>
        </tr>
        <tr>
          <Th colspan="2">Booked by</Th>
          <Th colspan="11"></Th>
          <Th colspan="2" style={{ textAlign: "center" }}>
            Rate
          </Th>
          <Th colspan="2" style={{ textAlign: "center" }}>
            DEMRG
          </Th>
          <Th colspan="2">
            <Span>{calculatedDemurrage}</Span>
          </Th>
        </tr>
        <tr>
          <Th colspan="13" rowspan="3">
            To Be Delivered At:&nbsp;&nbsp;
            <span style={{ fontWeight: "lighter" }}>{rAddress}</span>
          </Th>
          <Th colspan="2"></Th>
          <Th colspan="2" style={{ textAlign: "center" }}>
            OTHER
          </Th>
          <Th colspan="2">
            <Span>{otherCharges}</Span>
          </Th>
        </tr>
        <tr>
          <Th colspan="2"></Th>
          <Th colspan="2" style={{ textAlign: "center" }}>
            
          </Th>
          <Th colspan="2">
            <Span></Span>
          </Th>
        </tr>
        <tr>
          <Th colspan="2"></Th>
          <Th colspan="2" style={{ textAlign: "center" }}>
            {" "}
            TOTAL
          </Th>
          <Th colspan="2">
            <Span>{total}</Span>
          </Th>
        </tr>
        <tr>
          <Th colspan="13">
            <span
              style={{
                fontWeight: "normal",
                whiteSpace: "nowrap",
                fontSize: "0.7rem",
              }}
            >
              GST Is Payable By Consigner Or Consignee Under RCM Rules As Per
              The Provisions Of Section 9(3) Of CGST/SGST Act 2017
            </span>
          </Th>
          <Th colspan="2"></Th>
          <Th colspan="2"></Th>
          <Th colspan="2"></Th>
          <Th rowspan="2" colspan="2"></Th>
        </tr>
        <tr>
          <Th colspan="13">
            <span style={{ fontWeight: "bold", fontSize: "0.7rem" }}>
              Note: Consignment booked in favour of consignee will be delivered
              directly to the consignee party without collecting consignee copy
            </span>
          </Th>
          <Th
            colspan="6"
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "0.8rem",
              whiteSpace: "nowrap",
            }}
          >
            <span>BOOKED AT OWNER'S RISK</span>
          </Th>
        </tr>
        <tr>
          <Th colspan="21">
            <img style={{width: '25cm'}} src="/services.png" />
          </Th>
        </tr>
        <tr>
          <Th colspan="21">
            <img style={{width: '25cm', marginLeft: '16px'}} src="/new_branch_ad.png" />
          </Th>
        </tr>
      </tbody>
    </Table>
  );
};



export default BuiltyPrint;
