export const howItWorks = [
  {
    id: 0,
    heading: "Download the App",
    description: "Sign up, create a password, and then log in.",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/1.png",
  },
  {
    id: 1,
    heading: "Driver Personal Details",
    description:
      "Complete the Profile Details ( First & Last Name, Email ID, Password, Contact Number, Address, Pincode & City )",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/2.png",
  },
  {
    id: 2,
    heading: "Choose Vehicle Now",
    description:
      "1. Vehicle Name\n2.Vehicle Type(Three or Four Wheeler or Bike)\n3.Vehicle Number",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/3.png",
  },
  {
    id: 3,
    heading: "Upload Required Documents",
    description:
      "Aadhar Card | PAN card | Driving License | RC book | Passport Size Photo.",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/1.png",
  },
  {
    id: 4,
    heading: "Training & Instructions",
    description:
      "Complete the online training and the app using the instructions.",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/2.png",
  },
  {
    id: 5,
    heading: "Collect your welcome kit",
    description: "",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/3.png",
  },
  {
    id: 6,
    heading: "Easy to take orders",
    description: "Start accepting delivery orders.",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/1.png",
  },
];

export const individualWithFleetHowItWorks = [
  {
    id: 0,
    heading: "Download the App",
    description: "Sign up, create a password, and then log in.",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/1.png",
  },
  {
    id: 1,
    heading: "Driver Personal Details",
    description:
      "Complete the Profile Details ( First & Last Name, Email ID, Password, Contact Number, Address, Pincode & City )",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/2.png",
  },
  {
    id: 2,
    heading: "Choose Vehicle Now",
    description:
      "1. Vehicle Name\n2.Vehicle Type(Three or Four Wheeler or Bike)\n3.Vehicle Number",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/3.png",
  },
  {
    id: 3,
    heading: "Upload Required Documents",
    description:
      "Aadhar Card | PAN card | Driving License | RC book | Passport Size Photo.",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/1.png",
  },
  {
    id: 4,
    heading: "Training & Instructions",
    description:
      "Complete the online training and the app using the instructions.",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/2.png",
  },
  {
    id: 5,
    heading: "Collect your welcome kit",
    description: "",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/3.png",
  },
  {
    id: 6,
    heading: "Easy to take orders",
    description: "Start accepting delivery orders.",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/1.png",
  },
];

export const intraCityHowItWorks = [
  {
    id: 0,
    heading: "Open the TapTap  app",
    description: "",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/1.png",
  },
  {
    id: 1,
    heading: "Select the pickup and Drop locations with contact details",
    description: "",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/2.png",
  },
  {
    id: 2,
    heading: "Add any other stops, if needed",
    description: "",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/3.png",
  },
  {
    id: 3,
    heading: "Choose the goods type if needed",
    description: "",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/1.png",
  },
  {
    id: 4,
    heading: "Choose Vehicle",
    description: "",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/2.png",
  },
  {
    id: 5,
    heading: "Click on ‘Review Booking’ and go through all the order details.",
    description: "",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/3.png",
  },
  {
    id: 6,
    heading: "Tap ‘Check Prices’",
    description: "",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/1.png",
  },
  {
    id: 7,
    heading: "Apply Coupon Code",
    description: "",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/1.png",
  },
  {
    id: 8,
    heading:
      "Choose your payment method - Cash, To Pay, TapTap Wallet  or Digital Payment",
    description: "",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/1.png",
  },
  {
    id: 9,
    heading: "Meet the driver and spend your package on its way.",
    description: "",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/1.png",
  },
  {
    id: 10,
    heading: "You're all set. Track your order as we get it TapTap.",
    description: "",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/1.png",
  },
  {
    id: 11,
    heading:
      "After your Delivery Customers give the review and rating for Delivery Partners.",
    description: "",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/1.png",
  },
];

const spanLabel = (label: string) => `<span class="fw-500">${label}</span>`;

export const partLoadAndFullLoadHowItWorks = [
  {
    id: 0,
    heading: "Book Your Truck",
    description: `<p>${spanLabel(
      "Platform:"
    )} Use our website or TapTap App. </p>
       <p>${spanLabel(
         "Details:"
       )} Enter pick-up and drop-off locations, along with the dimensions, weight, and type of truck required (for full load).</p>`,
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/1.png",
  },
  {
    id: 1,
    heading: "Confirm Your Booking",
    description: `${spanLabel(
      "Confirmation:"
    )} Place your order easily by confirming the details.`,
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/2.png",
  },
  {
    id: 2,
    heading: "Receive and Accept Bids",
    description: `<p>${spanLabel("Bids:")} Receive bids from transporters</p>
    <p>${spanLabel(
      "Selection:"
    )} Choose the best bid based on cost and payment mode.</p>
    <p>${spanLabel(
      "Notifications:"
    )} Receive vehicle details, driver information, and key updates via email and SMS.</p>
    `,
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/3.png",
  },
  {
    id: 3,
    heading: "Track Your Shipment",
    description: `<p>${spanLabel(
      "Real-Time Tracking:"
    )} Monitor your consignment online at any time during delivery.</p>
    <p>${spanLabel(
      "User Experience:"
    )} Instantly check the location and status of your shipment through our user-friendly interface.</p>
    `,
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/1.png",
  },
  {
    id: 4,
    heading: "Delivery and Feedback",
    description: `
    <p>${spanLabel(
      "Delivery:"
    )} Ensure timely pickup and delivery of your shipment. Rate Us: After delivery, rate our service and leave a review via email. </p>
    <p>${spanLabel(
      "Tip the Driver:"
    )} Optionally tips the driver electronically without needing cash. </p>
    `,
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/2.png",
  },
];

export const expressParcelBranchToBranchHowItWorks = [
  {
    id: 0,
    heading: "Booking",
    description: "Book through our website, or app, or visit any of our 70+ branches.",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/1.png",
  },
  {
    id: 1,
    heading: "Pickup",
    description: "Choose door collection or godown drop-off.",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/2.png",
  },
  {
    id: 2,
    heading: "Transport",
    description: "Secure transport across 24 states using company-owned vehicles.",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/3.png",
  },
  {
    id: 3,
    heading: "Tracking",
    description: "Real-time tracking is available online.",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/1.png",
  },
  {
    id: 4,
    heading: "Billing",
    description: "Seamless billing and payment options.",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/2.png",
  }
];

export const expressParcelDoorDeliveryHowItWorks = [
  {
    id: 0,
    heading: "Create a Shipment",
    description: "Upload bulk shipments or create them manually through the TapTap dashboard.",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/1.png",
  },
  {
    id: 1,
    heading: "Order Placement",
    description: "Customers place orders for document or package pickup via our website or app.",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/2.png",
  },
  {
    id: 2,
    heading: "Select a courier partner",
    description: "Choose a courier partner with our AI tool, based on factors such as weight, destination, and more.",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/3.png",
  },
  {
    id: 3,
    heading: "Transportation",
    description: "Packages are securely transported to our sorting facility for efficient routing.",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/1.png",
  },
  {
    id: 4,
    heading: "Ship your parcel",
    description: "Generate labels, prepare your packages, and hand them over to the chosen courier partner.",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/2.png",
  },
  {
    id: 5,
    heading: "Delivery",
    description: "We ensure timely delivery to the recipient's doorstep, providing real-time tracking and updates.",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/2.png",
  },
  {
    id: 6,
    heading: "Confirmation",
    description: "Once delivered, customers receive confirmation of successful delivery.",
    img: "/assets/landing-page/how-it-works/partners/individual-with-fleet/2.png",
  },
];

export const howItWorksData = [
  {
    id: 0,
    page: "partners",
    partner: "Individual With Fleet",
    howItWorks: individualWithFleetHowItWorks,
  },
  {
    id: 1,
    page: "services",
    service: "Intracity",
    howItWorks: intraCityHowItWorks,
  },
  {
    id: 2,
    page: "services",
    service: "Part Load & Full Load",
    howItWorks: partLoadAndFullLoadHowItWorks,
  },
  {
    id: 3,
    page: "services",
    service: "Intercity Express Parcel",
    expressParcelType: "Branch to Branch",
    howItWorks: expressParcelBranchToBranchHowItWorks,
  },
  {
    id: 4,
    page: "services",
    service: "Intercity Express Parcel",
    expressParcelType: "Door Delivery",
    howItWorks: expressParcelDoorDeliveryHowItWorks,
  },
];
