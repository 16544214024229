// react
import React, { useState } from "react";
// react-router-dom
import { useNavigate, useLocation } from "react-router-dom";
// css
import "./header.css";
// constants (data | icon)
import {
  headerMobileMenuListItems,
  headerNavListItems,
} from "../../../../constants/data";
import {
  CloseIcon,
  CustomerIcon,
  DownLightIcon,
  LockIcon,
  MenuIcon,
  PartnersIcon,
  SearchIcon,
  ServiceIcon,
  SignInIcon,
  UpLightIcon,
} from "../../../../constants/icons";
// utils (validation)
import {
  branchDocketValidate,
  branchTrackingIdValidate,
  customerDocketValidate,
} from "../../../../utils/validation";
// ui components
import { Button } from "../../../../components/UI";
// custom hook
import { useLandingPageContext } from "../../../../hooks/useLandingPageContext";
import { StateObjType } from "../../partner-forms/useCustomState";
// import { sendNotification } from "../../../../../services/home";

//type
type StateType = {
  value: string;
  error: true | false;
};
type TrackingNumberStateType = StateType;

// variables
const icons = [<ServiceIcon />, <PartnersIcon />, <CustomerIcon />];

// FC: Header Component
const Header = () => {
  // State
  // from Landing Page Context
  const {
    activePage,
    setActivePage,
    isScrolled,
    isMobileMenuOpen,
    setIsMobileMenuOpen,
  } = useLandingPageContext();

  // State: Tracking Number
  const [trackingNumber, setTrackingNumber] = useState<TrackingNumberStateType>(
    () => ({
      value: "",
      error: false,
    })
  );
  // State: To Open the Mobile Menu
  // const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(() => false);
  // useLocation
  const { pathname } = useLocation();
  // useHistory
  const navigate = useNavigate();

  // does: handle tracking search
  const trackDocket = () => {
    const docket = trackingNumber.value.toUpperCase();
    const BranchDocketRegex = (str: any) => {
      const branchDocketRegex = /^[A-Z]{2,5}[1-9]{1}[0-9]{9}$/;
      return branchDocketRegex.test(str);
    };
    const BrnachTrackingIdRegex = (str: any) => {
      const trakingIdRegex = /^[A-Z]{3,5}-[0-9]{3,5}-[0-9]{0,10}$/;
      return trakingIdRegex.test(str);
    };
    const CustomerDocketRegex = (str: any) => {
      const customerDocketRegex = /^[A-Z]{2,4}-[A-Z]{1,3}-[0-9]{1,5}$/;
      return customerDocketRegex.test(str);
    };

    if (BranchDocketRegex(docket) === true) {
      setTrackingNumber((prevTrackingNumber) => {
        const prev = prevTrackingNumber as StateObjType;
        return { ...prev, value: "" };
      });
      navigate({ pathname: "/" });
      return alert("Tracking Number is Not Valid !");
    } else if (BrnachTrackingIdRegex(docket) === true) {
      return navigate("/TempTracking", {
        state: {
          from: "Customer-NotAuthenticated-BranchOrder",
          docketNumber: { docket },
          typeOfDocket: "Branch Order Tracking Id",
        },
      });
    } else if (CustomerDocketRegex(docket) === true) {
      return navigate("/TempTracking", {
        state: {
          from: "Customer-NotAuthenticated-CustomerOrder",
          docketNumber: { docket },
          typeOfDocket: "Customer Docket",
        },
      });
    } else if (
      CustomerDocketRegex(docket) === false ||
      BrnachTrackingIdRegex(docket) === false ||
      BranchDocketRegex(docket) === false
    ) {
      navigate({ pathname: "/" });
      return alert("Docket Number Or Tracking Number is Not Valid !");
    }
  };
  // does: call the trackDocket function
  function handleTracking() {
    trackDocket();
  }
  // does: handle the tracking input
  function handleTrackingInput(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key !== "Enter") {
      return;
    }
    trackDocket();
  }
  // does: validate tracking number
  function trackingNumberValidation(
    e: React.FocusEvent<HTMLInputElement, Element>
  ) {
    const value = e.target.value;
    if (
      value &&
      !branchDocketValidate(value) &&
      !customerDocketValidate(value) &&
      !branchTrackingIdValidate(value)
    ) {
      setTrackingNumber((prevTrackingNumber) => {
        return { ...prevTrackingNumber, error: true };
      });
      return
    }
  }

  // component return
  return (
    <header
      className={`header | bg-primary-400 ${isScrolled ? "shadow-small" : ""}`}
    >
      {/* Logo */}
      <h1
        onClick={() => {
          // debugger
          navigate("/", {
            replace: true,
          })
          setIsMobileMenuOpen(false)
        }
        }
        className="header-logo-text | fs-logo text-white-900 fw-800 pointer text-left"
      >
        TapTap
      </h1>

      {/* <button
        onClick={async () => {
          try {
            const response = await sendNotification({ deviceId: localStorage.getItem("deviceId") })
            console.log(response, "response1111")
          } catch (error) {
            console.error('Error subscribing to push notifications:', error);
          }

        }}>send notification</button> */}
      {/* <button onClick={async () => {

        try {
          const response = await sendNotification({})
          console.log(response, "response1111")
        } catch (error) {
          console.error('Error subscribing to push notifications:', error);
        }

      }}>send notification to all</button> */}
      {/* Header Navigation */}
      <nav className="header-nav | ml-auto">
        {/* {
          pathname !== "" && pathname !== "/"
          &&
          <li className={`header-nav-list-item | flex-shrink-0 flex ai-center fs-link uppercase fw-500`} onClick={() => navigate("")}><HomeIcon /> Home</li>
        } */}
        <ul className="header-nav-list | flex gap-1 ai-center m-0">
          {headerNavListItems.length > 0 &&
            headerNavListItems.map((item) => {
              return (
                <li
                  className={`header-nav-list-item | flex ai-center flex-shrink-0 fs-link text-white-900 ${item.value === activePage &&
                    (pathname === "" || pathname === "/")
                    ? "active"
                    : ""
                    } uppercase fw-500`}
                  key={item.value}
                  onClick={() => {
                    setActivePage(item.value);
                    navigate("/");
                  }}
                >
                  <span>{icons[item.id]}</span>
                  {item.value}
                </li>
              );
            })}
        </ul>
      </nav>
      {/* Menu & Actions */}
      <div className="header-menu-actions | flex gap-1 ai-center jc-end ml-auto text">
        {/* Header Actions */}
        <div className="header-actions | flex gap-1">
          {/* Tracking Search */}
          <div className="input-container | relative">
            <input
              type="text"
              placeholder="Tracking Number"
              className="| tracking-number-search outline-none shadow-small fs-input"
              onBlur={(e) => trackingNumberValidation(e)}
              style={{ width: "175px" }}
              value={trackingNumber.value}
              onChange={(e) =>
                setTrackingNumber((prevTrackingNumber) => {
                  return { ...prevTrackingNumber, value: e.target.value };
                })
              }
              onKeyDown={handleTrackingInput}
            />
            <SearchIcon
              className="tracking-number-search-icon | fs-link-icon"
              onClick={handleTracking}
            />
          </div>
          {/* Sign In Button */}
          <Button
            variant="secondary"
            action="secondary"
            Icon={<SignInIcon />}
            className="| fs-button"
            onClick={() => navigate("/login", { replace: true })}
          >
            Sign In
          </Button>
        </div>
        {/* Mobile Hamburger Menu */}
        <MenuIcon
          className="header-menu-actions-toggle | pointer fs-heading"
          onClick={() => setIsMobileMenuOpen(true)}
        />
      </div>
      {/* It will be visible behind the Mobile Sidebar Menu */}
      {/* Mobile Sidebar Menu */}
      {isMobileMenuOpen && (
        <div
          className="landing-overlay"
          onClick={() => setIsMobileMenuOpen(false)}
        ></div>
      )}
      <nav
        className={`header-mobile-menu | bg-primary-50 text-white-900 ${isMobileMenuOpen ? "active" : ""}`}
        style={{ overflow: "auto" }}
      >
        <div className="| flex jc-between ai-center p-1 fs-heading bg-primary-400 text-white-900 fw-500">
          <p>Menu</p>
          <CloseIcon
            onClick={() => setIsMobileMenuOpen(false)}
            className="pointer"
          />
        </div>
        <MobileMenuList
          items={headerMobileMenuListItems}
          setIsMobileMenuOpen={setIsMobileMenuOpen}
        />
      </nav>
    </header>
  );
};

// type
type MobileMenuListProps = {
  items?: typeof headerMobileMenuListItems; // Make items optional
  setIsMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

// FC: MobileMenuList Component
function MobileMenuList({ items, setIsMobileMenuOpen }: MobileMenuListProps) {
  const { setActiveService, setActivePartner, setActivePage, setActiveExpressParcelDeliveryType, setActiveLoadType } =
    useLandingPageContext();
  if (!items) return null; // Handle case where items is undefined
  // useHistory
  const navigate = useNavigate();

  // State: To Toggle The Dropdown Menu
  const [openDropdowns, setOpenDropdowns] = useState<number[]>([]);

  // does: toggle the dropdown item
  const toggleDropdown = (itemId: number) => {
    if (openDropdowns.includes(itemId)) {
      setOpenDropdowns(openDropdowns.filter((id) => id !== itemId));
    } else {
      setOpenDropdowns([...openDropdowns, itemId]);
    }
  };

  // component return
  return (
    <ul className="header-mobile-menu-list bg-primary-50 text-primary-900 flex-1">
      {items.map((item: any) => {
        const isComingSoon =
          item.value === "Insurance" ||
          item.value === "Store" ||
          item.value === "Buy/Sell Vehicle" ||
          item.value === "Packers & Movers" ||
          item.value === "International" ||
          item.value === "Warehouse" ||
          item.value === "Investors";
        return (
          <li
            key={item.id}
            className="header-mobile-menu-list-item | pointer fw-500 fs-subheading shadow-small"
            onClick={(e) => {
              console.log(`kjdfbnvndfv`, item.value)
              e.stopPropagation();
              if (isComingSoon) return alert("Coming Soon😊");;
              if (item.isDropdown) {
                toggleDropdown(item.id);
              }
              // to send it to the page
              if (
                item.value === "Direct Sales Agent" ||
                item.value === "Delivery Partner" ||
                item.value === "Investors" ||
                item.value === "Warehouse" ||
                item.value === "Transport Company"
              ) {
                setActivePartner(item.value);
                setActivePage("partners");
                navigate("/form", { replace: true });
                setIsMobileMenuOpen(false);
              }
              if (item.value === "Branch to Branch" || item.value === "Door Delivery") {
                console.log(`kjdfbnvndfv 2.0`, item)
                setActiveExpressParcelDeliveryType(item.value)
                setActivePage("services");
                setActiveService("Intercity Express Parcel");
                navigate("/form", { replace: true });
                setIsMobileMenuOpen(false);
              }
              if (item.value === "Part Load" || item.value === "Full Load") {
                console.log(`kjdfbnvndfv 2.0`, item)
                setActiveLoadType(item.value)
                setActivePage("services");
                setActiveService("Part Load & Full Load");
                navigate("/form", { replace: true });
                setIsMobileMenuOpen(false);
              }

              if (item.value === "Intracity") {
                setActiveService(item.value);
                setActivePage("services");
                navigate("/form", { replace: true });
                setIsMobileMenuOpen(false);
              }
              if (item.value === "Customer") {
                setActivePage("customer");
                navigate("/form", { replace: true });
                setIsMobileMenuOpen(false);
              }
              if (item.href) {
                navigate(`/${item.href}`, { replace: true });
                setIsMobileMenuOpen(false);
              }
            }}
          >
            {item.isDropdown ? (
              <>
                <p className="| flex-between" style={{ margin: "0" }}>
                  <span>{item.value}</span>
                  {openDropdowns.includes(item.id) ? (
                    <UpLightIcon onClick={() => toggleDropdown(item.id)} />
                  ) : (
                    <DownLightIcon onClick={() => toggleDropdown(item.id)} />
                  )}
                </p>
                {openDropdowns.includes(item.id) && (
                  <MobileMenuList
                    items={item.dropdownItems}
                    setIsMobileMenuOpen={setIsMobileMenuOpen}
                  />
                )}
              </>
            ) : (
              <>
                {isComingSoon ? (
                  <div className="flex gap-4 ai-end">
                    <span style={{ lineHeight: "100%" }}>{item.value}</span>
                    <LockIcon className="" />
                  </div>
                ) : (
                  <span>{item.value}</span>
                )}
              </>
            )}
          </li>
        );
      })}
    </ul>
  );
}

export default Header;
