// react
import React, { useEffect, useState } from "react";
// react-router-dom
import { useNavigate } from "react-router-dom";
// landing page components
import {
  Navigation,
  Features,
  HowItWorks,
  FAQ,
  Footer,
  Main,
  Testimonials,
  CTA,
} from "./components";
// css
import "./landing.css";
import "../../components/styles.css";
// constants ( data, images, icons )
import { ReactComponent as BikeSvg } from "./assets/bike.svg";
import { ReactComponent as CourierSvg } from "./assets/courier.svg";
import { ReactComponent as FlightSvg } from "./assets/flight.svg";
import { ReactComponent as FullLoadSvg } from "./assets/full-load.svg";
import { ReactComponent as MoversSvg } from "./assets/movers.svg";
import { ReactComponent as PackersSvg } from "./assets/packers.svg";
import { ReactComponent as PartLoadSvg } from "./assets/part-load.svg";
import { ReactComponent as ShipSvg } from "./assets/ship.svg";
import { ReactComponent as TempoSvg } from "./assets/tempo.svg";
import { ReactComponent as WarehouseSvg } from "./assets/warehouse.svg";
import {
  mobileLandingPageCustomerListItem,
  mobileLandingPagePartnerListItem,
} from "../../constants/data";
import { mobileCarouselImages } from "../../../constants/mobileCarouselImages";
import { mobileCarouselPartnersImages } from "../../constants/images";
import {
  DirectSalesAgentIcon,
  IndividualWithFleetIcon,
  InvestorsIcon,
  LockIcon,
  TransportCompanyIcon,
  WarehouseIcon,
} from "../../constants/icons";
// ui components
import { Button, Carousel } from "../../components/UI";
// components
import { MobileFooterNavigation } from "../../components";
// context
import { useLandingPageContext } from "../../hooks/useLandingPageContext";
// import MoreInformation from "./components/MoreInformation/MoreInformation";
// higher order component

// variables
// const customerServiceIcons = [<IntraCityIcon />, <DomesticIcon />, <PartLoadIcon />, <FullLoadIcon />, <PackageAndMoversIcon />, <InternationIcon />]
const partnersIcon = [
  <DirectSalesAgentIcon />,
  <IndividualWithFleetIcon />,
  <TransportCompanyIcon />,
  <WarehouseIcon />,
  <InvestorsIcon />,
];
const servicesIcon = [
  {
    id: 0,
    isOne: false,
    images: [BikeSvg, TempoSvg],
  },
  {
    id: 1,
    isOne: false,
    images: [WarehouseSvg, CourierSvg],
  },
  {
    id: 2,
    isOne: false,
    images: [PartLoadSvg, FullLoadSvg],
  },
  {
    id: 3,
    isOne: true,
    images: [PackersSvg, MoversSvg],
  },
  {
    id: 4,
    isOne: false,
    images: [FlightSvg, ShipSvg],
  },
];

export const LandingPageHome = () => {
  // State
  // from Landing Page Context
  const {
    mainRef,
    activePage,
    activePartner,
    activeService,
    setActiveService,
    setActivePartner,
    setActivePage,
    setIsScrolled,
    mobileLandingPageRef,
    setActiveExpressParcelDeliveryType,
    setActiveLoadType
  } = useLandingPageContext();
  const [isDropdown, setIsDropdown] = useState(-1);

  // useHistory
  // useEffect
  // does: whenever user changes page, service or customer the main container will be scrolled to top
  useEffect(() => {
    mainRef.current?.scrollTo({ top: 0, behavior: "smooth" });
    mobileLandingPageRef.current?.scrollTo({ top: 0, behavior: "smooth" });
  }, [activePage, activePartner, activeService]);

  // useEffect
  // does: when container is scrolled more than 400px we are adding the header shadow effect with isScroll state
  useEffect(() => {
    const handleScroll = () => {
      if (
        (mainRef.current && mainRef.current.scrollTop > 400) ||
        (mobileLandingPageRef.current &&
          mobileLandingPageRef.current.scrollTop > 400)
      ) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    mainRef.current?.addEventListener("scroll", handleScroll);

    return () => {
      mainRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, [mainRef]); // Only re-run effect if mainRef changes

  const navigate = useNavigate();
  return (
    <>
      <div className="landing-page-main custom-scrollbar" ref={mainRef}>
        <section
          className={`main-content-with-navigation min-component-height ${activePage === "customer" ? "one-row" : ""
            } |`}
        >
          {/* Navigation */}
          <Navigation />
          {/* Main (Get Estimate Forms | Partner Forms | Customer Form) */}
          <Main />
        </section>
        {/* More Information */}
        {/* <MoreInformation /> */}
        {/* Features */}
        <Features />
        {/* How it Works */}
        <HowItWorks />
        {/* Testimonials */}
        <Testimonials />
        {/* FAQ */}
        <FAQ />
        {/* CTA */}
        <CTA />
        {/* Footer */}
        <Footer />
      </div>
      {/* Mobile Landing Page Content  */}
      {/* This part will be visible by default */}
      <div className="mobile-landing-page-content">
        <section
          className="scrollable custom-scrollbar bg-primary-50"
          ref={mobileLandingPageRef}
        >
          {/* Customer Services Options + Carousel */}
          <section className="mobile-landing-page-service bg-primary-50">
            {/* Customer Carousel */}
            <Carousel
              images={mobileCarouselImages}
              style={{ margin: 0 }}
              title="Customer"
              hideButton
              onBtnClick={() => {
                setActivePage("customer");
                navigate("/form");
              }}
            />
            {/* <NewCarousel images={mobileCarouselImages} interval={3000} /> */}
            {/* Customer Service Options */}
            <ul className="mobile-landing-page-service-list | readable-container my-1 flex-col gap-1 bg-primary-50">
              {mobileLandingPageCustomerListItem.length > 0 &&
                mobileLandingPageCustomerListItem.map((item, index) => {
                  const isComingSoon =
                    item.value === "Packers & Movers" ||
                    item.value === "International";
                  return (
                    <li
                      key={item.value}
                      className="mobile-landing-page-service-list-item | p-1 br-4 relative bg-primary-50 text-primary-900 pointer flex-col gap-4 paper"
                      onClick={() => {
                        if (
                          item.value === "Intercity Express Parcel" ||
                          item.value === "Part Load & Full Load"
                        ) {
                          setIsDropdown(index);
                          return;
                        }
                        if (isComingSoon) {
                          return alert("Coming Soon😊");
                        }
                        setActiveService(item.value);
                        navigate("/form");
                        setActivePage("services");
                      }}
                    >
                      <div className="flex-between">
                        <div className="| fw-600 flex gap-4 ai-center">
                          {servicesIcon[index].images.length > 0 &&
                            servicesIcon[index].images.map((Item, index) => (
                              // <img
                              //   loading="lazy"
                              //   key={item}
                              //   src={item}
                              //   alt="img"
                              //   style={{ width: "30px" }}
                              // />
                              <Item
                                key={index}
                                style={{ width: "30px", height: "30px" }}
                                className="navigation-icon text-primary-900"
                              />
                            ))}
                        </div>
                        {isComingSoon && (
                          <LockIcon style={{ fontSize: "18px" }} />
                        )}
                      </div>
                      <span className="| fs-list-item-heading fw-600">
                        {item.value}
                      </span>
                      <p className="| fs-list-item-text fw-500 m-0">
                        {item.description}
                      </p>
                      {item.value === "Intercity Express Parcel" &&
                        isDropdown === index && (
                          <div className="two-column gap-1">
                            <Button
                              fullWidth
                              variant="primary"
                              action="primary"
                              onClick={() => {
                                setActiveExpressParcelDeliveryType("Branch to Branch")
                                navigate("/form");
                                setActivePage("services");
                                setActiveService("Intercity Express Parcel");
                              }}
                            >
                              Branch to Branch
                            </Button>
                            <Button
                              fullWidth
                              variant="primary"
                              action="primary"
                              onClick={() => {
                                setActiveExpressParcelDeliveryType("Door Delivery")
                                navigate("/form");
                                setActivePage("services");
                                setActiveService("Intercity Express Parcel");
                              }}
                            >
                              Door Delivery
                            </Button>
                          </div>
                        )}
                      {item.value === "Part Load & Full Load" &&
                        isDropdown === index && (
                          <div className="two-column gap-1">
                            <Button
                              fullWidth
                              variant="primary"
                              action="primary"
                              onClick={() => {
                                setActiveLoadType("Part Load")
                                navigate("/form");
                                setActivePage("services");
                                setActiveService("Part Load & Full Load");
                              }}
                            >
                              Part Load
                            </Button>
                            <Button
                              fullWidth
                              variant="primary"
                              action="primary"
                              onClick={() => {
                                setActiveLoadType("Full Load")
                                navigate("/form");
                                setActivePage("services");
                                setActiveService("Part Load & Full Load");
                              }}
                            >
                              Full Load
                            </Button>
                          </div>
                        )}
                    </li>
                  );
                })}
            </ul>
          </section>
          {/* Partner Services Options + Carousel */}
          <section className="mobile-landing-page-partners bg-primary-50">
            {/* Partner Service Carousel */}
            <Carousel
              images={mobileCarouselPartnersImages}
              style={{ margin: 0 }}
              title="Partners"
              hideButton
              onBtnClick={() => {
                setActivePage("partners");
                navigate("/form");
              }}
            />
            {/* Partner Service Options */}
            <ul className="mobile-landing-page-service-list | readable-container my-1 flex-col gap-1">
              {mobileLandingPagePartnerListItem.length > 0 &&
                mobileLandingPagePartnerListItem.map((item, index) => {
                  const isComingSoon =
                    item.value === "Warehouse" || item.value === "Investors";
                  return (
                    <li
                      key={item.value}
                      className="mobile-landing-page-service-list-item | p-1 br-4 relative bg-primary-50 text-primary-900 pointer flex-col gap-4 paper"
                      onClick={() => {
                        if (isComingSoon) {
                          return alert("Coming Soon😊");
                        }
                        setActivePartner(item.value);
                        navigate("/form");
                        setActivePage("partners");
                      }}
                    >
                      <div className="mobile-landing-page-service-list-item-icon flex-between">
                        {partnersIcon[index]}
                        {isComingSoon && (
                          <LockIcon style={{ width: "18px", height: "18px" }} />
                        )}
                      </div>
                      <span className="| fs-list-item-heading fw-600 flex gap-4 ai-center">
                        {item.value}
                      </span>
                      <p className="| fs-list-item-text fw-500 m-0">
                        {item.description}
                      </p>
                    </li>
                  );
                })}
            </ul>
          </section>
          {/* CTA */}
          <CTA />
          {/* <Footer /> */}
          <Footer />
        </section>
        {/* Mobile Footer Navigation Menu */}
        <MobileFooterNavigation />
      </div>
    </>
  );
};

// MobileForms Component (Get Estimate Form | Partner Form | Customer Form)
export function MobileForms() {
  // State
  const { activePartner, activeService, activePage, mobileFormPageRef } =
    useLandingPageContext();

  // useEffect
  // does: whenever user changes the active form from the sidebar it will move to the top
  useEffect(() => {
    if (mobileFormPageRef.current) {
      mobileFormPageRef.current.scroll({ behavior: "smooth", top: 0 });
    }
  }, [activePage, activeService, activePartner]);

  // component return
  return (
    <section className="mobile-forms">
      <div className="mobile-main-content">
        <div className="scrollable custom-scrollbar" ref={mobileFormPageRef}>
          <Main />
          {/* <MoreInformation /> */}
          <Features />
          <HowItWorks />
          <Testimonials />
          <FAQ />
          <CTA />
          <Footer />
        </div>
        <MobileFooterNavigation />
      </div>
    </section>
  );
}
