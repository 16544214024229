// react
import React from "react";
// context
import { useLandingPageContext } from "../../../../hooks/useLandingPageContext";
// components
import { Heading } from "../../../../components/UI";
// constants (data)
import { testimonials, testimonialsData } from "./data";

// FC: Testimonials Component
const Testimonials = () => {
  // state
  const {
    activePage,
    activeService,
    activePartner,
    activeExpressParcelDeliveryType,
  } = useLandingPageContext();
  // data
  const data =
    testimonialsData.find((testimonial) => {
      if (testimonial.service === "Intercity Express Parcel") {
        return testimonial.expressParcelType === activeExpressParcelDeliveryType;
      }
      if (testimonial.page === activePage) {
        if (testimonial.service === activeService) {
          return true;
        }
        if (testimonial.partner === activePartner) {
          return true;
        }
      }
      return false;
    })?.testimonials || testimonials;

  // return component
  return (
    <main className="testimonials | p-3 bg-primary-400">
      <section className="| flex-col gap-component jc-between my-container">
        <Heading className="text-white-900">Testimonials</Heading>
        <ul className="testimonial-grid | gap-2 my-container m-0 mx-auto">
          {data &&
            Array.isArray(data) &&
            data.length > 0 &&
            data.map((item) => (
              <li
                key={item.id}
                className="our-feature-item | py-2 px-20 br-4 bg-primary-500 relative flex-col ai-center gap-10 relative pointer shadow-small"
              >
                <div
                  className="| circle"
                  style={{ border: "10px solid var(--clr-white-400)" }}
                >
                  <img loading="lazy" src={item.img} alt="image" />
                </div>
                <h1
                  className="| fw-600 text-center text-white-900 fs-list-item-heading"
                  style={{ margin: 0 }}
                >
                  {item.name}
                </h1>
                <p
                  className="| fw-400 text-center text-white-800 fs-list-item-text text-justify"
                  style={{ margin: 0 }}
                >
                  {item.description}
                </p>
              </li>
            ))}
        </ul>
      </section>
    </main>
  );
};

export default Testimonials;
