import { buildQueryString, ServiceMaker } from "./index";

export const createAssetType = (data: any) =>
  ServiceMaker(`asset/type/create`, "POST", data);

export const updateAssetType = ( id:string,data: any) =>
  ServiceMaker(`asset/type/update/${id}`, "PUT", data);

export const removeAssetType = (data: any) =>
  ServiceMaker(`asset/type/delete`, "DELETE", data);

export const getAssetTypeByCategoryList = (data: any) =>
  ServiceMaker(`asset/type/list/by/category?${buildQueryString(data)}`, "GET");

export const addAssetType = (data:any) =>
  ServiceMaker("assetType/addAssetType", "POST",data); 
 
 export const addAssetSubType = (data:any) =>
  ServiceMaker("assetSubType/addAssetSubType", "POST",data);

 export const updateAssetSubType = (data:any) =>
  ServiceMaker("assetSubType/updateAssetSubType", "PATCH",data);
  
  export const listassetType = () =>
    getAssetTypeByCategoryList({categoryId:"5c304e42296d79da335ada16"})
  
  export const listAssetSubType = () =>
   ServiceMaker("assetSubType/list", "GET");
  
  export const listDriverLocationIssues = () =>
   ServiceMaker("driver/driverLocationIssueList", "GET");