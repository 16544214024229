import React, { useState } from "react";
import { Dialog } from "../../newSrc/components/UI";
import { CloseButton } from "../../Components/Button/AllButton";
import formatDate, { formatDateTime } from './../../services/FormatDate';
import { InfoIcon } from "../../newSrc/constants/icons";

interface IProps {
  title: string;
  info?: any;
}

const StatusHeader = (props: IProps) => {
  const [details, setDetails] = useState<any>(null);

  const handleOpen = () => {
    setDetails(props?.info);
  };

  const handleClose = () => {
    setDetails(null);
  };


  return (
    <>
      <div className="border-primary p-1 br-1 fs-link pointer flex-center gap-4">
        {props.title}{" "}
        { props?.info && <InfoIcon className="text-primary-400" onClick={() => handleOpen()}/>}
      </div>
      <Dialog
        state={Boolean(details)}
        onClose={handleClose}
        size="small"
        header={props?.title}
        body={
          <div style={{ fontSize: "18px" }}>
          {(props.title === "New Destination" ||
            props.title === "Back To Origin") && (
            <div>
              {props.title === "New Destination" ? (
                <div>
                  New Destination : {props?.info?.newDestination?.branchName}
                </div>
              ) : (
                <div>Back To Origin</div>
              )}
              <div>Amount : {props?.info?.charge}</div>
              <div>
                Status:{" "}
                {props.info?.status === "A"
                  ? "Approved"
                  : props?.info?.status === "R"
                  ? "Rejected"
                  : "Pending"}
              </div>
              <div>Remarks : {props?.info?.adminRemarks}</div>
              <div>Action Date : {props?.info?.date ? `${formatDateTime(props?.info?.date)}` : 
                                    props?.info?.createdAt ? `${formatDateTime(props?.info?.createdAt)}` : "- - -"}</div>
            </div>
          )}

          {props?.title === "Demurrage" && (
            <div>
              {props?.info?.acceptedAmount ? (
                <div>Accepted Demurrage: {props?.info?.acceptedAmount}</div>
              ) : (
                <div>Requested Demurrage: {props?.info?.requestedAmount}</div>
              )}
              <div>
                Status:{" "}
                {props?.info?.status === "A"
                  ? "Approved"
                  : props?.info?.status === "R"
                  ? "Rejected"
                  : "Pending"}
              </div>{" "}
              <div>Sender Remarks : {props?.info?.senderRemarks ? props?.info?.senderRemarks : "- - -"}</div>
              <div>Admin Remarks : {props?.info?.adminRemarks ? props?.info?.adminRemarks : "- - -"}</div>
              <div>Action Date : {props?.info?.date ? `${formatDateTime(props?.info?.date)}` : 
                                    props?.info?.createdAt ? `${formatDateTime(props?.info?.createdAt)}` : "- - -"}</div>
            </div>
          )}
          {props?.title === "Discount" && (
            <div>
              {props?.info?.acceptedDiscount ? (
                <div>Accepted Discount: {props?.info?.acceptedDiscount}</div>
              ) : (
                <div>Requested Discount: {props?.info?.discount}</div>
              )}
              <div>
                Status:{" "}
                {props?.info?.status === "A"
                  ? "Approved"
                  : props?.info?.status === "R"
                  ? "Rejected"
                  : "Pending"}
              </div>
              <div>Sender Remarks : {props?.info?.senderRemarks ? props?.info?.senderRemarks : "- - -"}</div>
              <div>Admin Remarks : {props?.info?.adminRemarks ? props?.info?.adminRemarks : "- - -"}</div>
              <div>Action Date : {props?.info?.date ? `${formatDateTime(props?.info?.date)}` : 
                                    props?.info?.createdAt ? `${formatDateTime(props?.info?.createdAt)}` : "- - -"}</div>
            </div>
          )}
          {props?.title === "Cancel Booking" && (
            <div>
              <div>Status: {props?.info?.status}</div>
              <div>Remarks: {props?.info?.remark}</div>
              <div>Action Date : {props?.info?.date ? `${formatDateTime(props?.info?.date)}` : 
                                    props?.info?.createdAt ? `${formatDateTime(props?.info?.createdAt)}` : "- - -"}</div>
            </div>
          )}
            {props?.title === "illegal Goods Founds" && (
              <div>
                <div>Created By : {props?.info?.createdBy?.name?.fName + props?.info?.createdBy?.name?.lName}</div>
                <div>Created At : {formatDate(props?.info?.createdAt)}</div>
              </div>
            )}
            {props?.title === "Eway Bill Details Missing" && (
              <div>
                <div>Created By : {props?.info?.createdBy?.name?.fName + props?.info?.createdBy?.name?.lName}</div>
                <div>Created At : {formatDate(props?.info?.createdAt)}</div>
              </div>
            )}
        </div>
        }
        footer={
          <CloseButton onClick={()=>handleClose()}/>
        }
      />
    </>
  );
};

export default StatusHeader;
